import { useContext } from "react"
import { AbsoluteBlock } from "../AbsoluteBlock"
import { AutosizeSvg } from "../AnnotationsLayer/AutosizeSvg"
import { cssToLogical } from "../AnnotationsLayer/csstoLogical"
import { ObjectFitContain, ObjectFitContaintContext } from "../ObjectFitContain"
import { RCCropImageCrop } from "../RCCropImage/RCCropImage"
import { lengthInMm } from "../AnnotationsLayer/Ruler"

export type PBLMeasurement = {
    x1: number
    y1: number
    x2: number
    y2: number,
    color: string,
}

export type RCPBLProps = {
    crop: RCCropImageCrop,
    measurements: PBLMeasurement[],
    pixelSpacing: [number, number],
}

export const RCPBL = (props: RCPBLProps) => {

    const { crop, measurements, pixelSpacing } = props;

    return (
        <AbsoluteBlock>
            <ObjectFitContain
                objectWidth={crop.w}
                objectHeight={crop.h}
            >
                <AutosizeSvg style={{ display: 'block', pointerEvents: 'none' }}>
                    {measurements?.map((measurement, index) => (
                        <RenderPBLMeasurement key={index} measurement={measurement} crop={crop} pixelSpacing={pixelSpacing} />
                    ))}
                </AutosizeSvg>
            </ObjectFitContain>
        </AbsoluteBlock>
    )
}

type RenderPBLMeasurementProps = {
    crop: RCCropImageCrop,
    measurement: PBLMeasurement,
    pixelSpacing: [number, number]
}

const RenderPBLMeasurement = (props: RenderPBLMeasurementProps) => {

    const objectFit = useContext(ObjectFitContaintContext);

    // pixelSpacing will be useful when we will need to convert from pixels to millimeters
    const { measurement, pixelSpacing, crop } = props;
    const { x1, y1, x2, y2, color } = measurement;

    const xa = x1 - crop.x;
    const ya = y1 - crop.y;

    const xb = x2 - crop.x;
    const yb = y2 - crop.y;

    const xCenter = (xa + xb) / 2;
    const yCenter = (ya + yb) / 2;

    /*
        Q: Why do we use cssToLogical here?
        A: Designs measurements are presented in css pixels (as you always expect them to be)
        But in case of svg we need to convert them to logical pixels (see cssToLogical documentation)
    */
    const circleRadius = cssToLogical(6, objectFit)
    const lineThickness = cssToLogical(2, objectFit)

    const rectWidth = cssToLogical(22, objectFit)
    const rectHeight = cssToLogical(13, objectFit)
    const rectRadius = cssToLogical(6, objectFit)

    const textFontSize = cssToLogical(9, objectFit)

    const length = lengthInMm(x1, y1, x2, y2, pixelSpacing).toFixed(1)

    return (
        <>
            <circle
                cx={xa}
                cy={ya}
                r={circleRadius}
                stroke={color}
                fill={color}
            />
            <line
                x1={xa}
                y1={ya}
                x2={xb}
                y2={yb}
                stroke={color}
                strokeWidth={lineThickness}
                strokeOpacity="1"
            />
            <circle
                cx={xb}
                cy={yb}
                r={circleRadius}
                stroke={color}
                fill={color}
            />

            <rect
                x={xCenter - rectWidth / 2}
                y={yCenter - rectHeight / 2}
                width={rectWidth}
                height={rectHeight}
                rx={rectRadius}
                fill={color}
            />
            <text
                x={xCenter}
                y={yCenter + 1}
                fontSize={textFontSize}
                textAnchor="middle"
                alignmentBaseline="middle"
                fill="black"
            >
                {length}
            </text>

        </>
    )
}