import { FormattedMessage } from 'react-intl';
import { setLocale } from 'yup';

setLocale({
  string: {
    min: ({ min }) => (
      <FormattedMessage
        id="yup.min"
        defaultMessage="Must be at least {min} characters"
        values={{ min }}
      />
    ),
    max: ({ max }) => (
      <FormattedMessage
        id="yup.max"
        defaultMessage="Must be at most {max} characters"
        values={{ max }}
      />
    ),
    email: () => (
      <FormattedMessage id="yup.email" defaultMessage="Incorrect email" />
    ),
    url: () => (
      <FormattedMessage id="yup.url" defaultMessage="Must be a valid URL" />
    ),
    trim: () => (
      <FormattedMessage
        id="yup.trim"
        defaultMessage="Must be a trimmed string"
      />
    ),
  },
  mixed: {
    required: () => (
      <FormattedMessage id="yup.required" defaultMessage="Field is required" />
    ),
  },
});

export * from 'yup';
