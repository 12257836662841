import { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './Divider.module.scss';

type DividerProps = {
  className?: string;
  children?: ReactNode;
};

export const Divider: FC<DividerProps> = (props) => {
  const { className, children } = props;

  return <div className={cn(styles.container, className)}>{children}</div>;
};
