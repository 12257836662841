import { FC, ReactNode } from 'react';
import cn from 'classnames';
import Popup from 'reactjs-popup';
import { PopupPosition } from 'reactjs-popup/dist/types';

import styles from './Dropdown.module.scss';

export type DropdownProps = {
  trigger: JSX.Element;
  className?: string;
  testID?: string;
  position?: PopupPosition | PopupPosition[];
  isOpen?: boolean;
  setIsOpen?: (o: boolean) => void;
  children?: ReactNode;
};

export const Dropdown: FC<DropdownProps> = (props) => {
  const {
    trigger,
    className,
    testID,
    isOpen,
    setIsOpen = () => {},
    position = 'bottom right',
    children,
  } = props;

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <Popup
        open={isOpen}
        trigger={<div className={styles.trigger}>{trigger}</div>}
        position={position}
        offsetY={8}
        onOpen={handleOpen}
        onClose={handleClose}
        contentStyle={{
          padding: '0px',
          border: 'none',
          background: 'none',
        }}
        arrow={false}
      >
        {isOpen && <div className={styles.children}>{children}</div>}
      </Popup>
    </div>
  );
};
