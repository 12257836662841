import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@/shared/ui';

type EditToothNumberProps = {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
};

export const EditToothNumber: FC<EditToothNumberProps> = (props) => {
  const { onClick, className, disabled } = props;

  return (
    <Button
      variant="tertiary"
      onClick={onClick}
      disabled={disabled}
      size="small"
      icon="pen"
      className={className}
    >
      <FormattedMessage
        id="report.editToothNumbers"
        defaultMessage="Edit Numbers"
      />
    </Button>
  );
};
