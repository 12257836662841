/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { CBCTSeriesGeometryData } from '../model/dto_common_geometry';
import { CephalometricLandmark } from '../model/dto_report_landmark';
import { ReportCBCTIOSSegment } from '../model/dto_report_common';

export const protobufPackage = 'com.diagnocat.model';

export interface ReportCBCTSegmentation {
  CropGeometryData: CBCTSeriesGeometryData | undefined;
  CephalometricLandmarks: CephalometricLandmark[];
  Segments: ReportCBCTIOSSegment[];
}

function createBaseReportCBCTSegmentation(): ReportCBCTSegmentation {
  return {
    CropGeometryData: undefined,
    CephalometricLandmarks: [],
    Segments: [],
  };
}

export const ReportCBCTSegmentation = {
  encode(
    message: ReportCBCTSegmentation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CropGeometryData !== undefined) {
      CBCTSeriesGeometryData.encode(
        message.CropGeometryData,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    for (const v of message.CephalometricLandmarks) {
      CephalometricLandmark.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.Segments) {
      ReportCBCTIOSSegment.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportCBCTSegmentation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportCBCTSegmentation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CropGeometryData = CBCTSeriesGeometryData.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.CephalometricLandmarks.push(
            CephalometricLandmark.decode(reader, reader.uint32()),
          );
          break;
        case 3:
          message.Segments.push(
            ReportCBCTIOSSegment.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportCBCTSegmentation {
    return {
      CropGeometryData: isSet(object.CropGeometryData)
        ? CBCTSeriesGeometryData.fromJSON(object.CropGeometryData)
        : undefined,
      CephalometricLandmarks: Array.isArray(object?.CephalometricLandmarks)
        ? object.CephalometricLandmarks.map((e: any) =>
            CephalometricLandmark.fromJSON(e),
          )
        : [],
      Segments: Array.isArray(object?.Segments)
        ? object.Segments.map((e: any) => ReportCBCTIOSSegment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ReportCBCTSegmentation): unknown {
    const obj: any = {};
    message.CropGeometryData !== undefined &&
      (obj.CropGeometryData = message.CropGeometryData
        ? CBCTSeriesGeometryData.toJSON(message.CropGeometryData)
        : undefined);
    if (message.CephalometricLandmarks) {
      obj.CephalometricLandmarks = message.CephalometricLandmarks.map((e) =>
        e ? CephalometricLandmark.toJSON(e) : undefined,
      );
    } else {
      obj.CephalometricLandmarks = [];
    }
    if (message.Segments) {
      obj.Segments = message.Segments.map((e) =>
        e ? ReportCBCTIOSSegment.toJSON(e) : undefined,
      );
    } else {
      obj.Segments = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportCBCTSegmentation>, I>>(
    object: I,
  ): ReportCBCTSegmentation {
    const message = createBaseReportCBCTSegmentation();
    message.CropGeometryData =
      object.CropGeometryData !== undefined && object.CropGeometryData !== null
        ? CBCTSeriesGeometryData.fromPartial(object.CropGeometryData)
        : undefined;
    message.CephalometricLandmarks =
      object.CephalometricLandmarks?.map((e) =>
        CephalometricLandmark.fromPartial(e),
      ) || [];
    message.Segments =
      object.Segments?.map((e) => ReportCBCTIOSSegment.fromPartial(e)) || [];
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
