// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toolbar-module__container--LpVn4uyf{border-radius:8px;display:flex;flex-direction:column;gap:2px}@media screen and (max-width: 1024px){.Toolbar-module__container--LpVn4uyf{flex-direction:row}}.Toolbar-module__icon--UBk8Ruxu{cursor:pointer;background-color:var(--wb);border-radius:8px}.Toolbar-module__icon--UBk8Ruxu:hover,.Toolbar-module__icon--UBk8Ruxu.Toolbar-module__active--Ns5TqQUb{background-color:var(--purpledec);color:var(--wb);fill:var(--wb);opacity:1}", "",{"version":3,"sources":["webpack://./src/pages/IOXRayReport/ui/Toolbar/Toolbar.module.scss","webpack://./src/shared/styles/media.scss"],"names":[],"mappings":"AAEA,qCACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CCaA,sCDjBF,qCAOI,kBAAA,CAAA,CAIJ,gCACE,cAAA,CACA,0BAAA,CACA,iBAAA,CAEA,uGAEE,iCAAA,CACA,eAAA,CACA,cAAA,CACA,SAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.container {\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n\n  @include smallDesktop {\n    flex-direction: row;\n  }\n}\n\n.icon {\n  cursor: pointer;\n  background-color: var(--wb);\n  border-radius: 8px;\n\n  &:hover,\n  &.active {\n    background-color: var(--purpledec);\n    color: var(--wb);\n    fill: var(--wb);\n    opacity: 1;\n  }\n}\n","$mobile: 420px;\n$tablet: 768px;\n$smallDesktop: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1400px;\n\n@mixin mobile {\n  @media screen and (max-width: $mobile) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media screen and (max-width: $tablet) {\n    @content;\n  }\n}\n\n@mixin smallDesktop {\n  @media screen and (max-width: $smallDesktop) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media screen and (max-width: $desktop) {\n    @content;\n  }\n}\n\n@mixin largeDesktop {\n  @media screen and (max-width: $largeDesktop) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Toolbar-module__container--LpVn4uyf",
	"icon": "Toolbar-module__icon--UBk8Ruxu",
	"active": "Toolbar-module__active--Ns5TqQUb"
};
export default ___CSS_LOADER_EXPORT___;
