/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  CBCTSeriesGeometryData,
  FloatPoint3D,
} from '../model/dto_common_geometry';
import { ToothNumeration } from '../model/dto_report_common';

export const protobufPackage = 'com.diagnocat.model';

export interface ReportStudioColorProfile {
  WindowWidth: number;
  WindowLevel: number;
}

export interface ReportStudioCBCTDownscaledGeometry {
  CropGeometryData: CBCTSeriesGeometryData | undefined;
  DownscaleFactor: number;
}

export interface ReportStudioToothPosition {
  Tooth: ToothNumeration | undefined;
  IsMissing: boolean;
  Position: ReportStudioOriginatedPosition | undefined;
}

export interface ReportStudioPanoramaGeometry {
  Points: FloatPoint3D[];
  Up: FloatPoint3D | undefined;
  ColorProfile: ReportStudioColorProfile | undefined;
  Shape:
    | ReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape
    | undefined;
  DepthStep: number;
}

export interface ReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape {
  Width: number;
  Height: number;
  Depth: number;
}

export interface ReportStudioOriginatedPosition {
  Origin: FloatPoint3D | undefined;
  Directions:
    | ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections
    | undefined;
}

export interface ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections {
  X: FloatPoint3D | undefined;
  Y: FloatPoint3D | undefined;
  Z: FloatPoint3D | undefined;
}

function createBaseReportStudioColorProfile(): ReportStudioColorProfile {
  return { WindowWidth: 0, WindowLevel: 0 };
}

export const ReportStudioColorProfile = {
  encode(
    message: ReportStudioColorProfile,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.WindowWidth !== 0) {
      writer.uint32(13).float(message.WindowWidth);
    }
    if (message.WindowLevel !== 0) {
      writer.uint32(21).float(message.WindowLevel);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioColorProfile {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioColorProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.WindowWidth = reader.float();
          break;
        case 2:
          message.WindowLevel = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioColorProfile {
    return {
      WindowWidth: isSet(object.WindowWidth) ? Number(object.WindowWidth) : 0,
      WindowLevel: isSet(object.WindowLevel) ? Number(object.WindowLevel) : 0,
    };
  },

  toJSON(message: ReportStudioColorProfile): unknown {
    const obj: any = {};
    message.WindowWidth !== undefined &&
      (obj.WindowWidth = message.WindowWidth);
    message.WindowLevel !== undefined &&
      (obj.WindowLevel = message.WindowLevel);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportStudioColorProfile>, I>>(
    object: I,
  ): ReportStudioColorProfile {
    const message = createBaseReportStudioColorProfile();
    message.WindowWidth = object.WindowWidth ?? 0;
    message.WindowLevel = object.WindowLevel ?? 0;
    return message;
  },
};

function createBaseReportStudioCBCTDownscaledGeometry(): ReportStudioCBCTDownscaledGeometry {
  return { CropGeometryData: undefined, DownscaleFactor: 0 };
}

export const ReportStudioCBCTDownscaledGeometry = {
  encode(
    message: ReportStudioCBCTDownscaledGeometry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CropGeometryData !== undefined) {
      CBCTSeriesGeometryData.encode(
        message.CropGeometryData,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.DownscaleFactor !== 0) {
      writer.uint32(21).float(message.DownscaleFactor);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioCBCTDownscaledGeometry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioCBCTDownscaledGeometry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CropGeometryData = CBCTSeriesGeometryData.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.DownscaleFactor = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioCBCTDownscaledGeometry {
    return {
      CropGeometryData: isSet(object.CropGeometryData)
        ? CBCTSeriesGeometryData.fromJSON(object.CropGeometryData)
        : undefined,
      DownscaleFactor: isSet(object.DownscaleFactor)
        ? Number(object.DownscaleFactor)
        : 0,
    };
  },

  toJSON(message: ReportStudioCBCTDownscaledGeometry): unknown {
    const obj: any = {};
    message.CropGeometryData !== undefined &&
      (obj.CropGeometryData = message.CropGeometryData
        ? CBCTSeriesGeometryData.toJSON(message.CropGeometryData)
        : undefined);
    message.DownscaleFactor !== undefined &&
      (obj.DownscaleFactor = message.DownscaleFactor);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportStudioCBCTDownscaledGeometry>, I>,
  >(object: I): ReportStudioCBCTDownscaledGeometry {
    const message = createBaseReportStudioCBCTDownscaledGeometry();
    message.CropGeometryData =
      object.CropGeometryData !== undefined && object.CropGeometryData !== null
        ? CBCTSeriesGeometryData.fromPartial(object.CropGeometryData)
        : undefined;
    message.DownscaleFactor = object.DownscaleFactor ?? 0;
    return message;
  },
};

function createBaseReportStudioToothPosition(): ReportStudioToothPosition {
  return { Tooth: undefined, IsMissing: false, Position: undefined };
}

export const ReportStudioToothPosition = {
  encode(
    message: ReportStudioToothPosition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      ToothNumeration.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    if (message.IsMissing === true) {
      writer.uint32(16).bool(message.IsMissing);
    }
    if (message.Position !== undefined) {
      ReportStudioOriginatedPosition.encode(
        message.Position,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioToothPosition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioToothPosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 2:
          message.IsMissing = reader.bool();
          break;
        case 3:
          message.Position = ReportStudioOriginatedPosition.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioToothPosition {
    return {
      Tooth: isSet(object.Tooth)
        ? ToothNumeration.fromJSON(object.Tooth)
        : undefined,
      IsMissing: isSet(object.IsMissing) ? Boolean(object.IsMissing) : false,
      Position: isSet(object.Position)
        ? ReportStudioOriginatedPosition.fromJSON(object.Position)
        : undefined,
    };
  },

  toJSON(message: ReportStudioToothPosition): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth
        ? ToothNumeration.toJSON(message.Tooth)
        : undefined);
    message.IsMissing !== undefined && (obj.IsMissing = message.IsMissing);
    message.Position !== undefined &&
      (obj.Position = message.Position
        ? ReportStudioOriginatedPosition.toJSON(message.Position)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportStudioToothPosition>, I>>(
    object: I,
  ): ReportStudioToothPosition {
    const message = createBaseReportStudioToothPosition();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? ToothNumeration.fromPartial(object.Tooth)
        : undefined;
    message.IsMissing = object.IsMissing ?? false;
    message.Position =
      object.Position !== undefined && object.Position !== null
        ? ReportStudioOriginatedPosition.fromPartial(object.Position)
        : undefined;
    return message;
  },
};

function createBaseReportStudioPanoramaGeometry(): ReportStudioPanoramaGeometry {
  return {
    Points: [],
    Up: undefined,
    ColorProfile: undefined,
    Shape: undefined,
    DepthStep: 0,
  };
}

export const ReportStudioPanoramaGeometry = {
  encode(
    message: ReportStudioPanoramaGeometry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Points) {
      FloatPoint3D.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.Up !== undefined) {
      FloatPoint3D.encode(message.Up, writer.uint32(18).fork()).ldelim();
    }
    if (message.ColorProfile !== undefined) {
      ReportStudioColorProfile.encode(
        message.ColorProfile,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.Shape !== undefined) {
      ReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape.encode(
        message.Shape,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.DepthStep !== 0) {
      writer.uint32(45).float(message.DepthStep);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioPanoramaGeometry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioPanoramaGeometry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Points.push(FloatPoint3D.decode(reader, reader.uint32()));
          break;
        case 2:
          message.Up = FloatPoint3D.decode(reader, reader.uint32());
          break;
        case 3:
          message.ColorProfile = ReportStudioColorProfile.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 4:
          message.Shape =
            ReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 5:
          message.DepthStep = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioPanoramaGeometry {
    return {
      Points: Array.isArray(object?.Points)
        ? object.Points.map((e: any) => FloatPoint3D.fromJSON(e))
        : [],
      Up: isSet(object.Up) ? FloatPoint3D.fromJSON(object.Up) : undefined,
      ColorProfile: isSet(object.ColorProfile)
        ? ReportStudioColorProfile.fromJSON(object.ColorProfile)
        : undefined,
      Shape: isSet(object.Shape)
        ? ReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape.fromJSON(
            object.Shape,
          )
        : undefined,
      DepthStep: isSet(object.DepthStep) ? Number(object.DepthStep) : 0,
    };
  },

  toJSON(message: ReportStudioPanoramaGeometry): unknown {
    const obj: any = {};
    if (message.Points) {
      obj.Points = message.Points.map((e) =>
        e ? FloatPoint3D.toJSON(e) : undefined,
      );
    } else {
      obj.Points = [];
    }
    message.Up !== undefined &&
      (obj.Up = message.Up ? FloatPoint3D.toJSON(message.Up) : undefined);
    message.ColorProfile !== undefined &&
      (obj.ColorProfile = message.ColorProfile
        ? ReportStudioColorProfile.toJSON(message.ColorProfile)
        : undefined);
    message.Shape !== undefined &&
      (obj.Shape = message.Shape
        ? ReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape.toJSON(
            message.Shape,
          )
        : undefined);
    message.DepthStep !== undefined && (obj.DepthStep = message.DepthStep);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportStudioPanoramaGeometry>, I>>(
    object: I,
  ): ReportStudioPanoramaGeometry {
    const message = createBaseReportStudioPanoramaGeometry();
    message.Points =
      object.Points?.map((e) => FloatPoint3D.fromPartial(e)) || [];
    message.Up =
      object.Up !== undefined && object.Up !== null
        ? FloatPoint3D.fromPartial(object.Up)
        : undefined;
    message.ColorProfile =
      object.ColorProfile !== undefined && object.ColorProfile !== null
        ? ReportStudioColorProfile.fromPartial(object.ColorProfile)
        : undefined;
    message.Shape =
      object.Shape !== undefined && object.Shape !== null
        ? ReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape.fromPartial(
            object.Shape,
          )
        : undefined;
    message.DepthStep = object.DepthStep ?? 0;
    return message;
  },
};

function createBaseReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape(): ReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape {
  return { Width: 0, Height: 0, Depth: 0 };
}

export const ReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape = {
  encode(
    message: ReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Width !== 0) {
      writer.uint32(13).float(message.Width);
    }
    if (message.Height !== 0) {
      writer.uint32(21).float(message.Height);
    }
    if (message.Depth !== 0) {
      writer.uint32(29).float(message.Depth);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Width = reader.float();
          break;
        case 2:
          message.Height = reader.float();
          break;
        case 3:
          message.Depth = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(
    object: any,
  ): ReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape {
    return {
      Width: isSet(object.Width) ? Number(object.Width) : 0,
      Height: isSet(object.Height) ? Number(object.Height) : 0,
      Depth: isSet(object.Depth) ? Number(object.Depth) : 0,
    };
  },

  toJSON(
    message: ReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape,
  ): unknown {
    const obj: any = {};
    message.Width !== undefined && (obj.Width = message.Width);
    message.Height !== undefined && (obj.Height = message.Height);
    message.Depth !== undefined && (obj.Depth = message.Depth);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape>,
      I
    >,
  >(object: I): ReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape {
    const message =
      createBaseReportStudioPanoramaGeometry_ReportStudioPanoramaGeometryShape();
    message.Width = object.Width ?? 0;
    message.Height = object.Height ?? 0;
    message.Depth = object.Depth ?? 0;
    return message;
  },
};

function createBaseReportStudioOriginatedPosition(): ReportStudioOriginatedPosition {
  return { Origin: undefined, Directions: undefined };
}

export const ReportStudioOriginatedPosition = {
  encode(
    message: ReportStudioOriginatedPosition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Origin !== undefined) {
      FloatPoint3D.encode(message.Origin, writer.uint32(10).fork()).ldelim();
    }
    if (message.Directions !== undefined) {
      ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections.encode(
        message.Directions,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioOriginatedPosition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioOriginatedPosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Origin = FloatPoint3D.decode(reader, reader.uint32());
          break;
        case 2:
          message.Directions =
            ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections.decode(
              reader,
              reader.uint32(),
            );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioOriginatedPosition {
    return {
      Origin: isSet(object.Origin)
        ? FloatPoint3D.fromJSON(object.Origin)
        : undefined,
      Directions: isSet(object.Directions)
        ? ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections.fromJSON(
            object.Directions,
          )
        : undefined,
    };
  },

  toJSON(message: ReportStudioOriginatedPosition): unknown {
    const obj: any = {};
    message.Origin !== undefined &&
      (obj.Origin = message.Origin
        ? FloatPoint3D.toJSON(message.Origin)
        : undefined);
    message.Directions !== undefined &&
      (obj.Directions = message.Directions
        ? ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections.toJSON(
            message.Directions,
          )
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportStudioOriginatedPosition>, I>>(
    object: I,
  ): ReportStudioOriginatedPosition {
    const message = createBaseReportStudioOriginatedPosition();
    message.Origin =
      object.Origin !== undefined && object.Origin !== null
        ? FloatPoint3D.fromPartial(object.Origin)
        : undefined;
    message.Directions =
      object.Directions !== undefined && object.Directions !== null
        ? ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections.fromPartial(
            object.Directions,
          )
        : undefined;
    return message;
  },
};

function createBaseReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections(): ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections {
  return { X: undefined, Y: undefined, Z: undefined };
}

export const ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections =
  {
    encode(
      message: ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections,
      writer: _m0.Writer = _m0.Writer.create(),
    ): _m0.Writer {
      if (message.X !== undefined) {
        FloatPoint3D.encode(message.X, writer.uint32(10).fork()).ldelim();
      }
      if (message.Y !== undefined) {
        FloatPoint3D.encode(message.Y, writer.uint32(18).fork()).ldelim();
      }
      if (message.Z !== undefined) {
        FloatPoint3D.encode(message.Z, writer.uint32(26).fork()).ldelim();
      }
      return writer;
    },

    decode(
      input: _m0.Reader | Uint8Array,
      length?: number,
    ): ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections {
      const reader =
        input instanceof _m0.Reader ? input : new _m0.Reader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message =
        createBaseReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.X = FloatPoint3D.decode(reader, reader.uint32());
            break;
          case 2:
            message.Y = FloatPoint3D.decode(reader, reader.uint32());
            break;
          case 3:
            message.Z = FloatPoint3D.decode(reader, reader.uint32());
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    },

    fromJSON(
      object: any,
    ): ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections {
      return {
        X: isSet(object.X) ? FloatPoint3D.fromJSON(object.X) : undefined,
        Y: isSet(object.Y) ? FloatPoint3D.fromJSON(object.Y) : undefined,
        Z: isSet(object.Z) ? FloatPoint3D.fromJSON(object.Z) : undefined,
      };
    },

    toJSON(
      message: ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections,
    ): unknown {
      const obj: any = {};
      message.X !== undefined &&
        (obj.X = message.X ? FloatPoint3D.toJSON(message.X) : undefined);
      message.Y !== undefined &&
        (obj.Y = message.Y ? FloatPoint3D.toJSON(message.Y) : undefined);
      message.Z !== undefined &&
        (obj.Z = message.Z ? FloatPoint3D.toJSON(message.Z) : undefined);
      return obj;
    },

    fromPartial<
      I extends Exact<
        DeepPartial<ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections>,
        I
      >,
    >(
      object: I,
    ): ReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections {
      const message =
        createBaseReportStudioOriginatedPosition_ReportStudioOriginatedPositionDirections();
      message.X =
        object.X !== undefined && object.X !== null
          ? FloatPoint3D.fromPartial(object.X)
          : undefined;
      message.Y =
        object.Y !== undefined && object.Y !== null
          ? FloatPoint3D.fromPartial(object.Y)
          : undefined;
      message.Z =
        object.Z !== undefined && object.Z !== null
          ? FloatPoint3D.fromPartial(object.Z)
          : undefined;
      return message;
    },
  };

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
