export type SignStatus = 'withoutSign' | 'signed' | 'notSigned';

export enum MaskStyleTypes {
  Default = 1,
  Style2 = 2,
  Style3 = 3,
  Style4 = 4,
  StyleBW = 5,
}

export enum FMXNavigationMode {
  ToothChart = 1,
  MatrixView = 2,
}

export type ViewOptionsInterface = {
  sharpness: number;
  invert: boolean;
  wwwc: { ww: number; wc: number };
};
