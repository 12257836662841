import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { toothTypeMessages } from '../../config/i18n';
import { ToothType } from '../../config/tooth.type';

import styles from './ToothChartLegends.module.scss';

const DEFAULT_TOOTH_CHART_LEGENDS: ToothType[] = [
  'unhealthy',
  'lowProbability',
  'treated',
  'healthy',
  'missing',
];

type ToothChartLegendsProps = {
  className?: string;
  toothChartLegends?: ToothType[];
  blackAndWhiteMode?: boolean;
  showLowProbability: boolean;
};

export const ToothChartLegends: FC<ToothChartLegendsProps> = (props) => {
  const {
    toothChartLegends = DEFAULT_TOOTH_CHART_LEGENDS,
    className,
    blackAndWhiteMode = false,
    showLowProbability,
  } = props;

  const { formatMessage } = useIntl();

  return (
    <ul className={cn(styles.container, className)}>
      {toothChartLegends
        .filter((legend) =>
          !showLowProbability ? legend !== 'lowProbability' : true,
        )
        .map((legend) => (
          <li className={cn(styles.legend)} key={legend}>
            <span className={cn(styles.legendTitle, 'p3t')}>
              <img
                className={
                  blackAndWhiteMode ? styles.blackAndWhiteIcon : undefined
                }
                src={
                  blackAndWhiteMode
                    ? // eslint-disable-next-line import/no-dynamic-require, global-require
                      require(`../../../../shared/assets/images/ToothStatusIcon/${legend}IconB&W.svg`)
                    : // eslint-disable-next-line import/no-dynamic-require, global-require
                      require(`../../../../shared/assets/images/ToothStatusIcon/${legend}IconColored.svg`)
                }
                alt={`${legend}`}
              />
              <span>{formatMessage(toothTypeMessages[legend])}</span>
            </span>
          </li>
        ))}
    </ul>
  );
};
