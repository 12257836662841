import React, { ChangeEvent, CSSProperties, ReactNode } from 'react';
import cn from 'classnames';

import { RadioVariant } from '../RadioGroup.types';

import styles from './Radio.module.scss';

export type RadioProps = {
  children: ReactNode;
  variant?: RadioVariant;
  value?: string | number;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  checked?: boolean;
  className?: string;
  testID?: string;
  style?: CSSProperties;
  disabled?: boolean;
  error?: boolean;
};

const InternalRadio: React.ForwardRefRenderFunction<
  HTMLInputElement,
  RadioProps
> = (props, ref) => {
  const {
    children,
    variant = 'default',
    value,
    name,
    onChange,
    checked,
    className,
    testID,
    style,
    disabled,
    error,
    ...restBaseInputProps
  } = props;

  return (
    <label
      style={style}
      className={cn(
        styles.container,
        variant === 'default' && styles.variantDefault,
        variant === 'tab' && styles.variantTab,
        disabled && styles.disabled,
        error && styles.isError,
        'p2',
        className,
      )}
      data-testid={testID}
    >
      <input
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...restBaseInputProps}
        ref={ref}
        value={value}
        type="radio"
        checked={checked}
        onChange={onChange}
        className={styles.input}
        disabled={disabled}
        name={name}
      />
      {variant === 'default' && (
        <>
          <span className={styles.defaultRadio} />
          {children !== undefined ? (
            <span className={styles.label}>{children}</span>
          ) : null}
        </>
      )}

      {variant === 'tab' && <span className={styles.tabRadio}>{children}</span>}
    </label>
  );
};

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  InternalRadio,
);
