// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CongratsModal-module__modalBody--oDdhkWCd{padding:8px 32px 32px}.CongratsModal-module__container--5RfYND\\+S{display:flex;flex-direction:column;align-items:center;gap:24px}.CongratsModal-module__message--dh36Kags{text-align:center}", "",{"version":3,"sources":["webpack://./src/widgets/CongratsModal/ui/CongratsModal/CongratsModal.module.scss"],"names":[],"mappings":"AAAA,2CACE,qBAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,QAAA,CAGF,yCACE,iBAAA","sourcesContent":[".modalBody {\n  padding: 8px 32px 32px;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 24px;\n}\n\n.message {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "CongratsModal-module__modalBody--oDdhkWCd",
	"container": "CongratsModal-module__container--5RfYND+S",
	"message": "CongratsModal-module__message--dh36Kags"
};
export default ___CSS_LOADER_EXPORT___;
