import { FC, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { filter } from 'ramda';

import { useAppSelector } from '@/shared/hooks';
import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { DentalNotationFormat } from '@/shared/api/protocol_gen/model/dto_organization';
import { AbsoluteBlock } from '@/shared/graphics/RenderComponents/AbsoluteBlock';
import { RCDetectedTeeth } from '@/shared/graphics/RenderComponents/RCDetectedTeeth/RCDetectedTeeth';
import { RCLocalizations } from '@/shared/graphics/RenderComponents/RCLocalizations/RCLocalizations';

import { protocolLocalizationToBBox, reportsModel } from '@/entities/reports';
import { assetsModel, getFileSrc, getImageSrc } from '@/entities/assets';
import {
  isDuplicate,
  ToothWithLocalization,
  transformBBoxToCrop,
} from '@/entities/tooth';
import {
  conditionModel,
  getConditionCodeColor,
  isConditionUncertain,
  shouldConditionItemBeShown,
} from '@/entities/condition';
import { logicalConditionModel } from '@/entities/logicalCondition';

import {
  doBBoxOverlap,
  groupIOXRayImagesByPartition,
} from '@/features/IOXRayMatrix';
import { useSubImageTeethLandmarks } from '@/features/toothLandmark';

import { IOXRAY_MATRIX_PADDINGS } from '../../config/constants';
import { getMatrixMaxHeight } from '../../lib/getMaxRowHeight';
import { getMatrixMaxWidth } from '../../lib/getMatrixMaxWidth';
import { getTeethIDsFromSubImages } from '../../lib/getTeethIDsFromSubImages';
import { IOXRaySubImage } from '../IOXRaySubImage/IOXRaySubImage';
import { SubImageInterface, SubImageMask } from '../../config/types';

import styles from './IOXRayMatrix.module.scss';

type FmxMatrixProps = {
  subImages: SubImageInterface[];
  assetIDs?: string[];
  className?: string;
  reportID?: string;
  studyID?: string;
  onToothClick?: (tooth: ToothWithLocalization) => void;
  onSubImageClick?: (subImage: SubImageInterface) => void;
  showDetectedTeeth?: boolean;
  onDetectDuplicateToothNumber?: (isInvalid: boolean) => void;
  dentalNotationFormat?: DentalNotationFormat;
  showMasks: boolean;
  activeSubImages?: SubImageInterface[];
  showLandmarks?: boolean;
  isPreviewMode?: boolean;
  containerSizes?: {
    width: number;
    height: number;
  };
};

// TODO: It seems that this matrix belongs to the feature IOXRayMatrix
export const IOXRayMatrix: FC<FmxMatrixProps> = (props) => {
  const {
    subImages,
    assetIDs,
    className,
    reportID = '',
    studyID = '',
    onToothClick,
    onSubImageClick,
    showDetectedTeeth,
    onDetectDuplicateToothNumber,
    dentalNotationFormat,
    showMasks,
    activeSubImages,
    showLandmarks = false,
    isPreviewMode = false, // TODO should be remove after using previews photos
    containerSizes,
  } = props;

  const allConditions = useAppSelector(conditionModel.selectors.selectAll);

  const disabledSubImagesIDs = useAppSelector(
    reportsModel.selectors.selectDisabledSubImagesIDs,
  );

  const mode = useAppSelector(reportsModel.selectors.selectToolsMode);

  const conditionsToBeShown = useMemo(
    () => filter(shouldConditionItemBeShown(true), allConditions),
    [allConditions],
  );

  const allMasksAssetIDs = conditionsToBeShown.reduce((masksIDs, condition) => {
    if (condition.Localizations.length) {
      const localizationMaskIDs = condition.Localizations.filter(
        (localization) => localization.MaskAssetID,
      ).map((localization) => localization.MaskAssetID);
      return [...masksIDs, ...localizationMaskIDs];
    }

    return masksIDs;
  }, [] as string[]);

  const masksAssets = useAppSelector(
    assetsModel.selectors.selectAssetsByIDs(allMasksAssetIDs),
  );

  const { toothID, code, parentID } = useAppSelector(
    logicalConditionModel.selectors.selectHoveredConditionLink,
  );

  const hoveredCondition = useAppSelector(
    logicalConditionModel.selectors.selectCondition({
      toothID,
      code,
      parentID,
    }),
  );

  const teethIDs = getTeethIDsFromSubImages(subImages);

  const { getPBLsForSubImage } = useSubImageTeethLandmarks(teethIDs);

  const conditionColor = getConditionCodeColor(
    hoveredCondition?.Code,
    isConditionUncertain(hoveredCondition),
    true,
  );

  const allMasksWithColors: SubImageMask[] = masksAssets.map((maskAsset) => {
    const maskCondition = conditionsToBeShown.find((condition) =>
      condition.Localizations.find(
        (localization) => localization.MaskAssetID === maskAsset.ID,
      ),
    );

    return {
      subImageID: maskCondition.Localizations[0].TargetAssetID,
      kind: 'raster-mask',
      url: getFileSrc(maskAsset?.GeneratedReport.Mask2D.Mask?.ID ?? ''),
      condition: maskCondition,
    };
  });

  const subImagesMasks: Record<string, SubImageMask[]> = subImages?.reduce(
    (subImagesMasksAcc, subImage) => {
      const subImageMasks = allMasksWithColors.filter(
        (mask) => mask.subImageID === subImage.TargetAssetID,
      );

      return { ...subImagesMasksAcc, [subImage.ID]: subImageMasks };
    },
    {},
  );

  const imageWrapperRef = useRef<HTMLDivElement>(null);

  const reports: Report[] = useAppSelector(
    reportsModel.selectors.selectByStudyIDAndType(
      studyID,
      ReportType.ReportType_IOXRay_GP,
    ),
  );

  const selectReport = useMemo(
    () => reportsModel.selectors.selectByID(reportID),
    [reportID],
  );
  const currentReport = useAppSelector(selectReport);

  const lastCompletedReport =
    reports?.find((report) => report?.Status.Completed) ?? reports[0];

  const isReportStatusComplete =
    reports?.length > 0 && !reportID
      ? lastCompletedReport?.Status.Completed
      : currentReport?.Status.Completed;

  const groupedSubImages = useMemo(
    () => groupIOXRayImagesByPartition(subImages),
    [subImages],
  );

  const calculateScale = () => {
    const { width: wrapperWidth, height: wrapperHeight } = containerSizes;

    if (!wrapperWidth && !wrapperHeight) return 0;

    if (
      Object.values(groupedSubImages).every(
        (partition) => partition.length === 0,
      )
    ) {
      return 0;
    }

    const IOXRaysOriginalWidth = getMatrixMaxWidth(groupedSubImages);
    const IOXRaysOriginalHeight = getMatrixMaxHeight(groupedSubImages);

    const scaleByWidth =
      (wrapperWidth - IOXRAY_MATRIX_PADDINGS) / IOXRaysOriginalWidth;
    const scaleByHeight =
      (wrapperHeight - IOXRAY_MATRIX_PADDINGS) / IOXRaysOriginalHeight;

    const IOXRaysOriginalAspect = IOXRaysOriginalHeight / IOXRaysOriginalWidth;

    const wrapperAspect = wrapperHeight / wrapperWidth;

    if (IOXRaysOriginalAspect >= wrapperAspect) {
      return scaleByHeight;
    }

    return scaleByWidth;
  };

  const scale = calculateScale();

  const handleSubImageClick = (subImage: SubImageInterface) => {
    if (!disabledSubImagesIDs?.includes(subImage.ID) && mode === 'view') {
      onSubImageClick(subImage);
    }
  };

  const canZoomSubImage = (subImageID: string) =>
    onSubImageClick &&
    !disabledSubImagesIDs?.includes(subImageID) &&
    mode === 'view';

  useEffect(() => {
    const hasDuplicates = subImages?.some((subImage) =>
      subImage.teethLocalizations.some((tooth) =>
        isDuplicate(subImage.teethLocalizations, tooth),
      ),
    );

    if (onDetectDuplicateToothNumber) {
      onDetectDuplicateToothNumber(hasDuplicates);
    }
  }, [subImages]);

  return !isReportStatusComplete ? (
    <div
      className={cn(
        styles.withoutReport,
        (assetIDs?.length ?? 0) > 1 && styles.gridContainerWithAutoFlow,
      )}
    >
      {assetIDs?.map((assetID) => (
        <img
          key={assetID}
          src={getImageSrc(assetID, 'original')}
          alt={`IOXRay ${assetID}`}
        />
      ))}
    </div>
  ) : (
    <div className={cn(styles.container, className)}>
      {scale > 0 &&
        Object.entries(groupedSubImages).map(([group, subImageInterface]) => (
          <div className={styles[group]} key={group}>
            {subImageInterface.map((subImage) => {
              const imageWidth =
                (subImage?.BBox?.X?.Max ?? 0) - (subImage?.BBox?.X?.Min ?? 0);
              const imageHeight =
                (subImage?.BBox?.Y?.Max ?? 0) - (subImage?.BBox?.Y?.Min ?? 0);

              const localizations = subImage.teethLocalizations.flatMap(
                (tooth) => {
                  const {
                    Localization: { BBox },
                  } = tooth;

                  return {
                    tooth,
                    xmin: BBox?.X?.Min ?? 0,
                    ymin: BBox?.Y?.Min ?? 0,
                    xmax: BBox?.X?.Max ?? 0,
                    ymax: BBox?.Y?.Max ?? 0,
                  };
                },
              );

              const hoveredConditionsInSubImage =
                hoveredCondition?.Localizations.filter(
                  (localization) =>
                    localization.TargetAssetID === subImage.TargetAssetID &&
                    doBBoxOverlap(subImage?.BBox, localization?.BBox),
                );

              const subImageBboxes =
                hoveredConditionsInSubImage?.length &&
                hoveredConditionsInSubImage.map(protocolLocalizationToBBox);

              const subImageIsActive = activeSubImages?.some(
                (activeSubImage) => activeSubImage.ID === subImage.ID,
              );

              return (
                <div
                  key={subImage.ID}
                  id={subImage.ID}
                  ref={imageWrapperRef}
                  className={cn(
                    styles.imageWrapper,
                    canZoomSubImage(subImage.ID) && styles.subImageZoom,
                    subImageIsActive && styles.activeSubImage,
                  )}
                >
                  <IOXRaySubImage
                    subImage={subImage}
                    scale={scale}
                    disabled={disabledSubImagesIDs?.includes(subImage.ID)}
                    masks={showMasks ? subImagesMasks[subImage.ID] : []}
                    onClick={handleSubImageClick}
                    landmarks={
                      showLandmarks
                        ? getPBLsForSubImage(subImage.TargetAssetID)
                        : []
                    }
                    reportID={reportID}
                    pixelScale={subImage.Scale}
                    isPreviewMode={isPreviewMode}
                  />

                  {showDetectedTeeth && (
                    <AbsoluteBlock style={{ top: 0, left: 0 }}>
                      <RCDetectedTeeth
                        localizations={localizations}
                        imageSize={{ width: imageWidth, height: imageHeight }}
                        onToothClick={onToothClick}
                        dentalNotationFormat={
                          dentalNotationFormat ||
                          DentalNotationFormat.DentalNotationFormatISO
                        }
                        crop={{
                          x: subImage?.BBox?.X?.Min ?? 0,
                          y: subImage?.BBox?.Y?.Min ?? 0,
                          w: imageWidth,
                          h: imageHeight,
                        }}
                      />
                    </AbsoluteBlock>
                  )}

                  {!isPreviewMode && subImageBboxes?.length && (
                    <AbsoluteBlock style={{ left: 0, top: 0, zIndex: 60 }}>
                      <RCLocalizations
                        detections={subImageBboxes}
                        imageSize={{
                          width: imageWidth,
                          height: imageHeight,
                        }}
                        crop={transformBBoxToCrop(subImage.BBox)}
                        color={conditionColor}
                        borderRadius={8}
                      />
                    </AbsoluteBlock>
                  )}
                </div>
              );
            })}
          </div>
        ))}
    </div>
  );
};
