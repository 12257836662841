// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BillingInformation-module__container--lU90sKjZ{display:flex;flex-direction:column;padding:24px;background-color:var(--white);border-radius:12px;gap:24px}.BillingInformation-module__header--KWQRk8g0{width:100%;display:flex;justify-content:space-between}.BillingInformation-module__innerContainer--6yF0GR68{display:flex;flex-direction:column;gap:12px}.BillingInformation-module__description--2rU3C9GT{width:100%;display:grid;grid-template-columns:1.2fr 3fr}", "",{"version":3,"sources":["webpack://./src/features/billingInformation/ui/BillingInformation.module.scss"],"names":[],"mappings":"AAAA,gDACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,QAAA,CAGF,6CACE,UAAA,CACA,YAAA,CACA,6BAAA,CAGF,qDACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,kDACE,UAAA,CACA,YAAA,CACA,+BAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  padding: 24px;\n  background-color: var(--white);\n  border-radius: 12px;\n  gap: 24px;\n}\n\n.header {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n}\n\n.innerContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.description {\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1.2fr 3fr;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BillingInformation-module__container--lU90sKjZ",
	"header": "BillingInformation-module__header--KWQRk8g0",
	"innerContainer": "BillingInformation-module__innerContainer--6yF0GR68",
	"description": "BillingInformation-module__description--2rU3C9GT"
};
export default ___CSS_LOADER_EXPORT___;
