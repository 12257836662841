import { OrganizationJobPosition } from '@/shared/api/protocol_gen/model/dto_organization';

interface SpecializationOptionInterface {
  label: string;
  value: OrganizationJobPosition;
}

export const sortSpecializationOptions = (
  a: SpecializationOptionInterface,
  b: SpecializationOptionInterface,
) => {
  if (a.value === OrganizationJobPosition.OrganizationJobPositionOther) {
    return 1;
  }
  if (b.value === OrganizationJobPosition.OrganizationJobPositionOther) {
    return -1;
  }
  return a.label.localeCompare(b.label);
};
