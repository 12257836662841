import { FC, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { isEqual } from 'lodash';

import { Localization } from '@/shared/api/protocol_gen/model/dto_report_localization';
import { AbsoluteBlock } from '@/shared/graphics/RenderComponents/AbsoluteBlock';
import { RCLocalizations } from '@/shared/graphics/RenderComponents/RCLocalizations/RCLocalizations';
import { RCContainer } from '@/shared/graphics/RenderComponents/RCContainer/RCContainer';
import {
  RCCropImage,
  RCCropImageCrop,
} from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImage';
import { MedicalImageInterface } from '@/shared/config';
import {
  useAppDispatch,
  useAppSelector,
  useDebouncedFunction,
} from '@/shared/hooks';
import { Skeleton } from '@/shared/ui';
import { RCCropImageWithPTools } from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImageWithTools';
import {
  BBox,
  FloatTuple2D,
} from '@/shared/api/protocol_gen/model/dto_common_geometry';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import {
  PBLMeasurement,
  RCPBL,
} from '@/shared/graphics/RenderComponents/RCPBL/RCPBL';

import { ModalID, modalModel } from '@/entities/modal';
import { getImageSrc } from '@/entities/assets';
import {
  getPresetByMaskType,
  protocolLocalizationToBBox,
  reportsModel,
  transformViewOptions,
  useMedicalImageControls,
} from '@/entities/reports';
import {
  getConditionCodeColor,
  isConditionUncertain,
} from '@/entities/condition';
import { transformBBoxToCrop } from '@/entities/tooth';

import { Landmark } from '@/features/toothLandmark';

import { SubImageMask } from '@/widgets/IOXRayMatrix';

import { ContextRequest } from 'graphics';

import { getCroppedImageSizes } from '../../lib/getCroppedImageSizes';

import styles from './IOXRayToothImage.module.scss';

type FMXImagsProps = {
  className?: string;
  croppedImage: MedicalImageInterface;
  hoveredCondition: Condition;
  actionsIsDisabled?: boolean;
  imageWidth?: number;
  minScale: number;
  allMasksWithColors: SubImageMask[];
  toothLocalizations: Localization[];
  landmarks?: Landmark[];
  pixelScale?: FloatTuple2D;
};

export const IOXRayToothImage: FC<FMXImagsProps> = (props) => {
  const {
    className,
    croppedImage,
    hoveredCondition,
    actionsIsDisabled = false,
    imageWidth,
    minScale,
    allMasksWithColors,
    toothLocalizations,
    landmarks,
    pixelScale,
  } = props;

  const showMasks = useAppSelector(reportsModel.selectors.selectShowMasks);
  const controlMode = useAppSelector(reportsModel.selectors.selectToolsMode);
  const activeControl = useAppSelector(
    reportsModel.selectors.selectToolbarActiveControl,
  );
  const lastUpdatedCropID = useAppSelector(
    reportsModel.selectors.selectLastUpdatedCropID,
  );

  const imageHeight = transformBBoxToCrop(croppedImage.BBox).h * minScale;
  const masks = showMasks
    ? allMasksWithColors.filter((mask) =>
        mask.croppedImageIDs.includes(croppedImage.id),
      )
    : [];

  const toothLocalization = toothLocalizations.find(
    (localization) => localization.ID === croppedImage.localizationID,
  );

  const [isImageReady, setIsImageReady] = useState(false);

  const dispatch = useAppDispatch();

  const maskType = useAppSelector(reportsModel.selectors.selectMaskStyle);

  const handleOpenModal = useCallback(
    (medicalImage: MedicalImageInterface) => {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.ZoomedMedicalImage,
          data: { medicalImage, medicalImageType: 'cropped' },
        }),
      );
    },
    [dispatch],
  );

  // TODO: Refactor for cropped tooth images
  const { BBox: bbox, TargetAssetID } = toothLocalization;
  const shouldShowLocalizations = false;

  const subImageSrc: ContextRequest = {
    url: getImageSrc(TargetAssetID, 'original'),
    kind: 'raster',
  };

  const crop: RCCropImageCrop = transformBBoxToCrop(bbox as BBox);

  const toothLocalizationBBox = [
    {
      xmin: bbox?.X?.Min ?? 0,
      ymin: bbox?.Y?.Min ?? 0,
      xmax: bbox?.X?.Max ?? 0,
      ymax: bbox?.Y?.Max ?? 0,
    },
  ];

  const hoveredConditionBBox = hoveredCondition?.Localizations?.map(
    protocolLocalizationToBBox,
  );

  const conditionColor = getConditionCodeColor(
    hoveredCondition?.Code,
    isConditionUncertain(hoveredCondition),
    true,
  );

  const onDicomRendered = () => {
    setIsImageReady(true);
  };

  const componentSize = getCroppedImageSizes({
    crop,
    fixHeight: imageHeight,
    fixWidth: imageWidth,
  });

  const subImageCrop: RCCropImageCrop = transformBBoxToCrop(bbox as BBox);

  const {
    wwwc,
    sharpness,
    setSharpness,
    setWWWC,
    handleSetCroppedMedicalImageViewOptions,
  } = useMedicalImageControls(
    croppedImage.src,
    croppedImage.assetID,
    croppedImage.viewOptions,
  );

  const delayedChangedCroppedImageViewOptions = useDebouncedFunction(
    handleSetCroppedMedicalImageViewOptions,
    900,
  );

  useEffect(() => {
    if (
      croppedImage.viewOptions &&
      !isEqual(croppedImage.viewOptions, {
        wwwc,
        sharpness,
        invert: false,
      })
    ) {
      const updatedViewOptions = transformViewOptions({
        wwwc,
        sharpness,
        invert: false,
      });

      dispatch(reportsModel.actions.setLastUpdatedCropID(croppedImage.id));

      delayedChangedCroppedImageViewOptions(
        croppedImage.id,
        updatedViewOptions,
      );
    }
  }, [wwwc.ww, wwwc.wc, sharpness]);

  useEffect(() => {
    if (activeControl === 'reset' && lastUpdatedCropID === croppedImage.id) {
      const initialViewOptions = transformViewOptions({
        wwwc: { ww: 0, wc: 0 },
        sharpness: 0,
        invert: false,
      });

      handleSetCroppedMedicalImageViewOptions(
        lastUpdatedCropID,
        initialViewOptions,
      );
    }
  }, [activeControl, croppedImage.id, lastUpdatedCropID]);

  const getPBL = (landmark: Landmark): PBLMeasurement => ({
    color: landmark.color,
    x1: landmark.lowPoint?.ModelPosition.X,
    x2: landmark.upPoint?.ModelPosition.X,
    y1: landmark.lowPoint?.ModelPosition.Y,
    y2: landmark.upPoint?.ModelPosition.Y,
  });

  const pbls = landmarks?.map((landmark) => getPBL(landmark)) ?? [];

  const pixelSpacing = [pixelScale?.X, pixelScale?.Y] as [number, number];

  return (
    <div
      onClick={() =>
        isImageReady && !actionsIsDisabled && handleOpenModal(croppedImage)
      }
    >
      <RCContainer
        style={{ width: componentSize.width, height: componentSize.height }}
        className={cn(
          className,
          styles.container,
          !actionsIsDisabled && styles.highlightBorder,
        )}
      >
        <AbsoluteBlock style={{ zIndex: 4 }}>
          {!isImageReady && (
            <Skeleton
              width="100%"
              borderRadius="12px"
              height="100%"
              className={styles.panoImageWidgetSkeleton}
            />
          )}

          <RCCropImageWithPTools
            mode={controlMode}
            currentAnnotation="eraser"
            annotations={croppedImage.annotations || []}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            onLoaded={() => {
              onDicomRendered();
            }}
            ww={wwwc.ww ?? 0}
            wc={wwwc.wc ?? 0}
            onSharpnessChanged={(currentSharpness) =>
              setSharpness(currentSharpness)
            }
            onWWWCChanged={(updatedWwwc) => setWWWC(updatedWwwc)}
            sharpness={sharpness ?? 0}
            inverted={croppedImage?.viewOptions?.invert ?? false}
            src={subImageSrc}
            crop={subImageCrop}
          />
        </AbsoluteBlock>

        {shouldShowLocalizations && isImageReady && (
          <AbsoluteBlock style={{ zIndex: 1 }}>
            <RCLocalizations
              detections={toothLocalizationBBox}
              crop={crop}
              imageSize={{
                width: crop.w,
                height: crop.h,
              }}
            />
          </AbsoluteBlock>
        )}

        {masks && (
          <AbsoluteBlock
            className={cn(
              styles.masksContainer,
              controlMode !== 'view' && styles.pointerEventsNone,
            )}
            style={{ top: 0, left: 0, zIndex: 5 }}
            data-id="MedicalImage Mask"
          >
            {masks.map((mask) => (
              <RCCropImage
                key={mask.url}
                src={{
                  url: mask.url,
                  kind: mask.kind,
                  credentials: 'include', // TODO PBL_INTEGRATION Set correct credentials for url
                }}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  mixBlendMode: 'multiply',
                  ...getPresetByMaskType(maskType)[
                    getConditionCodeColor(mask.condition.Code, true)
                  ].style,
                }}
                uniforms={{
                  // change mask color here, R, G, B from 0 to 1
                  color: getPresetByMaskType(maskType)[
                    getConditionCodeColor(mask.condition.Code, true)
                  ].color as [number, number, number],
                  opacity:
                    getPresetByMaskType(maskType)[
                      getConditionCodeColor(mask.condition.Code, true)
                    ].opacity,
                }}
                crop={crop}
              />
            ))}
          </AbsoluteBlock>
        )}

        <AbsoluteBlock
          style={{ left: 0, top: 0, zIndex: hoveredCondition ? 4 : 1 }}
          className={cn(controlMode !== 'view' && styles.pointerEventsNone)}
        >
          <RCLocalizations
            detections={hoveredConditionBBox ?? []}
            imageSize={{
              width: crop.w,
              height: crop.h,
            }}
            crop={subImageCrop}
            color={conditionColor}
            isNonDentalFindings
          />
        </AbsoluteBlock>

        <AbsoluteBlock
          className={cn(controlMode !== 'view' && styles.pointerEventsNone)}
          style={{ top: 0, left: 0, zIndex: 8 }}
        >
          <RCPBL measurements={pbls} crop={crop} pixelSpacing={pixelSpacing} />
        </AbsoluteBlock>
      </RCContainer>
    </div>
  );
};
