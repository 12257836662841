import { merge } from 'lodash';

import { Decision } from '@/shared/api/protocol_gen/model/dto_report_common';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';
import { ToothStatus } from '@/shared/config';

import { getCondtionStatus, shouldConditionItemBeShown } from '../condition';

import { LogicalCondition } from './config';
import { ToothCondition } from './model/logicalConditionSlice';

export const mergeLogicalConditions = (
  conditionA: LogicalCondition,
  conditionB: LogicalCondition,
): LogicalCondition => {
  const {
    Certainty: { ModelScore: modelScoreA },
  } = conditionA;
  const {
    Certainty: { ModelScore: modelScoreB },
  } = conditionB;

  if (modelScoreA >= modelScoreB) {
    return merge(conditionB, conditionA);
    //   ...conditionA,
    //   Localizations: conditionA.Localizations.concat(conditionB.Localizations),
    //   IDs: uniq(conditionA.IDs.concat(conditionB.IDs)),
    // };
  }

  return merge(conditionA, conditionB);

  // return {
  //   ...conditionB,
  //   Localizations: conditionB.Localizations.concat(conditionA.Localizations),
  //   IDs: uniq(conditionB.IDs.concat(conditionA.IDs)),
  // };
};

// Use it in the selector to iterate over each tooth conditions
export const getToothStatus = (toothConditions: ToothCondition) => {
  const missingConditions = toothConditions.conditions[ConditionCode.Missing];
  const missingCondition = Object.values(missingConditions || {})?.[0];

  if (
    missingCondition &&
    missingCondition.Certainty?.EngineDecision === Decision.PositiveDecision
  ) {
    return ToothStatus.missing;
  }

  const conditionStatuses = new Map<ToothStatus, boolean>();

  Object.values(toothConditions.conditions).forEach((conditions) => {
    // Can we ignore child conditions to find correct status?
    const condition = Object.values(conditions)?.[0];

    if (shouldConditionItemBeShown(true)(condition)) {
      const conditionStatus = getCondtionStatus(condition);

      conditionStatuses.set(conditionStatus, true);
    }
  });

  if (conditionStatuses.get(ToothStatus.lowProbability)) {
    return ToothStatus.lowProbability;
  }

  if (conditionStatuses.get(ToothStatus.unhealthy)) {
    return ToothStatus.unhealthy;
  }

  if (conditionStatuses.get(ToothStatus.treated)) {
    return ToothStatus.treated;
  }

  return ToothStatus.healthy;
};
