import { FC } from 'react';
import cn from 'classnames';

import styles from './Chip.module.scss';

type ChipProps = {
  className?: string;
  testID?: string;
  label: string;
};

export const Chip: FC<ChipProps> = (props) => {
  const { className, testID, label } = props;

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <p className={cn('p2', styles.label)}>{label}</p>
    </div>
  );
};
