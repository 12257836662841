import {
  BillingZones,
  CIS_TIER_3_COUNTRIES,
  EU_DACH_COUNTRIES,
  EU_ISRAEL_COUNTRIES,
  EU_TIER_1_COUNTRIES,
  EU_TIER_2_COUNTRIES,
  EU_TIER_3_COUNTRIES,
  OCEANIA_AU_COUNTRIES,
  OCEANIA_NZ_COUNTRIES,
  US_CA_COUNTRIES,
  US_IRB_COUNTRIES,
  US_TIER_2_COUNTRIES,
} from '../config';

// TODO: [1/l] In the future, the backend promises to add a method for obtaining the billing zone by country
export const getBillingZoneByCountry = (country: string) => {
  if (EU_TIER_1_COUNTRIES.includes(country)) {
    return BillingZones['EU-TIER-1'];
  }

  if (EU_TIER_2_COUNTRIES.includes(country)) {
    return BillingZones['EU-TIER-2'];
  }

  if (EU_TIER_3_COUNTRIES.includes(country)) {
    return BillingZones['EU-TIER-3'];
  }

  if (EU_DACH_COUNTRIES.includes(country)) {
    return BillingZones['EU-DACH'];
  }

  if (EU_ISRAEL_COUNTRIES.includes(country)) {
    return BillingZones['EU-ISRAEL'];
  }

  if (US_CA_COUNTRIES.includes(country)) {
    return BillingZones['US-CA'];
  }

  if (US_IRB_COUNTRIES.includes(country)) {
    return BillingZones['US-IRB'];
  }

  if (US_TIER_2_COUNTRIES.includes(country)) {
    return BillingZones['US-TIER-2'];
  }

  if (OCEANIA_AU_COUNTRIES.includes(country)) {
    return BillingZones['OCEANIA-AU'];
  }

  if (OCEANIA_NZ_COUNTRIES.includes(country)) {
    return BillingZones['OCEANIA-NZ'];
  }

  if (CIS_TIER_3_COUNTRIES.includes(country)) {
    return BillingZones['CIS-TIER-3'];
  }

  return undefined;
};
