// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewPassword-module__form--MdRsk-Ar{display:grid;gap:8px}.NewPassword-module__form--MdRsk-Ar>div{margin:0}.NewPassword-module__modalBody--2QRw8mht{padding:24px}", "",{"version":3,"sources":["webpack://./src/pages/NewPassword/NewPassword.module.scss"],"names":[],"mappings":"AAAA,oCACE,YAAA,CACA,OAAA,CAEA,wCACE,QAAA,CAIJ,yCACE,YAAA","sourcesContent":[".form {\n  display: grid;\n  gap: 8px;\n\n  > div {\n    margin: 0;\n  }\n}\n\n.modalBody {\n  padding: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "NewPassword-module__form--MdRsk-Ar",
	"modalBody": "NewPassword-module__modalBody--2QRw8mht"
};
export default ___CSS_LOADER_EXPORT___;
