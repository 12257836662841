import { ascend, filter, pathOr, prop, sortWith } from 'ramda';
import { useIntl } from 'react-intl';

import { SelectProps } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { getUserFullName } from '@/shared/lib';
import { OrganizationUserRole } from '@/shared/api/protocol_gen/model/dto_organization';

import { organizationModel } from '@/entities/organization';

import { doesNotIncludeRoleNonClinicalMember } from '../lib/doesNotIncludeRoleNonClinicalMember';

export const useOrganizationUsersOptions = (): SelectProps['options'] => {
  const { formatMessage } = useIntl();

  const allUsers =
    useAppSelector(organizationModel.selectors.selectAllUsers) || [];

  const filteredUsers = filter(doesNotIncludeRoleNonClinicalMember, allUsers);

  const sortedUsers: OrganizationUserRole[] = sortWith<OrganizationUserRole>(
    [
      ascend(pathOr('', ['PersonalData', 'LastName'])),
      ascend(pathOr('', ['PersonalData', 'FirstName'])),
      ascend(prop('UserID')),
    ],
    filteredUsers,
  );

  const options = sortedUsers.map((user) => ({
    value: user?.UserID,
    label: getUserFullName(user),
  }));

  return [
    {
      label: formatMessage({ id: 'global.all', defaultMessage: 'All' }),
      value: 'all',
    },
    ...options,
  ];
};
