import React, { FC, memo, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { RCContainerStyle } from '@/shared/graphics/RenderComponents/RCContainer/RCContainer';

import styles from './SelectedMedicalImages.module.scss';

type SelectedMedicalImagesProps = {
  onDrop?: () => void;
  className?: string;
  style?: RCContainerStyle;
  dragged?: boolean;
  groupName?: string | number;
};

// Simplified for tooth card component as dnd placeholder
export const SelectedMedicalImages: FC<SelectedMedicalImagesProps> = memo(
  (props) => {
    const { className, onDrop, dragged, style, groupName } = props;

    const [dragOvered, setDragOvered] = useState(false);

    const handleDragOver = (event: React.MouseEvent) => {
      event.preventDefault();
      setDragOvered(true);
    };

    const handleDragLeave = (event: React.MouseEvent) => {
      event.preventDefault();
      setDragOvered(false);
    };

    const handleDrop = (event: React.MouseEvent) => {
      event.preventDefault();
      if (onDrop) {
        onDrop();
      }
      setDragOvered(false);
    };

    return (
      <div
        data-group={groupName}
        style={style}
        className={cn(
          className,
          styles.container,
          dragged && styles.dragged,
          dragOvered && styles.dragOvered,
        )}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <p>
          <FormattedMessage
            id="selectedMedicalImages.dragDropPhotos"
            defaultMessage="Drag or add slices or photos to report"
          />
        </p>
      </div>
    );
  },
);
