import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Skeleton } from '@/shared/ui';
import { useMedia } from '@/shared/hooks';
import { Report } from '@/shared/api/protocol_gen/model/dto_report';

import { i18n } from '@/entities/reports';

import styles from './ReportHeading.module.scss';

type ReportProps = {
  report: Report;
  isReportLoading?: boolean;
  smallHeader?: boolean;
  className?: string;
};

export const ReportHeading: FC<ReportProps> = (props) => {
  const {
    report,
    isReportLoading = true,
    smallHeader = false,
    className,
  } = props;

  const { formatMessage } = useIntl();

  const { isMobile } = useMedia();

  if (!report) {
    return null;
  }

  return (
    <div className={cn(styles.container, className)}>
      <header className={styles.header}>
        {isReportLoading ? (
          <Skeleton width="75%" borderRadius="42px" height="87px" />
        ) : (
          <h1 className={cn(isMobile || smallHeader ? 'h2' : 'h1')}>
            {formatMessage(i18n[report.Type])}
          </h1>
        )}
      </header>
    </div>
  );
};
