import { FormattedMessage } from 'react-intl';
import { InferType, object, string, ref } from 'yup';

const regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!?'",_]{8,}$/;

export const newPasswordFormSchema = object({
  password: string()
    .required()
    .test('password', (value) => regexp.test(value || '')),
  confirmPassword: string()
    .oneOf([ref('password')], () => (
      <FormattedMessage
        id="signUp.passwordsMustMatch"
        defaultMessage="Passwords must match"
      />
    ))
    .required(),
});

export type NewPasswordFormPayload = InferType<typeof newPasswordFormSchema>;
