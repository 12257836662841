import { Report } from '@/shared/api/protocol_gen/model/dto_report';
import { useAppSelector } from '@/shared/hooks';

import { conditionModel } from '@/entities/condition';
import { reportsModel } from '@/entities/reports';
import { toothLandmarksModel } from '@/entities/toothLandmarks';

import {
  PblConditionWithChildren,
  PBLLandmark,
  DEFAULT_PIXEL_SCALE,
} from '../config';
import { filterPblConditions } from '../lib/filterPblConditions';
import { getPblLandmarksFromConditions } from '../lib/getPblLandmarksFromConditions';

export const useToothLandmarks = (reportID: string, toothID: string) => {
  const toothLandmarks = useAppSelector(
    toothLandmarksModel.selectors.selectByToothID(toothID),
  );
  const toothConditions = useAppSelector(
    conditionModel.selectors.selectByToothID(toothID),
  );

  const report: Report = useAppSelector(
    reportsModel.selectors.selectByID(reportID),
  );

  const pblConditions = filterPblConditions(toothConditions);

  const pblConditionsWithChildren: PblConditionWithChildren[] =
    pblConditions.map((condition) => {
      const conditionChildren = toothConditions.filter(
        (childCondition) =>
          childCondition.ParentID && childCondition.ParentID === condition.ID,
      );
      return { ...condition, children: conditionChildren };
    });

  const pblLandmarks: PBLLandmark[] = getPblLandmarksFromConditions(
    pblConditionsWithChildren,
    toothLandmarks,
  );

  const pixelScale =
    report?.DataIOXRayGP?.Subimages.find(
      (subImage) =>
        subImage.TargetAssetID === pblLandmarks[0]?.subImageTargetAssetID,
    )?.Scale ?? DEFAULT_PIXEL_SCALE;

  return {
    pblLandmarks,
    pixelScale,
  };
};
