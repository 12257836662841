import { FC, useCallback } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button, ToothViewClick } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { scrollToElementByID } from '@/shared/lib';

import {
  TeethChartFilters,
  ToothChartLegends,
  ToothType,
  toothModel,
} from '@/entities/tooth';
import { reportsModel } from '@/entities/reports';
import { ModalID, modalModel } from '@/entities/modal';

import {
  TeethChart,
  useTeethChart,
  useTeethChartROIFilters,
  useToothTransform,
} from '@/features/toothChart';
import { EditToothNumber } from '@/features/editToothNumber';

import styles from './ReportToothChart.module.scss';

type ReportToothChartProps = {
  // TODO: remove optional operator
  image?: {
    src: string;
    viewOptions?: {
      sharpness: number;
      invert: boolean;
      wwwc: { ww: number; wc: number };
    };
    path: string;
  };
  reportID: string;
  teethNumberingDisabled?: boolean; // TODO: refactor props
  className?: string;
  onToothClick?: (ID: string) => void;
  showActions?: boolean;
};

const toothChartFilters: ToothType[] = [
  'unhealthy',
  'lowProbability',
  'treated',
  'healthy',
  'missing',
  'all',
];

const toothChartLegends: ToothType[] = toothChartFilters.filter(
  (legend) => legend !== 'all',
);

export const ReportToothChart: FC<ReportToothChartProps> = (props) => {
  const {
    reportID,
    className,
    onToothClick,
    image,
    teethNumberingDisabled,
    showActions = false,
  } = props;

  const dispatch = useAppDispatch();

  const currentReport = useAppSelector(
    reportsModel.selectors.selectByID(reportID),
  );

  const currentReportTeeth = useAppSelector(
    toothModel.selectors.selectByReportID(reportID),
  );

  const teethEntities = useAppSelector(toothModel.selectors.selectEntities);

  // TODO refactor ReportToothChart

  const {
    ROIISONumbers,
    isROIEditing,
    toggleROIByISONumbers,
    enableROIEditing: openROIFilters,
    saveROIEditing,
  } = useTeethChart({ teeth: currentReportTeeth });

  const teethItems = useToothTransform({
    teeth: currentReportTeeth,
    showLowProbability: currentReport?.Settings?.LowProbabilityMode,
    ROIISONumbers,
  });

  const teethChartROIFilters = useTeethChartROIFilters({
    teethItems,
    ROIISONumbers,
  });

  const isSigned = currentReport?.Signature?.Signed;

  // I don't see a reason to use ISO numbers instead of IDs.
  // When refactor, we should use IDs instead of ISO numbers for all manipulations.
  const handleToothClick = useCallback<ToothViewClick>(
    ({ id, ISONumber }) => {
      const currentTooth = teethEntities[id];

      if (isROIEditing) {
        toggleROIByISONumbers([ISONumber]);
      } else {
        if (onToothClick) {
          onToothClick(id);
        }

        if (currentTooth?.IsInROI) {
          scrollToElementByID(id);
          dispatch(reportsModel.actions.setActiveToothID(id));
        }
      }
    },
    [
      dispatch,
      isROIEditing,
      onToothClick,
      teethEntities,
      toggleROIByISONumbers,
    ],
  );

  const handleOpenModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.TeethNumberingModal,
        data: {
          image,
        },
      }),
    );
  }, [dispatch, image]);

  if (!currentReportTeeth.length) {
    return null;
  }

  return (
    <div className={cn(styles.container, className)}>
      {!isROIEditing && showActions && (
        <div className={styles.buttons}>
          <EditToothNumber
            disabled={teethNumberingDisabled}
            onClick={handleOpenModal}
          />

          <Button
            onClick={openROIFilters}
            variant="secondary"
            size="small"
            className={styles.chooseToothRegionButton}
            disabled={isSigned}
          >
            <FormattedMessage
              id="report.chooseToothRegion"
              defaultMessage="Choose region of interest"
            />
          </Button>
        </div>
      )}

      {isROIEditing && showActions && (
        <div className={styles.roi}>
          <TeethChartFilters
            className={styles.toothChartLegends}
            filters={teethChartROIFilters}
            onClick={toggleROIByISONumbers}
          />

          <Button
            variant="secondary"
            size="small"
            onClick={() => saveROIEditing(reportID)}
          >
            <FormattedMessage id="global.save" defaultMessage="Save" />
          </Button>
        </div>
      )}

      <TeethChart
        teethItems={teethItems}
        isROIEditing={isROIEditing}
        onToothClick={handleToothClick}
      />

      <ToothChartLegends
        toothChartLegends={toothChartLegends}
        showLowProbability={
          currentReport?.Settings?.LowProbabilityMode ?? false
        }
      />
    </div>
  );
};
