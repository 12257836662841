import { IconNames } from '@/shared/ui';

export const CONTROLS: IconNames[] = [
  'ruler',
  'arrow',
  'erase',
  'eyeFilled',
  'brightness',
  'shaprness',
];

export const TOOTH_CHART_CONTAINER_HEIGHT = 254;
export const TOOTH_CHART_PADDINGS = 40;
