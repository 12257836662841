import { ApiError } from '@/shared/api/api';
import { UserBadRequestError } from '@/shared/config';

export const isInvalidFileError = (error: ApiError) => {
  const parsedMessage = JSON.parse(error?.message);

  const errorCode = parsedMessage?.code;

  return errorCode === UserBadRequestError.FILE_IS_INVALID;
};
