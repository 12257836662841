import { MedicalImageInterface } from '@/shared/config';

import { doBBoxOverlap } from '@/features/IOXRayMatrix';

import { SubImageInterface } from '@/widgets/IOXRayMatrix';

export const isBiteWing = (
  subImages: SubImageInterface[],
  crop: MedicalImageInterface,
) =>
  subImages.some(
    (subImage) =>
      subImage.TargetAssetID === crop.TargetAssetID &&
      doBBoxOverlap(subImage.BBox, crop.BBox),
  );
