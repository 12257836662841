import { FC } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import {
  Study,
  StudyStatus,
  StudyType,
} from '@/shared/api/protocol_gen/model/dto_study';

import { i18n } from '@/entities/study';

import { studyStatusData } from '@/widgets/StudyCard/model/constants';
import { studyCardUploadingStatusMessage } from '@/widgets/StudyCard/config';

import styles from './UploadingStatusFrame.module.scss';

type UploadingStatusFrameProps = {
  study: Study;
};
export const UploadingStatusFrame: FC<UploadingStatusFrameProps> = (props) => {
  const { study } = props;
  const { formatMessage } = useIntl();

  const { imageSrc, status } = studyStatusData[study.Status];

  const isVisible =
    study.Status === StudyStatus.StudyStatus_Finished &&
    (!!study.DisplayAssetID ||
      study.Type === StudyType.StudyType_IntraoralXRay);

  return (
    <div className={cn(styles.frame, { [styles.inVisible]: isVisible })}>
      <img
        className={styles.placeholderImage}
        src={imageSrc}
        alt={formatMessage(i18n.studyTypes[study.Type])}
      />
      <p className="p2">
        {formatMessage(studyCardUploadingStatusMessage[status])}
      </p>
    </div>
  );
};
