/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import { Observable } from 'rxjs';
import { BrowserHeaders } from 'browser-headers';
import { share } from 'rxjs/operators';
import {
  Invitation,
  InvitationTarget,
  InvitationTarget_OrganizationMembership,
} from '../../model/dto_access';
import { Empty } from '../../google/protobuf/empty';
import {
  Organization,
  OrganizationUserRoleType,
  organizationUserRoleTypeFromJSON,
  organizationUserRoleTypeToJSON,
} from '../../model/dto_organization';
import { Patient } from '../../model/dto_patient';

export const protobufPackage = 'com.diagnocat.core';

export enum SharedByMeStreamSortBy {
  SharedByMeStreamSortByPatientFirstName = 0,
  SharedByMeStreamSortByPatientLastName = 1,
  SharedByMeStreamSortBySenderFirstName = 2,
  SharedByMeStreamSortBySenderLastName = 3,
  SharedByMeStreamSortByRecipientEmail = 4,
  SharedByMeStreamSortBySharingDate = 5,
  SharedByMeStreamSortByStatus = 6,
  UNRECOGNIZED = -1,
}

export function sharedByMeStreamSortByFromJSON(
  object: any,
): SharedByMeStreamSortBy {
  switch (object) {
    case 0:
    case 'SharedByMeStreamSortByPatientFirstName':
      return SharedByMeStreamSortBy.SharedByMeStreamSortByPatientFirstName;
    case 1:
    case 'SharedByMeStreamSortByPatientLastName':
      return SharedByMeStreamSortBy.SharedByMeStreamSortByPatientLastName;
    case 2:
    case 'SharedByMeStreamSortBySenderFirstName':
      return SharedByMeStreamSortBy.SharedByMeStreamSortBySenderFirstName;
    case 3:
    case 'SharedByMeStreamSortBySenderLastName':
      return SharedByMeStreamSortBy.SharedByMeStreamSortBySenderLastName;
    case 4:
    case 'SharedByMeStreamSortByRecipientEmail':
      return SharedByMeStreamSortBy.SharedByMeStreamSortByRecipientEmail;
    case 5:
    case 'SharedByMeStreamSortBySharingDate':
      return SharedByMeStreamSortBy.SharedByMeStreamSortBySharingDate;
    case 6:
    case 'SharedByMeStreamSortByStatus':
      return SharedByMeStreamSortBy.SharedByMeStreamSortByStatus;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SharedByMeStreamSortBy.UNRECOGNIZED;
  }
}

export function sharedByMeStreamSortByToJSON(
  object: SharedByMeStreamSortBy,
): string {
  switch (object) {
    case SharedByMeStreamSortBy.SharedByMeStreamSortByPatientFirstName:
      return 'SharedByMeStreamSortByPatientFirstName';
    case SharedByMeStreamSortBy.SharedByMeStreamSortByPatientLastName:
      return 'SharedByMeStreamSortByPatientLastName';
    case SharedByMeStreamSortBy.SharedByMeStreamSortBySenderFirstName:
      return 'SharedByMeStreamSortBySenderFirstName';
    case SharedByMeStreamSortBy.SharedByMeStreamSortBySenderLastName:
      return 'SharedByMeStreamSortBySenderLastName';
    case SharedByMeStreamSortBy.SharedByMeStreamSortByRecipientEmail:
      return 'SharedByMeStreamSortByRecipientEmail';
    case SharedByMeStreamSortBy.SharedByMeStreamSortBySharingDate:
      return 'SharedByMeStreamSortBySharingDate';
    case SharedByMeStreamSortBy.SharedByMeStreamSortByStatus:
      return 'SharedByMeStreamSortByStatus';
    case SharedByMeStreamSortBy.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum SharedWithMeStreamSortBy {
  SharedWithMeStreamSortByClinicName = 0,
  SharedWithMeStreamSortByPatientFirstName = 1,
  SharedWithMeStreamSortByPatientLastName = 2,
  SharedWithMeStreamSortBySenderFirstName = 3,
  SharedWithMeStreamSortBySenderLastName = 4,
  SharedWithMeStreamSortBySenderEmail = 5,
  SharedWithMeStreamSortBySharingDate = 6,
  SharedWithMeStreamSortByStatus = 7,
  UNRECOGNIZED = -1,
}

export function sharedWithMeStreamSortByFromJSON(
  object: any,
): SharedWithMeStreamSortBy {
  switch (object) {
    case 0:
    case 'SharedWithMeStreamSortByClinicName':
      return SharedWithMeStreamSortBy.SharedWithMeStreamSortByClinicName;
    case 1:
    case 'SharedWithMeStreamSortByPatientFirstName':
      return SharedWithMeStreamSortBy.SharedWithMeStreamSortByPatientFirstName;
    case 2:
    case 'SharedWithMeStreamSortByPatientLastName':
      return SharedWithMeStreamSortBy.SharedWithMeStreamSortByPatientLastName;
    case 3:
    case 'SharedWithMeStreamSortBySenderFirstName':
      return SharedWithMeStreamSortBy.SharedWithMeStreamSortBySenderFirstName;
    case 4:
    case 'SharedWithMeStreamSortBySenderLastName':
      return SharedWithMeStreamSortBy.SharedWithMeStreamSortBySenderLastName;
    case 5:
    case 'SharedWithMeStreamSortBySenderEmail':
      return SharedWithMeStreamSortBy.SharedWithMeStreamSortBySenderEmail;
    case 6:
    case 'SharedWithMeStreamSortBySharingDate':
      return SharedWithMeStreamSortBy.SharedWithMeStreamSortBySharingDate;
    case 7:
    case 'SharedWithMeStreamSortByStatus':
      return SharedWithMeStreamSortBy.SharedWithMeStreamSortByStatus;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SharedWithMeStreamSortBy.UNRECOGNIZED;
  }
}

export function sharedWithMeStreamSortByToJSON(
  object: SharedWithMeStreamSortBy,
): string {
  switch (object) {
    case SharedWithMeStreamSortBy.SharedWithMeStreamSortByClinicName:
      return 'SharedWithMeStreamSortByClinicName';
    case SharedWithMeStreamSortBy.SharedWithMeStreamSortByPatientFirstName:
      return 'SharedWithMeStreamSortByPatientFirstName';
    case SharedWithMeStreamSortBy.SharedWithMeStreamSortByPatientLastName:
      return 'SharedWithMeStreamSortByPatientLastName';
    case SharedWithMeStreamSortBy.SharedWithMeStreamSortBySenderFirstName:
      return 'SharedWithMeStreamSortBySenderFirstName';
    case SharedWithMeStreamSortBy.SharedWithMeStreamSortBySenderLastName:
      return 'SharedWithMeStreamSortBySenderLastName';
    case SharedWithMeStreamSortBy.SharedWithMeStreamSortBySenderEmail:
      return 'SharedWithMeStreamSortBySenderEmail';
    case SharedWithMeStreamSortBy.SharedWithMeStreamSortBySharingDate:
      return 'SharedWithMeStreamSortBySharingDate';
    case SharedWithMeStreamSortBy.SharedWithMeStreamSortByStatus:
      return 'SharedWithMeStreamSortByStatus';
    case SharedWithMeStreamSortBy.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface SharedByMeStreamReq {
  /**
   * `ResumeToken`: [optional]: the last ResumeToken obtained from `InvitationListStreamResp`.
   * If filters are changed or a page is just opened, pass empty string until new token is received
   */
  ResumeToken: string;
  /** `OrganizationID`: [required] */
  OrganizationID: string;
  /** `SearchString`: [optional]: patient name/shared by (name)/ shared with (email) or a part of it */
  SearchString: string;
  /** `Sort`: [optional]: default == by last modification time */
  Sort: SharedByMeStreamSort[];
  /** `StartFromInvitationID`: [optional] */
  StartFromInvitationID: string;
  /** `Limit`: [required]: min == 10, max == 1000 */
  Limit: number;
}

export interface SharedByMeStreamSort {
  By: SharedByMeStreamSortBy;
  Ascending: boolean;
}

export interface SharedWithMeStreamReq {
  /**
   * `ResumeToken`: [optional]: the last ResumeToken obtained from `InvitationListStreamResp`.
   * If filters are changed or a page is just opened, pass empty string until new token is received
   */
  ResumeToken: string;
  /** `SearchString`: [optional]: patient name/shared by (name)/ shared with (email) or a part of it */
  SearchString: string;
  /** `OrganizationID`: [optional] */
  OrganizationID: string;
  /** `Sort`: [optional]: default == by last modification time */
  Sort: SharedWithMeStreamSort[];
  /** `StartFromInvitationID`: [optional] */
  StartFromInvitationID: string;
  /** `Limit`: [required]: min == 10, max == 1000 */
  Limit: number;
}

export interface SharedWithMeStreamSort {
  By: SharedWithMeStreamSortBy;
  Ascending: boolean;
}

export interface InvitationListStreamResp {
  /** If the stream was closed, we need `ResumeToken` to know which event you received the last. */
  ResumeToken: string;
  /** Invitations list according to request filters and limit */
  InitialInvitationsList:
    | InvitationListStreamResp_InitialInvitations
    | undefined;
  InvitationUpdated: Invitation | undefined;
  /** Total count of patients available to `Me` in the selected organization */
  TotalOrganizationPatientCount: number | undefined;
  SharedWithMeCount: number | undefined;
  /** if user is an admin in organization -> return SharedByStaffCount */
  SharedByMeCount: number | undefined;
  /** `Ping` - just a stub ping message to ensure that the stream won't break by timeout */
  Ping: Empty | undefined;
}

export interface InvitationListStreamResp_InitialInvitations {
  Invitations: Invitation[];
}

export interface GetInvitationByTokenReq {
  /** `Token`: [required] */
  Token: string;
}

export interface GetInvitationByTokenResp {
  Invitation: Invitation | undefined;
}

export interface InviteReq {
  /** `Recipient`: [required]: Phone or/and Email */
  Recipient: InviteReq_RecipientContacts | undefined;
  /** `Target`: [required], all fields are [required] */
  Target: InvitationTarget | undefined;
  /** `Comment`: [optional] */
  Comment: string;
}

export interface InviteReq_RecipientContacts {
  Phone: string;
  Email: string;
}

export interface InviteResp {
  CreatedInvitation: Invitation | undefined;
  /** `AutoAcceptedOrganizationInvitation`: [optional] */
  AutoAcceptedOrganizationInvitation:
    | InviteResp_AutoAcceptedOrganizationInvitationDetails
    | undefined;
}

/**
 * These details are provided if an invitation to organization is automatically accepted.
 * In this case just modified organization's data is provided [1]
 * Also all sharing invitations (from this organization) previously given to the invitee could become cancelled [2]
 * and the invitee will be added to these (previously shared) patients [3] as a treatment team member
 */
export interface InviteResp_AutoAcceptedOrganizationInvitationDetails {
  Organization: Organization | undefined;
  CancelledInvitations: Invitation[];
  Patients: Patient[];
}

export interface CancelInvitationReq {
  /** `InvitationID`: [required] */
  InvitationID: string;
}

export interface CancelInvitationResp {
  Invitation: Invitation | undefined;
}

export interface AcceptInvitationReq {
  /** `InvitationID`: [required] */
  InvitationID: string;
  /** `Secret`: [required] */
  Secret: string;
}

export interface AcceptInvitationResp {
  Invitations: Invitation[];
  Organization: Organization | undefined;
  Patients: Patient[];
}

export interface SetOrganizationInvitationUserInfoReq {
  /** `InvitationID`: [required] */
  InvitationID: string;
  /**
   * `Membership`: [required]:
   *  - `OrganizationID`: [optional]
   */
  Membership: InvitationTarget_OrganizationMembership | undefined;
}

export interface SetOrganizationInvitationUserInfoResp {
  Invitation: Invitation | undefined;
}

export interface RevokeSharingReq {
  /** `UserID`: [required] */
  UserID: string;
  /** `PatientID`: [required] */
  PatientID: string;
}

export interface RevokeSharingResp {
  Patient: Patient | undefined;
  /** Sharing invitation canceled */
  Invitation: Invitation | undefined;
}

export interface SetPatientTeamMembersReq {
  /** `PatientID`: [required] */
  PatientID: string;
  /** `UserIDs`: [optional] */
  UserIDs: string[];
}

export interface SetPatientTeamMembersResp {
  Patient: Patient | undefined;
}

export interface SetUserOrganizationRolesReq {
  /** `UserID`: [required] */
  UserID: string;
  /** `OrganizationID`: [required] */
  OrganizationID: string;
  /** `Roles`: [optional]: if empty -> the user loses access to the organization */
  Roles: OrganizationUserRoleType[];
}

export interface SetUserOrganizationRolesResp {
  Organization: Organization | undefined;
  /** if removed the last organization role -> revoked all patients team member roles */
  Patients: Patient[];
}

function createBaseSharedByMeStreamReq(): SharedByMeStreamReq {
  return {
    ResumeToken: '',
    OrganizationID: '',
    SearchString: '',
    Sort: [],
    StartFromInvitationID: '',
    Limit: 0,
  };
}

export const SharedByMeStreamReq = {
  encode(
    message: SharedByMeStreamReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ResumeToken !== '') {
      writer.uint32(10).string(message.ResumeToken);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(18).string(message.OrganizationID);
    }
    if (message.SearchString !== '') {
      writer.uint32(26).string(message.SearchString);
    }
    for (const v of message.Sort) {
      SharedByMeStreamSort.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    if (message.StartFromInvitationID !== '') {
      writer.uint32(802).string(message.StartFromInvitationID);
    }
    if (message.Limit !== 0) {
      writer.uint32(808).uint32(message.Limit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SharedByMeStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSharedByMeStreamReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ResumeToken = reader.string();
          break;
        case 2:
          message.OrganizationID = reader.string();
          break;
        case 3:
          message.SearchString = reader.string();
          break;
        case 21:
          message.Sort.push(
            SharedByMeStreamSort.decode(reader, reader.uint32()),
          );
          break;
        case 100:
          message.StartFromInvitationID = reader.string();
          break;
        case 101:
          message.Limit = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SharedByMeStreamReq {
    return {
      ResumeToken: isSet(object.ResumeToken) ? String(object.ResumeToken) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      SearchString: isSet(object.SearchString)
        ? String(object.SearchString)
        : '',
      Sort: Array.isArray(object?.Sort)
        ? object.Sort.map((e: any) => SharedByMeStreamSort.fromJSON(e))
        : [],
      StartFromInvitationID: isSet(object.StartFromInvitationID)
        ? String(object.StartFromInvitationID)
        : '',
      Limit: isSet(object.Limit) ? Number(object.Limit) : 0,
    };
  },

  toJSON(message: SharedByMeStreamReq): unknown {
    const obj: any = {};
    message.ResumeToken !== undefined &&
      (obj.ResumeToken = message.ResumeToken);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.SearchString !== undefined &&
      (obj.SearchString = message.SearchString);
    if (message.Sort) {
      obj.Sort = message.Sort.map((e) =>
        e ? SharedByMeStreamSort.toJSON(e) : undefined,
      );
    } else {
      obj.Sort = [];
    }
    message.StartFromInvitationID !== undefined &&
      (obj.StartFromInvitationID = message.StartFromInvitationID);
    message.Limit !== undefined && (obj.Limit = Math.round(message.Limit));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SharedByMeStreamReq>, I>>(
    object: I,
  ): SharedByMeStreamReq {
    const message = createBaseSharedByMeStreamReq();
    message.ResumeToken = object.ResumeToken ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.SearchString = object.SearchString ?? '';
    message.Sort =
      object.Sort?.map((e) => SharedByMeStreamSort.fromPartial(e)) || [];
    message.StartFromInvitationID = object.StartFromInvitationID ?? '';
    message.Limit = object.Limit ?? 0;
    return message;
  },
};

function createBaseSharedByMeStreamSort(): SharedByMeStreamSort {
  return { By: 0, Ascending: false };
}

export const SharedByMeStreamSort = {
  encode(
    message: SharedByMeStreamSort,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.By !== 0) {
      writer.uint32(8).int32(message.By);
    }
    if (message.Ascending === true) {
      writer.uint32(16).bool(message.Ascending);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SharedByMeStreamSort {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSharedByMeStreamSort();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.By = reader.int32() as any;
          break;
        case 2:
          message.Ascending = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SharedByMeStreamSort {
    return {
      By: isSet(object.By) ? sharedByMeStreamSortByFromJSON(object.By) : 0,
      Ascending: isSet(object.Ascending) ? Boolean(object.Ascending) : false,
    };
  },

  toJSON(message: SharedByMeStreamSort): unknown {
    const obj: any = {};
    message.By !== undefined &&
      (obj.By = sharedByMeStreamSortByToJSON(message.By));
    message.Ascending !== undefined && (obj.Ascending = message.Ascending);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SharedByMeStreamSort>, I>>(
    object: I,
  ): SharedByMeStreamSort {
    const message = createBaseSharedByMeStreamSort();
    message.By = object.By ?? 0;
    message.Ascending = object.Ascending ?? false;
    return message;
  },
};

function createBaseSharedWithMeStreamReq(): SharedWithMeStreamReq {
  return {
    ResumeToken: '',
    SearchString: '',
    OrganizationID: '',
    Sort: [],
    StartFromInvitationID: '',
    Limit: 0,
  };
}

export const SharedWithMeStreamReq = {
  encode(
    message: SharedWithMeStreamReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ResumeToken !== '') {
      writer.uint32(10).string(message.ResumeToken);
    }
    if (message.SearchString !== '') {
      writer.uint32(18).string(message.SearchString);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(26).string(message.OrganizationID);
    }
    for (const v of message.Sort) {
      SharedWithMeStreamSort.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    if (message.StartFromInvitationID !== '') {
      writer.uint32(802).string(message.StartFromInvitationID);
    }
    if (message.Limit !== 0) {
      writer.uint32(808).uint32(message.Limit);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SharedWithMeStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSharedWithMeStreamReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ResumeToken = reader.string();
          break;
        case 2:
          message.SearchString = reader.string();
          break;
        case 3:
          message.OrganizationID = reader.string();
          break;
        case 21:
          message.Sort.push(
            SharedWithMeStreamSort.decode(reader, reader.uint32()),
          );
          break;
        case 100:
          message.StartFromInvitationID = reader.string();
          break;
        case 101:
          message.Limit = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SharedWithMeStreamReq {
    return {
      ResumeToken: isSet(object.ResumeToken) ? String(object.ResumeToken) : '',
      SearchString: isSet(object.SearchString)
        ? String(object.SearchString)
        : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      Sort: Array.isArray(object?.Sort)
        ? object.Sort.map((e: any) => SharedWithMeStreamSort.fromJSON(e))
        : [],
      StartFromInvitationID: isSet(object.StartFromInvitationID)
        ? String(object.StartFromInvitationID)
        : '',
      Limit: isSet(object.Limit) ? Number(object.Limit) : 0,
    };
  },

  toJSON(message: SharedWithMeStreamReq): unknown {
    const obj: any = {};
    message.ResumeToken !== undefined &&
      (obj.ResumeToken = message.ResumeToken);
    message.SearchString !== undefined &&
      (obj.SearchString = message.SearchString);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    if (message.Sort) {
      obj.Sort = message.Sort.map((e) =>
        e ? SharedWithMeStreamSort.toJSON(e) : undefined,
      );
    } else {
      obj.Sort = [];
    }
    message.StartFromInvitationID !== undefined &&
      (obj.StartFromInvitationID = message.StartFromInvitationID);
    message.Limit !== undefined && (obj.Limit = Math.round(message.Limit));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SharedWithMeStreamReq>, I>>(
    object: I,
  ): SharedWithMeStreamReq {
    const message = createBaseSharedWithMeStreamReq();
    message.ResumeToken = object.ResumeToken ?? '';
    message.SearchString = object.SearchString ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.Sort =
      object.Sort?.map((e) => SharedWithMeStreamSort.fromPartial(e)) || [];
    message.StartFromInvitationID = object.StartFromInvitationID ?? '';
    message.Limit = object.Limit ?? 0;
    return message;
  },
};

function createBaseSharedWithMeStreamSort(): SharedWithMeStreamSort {
  return { By: 0, Ascending: false };
}

export const SharedWithMeStreamSort = {
  encode(
    message: SharedWithMeStreamSort,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.By !== 0) {
      writer.uint32(8).int32(message.By);
    }
    if (message.Ascending === true) {
      writer.uint32(16).bool(message.Ascending);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SharedWithMeStreamSort {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSharedWithMeStreamSort();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.By = reader.int32() as any;
          break;
        case 2:
          message.Ascending = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SharedWithMeStreamSort {
    return {
      By: isSet(object.By) ? sharedWithMeStreamSortByFromJSON(object.By) : 0,
      Ascending: isSet(object.Ascending) ? Boolean(object.Ascending) : false,
    };
  },

  toJSON(message: SharedWithMeStreamSort): unknown {
    const obj: any = {};
    message.By !== undefined &&
      (obj.By = sharedWithMeStreamSortByToJSON(message.By));
    message.Ascending !== undefined && (obj.Ascending = message.Ascending);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SharedWithMeStreamSort>, I>>(
    object: I,
  ): SharedWithMeStreamSort {
    const message = createBaseSharedWithMeStreamSort();
    message.By = object.By ?? 0;
    message.Ascending = object.Ascending ?? false;
    return message;
  },
};

function createBaseInvitationListStreamResp(): InvitationListStreamResp {
  return {
    ResumeToken: '',
    InitialInvitationsList: undefined,
    InvitationUpdated: undefined,
    TotalOrganizationPatientCount: undefined,
    SharedWithMeCount: undefined,
    SharedByMeCount: undefined,
    Ping: undefined,
  };
}

export const InvitationListStreamResp = {
  encode(
    message: InvitationListStreamResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ResumeToken !== '') {
      writer.uint32(10).string(message.ResumeToken);
    }
    if (message.InitialInvitationsList !== undefined) {
      InvitationListStreamResp_InitialInvitations.encode(
        message.InitialInvitationsList,
        writer.uint32(82).fork(),
      ).ldelim();
    }
    if (message.InvitationUpdated !== undefined) {
      Invitation.encode(
        message.InvitationUpdated,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.TotalOrganizationPatientCount !== undefined) {
      writer.uint32(168).uint32(message.TotalOrganizationPatientCount);
    }
    if (message.SharedWithMeCount !== undefined) {
      writer.uint32(176).uint32(message.SharedWithMeCount);
    }
    if (message.SharedByMeCount !== undefined) {
      writer.uint32(184).uint32(message.SharedByMeCount);
    }
    if (message.Ping !== undefined) {
      Empty.encode(message.Ping, writer.uint32(810).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): InvitationListStreamResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvitationListStreamResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ResumeToken = reader.string();
          break;
        case 10:
          message.InitialInvitationsList =
            InvitationListStreamResp_InitialInvitations.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 11:
          message.InvitationUpdated = Invitation.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 21:
          message.TotalOrganizationPatientCount = reader.uint32();
          break;
        case 22:
          message.SharedWithMeCount = reader.uint32();
          break;
        case 23:
          message.SharedByMeCount = reader.uint32();
          break;
        case 101:
          message.Ping = Empty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InvitationListStreamResp {
    return {
      ResumeToken: isSet(object.ResumeToken) ? String(object.ResumeToken) : '',
      InitialInvitationsList: isSet(object.InitialInvitationsList)
        ? InvitationListStreamResp_InitialInvitations.fromJSON(
            object.InitialInvitationsList,
          )
        : undefined,
      InvitationUpdated: isSet(object.InvitationUpdated)
        ? Invitation.fromJSON(object.InvitationUpdated)
        : undefined,
      TotalOrganizationPatientCount: isSet(object.TotalOrganizationPatientCount)
        ? Number(object.TotalOrganizationPatientCount)
        : undefined,
      SharedWithMeCount: isSet(object.SharedWithMeCount)
        ? Number(object.SharedWithMeCount)
        : undefined,
      SharedByMeCount: isSet(object.SharedByMeCount)
        ? Number(object.SharedByMeCount)
        : undefined,
      Ping: isSet(object.Ping) ? Empty.fromJSON(object.Ping) : undefined,
    };
  },

  toJSON(message: InvitationListStreamResp): unknown {
    const obj: any = {};
    message.ResumeToken !== undefined &&
      (obj.ResumeToken = message.ResumeToken);
    message.InitialInvitationsList !== undefined &&
      (obj.InitialInvitationsList = message.InitialInvitationsList
        ? InvitationListStreamResp_InitialInvitations.toJSON(
            message.InitialInvitationsList,
          )
        : undefined);
    message.InvitationUpdated !== undefined &&
      (obj.InvitationUpdated = message.InvitationUpdated
        ? Invitation.toJSON(message.InvitationUpdated)
        : undefined);
    message.TotalOrganizationPatientCount !== undefined &&
      (obj.TotalOrganizationPatientCount = Math.round(
        message.TotalOrganizationPatientCount,
      ));
    message.SharedWithMeCount !== undefined &&
      (obj.SharedWithMeCount = Math.round(message.SharedWithMeCount));
    message.SharedByMeCount !== undefined &&
      (obj.SharedByMeCount = Math.round(message.SharedByMeCount));
    message.Ping !== undefined &&
      (obj.Ping = message.Ping ? Empty.toJSON(message.Ping) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<InvitationListStreamResp>, I>>(
    object: I,
  ): InvitationListStreamResp {
    const message = createBaseInvitationListStreamResp();
    message.ResumeToken = object.ResumeToken ?? '';
    message.InitialInvitationsList =
      object.InitialInvitationsList !== undefined &&
      object.InitialInvitationsList !== null
        ? InvitationListStreamResp_InitialInvitations.fromPartial(
            object.InitialInvitationsList,
          )
        : undefined;
    message.InvitationUpdated =
      object.InvitationUpdated !== undefined &&
      object.InvitationUpdated !== null
        ? Invitation.fromPartial(object.InvitationUpdated)
        : undefined;
    message.TotalOrganizationPatientCount =
      object.TotalOrganizationPatientCount ?? undefined;
    message.SharedWithMeCount = object.SharedWithMeCount ?? undefined;
    message.SharedByMeCount = object.SharedByMeCount ?? undefined;
    message.Ping =
      object.Ping !== undefined && object.Ping !== null
        ? Empty.fromPartial(object.Ping)
        : undefined;
    return message;
  },
};

function createBaseInvitationListStreamResp_InitialInvitations(): InvitationListStreamResp_InitialInvitations {
  return { Invitations: [] };
}

export const InvitationListStreamResp_InitialInvitations = {
  encode(
    message: InvitationListStreamResp_InitialInvitations,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Invitations) {
      Invitation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): InvitationListStreamResp_InitialInvitations {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvitationListStreamResp_InitialInvitations();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Invitations.push(Invitation.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InvitationListStreamResp_InitialInvitations {
    return {
      Invitations: Array.isArray(object?.Invitations)
        ? object.Invitations.map((e: any) => Invitation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: InvitationListStreamResp_InitialInvitations): unknown {
    const obj: any = {};
    if (message.Invitations) {
      obj.Invitations = message.Invitations.map((e) =>
        e ? Invitation.toJSON(e) : undefined,
      );
    } else {
      obj.Invitations = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<InvitationListStreamResp_InitialInvitations>,
      I
    >,
  >(object: I): InvitationListStreamResp_InitialInvitations {
    const message = createBaseInvitationListStreamResp_InitialInvitations();
    message.Invitations =
      object.Invitations?.map((e) => Invitation.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetInvitationByTokenReq(): GetInvitationByTokenReq {
  return { Token: '' };
}

export const GetInvitationByTokenReq = {
  encode(
    message: GetInvitationByTokenReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Token !== '') {
      writer.uint32(10).string(message.Token);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetInvitationByTokenReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetInvitationByTokenReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Token = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetInvitationByTokenReq {
    return {
      Token: isSet(object.Token) ? String(object.Token) : '',
    };
  },

  toJSON(message: GetInvitationByTokenReq): unknown {
    const obj: any = {};
    message.Token !== undefined && (obj.Token = message.Token);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetInvitationByTokenReq>, I>>(
    object: I,
  ): GetInvitationByTokenReq {
    const message = createBaseGetInvitationByTokenReq();
    message.Token = object.Token ?? '';
    return message;
  },
};

function createBaseGetInvitationByTokenResp(): GetInvitationByTokenResp {
  return { Invitation: undefined };
}

export const GetInvitationByTokenResp = {
  encode(
    message: GetInvitationByTokenResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Invitation !== undefined) {
      Invitation.encode(message.Invitation, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetInvitationByTokenResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetInvitationByTokenResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Invitation = Invitation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetInvitationByTokenResp {
    return {
      Invitation: isSet(object.Invitation)
        ? Invitation.fromJSON(object.Invitation)
        : undefined,
    };
  },

  toJSON(message: GetInvitationByTokenResp): unknown {
    const obj: any = {};
    message.Invitation !== undefined &&
      (obj.Invitation = message.Invitation
        ? Invitation.toJSON(message.Invitation)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetInvitationByTokenResp>, I>>(
    object: I,
  ): GetInvitationByTokenResp {
    const message = createBaseGetInvitationByTokenResp();
    message.Invitation =
      object.Invitation !== undefined && object.Invitation !== null
        ? Invitation.fromPartial(object.Invitation)
        : undefined;
    return message;
  },
};

function createBaseInviteReq(): InviteReq {
  return { Recipient: undefined, Target: undefined, Comment: '' };
}

export const InviteReq = {
  encode(
    message: InviteReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Recipient !== undefined) {
      InviteReq_RecipientContacts.encode(
        message.Recipient,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.Target !== undefined) {
      InvitationTarget.encode(
        message.Target,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Comment !== '') {
      writer.uint32(26).string(message.Comment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Recipient = InviteReq_RecipientContacts.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.Target = InvitationTarget.decode(reader, reader.uint32());
          break;
        case 3:
          message.Comment = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InviteReq {
    return {
      Recipient: isSet(object.Recipient)
        ? InviteReq_RecipientContacts.fromJSON(object.Recipient)
        : undefined,
      Target: isSet(object.Target)
        ? InvitationTarget.fromJSON(object.Target)
        : undefined,
      Comment: isSet(object.Comment) ? String(object.Comment) : '',
    };
  },

  toJSON(message: InviteReq): unknown {
    const obj: any = {};
    message.Recipient !== undefined &&
      (obj.Recipient = message.Recipient
        ? InviteReq_RecipientContacts.toJSON(message.Recipient)
        : undefined);
    message.Target !== undefined &&
      (obj.Target = message.Target
        ? InvitationTarget.toJSON(message.Target)
        : undefined);
    message.Comment !== undefined && (obj.Comment = message.Comment);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<InviteReq>, I>>(
    object: I,
  ): InviteReq {
    const message = createBaseInviteReq();
    message.Recipient =
      object.Recipient !== undefined && object.Recipient !== null
        ? InviteReq_RecipientContacts.fromPartial(object.Recipient)
        : undefined;
    message.Target =
      object.Target !== undefined && object.Target !== null
        ? InvitationTarget.fromPartial(object.Target)
        : undefined;
    message.Comment = object.Comment ?? '';
    return message;
  },
};

function createBaseInviteReq_RecipientContacts(): InviteReq_RecipientContacts {
  return { Phone: '', Email: '' };
}

export const InviteReq_RecipientContacts = {
  encode(
    message: InviteReq_RecipientContacts,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Phone !== '') {
      writer.uint32(10).string(message.Phone);
    }
    if (message.Email !== '') {
      writer.uint32(18).string(message.Email);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): InviteReq_RecipientContacts {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteReq_RecipientContacts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Phone = reader.string();
          break;
        case 2:
          message.Email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InviteReq_RecipientContacts {
    return {
      Phone: isSet(object.Phone) ? String(object.Phone) : '',
      Email: isSet(object.Email) ? String(object.Email) : '',
    };
  },

  toJSON(message: InviteReq_RecipientContacts): unknown {
    const obj: any = {};
    message.Phone !== undefined && (obj.Phone = message.Phone);
    message.Email !== undefined && (obj.Email = message.Email);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<InviteReq_RecipientContacts>, I>>(
    object: I,
  ): InviteReq_RecipientContacts {
    const message = createBaseInviteReq_RecipientContacts();
    message.Phone = object.Phone ?? '';
    message.Email = object.Email ?? '';
    return message;
  },
};

function createBaseInviteResp(): InviteResp {
  return {
    CreatedInvitation: undefined,
    AutoAcceptedOrganizationInvitation: undefined,
  };
}

export const InviteResp = {
  encode(
    message: InviteResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CreatedInvitation !== undefined) {
      Invitation.encode(
        message.CreatedInvitation,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.AutoAcceptedOrganizationInvitation !== undefined) {
      InviteResp_AutoAcceptedOrganizationInvitationDetails.encode(
        message.AutoAcceptedOrganizationInvitation,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CreatedInvitation = Invitation.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 11:
          message.AutoAcceptedOrganizationInvitation =
            InviteResp_AutoAcceptedOrganizationInvitationDetails.decode(
              reader,
              reader.uint32(),
            );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InviteResp {
    return {
      CreatedInvitation: isSet(object.CreatedInvitation)
        ? Invitation.fromJSON(object.CreatedInvitation)
        : undefined,
      AutoAcceptedOrganizationInvitation: isSet(
        object.AutoAcceptedOrganizationInvitation,
      )
        ? InviteResp_AutoAcceptedOrganizationInvitationDetails.fromJSON(
            object.AutoAcceptedOrganizationInvitation,
          )
        : undefined,
    };
  },

  toJSON(message: InviteResp): unknown {
    const obj: any = {};
    message.CreatedInvitation !== undefined &&
      (obj.CreatedInvitation = message.CreatedInvitation
        ? Invitation.toJSON(message.CreatedInvitation)
        : undefined);
    message.AutoAcceptedOrganizationInvitation !== undefined &&
      (obj.AutoAcceptedOrganizationInvitation =
        message.AutoAcceptedOrganizationInvitation
          ? InviteResp_AutoAcceptedOrganizationInvitationDetails.toJSON(
              message.AutoAcceptedOrganizationInvitation,
            )
          : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<InviteResp>, I>>(
    object: I,
  ): InviteResp {
    const message = createBaseInviteResp();
    message.CreatedInvitation =
      object.CreatedInvitation !== undefined &&
      object.CreatedInvitation !== null
        ? Invitation.fromPartial(object.CreatedInvitation)
        : undefined;
    message.AutoAcceptedOrganizationInvitation =
      object.AutoAcceptedOrganizationInvitation !== undefined &&
      object.AutoAcceptedOrganizationInvitation !== null
        ? InviteResp_AutoAcceptedOrganizationInvitationDetails.fromPartial(
            object.AutoAcceptedOrganizationInvitation,
          )
        : undefined;
    return message;
  },
};

function createBaseInviteResp_AutoAcceptedOrganizationInvitationDetails(): InviteResp_AutoAcceptedOrganizationInvitationDetails {
  return { Organization: undefined, CancelledInvitations: [], Patients: [] };
}

export const InviteResp_AutoAcceptedOrganizationInvitationDetails = {
  encode(
    message: InviteResp_AutoAcceptedOrganizationInvitationDetails,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    for (const v of message.CancelledInvitations) {
      Invitation.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.Patients) {
      Patient.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): InviteResp_AutoAcceptedOrganizationInvitationDetails {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseInviteResp_AutoAcceptedOrganizationInvitationDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        case 2:
          message.CancelledInvitations.push(
            Invitation.decode(reader, reader.uint32()),
          );
          break;
        case 3:
          message.Patients.push(Patient.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InviteResp_AutoAcceptedOrganizationInvitationDetails {
    return {
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
      CancelledInvitations: Array.isArray(object?.CancelledInvitations)
        ? object.CancelledInvitations.map((e: any) => Invitation.fromJSON(e))
        : [],
      Patients: Array.isArray(object?.Patients)
        ? object.Patients.map((e: any) => Patient.fromJSON(e))
        : [],
    };
  },

  toJSON(
    message: InviteResp_AutoAcceptedOrganizationInvitationDetails,
  ): unknown {
    const obj: any = {};
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    if (message.CancelledInvitations) {
      obj.CancelledInvitations = message.CancelledInvitations.map((e) =>
        e ? Invitation.toJSON(e) : undefined,
      );
    } else {
      obj.CancelledInvitations = [];
    }
    if (message.Patients) {
      obj.Patients = message.Patients.map((e) =>
        e ? Patient.toJSON(e) : undefined,
      );
    } else {
      obj.Patients = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<InviteResp_AutoAcceptedOrganizationInvitationDetails>,
      I
    >,
  >(object: I): InviteResp_AutoAcceptedOrganizationInvitationDetails {
    const message =
      createBaseInviteResp_AutoAcceptedOrganizationInvitationDetails();
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    message.CancelledInvitations =
      object.CancelledInvitations?.map((e) => Invitation.fromPartial(e)) || [];
    message.Patients =
      object.Patients?.map((e) => Patient.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCancelInvitationReq(): CancelInvitationReq {
  return { InvitationID: '' };
}

export const CancelInvitationReq = {
  encode(
    message: CancelInvitationReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.InvitationID !== '') {
      writer.uint32(10).string(message.InvitationID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelInvitationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelInvitationReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.InvitationID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CancelInvitationReq {
    return {
      InvitationID: isSet(object.InvitationID)
        ? String(object.InvitationID)
        : '',
    };
  },

  toJSON(message: CancelInvitationReq): unknown {
    const obj: any = {};
    message.InvitationID !== undefined &&
      (obj.InvitationID = message.InvitationID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CancelInvitationReq>, I>>(
    object: I,
  ): CancelInvitationReq {
    const message = createBaseCancelInvitationReq();
    message.InvitationID = object.InvitationID ?? '';
    return message;
  },
};

function createBaseCancelInvitationResp(): CancelInvitationResp {
  return { Invitation: undefined };
}

export const CancelInvitationResp = {
  encode(
    message: CancelInvitationResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Invitation !== undefined) {
      Invitation.encode(message.Invitation, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CancelInvitationResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelInvitationResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Invitation = Invitation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CancelInvitationResp {
    return {
      Invitation: isSet(object.Invitation)
        ? Invitation.fromJSON(object.Invitation)
        : undefined,
    };
  },

  toJSON(message: CancelInvitationResp): unknown {
    const obj: any = {};
    message.Invitation !== undefined &&
      (obj.Invitation = message.Invitation
        ? Invitation.toJSON(message.Invitation)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CancelInvitationResp>, I>>(
    object: I,
  ): CancelInvitationResp {
    const message = createBaseCancelInvitationResp();
    message.Invitation =
      object.Invitation !== undefined && object.Invitation !== null
        ? Invitation.fromPartial(object.Invitation)
        : undefined;
    return message;
  },
};

function createBaseAcceptInvitationReq(): AcceptInvitationReq {
  return { InvitationID: '', Secret: '' };
}

export const AcceptInvitationReq = {
  encode(
    message: AcceptInvitationReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.InvitationID !== '') {
      writer.uint32(10).string(message.InvitationID);
    }
    if (message.Secret !== '') {
      writer.uint32(18).string(message.Secret);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcceptInvitationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcceptInvitationReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.InvitationID = reader.string();
          break;
        case 2:
          message.Secret = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AcceptInvitationReq {
    return {
      InvitationID: isSet(object.InvitationID)
        ? String(object.InvitationID)
        : '',
      Secret: isSet(object.Secret) ? String(object.Secret) : '',
    };
  },

  toJSON(message: AcceptInvitationReq): unknown {
    const obj: any = {};
    message.InvitationID !== undefined &&
      (obj.InvitationID = message.InvitationID);
    message.Secret !== undefined && (obj.Secret = message.Secret);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AcceptInvitationReq>, I>>(
    object: I,
  ): AcceptInvitationReq {
    const message = createBaseAcceptInvitationReq();
    message.InvitationID = object.InvitationID ?? '';
    message.Secret = object.Secret ?? '';
    return message;
  },
};

function createBaseAcceptInvitationResp(): AcceptInvitationResp {
  return { Invitations: [], Organization: undefined, Patients: [] };
}

export const AcceptInvitationResp = {
  encode(
    message: AcceptInvitationResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Invitations) {
      Invitation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    for (const v of message.Patients) {
      Patient.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AcceptInvitationResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcceptInvitationResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Invitations.push(Invitation.decode(reader, reader.uint32()));
          break;
        case 2:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        case 3:
          message.Patients.push(Patient.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AcceptInvitationResp {
    return {
      Invitations: Array.isArray(object?.Invitations)
        ? object.Invitations.map((e: any) => Invitation.fromJSON(e))
        : [],
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
      Patients: Array.isArray(object?.Patients)
        ? object.Patients.map((e: any) => Patient.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AcceptInvitationResp): unknown {
    const obj: any = {};
    if (message.Invitations) {
      obj.Invitations = message.Invitations.map((e) =>
        e ? Invitation.toJSON(e) : undefined,
      );
    } else {
      obj.Invitations = [];
    }
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    if (message.Patients) {
      obj.Patients = message.Patients.map((e) =>
        e ? Patient.toJSON(e) : undefined,
      );
    } else {
      obj.Patients = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AcceptInvitationResp>, I>>(
    object: I,
  ): AcceptInvitationResp {
    const message = createBaseAcceptInvitationResp();
    message.Invitations =
      object.Invitations?.map((e) => Invitation.fromPartial(e)) || [];
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    message.Patients =
      object.Patients?.map((e) => Patient.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetOrganizationInvitationUserInfoReq(): SetOrganizationInvitationUserInfoReq {
  return { InvitationID: '', Membership: undefined };
}

export const SetOrganizationInvitationUserInfoReq = {
  encode(
    message: SetOrganizationInvitationUserInfoReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.InvitationID !== '') {
      writer.uint32(10).string(message.InvitationID);
    }
    if (message.Membership !== undefined) {
      InvitationTarget_OrganizationMembership.encode(
        message.Membership,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetOrganizationInvitationUserInfoReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetOrganizationInvitationUserInfoReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.InvitationID = reader.string();
          break;
        case 2:
          message.Membership = InvitationTarget_OrganizationMembership.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetOrganizationInvitationUserInfoReq {
    return {
      InvitationID: isSet(object.InvitationID)
        ? String(object.InvitationID)
        : '',
      Membership: isSet(object.Membership)
        ? InvitationTarget_OrganizationMembership.fromJSON(object.Membership)
        : undefined,
    };
  },

  toJSON(message: SetOrganizationInvitationUserInfoReq): unknown {
    const obj: any = {};
    message.InvitationID !== undefined &&
      (obj.InvitationID = message.InvitationID);
    message.Membership !== undefined &&
      (obj.Membership = message.Membership
        ? InvitationTarget_OrganizationMembership.toJSON(message.Membership)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetOrganizationInvitationUserInfoReq>, I>,
  >(object: I): SetOrganizationInvitationUserInfoReq {
    const message = createBaseSetOrganizationInvitationUserInfoReq();
    message.InvitationID = object.InvitationID ?? '';
    message.Membership =
      object.Membership !== undefined && object.Membership !== null
        ? InvitationTarget_OrganizationMembership.fromPartial(object.Membership)
        : undefined;
    return message;
  },
};

function createBaseSetOrganizationInvitationUserInfoResp(): SetOrganizationInvitationUserInfoResp {
  return { Invitation: undefined };
}

export const SetOrganizationInvitationUserInfoResp = {
  encode(
    message: SetOrganizationInvitationUserInfoResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Invitation !== undefined) {
      Invitation.encode(message.Invitation, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetOrganizationInvitationUserInfoResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetOrganizationInvitationUserInfoResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Invitation = Invitation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetOrganizationInvitationUserInfoResp {
    return {
      Invitation: isSet(object.Invitation)
        ? Invitation.fromJSON(object.Invitation)
        : undefined,
    };
  },

  toJSON(message: SetOrganizationInvitationUserInfoResp): unknown {
    const obj: any = {};
    message.Invitation !== undefined &&
      (obj.Invitation = message.Invitation
        ? Invitation.toJSON(message.Invitation)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetOrganizationInvitationUserInfoResp>, I>,
  >(object: I): SetOrganizationInvitationUserInfoResp {
    const message = createBaseSetOrganizationInvitationUserInfoResp();
    message.Invitation =
      object.Invitation !== undefined && object.Invitation !== null
        ? Invitation.fromPartial(object.Invitation)
        : undefined;
    return message;
  },
};

function createBaseRevokeSharingReq(): RevokeSharingReq {
  return { UserID: '', PatientID: '' };
}

export const RevokeSharingReq = {
  encode(
    message: RevokeSharingReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.PatientID !== '') {
      writer.uint32(18).string(message.PatientID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevokeSharingReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevokeSharingReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.PatientID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RevokeSharingReq {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
    };
  },

  toJSON(message: RevokeSharingReq): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RevokeSharingReq>, I>>(
    object: I,
  ): RevokeSharingReq {
    const message = createBaseRevokeSharingReq();
    message.UserID = object.UserID ?? '';
    message.PatientID = object.PatientID ?? '';
    return message;
  },
};

function createBaseRevokeSharingResp(): RevokeSharingResp {
  return { Patient: undefined, Invitation: undefined };
}

export const RevokeSharingResp = {
  encode(
    message: RevokeSharingResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    if (message.Invitation !== undefined) {
      Invitation.encode(message.Invitation, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevokeSharingResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevokeSharingResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        case 2:
          message.Invitation = Invitation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RevokeSharingResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
      Invitation: isSet(object.Invitation)
        ? Invitation.fromJSON(object.Invitation)
        : undefined,
    };
  },

  toJSON(message: RevokeSharingResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    message.Invitation !== undefined &&
      (obj.Invitation = message.Invitation
        ? Invitation.toJSON(message.Invitation)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RevokeSharingResp>, I>>(
    object: I,
  ): RevokeSharingResp {
    const message = createBaseRevokeSharingResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    message.Invitation =
      object.Invitation !== undefined && object.Invitation !== null
        ? Invitation.fromPartial(object.Invitation)
        : undefined;
    return message;
  },
};

function createBaseSetPatientTeamMembersReq(): SetPatientTeamMembersReq {
  return { PatientID: '', UserIDs: [] };
}

export const SetPatientTeamMembersReq = {
  encode(
    message: SetPatientTeamMembersReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    for (const v of message.UserIDs) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientTeamMembersReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientTeamMembersReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.UserIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientTeamMembersReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      UserIDs: Array.isArray(object?.UserIDs)
        ? object.UserIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: SetPatientTeamMembersReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    if (message.UserIDs) {
      obj.UserIDs = message.UserIDs.map((e) => e);
    } else {
      obj.UserIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientTeamMembersReq>, I>>(
    object: I,
  ): SetPatientTeamMembersReq {
    const message = createBaseSetPatientTeamMembersReq();
    message.PatientID = object.PatientID ?? '';
    message.UserIDs = object.UserIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseSetPatientTeamMembersResp(): SetPatientTeamMembersResp {
  return { Patient: undefined };
}

export const SetPatientTeamMembersResp = {
  encode(
    message: SetPatientTeamMembersResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientTeamMembersResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientTeamMembersResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientTeamMembersResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: SetPatientTeamMembersResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientTeamMembersResp>, I>>(
    object: I,
  ): SetPatientTeamMembersResp {
    const message = createBaseSetPatientTeamMembersResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseSetUserOrganizationRolesReq(): SetUserOrganizationRolesReq {
  return { UserID: '', OrganizationID: '', Roles: [] };
}

export const SetUserOrganizationRolesReq = {
  encode(
    message: SetUserOrganizationRolesReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(18).string(message.OrganizationID);
    }
    writer.uint32(26).fork();
    for (const v of message.Roles) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetUserOrganizationRolesReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetUserOrganizationRolesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.OrganizationID = reader.string();
          break;
        case 3:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.Roles.push(reader.int32() as any);
            }
          } else {
            message.Roles.push(reader.int32() as any);
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetUserOrganizationRolesReq {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      Roles: Array.isArray(object?.Roles)
        ? object.Roles.map((e: any) => organizationUserRoleTypeFromJSON(e))
        : [],
    };
  },

  toJSON(message: SetUserOrganizationRolesReq): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    if (message.Roles) {
      obj.Roles = message.Roles.map((e) => organizationUserRoleTypeToJSON(e));
    } else {
      obj.Roles = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetUserOrganizationRolesReq>, I>>(
    object: I,
  ): SetUserOrganizationRolesReq {
    const message = createBaseSetUserOrganizationRolesReq();
    message.UserID = object.UserID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.Roles = object.Roles?.map((e) => e) || [];
    return message;
  },
};

function createBaseSetUserOrganizationRolesResp(): SetUserOrganizationRolesResp {
  return { Organization: undefined, Patients: [] };
}

export const SetUserOrganizationRolesResp = {
  encode(
    message: SetUserOrganizationRolesResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    for (const v of message.Patients) {
      Patient.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetUserOrganizationRolesResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetUserOrganizationRolesResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        case 2:
          message.Patients.push(Patient.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetUserOrganizationRolesResp {
    return {
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
      Patients: Array.isArray(object?.Patients)
        ? object.Patients.map((e: any) => Patient.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetUserOrganizationRolesResp): unknown {
    const obj: any = {};
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    if (message.Patients) {
      obj.Patients = message.Patients.map((e) =>
        e ? Patient.toJSON(e) : undefined,
      );
    } else {
      obj.Patients = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetUserOrganizationRolesResp>, I>>(
    object: I,
  ): SetUserOrganizationRolesResp {
    const message = createBaseSetUserOrganizationRolesResp();
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    message.Patients =
      object.Patients?.map((e) => Patient.fromPartial(e)) || [];
    return message;
  },
};

export interface AccessService {
  /** Shared by me or shared by staff (if Me has admin organization role) */
  SharedByMeStream(
    request: DeepPartial<SharedByMeStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<InvitationListStreamResp>;
  SharedWithMeStream(
    request: DeepPartial<SharedWithMeStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<InvitationListStreamResp>;
  /** Only for Recipient (Recipient.UserID == meID) */
  GetInvitationByToken(
    request: DeepPartial<GetInvitationByTokenReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetInvitationByTokenResp>;
  /**
   * Invite:
   * - invite user to organization (only first roles)
   * - sharing patient for user which is not an employee of this patient's organization
   *
   * Permission:
   * - `OrganizationPermissions.CanEditStaff` (if Target == Organization)
   * - `PatientPermissions.CanShare` (if Target == Patient)
   *
   * Errors:
   * 1. Common errors:
   * - type: RequiredArgumentMissingError, Argument: Recipient/recipient contacts/Target - (if the corresponding argument is empty)
   * - type: NotFoundError, Entity: recipient (if user not found by `InviteReq.Recipient.UserID`)
   * 2. If Target == Organization:
   * - type: RequiredArgumentMissingError, Argument: target organizationID - (if the corresponding argument is empty)
   * - type: BadRequestError, Reason: forbidden to invite to owner role (if remote organization found by `InviteReq.Target.Organization.Role` == `TypeOwner`)
   * - type: InvalidArgumentValueError, Argument: target organization user role - (if `InviteReq.Target.Organization.Role` == `TypeInvalidValue`)
   * - type: NotFoundError, Entity: target organization (if organization not found by `InviteReq.Target.Organization.OrganisationID`)
   * - type: BadRequestError, Reason: target organization deleted (if remote organization found by `InviteReq.Target.Organization.OrganisationID`)
   * - type: BadRequestError, Reason: recipient already has organization role
   * - type: AlreadyExistError, Entity: invitation to this organization role
   * 3. If Target == Patient:
   * - type: RequiredArgumentMissingError, Argument: target patientID - (if the corresponding argument is empty)
   * - type: NotFoundError, Entity: target patient (if organization not found by `InviteReq.Target.Patient.PatientID`)
   * - type: BadRequestError, Reason: target patient deleted (if remote patient found by `InviteReq.Target.Patient.PatientID`)
   * - type: BadRequestError, Reason: recipient is an organization employee
   * - type: BadRequestError, Reason: recipient is a patient team member
   * - type: AlreadyExistError, Entity: accepted patient sharing
   * - type: AlreadyExistError, Entity: invitation to this patient
   */
  Invite(
    request: DeepPartial<InviteReq>,
    metadata?: grpc.Metadata,
  ): Promise<InviteResp>;
  /**
   * Cancel not accepted invitation (`InvitationStatus` == `StatusPending`)
   *
   * Permission:
   * - Me.ID == `invitation.Created.By`
   * - `OrganizationPermissions.CanEditStaff`
   * - `PatientPermissions.CanCancelOthersSharings`
   *
   * Errors:
   * - type: BadRequestError, Reason: invitation closed
   */
  CancelInvitation(
    request: DeepPartial<CancelInvitationReq>,
    metadata?: grpc.Metadata,
  ): Promise<CancelInvitationResp>;
  /**
   * Accept invitation:
   * - if target == organization:
   * - accept current invitation
   * - add organization role
   * - cancel all pending/accepted sharing invitations
   * - replace sharing in organization's patient to team member role
   *
   * Errors:
   * - type: BadRequestError, Reason: invitation already accepted
   * - type: BadRequestError, Reason: invitation canceled
   * - type: BadRequestError, Reason: invitation closed
   * - type: BadRequestError, Reason: incorrect secret
   */
  AcceptInvitation(
    request: DeepPartial<AcceptInvitationReq>,
    metadata?: grpc.Metadata,
  ): Promise<AcceptInvitationResp>;
  /**
   * Edit user information in pending invitation (name, roles, job positions):
   *
   * Permission:
   * - Me.ID == `invitation.Created.By`
   * - `OrganizationPermissions.CanEditStaff`
   */
  SetOrganizationInvitationUserInfo(
    request: DeepPartial<SetOrganizationInvitationUserInfoReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetOrganizationInvitationUserInfoResp>;
  /**
   * Revoke sharing
   *
   * Permission: PatientPermissions.CanCancelOthersSharings || Sharing.Created.By == meID
   */
  RevokeSharing(
    request: DeepPartial<RevokeSharingReq>,
    metadata?: grpc.Metadata,
  ): Promise<RevokeSharingResp>;
  /**
   * Set patient doctors(Users)
   *
   * Permission: PatientPermissions.CanEditTeam
   */
  SetPatientTeamMembers(
    request: DeepPartial<SetPatientTeamMembersReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientTeamMembersResp>;
  /**
   * Set organization user roles (if user is already an employee) or
   * Delete User from Organization: if Roles are empty -> the user loses access to the organization
   *
   * Permission: OrganizationPermissions.CanEditStaff
   *
   * Errors:
   * - type: BadRequestError, Reason: Owner role cannot be added
   * - type: BadRequestError, Reason: Owner role cannot be deleted
   */
  SetUserOrganizationRoles(
    request: DeepPartial<SetUserOrganizationRolesReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetUserOrganizationRolesResp>;
}

export class AccessServiceClientImpl implements AccessService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.SharedByMeStream = this.SharedByMeStream.bind(this);
    this.SharedWithMeStream = this.SharedWithMeStream.bind(this);
    this.GetInvitationByToken = this.GetInvitationByToken.bind(this);
    this.Invite = this.Invite.bind(this);
    this.CancelInvitation = this.CancelInvitation.bind(this);
    this.AcceptInvitation = this.AcceptInvitation.bind(this);
    this.SetOrganizationInvitationUserInfo =
      this.SetOrganizationInvitationUserInfo.bind(this);
    this.RevokeSharing = this.RevokeSharing.bind(this);
    this.SetPatientTeamMembers = this.SetPatientTeamMembers.bind(this);
    this.SetUserOrganizationRoles = this.SetUserOrganizationRoles.bind(this);
  }

  SharedByMeStream(
    request: DeepPartial<SharedByMeStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<InvitationListStreamResp> {
    return this.rpc.invoke(
      AccessServiceSharedByMeStreamDesc,
      SharedByMeStreamReq.fromPartial(request),
      metadata,
    );
  }

  SharedWithMeStream(
    request: DeepPartial<SharedWithMeStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<InvitationListStreamResp> {
    return this.rpc.invoke(
      AccessServiceSharedWithMeStreamDesc,
      SharedWithMeStreamReq.fromPartial(request),
      metadata,
    );
  }

  GetInvitationByToken(
    request: DeepPartial<GetInvitationByTokenReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetInvitationByTokenResp> {
    return this.rpc.unary(
      AccessServiceGetInvitationByTokenDesc,
      GetInvitationByTokenReq.fromPartial(request),
      metadata,
    );
  }

  Invite(
    request: DeepPartial<InviteReq>,
    metadata?: grpc.Metadata,
  ): Promise<InviteResp> {
    return this.rpc.unary(
      AccessServiceInviteDesc,
      InviteReq.fromPartial(request),
      metadata,
    );
  }

  CancelInvitation(
    request: DeepPartial<CancelInvitationReq>,
    metadata?: grpc.Metadata,
  ): Promise<CancelInvitationResp> {
    return this.rpc.unary(
      AccessServiceCancelInvitationDesc,
      CancelInvitationReq.fromPartial(request),
      metadata,
    );
  }

  AcceptInvitation(
    request: DeepPartial<AcceptInvitationReq>,
    metadata?: grpc.Metadata,
  ): Promise<AcceptInvitationResp> {
    return this.rpc.unary(
      AccessServiceAcceptInvitationDesc,
      AcceptInvitationReq.fromPartial(request),
      metadata,
    );
  }

  SetOrganizationInvitationUserInfo(
    request: DeepPartial<SetOrganizationInvitationUserInfoReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetOrganizationInvitationUserInfoResp> {
    return this.rpc.unary(
      AccessServiceSetOrganizationInvitationUserInfoDesc,
      SetOrganizationInvitationUserInfoReq.fromPartial(request),
      metadata,
    );
  }

  RevokeSharing(
    request: DeepPartial<RevokeSharingReq>,
    metadata?: grpc.Metadata,
  ): Promise<RevokeSharingResp> {
    return this.rpc.unary(
      AccessServiceRevokeSharingDesc,
      RevokeSharingReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientTeamMembers(
    request: DeepPartial<SetPatientTeamMembersReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientTeamMembersResp> {
    return this.rpc.unary(
      AccessServiceSetPatientTeamMembersDesc,
      SetPatientTeamMembersReq.fromPartial(request),
      metadata,
    );
  }

  SetUserOrganizationRoles(
    request: DeepPartial<SetUserOrganizationRolesReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetUserOrganizationRolesResp> {
    return this.rpc.unary(
      AccessServiceSetUserOrganizationRolesDesc,
      SetUserOrganizationRolesReq.fromPartial(request),
      metadata,
    );
  }
}

export const AccessServiceDesc = {
  serviceName: 'com.diagnocat.core.AccessService',
};

export const AccessServiceSharedByMeStreamDesc: UnaryMethodDefinitionish = {
  methodName: 'SharedByMeStream',
  service: AccessServiceDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return SharedByMeStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...InvitationListStreamResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AccessServiceSharedWithMeStreamDesc: UnaryMethodDefinitionish = {
  methodName: 'SharedWithMeStream',
  service: AccessServiceDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return SharedWithMeStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...InvitationListStreamResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AccessServiceGetInvitationByTokenDesc: UnaryMethodDefinitionish = {
  methodName: 'GetInvitationByToken',
  service: AccessServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetInvitationByTokenReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...GetInvitationByTokenResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AccessServiceInviteDesc: UnaryMethodDefinitionish = {
  methodName: 'Invite',
  service: AccessServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return InviteReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...InviteResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AccessServiceCancelInvitationDesc: UnaryMethodDefinitionish = {
  methodName: 'CancelInvitation',
  service: AccessServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CancelInvitationReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...CancelInvitationResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AccessServiceAcceptInvitationDesc: UnaryMethodDefinitionish = {
  methodName: 'AcceptInvitation',
  service: AccessServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AcceptInvitationReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AcceptInvitationResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AccessServiceSetOrganizationInvitationUserInfoDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetOrganizationInvitationUserInfo',
    service: AccessServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetOrganizationInvitationUserInfoReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetOrganizationInvitationUserInfoResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const AccessServiceRevokeSharingDesc: UnaryMethodDefinitionish = {
  methodName: 'RevokeSharing',
  service: AccessServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RevokeSharingReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...RevokeSharingResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AccessServiceSetPatientTeamMembersDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientTeamMembers',
    service: AccessServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientTeamMembersReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientTeamMembersResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const AccessServiceSetUserOrganizationRolesDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetUserOrganizationRoles',
    service: AccessServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetUserOrganizationRolesReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetUserOrganizationRolesResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
    const upStreamCodes = [2, 4, 8, 9, 10, 13, 14, 15];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              observer.error(new Error(`Error ${code} ${message}`));
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
