/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { BBox, FloatTuple2D } from '../model/dto_common_geometry';
import { MedicalImageFeatures } from '../model/dto_common_image_medical_features';

export const protobufPackage = 'com.diagnocat.model';

export enum IOXRaySubimageType {
  IOXRay_SUBIMAGE_TYPE_INTRAORAL_XRAY = 0,
  IOXRay_SUBIMAGE_TYPE_PHOTO = 1,
  IOXRay_SUBIMAGE_TYPE_PANORAMIC_XRAY = 2,
  IOXRay_SUBIMAGE_TYPE_CBCT_SLICE = 3,
  UNRECOGNIZED = -1,
}

export function iOXRaySubimageTypeFromJSON(object: any): IOXRaySubimageType {
  switch (object) {
    case 0:
    case 'IOXRay_SUBIMAGE_TYPE_INTRAORAL_XRAY':
      return IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_INTRAORAL_XRAY;
    case 1:
    case 'IOXRay_SUBIMAGE_TYPE_PHOTO':
      return IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_PHOTO;
    case 2:
    case 'IOXRay_SUBIMAGE_TYPE_PANORAMIC_XRAY':
      return IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_PANORAMIC_XRAY;
    case 3:
    case 'IOXRay_SUBIMAGE_TYPE_CBCT_SLICE':
      return IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_CBCT_SLICE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return IOXRaySubimageType.UNRECOGNIZED;
  }
}

export function iOXRaySubimageTypeToJSON(object: IOXRaySubimageType): string {
  switch (object) {
    case IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_INTRAORAL_XRAY:
      return 'IOXRay_SUBIMAGE_TYPE_INTRAORAL_XRAY';
    case IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_PHOTO:
      return 'IOXRay_SUBIMAGE_TYPE_PHOTO';
    case IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_PANORAMIC_XRAY:
      return 'IOXRay_SUBIMAGE_TYPE_PANORAMIC_XRAY';
    case IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_CBCT_SLICE:
      return 'IOXRay_SUBIMAGE_TYPE_CBCT_SLICE';
    case IOXRaySubimageType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * message ToothToSubimage {
 *    string ToothID    = 1;
 *    string SubimageID = 2;
 *  }
 */
export interface ReportIOXRayGP {
  Subimages: IOXRaySubimage[];
}

export interface IOXRaySubimage {
  ID: string;
  Type: IOXRaySubimageType;
  /** `TargetAssetID`: [required] */
  TargetAssetID: string;
  /** `BBox`: [required] */
  BBox: BBox | undefined;
  /** `Scale`: [optional]: Defines number of millimeters per pixel for corresponding axis. */
  Scale: FloatTuple2D | undefined;
  /** `OrientationAngle`: [required]: A counter clockwise angle to rotate an image into correct orientation */
  OrientationAngle: number;
  MedicalImageFeatures: MedicalImageFeatures | undefined;
}

function createBaseReportIOXRayGP(): ReportIOXRayGP {
  return { Subimages: [] };
}

export const ReportIOXRayGP = {
  encode(
    message: ReportIOXRayGP,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Subimages) {
      IOXRaySubimage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportIOXRayGP {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportIOXRayGP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Subimages.push(
            IOXRaySubimage.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportIOXRayGP {
    return {
      Subimages: Array.isArray(object?.Subimages)
        ? object.Subimages.map((e: any) => IOXRaySubimage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ReportIOXRayGP): unknown {
    const obj: any = {};
    if (message.Subimages) {
      obj.Subimages = message.Subimages.map((e) =>
        e ? IOXRaySubimage.toJSON(e) : undefined,
      );
    } else {
      obj.Subimages = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportIOXRayGP>, I>>(
    object: I,
  ): ReportIOXRayGP {
    const message = createBaseReportIOXRayGP();
    message.Subimages =
      object.Subimages?.map((e) => IOXRaySubimage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIOXRaySubimage(): IOXRaySubimage {
  return {
    ID: '',
    Type: 0,
    TargetAssetID: '',
    BBox: undefined,
    Scale: undefined,
    OrientationAngle: 0,
    MedicalImageFeatures: undefined,
  };
}

export const IOXRaySubimage = {
  encode(
    message: IOXRaySubimage,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Type !== 0) {
      writer.uint32(16).int32(message.Type);
    }
    if (message.TargetAssetID !== '') {
      writer.uint32(26).string(message.TargetAssetID);
    }
    if (message.BBox !== undefined) {
      BBox.encode(message.BBox, writer.uint32(34).fork()).ldelim();
    }
    if (message.Scale !== undefined) {
      FloatTuple2D.encode(message.Scale, writer.uint32(42).fork()).ldelim();
    }
    if (message.OrientationAngle !== 0) {
      writer.uint32(49).double(message.OrientationAngle);
    }
    if (message.MedicalImageFeatures !== undefined) {
      MedicalImageFeatures.encode(
        message.MedicalImageFeatures,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IOXRaySubimage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIOXRaySubimage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Type = reader.int32() as any;
          break;
        case 3:
          message.TargetAssetID = reader.string();
          break;
        case 4:
          message.BBox = BBox.decode(reader, reader.uint32());
          break;
        case 5:
          message.Scale = FloatTuple2D.decode(reader, reader.uint32());
          break;
        case 6:
          message.OrientationAngle = reader.double();
          break;
        case 11:
          message.MedicalImageFeatures = MedicalImageFeatures.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): IOXRaySubimage {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Type: isSet(object.Type) ? iOXRaySubimageTypeFromJSON(object.Type) : 0,
      TargetAssetID: isSet(object.TargetAssetID)
        ? String(object.TargetAssetID)
        : '',
      BBox: isSet(object.BBox) ? BBox.fromJSON(object.BBox) : undefined,
      Scale: isSet(object.Scale)
        ? FloatTuple2D.fromJSON(object.Scale)
        : undefined,
      OrientationAngle: isSet(object.OrientationAngle)
        ? Number(object.OrientationAngle)
        : 0,
      MedicalImageFeatures: isSet(object.MedicalImageFeatures)
        ? MedicalImageFeatures.fromJSON(object.MedicalImageFeatures)
        : undefined,
    };
  },

  toJSON(message: IOXRaySubimage): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Type !== undefined &&
      (obj.Type = iOXRaySubimageTypeToJSON(message.Type));
    message.TargetAssetID !== undefined &&
      (obj.TargetAssetID = message.TargetAssetID);
    message.BBox !== undefined &&
      (obj.BBox = message.BBox ? BBox.toJSON(message.BBox) : undefined);
    message.Scale !== undefined &&
      (obj.Scale = message.Scale
        ? FloatTuple2D.toJSON(message.Scale)
        : undefined);
    message.OrientationAngle !== undefined &&
      (obj.OrientationAngle = message.OrientationAngle);
    message.MedicalImageFeatures !== undefined &&
      (obj.MedicalImageFeatures = message.MedicalImageFeatures
        ? MedicalImageFeatures.toJSON(message.MedicalImageFeatures)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<IOXRaySubimage>, I>>(
    object: I,
  ): IOXRaySubimage {
    const message = createBaseIOXRaySubimage();
    message.ID = object.ID ?? '';
    message.Type = object.Type ?? 0;
    message.TargetAssetID = object.TargetAssetID ?? '';
    message.BBox =
      object.BBox !== undefined && object.BBox !== null
        ? BBox.fromPartial(object.BBox)
        : undefined;
    message.Scale =
      object.Scale !== undefined && object.Scale !== null
        ? FloatTuple2D.fromPartial(object.Scale)
        : undefined;
    message.OrientationAngle = object.OrientationAngle ?? 0;
    message.MedicalImageFeatures =
      object.MedicalImageFeatures !== undefined &&
      object.MedicalImageFeatures !== null
        ? MedicalImageFeatures.fromPartial(object.MedicalImageFeatures)
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
