import React from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { ToothType, toothTypeMessages } from '@/entities/tooth';

import styles from './ToothChartTag.module.scss';

type ToothChartTagProps = {
  toothType: ToothType;
  active: boolean;
  amount: number;
  onClick: (toothType: ToothType) => void;
};

const toothTypeStyle: Record<ToothType, string> = {
  unhealthy: styles.toothTypeUnhealthy,
  healthy: styles.toothTypeHealthy,
  treated: styles.toothTypeTreated,
  missing: styles.toothTypeMissing,
  lowProbability: styles.toothTypeLowProbability,
  all: styles.toothTypeAll,
};

const InternalToothChartTag = (props: ToothChartTagProps) => {
  const { toothType, active, amount, onClick } = props;

  const { formatMessage } = useIntl();

  return (
    <button
      className={cn(
        styles.container,
        'p5',
        active && styles.active,
        toothTypeStyle[toothType],
      )}
      type="button"
      onClick={() => onClick(toothType)}
    >
      {formatMessage(toothTypeMessages[toothType])}
      {!!amount && <span className="amount">{amount}</span>}
    </button>
  );
};

export const ToothChartTag = React.memo(InternalToothChartTag);
