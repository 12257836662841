// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GetFullAccessModal-module__modalBody--yOvus\\+9T{display:grid;gap:24px}", "",{"version":3,"sources":["webpack://./src/widgets/GetFullAccessModal/ui/GetFullAccessModal.module.scss"],"names":[],"mappings":"AAAA,iDACE,YAAA,CACA,QAAA","sourcesContent":[".modalBody {\n  display: grid;\n  gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "GetFullAccessModal-module__modalBody--yOvus+9T"
};
export default ___CSS_LOADER_EXPORT___;
