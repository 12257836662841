import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/model/store';

import { getPatientFullName } from '../lib';

import { patientAdapter } from './patientSlice';

export const patientSelectors = patientAdapter.getSelectors(
  (state: RootState) => state.patient,
);

export const { selectAll, selectById, selectTotal, selectEntities, selectIds } =
  patientSelectors;

export const selectPatientByID = (patientID: string) => (state: RootState) =>
  selectById(state, patientID);

export const selectCounters = createSelector(
  (
    state: RootState,
  ): {
    sharedWithMeCount: number;
    sharedByMeCount: number;
    totalOrganizationPatientCount: number;
  } => ({
    sharedWithMeCount: state.patient.sharedWithMeCount,
    sharedByMeCount: state.patient.sharedByMeCount,
    totalOrganizationPatientCount: state.patient.totalOrganizationPatientCount,
  }),

  (counters) => counters,
);

export const selectAllPatientDoctors =
  (patientID: string) => (state: RootState) =>
    patientSelectors.selectById(state, patientID)?.Doctors;

export const selectPatientDoctorsIDs =
  (patientID: string) => (state: RootState) =>
    patientSelectors
      .selectById(state, patientID)
      ?.Doctors.map((patientDoctor) => patientDoctor.UserID);

export const selectTreatingDoctors =
  (patientID: string) => (state: RootState) =>
    patientSelectors
      .selectById(state, patientID)
      ?.Doctors.filter((doctor) => doctor.TeamMember !== undefined);

export const selectTreatingDoctorsIDs =
  (patientID: string) => (state: RootState) =>
    patientSelectors
      .selectById(state, patientID)
      ?.Doctors.filter((doctor) => doctor.TeamMember !== undefined)
      .map((doctor) => doctor.UserID);

export const selectSharedDoctors = (patientID: string) => (state: RootState) =>
  patientSelectors
    .selectById(state, patientID)
    ?.Doctors.filter((doctor) => doctor.Sharing !== undefined);

// TODO: [1/l] Replace instances where patient is retrieved from the store solely for the purpose of obtaining the name (getPatientFullName) with selectPatientFullName
export const selectPatientFullName = (patientID: string) =>
  createSelector(
    (state: RootState) => patientSelectors.selectById(state, patientID),
    (patient) => getPatientFullName(patient),
  );

export const selectLoading = (state: RootState) => state.patient.loading;
