import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ErrorText, Modal, ModalProps } from '@/shared/ui';
import { useMedia } from '@/shared/hooks';
import { ApiError } from '@/shared/api/api';

import { DoctorsSelect, useSetDoctors } from '@/entities/doctors';

import {
  ADD_DOCTORS_FORM_ID,
  AddDoctorsPayload,
  addDoctorsSchema,
} from '../../config';
import { useOrganizationDoctorsOptions } from '../../hooks/useOrganizationDoctorsOptions';

import styles from './AddDoctorsModal.module.scss';

type AddDoctorsModalProps = {
  patientID?: string;
  currentDoctorIDs?: string[];
} & Pick<ModalProps, 'isOpen' | 'onClose'>;

export const AddDoctorsModal: FC<AddDoctorsModalProps> = (props) => {
  const { isOpen, onClose, patientID = '', currentDoctorIDs } = props;

  const [globalFormError, setGlobalFormError] = useState<string>();

  const { formatMessage } = useIntl();
  const addDoctors = useSetDoctors();
  const doctorsOptions = useOrganizationDoctorsOptions();

  const { isPhone } = useMedia();

  const buttonSize = isPhone ? 'medium' : 'large';

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = useForm<AddDoctorsPayload>({
    resolver: yupResolver(addDoctorsSchema),
  });

  const onSubmit: SubmitHandler<AddDoctorsPayload> = async (data) => {
    const { treatingDoctors } = data;

    try {
      setGlobalFormError(undefined);

      await addDoctors({ patientID, doctorIDs: treatingDoctors as string[] });

      if (onClose) {
        onClose();
      }
    } catch (error) {
      setGlobalFormError((error as ApiError).message);
    }
  };

  // Set default
  useEffect(() => {
    reset({ treatingDoctors: currentDoctorIDs });
  }, [patientID, currentDoctorIDs]);

  return (
    <Modal
      containerClassName={styles.container}
      shouldCloseOnOverlayClick={false}
      title={formatMessage({
        id: 'addDoctorModal.title',
        defaultMessage: 'Add treating doctors',
      })}
      isOpen={isOpen}
      onClose={onClose}
      applyButtonText={formatMessage({
        id: 'addDoctorModal.applyButtonText',
        defaultMessage: 'Add doctors',
      })}
      applyButtonProps={{
        type: 'submit',
        form: ADD_DOCTORS_FORM_ID,
        disabled: !isDirty,
        loading: isSubmitting,
        size: buttonSize,
      }}
    >
      <form
        id={ADD_DOCTORS_FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
        className={styles.form}
      >
        <Controller
          control={control}
          name="treatingDoctors"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <DoctorsSelect
              options={doctorsOptions}
              value={value as string[]}
              onChange={(event) => onChange(event)}
              error={error?.message}
            />
          )}
        />

        <ErrorText className={styles.formGlobalError} error={globalFormError} />
      </form>
    </Modal>
  );
};
