import { useAppDispatch } from '@/shared/hooks';
import { InviteReq } from '@/shared/api/protocol_gen/api/core/svc_access';

import { accessModel } from '@/entities/access';
import { ModalID, modalModel } from '@/entities/modal';

export const useInviteForSharingPatient = () => {
  const dispatch = useAppDispatch();

  const openProtectedSharingModal = ({
    accessCode,
    invitationCreateDate,
  }: {
    accessCode: string;
    invitationCreateDate: Date | number;
  }) => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.ProtectedSharing,
        data: { accessCode, invitationCreateDate },
      }),
    );
  };

  return async ({
    comment,
    sharedPatientDoctorEmail,
    patientID,
  }: {
    patientID: string;
    sharedPatientDoctorEmail: string;
    comment: string;
  }) => {
    const { CreatedInvitation } = await dispatch(
      accessModel.thunks.inviteForSharingPatient({
        Recipient: { Email: sharedPatientDoctorEmail, Phone: '' },
        Comment: comment,
        Target: { Patient: { PatientID: patientID } },
      } as InviteReq),
    ).unwrap();

    dispatch(accessModel.actions.setOne(CreatedInvitation));

    openProtectedSharingModal({
      invitationCreateDate: CreatedInvitation?.Created?.At ?? 0,
      accessCode: CreatedInvitation?.Secret ?? '',
    });
  };
};
