import { ChangeEvent, FC, useCallback } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormattedNumber } from 'react-intl/lib';

import { Button, Modal, RadioGroup, toastCaller } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { ProductModel } from '@/shared/api/protocol_gen/api/billing_new/dto_product_new';
import { ApiError } from '@/shared/api/api';
import { ToastErrors, toastErrors } from '@/shared/config';

import { billingModel } from '@/entities/billing';
import { ModalID, modalModel } from '@/entities/modal';

import { useTransformTopUpAddonsOptions } from '../../hooks/useTransformTopUpAddonsOptions';

import styles from './TopUpLimitsModal.module.scss';

type TopUpLimitsModalProps = {
  availableAddons: ProductModel[];
  className?: string;
  testID?: string;
};

export const TopUpLimitsModal: FC<TopUpLimitsModalProps> = (props) => {
  const { className, testID, availableAddons } = props;

  const { formatMessage } = useIntl();

  const { visible } = useAppSelector(
    modalModel.selectors.selectTopUpReportsModal,
  );

  const { ID } = useAppSelector(billingModel.selectors.selectAccount);

  const currentSubscription = useAppSelector(
    billingModel.selectors.selectCurrentSubscription,
  );

  const selectedTopUpPackageID = useAppSelector(
    billingModel.selectors.selectSelectedTopUpPackageID,
  );

  const dispatch = useAppDispatch();

  const availableAddonsOptions =
    useTransformTopUpAddonsOptions(availableAddons);

  const onSelectedAddonChange = (e: ChangeEvent<HTMLInputElement>) =>
    dispatch(billingModel.actions.setSelectedTopUpPackageID(e.target.value));

  const selectedAddon = availableAddons.find(
    (addon) => addon.ID === selectedTopUpPackageID,
  );
  const currency = selectedAddon?.Currency ?? 'USD';
  const price = selectedAddon?.Price ?? '0';

  const handlePurchaseClick = useCallback(async () => {
    try {
      const { Order, InvoicePaymentURL } = await dispatch(
        billingModel.thunks.createOrder({
          AccountID: ID,
          AddonAdd: {
            Addon: selectedAddon,
            ToSubscriptionStateID: currentSubscription.ID,
          },
        }),
      ).unwrap();

      if (InvoicePaymentURL) {
        window.open(InvoicePaymentURL, '_blank');
      }

      dispatch(billingModel.actions.updateOrders(Order));
      dispatch(modalModel.actions.closeModal(ModalID.TopUpReportLimitsModal));
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  }, [ID, selectedAddon, currentSubscription.ID]);

  return (
    <Modal
      isOpen={visible}
      hideFooter
      data-testid={testID}
      bodyClassName={styles.body}
      className={cn(styles.container, className)}
      title={formatMessage({
        id: 'billing.topUpLimitsModal.title',
        defaultMessage: 'Top up report limits',
      })}
      onClose={() =>
        dispatch(modalModel.actions.closeModal(ModalID.TopUpReportLimitsModal))
      }
    >
      <RadioGroup
        variant="tab"
        onChange={onSelectedAddonChange}
        items={availableAddonsOptions}
        value={selectedTopUpPackageID}
      />

      <div className={styles.priceContainer}>
        <div className={cn(styles.price, 'h3')}>
          <FormattedNumber
            maximumFractionDigits={0}
            value={parseInt(price, 10)}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currencyDisplay="narrowSymbol"
            currency={currency}
          />
        </div>

        <div className={cn(styles.disclaimer, 'p3t')}>
          <FormattedMessage
            id="billing.topUpLimitsModal.disclaimer"
            defaultMessage="Valid until your subscription next billing date"
          />
        </div>
      </div>

      <Button className={styles.button} onClick={handlePurchaseClick}>
        <FormattedMessage id="global.buy" defaultMessage="Buy" />
      </Button>
    </Modal>
  );
};
