import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

import { Tooth as ReportTooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { MedicalImageInterface } from '@/shared/config';
import {
  Breadcrumbs,
  Button,
  Column,
  Layout,
  MedicalImage,
  Skeleton,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui/';
import { useAppSelector, useMedia } from '@/shared/hooks';

import {
  getDisplayToothNumber,
  toothModel,
  useAddAndRemoveMedicalImage,
} from '@/entities/tooth';
import {
  assetsModel,
  useGetSelectedMedicalImages,
  useGetMedicalImageGroups,
  getFileSrc,
  makeImageViewOptions,
} from '@/entities/assets';
import { reportsModel, useReportsBreadcrumbs } from '@/entities/reports';
import { usePatientBreadcrumbs } from '@/entities/patient';
import { organizationModel } from '@/entities/organization';

import { SimplifiedToothChart } from '@/features/simplifiedToothChart';
import { useReportDataStream } from '@/features/reportStream';
import { useApproveTooth } from '@/features/approveTooth';

import { PanoImage } from '@/widgets/PanoImage';
import { ZoomedMedicalImageModal } from '@/widgets/ZoomedMedicalImageModal';
import { Header } from '@/widgets/Header';
import { MedicalImagesGroup } from '@/widgets/MedicalImageGroup';
import { MPRWidget } from '@/widgets/MPR/ui/MPRWidget/MPRWidget';
import { MprViewerModal } from '@/widgets/MPR';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { ToothCard } from '@/widgets/ToothCard';
import { AddConditionModal } from '@/widgets/AddConditionModal';

import { SelectedMedicalImages } from './ui/SelectedMedicalImages/SelectedMedicalImages';
import styles from './Tooth.module.scss';
import { PANO_TOOLS, SCELETON_DEFAULT_HEIGHT } from './config/constants';

type ToothProps = {
  className?: string;
};

export const Tooth: FC<ToothProps> = (props) => {
  const { className } = props;

  const [panoImageToggled, setPanoImageToggled] = useState(true);
  const [panoContainerWidth] = useState(0);
  const [draggedMedicalImage, setDraggedMedicalImage] =
    useState<MedicalImageInterface>();

  const { reportID = '', patientID = '', toothID = '' } = useParams();
  const { isSmallDesktop, isMobile, isTablet } = useMedia();

  const selectedMedicalImages = useGetSelectedMedicalImages(toothID);

  const tooth = useAppSelector((state) =>
    toothModel.selectors.selectById(state, toothID),
  );

  const organization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );

  const report = useAppSelector((state) =>
    reportsModel.selectors.selectById(state, reportID),
  );

  const CBCTGPMPRSubVolume = useAppSelector(
    assetsModel.selectors.selectMPRByToothID(toothID),
  );

  const { isApproveToothLoading, toggleApproveTooth } = useApproveTooth(
    tooth as ReportTooth,
  );
  const panoImage = useAppSelector(
    assetsModel.selectors.selectCBCTGPPanoImageByReportID(reportID),
  );

  const panoImageAsset = useAppSelector(
    assetsModel.selectors.selectGPAssetByReportID(reportID),
  );

  const panoImageAssetID = panoImageAsset?.ID;

  const patientBreadcrumbs = usePatientBreadcrumbs(patientID);
  const reportsBreadcrumbs = useReportsBreadcrumbs({ reportID, patientID });

  const viewOptionsTooth = makeImageViewOptions(
    panoImage?.MedicalImageFeatures?.ViewOptions,
  );

  const panoImageID = panoImage?.CBCTGPPanoramaReformatGeneral?.PanoramaSingle;

  const panoImageUrl = useMemo(
    () => (panoImageID?.ID ? getFileSrc(panoImageID?.ID as string) : ''),
    [panoImageID?.ID],
  );

  const { removeMedicalImageFromSelected, addMedicalImageToSelected } =
    useAddAndRemoveMedicalImage();

  const isToothLoaded = !!tooth;

  const displayedToothNumber = getDisplayToothNumber(
    tooth?.Numeration?.ISO ?? 0,
    organization.Settings?.DentalNotationFormat,
  );

  useReportDataStream(reportID as string);

  const toothGroups = useGetMedicalImageGroups(toothID);

  const handleAddSelected = (medicalImage: MedicalImageInterface) => {
    if (selectedMedicalImages.find((item) => item.src === medicalImage.src)) {
      return;
    }

    addMedicalImageToSelected(medicalImage.assetID, toothID);
  };

  const toggleSelected = (medicalImage: MedicalImageInterface) => {
    if (selectedMedicalImages.find((item) => item.src === medicalImage.src)) {
      removeMedicalImageFromSelected(medicalImage.assetID, toothID);
    } else {
      addMedicalImageToSelected(medicalImage.assetID, toothID);
    }
  };

  const handleDrop = () => {
    handleAddSelected(draggedMedicalImage as MedicalImageInterface);
    setDraggedMedicalImage({} as MedicalImageInterface);
  };

  const handleDeleteMedicalImage = (medicalImage: MedicalImageInterface) => {
    removeMedicalImageFromSelected(medicalImage.assetID, toothID);
  };

  // TODO: [8|m] Just copied it from group. I don't think this is a good way to define selected medical image
  // More obvious way is to just use boolean property in MedicalImageInterface
  const checkIsTheMedicalImageSelected = useCallback(
    (src: string) =>
      selectedMedicalImages?.some((medicalImage) => medicalImage.src === src) ??
      false,
    [selectedMedicalImages],
  );

  useEffect(() => {
    if (isSmallDesktop || isTablet || isMobile) {
      setPanoImageToggled(false);
    }
  }, [isSmallDesktop, isTablet, isMobile]);

  return (
    <Layout footerWithSettings fullWidth>
      <Layout.Header>
        <Header
          fullWidth
          navigation={
            <Breadcrumbs
              config={[
                patientBreadcrumbs.patients,
                patientBreadcrumbs.patientProfile,
                reportsBreadcrumbs.report,
              ]}
            />
          }
        />
      </Layout.Header>

      <Layout.Content>
        <Layout.Main>
          <Layout.Content className={cn(styles.container, className)}>
            <WidgetLayout className={styles.columnWrapper}>
              <Column className={styles.leftColumnWrapper}>
                <div className={cn(styles.leftFixedColumn)}>
                  {isToothLoaded ? (
                    <ToothCard
                      className={styles.mb16}
                      tooth={tooth}
                      patientID={patientID ?? ''}
                      reportID={reportID ?? ''}
                      showViewerButton={false}
                      // TODO: [8|m] Temporary fast solution for demo. Should be refactored
                      medicalImages={selectedMedicalImages.map(
                        (medicalImage) => (
                          <MedicalImage
                            key={medicalImage.id}
                            onRemoveMedicalImage={handleDeleteMedicalImage}
                            medicalImage={medicalImage}
                            annotations={medicalImage?.annotations}
                            wc={medicalImage?.viewOptions?.wwwc.wc}
                            ww={medicalImage?.viewOptions?.wwwc.ww}
                            sharpness={medicalImage?.viewOptions?.sharpness}
                            inverted={medicalImage?.viewOptions?.invert}
                            mask={medicalImage.mask}
                            order={medicalImage.order}
                            checkIsTheMedicalImageSelected={
                              checkIsTheMedicalImageSelected
                            }
                          />
                        ),
                      )}
                      dndComponent={
                        <SelectedMedicalImages
                          className={cn(
                            styles.selectedMedicalImages,
                            panoImageToggled && styles.medicalImageToogled,
                            draggedMedicalImage && styles.draggedMedicalImage,
                          )}
                          onDrop={handleDrop}
                          dragged={!!draggedMedicalImage}
                          groupName={draggedMedicalImage?.groupName}
                        />
                      }
                    />
                  ) : (
                    <Skeleton width="100%" borderRadius="8px" height="46px" />
                  )}

                  <WidgetCard className={cn(styles.panoImageWidget)}>
                    {!panoImage && (
                      <Skeleton
                        width="100%"
                        borderRadius="8px"
                        height={`${SCELETON_DEFAULT_HEIGHT}px`}
                      />
                    )}
                    {panoImage && panoImageUrl && (
                      <PanoImage
                        src={panoImageUrl}
                        controls={PANO_TOOLS}
                        style={{
                          width: '100%',
                        }}
                        toggled={false}
                        containerWidth={panoContainerWidth}
                        toolbarPosition="inside"
                        viewOptions={viewOptionsTooth}
                        assetID={panoImageAssetID}
                      />
                    )}
                  </WidgetCard>
                </div>
              </Column>

              <Column className={cn(styles.rightColumn)}>
                <WidgetCard>
                  <div className={styles.mprHeader}>
                    <div className={styles.mprHeaderText}>
                      <h3 className="h3">
                        <FormattedMessage
                          id="tooth.mpr.abbreviation"
                          defaultMessage="MPR"
                        />
                      </h3>
                      <p className="p3">
                        <FormattedMessage
                          id="tooth.mpr"
                          defaultMessage="Multi-Planar Reconstruction"
                        />
                      </p>
                    </div>
                    {/* TODO: make full screen mode and connect this btn */}
                    {/* <button */}
                    {/*   className={styles.mprExpand} */}
                    {/*   type="button" */}
                    {/*   disabled={!CBCTGPMPRSubVolume} */}
                    {/*   onClick={() => */}
                    {/*     dispatch( */}
                    {/*       modalModel.actions.openMPRFullScreenModal( */}
                    {/*         getImageSrc(CBCTGPMPRSubVolume?.Volume?.ID), */}
                    {/*       ), */}
                    {/*     ) */}
                    {/*   } */}
                    {/* > */}
                    {/*   <Icon */}
                    {/*     name="expand" */}
                    {/*     size={32} */}
                    {/*   /> */}
                    {/* </button> */}
                  </div>
                  {CBCTGPMPRSubVolume ? (
                    <MPRWidget
                      mprUrl={getFileSrc(CBCTGPMPRSubVolume?.Volume?.ID ?? '')}
                      initialWw={CBCTGPMPRSubVolume?.WindowWidth}
                      initialWc={CBCTGPMPRSubVolume?.WindowLevel}
                    />
                  ) : (
                    <Skeleton borderRadius="16px" width="100%" height="300px" />
                  )}
                </WidgetCard>

                <WidgetCard>
                  <MedicalImagesGroup
                    onDragMedicalImage={setDraggedMedicalImage}
                    selectedMedicalImages={selectedMedicalImages}
                    toggleSelected={toggleSelected}
                    groups={toothGroups}
                  />
                </WidgetCard>
              </Column>
            </WidgetLayout>
          </Layout.Content>
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer settingsFooter>
        <div className={styles.footerWrapper}>
          <SimplifiedToothChart
            reportID={reportID ?? ''}
            patientID={patientID ?? ''}
            toothOfInterestNumber={displayedToothNumber as number}
          />

          <div className={styles.footerButtons}>
            <Button
              size={isSmallDesktop || isMobile ? 'medium' : 'large'}
              icon={tooth?.IsApproved ? 'check' : 'plus'}
              loading={isApproveToothLoading}
              onClick={toggleApproveTooth}
              disabled={!report?.YourPermissions?.CanChangeToothApproved}
              danger={tooth?.IsApproved}
              className={styles.approveButton}
            >
              {tooth?.IsApproved ? (
                <FormattedMessage
                  id="toothCard.approved"
                  defaultMessage="Approved"
                />
              ) : (
                <FormattedMessage
                  id="toothCard.approve"
                  defaultMessage="Approve"
                />
              )}
            </Button>
          </div>
        </div>
      </Layout.Footer>
      <ZoomedMedicalImageModal
        toggleSelected={toggleSelected}
        selectedMedicalImages={selectedMedicalImages}
      />

      <MprViewerModal />

      <GetFullAccessModal />

      <AddConditionModal />
    </Layout>
  );
};
