import cn from 'classnames';
import { useMemo } from 'react';

import { ToothView, ToothViewClick } from '@/shared/ui';
import { ToothStatus } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';

import {
  LOWER_JAW_TEETH_ISO_NUMBERS,
  UPPER_JAW_TEETH_ISO_NUMBERS,
} from '@/entities/tooth';
import { reportsModel } from '@/entities/reports';

import { ToothInterface } from '../../hooks/useToothTransform';

import styles from './TeethChart.module.scss';

type TeethChartProps = {
  className?: string;
  teethItems: ToothInterface[];
  isROIEditing?: boolean;
  showUpperJaw?: boolean;
  showLowerJaw?: boolean;
  blackAndWhiteMode?: boolean;
  onToothClick?: ToothViewClick;
};

const getTeethChartGridTemplateRules = (ISONumbers: number[]) => {
  const isUpperChild = ISONumbers.some(
    (ISONumber) => ISONumber > 50 && ISONumber < 69,
  );
  const isLowerChild = ISONumbers.some(
    (ISONumber) => ISONumber > 70 && ISONumber < 90,
  );

  return {
    isOnlyUpperChilds: isUpperChild && !isLowerChild,
    isOnlyLowerChilds: isLowerChild && !isUpperChild,
    isAllChilds: isUpperChild && isLowerChild,
  };
};

export const TeethChart = (props: TeethChartProps) => {
  const {
    className,
    teethItems,
    isROIEditing = false,
    showUpperJaw = true,
    showLowerJaw = true,
    blackAndWhiteMode = false,
    onToothClick,
  } = props;

  const focusedToothCardID = useAppSelector(
    reportsModel.selectors.selectActiveToothID,
  );

  const gridTemplateRules = useMemo(() => {
    const ISONumbers = teethItems.map((toothItem) => toothItem.ISONumber);

    return getTeethChartGridTemplateRules(ISONumbers);
  }, [teethItems]);

  // TODO [m|8] need to handle this with child teeth
  const filteredTeethItems = useMemo(
    () =>
      teethItems.filter((toothItem) => {
        if (!showUpperJaw) {
          return LOWER_JAW_TEETH_ISO_NUMBERS.includes(toothItem.ISONumber);
        }

        if (!showLowerJaw) {
          return UPPER_JAW_TEETH_ISO_NUMBERS.includes(toothItem.ISONumber);
        }

        return true;
      }),
    [showLowerJaw, showUpperJaw, teethItems],
  );

  return (
    <div
      className={cn(
        styles.container,
        gridTemplateRules.isOnlyUpperChilds && styles.withUpperChilds,
        gridTemplateRules.isOnlyLowerChilds && styles.withLowerChilds,
        gridTemplateRules.isAllChilds && styles.withAllChilds,
        !showUpperJaw && styles.hideUpperJaw,
        !showLowerJaw && styles.hideLowerJaw,
        className,
      )}
    >
      {filteredTeethItems.map((toothItem) => (
        <ToothView
          key={toothItem.id}
          id={toothItem.id}
          ISONumber={toothItem.ISONumber}
          status={toothItem.status}
          viewNumber={toothItem.toothNumberView}
          isLowerJaw={toothItem.isLowerJaw ?? false}
          isMissingThirdMolar={toothItem.isMissingThirdMolar}
          isActive={toothItem.isActive}
          isROIOpen={isROIEditing}
          disabled={toothItem.status === ToothStatus.missing}
          style={{ gridArea: toothItem.gridArea }}
          blackAndWhiteMode={blackAndWhiteMode}
          isFocused={focusedToothCardID === toothItem.id}
          onClick={onToothClick}
        />
      ))}
    </div>
  );
};
