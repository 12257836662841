import { createSlice } from '@reduxjs/toolkit';

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { SliceName } from '@/shared/config';

type ConditionsType = Record<string, Condition>;

export type NewConditions = Record<string, ConditionsType>;

type InitialState = {
  newConditions: NewConditions;
};

const newConditionSlice = createSlice({
  name: SliceName.newCondition,
  initialState: {
    newConditions: {},
  } as InitialState,
  reducers: {
    setConditions: (state, { payload }) => {
      state.newConditions = payload;
    },
    resetSlice: (state) => {
      state.newConditions = {} as NewConditions;
    },
  },
});

export const { actions } = newConditionSlice;

export default newConditionSlice.reducer;
