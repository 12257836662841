import { useState } from 'react';

import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

export const useCBCTNewReportFormModal = () => {
  const [show, setShow] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState<ReportType>();

  const shouldOpenCBCTNewReportForm = (type: ReportType) =>
    type === ReportType.ReportType_CBCT_Endo ||
    type === ReportType.ReportType_CBCT_Molar ||
    type === ReportType.ReportType_CBCT_Implant;

  const openCBCTNewReportFormModal = (type: ReportType) => {
    setSelectedReportType(type);
    setShow(true);
  };

  const closeCBCTNewReportFromModal = () => {
    setShow(false);
  };

  return {
    showCBCTNewReportModal: show,
    closeCBCTNewReportFromModal,
    shouldOpenCBCTNewReportForm,
    openCBCTNewReportFormModal,
    selectedReportType,
  };
};
