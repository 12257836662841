// TODO: [2|m] Resolve TS errors
// @ts-nocheck
import { defineMessages } from 'react-intl';

import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import {
  SliceConfigValuesType,
  SliceStepConfig,
  SliceThicknessConfig,
} from './types';

export const SliceConfigValues = defineMessages<SliceConfigValuesType>({
  [SliceThicknessConfig.name]: {
    id: 'newReport.tab.sliceThickness.sliceConfigName',
    defaultMessage: 'Slice thickness',
  },
  [SliceThicknessConfig.OneVoxel]: {
    id: 'newReport.tab.sliceThickness.oneVoxel',
    defaultMessage: 'One voxel',
  },
  [SliceThicknessConfig.FiveTenthsMillimeter]: {
    id: 'newReport.tab.sliceThickness.fiveTenthsMillimeter',
    defaultMessage: '0.5 mm',
  },
  [SliceThicknessConfig.OneMillimeter]: {
    id: 'newReport.tab.sliceThickness.oneMillimeter',
    defaultMessage: '1 mm',
  },
  [SliceThicknessConfig.ThreeMillimeters]: {
    id: 'newReport.tab.sliceThickness.threeMillimeters',
    defaultMessage: '3 mm',
  },
  [SliceThicknessConfig.FiveMillimeters]: {
    id: 'newReport.tab.sliceThickness.fiveMillimeters',
    defaultMessage: '5 mm',
  },
  [SliceStepConfig.name]: {
    id: 'newReport.tab.sliceStep.sliceConfigName',
    defaultMessage: 'Slice step',
  },
  [SliceStepConfig.OneMillimeter]: {
    id: 'newReport.tab.sliceStep.oneMillimeter',
    defaultMessage: '1 mm',
  },
  [SliceStepConfig.OneAndAHalfMillimeters]: {
    id: 'newReport.tab.sliceStep.oneAndAHalfMillimeters',
    defaultMessage: '1.5 mm',
  },
  [SliceStepConfig.TwoMillimeters]: {
    id: 'newReport.tab.sliceStep.twoMillimeters',
    defaultMessage: '2 mm',
  },
  [SliceStepConfig.TwoAndAHalfMillimeters]: {
    id: 'newReport.tab.sliceStep.twoAndAHalfMillimeters',
    defaultMessage: '2.5 mm',
  },
  [SliceStepConfig.ThreeMillimeters]: {
    id: 'newReport.tab.sliceStep.threeMillimeters',
    defaultMessage: '3 mm',
  },
});

// TODO: [1/l] The script ignores \n during translation compilation. Change {newLine} to \n after connecting to CrowdIn
export const NewReportTypes = defineMessages<ReportType>({
  // Radiological
  [ReportType.ReportType_CBCT_GP]: {
    id: 'newReport.ReportType_CBCT_GP',
    defaultMessage: 'Radiological{newLine} Report',
  },
  [ReportType.ReportType_CBCT_Segmentation]: {
    id: 'newReport.ReportType_CBCT_Segmentation',
    defaultMessage: 'STL',
  },
  [ReportType.ReportType_CBCT_OrthoSlides]: {
    id: 'newReport.ReportType_CBCT_OrthoSlides',
    defaultMessage: 'Orthodontic{newLine} Report',
  },
  [ReportType.ReportType_CBCT_Implant]: {
    id: 'newReport.ReportType_CBCT_Implant',
    defaultMessage: 'Implantology{newLine} Report',
  },
  [ReportType.ReportType_CBCT_Endo]: {
    id: 'newReport.ReportType_CBCT_Endo',
    defaultMessage: 'Endodontic{newLine} Report',
  },
  [ReportType.ReportType_CBCT_Molar]: {
    id: 'newReport.ReportType_CBCT_Molar',
    defaultMessage: 'Third Molar{newLine} Report',
  },
  [ReportType.ReportType_Pano_GP]: {
    id: 'newReport.ReportType_Pano_GP',
    defaultMessage: 'Pano AI Report',
  },
  // FMX
  [ReportType.ReportType_IOXRay_GP]: {
    id: 'newReport.ReportType_IOXRay_GP',
    defaultMessage: 'IOXRay AI Report',
  },
  [ReportType.ReportType_DentalPhoto_GP]: {
    id: 'newReport.ReportType_DentalPhoto_GP',
    defaultMessage: 'ReportType_DentalPhoto_GP',
  },
  [ReportType.ReportType_IOS_Segmentation]: {
    id: 'newReport.ReportType_IOS_Segmentation',
    defaultMessage: 'ReportType_IOS_Segmentation',
  },
  [ReportType.ReportType_CBCT_IOS_Superimposition]: {
    id: 'newReport.ReportType_CBCT_IOS_Superimposition',
    defaultMessage: 'ReportType_CBCT_IOS_Superimposition',
  },
  [ReportType.ReportType_StudioImplant]: {
    id: 'newReport.ReportType_ImplantStudio',
    defaultMessage: 'ReportType_ImplantStudio',
  },
  [ReportType.ReportType_Photo_Ortho]: {
    id: 'newReport.ReportType_Photo_Ortho',
    defaultMessage: 'ReportType_Photo_Ortho',
  },
  [ReportType.ReportType_StudioOrtho]: {
    id: 'newReport.ReportType_OrthoStudio',
    defaultMessage: 'ReportType_OrthoStudio',
  },
  [ReportType.UNRECOGNIZED]: {
    id: 'newReport.UNRECOGNIZED',
    defaultMessage: 'UNRECOGNIZED',
  },
  [ReportType.ReportType_InvalidValue]: {
    id: 'newReport.ReportType_InvalidValue',
    defaultMessage: 'ReportType_InvalidValue',
  },
});
