// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer-module__container--vbr30UKU{display:flex;align-items:center;gap:20px;max-width:1400px;width:100%;padding:0 32px}.Footer-module__fullWidth--\\+DblAZsi{max-width:none}.Footer-module__link--Ob3Y-WMC{color:var(--purpletext2)}", "",{"version":3,"sources":["webpack://./src/features/footer/ui/Footer.module.scss","webpack://./src/shared/styles/media.scss"],"names":[],"mappings":"AAEA,oCACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,gBCFa,CDGb,UAAA,CACA,cAAA,CAGF,qCACE,cAAA,CAGF,+BACE,wBAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.container {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  max-width: $largeDesktop;\n  width: 100%;\n  padding: 0 32px;\n}\n\n.fullWidth {\n  max-width: none;\n}\n\n.link {\n  color: var(--purpletext2);\n}\n","$mobile: 420px;\n$tablet: 768px;\n$smallDesktop: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1400px;\n\n@mixin mobile {\n  @media screen and (max-width: $mobile) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media screen and (max-width: $tablet) {\n    @content;\n  }\n}\n\n@mixin smallDesktop {\n  @media screen and (max-width: $smallDesktop) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media screen and (max-width: $desktop) {\n    @content;\n  }\n}\n\n@mixin largeDesktop {\n  @media screen and (max-width: $largeDesktop) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Footer-module__container--vbr30UKU",
	"fullWidth": "Footer-module__fullWidth--+DblAZsi",
	"link": "Footer-module__link--Ob3Y-WMC"
};
export default ___CSS_LOADER_EXPORT___;
