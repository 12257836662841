// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu-module__menu--YKUPehmX{position:absolute;min-width:285px;margin-top:4px;padding:16px;background-color:var(--white);border-radius:16px;box-shadow:0 10px 24px rgba(0,0,0,.05);z-index:90}", "",{"version":3,"sources":["webpack://./src/shared/ui/DropdownSelect/components/Menu/menu.module.scss"],"names":[],"mappings":"AAAA,6BACE,iBAAA,CACA,eAAA,CACA,cAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,sCAAA,CACA,UAAA","sourcesContent":[".menu {\n  position: absolute;\n  min-width: 285px;\n  margin-top: 4px;\n  padding: 16px;\n  background-color: var(--white);\n  border-radius: 16px;\n  box-shadow: 0 10px 24px rgb(0 0 0 / 5%);\n  z-index: 90;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "menu-module__menu--YKUPehmX"
};
export default ___CSS_LOADER_EXPORT___;
