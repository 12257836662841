// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderDivider-module__container--y11F3I\\+Z{position:fixed;top:0;margin-top:76px;border-bottom:1px solid var(--grey6);width:100%;background-color:var(--mainbg);z-index:10}", "",{"version":3,"sources":["webpack://./src/shared/ui/HeaderDivider/HeaderDivider.module.scss"],"names":[],"mappings":"AAAA,4CACE,cAAA,CACA,KAAA,CACA,eAAA,CACA,oCAAA,CACA,UAAA,CACA,8BAAA,CACA,UAAA","sourcesContent":[".container {\n  position: fixed;\n  top: 0;\n  margin-top: 76px;\n  border-bottom: 1px solid var(--grey6);\n  width: 100%;\n  background-color: var(--mainbg);\n  z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HeaderDivider-module__container--y11F3I+Z"
};
export default ___CSS_LOADER_EXPORT___;
