import { ToothWithLocalization } from '../config/tooth.type';

export const isDuplicate = (
  teeth: ToothWithLocalization[],
  tooth: ToothWithLocalization,
) =>
  teeth.some(
    (item) =>
      item.ISONumber === tooth?.ISONumber && item.toothID !== tooth.toothID,
  );
