// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UploadStudyIcon-module__success--NEtAxA\\+Z{color:var(--purpleflat)}.UploadStudyIcon-module__failed--Y01KbTMy{color:var(--redrose)}.UploadStudyIcon-module__uploading--d2R1dJ2I{color:var(--grey9)}", "",{"version":3,"sources":["webpack://./src/app/ui/UploadStudyIcon/UploadStudyIcon.module.scss"],"names":[],"mappings":"AAAA,4CACE,uBAAA,CAGF,0CACE,oBAAA,CAGF,6CACE,kBAAA","sourcesContent":[".success {\n  color: var(--purpleflat);\n}\n\n.failed {\n  color: var(--redrose);\n}\n\n.uploading {\n  color: var(--grey9);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": "UploadStudyIcon-module__success--NEtAxA+Z",
	"failed": "UploadStudyIcon-module__failed--Y01KbTMy",
	"uploading": "UploadStudyIcon-module__uploading--d2R1dJ2I"
};
export default ___CSS_LOADER_EXPORT___;
