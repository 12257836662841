// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToothChartTags-module__container--ycNYQkS-{display:flex;gap:1px}", "",{"version":3,"sources":["webpack://./src/features/toothChart/ui/ToothChartTags/ToothChartTags.module.scss"],"names":[],"mappings":"AAAA,4CACE,YAAA,CACA,OAAA","sourcesContent":[".container {\n  display: flex;\n  gap: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ToothChartTags-module__container--ycNYQkS-"
};
export default ___CSS_LOADER_EXPORT___;
