import { FC, useMemo, useRef } from 'react';

import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';
import { useAppSelector } from '@/shared/hooks';

import { useToothConditions } from '@/entities/condition';
import { toothModel, transformBBoxToCrop } from '@/entities/tooth';
import { assetsModel, getFileSrc } from '@/entities/assets';
import { logicalConditionModel } from '@/entities/logicalCondition';
import { reportsModel } from '@/entities/reports';

import { useToothLandmarks } from '@/features/toothLandmark';

import { SubImageInterface, SubImageMask } from '@/widgets/IOXRayMatrix';
import {
  IOXRayToothImage,
  makeCroppedMedicalImagesFromLocalizations,
} from '@/widgets/ToothCard';

import { sortToothImages } from '../../utils/sortToothImages';
import { findMaskCondition } from '../../utils/findMaskCondition';
import { getMaskAssetIDSFromConditions } from '../../utils/getMaskAssetIDSFromConditions';
import { getCroppedImagesIDSWithCondition } from '../../utils/getCroppedImagesIDSWithCondition';
import { IOX_RAY_IMAGE_PADDING } from '../../config/constants';

import styles from './ToothImages.module.scss';

type IOXRayToothImagesProps = {
  reportID: string;
  wrapperSize: { width: number; height: number };
  biteWingSubImages: SubImageInterface[];
};

export const ToothImages: FC<IOXRayToothImagesProps> = (props) => {
  const { wrapperSize, biteWingSubImages, reportID } = props;

  const { code, parentID } = useAppSelector(
    logicalConditionModel.selectors.selectHoveredConditionLink,
  );

  const toothID = useAppSelector(reportsModel.selectors.selectActiveToothID);
  const currentTooth = useAppSelector(
    toothModel.selectors.selectToothByID(toothID),
  );

  const { pblLandmarks, pixelScale } = useToothLandmarks(reportID, toothID);

  // TODO: Refactor for cropped tooth images
  const croppedImages = makeCroppedMedicalImagesFromLocalizations(
    currentTooth?.Localizations,
  );

  const hoveredCondition = useAppSelector(
    logicalConditionModel.selectors.selectCondition({
      toothID,
      code,
      parentID,
    }),
  );

  const { conditionsToBeShown } = useToothConditions({
    toothID,
    showLowProbability: true, // Why always true?
  });

  // const selectMaskAssetsIDs = useMemo(() => logicalConditionModel.selectors.makeSelectToothMasksAssetIDs(toothID), [toothID]);

  // const test = useAppSelector(selectMaskAssetsIDs);
  const maskAssetsIDS = getMaskAssetIDSFromConditions(conditionsToBeShown);

  // const selectAssetsByIDs = useMemo(
  //   () => assetsModel.selectors.makeSelectAssetsByIDs(test),
  //   [test],
  // );

  // const testMaskAssets = useAppSelector(selectAssetsByIDs);

  const masksAssets = useAppSelector(
    assetsModel.selectors.selectAssetsByIDs(maskAssetsIDS),
  );

  const allMasksWithColors: SubImageMask[] = masksAssets.map((maskAsset) => {
    const maskCondition = findMaskCondition(conditionsToBeShown, maskAsset.ID);

    const croppedImageIDs = getCroppedImagesIDSWithCondition(
      croppedImages,
      maskCondition.Localizations[0].BBox,
      // maskCondition.Localizations.find(localization => localization.MaskAssetID === maskAsset.ID).BBox,
    );

    return {
      subImageID: maskCondition?.Localizations[0].TargetAssetID,
      croppedImageIDs,
      kind: 'raster-mask',
      url: getFileSrc(maskAsset?.GeneratedReport.Mask2D.Mask?.ID ?? ''),
      condition: maskCondition,
    };
  });

  const masksShouldShown = useMemo(
    () =>
      allMasksWithColors.filter(
        (mask) => mask.condition.Code !== ConditionCode.Missing,
      ),
    [allMasksWithColors],
  );

  const leftSideRef = useRef<HTMLDivElement>();
  const rightSideRef = useRef<HTMLDivElement>();

  const sortedImages = sortToothImages(croppedImages, biteWingSubImages);

  sortedImages.right.sort((a, b) => {
    const cropA = transformBBoxToCrop(a.BBox);
    const cropB = transformBBoxToCrop(b.BBox);

    if (cropA.w > cropB.w) {
      return -1;
    }
    return 1;
  });

  sortedImages.left.sort((a, b) => {
    const cropA = transformBBoxToCrop(a.BBox);
    const cropB = transformBBoxToCrop(b.BBox);

    if (cropA.h > cropB.h) {
      return -1;
    }
    return 1;
  });

  const biggestBiteWing = transformBBoxToCrop(sortedImages.right[0]?.BBox);
  const biggestNotBiteWing = transformBBoxToCrop(sortedImages.left[0]?.BBox);

  const biteWingsHeightSum = sortedImages.right.reduce(
    (summaryHeight, image) => {
      const crop = transformBBoxToCrop(image.BBox);

      return summaryHeight + crop.h;
    },
    0,
  );

  const notBiteWingWidthSum = sortedImages.left.reduce((widthSum, image) => {
    const crop = transformBBoxToCrop(image.BBox);
    return widthSum + crop.w;
  }, 0);

  const height =
    biteWingsHeightSum > biggestNotBiteWing.h
      ? biteWingsHeightSum
      : biggestNotBiteWing.h;
  const width = notBiteWingWidthSum + biggestBiteWing.w;

  const scaleY = (wrapperSize.height - IOX_RAY_IMAGE_PADDING) / height;
  const scaleX = (wrapperSize.width - IOX_RAY_IMAGE_PADDING) / width;

  const minScale = Math.min(scaleX, scaleY);

  const conditionHovered =
    hoveredCondition?.Tooth.ToothID === toothID && hoveredCondition;

  return (
    <>
      <div className={styles.leftSide} ref={leftSideRef}>
        {sortedImages.left.map((image) => (
          <IOXRayToothImage
            key={image.id}
            className={styles.toothImage}
            croppedImage={image}
            hoveredCondition={conditionHovered}
            actionsIsDisabled
            minScale={minScale}
            allMasksWithColors={masksShouldShown}
            toothLocalizations={currentTooth?.Localizations}
            landmarks={pblLandmarks}
            pixelScale={pixelScale}
          />
        ))}
      </div>

      <div className={styles.rightSide} ref={rightSideRef}>
        {sortedImages.right.map((image) => (
          <IOXRayToothImage
            key={image.id}
            className={styles.toothImage}
            croppedImage={image}
            hoveredCondition={conditionHovered}
            actionsIsDisabled
            minScale={minScale}
            allMasksWithColors={masksShouldShown}
            toothLocalizations={currentTooth?.Localizations}
            landmarks={pblLandmarks}
            pixelScale={pixelScale}
          />
        ))}
      </div>
    </>
  );
};
