import { FC } from 'react';
import cn from 'classnames';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  Button,
  Checkbox,
  Input,
  InputPassword,
  Layout,
  Modal,
  Select,
} from '@/shared/ui/';
import { PATHS } from '@/shared/config';
import { useLanguages } from '@/shared/hooks/useLanguages';

import { Header } from '@/widgets/Header';
import { CustomPasswordError } from '@/widgets/CustomPasswordError';

import {
  InviteSignUpFormPayload,
  inviteSignUpFormSchema,
} from './config/formConfig';
import styles from './InviteSignUp.module.scss';

type InviteSignUpProps = {
  className?: string;
  testID?: string;
};

export const InviteSignUp: FC<InviteSignUpProps> = (props) => {
  const { className, testID } = props;

  const { formatMessage } = useIntl();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting, isSubmitted },
  } = useForm<InviteSignUpFormPayload>({
    resolver: yupResolver(inviteSignUpFormSchema),
    mode: 'all',
  });

  const { languageOptions } = useLanguages();

  const onSubmit: SubmitHandler<InviteSignUpFormPayload> = async () => {
    // do nothing
  };

  const invitationFrom: 'user' | 'org' = 'user'; // TODO take from useLocation
  // org || user

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <Layout.Content>
        <Layout.Main>
          <div className={cn(styles.container, className)} data-testid={testID}>
            <Modal
              containerClassName={styles.container}
              bodyClassName={styles.modalBody}
              title={formatMessage({
                id: 'signUp.inviteSignUp',
                defaultMessage: 'Sign Up',
              })}
              isOpen
              onClose={() => {}}
              applyButtonText={formatMessage({
                id: 'inviteSignUp.signUp',
                defaultMessage: 'Sign Up',
              })}
              applyButtonProps={{
                type: 'submit',
                form: 'inviteSignUp',
                disabled: isSubmitting,
              }}
              footer={
                <div className={styles.modalFooter}>
                  <Button
                    className={styles.submitButton}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                  >
                    <FormattedMessage
                      id="signUp.submit"
                      defaultMessage="Sign up"
                    />
                  </Button>
                </div>
              }
            >
              <h4 className={cn(styles.title, 'h4')}>
                {invitationFrom === 'user' ? (
                  <FormattedMessage
                    id="additionalInfo.congratsAccountIsReady"
                    defaultMessage="{user} granted access to the patient profile. Create a Diagnocat account for review"
                    values={{
                      user: 'test user',
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="additionalInfo.invitationFromOrganization"
                    defaultMessage="Invitation from {org}"
                    values={{
                      org: 'test organization',
                    }}
                  />
                )}
              </h4>
              <form
                id="inviteSignUp"
                onSubmit={handleSubmit(onSubmit)}
                className={styles.form}
              >
                <div className={styles.row}>
                  <Controller
                    control={control}
                    name="firstName"
                    render={({
                      field: { ref, value, name, onBlur, onChange },
                      fieldState: { error },
                    }) => (
                      <Input
                        required
                        ref={ref}
                        value={value}
                        name={name}
                        onBlur={() => {
                          if (isSubmitted) {
                            onBlur();
                          }
                        }}
                        onChange={(event) => {
                          if (!isSubmitted) {
                            setValue(name, event.target.value);
                          } else {
                            onChange(event);
                          }
                        }}
                        type="text"
                        label={formatMessage({
                          id: 'inviteSignUp.firstName',
                          defaultMessage: 'First name',
                        })}
                        error={error?.message}
                        autoComplete="given-name"
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="lastName"
                    render={({
                      field: { ref, value, name, onBlur, onChange },
                      fieldState: { error },
                    }) => (
                      <Input
                        required
                        ref={ref}
                        value={value}
                        name={name}
                        onBlur={() => {
                          if (isSubmitted) {
                            onBlur();
                          }
                        }}
                        onChange={(event) => {
                          if (!isSubmitted) {
                            setValue(name, event.target.value);
                          } else {
                            onChange(event);
                          }
                        }}
                        type="text"
                        label={formatMessage({
                          id: 'inviteSignUp.lastName',
                          defaultMessage: 'Last name',
                        })}
                        error={error?.message}
                      />
                    )}
                  />
                </div>

                <div className={styles.row}>
                  <div>
                    <Controller
                      control={control}
                      name="password"
                      render={({
                        field: { ref, value, name, onBlur, onChange },
                        fieldState: { error },
                      }) => (
                        <>
                          <InputPassword
                            required
                            ref={ref}
                            value={value}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            label={formatMessage({
                              id: 'inviteSignUp.password',
                              defaultMessage: 'Password',
                            })}
                            autoComplete="new-password"
                          />
                          {error && <CustomPasswordError inputValue={value} />}
                        </>
                      )}
                    />
                  </div>

                  <Controller
                    control={control}
                    name="confirmPassword"
                    render={({
                      field: { ref, value, name, onBlur, onChange },
                      fieldState: { error, isTouched },
                    }) => (
                      <InputPassword
                        required
                        ref={ref}
                        value={value}
                        name={name}
                        inputMode="text"
                        type="password"
                        onBlur={onBlur}
                        onChange={(event) => {
                          if (!isSubmitted && !isTouched) {
                            setValue(name, event.target.value);
                          } else {
                            onChange(event);
                          }
                        }}
                        label={formatMessage({
                          id: 'inviteSignUp.ConfirmPassword',
                          defaultMessage: 'Confirm Password',
                        })}
                        error={error?.message}
                      />
                    )}
                  />
                </div>

                <Controller
                  control={control}
                  name="language"
                  render={({
                    field: { ref, value, name, onBlur, onChange },
                    fieldState: { error },
                  }) => (
                    <Select
                      label={formatMessage({
                        id: 'inviteSignUp.chooseYourLanguage',
                        defaultMessage: 'Choose your language',
                      })}
                      options={languageOptions}
                      isRequired
                      ref={ref}
                      value={`${value}`}
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="termsAndConditions"
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Checkbox
                      error={error?.message}
                      name={name}
                      onChange={onChange}
                      checked={value}
                    >
                      <div>
                        <FormattedMessage
                          id="inviteSignUp.iConfirm"
                          defaultMessage="I confirm, I am a registered/licensed dental professional and I agree with"
                        />{' '}
                        <Link className={styles.link} to={PATHS.main}>
                          <FormattedMessage
                            id="inviteSignUp.termsAndConditions"
                            defaultMessage="Terms and conditions"
                          />
                        </Link>{' '}
                        <FormattedMessage
                          id="inviteSignUp.and"
                          defaultMessage="and"
                        />{' '}
                        <Link className={styles.link} to={PATHS.main}>
                          <FormattedMessage
                            id="inviteSignUp.dataProcessingAddendum"
                            defaultMessage="Data Processing Addendum"
                          />
                        </Link>
                      </div>
                    </Checkbox>
                  )}
                />

                <Controller
                  control={control}
                  name="privacyPolicy"
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Checkbox
                      error={error?.message}
                      name={name}
                      onChange={onChange}
                      checked={value}
                    >
                      <div>
                        <FormattedMessage
                          id="inviteSignUp.iAgree"
                          defaultMessage="I agree with"
                        />{' '}
                        <Link className={styles.link} to={PATHS.main}>
                          <FormattedMessage
                            id="inviteSignUp.thePrivacyPolicy"
                            defaultMessage="The Privacy Policy"
                          />
                        </Link>
                      </div>
                    </Checkbox>
                  )}
                />
                <Controller
                  control={control}
                  name="marketingCommunicationConsent"
                  render={({
                    field: { onChange, value = false, name },
                    fieldState: { error },
                  }) => (
                    <Checkbox
                      error={error?.message}
                      name={name}
                      onChange={onChange}
                      checked={value}
                    >
                      {' '}
                      <FormattedMessage
                        id="inviteSignUp.marketingCommunicationConsent"
                        defaultMessage="I agree that DGNCT LLC and its affiliates (hereinafter – Diagnocat) may contact me by e-mail, mobile phone or text messages with information about the products and services of Diagnocat, which may include special offers and feedback requests."
                      />
                    </Checkbox>
                  )}
                />
              </form>
            </Modal>
          </div>
        </Layout.Main>
      </Layout.Content>
    </Layout>
  );
};
