import { useIntl } from 'react-intl';

import { ProductModel } from '@/shared/api/protocol_gen/api/billing_new/dto_product_new';

export const useTransformTopUpAddonsOptions = (products: ProductModel[]) => {
  const { formatMessage } = useIntl();

  if (products.length === 0) return [];

  const transformedOptions = products.map((product) => ({
    label: formatMessage(
      {
        id: 'billing.topUpLimitsModal.reportLimit',
        defaultMessage: '{amount, plural, one {# report} other {# reports}}',
      },
      {
        amount: product?.Services?.at(0)?.Countable.Limit ?? 0,
      },
    ),
    value: product?.ID,
  }));

  return transformedOptions;
};
