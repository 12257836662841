/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

/** https://diagnocat.atlassian.net/wiki/spaces/RES/pages/276889634/Pipelines+Errors */
export enum ReportGenerationErrorCode {
  ReportGenerationErrorCode_Unspecified = 0,
  /** ReportGenerationErrorCode_StudyUpload_Failed - Example: user has started study upload session, then requested report, but study_session failed */
  ReportGenerationErrorCode_StudyUpload_Failed = 1,
  /** ReportGenerationErrorCode_StudyUpload_Canceled - Example: user has started study upload session, then requested report, but then user canceled study_session */
  ReportGenerationErrorCode_StudyUpload_Canceled = 2,
  /** ReportGenerationErrorCode_GeneratedAssetUpload_Failed - some pipelines upload session error */
  ReportGenerationErrorCode_GeneratedAssetUpload_Failed = 3,
  ReportGenerationErrorCode_FileCannotBeRead = 11,
  /**
   * ReportGenerationErrorCode_Dicom_IsNot2DImage - Example: for 2D reports user provided volumetric DICOM that cannot be interpreted
   * as grayscale or RGB image.
   */
  ReportGenerationErrorCode_Dicom_IsNot2DImage = 20,
  /** ReportGenerationErrorCode_Dicom_IsNot3DImage - Example: for 3D reports user provided non volumetric DICOM. */
  ReportGenerationErrorCode_Dicom_IsNot3DImage = 21,
  ReportGenerationErrorCode_Dicom_InsufficientVolume = 40,
  /** ReportGenerationErrorCode_Dicom_DuplicateSlices - Multi-file DICOM contains slice duplicates. */
  ReportGenerationErrorCode_Dicom_DuplicateSlices = 41,
  ReportGenerationErrorCode_Dicom_Metadata_NonDerivableVoxelSize = 42,
  ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleInterceptAttribute = 43,
  ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleSlopeAttribute = 44,
  ReportGenerationErrorCode_Dicom_Metadata_NonDerivableImagePositionPatientAttribute = 45,
  ReportGenerationErrorCode_Dicom_Metadata_UnexpectedModality = 46,
  /** ReportGenerationErrorCode_Dicom_Metadata_NonDerivableSlicePosition - Multi-file DICOM contains slices with non derivable position. */
  ReportGenerationErrorCode_Dicom_Metadata_NonDerivableSlicePosition = 47,
  ReportGenerationErrorCode_Dicom_Metadata_NoPixelData = 48,
  /**
   * ReportGenerationErrorCode_InputData_UnexpectedStudyQuantity - Task contains incorrect number of study, so that it is not possible to start
   * report generation.
   */
  ReportGenerationErrorCode_InputData_UnexpectedStudyQuantity = 60,
  ReportGenerationErrorCode_InputData_UnexpectedStudyContent = 61,
  ReportGenerationErrorCode_InputData_BadTaskParameters = 62,
  ReportGenerationErrorCode_Modeling_CbctIosMisalignment = 80,
  ReportGenerationErrorCode_Modeling_IosWrongOcclusion = 81,
  ReportGenerationErrorCode_Modeling_IosMissingJaw = 82,
  /**
   * ReportGenerationErrorCode_Modeling_RequestedToothNotFound - Example: for CBCT_Endo or CBCT_Molar user request analysis for a tooth that was not
   * detected by `pipelines`.
   */
  ReportGenerationErrorCode_Modeling_RequestedToothNotFound = 83,
  ReportGenerationErrorCode_ValidTimeExpired = 101,
  UNRECOGNIZED = -1,
}

export function reportGenerationErrorCodeFromJSON(
  object: any,
): ReportGenerationErrorCode {
  switch (object) {
    case 0:
    case 'ReportGenerationErrorCode_Unspecified':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Unspecified;
    case 1:
    case 'ReportGenerationErrorCode_StudyUpload_Failed':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_StudyUpload_Failed;
    case 2:
    case 'ReportGenerationErrorCode_StudyUpload_Canceled':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_StudyUpload_Canceled;
    case 3:
    case 'ReportGenerationErrorCode_GeneratedAssetUpload_Failed':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_GeneratedAssetUpload_Failed;
    case 11:
    case 'ReportGenerationErrorCode_FileCannotBeRead':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_FileCannotBeRead;
    case 20:
    case 'ReportGenerationErrorCode_Dicom_IsNot2DImage':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_IsNot2DImage;
    case 21:
    case 'ReportGenerationErrorCode_Dicom_IsNot3DImage':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_IsNot3DImage;
    case 40:
    case 'ReportGenerationErrorCode_Dicom_InsufficientVolume':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_InsufficientVolume;
    case 41:
    case 'ReportGenerationErrorCode_Dicom_DuplicateSlices':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_DuplicateSlices;
    case 42:
    case 'ReportGenerationErrorCode_Dicom_Metadata_NonDerivableVoxelSize':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableVoxelSize;
    case 43:
    case 'ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleInterceptAttribute':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleInterceptAttribute;
    case 44:
    case 'ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleSlopeAttribute':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleSlopeAttribute;
    case 45:
    case 'ReportGenerationErrorCode_Dicom_Metadata_NonDerivableImagePositionPatientAttribute':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableImagePositionPatientAttribute;
    case 46:
    case 'ReportGenerationErrorCode_Dicom_Metadata_UnexpectedModality':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_UnexpectedModality;
    case 47:
    case 'ReportGenerationErrorCode_Dicom_Metadata_NonDerivableSlicePosition':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableSlicePosition;
    case 48:
    case 'ReportGenerationErrorCode_Dicom_Metadata_NoPixelData':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NoPixelData;
    case 60:
    case 'ReportGenerationErrorCode_InputData_UnexpectedStudyQuantity':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_InputData_UnexpectedStudyQuantity;
    case 61:
    case 'ReportGenerationErrorCode_InputData_UnexpectedStudyContent':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_InputData_UnexpectedStudyContent;
    case 62:
    case 'ReportGenerationErrorCode_InputData_BadTaskParameters':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_InputData_BadTaskParameters;
    case 80:
    case 'ReportGenerationErrorCode_Modeling_CbctIosMisalignment':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_CbctIosMisalignment;
    case 81:
    case 'ReportGenerationErrorCode_Modeling_IosWrongOcclusion':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_IosWrongOcclusion;
    case 82:
    case 'ReportGenerationErrorCode_Modeling_IosMissingJaw':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_IosMissingJaw;
    case 83:
    case 'ReportGenerationErrorCode_Modeling_RequestedToothNotFound':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_RequestedToothNotFound;
    case 101:
    case 'ReportGenerationErrorCode_ValidTimeExpired':
      return ReportGenerationErrorCode.ReportGenerationErrorCode_ValidTimeExpired;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ReportGenerationErrorCode.UNRECOGNIZED;
  }
}

export function reportGenerationErrorCodeToJSON(
  object: ReportGenerationErrorCode,
): string {
  switch (object) {
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Unspecified:
      return 'ReportGenerationErrorCode_Unspecified';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_StudyUpload_Failed:
      return 'ReportGenerationErrorCode_StudyUpload_Failed';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_StudyUpload_Canceled:
      return 'ReportGenerationErrorCode_StudyUpload_Canceled';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_GeneratedAssetUpload_Failed:
      return 'ReportGenerationErrorCode_GeneratedAssetUpload_Failed';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_FileCannotBeRead:
      return 'ReportGenerationErrorCode_FileCannotBeRead';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_IsNot2DImage:
      return 'ReportGenerationErrorCode_Dicom_IsNot2DImage';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_IsNot3DImage:
      return 'ReportGenerationErrorCode_Dicom_IsNot3DImage';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_InsufficientVolume:
      return 'ReportGenerationErrorCode_Dicom_InsufficientVolume';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_DuplicateSlices:
      return 'ReportGenerationErrorCode_Dicom_DuplicateSlices';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableVoxelSize:
      return 'ReportGenerationErrorCode_Dicom_Metadata_NonDerivableVoxelSize';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleInterceptAttribute:
      return 'ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleInterceptAttribute';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleSlopeAttribute:
      return 'ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleSlopeAttribute';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableImagePositionPatientAttribute:
      return 'ReportGenerationErrorCode_Dicom_Metadata_NonDerivableImagePositionPatientAttribute';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_UnexpectedModality:
      return 'ReportGenerationErrorCode_Dicom_Metadata_UnexpectedModality';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableSlicePosition:
      return 'ReportGenerationErrorCode_Dicom_Metadata_NonDerivableSlicePosition';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NoPixelData:
      return 'ReportGenerationErrorCode_Dicom_Metadata_NoPixelData';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_InputData_UnexpectedStudyQuantity:
      return 'ReportGenerationErrorCode_InputData_UnexpectedStudyQuantity';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_InputData_UnexpectedStudyContent:
      return 'ReportGenerationErrorCode_InputData_UnexpectedStudyContent';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_InputData_BadTaskParameters:
      return 'ReportGenerationErrorCode_InputData_BadTaskParameters';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_CbctIosMisalignment:
      return 'ReportGenerationErrorCode_Modeling_CbctIosMisalignment';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_IosWrongOcclusion:
      return 'ReportGenerationErrorCode_Modeling_IosWrongOcclusion';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_IosMissingJaw:
      return 'ReportGenerationErrorCode_Modeling_IosMissingJaw';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_RequestedToothNotFound:
      return 'ReportGenerationErrorCode_Modeling_RequestedToothNotFound';
    case ReportGenerationErrorCode.ReportGenerationErrorCode_ValidTimeExpired:
      return 'ReportGenerationErrorCode_ValidTimeExpired';
    case ReportGenerationErrorCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
