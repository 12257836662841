// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CBCTNewReportModal-module__newReportsWrapper--N1G8incl{display:grid;grid-template-columns:repeat(3, 1fr);grid-template-rows:repeat(2, 1fr);gap:12px}.CBCTNewReportModal-module__newReport--NpbwYt9j{width:200px;height:170px;background-color:var(--transparentlavand);border-radius:12px;display:flex;justify-content:center;align-items:center;color:var(--purpletext1);text-align:center;white-space:pre;cursor:pointer;transition-property:box-shadow;transition-duration:var(--transition-time)}.CBCTNewReportModal-module__newReport--NpbwYt9j:hover{box-shadow:0 0 0 4px var(--purpleflat)}.CBCTNewReportModal-module__radial--sdZt1Qab{background:var(--radial);color:var(--purpletext2)}.CBCTNewReportModal-module__modalBody--nRfrX\\+2Z{padding-bottom:24px}", "",{"version":3,"sources":["webpack://./src/features/addNewReport/ui/CBCTNewReportModal/CBCTNewReportModal.module.scss"],"names":[],"mappings":"AAAA,wDACE,YAAA,CACA,oCAAA,CACA,iCAAA,CACA,QAAA,CAGF,gDACE,WAAA,CACA,YAAA,CACA,yCAAA,CACA,kBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,wBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,8BAAA,CACA,0CAAA,CAEA,sDACE,sCAAA,CAIJ,6CACE,wBAAA,CACA,wBAAA,CAGF,iDACE,mBAAA","sourcesContent":[".newReportsWrapper {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  grid-template-rows: repeat(2, 1fr);\n  gap: 12px;\n}\n\n.newReport {\n  width: 200px;\n  height: 170px;\n  background-color: var(--transparentlavand);\n  border-radius: 12px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: var(--purpletext1);\n  text-align: center;\n  white-space: pre;\n  cursor: pointer;\n  transition-property: box-shadow;\n  transition-duration: var(--transition-time);\n\n  &:hover {\n    box-shadow: 0 0 0 4px var(--purpleflat);\n  }\n}\n\n.radial {\n  background: var(--radial);\n  color: var(--purpletext2);\n}\n\n.modalBody {\n  padding-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newReportsWrapper": "CBCTNewReportModal-module__newReportsWrapper--N1G8incl",
	"newReport": "CBCTNewReportModal-module__newReport--NpbwYt9j",
	"radial": "CBCTNewReportModal-module__radial--sdZt1Qab",
	"modalBody": "CBCTNewReportModal-module__modalBody--nRfrX+2Z"
};
export default ___CSS_LOADER_EXPORT___;
