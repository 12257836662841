import { FC, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { isEqual } from 'lodash';

import { RCCropImageWithPTools } from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImageWithTools';
import { AbsoluteBlock } from '@/shared/graphics/RenderComponents/AbsoluteBlock';
import { RCCropImage } from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImage';
import { FloatTuple2D } from '@/shared/api/protocol_gen/model/dto_common_geometry';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';
import {
  PBLMeasurement,
  RCPBL,
} from '@/shared/graphics/RenderComponents/RCPBL/RCPBL';
import { useAppSelector, useDebouncedFunction } from '@/shared/hooks';

import { getImageSrc, makeImageViewOptions } from '@/entities/assets';
import {
  getPresetByMaskType,
  reportsModel,
  useViewOptions,
} from '@/entities/reports';
import { getConditionCodeColor } from '@/entities/condition';

import { SubImageInterface } from '@/features/IOXRayMatrix';
import { Landmark } from '@/features/toothLandmark';

import { SubImageMask } from '../../config/types';

import styles from './IOXRaySubImage.module.scss';

type IOXRaySubImageProps = {
  className?: string;
  subImage: SubImageInterface;
  reportID: string;
  scale: number;
  masks?: SubImageMask[];
  onClick?: (subImage: SubImageInterface) => void;
  landmarks?: Landmark[];
  pixelScale?: FloatTuple2D;
  disabled?: boolean;
  isPreviewMode?: boolean;
};

const viewOptions = { wwwc: { ww: 0, wc: 0 }, sharpness: 0, invert: false };

export const IOXRaySubImage: FC<IOXRaySubImageProps> = (props) => {
  const {
    className,
    subImage,
    reportID,
    scale,
    masks,
    onClick = () => {},
    landmarks,
    pixelScale,
    disabled,
    isPreviewMode = false,
  } = props;

  const maskType = useAppSelector(reportsModel.selectors.selectMaskStyle);
  const mode = useAppSelector(reportsModel.selectors.selectToolsMode);

  const activePageNumber = useAppSelector(
    reportsModel.selectors.selectActivePageNumber,
  );

  const activeControl = useAppSelector(
    reportsModel.selectors.selectToolbarActiveControl,
  );

  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectIOXRayReportViewOptions(reportID),
  );

  const subImageViewOptions = subImage.MedicalImageFeatures.ViewOptions;

  const masksShouldShown = useMemo(
    () => masks.filter((mask) => mask.condition.Code !== ConditionCode.Missing),
    [masks],
  );

  const viewOptionsSubImage = makeImageViewOptions(subImageViewOptions);
  const viewOptionsReport = useMemo(
    () => makeImageViewOptions(reportViewOptions),
    [reportViewOptions],
  );

  const {
    wwwc,
    setWWWC,
    sharpness,
    setSharpness,
    handleSetReportMedicalImageViewOptions,
    handleSetSubImageViewOptions,
    handleUpdateViewOptions,
  } = useViewOptions(isPreviewMode ? viewOptions : viewOptionsReport);

  const getPBL = (landmark: Landmark): PBLMeasurement => ({
    color: landmark.color,
    x1: landmark.lowPoint?.ModelPosition.X,
    x2: landmark.upPoint?.ModelPosition.X,
    y1: landmark.lowPoint?.ModelPosition.Y,
    y2: landmark.upPoint?.ModelPosition.Y,
  });

  const pbls = landmarks?.map((landmark) => getPBL(landmark)) ?? [];

  const imageWidth =
    (subImage?.BBox?.X?.Max ?? 0) - (subImage?.BBox?.X?.Min ?? 0);
  const imageHeight =
    (subImage?.BBox?.Y?.Max ?? 0) - (subImage?.BBox?.Y?.Min ?? 0);

  const crop = {
    x: subImage?.BBox?.X?.Min ?? 0,
    y: subImage?.BBox?.Y?.Min ?? 0,
    w: imageWidth,
    h: imageHeight,
  };

  // TODO PBL_INTEGRATION Get data from protocol and convert this input to this type
  // pass data as it is
  // const pblMeasurements: PBLMeasurement[] = [];

  // TODO PBL_INTEGRATION Get data from protocol and convert this input to this type
  // In protocol it have to have a name like "Scale" or something
  // const pixelSpacing = [1 / 50, 1 / 50] as [number, number];
  // const getPixelSpaceing = () => {
  //   return [ pixelScale?.X  ]
  // }
  const pixelSpacing = [pixelScale?.X, pixelScale?.Y] as [number, number];

  // const creads = isLocalHostOrFeatureBranch : "include" : "same-origin"
  // storage.googleapis.com - "same-origin" | "omit"
  // dev.diagnocat.dev - isLocalHostOrFeatureBranch : "include" : "same-origin"
  // dev.diagnocat.dev -> storage.googleapis.com -> unwrap request

  const handleClick = () => {
    onClick(subImage);
  };

  const delayedChangedSubImageViewOptions = useDebouncedFunction(
    handleSetSubImageViewOptions,
    900,
  );

  const delayedChangedReportViewOptions = useDebouncedFunction(
    handleSetReportMedicalImageViewOptions,
    900,
  );

  const handleChangeViewOptions = (isReportViewOption: boolean = true) => {
    if (isReportViewOption) {
      delayedChangedReportViewOptions(reportID);
    } else {
      delayedChangedSubImageViewOptions(reportID, subImage.ID);
    }
  };

  // TODO: think how to get rid of useEffects
  useEffect(() => {
    const currentOptions = {
      wwwc,
      sharpness,
      invert: false,
    };

    if (
      reportViewOptions &&
      !isPreviewMode &&
      !isEqual(viewOptionsReport, currentOptions) &&
      !isEqual(viewOptionsSubImage, currentOptions)
    ) {
      handleChangeViewOptions(activePageNumber === 0);
    }
  }, [wwwc.ww, wwwc.wc, sharpness, activePageNumber]);

  useEffect(() => {
    if (!isPreviewMode) {
      handleUpdateViewOptions(
        activePageNumber ? viewOptionsSubImage : viewOptionsReport,
      );
    }

    if (activeControl === 'reset') {
      handleUpdateViewOptions({
        wwwc: { ww: 0, wc: 0 },
        sharpness: 0,
        invert: false,
      });
    }
  }, [activeControl, activePageNumber, reportViewOptions]);

  return (
    <div onClick={handleClick}>
      <RCCropImageWithPTools
        className={cn(className, disabled && styles.disabled)}
        mode={mode}
        currentAnnotation="eraser"
        crop={crop}
        style={{
          height: imageHeight * scale,
          width: imageWidth * scale,
          overflow: 'hidden',
          borderRadius: '8px',
          objectFit: 'contain',
        }}
        onLoaded={() => {}}
        src={{
          url: getImageSrc(subImage.TargetAssetID, 'original'),
          kind: 'raster',
          // TODO PBL_INTEGRATION Check if you have cors problems here
          // If you do, you have to set correct credentials for localhost and featurebranch
        }}
        annotations={[]}
        inverted={false}
        sharpness={sharpness}
        onSharpnessChanged={setSharpness}
        onWWWCChanged={(currentWwwc) => setWWWC(currentWwwc)}
        wc={wwwc?.wc}
        ww={wwwc?.ww}
      />
      {masksShouldShown && (
        <AbsoluteBlock
          className={cn(
            styles.masksContainer,
            mode !== 'view' && styles.pointerEventsNone,
          )}
          style={{ top: 0, left: 0, zIndex: 5 }}
          data-id="MedicalImage Mask"
        >
          {masksShouldShown.map((mask) => (
            <RCCropImage
              key={mask.url}
              src={{
                url: mask.url,
                kind: mask.kind,
                credentials: 'include', // TODO PBL_INTEGRATION Set correct credentials for url
              }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                mixBlendMode: 'multiply',
                ...getPresetByMaskType(maskType)[
                  getConditionCodeColor(mask.condition.Code, true)
                ].style,
              }}
              uniforms={{
                // change mask color here, R, G, B from 0 to 1
                color: getPresetByMaskType(maskType)[
                  getConditionCodeColor(mask.condition.Code, true)
                ].color as [number, number, number],
                opacity:
                  getPresetByMaskType(maskType)[
                    getConditionCodeColor(mask.condition.Code, true)
                  ].opacity,
              }}
              crop={crop}
            />
          ))}
        </AbsoluteBlock>
      )}
      <AbsoluteBlock
        style={{ top: 0, left: 0, zIndex: 8 }}
        className={cn(mode !== 'view' && styles.pointerEventsNone)}
      >
        <RCPBL measurements={pbls} crop={crop} pixelSpacing={pixelSpacing} />
      </AbsoluteBlock>
    </div>
  );
};
