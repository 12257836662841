// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GroupMedicalImagesList-module__container--FbzXQrwD{position:relative}.GroupMedicalImagesList-module__medicalImages--GWKgmUVV{position:relative;display:flex;flex-wrap:wrap;grid-gap:8px}\n\n.GroupMedicalImagesList-module__restoreButton--jMQgLPD2{position:absolute;top:-55px;right:0}\n\n[dir=\"rtl\"] .GroupMedicalImagesList-module__restoreButton--jMQgLPD2{right:auto;left:0}.GroupMedicalImagesList-module__medicalImageWrapper--prSHAoJ5{position:relative}.GroupMedicalImagesList-module__mask--qJCcBmns{background-color:#ff0;top:0;opacity:.4}.GroupMedicalImagesList-module__medicalImagesActions--d0csT-3B{display:flex;margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/widgets/MedicalImageGroup/ui/GroupMedicalImagesList/GroupMedicalImagesList.module.scss"],"names":[],"mappings":"AAAA,oDACE,iBAAA,CAGF,wDACE,iBAAA,CACA,YAAA,CACA,cAAA,CACA,YAAA;;AAGF,wDACE,iBAAA,CACA,SAAA,CACA,OAAA;;AAHF,oEAGE,UAAA,CAAA,MAAA,CAGF,8DACE,iBAAA,CAGF,+CACE,qBAAA,CACA,KAAA,CACA,UAAA,CAGF,+DACE,YAAA,CACA,iBAAA","sourcesContent":[".container {\n  position: relative;\n}\n\n.medicalImages {\n  position: relative;\n  display: flex;\n  flex-wrap: wrap;\n  grid-gap: 8px;\n}\n\n.restoreButton {\n  position: absolute;\n  top: -55px;\n  right: 0;\n}\n\n.medicalImageWrapper {\n  position: relative;\n}\n\n.mask {\n  background-color: yellow;\n  top: 0;\n  opacity: 0.4;\n}\n\n.medicalImagesActions {\n  display: flex;\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GroupMedicalImagesList-module__container--FbzXQrwD",
	"medicalImages": "GroupMedicalImagesList-module__medicalImages--GWKgmUVV",
	"restoreButton": "GroupMedicalImagesList-module__restoreButton--jMQgLPD2",
	"medicalImageWrapper": "GroupMedicalImagesList-module__medicalImageWrapper--prSHAoJ5",
	"mask": "GroupMedicalImagesList-module__mask--qJCcBmns",
	"medicalImagesActions": "GroupMedicalImagesList-module__medicalImagesActions--d0csT-3B"
};
export default ___CSS_LOADER_EXPORT___;
