import { MaskStyleTypes } from '../config/reports.type';
import { presetTypes } from '../config/constants';

export const getPresetByMaskType = (maskColorType: MaskStyleTypes) => {
  switch (maskColorType) {
    case MaskStyleTypes.Default:
      return presetTypes[0];
    case MaskStyleTypes.Style2:
      return presetTypes[1];
    case MaskStyleTypes.Style3:
      return presetTypes[2];
    default:
      return presetTypes[3];
  }
};
