// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReportSettingsToggle-module__contaner--yswK2JQf{display:flex;gap:16px;flex-wrap:wrap}@media screen and (max-width: 420px){.ReportSettingsToggle-module__contaner--yswK2JQf{display:grid;gap:12px}}.ReportSettingsToggle-module__lowProbability--ve14sbFD{display:flex;align-items:center;gap:8px}.ReportSettingsToggle-module__lowProbabilityInfoIcon--dCH71FfU{width:24px;height:24px;color:var(--purpleflat)}", "",{"version":3,"sources":["webpack://./src/entities/reports/ui/ReportSettingsToggles/ReportSettingsToggle.module.scss","webpack://./src/shared/styles/media.scss"],"names":[],"mappings":"AAEA,iDACE,YAAA,CACA,QAAA,CACA,cAAA,CCEA,qCDLF,iDAMI,YAAA,CACA,QAAA,CAAA,CAIJ,uDACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,+DACE,UAAA,CACA,WAAA,CACA,uBAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.contaner {\n  display: flex;\n  gap: 16px;\n  flex-wrap: wrap;\n\n  @include mobile {\n    display: grid;\n    gap: 12px;\n  }\n}\n\n.lowProbability {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.lowProbabilityInfoIcon {\n  width: 24px;\n  height: 24px;\n  color: var(--purpleflat);\n}\n","$mobile: 420px;\n$tablet: 768px;\n$smallDesktop: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1400px;\n\n@mixin mobile {\n  @media screen and (max-width: $mobile) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media screen and (max-width: $tablet) {\n    @content;\n  }\n}\n\n@mixin smallDesktop {\n  @media screen and (max-width: $smallDesktop) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media screen and (max-width: $desktop) {\n    @content;\n  }\n}\n\n@mixin largeDesktop {\n  @media screen and (max-width: $largeDesktop) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contaner": "ReportSettingsToggle-module__contaner--yswK2JQf",
	"lowProbability": "ReportSettingsToggle-module__lowProbability--ve14sbFD",
	"lowProbabilityInfoIcon": "ReportSettingsToggle-module__lowProbabilityInfoIcon--dCH71FfU"
};
export default ___CSS_LOADER_EXPORT___;
