// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Signature-module__container--wmS8fvjP{width:100%;height:110px;display:flex;align-items:flex-start;gap:8px}@media screen and (max-width: 420px){.Signature-module__container--wmS8fvjP{flex-direction:column;align-items:flex-start;gap:8px}}.Signature-module__nameAndSpeciality--AJBXSKjm{height:100%;display:grid;gap:4px;align-items:center;flex:1}.Signature-module__signedUserName--ojSwMOnQ{white-space:pre}.Signature-module__signatureOrStampImage--csq7F0P0{width:100%;height:100%;object-fit:contain;object-position:center}.Signature-module__imgFrame--2VRQg\\+Hb{height:100%;flex:1}", "",{"version":3,"sources":["webpack://./src/pages/PreviewReport/ui/Signature/Signature.module.scss"],"names":[],"mappings":"AAEA,uCACE,UAAA,CACA,YAAA,CACA,YAAA,CACA,sBAAA,CACA,OAAA,CAAA,qCALF,uCAQI,qBAAA,CACA,sBAAA,CACA,OAAA,CAAA,CAIJ,+CACE,WAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CACA,MAAA,CAGF,4CACE,eAAA,CAGF,mDACE,UAAA,CACA,WAAA,CACA,kBAAA,CACA,sBAAA,CAGF,uCACE,WAAA,CACA,MAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.container {\n  width: 100%;\n  height: 110px;\n  display: flex;\n  align-items: flex-start;\n  gap: 8px;\n\n  @include mobile {\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 8px;\n  }\n}\n\n.nameAndSpeciality {\n  height: 100%;\n  display: grid;\n  gap: 4px;\n  align-items: center;\n  flex: 1;\n}\n\n.signedUserName {\n  white-space: pre;\n}\n\n.signatureOrStampImage {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  object-position: center;\n}\n\n.imgFrame {\n  height: 100%;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Signature-module__container--wmS8fvjP",
	"nameAndSpeciality": "Signature-module__nameAndSpeciality--AJBXSKjm",
	"signedUserName": "Signature-module__signedUserName--ojSwMOnQ",
	"signatureOrStampImage": "Signature-module__signatureOrStampImage--csq7F0P0",
	"imgFrame": "Signature-module__imgFrame--2VRQg+Hb"
};
export default ___CSS_LOADER_EXPORT___;
