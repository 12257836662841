import { BBox } from '@/shared/api/protocol_gen/model/dto_common_geometry';

export const doBBoxOverlap = (
  subImage: BBox,
  toothLocalization: BBox,
): boolean =>
  (toothLocalization?.X?.Min ?? 0) >= (subImage?.X?.Min ?? 0) &&
  (toothLocalization?.X?.Max ?? 0) <= (subImage?.X?.Max ?? 0) &&
  (toothLocalization?.Y?.Min ?? 0) >= (subImage?.Y?.Min ?? 0) &&
  (toothLocalization?.Y?.Max ?? 0) <= (subImage?.Y?.Max ?? 0);
