// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MPRControlTools-module__container--tdcGGLgD{display:flex;gap:4px}.MPRControlTools-module__controlIcon--2Vy0863Y{cursor:pointer;color:var(--bw);background-color:var(--wb);border-radius:8px}.MPRControlTools-module__controlIcon--2Vy0863Y:hover,.MPRControlTools-module__controlIcon--2Vy0863Y.MPRControlTools-module__active--1IkXcshS{background-color:var(--purpledec);color:var(--wb);fill:var(--wb);opacity:1}", "",{"version":3,"sources":["webpack://./src/widgets/MPR/ui/MPRControlTools/MPRControlTools.module.scss"],"names":[],"mappings":"AAAA,6CACE,YAAA,CACA,OAAA,CAGF,+CACE,cAAA,CACA,eAAA,CACA,0BAAA,CACA,iBAAA,CAEA,6IAEE,iCAAA,CACA,eAAA,CACA,cAAA,CACA,SAAA","sourcesContent":[".container {\n  display: flex;\n  gap: 4px;\n}\n\n.controlIcon {\n  cursor: pointer;\n  color: var(--bw);\n  background-color: var(--wb);\n  border-radius: 8px;\n\n  &:hover,\n  &.active {\n    background-color: var(--purpledec);\n    color: var(--wb);\n    fill: var(--wb);\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MPRControlTools-module__container--tdcGGLgD",
	"controlIcon": "MPRControlTools-module__controlIcon--2Vy0863Y",
	"active": "MPRControlTools-module__active--1IkXcshS"
};
export default ___CSS_LOADER_EXPORT___;
