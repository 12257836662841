import { useState } from 'react';
import { useIntl } from 'react-intl';

import { MedicalImageViewOptions } from '@/shared/api/protocol_gen/model/dto_common_image_view_options';
import { ApiError } from '@/shared/api/api';
import { useAppDispatch } from '@/shared/hooks';
import { toastCaller } from '@/shared/ui';
import { ToastErrors, toastErrors } from '@/shared/config';

import * as reportsModel from '../model';
import { ViewOptionsInterface } from '../config';
import { transformViewOptions } from '../lib/transformViewOptions';

export const useViewOptions = (viewOptions?: ViewOptionsInterface) => {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const [sharpness, setSharpness] = useState(() =>
    viewOptions?.sharpness ? viewOptions?.sharpness : 0,
  );

  const [wwwc, setWWWC] = useState<{ ww: number; wc: number }>(
    () => viewOptions?.wwwc || { ww: 0, wc: 0 },
  );

  const handleSetSubImageViewOptions = async (
    reportID: string,
    subImageID: string,
  ) => {
    try {
      const updatedViewOptions = transformViewOptions({
        wwwc,
        sharpness,
        invert: false,
      });

      const { Report } = await dispatch(
        reportsModel.thunks.setReportIOXRaySubimageMedicalImageViewOptions({
          ReportID: reportID,
          IOXRaySubimageID: subImageID,
          MedicalImageViewOptions: updatedViewOptions,
        }),
      ).unwrap();

      if (Report) {
        dispatch(reportsModel.actions.setNewestOne(Report));
      }
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };

  const handleSetReportMedicalImageViewOptions = async (reportID: string) => {
    try {
      const updatedViewOptions: MedicalImageViewOptions = {
        WindowWidth: wwwc.ww,
        WindowLevel: wwwc.wc,
        Sharpness: sharpness,
        Invert: false,
        ColorMap: undefined,
        ViewportCoords: undefined,
      };

      const { Report } = await dispatch(
        reportsModel.thunks.setReportMedicalImageViewOptions({
          ReportID: reportID,
          MedicalImageViewOptions: updatedViewOptions,
        }),
      ).unwrap();

      if (Report) {
        dispatch(reportsModel.actions.setNewestOne(Report));
      }
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };

  const handleUpdateViewOptions = (options: ViewOptionsInterface) => {
    setWWWC(options.wwwc);
    setSharpness(options.sharpness);
  };

  return {
    sharpness,
    setSharpness,
    wwwc,
    setWWWC,
    handleSetSubImageViewOptions,
    handleSetReportMedicalImageViewOptions,
    handleUpdateViewOptions,
  };
};
