import { FC, useMemo } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { format } from 'date-fns';

import { Icon, WidgetCard } from '@/shared/ui';
import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import BasicCBCTToStlImage from '@/shared/assets/images/basic-CBCT-to-Stl.png';
import OriginalIOSImage from '@/shared/assets/images/originalIOS.png';
import OriginalIOS2Image from '@/shared/assets/images/originalIOS-2.png';

import { getImageSrc } from '@/entities/assets';
import { i18n } from '@/entities/reports';

import { ReportGeneratingProgress } from '@/features/reportRow';
import {
  RemoveReportModal,
  useRemoveReportModal,
} from '@/features/removeReport';

import { getModelTypeForUrl } from '../../utils/getModelTypeForUrl';

import styles from './SegmentronModelWidget.module.scss';

type ModelWidgetProps = {
  report?: Report;
  className?: string;
};

export const SegmentronModelWidget: FC<ModelWidgetProps> = (props) => {
  const { className, report } = props;

  const { formatMessage } = useIntl();

  // TODO: delete mocked images, and get images from reports
  const mockedImage = (reportType: ReportType) => {
    switch (reportType) {
      case ReportType.ReportType_CBCT_IOS_Superimposition:
        return BasicCBCTToStlImage;
      case ReportType.ReportType_CBCT_Segmentation:
        return OriginalIOSImage;
      default:
        return OriginalIOS2Image;
    }
  };

  const {
    isRemoveReportModalOpen,
    openRemoveReportModal,
    closeRemoveReportModal,
  } = useRemoveReportModal();

  const reportLink = useMemo(() => {
    const orderType = getModelTypeForUrl(
      report?.Type ?? ReportType.ReportType_CBCT_Segmentation,
    );

    return `https://dev.diagnocat.dev/studio/${orderType}/?analysis_uid=${report?.ID}`;
  }, [report]);

  // TODO [8|h]: add skeletons after we develop loading strategy with the team
  if (!report) {
    return null;
  }

  const createdAt = format(
    report?.Created?.At ? new Date(report?.Created.At) : new Date(),
    'MMMM, d yyyy',
  );
  const reportPreview = getImageSrc(report?.DisplayAssetID, 'thumbnail');

  return (
    <WidgetCard
      className={cn(styles.container, className, {
        [styles.unVisible]: !report,
      })}
    >
      <div className={styles.loadingWrapper}>
        <div className={styles.heading}>
          <h3 className="h3">
            <FormattedMessage
              id="orderModel.Segmentron3DModels"
              defaultMessage="Segmentron 3D Models"
            />
          </h3>
          <div className={styles.actions}>
            <Icon
              name="delete"
              size={32}
              onClick={() => openRemoveReportModal(report.ID)}
            />
          </div>
        </div>
        <div
          className={cn(
            !report.Status.InProgress &&
              !report.Status.Failed &&
              styles.imageWrapper,
            styles.progressBarWrapper,
          )}
        >
          {report.Status.InProgress || report.Status.Failed ? (
            <ReportGeneratingProgress
              report={report}
              loadingText={formatMessage({
                id: 'orderModel.progress.text',
                defaultMessage: 'Generating 3d model',
              })}
            />
          ) : (
            <a href={reportLink} target="_blank" rel="noreferrer">
              <img
                src={
                  report.DisplayAssetID
                    ? reportPreview
                    : mockedImage(report.Type)
                }
                className={styles.img}
                alt={formatMessage(i18n[report.Type])}
              />
            </a>
          )}

          <div className={styles.footer}>
            <div>
              <h3 className={styles.reportCreationDate}>
                <FormattedMessage
                  id="global.CreationDate"
                  defaultMessage="Creation date"
                />
              </h3>
              <p>{createdAt}</p>
            </div>

            {report && (
              <p className={styles.reportType}>
                {formatMessage(i18n[report.Type])}
              </p>
            )}
          </div>
        </div>
      </div>
      <RemoveReportModal
        reportID={report?.ID}
        isOpen={isRemoveReportModalOpen}
        onClose={closeRemoveReportModal}
      />
    </WidgetCard>
  );
};
