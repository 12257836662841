// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BasicCBCTToSTL-module__radioGroup--hVcIJq-d>div{flex-direction:column;margin-top:16px}.BasicCBCTToSTL-module__chart--3zkMge4U{padding:16px}.BasicCBCTToSTL-module__switches--Iwr2DSfc{display:flex;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/widgets/Order3DSegmentronModal/ui/BasicCBCTToSTL/BasicCBCTToSTL.module.scss"],"names":[],"mappings":"AACE,iDACE,qBAAA,CACA,eAAA,CAIJ,wCACE,YAAA,CAGF,2CACE,YAAA,CACA,6BAAA","sourcesContent":[".radioGroup {\n  > div {\n    flex-direction: column;\n    margin-top: 16px;\n  }\n}\n\n.chart {\n  padding: 16px;\n}\n\n.switches {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioGroup": "BasicCBCTToSTL-module__radioGroup--hVcIJq-d",
	"chart": "BasicCBCTToSTL-module__chart--3zkMge4U",
	"switches": "BasicCBCTToSTL-module__switches--Iwr2DSfc"
};
export default ___CSS_LOADER_EXPORT___;
