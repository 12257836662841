// TODO: remove after fix NewReportToothChart
export const teethForNewReportMock = [
  {
    ID: 'cfv814r9advs73842ql0',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 18,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384au20',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 457,
            Max: 618,
            Size: 0,
          },
          X: {
            Min: 439,
            Max: 525,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 537,
          X: 481,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200013787,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200013787,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842q80',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 17,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asg0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 459,
            Max: 645,
            Size: 0,
          },
          X: {
            Min: 508,
            Max: 601,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 557,
          X: 559,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200012197,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200012197,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qhg',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 16,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384au40',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 465,
            Max: 661,
            Size: 0,
          },
          X: {
            Min: 597,
            Max: 697,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 571,
          X: 651,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200013781,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200013781,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qfg',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 15,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384ass0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 482,
            Max: 662,
            Size: 0,
          },
          X: {
            Min: 693,
            Max: 759,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 578,
          X: 726,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200013777,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200013777,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qj0',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 14,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asu0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 433,
            Max: 656,
            Size: 0,
          },
          X: {
            Min: 750,
            Max: 824,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 553,
          X: 788,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200013783,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200013783,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qc0',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 13,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384ash0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 412,
            Max: 658,
            Size: 0,
          },
          X: {
            Min: 806,
            Max: 878,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 546,
          X: 840,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200012206,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200012206,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qbg',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 12,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asi0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 437,
            Max: 654,
            Size: 0,
          },
          X: {
            Min: 877,
            Max: 934,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 551,
          X: 906,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200012205,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200012205,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qag',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 11,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384ask0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 430,
            Max: 648,
            Size: 0,
          },
          X: {
            Min: 935,
            Max: 1014,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 548,
          X: 973,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200012203,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200012203,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qig',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 21,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asv0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 431,
            Max: 644,
            Size: 0,
          },
          X: {
            Min: 1015,
            Max: 1091,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 543,
          X: 1055,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200013783,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200013783,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qd0',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 22,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384aso0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 418,
            Max: 642,
            Size: 0,
          },
          X: {
            Min: 1085,
            Max: 1154,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 534,
          X: 1123,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200012208,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200012208,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qgg',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 23,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asq0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 388,
            Max: 649,
            Size: 0,
          },
          X: {
            Min: 1141,
            Max: 1217,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 525,
          X: 1185,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200013779,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200013779,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qe0',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 24,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asm0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 447,
            Max: 645,
            Size: 0,
          },
          X: {
            Min: 1193,
            Max: 1276,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 553,
          X: 1241,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200013774,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200013774,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qb0',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 25,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asj0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 471,
            Max: 656,
            Size: 0,
          },
          X: {
            Min: 1267,
            Max: 1348,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 569,
          X: 1310,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200012204,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200012204,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842q6g',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 26,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asa0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 457,
            Max: 645,
            Size: 0,
          },
          X: {
            Min: 1337,
            Max: 1445,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 557,
          X: 1390,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200012186,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200012186,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842q90',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 27,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384ase0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 442,
            Max: 625,
            Size: 0,
          },
          X: {
            Min: 1432,
            Max: 1529,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 539,
          X: 1479,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200012200,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200012200,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qk0',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 28,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384au30',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 442,
            Max: 595,
            Size: 0,
          },
          X: {
            Min: 1518,
            Max: 1606,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 518,
          X: 1561,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200013785,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200013785,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842q70',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 38,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384as90',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 603,
            Max: 743,
            Size: 0,
          },
          X: {
            Min: 1483,
            Max: 1612,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 670,
          X: 1552,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200012193,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200012193,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qa0',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 37,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384au70',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 644,
            Max: 799,
            Size: 0,
          },
          X: {
            Min: 1397,
            Max: 1543,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 716,
          X: 1469,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200012202,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200012202,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842q8g',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 36,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asf0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 670,
            Max: 855,
            Size: 0,
          },
          X: {
            Min: 1298,
            Max: 1440,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 749,
          X: 1368,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200012199,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200012199,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qf0',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 35,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384au50',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 679,
            Max: 859,
            Size: 0,
          },
          X: {
            Min: 1231,
            Max: 1312,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 759,
          X: 1280,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200013776,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200013776,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qi0',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 34,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384at00',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 683,
            Max: 869,
            Size: 0,
          },
          X: {
            Min: 1171,
            Max: 1244,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 763,
          X: 1215,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200013782,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200013782,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qg0',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 33,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asr0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 675,
            Max: 898,
            Size: 0,
          },
          X: {
            Min: 1116,
            Max: 1177,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 778,
          X: 1150,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200013778,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200013778,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qcg',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 32,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384au60',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 667,
            Max: 874,
            Size: 0,
          },
          X: {
            Min: 1067,
            Max: 1116,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 755,
          X: 1093,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200012207,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200012207,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qjg',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 31,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384ast0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 669,
            Max: 868,
            Size: 0,
          },
          X: {
            Min: 1020,
            Max: 1067,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 755,
          X: 1045,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200013784,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200013784,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qh0',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 41,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asp0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 671,
            Max: 862,
            Size: 0,
          },
          X: {
            Min: 975,
            Max: 1020,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 750,
          X: 997,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200013780,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200013780,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842q7g',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 42,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384au80',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 672,
            Max: 880,
            Size: 0,
          },
          X: {
            Min: 925,
            Max: 974,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 763,
          X: 948,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200012196,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200012196,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842q9g',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 43,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asd0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 682,
            Max: 909,
            Size: 0,
          },
          X: {
            Min: 866,
            Max: 922,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 789,
          X: 893,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200012201,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200012201,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qdg',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 44,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asn0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 692,
            Max: 880,
            Size: 0,
          },
          X: {
            Min: 796,
            Max: 859,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 775,
          X: 822,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200012218,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200012218,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qeg',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 45,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asl0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 690,
            Max: 860,
            Size: 0,
          },
          X: {
            Min: 726,
            Max: 795,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 765,
          X: 755,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200013775,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200013775,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842q60',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 46,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asb0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 684,
            Max: 852,
            Size: 0,
          },
          X: {
            Min: 615,
            Max: 734,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 758,
          X: 674,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200010862,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200010862,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842q5g',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 47,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384asc0',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 664,
            Max: 826,
            Size: 0,
          },
          X: {
            Min: 512,
            Max: 631,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 742,
          X: 572,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200010858,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200010858,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
  {
    ID: 'cfv814r9advs73842qkg',
    ReportID: 'cfv814j9advs73842hcg',
    PatientID: 'cfv813j9advs738425fg',
    OrganizationID: 'cfha1dnsemvgfb7rmncg',
    ISONumber: 48,
    Comment: '',
    IsInROI: true,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    IsMissing: false,
    IsSupernumerary: false,
    Localizations: [
      {
        ID: 'cfv815b9advs7384at20',
        Target: {
          AssetID: 'cfv814b9advs73842e90',
          FileID: 'cfv814b9advs73842e9g',
        },
        Certainty: {
          ModelDecision: 0,
          ModelScore: 0,
          IsModelScoreSet: false,
          UserDecision: 0,
          EngineDecision: 0,
        },
        Type: 0,
        MaskAssetID: '',
        MaskOffset: {
          Z: 0,
          Y: 0,
          X: 0,
        },
        BBox: {
          Z: {
            Min: 0,
            Max: 0,
            Size: 0,
          },
          Y: {
            Min: 657,
            Max: 788,
            Size: 0,
          },
          X: {
            Min: 439,
            Max: 530,
            Size: 0,
          },
        },
        Centroid: {
          Z: 0,
          Y: 722,
          X: 484,
        },
      },
    ],
    DisplaySlices: [],
    Created: {
      By: 'system:core/report_ingress',
      At: 1669200013786,
      Via: 0,
    },
    Revision: {
      Number: 1,
      UpdatedAt: 1669200013786,
      UserID: 'system:core/report_ingress',
    },
    Deleted: {
      Deleted: false,
      By: '',
      At: 0,
      Via: 0,
    },
  },
];
