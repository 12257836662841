import { both, filter, propEq } from 'ramda';

import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import { isReportIncludesStudyID } from './isReportIncludesStudyID';

export const filterReportByStudyIDAndType = (
  reports: Report[],
  studyID: string,
  reportType: ReportType,
) =>
  filter<Report>(
    both(isReportIncludesStudyID(studyID), propEq('Type', reportType)),
  )(reports);
