import { useMemo } from 'react';
import { filter } from 'ramda';

import { useAppSelector } from '@/shared/hooks';
// TODO: move to logicalCondition entity
// import * as logicalConditionModel from '../model';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

// TODO: move to logicalCondition entity
import { logicalConditionModel } from '@/entities/logicalCondition';

import {
  filterConditionItemsByGroups,
  groupConditionInterfaceByGroup,
  shouldConditionItemBeShown,
} from '../lib';
import {
  ConditionGroups,
  ConditionInterface,
  TOOTH_ANATOMY_GROUPS,
  TOOTH_GENERAL_GROUPS,
  TOOTH_TYPE_GROUP,
} from '../config';

import { useTransformLogicalCondition } from './useTransformLogicalCondition';

type UseToothConditionsPropsReturnType = {
  conditionIsLoaded: boolean;
  allConditionItems: ConditionInterface[];
  anatomyConditionItems: ConditionInterface[];
  generalConditionItems: ConditionInterface[];
  groupedConditionItems: Record<ConditionGroups, ConditionInterface[]>;
  conditionsToBeShown: Condition[];
};

type UseToothConditionsProps = (props: {
  toothID: string;
  showLowProbability: boolean;
}) => UseToothConditionsPropsReturnType;

const isConditionItemVisible = (item: ConditionInterface) => item.isChecked;

export const useToothConditions: UseToothConditionsProps = ({
  toothID,
  showLowProbability,
}) => {
  const toothConditions = useAppSelector(
    logicalConditionModel.selectors.selectToothConditions(toothID),
  );

  const mappedConditions = useMemo(() => {
    const conditions = Object.values(toothConditions.conditions).flatMap(
      (item) => Object.values(item),
    );
    const childConditions = Object.values(
      toothConditions.childConditions,
    ).flatMap((item) =>
      Object.values(item).flatMap((childItem) => Object.values(childItem)),
    );

    return conditions.concat(childConditions);
  }, [toothConditions]);

  const conditionIsLoading = useAppSelector(
    logicalConditionModel.selectors.selectLoading,
  );

  const conditionIsLoaded = conditionIsLoading === 'succeeded';

  const conditionItems = useTransformLogicalCondition(
    toothConditions,
    true,
    showLowProbability,
  );

  const dataConditionsItems = useTransformLogicalCondition(
    toothConditions,
    false,
    showLowProbability,
  );

  const visibleConditionItems = useMemo(
    () => dataConditionsItems.filter(isConditionItemVisible),
    [dataConditionsItems],
  );

  const groupedConditionItems = useMemo(
    () => groupConditionInterfaceByGroup(conditionItems),
    [conditionItems],
  );

  // We already have all conditions in conditionItems, so we can filter them by isChecked field
  const conditionsToBeShown = useMemo(
    () =>
      filter(shouldConditionItemBeShown(showLowProbability), mappedConditions),
    [mappedConditions, showLowProbability],
  );

  // Leave in case of some bugs. No need to rush when it's about conditions
  // const anatomyConditions = useMemo(
  //   () =>
  //     filterConditionsByGroups({
  //       ...TOOTH_TYPE_GROUP,
  //       ...TOOTH_ANATOMY_GROUPS,
  //     })(conditionsToBeShown),
  //   [conditionsToBeShown],
  // );

  const anatomyConditionItems = useMemo(
    () =>
      filterConditionItemsByGroups({
        ...TOOTH_TYPE_GROUP,
        ...TOOTH_ANATOMY_GROUPS,
      })(visibleConditionItems),
    [visibleConditionItems],
  );

  // Leave in case of some bugs. No need to rush when it's about conditions
  // const generalConditions = useMemo(
  //   () =>
  //     filterConditionsByGroups({
  //       ...TOOTH_GENERAL_GROUPS,
  //     })(conditionsToBeShown),
  //   [conditionsToBeShown],
  // );

  const generalConditionItems = useMemo(
    () =>
      filterConditionItemsByGroups(TOOTH_GENERAL_GROUPS)(visibleConditionItems),
    [visibleConditionItems],
  );

  // Leave in case of some bugs. No need to rush when it's about conditions
  // const anatomyConditionItems = useTransformCondition(
  //   anatomyConditions,
  //   false,
  //   showLowProbability,
  // );

  // const generalConditionItems = useTransformCondition(
  //   generalConditions,
  //   false,
  //   showLowProbability,
  // );

  // const allConditionItems = useTransformCondition(
  //   conditionsToBeShown,
  //   false,
  //   showLowProbability,
  // );

  return {
    conditionIsLoaded,
    allConditionItems: visibleConditionItems,
    conditionsToBeShown,
    anatomyConditionItems,
    generalConditionItems,
    groupedConditionItems,
  };
};
