// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToothIcon-module__container--Xb9qOAVf{color:var(--white);fill:var(--grey2);stroke:var(--grey3)}.ToothIcon-module__pointer--OSh71Qym{cursor:pointer}.ToothIcon-module__missing--hSXqNLif{color:var(--redrose)}.ToothIcon-module__missingBlackAndWhite--GZ7jejzM{color:var(--black)}", "",{"version":3,"sources":["webpack://./src/shared/ui/ToothIcon/ToothIcon.module.scss"],"names":[],"mappings":"AAAA,uCACE,kBAAA,CACA,iBAAA,CACA,mBAAA,CAGF,qCACE,cAAA,CAGF,qCACE,oBAAA,CAGF,kDACE,kBAAA","sourcesContent":[".container {\n  color: var(--white);\n  fill: var(--grey2);\n  stroke: var(--grey3);\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.missing {\n  color: var(--redrose);\n}\n\n.missingBlackAndWhite {\n  color: var(--black);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ToothIcon-module__container--Xb9qOAVf",
	"pointer": "ToothIcon-module__pointer--OSh71Qym",
	"missing": "ToothIcon-module__missing--hSXqNLif",
	"missingBlackAndWhite": "ToothIcon-module__missingBlackAndWhite--GZ7jejzM"
};
export default ___CSS_LOADER_EXPORT___;
