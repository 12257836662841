import { useState } from 'react';

import { StudyType } from '@/shared/api/protocol_gen/model/dto_study';

// TODO [4|m]: remove this hook and add logic into modal model
export const useCBCTNewReportModal = () => {
  const [show, setShow] = useState(false);

  const shouldOpenCBCTNewReport = (studyType: StudyType) =>
    studyType === StudyType.StudyType_CBCT;

  const openCBCTNewReportModal = () => {
    setShow(true);
  };

  const closeCBCTNewReportModal = () => {
    setShow(false);
  };

  return {
    showCBCTNewReportModal: show,
    openCBCTNewReportModal,
    closeCBCTNewReportModal,
    shouldOpenCBCTNewReport,
  };
};
