import {
  CBCTSectionOrientation,
  FileMetadataImage,
} from '@/shared/api/protocol_gen/model/dto_asset_common';
import { AssetContentGeneratedCBCTToothPathologySlice_PathologyType } from '@/shared/api/protocol_gen/model/dto_asset';
import { MedicalImageInterface } from '@/shared/config';
import { AnnotationTypeGuide } from '@/shared/api/protocol_gen/model/dto_common_image_annotations';
import {
  CBCTGPToothSliceMetadataGuide,
  CBCTGPToothSliceMetadataGuideFrontal,
} from '@/shared/api/protocol_gen/model/dto_asset_cbct_gp';
import { AssetContentGeneratedCBCTEndoReportSection } from '@/shared/api/protocol_gen/model/dto_asset_cbct_endo';

export interface ToothImage {
  // TODO: remove after migration to new api
  id?: string;
  reportId?: number;
  toothNumber?: number;
  key: string;
  revision?: number;
  revisionOrigin?: string;
  nameRu?: string;
  nameEn?: string;
  s3Endpoint?: string;
  s3Bucket?: string;
  s3ObjectName?: string;
  url?: string;
  isSelected?: boolean;
  format?: number;
  meta?: string;
  createdAt?: string;
  updatedAt?: string;
  report?: unknown;
  name?: string;
  type?: string;
  order: number | null;
  section?: string;
  thicknessMm?: number;
  strideMm?: number;
  misc?: string;
  annotations?: string;
  viewport?: string;
  displayKey?: string;
  selectedOrder?: number;
  maskUrl?: string;
  fileName?: string;
  attributeId?: string;
  autoDelete?: boolean;
}

export type GuideType =
  | (CBCTGPToothSliceMetadataGuide & { Lines?: AnnotationTypeGuide[] })
  | (CBCTGPToothSliceMetadataGuideFrontal & { Lines?: AnnotationTypeGuide[] });

export type Guide = {
  id?: string;
  guide?: GuideType;
  src: string;
  image: FileMetadataImage;
  guideAxesName?: string;
};

export type Group = {
  type?: CBCTSectionOrientation | number | string;
  titleId?: string;
  guides: Guide[];
  medicalImages: MedicalImageInterface[];
};

export type PathologyGroup = {
  type:
    | AssetContentGeneratedCBCTToothPathologySlice_PathologyType
    | number
    | string;
  titleId?: string;
  medicalImages?: MedicalImageInterface[];
  masks?: string[];
  guides?: Guide[];
};

export enum GuideAxesNames {
  SectionOrientationInvalidValue = 'SectionOrientationInvalidValue',
  SectionOrientationAxial = 'SectionOrientationAxial',
  SectionOrientationVestibuloOral = 'SectionOrientationVestibuloOral',
  SectionOrientationMesioDistal = 'SectionOrientationMesioDistal',
}

export type GroupConfigsType = {
  groupName: GPMedicalImageGroupsTypes;
  groupCode:
    | CBCTSectionOrientation
    | AssetContentGeneratedCBCTToothPathologySlice_PathologyType
    | AssetContentGeneratedCBCTEndoReportSection;
};

export type GPMedicalImageGroupsTypes =
  | 'CBCTGPToothSliceGuideFrontal'
  | 'CBCTEndoFurcationGuide'
  | 'OrientationVestibuloOral'
  | 'OrientationMesioDistal'
  | 'OrientationAxial'
  | 'Caries'
  | 'PeriapicalLesion'
  | 'Endo_ReportSection_RootCanalSpace'
  | 'Endo_ReportSection_RootCanalSystem'
  | 'Endo_ReportSection_RootCanalShape'
  | 'Endo_ReportSection_Furcation';

export enum MedicalImagesGroups {
  CBCTGPToothSliceGuideFrontal = 'CBCTGPToothSliceGuideFrontal',
  OrientationAxial = 'OrientationAxial',
  OrientationMesioDistal = 'OrientationMesioDistal',
  CBCTEndoFurcationGuide = 'CBCTEndoFurcationGuide',
  OrientationVestibuloOral = 'OrientationVestibuloOral',
  Caries = 'Caries',
  PeriapicalLesion = 'PeriapicalLesion',
  EndoReportSectionFurcation = 'Endo_ReportSection_Furcation',
  EndoReportSectionRootCanalShape = 'Endo_ReportSection_RootCanalShape',
  EndoReportSectionRootCanalSpace = 'Endo_ReportSection_RootCanalSpace',
  EndoReportSectionRootCanalSystem = 'Endo_ReportSection_RootCanalSystem',
}
