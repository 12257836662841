import { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import { Icon, Modal } from '@/shared/ui';

import { ModalID, modalModel } from '@/entities/modal';
import { PERMISSIONS, i18n, PermissionType } from '@/entities/organization';
import {
  permissionDescriptions,
  PermissionStatuses,
} from '@/entities/organization/config/i18n';

import styles from './PermissionsModal.module.scss';

export type PermissionsModalProps = {
  className?: string;
};

export const PermissionsModal: FC<PermissionsModalProps> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { visible } = useAppSelector((state) => state.modal.PermissionsModal);
  const { isMobile } = useMedia();

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.PermissionsModal));
  }, [dispatch]);

  return (
    <Modal
      title={formatMessage({
        id: 'permissionsModal.title',
        defaultMessage: 'Permissions',
      })}
      isOpen={visible}
      onClose={handleClose}
      className={styles.modal}
      bodyClassName={styles.modalBody}
      containerClassName={styles.modalContainer}
      borderless
      hideFooter
    >
      <p className={cn(styles.subTitle, 'p2')}>
        <FormattedMessage
          id="PermissionsModal.subtitle"
          defaultMessage="This table presents the list of roles that you can assign on your staff members and the list of permissions for each of those roles"
        />
      </p>
      <div className={styles.headingRow}>
        <p className={isMobile ? 'p3' : 'p2'}>
          <FormattedMessage
            id="permissionsModal.title"
            defaultMessage="Permissions"
          />
        </p>
        {Object.values(i18n.permissionOwners)
          .filter((_, index, arr) => index !== 0 && index !== arr.length - 1)
          .map((item) => (
            <p key={item.id} className={isMobile ? 'p3' : 'p2'}>
              {formatMessage(item)}
            </p>
          ))}
      </div>
      {Object.values(permissionDescriptions).map((status) => (
        <div key={status.id} className={styles.row}>
          <p className={isMobile ? 'p3' : 'p2'}>{formatMessage(status)}</p>
          {/* TODO:[h/3] should be refactored after creating enum for permissions on the backend */}
          {(Object.keys(PERMISSIONS) as PermissionType[]).map((item) => (
            <p key={item}>
              {PERMISSIONS[item]?.includes(
                status.defaultMessage as PermissionStatuses,
              ) ? (
                <Icon size={24} className={styles.iconPlus} name="plus" />
              ) : (
                <Icon size={24} className={styles.iconMinus} name="minus" />
              )}
            </p>
          ))}
        </div>
      ))}
    </Modal>
  );
};
