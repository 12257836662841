import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Modal } from '@/shared/ui';
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import { NewReportToothChart } from '../NewReportToothChart/NewReportToothChart';
import { NewReportTypes, SliceConfigType, SLICE_CONFIGS } from '../../config';
import { SliceConfig } from '../SliceConfig/SliceConfig';

import styles from './CBCTNewReportFormModal.module.scss';

type CBCTNewREportModalProps = {
  className?: string;
  testID?: string;
  isOpen: boolean;
  onClose: () => void;
  selectedReportType: ReportType;
  onOrderReport: (report: ReportType) => void;
  CBCTPreviewImageSrc: string;
};

export const CBCTNewReportFormModal: FC<CBCTNewREportModalProps> = (props) => {
  const {
    className,
    testID,
    isOpen,
    onClose,
    selectedReportType,
    onOrderReport,
    CBCTPreviewImageSrc,
  } = props;

  const { formatMessage } = useIntl();

  const isThirdMolarStudy =
    selectedReportType === ReportType.ReportType_CBCT_Molar;

  // TODO: [1/l] The script ignores \n during translation compilation. Remove it after connecting to CrowdIn
  const newLine = `\n`;

  return (
    <Modal
      className={cn(styles.container, className)}
      data-testid={testID}
      isOpen={isOpen}
      onClose={onClose}
      title={selectedReportType 
        ? formatMessage(NewReportTypes[selectedReportType], { newLine }) 
        : ''
      }
      bodyClassName={styles.modalBody}
      footer={
        <footer className={cn(styles.footer)}>
          <Button
            type="button"
            variant="secondary"
            size="medium"
            onClick={onClose}
          >
            <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            type="button"
            variant="primary"
            size="medium"
            onClick={() => onOrderReport(selectedReportType)}
          >
            <FormattedMessage
              id="newReport.orderReport"
              defaultMessage="Order report"
            />
          </Button>
        </footer>
      }
    >
      <img
        className={styles.image}
        src={CBCTPreviewImageSrc}
        alt={formatMessage({
          id: 'imgAltText.CBCTPreviewImage',
          defaultMessage: 'CBCT preview',
        })}
      />
      <NewReportToothChart />
      {!isThirdMolarStudy && (
        <div className={styles.sliceConfig}>
          {SLICE_CONFIGS.map((sliceConfig: SliceConfigType) => (
            <SliceConfig sliceConfig={sliceConfig} key={sliceConfig.name} />
          ))}
        </div>
      )}
    </Modal>
  );
};
