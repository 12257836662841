import { FC, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, toastCaller } from '@/shared/ui';
import { toastErrors, ToastErrors } from '@/shared/config';

import styles from './ScanUidCopy.module.scss';

type ScanUidCopyProps = {
  scanUid: string;
  className?: string;
};

export const ScanUidCopy: FC<ScanUidCopyProps> = (props) => {
  const { scanUid, className } = props;

  const { formatMessage } = useIntl();

  const [copied, setCopied] = useState(false);

  const isScanUid = Boolean(scanUid);

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);

      setCopied(true);

      setTimeout(() => setCopied(false), 2000);

      toastCaller({
        message: formatMessage({
          id: 'global.linkCopied',
          defaultMessage: 'Link copied',
        }),
        type: 'default',
        heading: '',
        position: 'bottom-right',
      });
    } catch (error) {
      setCopied(false);

      const parsedMessage = JSON.parse(error?.message);

      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };

  return (
    <div className={cn(className, styles.container)}>
      <div className={cn(styles.scanElemRow, 'p2')}>
        <p className={styles.title}>
          <FormattedMessage id="studyInfo.scanUID" defaultMessage="Scan UID" />
          {`: `}
        </p>

        <p className={styles.scanUid}>
          {isScanUid ? (
            ` ${scanUid}`
          ) : (
            <FormattedMessage
              id="global.notAvailable "
              defaultMessage="not available"
            />
          )}
        </p>

        {isScanUid && (
          <Button
            size="medium"
            variant="tertiary"
            onClick={() => copyToClipBoard(scanUid)}
            className={cn(styles.copyButton, { [styles.copied]: copied })}
            icon={copied ? 'check' : 'copy'}
          >
            {copied ? (
              <FormattedMessage id="global.copied" defaultMessage="Copied" />
            ) : (
              <FormattedMessage id="global.copy" defaultMessage="Copy" />
            )}
          </Button>
        )}
      </div>
    </div>
  );
};
