import {
  SubImageInterface,
  GroupedIOXRayImages,
  FMXMatrixPartition,
} from '../config/types';

const SORT_ORDER: FMXMatrixPartition[] = [
  'UpperLeft',
  'UpperMiddle',
  'UpperRight',
  'MiddleLeft',
  'MiddleMiddle',
  'MiddleRight',
  'LowerLeft',
  'LowerMiddle',
  'LowerRight',
];

export const sortGroupedSubImages = (groupedSubImages: GroupedIOXRayImages) => {
  const sortedSubImages: SubImageInterface[] = [];
  SORT_ORDER.forEach((orderKey) => {
    sortedSubImages.push(...groupedSubImages[orderKey]);
  });

  return sortedSubImages;
};
