import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import {
  ProductState,
  ProductStateStatus,
} from '@/shared/api/protocol_gen/api/billing_new/dto_product_new';

import { ServiceView } from '../ServiceView/ServiceView';
import { subscriptionStatusDefaultMessages } from '../../config/i18n';
import { ProductStatus } from '../../config/types';

import styles from './InventoryItemView.module.scss';

type InventoryItemViewProps = {
  cost: string;
  inventoryItem: ProductState;
  className?: string;
  testID?: string;
};

const statusStyles: Record<ProductStatus, string> = {
  [ProductStateStatus.ProductStateStatusActive]: styles.active,
  [ProductStateStatus.ProductStateStatusLifeTimeIsOver]: styles.expired,
  [ProductStateStatus.ProductStateStatusDeleted]: styles.blocked,
  [ProductStateStatus.ProductStateStatusAwaitingActivation]:
    styles.awaitingActivation,
};

export const InventoryItemView: FC<InventoryItemViewProps> = (props) => {
  const { className, testID, inventoryItem, cost } = props;

  const { formatMessage } = useIntl();

  const isAnnualSubscription =
    Number(inventoryItem?.Model?.Subscription?.LifeTimeMonths) > 1;
  const status = inventoryItem?.Status as ProductStatus;

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <div className={styles.title}>
        <h5 className="p2">
          {inventoryItem?.Model?.Name} ({cost}{' '}
          {isAnnualSubscription
            ? formatMessage({
                id: 'subscription.perYear',
                defaultMessage: 'per year',
              })
            : formatMessage({
                id: 'subscription.perMonth',
                defaultMessage: 'per month',
              })}
          )
        </h5>

        <div className={cn(styles.status, statusStyles[status])}>
          {formatMessage(subscriptionStatusDefaultMessages[status])}
        </div>
      </div>

      <div className={styles.services}>
        {inventoryItem?.Services?.length > 0 &&
          inventoryItem?.Services?.filter((service) => service.Visible)?.map(
            (service) => (
              // TODO: [2/m] Think about key
              <ServiceView key={inventoryItem.ID} service={service} />
            ),
          )}
      </div>
    </div>
  );
};
