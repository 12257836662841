// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MPRViewerModal-module__container--nvg7YB4M{width:80vw}@media screen and (max-width: 420px){.MPRViewerModal-module__container--nvg7YB4M{width:auto}}", "",{"version":3,"sources":["webpack://./src/widgets/MPR/ui/MPRViewerModal/MPRViewerModal.module.scss","webpack://./src/shared/styles/media.scss"],"names":[],"mappings":"AAEA,4CACE,UAAA,CCIA,qCDLF,4CAII,UAAA,CAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.container {\n  width: 80vw;\n\n  @include mobile {\n    width: auto;\n  }\n}\n","$mobile: 420px;\n$tablet: 768px;\n$smallDesktop: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1400px;\n\n@mixin mobile {\n  @media screen and (max-width: $mobile) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media screen and (max-width: $tablet) {\n    @content;\n  }\n}\n\n@mixin smallDesktop {\n  @media screen and (max-width: $smallDesktop) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media screen and (max-width: $desktop) {\n    @content;\n  }\n}\n\n@mixin largeDesktop {\n  @media screen and (max-width: $largeDesktop) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MPRViewerModal-module__container--nvg7YB4M"
};
export default ___CSS_LOADER_EXPORT___;
