import { FC, useCallback, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import { Input, Modal, Select, toastCaller } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { DEFAULT_LOCALE, ToastErrors, toastErrors } from '@/shared/config';
import { ApiError } from '@/shared/api/api';
import { openInNewBrowserTab } from '@/shared/lib';

import { ModalID, modalModel } from '@/entities/modal';
import { billingModel } from '@/entities/billing';
import { userModel } from '@/entities/user';
import { useCountriesOptions } from '@/entities/organization';

import {
  BillingInformationFormPayload,
  billingInformationFormSchema,
} from '../config/formSchema';

import styles from './BillingInformationModal.module.scss';

type BillingInformationModalProps = {
  className?: string;
};

export const BillingInformationModal: FC<BillingInformationModalProps> = (
  props,
) => {
  const { className } = props;

  const { formatMessage } = useIntl();

  const {
    visible,
    data: { isEdit, effectToAdd, invoiceID },
  } = useAppSelector(modalModel.selectors.selectBillingInfoModal);

  const { ID, Country, BillingInformation } = useAppSelector(
    billingModel.selectors.selectAccount,
  );

  const { PersonalData } = useAppSelector(
    userModel.selectors.selectCurrentUser,
  );

  const userSettings = useAppSelector(userModel.selectors.selectSettings);

  const locale = userSettings?.locale ?? DEFAULT_LOCALE;

  const countryOptions = useCountriesOptions(locale);

  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = useForm<BillingInformationFormPayload>({
    defaultValues: {
      firstName: PersonalData?.FirstName,
      lastName: PersonalData?.LastName,
      email: PersonalData?.Emails[0],
      country: Country,
      zipCode: BillingInformation?.Address?.ZIP,
      region: BillingInformation?.Address?.Region,
      address: BillingInformation?.Address?.Address,
      city: BillingInformation?.Address?.City,
      phoneNumber: BillingInformation?.Phone,
      companyName: BillingInformation?.CompanyName,
      taxID: BillingInformation?.TaxID,
    },
    resolver: yupResolver(billingInformationFormSchema),
  });

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.BillingInformationModal));
    reset();
  }, []);

  const onSubmit: SubmitHandler<BillingInformationFormPayload> = async (
    formData,
  ) => {
    const { zipCode, region, address, phoneNumber, city, companyName, taxID } =
      formData;

    const accountAddress = {
      Region: region,
      City: city,
      ZIP: zipCode,
      Address: address,
    };

    try {
      dispatch(billingModel.actions.setLoading('pending'));

      await dispatch(
        billingModel.thunks.setAccountBillingInformation({
          AccountID: ID,
          BillingInformation: {
            Phone: phoneNumber,
            Address: accountAddress,
            CompanyName: companyName ?? '',
            TaxID: taxID ?? '',
          },
        }),
      );

      if (!isEdit && invoiceID) {
        const { Paid, URL } = await dispatch(
          billingModel.thunks.payOrder({
            OrderID: invoiceID,
          }),
        ).unwrap();

        if (!Paid && URL) {
          openInNewBrowserTab(URL);
        }
      }

      if (!isEdit && effectToAdd) {
        await dispatch(
          billingModel.thunks.createOrder({
            AccountID: ID,
            ...effectToAdd,
          }),
        ).unwrap();
      }

      dispatch(billingModel.actions.setLoading('succeeded'));
      dispatch(modalModel.actions.closeModal(ModalID.BillingInformationModal));
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      dispatch(billingModel.actions.setLoading('failed'));

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message:
          parsedMessage?.text ??
          parsedMessage?.reason ??
          parsedMessage?.message,
      });
    }
  };

  useEffect(() => {
    reset({
      firstName: PersonalData?.FirstName,
      lastName: PersonalData?.LastName,
      email: PersonalData?.Emails[0],
      country: Country,
      zipCode: BillingInformation?.Address?.ZIP,
      region: BillingInformation?.Address?.Region,
      address: BillingInformation?.Address?.Address,
      city: BillingInformation?.Address?.City,
      phoneNumber: BillingInformation?.Phone,
      companyName: BillingInformation?.CompanyName,
      taxID: BillingInformation?.TaxID,
    });
  }, [PersonalData, BillingInformation, Country]);

  return (
    <Modal
      borderless
      title={formatMessage({
        id: 'billingInformationModal.title',
        defaultMessage: 'Enter billing information',
      })}
      isOpen={visible}
      onClose={handleClose}
      bodyClassName={className}
      containerClassName={styles.modalContainer}
      applyButtonText={
        isEdit
          ? formatMessage({
              id: 'global.save',
              defaultMessage: 'Save',
            })
          : formatMessage({
              id: 'global.pay',
              defaultMessage: 'Pay',
            })
      }
      cancelButtonText={formatMessage({
        id: 'global.cancel',
        defaultMessage: 'Cancel',
      })}
      onApply={handleSubmit(onSubmit)}
      applyButtonProps={{
        type: 'submit',
        form: 'inviteSignUp',
        loading: isSubmitting,
      }}
      cancelButtonProps={{
        disabled: isSubmitting,
      }}
    >
      <form
        id="billingInformation"
        className={styles.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <h3 className={cn(styles.billingAddressTitle, 'h4')}>
          <FormattedMessage
            id="billingInformationModal.subtitle.billingAddress"
            defaultMessage="Billing address"
          />
        </h3>

        <div className={styles.row}>
          <Controller
            control={control}
            name="firstName"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                required
                disabled
                ref={ref}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                type="text"
                label={formatMessage({
                  id: 'global.firstName',
                  defaultMessage: 'First name',
                })}
                error={error?.message}
                autoComplete="given-name"
              />
            )}
          />

          <Controller
            control={control}
            name="lastName"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                required
                disabled
                ref={ref}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                type="text"
                label={formatMessage({
                  id: 'global.lastName',
                  defaultMessage: 'Last name',
                })}
                error={error?.message}
                autoComplete="family-name"
              />
            )}
          />
        </div>

        <div className={styles.row}>
          <Controller
            control={control}
            name="email"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                required
                disabled
                ref={ref}
                value={value}
                name={name}
                type="email"
                inputMode="email"
                autoComplete="email"
                onBlur={onBlur}
                onChange={onChange}
                label={formatMessage({
                  id: 'global.email',
                  defaultMessage: 'Email',
                })}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="phoneNumber"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                ref={ref}
                required
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                type="tel"
                inputMode="tel"
                autoComplete="tel"
                label={formatMessage({
                  id: 'global.phoneNumber',
                  defaultMessage: 'Phone number',
                })}
                error={error?.message}
              />
            )}
          />
        </div>

        <div className={styles.row}>
          <Controller
            control={control}
            name="companyName"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                ref={ref}
                value={value}
                name={name}
                type="text"
                autoComplete="organization"
                onBlur={onBlur}
                onChange={onChange}
                label={formatMessage({
                  id: 'global.companyName',
                  defaultMessage: 'Company name',
                })}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="taxID"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                ref={ref}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                type="text"
                label={formatMessage({
                  id: 'global.taxID',
                  defaultMessage: 'Tax ID',
                })}
                error={error?.message}
              />
            )}
          />
        </div>

        <div className={styles.row}>
          <Controller
            control={control}
            name="country"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Select
                className={styles.countrySelect}
                label={formatMessage({
                  id: 'global.country',
                  defaultMessage: 'Country',
                })}
                isDisabled={!!value}
                options={countryOptions}
                isRequired
                ref={ref}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="region"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                required
                ref={ref}
                value={value}
                name={name}
                type="text"
                onBlur={onBlur}
                onChange={onChange}
                label={formatMessage({
                  id: 'global.stateRegion',
                  defaultMessage: 'State/Region',
                })}
                error={error?.message}
              />
            )}
          />
        </div>

        <div className={styles.row}>
          <Controller
            control={control}
            name="city"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                required
                ref={ref}
                value={value}
                name={name}
                type="text"
                autoComplete="address-level2"
                onBlur={onBlur}
                onChange={onChange}
                label={formatMessage({
                  id: 'global.city',
                  defaultMessage: 'City',
                })}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="zipCode"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                required
                ref={ref}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                type="text"
                autoComplete="postal-code"
                label={formatMessage({
                  id: 'global.zipCode',
                  defaultMessage: 'ZIP code',
                })}
                error={error?.message}
              />
            )}
          />
        </div>

        <div className={cn(styles.row, styles.address)}>
          <Controller
            control={control}
            name="address"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                required
                ref={ref}
                value={value}
                name={name}
                type="text"
                autoComplete="address-level2"
                onBlur={onBlur}
                onChange={onChange}
                label={formatMessage({
                  id: 'global.companyAddress',
                  defaultMessage: 'Company address',
                })}
                error={error?.message}
              />
            )}
          />
        </div>
      </form>
    </Modal>
  );
};
