import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { generatePath } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useIntl } from 'react-intl/lib';

import {
  LocationStateType,
  PATHS,
  ToastErrors,
  toastErrors,
} from '@/shared/config';
import {
  Breadcrumbs,
  Button,
  Column,
  Layout,
  toastCaller,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { scrollToElementByID } from '@/shared/lib';

import {
  getReportSignStatus,
  ReportSettingsToggle,
  reportsModel,
} from '@/entities/reports';
import { userModel } from '@/entities/user';
import {
  conditionModel,
  getConditionIDsByColor,
  // useLogicalConditions,
  useTransformCondition,
} from '@/entities/condition';
import { usePatientBreadcrumbs } from '@/entities/patient';
// import { logicalConditionModel } from '@/entities/logicalCondition';
// import { toothModel } from '@/entities/tooth';

import { useReport, useReportDataStream } from '@/features/reportStream';

import { Header } from '@/widgets/Header';
import { ReportToothChart } from '@/widgets/ReportToothChart';
import { ToothCard } from '@/widgets/ToothCard';
import { AddConditionModal } from '@/widgets/AddConditionModal';
import { Conclusion } from '@/widgets/Conclusion';
// import { ReferralRecommendations } from '@/widgets/ReferralRecommendations';
import { ReportHeading } from '@/widgets/ReportHeading';
import { ZoomedMedicalImageModal } from '@/widgets/ZoomedMedicalImageModal';
import { NonDentalFindings } from '@/widgets/NonDentalFindings';
import { TeethNumberingModal } from '@/widgets/TeethNumberingModal';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';

import { ProcessingUnitContext } from 'graphics';

import { useGetTeethNumberingModalImage } from './hooks/useGetTeethNumberingModalImage';
import { ConfirmSignReportModal } from './ui/ConfirmSignReportModal/ConfirmSignReportModal';
import { useConfirmSignReportModal } from './hooks/useConfirmSignReportModal';
import { ReportSigns } from './ui/ReportSigns/ReportSigns';
import { AddSignature } from './ui/AddSignature/AddSignature';
import { ReportImage } from './ui/ReportImage/ReportImage';
import styles from './GpReport.module.scss';
// import { GpToothChart } from './ui/GpToothChart/GpToothChart';

type ReportProps = {
  className?: string;
};

export const GpReport: FC<ReportProps> = (props) => {
  const { className } = props;

  const { patientID = '', reportID = '' } = useParams();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const location = useLocation();

  const user = useAppSelector(userModel.selectors.selectCurrentUser);

  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  // const logicalConditionLoading = useAppSelector(
  //   logicalConditionModel.selectors.selectLoading,
  // );

  // const toothLoading = useAppSelector(toothModel.selectors.selectLoading);

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [panoImageLoaded, setPanoImageLoaded] = useState(false);

  const { isMobile } = useMedia();

  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  const scrollToElementID = locationState?.toothID;

  const lastPositionReport = locationState?.lastPositionReport;

  const {
    isConfirmSignReportModalOpen,
    openConfirmSignReportModal,
    closeConfirmSignReportModal,
  } = useConfirmSignReportModal();

  const {
    report,
    isReportSigned,
    isReportLoading,
    ROITeeth,
    isAllTeethApproved,
    handleRemoveToothFromROI,
  } = useReport(reportID, patientID);

  const patientBreadcrumbs = usePatientBreadcrumbs(patientID);

  const canChangeToothNumeration =
    report?.YourPermissions?.CanChangeToothNumeration;

  const {
    panoramicImage,
    panoImageURL,
    panoAssetID,
    CBCTPanoImage,
    viewOptionsGP,
    viewOptionsPano,
    CBCTPanoramaSplitURL,
    CBCTPanoramaURL,
    CBCTPanoramaAssetID,
    modalWidth,
  } = useGetTeethNumberingModalImage(report);

  const conditionsByROI = useAppSelector(
    conditionModel.selectors.selectByROIIDs(ROITeeth),
  );

  const maxFaxConditions = useAppSelector(
    conditionModel.selectors.selectMaxFaxConditionsByReportID(reportID),
  );

  const showLowProbability = report?.Settings?.LowProbabilityMode;

  const conditionsInterfaces = useTransformCondition(
    conditionsByROI,
    false,
    showLowProbability,
  );
  const yellowConditionsIDs = getConditionIDsByColor(
    'yellow',
    conditionsInterfaces,
  );

  const reportSignStatus = getReportSignStatus(report);

  const canAddSign =
    report?.Signature?.UserSignatures.length &&
    !report?.Signature.UserSignatures.find(
      (userSignature) => userSignature.UserID === user.ID,
    ) &&
    report.YourPermissions?.CanSign;

  const handlePrintButton = () => {
    navigate(generatePath(PATHS.previewReport, { patientID, reportID }), {
      state: {
        ...locationState,
        lastPositionReport: window.pageYOffset,
        toothID: undefined,
      },
    });
  };

  const handleSign = async () => {
    setLoading(true);

    try {
      const response = await dispatch(
        reportsModel.thunks.signReport({ ReportID: reportID }),
      ).unwrap();

      dispatch(reportsModel.actions.setNewestOne(response.Report));
    } catch (error) {
      const parsedMessage = JSON.parse(error?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    } finally {
      setLoading(false);
    }
  };

  const getPanoImageContext = (context: ProcessingUnitContext) => {
    setPanoImageLoaded(!!context);
  };

  // const showToothChart = logicalConditionLoading === 'succeeded' && toothLoading === 'succeeded';

  useEffect(() => {
    if (scrollToElementID) {
      scrollToElementByID(scrollToElementID, 'auto');
    }

    if (!scrollToElementID && lastPositionReport) {
      window.scrollTo(0, lastPositionReport);
    }
  }, []);

  useReportDataStream(reportID);

  if (!report) {
    return null;
  }

  const teethNumberingImage =
    report.Type === ReportType.ReportType_Pano_GP
      ? panoramicImage
      : CBCTPanoImage;

  const panoImage =
    report.Type === ReportType.ReportType_CBCT_GP
      ? {
          viewOptions: viewOptionsGP,
          assetID: CBCTPanoramaAssetID ?? '',
          panoramaURL: CBCTPanoramaURL,
          panoramaSplitURL: CBCTPanoramaSplitURL,
        }
      : {
          viewOptions: viewOptionsPano,
          assetID: panoAssetID ?? '',
          panoramaURL: panoImageURL,
        };

  const reportImageLoading = !panoImage.assetID;

  const isSmallHeader = userLocale === 'ru';

  return (
    <>
      <Layout footerWithSettings fullWidth>
        <Layout.Header>
          <Header
            fullWidth
            navigation={
              <Breadcrumbs
                config={[
                  patientBreadcrumbs.patients,
                  patientBreadcrumbs.patientProfile,
                ]}
              />
            }
          />
        </Layout.Header>

        <Layout.Content>
          <Layout.Main>
            <Layout.Content className={cn(styles.container, className)}>
              <WidgetLayout className={styles.columnWrapper}>
                <Column className={styles.column}>
                  <div className={styles.leftFixedColumn}>
                    <ReportHeading
                      report={report}
                      isReportLoading={isReportLoading}
                      smallHeader={isSmallHeader}
                    />
                    <WidgetCard
                      className={styles.panoImageWidget}
                      loading={reportImageLoading}
                    >
                      <ReportImage
                        report={report}
                        panoImage={panoImage}
                        getPanoImageContext={getPanoImageContext}
                      />
                    </WidgetCard>

                    <WidgetCard className={styles.toothChart}>
                      <ReportToothChart
                        image={teethNumberingImage}
                        reportID={reportID}
                        teethNumberingDisabled={
                          !panoImageLoaded && canChangeToothNumeration
                        }
                        showActions
                      />

                      {/* {showToothChart && <GpToothChart />} */}
                    </WidgetCard>
                  </div>
                </Column>

                <Column className={styles.rightColumn}>
                  {/* <ReferralRecommendations reportID={reportID} /> */}

                  {maxFaxConditions.length > 0 && (
                    <NonDentalFindings conditions={maxFaxConditions} />
                  )}

                  {/* TODO: Refactor for cropped tooth images */}
                  {/* {report.Type === ReportType.ReportType_IOXRay_GP && <WidgetCard> */}
                  {/*   <Switch onChange={() => {}}  checked> */}
                  {/*     <FormattedMessage id="report.cropped" defaultMessage="Cropped images" /> */}
                  {/*   </Switch> */}
                  {/* </WidgetCard>} */}

                  {ROITeeth.map((tooth) => (
                    <ToothCard
                      key={tooth.ID}
                      tooth={tooth}
                      patientID={patientID}
                      reportID={reportID}
                      onRemove={handleRemoveToothFromROI}
                    />
                  ))}

                  <WidgetCard>
                    <Conclusion
                      conclusion={report?.LegacyConclusion}
                      reportID={reportID}
                    />

                    {report.Signature?.UserSignatures.length ? (
                      <ReportSigns
                        signedUsers={report.Signature.UserSignatures}
                      />
                    ) : null}

                    {canAddSign ? (
                      <AddSignature
                        user={user}
                        loading={loading}
                        handleSign={handleSign}
                      />
                    ) : null}
                  </WidgetCard>
                </Column>
              </WidgetLayout>
            </Layout.Content>
          </Layout.Main>
        </Layout.Content>

        <Layout.Footer settingsFooter className={styles.footerContainer}>
          <div className={styles.footerWrapper}>
            <ReportSettingsToggle
              reportID={reportID}
              className={styles.settingsToggle}
            />

            <Button
              variant={isReportSigned ? 'primary' : 'tertiary'}
              onClick={handlePrintButton}
              icon={isReportSigned ? 'check' : undefined}
              size={isMobile ? 'medium' : 'large'}
            >
              {isReportSigned ? (
                <FormattedMessage
                  id="report.printReport"
                  defaultMessage="Print report"
                />
              ) : (
                <FormattedMessage
                  id="report.printReportWithoutSignature"
                  defaultMessage="Print report without signature"
                />
              )}
            </Button>

            {!isReportSigned && (
              <Button
                icon="pen"
                disabled={
                  reportSignStatus === 'withoutSign' ||
                  !report.YourPermissions?.CanChangeToothApproved
                }
                onClick={openConfirmSignReportModal}
                size={isMobile ? 'medium' : 'large'}
              >
                {isAllTeethApproved ? (
                  <FormattedMessage id="report.sign" defaultMessage="Sign" />
                ) : (
                  <FormattedMessage
                    id="report.approveAndSign"
                    defaultMessage="Approve all and sign"
                  />
                )}
              </Button>
            )}
          </div>
        </Layout.Footer>
      </Layout>

      <ConfirmSignReportModal
        reportID={reportID}
        isOpen={isConfirmSignReportModalOpen}
        onClose={closeConfirmSignReportModal}
        yellowConditionsIDs={yellowConditionsIDs}
      />

      <TeethNumberingModal
        title={formatMessage({
          id: 'report.EditTeethNumbers',
          defaultMessage: 'Edit teeth numbers',
        })}
        patientID={patientID}
        reportID={reportID}
        report={report}
        modalWidth={modalWidth}
      />

      <AddConditionModal />

      <ZoomedMedicalImageModal />

      <GetFullAccessModal />
    </>
  );
};
