// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StudyInformation-module__modalBody--4oOZjT2l{padding:24px}.StudyInformation-module__content--87pyDE16{display:flex;flex-direction:column;justify-content:center;align-items:flex-start;gap:8px}.StudyInformation-module__footer--k4XU2Mhv{width:100%;display:flex;justify-content:space-between;gap:16px}@media screen and (max-width: 420px){.StudyInformation-module__downloadButton--B\\+KpqyMO{margin-right:6px}\n\n[dir=\"rtl\"] .StudyInformation-module__downloadButton--B\\+KpqyMO{margin-right:0;margin-left:6px}}", "",{"version":3,"sources":["webpack://./src/widgets/StudyInformation/ui/StudyInformation/StudyInformation.module.scss","webpack://./src/shared/styles/media.scss"],"names":[],"mappings":"AAEA,8CACE,YAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,sBAAA,CACA,OAAA,CAGF,2CACE,UAAA,CACA,YAAA,CACA,6BAAA,CACA,QAAA,CCXA,qCDcF,oDAEI,gBAAA;;AAFJ,gEAEI,cAAA,CAAA,eAAA,CAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.modalBody {\n  padding: 24px;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  gap: 8px;\n}\n\n.footer {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  gap: 16px;\n}\n\n.downloadButton {\n  @include mobile {\n    margin-right: 6px;\n  }\n}\n","$mobile: 420px;\n$tablet: 768px;\n$smallDesktop: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1400px;\n\n@mixin mobile {\n  @media screen and (max-width: $mobile) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media screen and (max-width: $tablet) {\n    @content;\n  }\n}\n\n@mixin smallDesktop {\n  @media screen and (max-width: $smallDesktop) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media screen and (max-width: $desktop) {\n    @content;\n  }\n}\n\n@mixin largeDesktop {\n  @media screen and (max-width: $largeDesktop) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "StudyInformation-module__modalBody--4oOZjT2l",
	"content": "StudyInformation-module__content--87pyDE16",
	"footer": "StudyInformation-module__footer--k4XU2Mhv",
	"downloadButton": "StudyInformation-module__downloadButton--B+KpqyMO"
};
export default ___CSS_LOADER_EXPORT___;
