import { FC, useCallback, useState } from 'react';
import cn from 'classnames';

import { Localization } from '@/shared/api/protocol_gen/model/dto_report_localization';
import { AbsoluteBlock } from '@/shared/graphics/RenderComponents/AbsoluteBlock';
import { RCLocalizations } from '@/shared/graphics/RenderComponents/RCLocalizations/RCLocalizations';
import { RCContainer } from '@/shared/graphics/RenderComponents/RCContainer/RCContainer';
import { RCCropImageCrop } from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImage';
import {
  DEFAULT_MEDICAL_IMAGE_HEIGHT,
  MedicalImageInterface,
} from '@/shared/config';
import { useAppDispatch } from '@/shared/hooks';
import { Skeleton } from '@/shared/ui';
import { RCCropImageWithPTools } from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImageWithTools';
import { BBox } from '@/shared/api/protocol_gen/model/dto_common_geometry';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { ModalID, modalModel } from '@/entities/modal';
import { getImageSrc } from '@/entities/assets';
import { protocolLocalizationToBBox } from '@/entities/reports';
import { transformBBoxToCrop } from '@/entities/tooth/lib/transformBBoxToCropp';

import { ContextRequest } from 'graphics';

import styles from './IOXRayToothImage.module.scss';

type FMXImagsProps = {
  className?: string;
  toothLocalization: Localization;
  croppedImage: MedicalImageInterface;
  hoveredCondition: Condition;
};

export const IOXRayToothImageDeprecated: FC<FMXImagsProps> = (props) => {
  const { toothLocalization, className, croppedImage, hoveredCondition } =
    props;

  const [isImageReady, setIsImageReady] = useState(false);

  const dispatch = useAppDispatch();

  const handleOpenModal = useCallback(
    (medicalImage: MedicalImageInterface) => {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.ZoomedMedicalImage,
          data: { medicalImage, medicalImageType: 'cropped' },
        }),
      );
    },
    [dispatch],
  );

  // TODO: Refactor for cropped tooth images
  const { BBox: bbox, TargetAssetID } = toothLocalization;
  const shouldShowLocalizations = false;

  const subImageSrc: ContextRequest = {
    url: getImageSrc(TargetAssetID, 'original'),
    kind: 'raster',
  };

  const crop: RCCropImageCrop = transformBBoxToCrop(bbox as BBox);

  const toothLocalizationBBox = [
    {
      xmin: bbox?.X?.Min ?? 0,
      ymin: bbox?.Y?.Min ?? 0,
      xmax: bbox?.X?.Max ?? 0,
      ymax: bbox?.Y?.Max ?? 0,
    },
  ];

  const hoveredConditionBBox = hoveredCondition?.Localizations?.map(
    protocolLocalizationToBBox,
  );

  const onDicomRendered = () => {
    setIsImageReady(true);
  };

  const componentSize = {
    width: (crop.w / crop.h) * DEFAULT_MEDICAL_IMAGE_HEIGHT,
    height: DEFAULT_MEDICAL_IMAGE_HEIGHT,
  };

  const subImageCrop: RCCropImageCrop = transformBBoxToCrop(bbox as BBox);

  return (
    <div onClick={() => isImageReady && handleOpenModal(croppedImage)}>
      <RCContainer
        style={{ width: componentSize.width, height: componentSize.height }}
        className={cn(className, styles.container)}
      >
        <AbsoluteBlock>
          {!isImageReady && (
            <Skeleton
              width="100%"
              borderRadius="12px"
              height="100%"
              className={styles.panoImageWidgetSkeleton}
            />
          )}

          <RCCropImageWithPTools
            mode="view"
            currentAnnotation="eraser"
            annotations={croppedImage.annotations || []}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            onLoaded={() => {
              onDicomRendered();
            }}
            ww={croppedImage?.viewOptions?.wwwc.ww ?? 0}
            wc={croppedImage?.viewOptions?.wwwc.wc ?? 0}
            sharpness={croppedImage?.viewOptions?.sharpness ?? 0}
            inverted={croppedImage?.viewOptions?.invert ?? false}
            src={subImageSrc}
            crop={subImageCrop}
          />
        </AbsoluteBlock>

        {shouldShowLocalizations && isImageReady && (
          <AbsoluteBlock>
            <RCLocalizations
              detections={toothLocalizationBBox}
              crop={crop}
              imageSize={{
                width: crop.w,
                height: crop.h,
              }}
            />
          </AbsoluteBlock>
        )}

        <AbsoluteBlock style={{ left: 0, top: 0, zIndex: 3 }}>
          <RCLocalizations
            detections={hoveredConditionBBox ?? []}
            imageSize={{
              width: crop.w,
              height: crop.h,
            }}
            crop={subImageCrop}
            isNonDentalFindings
          />
        </AbsoluteBlock>
      </RCContainer>
    </div>
  );
};
