import { SubImageInterface } from '@/features/IOXRayMatrix';

import { isSubImageTeethInROI } from './isSubImageTeethInROI';

export const getDisabledSubImagesIDs = (
  subImages: SubImageInterface[],
  roiTeethNumbers: number[],
) =>
  subImages.reduce((subImagesIDs, subImage) => {
    if (!isSubImageTeethInROI(subImage, roiTeethNumbers)) {
      return [...subImagesIDs, subImage.ID];
    }
    return subImagesIDs;
  }, [] as string[]);
