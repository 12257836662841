import { StartUploadSessionReq } from '@/shared/api/protocol_gen/api/core/svc_upload';
import { AssetType } from '@/shared/api/protocol_gen/model/dto_asset';

export const getStudyRequestParams = ({
  patientID,
  userID,
  organizationID,
  filePaths,
  assetType,
}: {
  filePaths: string[];
  assetType: AssetType;
  patientID?: string;
  userID?: string;
  organizationID?: string;
}): StartUploadSessionReq => {
  switch (assetType) {
    case AssetType.AssetType_Organization_EmployeeStamp: {
      return {
        UserID: userID,
        Organization: {
          OrganizationID: organizationID,
          UserID: userID,
        },
        FilePaths: filePaths,
        Type: AssetType.AssetType_Organization_EmployeeStamp,
      } as unknown as StartUploadSessionReq;
    }
    case AssetType.AssetType_User_Signature: {
      return {
        UserID: userID,
        Type: AssetType.AssetType_User_Signature,
        FilePaths: filePaths,
      } as unknown as StartUploadSessionReq;
    }
    case AssetType.AssetType_User_Avatar: {
      return {
        UserID: userID,
        Type: AssetType.AssetType_User_Avatar,
        FilePaths: filePaths,
      } as unknown as StartUploadSessionReq;
    }
    default:
      return {
        PatientID: patientID,
        Organization: {
          OrganizationID: organizationID,
        },
        FilePaths: filePaths,
        Type: assetType,
      } as unknown as StartUploadSessionReq;
  }
};
