import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Modal } from '@/shared/ui';
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import { CBCTNewReportFormModal } from '../CBCTNewReportFormModal/CBCTNewReportFormModal';
import { NewReportTypes, REPORTS_TYPE_FOR_NEW_REPORT } from '../../config';
import { useCBCTNewReportFormModal } from '../../hooks';

import styles from './CBCTNewReportModal.module.scss';

type AddNewReportModalProps = {
  className?: string;
  testID?: string;
  isOpen: boolean;
  onClose: () => void;
  CBCTPreviewImageSrc: string;
  onRequestReport: () => Promise<void>;
};

export const CBCTNewReportModal: FC<AddNewReportModalProps> = (props) => {
  const {
    className,
    testID,
    isOpen,
    onClose,
    CBCTPreviewImageSrc,
    onRequestReport,
  } = props;

  const { formatMessage } = useIntl();

  const {
    closeCBCTNewReportFromModal,
    openCBCTNewReportFormModal,
    shouldOpenCBCTNewReportForm,
    showCBCTNewReportModal,
    selectedReportType = ReportType.UNRECOGNIZED,
  } = useCBCTNewReportFormModal();

  // TODO: [4, h] Need to add request load/error handling
  const onCBCTNewReportHandle = async () => {
    await onRequestReport();
    closeCBCTNewReportFromModal();
    onClose();
  };

  // TODO: [1/l] The script ignores \n during translation compilation. Remove it after connecting to CrowdIn
  const newLine = `\n`;

  return (
    <>
      <Modal
        className={cn(styles.container, className)}
        data-testid={testID}
        isOpen={isOpen}
        onClose={onClose}
        bodyClassName={styles.modalBody}
        title={formatMessage({
          id: 'newReportModal.title',
          defaultMessage: 'Order new report',
        })}
        hideFooter
      >
        <div className={styles.newReportsWrapper}>
          {REPORTS_TYPE_FOR_NEW_REPORT.map((reportType) => {
            const needOpenNewCBCTReportForm =
              shouldOpenCBCTNewReportForm(reportType);

            return (
              <h4
                className={cn(styles.newReport, 'h4')}
                onClick={() =>
                  needOpenNewCBCTReportForm
                    ? openCBCTNewReportFormModal(reportType)
                    : onCBCTNewReportHandle()
                }
                key={reportType}
              >
                {formatMessage(NewReportTypes[reportType], { newLine })}
              </h4>
            );
          })}
          {/* TODO: add to REPORTS_TYPE_FOR_NEW_REPORT when the type becomes clear */}
          <h4
            className={cn(styles.newReport, styles.radial, 'h4')}
            onClick={() => onClose()}
          >
            <FormattedMessage
              id="newReportModal.3DModel"
              defaultMessage="3D Model"
            />
          </h4>
        </div>
      </Modal>

      <CBCTNewReportFormModal
        isOpen={showCBCTNewReportModal}
        onClose={closeCBCTNewReportFromModal}
        selectedReportType={selectedReportType}
        onOrderReport={onCBCTNewReportHandle}
        CBCTPreviewImageSrc={CBCTPreviewImageSrc}
      />
    </>
  );
};
