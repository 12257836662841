import { FC } from 'react';
import cn from 'classnames';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Checkbox, InputPassword, Layout, Modal } from '@/shared/ui';

import { CustomPasswordError } from '@/widgets/CustomPasswordError';

import {
  finishRegistrationFormSchema,
  FinishRegistrationFormPayload,
} from '../config/formConfig';
import { useFinishRegistration } from '../hooks/useFinishRegistration';

import styles from './FinishRegistration.module.scss';

type FinishRegistrationProps = {
  className?: string;
  testID?: string;
};

export const FinishRegistration: FC<FinishRegistrationProps> = (props) => {
  const { className, testID } = props;

  const { formatMessage } = useIntl();

  const {
    authenticationMethod,
    isInviteFromSalesman,
    confirmPasswordHandle,
    signUpViaSalesmanHandle,
  } = useFinishRegistration();

  const {
    handleSubmit,
    setValue,
    control,
    formState: { isSubmitting, isSubmitted },
  } = useForm<FinishRegistrationFormPayload>({
    resolver: yupResolver(finishRegistrationFormSchema[authenticationMethod]),
    mode: 'all',
  });

  const onSubmit: SubmitHandler<FinishRegistrationFormPayload> = async (
    data,
  ) => {
    const { password } = data;

    if (isInviteFromSalesman) {
      signUpViaSalesmanHandle(password);
    } else {
      confirmPasswordHandle(password);
    }
  };

  return (
    <Layout>
      <Layout.Content>
        <Layout.Main>
          <div className={cn(styles.container, className)} data-testid={testID}>
            <Modal
              containerClassName={styles.container}
              bodyClassName={styles.modalBody}
              title={formatMessage({
                id: 'finishRegistration.title',
                defaultMessage: 'Finish your registration',
              })}
              shouldRenderCloseIconButton={false}
              isOpen
              footer={
                <div className={styles.modalFooter}>
                  <Button
                    className={styles.submitButton}
                    type="submit"
                    form="finishregistrationform"
                    onClick={handleSubmit(onSubmit)}
                    loading={isSubmitting}
                  >
                    <FormattedMessage
                      id="finishRegistration.confirm"
                      defaultMessage="Confirm"
                    />
                  </Button>
                </div>
              }
            >
              <form
                id="finishregistrationform"
                onSubmit={handleSubmit(onSubmit)}
                className={styles.form}
              >
                <Controller
                  control={control}
                  name="password"
                  render={({
                    field: { ref, value, name, onBlur, onChange },
                    fieldState: { error },
                  }) => (
                    <>
                      <InputPassword
                        required
                        ref={ref}
                        value={value}
                        name={name}
                        // inputMode="text"
                        onBlur={onBlur}
                        onChange={onChange}
                        label={formatMessage({
                          id: 'finishRegistration.password',
                          defaultMessage: 'Password',
                        })}
                        autoComplete="new-password"
                      />
                      {error && <CustomPasswordError inputValue={value} />}
                    </>
                  )}
                />

                <Controller
                  control={control}
                  name="confirmPassword"
                  render={({
                    field: { ref, value, name, onBlur, onChange },
                    fieldState: { error, isTouched },
                  }) => (
                    <InputPassword
                      required
                      ref={ref}
                      value={value}
                      name={name}
                      // inputMode="text"
                      onBlur={onBlur}
                      onChange={(event) => {
                        if (!isSubmitted && !isTouched) {
                          setValue(name, event.target.value);
                        } else {
                          onChange(event);
                        }
                      }}
                      label={formatMessage({
                        id: 'finishRegistration.confirmPassword',
                        defaultMessage: 'Confirm password',
                      })}
                      autoComplete="new-password"
                      error={error?.message}
                    />
                  )}
                />

                {!isInviteFromSalesman && (
                  <Controller
                    control={control}
                    name="marketingCommunicationConsent"
                    render={({
                      field: { onChange, value = false, name },
                      fieldState: { error },
                    }) => (
                      <Checkbox
                        error={error?.message}
                        className={styles.checkbox}
                        name={name}
                        onChange={onChange}
                        checked={value}
                      >
                        <FormattedMessage
                          id="finishRegistration.marketingCommunicationConsent"
                          defaultMessage="I agree that DGNCT LLC and its affiliates (hereinafter – Diagnocat) may contact me by e-mail, mobile phone or text messages with information about the products and services of Diagnocat, which may include special offers and feedback requests."
                        />
                      </Checkbox>
                    )}
                  />
                )}
              </form>
            </Modal>
          </div>
        </Layout.Main>
      </Layout.Content>
    </Layout>
  );
};
