import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import cn from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileWithPath, useDropzone } from 'react-dropzone';

import { AssetType } from '@/shared/api/protocol_gen/model/dto_asset';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Modal, Select, Switch } from '@/shared/ui';
import { StudyType } from '@/shared/api/protocol_gen/model/dto_study';
import { ServiceType } from '@/shared/api/protocol_gen/api/billing/dto_services';

import { ModalID, modalModel } from '@/entities/modal';
import { getPatientFullName, patientModel } from '@/entities/patient';
import {
  ASSET_TO_SERVICE_ORDER_REPORT_MAP,
  ASSET_TO_SERVICE_UPLOAD_MAP,
  AssetToServiceOrderReportMap,
  AssetToServiceUploadMap,
  useBillingAccess,
} from '@/entities/billing';

import { useUploadAssetContext } from '@/features/uploadAsset';
import { useNewReport } from '@/features/addNewReport/hooks';

import {
  UPLOAD_STUDY_FORM_ID,
  UploadStudyAssetType,
  UploadStudyPayload,
  uploadStudySchema,
} from '../../config';
import { useSelectStudyOptions } from '../../hooks';
import { uploadStudyHelpText } from '../../config/i18n';
import { CreateReportToggleText } from '../CreateReportToggleText/CreateReportToggleText';
import { UploadStudyDropzone } from '../UploadStudyDropzone/UploadStudyDropzone';
import { UploadStudyFile } from '../UploadStudyFile/UploadStudyFile';
import { isExceedTheSize } from '../../utils/isExceedTheSize';
import { areFilesValid } from '../../utils/areFilesValid';
import { shouldShowCreateReportToggle } from '../../utils/shouldShowCreateReportToggle';

import styles from './UploadStudyModalForm.module.scss';

export const UploadStudyModalForm = () => {
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [uploadFilesError, setUploadFilesError] = useState('');

  const {
    visible,
    data: { patientID },
  } = useAppSelector(modalModel.selectors.selectUploadModal);

  const services = useBillingAccess();

  const { startUploadAsset: startUploadStudy } = useUploadAssetContext();

  const { requestCBCTNewReport, requestPanoOrFMXReport } = useNewReport();

  const studyOptions = useSelectStudyOptions();

  const patient = useAppSelector(
    patientModel.selectors.selectPatientByID(patientID),
  );
  const patientName = getPatientFullName(patient);

  const uploadFolderInputElement = document.querySelector(
    `#uploadFolderInput`,
  ) as HTMLInputElement;

  const {
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<UploadStudyPayload>({
    defaultValues: {
      studyAssetType: AssetType.AssetType_Study_CBCT,
      createReportAfterLoading: true,
    },
    resolver: yupResolver(uploadStudySchema),
  });

  const currentStudyAssetType = watch('studyAssetType');
  const serviceUploadType =
    ASSET_TO_SERVICE_UPLOAD_MAP[
      currentStudyAssetType as keyof AssetToServiceUploadMap
    ];
  const availableReportsCount =
    services[
      ASSET_TO_SERVICE_ORDER_REPORT_MAP[
        currentStudyAssetType as keyof AssetToServiceOrderReportMap
      ]
    ];

  const addFiles = (accepterFiles: FileWithPath[]) => {
    const validatedFiles = accepterFiles.filter((file) =>
      areFilesValid(currentStudyAssetType, [file]),
    );

    setFiles((currentFiles) => [...currentFiles, ...validatedFiles]);
  };

  const removeAllFiles = () => {
    if (uploadFolderInputElement?.value) {
      uploadFolderInputElement.value = '';
    }
    setFiles([]);
  };

  const dropzoneState = useDropzone({
    onDrop: addFiles,
    disabled: !currentStudyAssetType && !services[serviceUploadType],
  });

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.UploadStudy));

    removeAllFiles();
  }, [dispatch]);

  const onSubmit: SubmitHandler<UploadStudyPayload> = async (data) => {
    handleClose();

    const { createReportAfterLoading, studyAssetType } = data;

    const { studyID } = await startUploadStudy({
      patientID,
      files,
      assetType: studyAssetType,
      meta: {
        studyType: studyAssetType,
        patientName,
      },
    });

    if (studyID && createReportAfterLoading) {
      switch (studyAssetType) {
        case AssetType.AssetType_Study_CBCT: {
          requestCBCTNewReport(studyID);
          break;
        }
        case AssetType.AssetType_Study_IntraoralXRay: {
          requestPanoOrFMXReport(studyID, StudyType.StudyType_IntraoralXRay);
          break;
        }
        case AssetType.AssetType_Study_PanoramicXRay: {
          requestPanoOrFMXReport(studyID, StudyType.StudyType_PanoramicXRay);
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const showCreateReportToggle = shouldShowCreateReportToggle(
    currentStudyAssetType,
  );

  useEffect(() => {
    if (
      !files.length ||
      (!isExceedTheSize(files, currentStudyAssetType) &&
        areFilesValid(currentStudyAssetType, files))
    ) {
      setUploadFilesError('');
      return;
    }

    if (isExceedTheSize(files, currentStudyAssetType)) {
      const maxSize =
        currentStudyAssetType === AssetType.AssetType_Study_CBCT
          ? '10GB'
          : '250MB';

      setUploadFilesError(
        formatMessage(
          {
            id: 'uploadStudyModalForm.sizeError',
            defaultMessage: 'File(s) size must be less than {maxSize}',
          },
          { maxSize },
        ),
      );
    }

    if (!areFilesValid(currentStudyAssetType, files)) {
      setUploadFilesError(
        formatMessage({
          id: 'uploadStudyModalForm.wrongFile',
          defaultMessage: 'Wrong File(s) format',
        }),
      );
    }
  }, [files, currentStudyAssetType]);

  const isSegmentronActive =
    services[ServiceType.ServiceType_Order_ImplantStudio_Analysis] ||
    services[ServiceType.ServiceType_Order_OrthoStudio_Analysis] ||
    services[ServiceType.ServiceType_Order_CBCT_IOS_Superimposition_Analysis] ||
    services[ServiceType.ServiceType_Order_IOS_Segmentation_Analysis];

  return (
    <Modal
      containerClassName={styles.modalcontainer}
      bodyClassName={styles.modalBody}
      title={
        <FormattedMessage
          id="uploadStudyModalForm.title"
          defaultMessage="Upload study"
        />
      }
      isOpen={visible}
      onClose={handleClose}
      borderless
      applyButtonText={
        <FormattedMessage
          id="uploadStudyModalForm.applyButtonText"
          defaultMessage="Upload"
        />
      }
      applyButtonProps={{
        type: 'submit',
        form: UPLOAD_STUDY_FORM_ID,
        disabled:
          !services[serviceUploadType] ||
          files.length === 0 ||
          !!uploadFilesError,
      }}
    >
      <form
        id={UPLOAD_STUDY_FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
        className={styles.container}
      >
        <Controller
          control={control}
          name="studyAssetType"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Select
              label={
                <FormattedMessage
                  id="uploadStudyModalForm.studyTypeLabel"
                  defaultMessage="Select study type"
                />
              }
              isSearchable={false}
              options={
                isSegmentronActive
                  ? studyOptions
                  : studyOptions.filter(
                      (studyOption) =>
                        studyOption.value !==
                        AssetType.AssetType_Study_IOS_Meshes,
                    )
              }
              value={value}
              onChange={onChange}
              isRequired
              error={error?.message}
            />
          )}
        />

        {files.length > 0 && (
          <UploadStudyFile
            files={files}
            onDelete={removeAllFiles}
            errorText={uploadFilesError}
          />
        )}

        {currentStudyAssetType && (
          <p className={cn(styles.helpText, 'p3')}>
            {formatMessage(
              uploadStudyHelpText[
                currentStudyAssetType as UploadStudyAssetType
              ],
            )}
          </p>
        )}

        <UploadStudyDropzone
          canUploadFolder={
            currentStudyAssetType !== AssetType.AssetType_Study_PanoramicXRay
          }
          dropzoneState={dropzoneState}
        />

        {showCreateReportToggle && (
          <Controller
            control={control}
            name="createReportAfterLoading"
            render={({ field: { value = false, onChange } }) => (
              <Switch
                className={styles.reportSwitch}
                checked={value}
                disabled={!availableReportsCount || isSubmitting}
                onChange={onChange}
              >
                <CreateReportToggleText
                  studyAssetType={currentStudyAssetType}
                />
                <p className={cn(styles.reportsCounter, 'p3')}>
                  <FormattedMessage
                    id="uploadStudyModalForm.reportsCount"
                    defaultMessage="{ availableReportsCount } reports left"
                    values={{ availableReportsCount }}
                  />
                </p>
              </Switch>
            )}
          />
        )}
      </form>
    </Modal>
  );
};
