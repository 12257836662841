import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Control, Controller } from 'react-hook-form';

import { Select } from '@/shared/ui';

import { useGetCompanyProfileOptions } from '../../hooks/useGetCompanyProfileOptions';
import styles from '../../Steps.module.scss';
import { AdditionalInfoFormPayload } from '../../config/types';

type CompanyProfileStepProps = {
  control: Control<AdditionalInfoFormPayload>;
  className?: string;
  testID?: string;
};

export const CompanyProfileStep: FC<CompanyProfileStepProps> = (props) => {
  const { control, className, testID } = props;

  const companyProfileOptions = useGetCompanyProfileOptions();

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <h2 className={cn(styles.title, 'h2')}>
        <FormattedMessage
          id="additionalInfo.companyProfile"
          defaultMessage="Company profile"
        />
      </h2>

      <Controller
        control={control}
        name="CompanyProfile"
        render={({
          field: { ref, value, name, onBlur, onChange },
          fieldState: { error },
        }) => (
          <Select
            options={companyProfileOptions}
            isRequired
            ref={ref}
            value={value}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />
    </div>
  );
};
