// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Textarea-module__cont--W3ZWQY3\\+{width:100%;border-radius:12px;border:2px solid var(--bg3);padding:12px 16px;box-sizing:border-box}.Textarea-module__cont--W3ZWQY3\\+:focus{outline:none;border:2px solid var(--purpleflat);box-shadow:0 0 0 2px rgb(var(--purpleflat-rgb)/50%)}.Textarea-module__isResizeDisabled--0J1q32lN{resize:none}.Textarea-module__isElastic--eTffHton{overflow:hidden}", "",{"version":3,"sources":["webpack://./src/shared/ui/Textarea/Textarea.module.scss"],"names":[],"mappings":"AAAA,kCACE,UAAA,CACA,kBAAA,CACA,2BAAA,CACA,iBAAA,CACA,qBAAA,CAEA,wCACE,YAAA,CACA,kCAAA,CACA,mDAAA,CAIJ,6CACE,WAAA,CAGF,sCACE,eAAA","sourcesContent":[".cont {\n  width: 100%;\n  border-radius: 12px;\n  border: 2px solid var(--bg3);\n  padding: 12px 16px;\n  box-sizing: border-box;\n\n  &:focus {\n    outline: none;\n    border: 2px solid var(--purpleflat);\n    box-shadow: 0 0 0 2px rgb(var(--purpleflat-rgb) / 50%);\n  }\n}\n\n.isResizeDisabled {\n  resize: none;\n}\n\n.isElastic {\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cont": "Textarea-module__cont--W3ZWQY3+",
	"isResizeDisabled": "Textarea-module__isResizeDisabled--0J1q32lN",
	"isElastic": "Textarea-module__isElastic--eTffHton"
};
export default ___CSS_LOADER_EXPORT___;
