// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PatientDoctrosCell-module__avatarCount--qwcJWZjA{margin-left:0 !important}\n\n[dir=\"rtl\"] .PatientDoctrosCell-module__avatarCount--qwcJWZjA{margin-left:0 !important;margin-right:0 !important}", "",{"version":3,"sources":["webpack://./src/pages/Patients/ui/PatientDoctrosCell/PatientDoctrosCell.module.scss"],"names":[],"mappings":"AAAA,kDACE,wBAAA;;AADF,8DACE,wBAAA,CAAA,yBAAA","sourcesContent":[".avatarCount {\n  margin-left: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarCount": "PatientDoctrosCell-module__avatarCount--qwcJWZjA"
};
export default ___CSS_LOADER_EXPORT___;
