import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileWithPath } from 'react-dropzone';
import { concat, equals } from 'ramda';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import {
  Button,
  Checkbox,
  getStudySelectStyles,
  Modal,
  RadioGroup,
  Select,
  Tabs,
  toastCaller,
  UploadImageInput,
} from '@/shared/ui';
import { ToastErrors, toastErrors } from '@/shared/config';
import { Study } from '@/shared/api/protocol_gen/model/dto_study';
import { ReportCBCTIOSSuperimpositionOrientation } from '@/shared/api/protocol_gen/model/dto_report_type_cbct_ios_superimposition';
import { AssetType } from '@/shared/api/protocol_gen/model/dto_asset';
import optionDefaultImage from '@/shared/assets/images/StudyCardPlaceholder.png';
import { ApiError } from '@/shared/api/api';
import { RequestReportReq } from '@/shared/api/protocol_gen/api/core/svc_report';

import { reportsModel } from '@/entities/reports';
import { ModalID, modalModel } from '@/entities/modal';
import { getImageSrc } from '@/entities/assets';
import { useGetOptionsFromStudies } from '@/entities/study';
import { toothModel } from '@/entities/tooth';
import { userModel } from '@/entities/user';

import { useUploadAssetContext } from '@/features/uploadAsset';

import {
  getSegmentsOfTeeth,
  getSegmentsOfAllCBCTAnatomies,
  getTeethISONumbersWithRudimentTeeth,
} from '../../utils';
import { useGetOutputSTLModelsOptions } from '../../hooks/useGetOutputSTLModelsOptions';
import { useGetSTLModelCustomOptions } from '../../hooks/useGetSTLModelCustomOptions';
import { useGetOrientationOptions } from '../../hooks/useGetOrientationOptions';
import { useGetSegmentronModelTypeOptions } from '../../hooks/useGetSegmentronModelTypeOptions';
import { OUTPUT_STL_PRESETS } from '../../config/constants';
import {
  OutputFilesForSegmentation,
  OutputSTLModels,
  SegmentronModelType,
} from '../../config/i18n';
import {
  order3DSegmentronFormSchema,
  Order3DSegmentronFormSchemaFormPayload,
} from '../../config/formSchema';
import { BasicCBCTToSTL } from '../BasicCBCTToSTL/BasicCBCTToSTL';

import styles from './Order3DSegmentronModal.module.scss';

export type Order3DSegmentronModalProps = {
  className?: string;
  patientID: string;
  cbctStudies: Study[];
  iosStudies: Study[];
};

enum TabValue {
  'FirstStep' = 'FirstStep',
  'SecondStep' = 'SecondStep',
}

export const Order3DSegmentronModal: FC<Order3DSegmentronModalProps> = ({
  cbctStudies,
  iosStudies,
  patientID,
  className,
}) => {
  const dispatch = useAppDispatch();
  const { visible } = useAppSelector(
    (state) => state.modal.Order3DSegmentronModal,
  );

  const [tab, setTab] = useState<keyof typeof TabValue>('FirstStep');
  const [dentalPhoto, setDentalPhoto] = useState<FileWithPath>();
  const [customOutput, setCustomOutput] = useState<number[]>(
    OUTPUT_STL_PRESETS[OutputSTLModels.AllAnatomiesInSeparateFiles],
  );
  const [teethIsoNumbers, setTeethIsoNumbers] = useState<number[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isCustomFieldsShow, setIsCustomFieldsShow] = useState(false);

  const { formatMessage } = useIntl();

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.Order3DSegmentronModal));
  }, [dispatch]);

  const { control, watch, setValue, getValues, handleSubmit } =
    useForm<Order3DSegmentronFormSchemaFormPayload>({
      resolver: yupResolver(order3DSegmentronFormSchema),
    });

  const user = useAppSelector(userModel.selectors.selectCurrentUser);

  const cbctOptions = useGetOptionsFromStudies(cbctStudies);
  const iosOptions = useGetOptionsFromStudies(iosStudies);

  const outputSTLModelsOptions = useGetOutputSTLModelsOptions();
  const stlCustomOptions = useGetSTLModelCustomOptions();
  const orientationOptions = useGetOrientationOptions();
  const segmentronModelTypeOptions = useGetSegmentronModelTypeOptions();

  const { startUploadAsset: startUploadDentalPhoto } = useUploadAssetContext();

  const patientTeethISONumbers = useAppSelector(
    toothModel.selectors.selectISONumbers,
  );

  const handelChangePhoto = async (image: FileWithPath) => {
    setDentalPhoto(image);
  };

  const handleChangeCustomOutput = (option: number) => {
    if (customOutput.includes(option)) {
      setCustomOutput(customOutput.filter((item) => item !== option));
    } else {
      setCustomOutput([...customOutput, option]);
    }
  };

  const handleChangeOutputPreset = (preset: OutputSTLModels) => {
    setCustomOutput(OUTPUT_STL_PRESETS[preset]);
    if (preset === OutputSTLModels.Custom) {
      setIsCustomFieldsShow(true);
    }
  };

  const preset = useMemo(() => {
    const outputPreset = (
      Object.keys(OUTPUT_STL_PRESETS) as OutputSTLModels[]
    ).find((item) => {
      if (OUTPUT_STL_PRESETS[item].length) {
        return equals(OUTPUT_STL_PRESETS[item], customOutput);
      }
      return false;
    });
    return outputPreset || OutputSTLModels.Custom;
  }, [customOutput]);

  const isAdvancedCBCTAndDentalPhoto =
    getValues().segmentronModelType ===
    SegmentronModelType.AdvancedCBCTAndDental;
  const isBasicSBCT =
    getValues().segmentronModelType === SegmentronModelType.BasicCBCTToSTL;

  const cbctImage = useMemo(() => {
    const selectedStudyImagePath = cbctStudies.find(
      (item) => item.ID === watch('cbct'),
    )?.DisplayAssetID;

    if (!selectedStudyImagePath) {
      return optionDefaultImage;
    }

    return getImageSrc(selectedStudyImagePath, 'preview');
  }, [watch('cbct'), cbctStudies]);

  const teethWithRudimentTeeth =
    getTeethISONumbersWithRudimentTeeth(teethIsoNumbers);

  const segmentsOfChosenTeeth = getSegmentsOfTeeth(teethWithRudimentTeeth);
  const segmentsOfPatientTeeth = getSegmentsOfTeeth(patientTeethISONumbers);
  const segmentsOfAllCBCTAnatomies = getSegmentsOfAllCBCTAnatomies();

  const segmentsWithChosenTeethAndAnatomies = concat(
    segmentsOfAllCBCTAnatomies,
    segmentsOfChosenTeeth,
  );
  const segmentsWithPatientTeethAndAnatomies = concat(
    segmentsOfAllCBCTAnatomies,
    segmentsOfPatientTeeth,
  );

  const inputCBCTIOSSuperimpositionRequest = async (
    cbct: string,
    ios: string,
    orientation: ReportCBCTIOSSuperimpositionOrientation,
  ) => {
    await dispatch(
      reportsModel.thunks.requestReport({
        Request: {
          InputCBCTIOSSuperimposition: {
            CBCTStudyID: cbct,
            Segments: segmentsWithPatientTeethAndAnatomies,
            Orientation: orientation,
            STLStudyID: ios,
          },
        },
      } as RequestReportReq),
    );
  };

  const inputCBCTIOSSuperimpositionWithDentalPhoto = async (
    cbct: string,
    ios: string,
    orientation: ReportCBCTIOSSuperimpositionOrientation,
    photo: FileWithPath,
  ) => {
    const { studyID } = await startUploadDentalPhoto(
      {
        files: [photo],
        userID: user?.ID,
        patientID,
        assetType: AssetType.AssetType_Study_DentalPhoto,
        meta: {
          studyType: AssetType.AssetType_Study_DentalPhoto,
          patientName: user?.PersonalData?.FirstName,
        },
      },
      true,
    );

    await dispatch(
      reportsModel.thunks.requestReport({
        Request: {
          InputCBCTIOSSuperimposition: {
            CBCTStudyID: cbct,
            Segments: segmentsWithPatientTeethAndAnatomies,
            DentalPhotoStudyID: studyID,
            Orientation: orientation,
            STLStudyID: ios,
          },
        },
      } as RequestReportReq),
    );
  };

  const inputCBCTSegmentationRequest = async (cbctID: string) => {
    await dispatch(
      reportsModel.thunks.requestReport({
        Request: {
          InputCBCTSegmentation: {
            CBCTStudyID: cbctID,
            Segments: segmentsWithChosenTeethAndAnatomies,
          },
        },
      } as RequestReportReq),
    );
  };

  const onSubmit: SubmitHandler<
    Order3DSegmentronFormSchemaFormPayload
  > = async (data) => {
    setIsSubmitting(true);

    const { cbct, ios = '', orientation = 0 } = data;

    try {
      switch (watch('segmentronModelType')) {
        case SegmentronModelType.BasicCBCTToSTL:
          await inputCBCTSegmentationRequest(cbct);
          break;
        case SegmentronModelType.AdvancedCBCTAndIOS:
          await inputCBCTIOSSuperimpositionRequest(cbct, ios, orientation);
          break;
        default:
          await inputCBCTIOSSuperimpositionWithDentalPhoto(
            cbct,
            ios,
            orientation,
            dentalPhoto as FileWithPath,
          );
      }
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    } finally {
      setIsSubmitting(false);
      handleClose();
    }
  };

  useEffect(() => {
    if (cbctOptions.length) {
      setValue('cbct', cbctOptions[0].value);
      setValue(
        'outputFilesForSegmentation',
        OutputFilesForSegmentation.STLOfTeethAsSeparateFiles,
      );
    }
  }, [setValue, cbctStudies]);

  return (
    <Modal
      title={formatMessage({
        id: 'Order3dSegmentronModal.Order3DModel',
        defaultMessage: 'Order 3D Model',
      })}
      key={tab}
      isOpen={visible}
      onClose={handleClose}
      className={cn(className, styles.modal)}
      bodyClassName={styles.modalBody}
      containerClassName={styles.modalContainer}
      borderless
      footer={
        <footer className={cn(styles.footer)}>
          <div className={styles.buttons}>
            <Button variant="secondary" onClick={handleClose}>
              <FormattedMessage id="global.close" defaultMessage="Close" />
            </Button>
            {tab === TabValue.SecondStep ? (
              <Button
                variant="primary"
                type="submit"
                loading={isSubmitting}
                onClick={handleSubmit(onSubmit)}
                disabled={
                  (!segmentsOfChosenTeeth.length && isBasicSBCT) ||
                  (!watch('ios') && !isBasicSBCT)
                }
              >
                <FormattedMessage id="global.order" defaultMessage="Order" />
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={() => setTab(TabValue.SecondStep)}
              >
                <FormattedMessage id="global.next" defaultMessage="Next" />
              </Button>
            )}
          </div>
        </footer>
      }
    >
      <form id="orderSegmentronModelForm" onSubmit={handleSubmit(onSubmit)}>
        <Tabs.Root
          defaultValue={tab}
          onValueChange={(value) => setTab(value as TabValue)}
        >
          <Tabs.List className={styles.tabList}>
            <Tabs.Trigger className={styles.tabs} value={TabValue.FirstStep}>
              <span>
                <FormattedMessage id="global.one" defaultMessage="1" />
              </span>
            </Tabs.Trigger>

            <Tabs.Trigger className={styles.tabs} value={TabValue.SecondStep}>
              <span>
                <FormattedMessage id="global.two" defaultMessage="2" />
              </span>
            </Tabs.Trigger>

            <Tabs.Content
              value={TabValue.FirstStep}
              className={styles.tabContent}
            >
              <h3 className={cn('h3', styles.title)}>
                <FormattedMessage
                  id="Order3dSegmentronModal.Available 3D models"
                  defaultMessage="Available 3D models"
                />
              </h3>

              <div className={styles.radioButtonWrapper}>
                <Controller
                  control={control}
                  name="segmentronModelType"
                  defaultValue={SegmentronModelType.BasicCBCTToSTL}
                  render={({
                    field: { ref, value, name, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <RadioGroup
                      className={styles.radioGroup}
                      ref={ref}
                      value={value}
                      name={name}
                      variant="default"
                      onBlur={onBlur}
                      onChange={onChange}
                      error={error?.message}
                      items={segmentronModelTypeOptions}
                    />
                  )}
                />
              </div>
            </Tabs.Content>

            <Tabs.Content
              value={TabValue.SecondStep}
              className={styles.tabContent}
            >
              <p className={cn('p2 bold', styles.stepOneTitle)}>
                <FormattedMessage
                  id="orderModel.step1"
                  defaultMessage="Step 1: Select CBCT"
                />
              </p>

              <Controller
                control={control}
                name="cbct"
                render={({
                  field: { ref, value, name, onBlur, onChange },
                  fieldState: { error },
                }) => (
                  <Select
                    styles={getStudySelectStyles()}
                    options={cbctOptions}
                    isRequired
                    ref={ref}
                    value={`${value}`}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={error?.message}
                  />
                )}
              />

              {isBasicSBCT ? (
                <BasicCBCTToSTL
                  className={styles.basicCBCT}
                  cbctImage={cbctImage}
                  control={control}
                  onTeethNumbersChange={(isoNumbers: number[]) =>
                    setTeethIsoNumbers(isoNumbers)
                  }
                />
              ) : (
                <>
                  <p className="p2 bold">
                    <FormattedMessage
                      id="orderModel.step2"
                      defaultMessage="Step 2: Select IOS you want to combine with CBCT study"
                    />
                  </p>

                  <Controller
                    control={control}
                    name="ios"
                    render={({
                      field: { ref, value, name, onBlur, onChange },
                      fieldState: { error },
                    }) => (
                      <Select
                        label={formatMessage({
                          id: 'orderModel.selectStudyWithUpperAndLowerJaws',
                          defaultMessage:
                            'Select study with upper and lower jaws',
                        })}
                        styles={getStudySelectStyles()}
                        options={iosOptions}
                        isRequired
                        ref={ref}
                        value={`${value}`}
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={error?.message}
                      />
                    )}
                  />

                  {isAdvancedCBCTAndDentalPhoto && (
                    <>
                      <p className="p2 bold">
                        <FormattedMessage
                          id="orderModel.step3DentalPhoto"
                          defaultMessage="Step 3: Upload dental photo"
                        />
                      </p>

                      <div className={styles.dragDescription}>
                        <UploadImageInput
                          className={cn(
                            styles.uploadImage,
                            dentalPhoto && styles.hasImage,
                          )}
                          image={dentalPhoto as FileWithPath}
                          onChange={handelChangePhoto}
                          dropDescription={
                            <p className={styles.dragLabel}>
                              <FormattedMessage
                                id="orderModel.UploadDentalPhoto"
                                defaultMessage="Upload dental photo"
                              />
                            </p>
                          }
                        />
                      </div>
                    </>
                  )}

                  <p className="p2 bold">
                    {isAdvancedCBCTAndDentalPhoto ? (
                      <FormattedMessage
                        id="orderModel.step-4"
                        defaultMessage="Step 4: Select orientation"
                      />
                    ) : (
                      <FormattedMessage
                        id="orderModel.step3"
                        defaultMessage="Step 3: Select orientation"
                      />
                    )}
                  </p>

                  <Controller
                    control={control}
                    name="orientation"
                    render={({
                      field: { ref, value, name, onChange },
                      fieldState: { error },
                    }) => (
                      <RadioGroup
                        ref={ref}
                        name={name}
                        value={value}
                        variant="tab"
                        onChange={onChange}
                        items={orientationOptions}
                        error={error?.message}
                      />
                    )}
                  />

                  <p className="p2 bold">
                    {isAdvancedCBCTAndDentalPhoto ? (
                      <FormattedMessage
                        id="orderModel.step-5"
                        defaultMessage="Step 5 : Select output STL models"
                      />
                    ) : (
                      <FormattedMessage
                        id="orderModel.step4"
                        defaultMessage="Step 4 : Select output STL models"
                      />
                    )}
                  </p>
                  <RadioGroup
                    className={styles.orientationRadioGroup}
                    value={preset}
                    onChange={(event) =>
                      handleChangeOutputPreset(
                        event.target.value as OutputSTLModels,
                      )
                    }
                    items={outputSTLModelsOptions}
                  />
                  {isCustomFieldsShow && (
                    <Controller
                      control={control}
                      name="outputSTL"
                      render={({
                        field: { ref, name },
                        fieldState: { error },
                      }) => (
                        <div ref={ref}>
                          {stlCustomOptions.map((option) => (
                            <Checkbox
                              key={option.label}
                              checked={customOutput?.includes(option.value)}
                              onChange={() =>
                                handleChangeCustomOutput(option.value)
                              }
                              name={name}
                              error={error?.message}
                            >
                              {option.label}
                            </Checkbox>
                          ))}
                        </div>
                      )}
                    />
                  )}
                </>
              )}
            </Tabs.Content>
          </Tabs.List>
        </Tabs.Root>
      </form>
    </Modal>
  );
};
