import { capitalize } from 'lodash';
import { pipe, nth, defaultTo, match, split } from 'ramda';

import { SelectOptionType } from '../config';

const extractTextFromBrackets = pipe(match(/\((.*?)\)/), nth(1), defaultTo(''));

export const getLocalizedLanguageName = (
  languageOption: SelectOptionType,
): string => {
  const nonUpperCaseRegex = /[^A-Z]+/g;

  const insideBracketsText = extractTextFromBrackets(languageOption.label);
  const textArray: string[] = split(' ', insideBracketsText);

  const languageKey: string = languageOption.value.replace(
    nonUpperCaseRegex,
    '',
  );

  return textArray.length === 1
    ? capitalize(textArray[0])
    : `${textArray[1]} (${languageKey})`;
};
