import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { FileWithPath } from 'react-dropzone';
import { isEmpty } from 'lodash';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Icon, Input, Modal, toastCaller, UploadImageInput } from '@/shared/ui';
import { PersonalData } from '@/shared/api/protocol_gen/model/dto_base';
import { ToastErrors, toastErrors } from '@/shared/config';
import { AssetType } from '@/shared/api/protocol_gen/model/dto_asset';
import { ApiError } from '@/shared/api/api';

import { modalModel, ModalID } from '@/entities/modal';
import { userModel } from '@/entities/user';
import { assetsModel, getImageSrc } from '@/entities/assets';

import { useUploadAssetContext } from '@/features/uploadAsset';

import { EditInfoFormPayload, editInfoFormSchema } from '../config/formSchema';

import styles from './EditInfoModal.module.scss';

export type EditInfoModalProps = {
  userID: string;
  className?: string;
};

export const EditInfoModal: FC<EditInfoModalProps> = ({
  userID,
  className,
}) => {
  const [photo, setPhoto] = useState<FileWithPath>();
  const [isUserAvatarRemoved, setIsUserAvatarRemoved] = useState(false);

  const dispatch = useAppDispatch();
  const { visible } = useAppSelector((state) => state.modal.EditInfoModal);

  const { formatMessage } = useIntl();

  const user = useAppSelector(userModel.selectors.selectCurrentUser);

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.EditInfoModal));
  }, [dispatch]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<EditInfoFormPayload>({
    resolver: yupResolver(editInfoFormSchema),
  });

  const getEditUserDefaultValues = (userInfo: PersonalData) => ({
    firstName: userInfo.FirstName,
    lastName: userInfo.LastName,
  });

  const handelChangePhoto = (image: FileWithPath) => {
    setPhoto(image);
  };

  const { startUploadAsset: startUploadStudy } = useUploadAssetContext();

  const onSubmit: SubmitHandler<EditInfoFormPayload> = async (data) => {
    const { firstName, lastName } = data;

    try {
      if (!isEmpty(photo)) {
        await startUploadStudy(
          {
            files: [photo],
            userID: user?.ID,
            assetType: AssetType.AssetType_User_Avatar,
            meta: {
              studyType: AssetType.AssetType_User_Avatar,
              patientName: user?.PersonalData?.FirstName,
            },
          },
          true,
        );
      } else if (isUserAvatarRemoved) {
        await dispatch(
          assetsModel.thunks.deleteAsset({ AssetID: user.AvatarAssetID }),
        ).unwrap();
      }

      const { User } = await dispatch(
        userModel.thunks.setUserName({
          UserID: userID,
          FirstName: firstName,
          LastName: lastName,
        }),
      ).unwrap();

      dispatch(userModel.actions.setOne(User));
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    } finally {
      handleClose();
    }
  };

  const userAvatarSrc = getImageSrc(user?.AvatarAssetID, 'thumbnail');

  const handleRemoveUserAvatar = () => {
    if (user.AvatarAssetID) {
      setIsUserAvatarRemoved(true);
    }
  };

  // Set default
  useEffect(() => {
    if (user.PersonalData) {
      reset(getEditUserDefaultValues(user.PersonalData));
    }
  }, [user, reset]);

  const hasImageInUploadInput =
    !isEmpty(photo) || (userAvatarSrc && !isUserAvatarRemoved);

  return (
    <Modal
      title={formatMessage({
        id: 'editInfo.EditPersonalInfo',
        defaultMessage: 'Edit personal info',
      })}
      isOpen={visible}
      onClose={handleClose}
      className={cn(styles.modal, className)}
      bodyClassName={styles.modalBody}
      containerClassName={styles.modalContainer}
      borderless
      applyButtonText={formatMessage({
        id: 'global.save',
        defaultMessage: 'Save',
      })}
      applyButtonProps={{
        type: 'submit',
        form: 'editInfoForm',
        loading: isSubmitting,
      }}
    >
      <form
        id="editInfoForm"
        className={styles.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.dragDescription}>
          <UploadImageInput
            className={cn(
              styles.uploadImage,
              hasImageInUploadInput && styles.hasImage,
            )}
            image={photo as FileWithPath}
            onChange={handelChangePhoto}
            hideImageDescription
            previewImage={userAvatarSrc}
            dropDescription={
              <Icon name="camera" className={styles.purpleText} size={24} />
            }
            onRemove={handleRemoveUserAvatar}
          />
          <div>
            <p className={cn(styles.purpleText, 'p2')}>
              <FormattedMessage
                id="editInfo.AddYourImage"
                defaultMessage="Add your image"
              />
            </p>

            <p className={cn(styles.formatsText, 'p3')}>
              <FormattedMessage
                id="editInfo.imageFormats"
                defaultMessage="max 2 MB, file formats: JPG, PNG, JPEG"
              />
            </p>
          </div>
        </div>

        <Controller
          control={control}
          name="firstName"
          render={({
            field: { ref, value, name, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Input
              required
              ref={ref}
              value={value}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              type="text"
              label={formatMessage({
                id: 'editInfo.firstName',
                defaultMessage: 'First name',
              })}
              error={error?.message}
              autoComplete="given-name"
            />
          )}
        />

        <Controller
          control={control}
          name="lastName"
          render={({
            field: { ref, value, name, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Input
              className={styles.inputLastName}
              required
              ref={ref}
              value={value}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              type="text"
              label={formatMessage({
                id: 'editInfo.lastName',
                defaultMessage: 'Last name',
              })}
              error={error?.message}
              autoComplete="family-name"
            />
          )}
        />
      </form>
    </Modal>
  );
};
