import React, { FC, useCallback } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import imageBroken from '@/shared/assets/images/imagebroken.png';

import { Spinner } from '../Spinner/Spinner';

import styles from './UploadImage.module.scss';

type UploadImageProps = {
  className?: string;
  title?: string | React.ReactNode;
  dropDescription?: string | React.ReactNode;
  onChange: (image: File | FileWithPath) => void;
  previewImage?: string;
  onRemove?: (assetID: string) => void;
  imageAssetID?: string;
  loading?: boolean;
  error?: string;
};

export const UploadImage: FC<UploadImageProps> = (props) => {
  const {
    title,
    dropDescription,
    onChange,
    className,
    previewImage,
    onRemove,
    loading,
    imageAssetID,
    error,
  } = props;

  const { formatMessage } = useIntl();

  const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
    onChange(acceptedFiles[0]);
  }, []);

  const handleRemove = () => {
    onChange({} as File);
    if (onRemove && imageAssetID) {
      onRemove(imageAssetID);
    }
  };

  const { getInputProps, getRootProps, isFocused } = useDropzone({
    onDrop,
  });

  return (
    <div className={styles.container}>
      <p className="p2">{title}</p>

      <div
        className={cn(
          className,
          previewImage && styles.previewWrapper,
          loading && styles.loading,
        )}
      >
        {previewImage ? (
          <div>
            <img
              src={previewImage}
              alt={formatMessage({
                id: 'uploadImage.imagePreview',
                defaultMessage: 'Image Preview',
              })}
            />
            {onRemove && (
              <div className={styles.deleteImage}>
                <button
                  onClick={handleRemove}
                  type="button"
                  className={cn(styles.removeButton, 'p2')}
                >
                  <FormattedMessage
                    id="global.RemoveImage"
                    defaultMessage="Remove image"
                  />
                </button>
              </div>
            )}
          </div>
        ) : (
          <div>
            {loading ? (
              <Spinner size={48} className={styles.spinner} />
            ) : (
              <div
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getRootProps()}
              >
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <input {...getInputProps()} />
                <div
                  className={cn(
                    styles.dragDescription,
                    isFocused && styles.isFocused,
                  )}
                >
                  {error ? (
                    <div className={styles.errorWrapper}>
                      <img
                        className={styles.brokenImage}
                        src={imageBroken}
                        alt=""
                      />
                      <p className="p2">{error}</p>

                      <div className={styles.loadAgain}>
                        <p className="p2">
                          <FormattedMessage
                            id="uploadImage.loadAgain"
                            defaultMessage="Load again"
                          />{' '}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <p className={cn(styles.descriptionText, 'p2')}>
                      {dropDescription}
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
