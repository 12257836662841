import { Patient } from '@/shared/api/protocol_gen/model/dto_patient';
import { PatientPermissions } from '@/shared/api/protocol_gen/model/dto_patient_permissions';

export const getPatientYourPermissions = (
  patient?: Patient,
): PatientPermissions => {
  const permissions = patient?.YourPermissions;

  return {
    CanUploadDentalPhoto: permissions?.CanUploadDentalPhoto ?? false,
    CanViewStudies: permissions?.CanViewStudies ?? false,
    CanUploadPanoramicXRay: permissions?.CanUploadPanoramicXRay ?? false,
    CanUploadIOXRay: permissions?.CanUploadIOXRay ?? false,
    CanViewReports: permissions?.CanViewReports ?? false,
    CanUploadCBCT: permissions?.CanUploadCBCT ?? false,
    CanUploadDocuments: permissions?.CanUploadDocuments ?? false,
    CanUploadSTL: permissions?.CanUploadSTL ?? false,
    CanRequestReport: permissions?.CanRequestReport ?? false,
    CanEditProject: permissions?.CanEditProject ?? false,
    CanViewProject: permissions?.CanViewProject ?? false,
    CanEditPersonalData: permissions?.CanEditPersonalData ?? false,
    CanViewPersonalData: permissions?.CanViewPersonalData ?? false,
    CanView: permissions?.CanView ?? false,
    CanEditPatient: permissions?.CanEditPatient ?? false,
    CanViewTeam: permissions?.CanViewTeam ?? false,
    CanEditTeam: permissions?.CanEditTeam ?? false,
    CanViewSharings: permissions?.CanViewSharings ?? false,
    CanShare: permissions?.CanShare ?? false,
    CanCancelOthersSharings: permissions?.CanCancelOthersSharings ?? false,
  };
};
