import { useAppSelector } from '@/shared/hooks';

import { conditionModel } from '@/entities/condition';
import { toothLandmarksModel } from '@/entities/toothLandmarks';

import { PblConditionWithChildren, PBLLandmark } from '../config';
import { filterPblConditions } from '../lib/filterPblConditions';
import { getPblLandmarksFromConditions } from '../lib/getPblLandmarksFromConditions';

export const useSubImageTeethLandmarks = (teethIDs: string[]) => {
  const teethConditions = useAppSelector(
    conditionModel.selectors.selectByTeethIDs(teethIDs),
  );
  const teethLandmarks = useAppSelector(
    toothLandmarksModel.selectors.selectByTeethIDs(teethIDs),
  );

  const pblConditions = filterPblConditions(teethConditions);

  const pblConditionsWithChildren: PblConditionWithChildren[] =
    pblConditions.map((condition) => {
      const conditionChildren = teethConditions.filter(
        (childCondition) =>
          childCondition.ParentID && childCondition.ParentID === condition.ID,
      );
      return { ...condition, children: conditionChildren };
    });

  const pblLandmarks: PBLLandmark[] = getPblLandmarksFromConditions(
    pblConditionsWithChildren,
    teethLandmarks,
  );

  const getPBLsForSubImage = (subImageTargetAssetID: string) =>
    pblLandmarks.filter(
      (landmark) => landmark.subImageTargetAssetID === subImageTargetAssetID,
    );

  return {
    pblLandmarks,
    getPBLsForSubImage,
  };
};
