// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IOXRayToothImage-module__container--r5FAopcm{overflow:hidden;border-radius:8px}.IOXRayToothImage-module__highlightBorder--hll4zMWD:hover{cursor:pointer;box-shadow:0 0 0 5px var(--purpleflat)}.IOXRayToothImage-module__pointerEventsNone--BEjV1jeG{pointer-events:none}", "",{"version":3,"sources":["webpack://./src/widgets/ToothCard/ui/IOXRayToothImage/IOXRayToothImage.module.scss"],"names":[],"mappings":"AAAA,8CACE,eAAA,CACA,iBAAA,CAIA,0DACE,cAAA,CACA,sCAAA,CAIJ,sDACE,mBAAA","sourcesContent":[".container {\n  overflow: hidden;\n  border-radius: 8px;\n}\n\n.highlightBorder {\n  &:hover {\n    cursor: pointer;\n    box-shadow: 0 0 0 5px var(--purpleflat);\n  }\n}\n\n.pointerEventsNone {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "IOXRayToothImage-module__container--r5FAopcm",
	"highlightBorder": "IOXRayToothImage-module__highlightBorder--hll4zMWD",
	"pointerEventsNone": "IOXRayToothImage-module__pointerEventsNone--BEjV1jeG"
};
export default ___CSS_LOADER_EXPORT___;
