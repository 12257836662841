import { FC, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { MedicalImage } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { CORS_POLICY, MedicalImageInterface } from '@/shared/config';

import { ModalID, modalModel } from '@/entities/modal';

import { MedicalImageLabelsTypes } from '../../config/types';
import { medicalImagesLabels } from '../../config/i18n';
import { getMedicalImageLabel } from '../../lib/getMedicalImageLabel';

type SliceListProps = {
  medicalImages?: MedicalImageInterface[];
  className?: string;
  reportType?: ReportType;
};

export const GPMedicalImageList: FC<SliceListProps> = memo((props) => {
  const { medicalImages, reportType } = props;

  const dispatch = useAppDispatch();

  const handleOpenModal = useCallback(
    (medicalImage: MedicalImageInterface) => {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.ZoomedMedicalImage,
          data: { medicalImage },
        }),
      );
    },
    [dispatch],
  );

  const { formatMessage } = useIntl();

  return medicalImages?.map((medicalImage) => {
    const medicalImageLabel = getMedicalImageLabel(
      medicalImage?.path || '',
    ) as MedicalImageLabelsTypes;
    const label = medicalImagesLabels[medicalImageLabel]
      ? formatMessage(medicalImagesLabels[medicalImageLabel])
      : '';

    if (reportType !== ReportType.ReportType_CBCT_GP) {
      return null;
    }
    // TODO add logic for rendering different images

    return (
      <MedicalImage
        key={medicalImage.src}
        medicalImage={medicalImage}
        onClick={() => handleOpenModal(medicalImage)}
        medicalImageLabel={label}
        annotations={medicalImage.annotations}
        wc={medicalImage?.viewOptions?.wwwc.wc}
        ww={medicalImage?.viewOptions?.wwwc.ww}
        sharpness={medicalImage?.viewOptions?.sharpness}
        inverted={medicalImage?.viewOptions?.invert}
        mask={{ ...medicalImage.mask, credentials: CORS_POLICY }}
      />
    );
  });
});
