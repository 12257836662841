import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SortByFunctionType, SortKeyEnum } from '@/shared/config';

import { PatientsFilters, PatientsFiltersType } from '@/entities/patient';

import {
  AllPatientsFilterSettingsType,
  SharedByMeFilterSettingsType,
  SharedWithMeFilterSettingsType,
  SortByStateType,
} from '../config/types';
import { getSortByOptionsForSharingWith } from '../utils/getSortByOptionsForSharingWith';
import { getSortByOptionsForSharingBy } from '../utils/getSortByOptionsForSharingBy';
import { getSortByOptions } from '../utils/getSortByOptions';

import { usePatientsListStream } from './usePatientsListStream';
import { useSharedWithMeStream } from './useSharedWithMeStream';
import { useSharedByMeStream } from './useSharedByMeStream';

export type UsePatientsSortAndFiltersArgs = {
  organizationID: string;
  isAllPatientList: boolean;
  isSharedWithMeList: boolean;
  isSharedByMeOrSharedByStaffList: boolean;
};

// TODO: [16|h] For some reason patient list stream is not closing on unmount which it leads to infinite potential to duplicate opened streams
// This is very important to fix this issue because it could lead to serious performance issues
export const usePatientsSortAndFilters = ({
  organizationID,
  isAllPatientList,
  isSharedWithMeList,
  isSharedByMeOrSharedByStaffList,
}: UsePatientsSortAndFiltersArgs) => {
  const [searchParams] = useSearchParams();

  const [sortByState, setSortByState] = useState<SortByStateType>({
    sortKey: SortKeyEnum.Status,
    leastOrGreatest: false,
  });

  const searchValue = searchParams.get('search') ?? '';

  const doctorFilters = searchParams.get('doctorIDs')?.split(',');

  const { openPatientListStream, closePatientListStream } =
    usePatientsListStream({ organizationID });

  const { openSharedWithMeStream, closeSharedWithMeStream } =
    useSharedWithMeStream({ organizationID, searchValue });

  const { openSharedByMeStream, closeSharedByMeStream } = useSharedByMeStream({
    organizationID,
    searchValue,
  });

  const fetchMorePatients = (id: string) => {
    if (!organizationID) {
      return;
    }

    if (isAllPatientList) {
      closePatientListStream(false);

      openPatientListStream({
        searchString: searchValue,
        id,
        doctorFilters,
      });
    }

    if (isSharedWithMeList) {
      closeSharedWithMeStream(false);

      openSharedWithMeStream({
        searchString: searchValue,
        id,
        sortBy: getSortByOptionsForSharingWith(
          sortByState?.sortKey,
          sortByState?.leastOrGreatest,
        ),
      });
    }

    if (isSharedByMeOrSharedByStaffList) {
      closeSharedByMeStream(false);

      openSharedByMeStream({
        searchString: searchValue,
        id,
        sortBy: getSortByOptionsForSharingBy(
          sortByState?.sortKey,
          sortByState?.leastOrGreatest,
        ),
      });
    }
  };

  const sortByForAllPatients: SortByFunctionType = (
    sortKey,
    leastOrGreatest,
  ) => {
    if (!organizationID) {
      return;
    }

    const filterSettings: AllPatientsFilterSettingsType = {
      searchString: searchValue,
      id: '',
      doctorFilters,
      sortBy: getSortByOptions(sortKey, leastOrGreatest),
    };

    setSortByState({ sortKey, leastOrGreatest });

    if (isAllPatientList) {
      closePatientListStream(false);

      openPatientListStream(filterSettings);
    }
  };

  const sortByForSharedWithMe: SortByFunctionType = (
    sortKey,
    leastOrGreatest,
  ) => {
    if (!organizationID) {
      return;
    }

    const filterSettings: SharedWithMeFilterSettingsType = {
      searchString: searchValue,
      id: '',
      sortBy: getSortByOptionsForSharingWith(sortKey, leastOrGreatest),
    };

    setSortByState({ sortKey, leastOrGreatest });

    if (isSharedWithMeList) {
      closeSharedWithMeStream();

      openSharedWithMeStream(filterSettings);
    }
  };

  const sortByForSharedByMe: SortByFunctionType = (
    sortKey,
    leastOrGreatest,
  ) => {
    if (!organizationID) {
      return;
    }

    const filterSettings: SharedByMeFilterSettingsType = {
      searchString: searchValue,
      id: '',
      sortBy: getSortByOptionsForSharingBy(sortKey, leastOrGreatest),
    };

    setSortByState({ sortKey, leastOrGreatest });

    if (isSharedByMeOrSharedByStaffList) {
      closeSharedByMeStream();

      openSharedByMeStream(filterSettings);
    }
  };

  const searchPatients = (searchString: string) => {
    if (!organizationID && !isSharedWithMeList) {
      return;
    }

    if (isAllPatientList) {
      closePatientListStream();

      openPatientListStream({
        searchString,
        id: '',
        doctorFilters,
      });
    }

    if (isSharedWithMeList) {
      closeSharedWithMeStream();

      openSharedWithMeStream({
        searchString,
        id: '',
        sortBy: getSortByOptionsForSharingWith(
          sortByState?.sortKey,
          sortByState?.leastOrGreatest,
        ),
      });
    }

    if (isSharedByMeOrSharedByStaffList) {
      closeSharedByMeStream();

      openSharedByMeStream({
        searchString,
        id: '',
        sortBy: getSortByOptionsForSharingBy(
          sortByState?.sortKey,
          sortByState?.leastOrGreatest,
        ),
      });
    }
  };

  const filterByDoctors = (doctorsIDs: string[]) => {
    closePatientListStream();

    openPatientListStream({
      searchString: searchValue,
      doctorFilters: doctorsIDs,
      id: '',
    });
  };

  const onFilterPatientsChange = (patientFilterTab: PatientsFiltersType) => {
    if (!organizationID) {
      return;
    }

    if (patientFilterTab === PatientsFilters.all || patientFilterTab === null) {
      openPatientListStream({
        searchString: searchValue,
        id: '',
        doctorFilters,
      });
    }

    if (patientFilterTab === PatientsFilters.sharedWithMe) {
      closeSharedByMeStream();

      openSharedWithMeStream({
        searchString: searchValue,
        id: '',

        sortBy: getSortByOptionsForSharingWith(
          sortByState?.sortKey,
          sortByState?.leastOrGreatest,
        ),
      });
    }

    if (
      patientFilterTab === PatientsFilters.sharedByStaff ||
      patientFilterTab === PatientsFilters.sharedByMe
    ) {
      closeSharedWithMeStream();

      openSharedByMeStream({
        searchString: searchValue,
        id: '',
        sortBy: getSortByOptionsForSharingBy(
          sortByState?.sortKey,
          sortByState?.leastOrGreatest,
        ),
      });
    }
  };

  const closeStreams = useCallback(() => {
    closePatientListStream();
    closeSharedWithMeStream();
    closeSharedByMeStream();
  }, [closePatientListStream, closeSharedByMeStream, closeSharedWithMeStream]);

  useEffect(() => {
    if (organizationID) {
      if (isAllPatientList) {
        openPatientListStream();
      }

      if (isSharedWithMeList) {
        openPatientListStream();
        openSharedWithMeStream();
      }

      if (isSharedByMeOrSharedByStaffList) {
        openPatientListStream();
        openSharedByMeStream();
      }
    }

    return () => {
      closeStreams();
    };
  }, [organizationID]);

  return {
    sortByForSharedWithMe,
    sortByForSharedByMe,
    searchPatients,
    filterByDoctors,
    sortByForAllPatients,
    onFilterPatientsChange,
    fetchMorePatients,
  };
};
