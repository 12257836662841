// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../shared/assets/images/cursors/cursorPending.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConditionToggle-module__container--8Ij94pnm{display:flex}.ConditionToggle-module__container--8Ij94pnm.ConditionToggle-module__disabled--SgGoseRi{cursor:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),auto}.ConditionToggle-module__text--GpWGCr9E.ConditionToggle-module__colorYellow--4kvtr56L{color:var(--orange)}.ConditionToggle-module__text--GpWGCr9E.ConditionToggle-module__colorRed--ZjDQ-ihb{color:var(--redrose)}.ConditionToggle-module__text--GpWGCr9E.ConditionToggle-module__colorPurple--akTKeqO\\+{color:var(--purpletext1)}.ConditionToggle-module__text--GpWGCr9E.ConditionToggle-module__colorWhite--zKO8LeCU{color:var(--bw)}.ConditionToggle-module__probability--\\+3\\+IF5aS{color:var(--bw)}.ConditionToggle-module__label--LtGmpc2-{display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/entities/condition/ui/ConditionToggle/ConditionToggle.module.scss"],"names":[],"mappings":"AAAA,6CACE,YAAA,CAEA,wFACE,mDAAA,CAKF,sFACE,mBAAA,CAGF,mFACE,oBAAA,CAGF,uFACE,wBAAA,CAGF,qFACE,eAAA,CAIJ,iDACE,eAAA,CAGF,yCACE,YAAA,CACA,OAAA","sourcesContent":[".container {\n  display: flex;\n\n  &.disabled {\n    cursor: url('@/shared/assets/images/cursors/cursorPending.png'), auto;\n  }\n}\n\n.text {\n  &.colorYellow {\n    color: var(--orange);\n  }\n\n  &.colorRed {\n    color: var(--redrose);\n  }\n\n  &.colorPurple {\n    color: var(--purpletext1);\n  }\n\n  &.colorWhite {\n    color: var(--bw);\n  }\n}\n\n.probability {\n  color: var(--bw);\n}\n\n.label {\n  display: flex;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ConditionToggle-module__container--8Ij94pnm",
	"disabled": "ConditionToggle-module__disabled--SgGoseRi",
	"text": "ConditionToggle-module__text--GpWGCr9E",
	"colorYellow": "ConditionToggle-module__colorYellow--4kvtr56L",
	"colorRed": "ConditionToggle-module__colorRed--ZjDQ-ihb",
	"colorPurple": "ConditionToggle-module__colorPurple--akTKeqO+",
	"colorWhite": "ConditionToggle-module__colorWhite--zKO8LeCU",
	"probability": "ConditionToggle-module__probability--+3+IF5aS",
	"label": "ConditionToggle-module__label--LtGmpc2-"
};
export default ___CSS_LOADER_EXPORT___;
