import { defineMessages } from 'react-intl';

import { UserBadRequestError } from '@/shared/config';

export const confirmEmailErrorMessages = defineMessages<string>({
  [UserBadRequestError.ALREADY_REGISTERED]: {
    id: 'confirmEmail.errors.alreadyRegisteredError',
    defaultMessage: 'You have already finished the registration',
  },
  [UserBadRequestError.CODE_RETRIES_LIMIT]: {
    id: 'confirmEmail.errors.retriesLimitError',
    defaultMessage:
      'The limit for retries has been exceeded for your confirmation code. Please refill the Sign Up form.',
  },
  [UserBadRequestError.CODE_IS_EXPIRED]: {
    id: 'confirmEmail.errors.codeExpiredError',
    defaultMessage:
      'The confirmation code has expired. Please refill your Sign Up form.',
  },
  [UserBadRequestError.WRONG_CODE]: {
    id: 'confirmEmail.errors.wrongCodeError',
    defaultMessage: 'The code you have entered is incorrect. Please try again.',
  },
});
