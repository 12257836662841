// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NonDentalFindings-module__condition--93dQN-Qu{cursor:pointer}.NonDentalFindings-module__condition--93dQN-Qu:not(:last-of-type){margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/widgets/NonDentalFindings/ui/NonDentalFindings.module.scss"],"names":[],"mappings":"AAAA,+CACE,cAAA,CAEA,kEACE,iBAAA","sourcesContent":[".condition {\n  cursor: pointer;\n\n  &:not(:last-of-type) {\n    margin-bottom: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"condition": "NonDentalFindings-module__condition--93dQN-Qu"
};
export default ___CSS_LOADER_EXPORT___;
