import { FC } from 'react';

import { Avatar } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';

import { DoctorsAvatars } from '@/entities/doctors';
import { patientModel } from '@/entities/patient';
import { organizationModel } from '@/entities/organization';

import styles from './PatientDoctrosCell.module.scss';

type PatientDoctrosCellProps = {
  patientID: string;
  className?: string;
};

export const PatientDoctrosCell: FC<PatientDoctrosCellProps> = (props) => {
  const { className, patientID } = props;

  const patientDoctorsIDs = useAppSelector(
    patientModel.selectors.selectPatientDoctorsIDs(patientID),
  );
  const patientDoctorsAsUserRole = useAppSelector(
    organizationModel.selectors.selectDoctorsByIDs(patientDoctorsIDs ?? []),
  );

  const isOnlyOneDoctor = patientDoctorsAsUserRole?.length === 1;

  return (
    <div className={className}>
      <Avatar.Group>
        {patientDoctorsAsUserRole
          ?.slice(0, 3)
          .map((patientDoctorAsUserRole) => (
            <DoctorsAvatars
              doctor={patientDoctorAsUserRole}
              isOnlyOneDoctor={isOnlyOneDoctor}
              key={patientDoctorAsUserRole?.UserID}
            />
          ))}
        {patientDoctorsAsUserRole && patientDoctorsAsUserRole?.length > 4 && (
          <Avatar
            className={styles.avatarCount}
            count={
              patientDoctorsAsUserRole && patientDoctorsAsUserRole.length - 4
            }
          />
        )}
      </Avatar.Group>
    </div>
  );
};
