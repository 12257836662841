import { createSelector } from '@reduxjs/toolkit';
import { compose, filter, head, propEq } from 'ramda';
import { generatePath } from 'react-router';

import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import { RootState } from '@/app/model/store';

import {
  filterReportByStudyID,
  filterReportByStudyIDAndType,
  getReportPath,
} from '../lib';
import { getPatientRecentReports } from '../lib/getPatientRecentReports';

import { reportsAdapter } from './reportSlice';

const reportsSelectors = reportsAdapter.getSelectors(
  (state: RootState) => state.reports,
);

export const { selectAll, selectById, selectIds, selectEntities, selectTotal } =
  reportsSelectors;

export const selectByID = (reportID: string) => (state: RootState) =>
  selectById(state, reportID);

export const selectFirst = (state: RootState) =>
  <Report>compose(head, reportsSelectors.selectAll)(state);

export const selectByStudyID = (studyID: string) =>
  createSelector(selectAll, (reports) =>
    filterReportByStudyID(reports, studyID),
  );

export const selectMaskStyle = (state: RootState) =>
  state.reports.maskStyleType;

export const selectShowMasks = (state: RootState) => state.reports.showMasks;

export const selectByStudyIDAndType = (
  studyID: string,
  reportType: ReportType,
) =>
  createSelector(selectAll, (reports) =>
    filterReportByStudyIDAndType(reports, studyID, reportType),
  );

export const selectByPatientID = (patientID: string) =>
  createSelector(selectAll, (reports) =>
    filter<Report>(propEq<keyof Report>('PatientID')(patientID))(reports),
  );

export const selectPatientRecentReports = (
  patientID: string,
  enabledAsRecentTypes: ReportType[],
) =>
  createSelector(selectAll, (reports) =>
    getPatientRecentReports(reports, patientID, enabledAsRecentTypes),
  );

export const selectToothChartHeight = (state: RootState) =>
  state.reports.toothChartHeight;

export const selectNavigationMode = (state: RootState) =>
  state.reports.navigationMode;

export const selectActivePageNumber = (state: RootState) =>
  state.reports.activePageNumber;

export const selectActiveToothID = (state: RootState) =>
  state.reports.activeToothID;

export const selectActiveSubImagesIDs = (state: RootState) =>
  state.reports.activeSubImagesIDs;

export const selectDisabledSubImagesIDs = (state: RootState) =>
  state.reports.disabledSubImagesIDs;

export const selectSubImagesIDs = (state: RootState) =>
  state.reports.subImagesIDs;

export const selectIOXRayReportViewOptions = (reportID: string) =>
  createSelector(
    (state: RootState) => reportsSelectors.selectById(state, reportID),
    (report) => report?.MedicalImageFeatures.ViewOptions,
  );

export const selectToolbarActiveControl = (state: RootState) =>
  state.reports.toolbarActiveControl;

export const selectToolsMode = (state: RootState) => state.reports.toolsMode;

export const selectLastUpdatedCropID = (state: RootState) =>
  state.reports.lastUpdatedCropID;

export const selectShownSubImagesIDs = createSelector(
  [selectSubImagesIDs, selectDisabledSubImagesIDs],
  (subImagesIDs, disabledSubImagesIDs) =>
    subImagesIDs.filter(
      (subImageID) => !disabledSubImagesIDs.includes(subImageID),
    ),
);

export const selectReportBreadcrumbData = ({
  reportID,
  patientID,
}: {
  reportID: string;
  patientID: string;
}) =>
  createSelector(
    (state: RootState) => reportsSelectors.selectById(state, reportID),
    (report) => ({
      reportType: report?.Type,
      reportCreatedDate: report?.Created?.At,
      reportPath: generatePath(
        getReportPath(report?.Type ?? ReportType.UNRECOGNIZED),
        {
          reportID,
          patientID,
        },
      ),
    }),
  );

export const selectCurrentReport = (state: RootState) =>
  state.reports.currentReport;

export const selectCurrentReportID = (state: RootState) =>
  state.reports.currentReport?.ID;

export const selectLoading = (state: RootState) => state.reports.loading;
