import { useEffect, useState } from 'react';
import constate from 'constate';
import { FileWithPath } from 'react-dropzone';
import axios, { AxiosRequestConfig } from 'axios';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';

import { AssetType } from '@/shared/api/protocol_gen/model/dto_asset';
import {
  useAppDispatch,
  useAppSelector,
  useConfirmRefresh,
} from '@/shared/hooks';

import { studyModel } from '@/entities/study';
import { organizationModel } from '@/entities/organization';

import { getStudyRequestParams } from '@/features/uploadAsset/lib/getStudyRequestParams';

import { isInvalidFileError } from '../lib/isInvalidFileError';
import { assetStatusErrorMessages } from '../model';

type UploadStatus = 'idle' | 'uploading' | 'uploaded' | 'cancelled' | 'failed';

export type UploadFile = {
  id: string;
  file: FileWithPath;
  status: UploadStatus;
  progress?: number;
  rate?: number;
  errorMessage?: string;
};

type UploadFileState = Record<string, UploadFile>;

interface RetryConfig extends AxiosRequestConfig {
  retry: number;
  retryDelay: number;
}

const globalConfig: RetryConfig = {
  retry: 3,
  retryDelay: 1000,
};

const uploadAxios = axios.create();

uploadAxios.interceptors.response.use(undefined, (error) => {
  const { config, message } = error;

  if (!config || !config.retry) {
    return Promise.reject(error);
  }
  // retry while Network timeout or Network Error
  if (!(message.includes('timeout') || message.includes('Network Error'))) {
    return Promise.reject(error);
  }

  config.retry -= 1;

  const delayRetryRequest = new Promise<void>((resolve) => {
    setTimeout(() => {
      // eslint-disable-next-line no-console
      console.log('retry the request', config.url);
      resolve();
    }, config.retryDelay || 1000);
  });

  return delayRetryRequest.then(() => uploadAxios(config));
});

export const getFormattedPercent = (part: number, total: number) =>
  Math.floor((part / total) * 100);

export type UploadSessionPullStatus =
  | 'failed'
  | 'uploading'
  | 'success'
  | 'unsuccessful';

export type UploadSessionPull = {
  files: Record<string, UploadFile>;
  patientID: string;
  sessionID: string;
  StudyID?: string;
  status: UploadSessionPullStatus;
  meta?: Record<string, unknown>;
  errorMessage?: string;
  startAt?: number;
};

export type UploadAssetParams = {
  patientID?: string;
  userID?: string;
  files: FileWithPath[];
  assetType: AssetType;
  meta?: Record<string, unknown>;
};

const useUploadAsset = () => {
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [canceledSessions, setCanceledSessions] = useState<string[]>([]);

  const [sessionsAbortControllers, setSessionsAbortControllers] = useState<
    Record<string, AbortController>
  >({});

  const [uploadSessionPullStack, setUploadSessionPullStack] = useState<
    Record<string, UploadSessionPull>
  >({});

  const { formatMessage } = useIntl();

  const handleChangeCanceledSessionID = (canceledSessionID: string) => {
    sessionsAbortControllers[canceledSessionID].abort();
    setCanceledSessions((perv) => [...perv, canceledSessionID]);
  };

  const dispatch = useAppDispatch();

  const { addConfirmRefreshListener, removeConfirmRefreshListener } =
    useConfirmRefresh();

  const currentOrganizationID = useAppSelector(
    organizationModel.selectors.selectCurrentOrganizationID,
  );

  useEffect(() => {
    const someUploadSessionInProgress = Object.values(
      uploadSessionPullStack,
    ).some((pullStack) => pullStack.status === 'uploading');

    if (someUploadSessionInProgress) {
      addConfirmRefreshListener();
    } else {
      removeConfirmRefreshListener();
    }

    return () => {
      removeConfirmRefreshListener();
    };
  }, [
    addConfirmRefreshListener,
    removeConfirmRefreshListener,
    uploadSessionPullStack,
  ]);

  const handleChangeUploadSessionPullStack = (
    updatedPullStack: Record<string, UploadSessionPull>,
  ) => {
    setUploadSessionPullStack(updatedPullStack);
  };

  const startUploadAsset = async (
    { patientID, files, assetType, meta, userID }: UploadAssetParams,
    hideProgress?: boolean,
  ) => {
    if (!hideProgress) {
      setIsUploading(true);
    }

    let sessionID: string = '';

    try {
      const { UploadSession, UploadTargets, Study } = await dispatch(
        studyModel.thunks.startUploadSession({
          ...getStudyRequestParams({
            assetType,
            organizationID: currentOrganizationID,
            filePaths: files.map((file) => file.path ?? ''),
            userID,
            patientID,
          }),
        }),
      ).unwrap();

      sessionID = UploadSession?.ID ?? '';

      const uploadFiles = files.reduce((acc, currentFile) => {
        if (currentFile.path) {
          acc[currentFile.path] = {
            id: currentFile.path,
            file: currentFile,
            status: 'idle',
          };
        }

        return acc;
      }, {} as UploadFileState);

      if (sessionID && patientID) {
        setUploadSessionPullStack((currentPull) => ({
          ...currentPull,
          [sessionID]: {
            sessionID,
            patientID,
            files: uploadFiles,
            status: 'uploading',
            StudyID: UploadSession?.Target?.StudyID,
            meta,
          },
        }));
      }

      const startAt = Date.now();

      setUploadSessionPullStack((prev) => ({
        ...prev,
        [sessionID]: {
          ...prev[sessionID],
          startAt,
        },
      }));

      // upload files one by one
      await Promise.all(
        UploadTargets.map(async (uploadTarget) => {
          const { UploadURL, Path, FileID } = uploadTarget;
          const file = files.find(({ path }) => path === Path);
          const uploadFile = uploadFiles[Path];

          // Each 15 seconds notify about upload progress
          const notifyFileUploadProgress = () => {
            dispatch(
              studyModel.thunks.notifyFileUploadProgress({
                FileID,
              }),
            );
          };

          const debouncedNotifyFileUploadProgress = debounce(
            notifyFileUploadProgress,
            15000,
            { maxWait: 15000 },
          );

          setUploadSessionPullStack((prev) => ({
            ...prev,
            [sessionID]: {
              ...prev[sessionID],
              files: {
                ...(prev[sessionID]?.files ? prev[sessionID].files : {}),
                [Path]: {
                  ...uploadFile,
                  progress: 0,
                  status: 'uploading',
                },
              },
            },
          }));

          if (file === undefined) {
            // TODO: [2|m] no hardcoded messages
            throw new Error('There is no file with such path');
          }

          dispatch(studyModel.thunks.notifyFileUploadStarted({ FileID }));

          const controller = new AbortController();

          setSessionsAbortControllers((prev) => ({
            ...prev,
            [sessionID]: controller,
          }));

          return uploadAxios
            .put(UploadURL, file, {
              ...globalConfig,
              baseURL: process.env.REACT_APP_BASE_URI,
              headers: {
                'Content-Type': 'application/octet-stream',
              },
              signal: controller.signal,
              withCredentials: false,
              onUploadProgress: (event) => {
                debouncedNotifyFileUploadProgress();

                setUploadSessionPullStack((prev) => ({
                  ...prev,
                  [sessionID]: {
                    ...prev[sessionID],
                    files: {
                      ...(prev[sessionID]?.files ? prev[sessionID].files : {}),
                      [Path]: {
                        ...uploadFile,
                        progress: event.progress,
                        status: 'uploading',
                        rate: event.rate,
                        loaded: event.loaded,
                      },
                    },
                  },
                }));
              },
            })
            .then(async (response) => {
              debouncedNotifyFileUploadProgress.cancel();
              // update successfull uploaded file status
              if (response.status === 200) {
                await dispatch(
                  studyModel.thunks.notifyFileUploadFinished({ FileID }),
                ).unwrap();

                setUploadSessionPullStack((prev) => ({
                  ...prev,
                  [sessionID]: {
                    ...prev[sessionID],
                    files: {
                      ...(prev[sessionID]?.files ? prev[sessionID].files : {}),
                      [Path]: {
                        ...uploadFile,
                        progress: 1,
                        status: 'uploaded',
                      },
                    },
                  },
                }));
              }

              return response;
            })
            .catch(async (error) => {
              // update failed uploaded file status
              setUploadSessionPullStack((prev) => ({
                ...prev,
                [sessionID]: {
                  ...prev[sessionID],
                  files: {
                    ...(prev[sessionID]?.files ? prev[sessionID].files : {}),
                    [Path]: {
                      ...uploadFile,
                      status: 'failed',
                      errorMessage: error.message,
                    },
                  },
                },
              }));

              throw { ...error, FileID };
            });
        }),
      );

      // If all files uploaded successfully close session
      await dispatch(
        studyModel.thunks.closeSession({ UploadSessionID: sessionID }),
      ).unwrap();

      // update session success status
      setUploadSessionPullStack((prev) => ({
        ...prev,
        [sessionID]: {
          ...prev[sessionID],
          progress: 1,
          status: 'success',
        },
      }));
      return await Promise.resolve({ studyID: Study?.ID });
    } catch (error) {
      if (sessionID) {
        await dispatch(
          studyModel.thunks.FailSession({
            UploadSessionID: sessionID,
            Error: {
              FileID: error?.FileID,
              Message: error?.message,
            },
            Cancelled: {},
          }),
        );

        const isInvalidError =
          error.type === 'UserBadRequestError' && isInvalidFileError(error);

        // update session failed status
        setUploadSessionPullStack((prev) => ({
          ...prev,
          [sessionID]: {
            ...prev[sessionID],
            errorMessage: isInvalidError
              ? formatMessage(assetStatusErrorMessages.unsuccessful)
              : formatMessage(assetStatusErrorMessages.failed),
            progress: 1,
            status: isInvalidError ? 'unsuccessful' : 'failed',
          },
        }));
      }

      return await Promise.reject(error);
    }
  };

  return {
    uploadSessionPullStack,
    canceledSessions,
    startUploadAsset,
    handleChangeUploadSessionPullStack,
    handleChangeCanceledSessionID,
    isUploading,
  };
};

const [UploadAssetProvider, useUploadAssetContext] = constate(useUploadAsset);

export { UploadAssetProvider, useUploadAssetContext };
