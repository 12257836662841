// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShowAllTreatingDoctorsModal-module__modalBody--QdGlDAol{padding-top:32px}.ShowAllTreatingDoctorsModal-module__content--iOFMZBox{max-height:384px;overflow-y:auto;padding:8px}.ShowAllTreatingDoctorsModal-module__content--iOFMZBox::-webkit-scrollbar{width:5px;border-radius:8px;background-color:var(--grey10)}.ShowAllTreatingDoctorsModal-module__content--iOFMZBox::-webkit-scrollbar-thumb{width:5px;border-radius:8px;background-color:var(--grey9)}", "",{"version":3,"sources":["webpack://./src/features/showAllTreatingDoctors/ui/ShowAllTreatingDoctorsModal/ShowAllTreatingDoctorsModal.module.scss"],"names":[],"mappings":"AAAA,yDACE,gBAAA,CAGF,uDACE,gBAAA,CACA,eAAA,CACA,WAAA,CAEA,0EACE,SAAA,CACA,iBAAA,CACA,8BAAA,CAGF,gFACE,SAAA,CACA,iBAAA,CACA,6BAAA","sourcesContent":[".modalBody {\n  padding-top: 32px;\n}\n\n.content {\n  max-height: 384px;\n  overflow-y: auto;\n  padding: 8px;\n\n  &::-webkit-scrollbar {\n    width: 5px;\n    border-radius: 8px;\n    background-color: var(--grey10);\n  }\n\n  &::-webkit-scrollbar-thumb {\n    width: 5px;\n    border-radius: 8px;\n    background-color: var(--grey9);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "ShowAllTreatingDoctorsModal-module__modalBody--QdGlDAol",
	"content": "ShowAllTreatingDoctorsModal-module__content--iOFMZBox"
};
export default ___CSS_LOADER_EXPORT___;
