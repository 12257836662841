import { useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';
import { useLocation, useNavigate } from 'react-router';

import api from '@/shared/api/api';
import { useAppDispatch } from '@/shared/hooks';
import { PATHS } from '@/shared/config';
import { useHubspotEvents } from '@/shared/hooks/useHubspotEvents';

import { userModel } from '@/entities/user';
import { organizationModel } from '@/entities/organization';

import { useSyncOrganizationID } from './useSyncOrganizationID';

export const useUserDataStream = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { setIdentity, trackPage } = useHubspotEvents();

  const userDataStream = useRef<Subscription>(undefined);

  const openUserDataStream = () => {
    dispatch(organizationModel.actions.setLoading('pending'));
    dispatch(userModel.actions.setLoading('pending'));

    performance.mark('MyDataStream::next::HistoricalMe:start');
    performance.mark('MyDataStream::next::HistoricalOrganizations:start');

    userDataStream.current = api.user.MyDataStream({}).subscribe({
      next: (data) => {
        if (data.HistoricalMe) {
          performance.mark('MyDataStream::next::HistoricalMe:end');
          const HistoricalMeMeasure = performance.measure(
            'MyDataStream::next::HistoricalMe',
            'MyDataStream::next::HistoricalMe:start',
            'MyDataStream::next::HistoricalMe:end',
          );
          // eslint-disable-next-line no-console
          console.log('HistoricalMeMeasure: ', HistoricalMeMeasure);

          // Hubspot events to set identity and track page view
          setIdentity({
            email: data.HistoricalMe.PersonalData.Emails[0],
            options: {
              id: data.HistoricalMe.ID,
            },
          });
          trackPage(window.location.href);

          dispatch(userModel.actions.addUser(data.HistoricalMe));
          dispatch(userModel.actions.setLoading('succeeded'));
        }

        if (data.UpdatedMe) {
          dispatch(userModel.actions.addUser(data.UpdatedMe));
        }

        if (data.HistoricalOrganizations) {
          performance.mark('MyDataStream::next::HistoricalOrganizations:end');
          const HistoricalOrganizationsMeasure = performance.measure(
            'MyDataStream::next::HistoricalOrganizations',
            'MyDataStream::next::HistoricalOrganizations:start',
            'MyDataStream::next::HistoricalOrganizations:end',
          );
          // eslint-disable-next-line no-console
          console.log(
            'HistoricalOrganizationsMeasure: ',
            HistoricalOrganizationsMeasure,
          );

          dispatch(
            organizationModel.actions.setNewestMany(
              data.HistoricalOrganizations.Organizations,
            ),
          );
          // There is no EndOfHistoricalOrganizations in the API
          dispatch(organizationModel.actions.setLoading('succeeded'));
        }

        if (data.UpdatedOrganization) {
          dispatch(
            organizationModel.actions.setNewestOne(data.UpdatedOrganization),
          );
        }
      },
      error: (error) => {
        // eslint-disable-next-line no-console
        console.error(
          'MyDataStream:: error: ',
          error.message,
          error.code,
          error.type,
          error,
        );

        if (error.type === 'UnauthenticatedError') {
          navigate(PATHS.signIn, { state: { from: location?.pathname } });
        }
      },

      complete: () => {
        // Do nothing
      },
    });
  };

  const closeUserDataStream = () => {
    if (userDataStream.current) {
      userDataStream.current.unsubscribe();
    }
  };

  // verify available current organization and sync with localStorage
  useSyncOrganizationID();

  useEffect(() => {
    openUserDataStream();

    return () => {
      closeUserDataStream();
    };
  }, []);
};
