/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { File } from '../model/dto_asset_common';
import {
  ToothNumeration,
  IOSAnatomy,
  CBCTAnatomy,
  iOSAnatomyFromJSON,
  cBCTAnatomyFromJSON,
  iOSAnatomyToJSON,
  cBCTAnatomyToJSON,
} from '../model/dto_report_common';

export const protobufPackage = 'com.diagnocat.model';

export interface AssetContentGeneratedMeshModel {
  /** optional, used only in segmentation */
  SegmentID: string;
  /** and superimposition */
  Mesh: File | undefined;
  CBCTTooth: ToothNumeration | undefined;
  IOSTooth: ToothNumeration | undefined;
  FusionTooth: ToothNumeration | undefined;
  IOSAnatomy: IOSAnatomy | undefined;
  CBCTAnatomy: CBCTAnatomy | undefined;
  CBCTPeriapicalLesion:
    | AssetContentGeneratedMeshModel_CBCTPeriapicalLesion
    | undefined;
  ArtificialCrown: ToothNumeration | undefined;
  /**
   * the following files represent gzipped JSONs with additional face
   * attributes that currently cannot be plugged into draco files
   */
  ToothNumerationWithSurfaceAttributes: File | undefined;
  /** only for fused teeth; contained keys: */
  FusedModalityAttributes: File | undefined;
}

export interface AssetContentGeneratedMeshModel_CBCTPeriapicalLesion {
  Numeration: ToothNumeration | undefined;
  /** there may be several lesions for one tooth */
  LesionIndex: number;
}

function createBaseAssetContentGeneratedMeshModel(): AssetContentGeneratedMeshModel {
  return {
    SegmentID: '',
    Mesh: undefined,
    CBCTTooth: undefined,
    IOSTooth: undefined,
    FusionTooth: undefined,
    IOSAnatomy: undefined,
    CBCTAnatomy: undefined,
    CBCTPeriapicalLesion: undefined,
    ArtificialCrown: undefined,
    ToothNumerationWithSurfaceAttributes: undefined,
    FusedModalityAttributes: undefined,
  };
}

export const AssetContentGeneratedMeshModel = {
  encode(
    message: AssetContentGeneratedMeshModel,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.SegmentID !== '') {
      writer.uint32(10).string(message.SegmentID);
    }
    if (message.Mesh !== undefined) {
      File.encode(message.Mesh, writer.uint32(18).fork()).ldelim();
    }
    if (message.CBCTTooth !== undefined) {
      ToothNumeration.encode(
        message.CBCTTooth,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.IOSTooth !== undefined) {
      ToothNumeration.encode(
        message.IOSTooth,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    if (message.FusionTooth !== undefined) {
      ToothNumeration.encode(
        message.FusionTooth,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    if (message.IOSAnatomy !== undefined) {
      writer.uint32(112).int32(message.IOSAnatomy);
    }
    if (message.CBCTAnatomy !== undefined) {
      writer.uint32(120).int32(message.CBCTAnatomy);
    }
    if (message.CBCTPeriapicalLesion !== undefined) {
      AssetContentGeneratedMeshModel_CBCTPeriapicalLesion.encode(
        message.CBCTPeriapicalLesion,
        writer.uint32(130).fork(),
      ).ldelim();
    }
    if (message.ArtificialCrown !== undefined) {
      ToothNumeration.encode(
        message.ArtificialCrown,
        writer.uint32(138).fork(),
      ).ldelim();
    }
    if (message.ToothNumerationWithSurfaceAttributes !== undefined) {
      File.encode(
        message.ToothNumerationWithSurfaceAttributes,
        writer.uint32(80002).fork(),
      ).ldelim();
    }
    if (message.FusedModalityAttributes !== undefined) {
      File.encode(
        message.FusedModalityAttributes,
        writer.uint32(80010).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedMeshModel {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedMeshModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SegmentID = reader.string();
          break;
        case 2:
          message.Mesh = File.decode(reader, reader.uint32());
          break;
        case 11:
          message.CBCTTooth = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 12:
          message.IOSTooth = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 13:
          message.FusionTooth = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 14:
          message.IOSAnatomy = reader.int32() as any;
          break;
        case 15:
          message.CBCTAnatomy = reader.int32() as any;
          break;
        case 16:
          message.CBCTPeriapicalLesion =
            AssetContentGeneratedMeshModel_CBCTPeriapicalLesion.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 17:
          message.ArtificialCrown = ToothNumeration.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10000:
          message.ToothNumerationWithSurfaceAttributes = File.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10001:
          message.FusedModalityAttributes = File.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedMeshModel {
    return {
      SegmentID: isSet(object.SegmentID) ? String(object.SegmentID) : '',
      Mesh: isSet(object.Mesh) ? File.fromJSON(object.Mesh) : undefined,
      CBCTTooth: isSet(object.CBCTTooth)
        ? ToothNumeration.fromJSON(object.CBCTTooth)
        : undefined,
      IOSTooth: isSet(object.IOSTooth)
        ? ToothNumeration.fromJSON(object.IOSTooth)
        : undefined,
      FusionTooth: isSet(object.FusionTooth)
        ? ToothNumeration.fromJSON(object.FusionTooth)
        : undefined,
      IOSAnatomy: isSet(object.IOSAnatomy)
        ? iOSAnatomyFromJSON(object.IOSAnatomy)
        : undefined,
      CBCTAnatomy: isSet(object.CBCTAnatomy)
        ? cBCTAnatomyFromJSON(object.CBCTAnatomy)
        : undefined,
      CBCTPeriapicalLesion: isSet(object.CBCTPeriapicalLesion)
        ? AssetContentGeneratedMeshModel_CBCTPeriapicalLesion.fromJSON(
            object.CBCTPeriapicalLesion,
          )
        : undefined,
      ArtificialCrown: isSet(object.ArtificialCrown)
        ? ToothNumeration.fromJSON(object.ArtificialCrown)
        : undefined,
      ToothNumerationWithSurfaceAttributes: isSet(
        object.ToothNumerationWithSurfaceAttributes,
      )
        ? File.fromJSON(object.ToothNumerationWithSurfaceAttributes)
        : undefined,
      FusedModalityAttributes: isSet(object.FusedModalityAttributes)
        ? File.fromJSON(object.FusedModalityAttributes)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedMeshModel): unknown {
    const obj: any = {};
    message.SegmentID !== undefined && (obj.SegmentID = message.SegmentID);
    message.Mesh !== undefined &&
      (obj.Mesh = message.Mesh ? File.toJSON(message.Mesh) : undefined);
    message.CBCTTooth !== undefined &&
      (obj.CBCTTooth = message.CBCTTooth
        ? ToothNumeration.toJSON(message.CBCTTooth)
        : undefined);
    message.IOSTooth !== undefined &&
      (obj.IOSTooth = message.IOSTooth
        ? ToothNumeration.toJSON(message.IOSTooth)
        : undefined);
    message.FusionTooth !== undefined &&
      (obj.FusionTooth = message.FusionTooth
        ? ToothNumeration.toJSON(message.FusionTooth)
        : undefined);
    message.IOSAnatomy !== undefined &&
      (obj.IOSAnatomy =
        message.IOSAnatomy !== undefined
          ? iOSAnatomyToJSON(message.IOSAnatomy)
          : undefined);
    message.CBCTAnatomy !== undefined &&
      (obj.CBCTAnatomy =
        message.CBCTAnatomy !== undefined
          ? cBCTAnatomyToJSON(message.CBCTAnatomy)
          : undefined);
    message.CBCTPeriapicalLesion !== undefined &&
      (obj.CBCTPeriapicalLesion = message.CBCTPeriapicalLesion
        ? AssetContentGeneratedMeshModel_CBCTPeriapicalLesion.toJSON(
            message.CBCTPeriapicalLesion,
          )
        : undefined);
    message.ArtificialCrown !== undefined &&
      (obj.ArtificialCrown = message.ArtificialCrown
        ? ToothNumeration.toJSON(message.ArtificialCrown)
        : undefined);
    message.ToothNumerationWithSurfaceAttributes !== undefined &&
      (obj.ToothNumerationWithSurfaceAttributes =
        message.ToothNumerationWithSurfaceAttributes
          ? File.toJSON(message.ToothNumerationWithSurfaceAttributes)
          : undefined);
    message.FusedModalityAttributes !== undefined &&
      (obj.FusedModalityAttributes = message.FusedModalityAttributes
        ? File.toJSON(message.FusedModalityAttributes)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentGeneratedMeshModel>, I>>(
    object: I,
  ): AssetContentGeneratedMeshModel {
    const message = createBaseAssetContentGeneratedMeshModel();
    message.SegmentID = object.SegmentID ?? '';
    message.Mesh =
      object.Mesh !== undefined && object.Mesh !== null
        ? File.fromPartial(object.Mesh)
        : undefined;
    message.CBCTTooth =
      object.CBCTTooth !== undefined && object.CBCTTooth !== null
        ? ToothNumeration.fromPartial(object.CBCTTooth)
        : undefined;
    message.IOSTooth =
      object.IOSTooth !== undefined && object.IOSTooth !== null
        ? ToothNumeration.fromPartial(object.IOSTooth)
        : undefined;
    message.FusionTooth =
      object.FusionTooth !== undefined && object.FusionTooth !== null
        ? ToothNumeration.fromPartial(object.FusionTooth)
        : undefined;
    message.IOSAnatomy = object.IOSAnatomy ?? undefined;
    message.CBCTAnatomy = object.CBCTAnatomy ?? undefined;
    message.CBCTPeriapicalLesion =
      object.CBCTPeriapicalLesion !== undefined &&
      object.CBCTPeriapicalLesion !== null
        ? AssetContentGeneratedMeshModel_CBCTPeriapicalLesion.fromPartial(
            object.CBCTPeriapicalLesion,
          )
        : undefined;
    message.ArtificialCrown =
      object.ArtificialCrown !== undefined && object.ArtificialCrown !== null
        ? ToothNumeration.fromPartial(object.ArtificialCrown)
        : undefined;
    message.ToothNumerationWithSurfaceAttributes =
      object.ToothNumerationWithSurfaceAttributes !== undefined &&
      object.ToothNumerationWithSurfaceAttributes !== null
        ? File.fromPartial(object.ToothNumerationWithSurfaceAttributes)
        : undefined;
    message.FusedModalityAttributes =
      object.FusedModalityAttributes !== undefined &&
      object.FusedModalityAttributes !== null
        ? File.fromPartial(object.FusedModalityAttributes)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedMeshModel_CBCTPeriapicalLesion(): AssetContentGeneratedMeshModel_CBCTPeriapicalLesion {
  return { Numeration: undefined, LesionIndex: 0 };
}

export const AssetContentGeneratedMeshModel_CBCTPeriapicalLesion = {
  encode(
    message: AssetContentGeneratedMeshModel_CBCTPeriapicalLesion,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Numeration !== undefined) {
      ToothNumeration.encode(
        message.Numeration,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.LesionIndex !== 0) {
      writer.uint32(16).uint32(message.LesionIndex);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedMeshModel_CBCTPeriapicalLesion {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseAssetContentGeneratedMeshModel_CBCTPeriapicalLesion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Numeration = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 2:
          message.LesionIndex = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedMeshModel_CBCTPeriapicalLesion {
    return {
      Numeration: isSet(object.Numeration)
        ? ToothNumeration.fromJSON(object.Numeration)
        : undefined,
      LesionIndex: isSet(object.LesionIndex) ? Number(object.LesionIndex) : 0,
    };
  },

  toJSON(
    message: AssetContentGeneratedMeshModel_CBCTPeriapicalLesion,
  ): unknown {
    const obj: any = {};
    message.Numeration !== undefined &&
      (obj.Numeration = message.Numeration
        ? ToothNumeration.toJSON(message.Numeration)
        : undefined);
    message.LesionIndex !== undefined &&
      (obj.LesionIndex = Math.round(message.LesionIndex));
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedMeshModel_CBCTPeriapicalLesion>,
      I
    >,
  >(object: I): AssetContentGeneratedMeshModel_CBCTPeriapicalLesion {
    const message =
      createBaseAssetContentGeneratedMeshModel_CBCTPeriapicalLesion();
    message.Numeration =
      object.Numeration !== undefined && object.Numeration !== null
        ? ToothNumeration.fromPartial(object.Numeration)
        : undefined;
    message.LesionIndex = object.LesionIndex ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
