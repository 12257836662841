import { useCallback, useEffect, useMemo } from 'react';

import { userModel } from '@/entities/user';

import api, { ApiError } from '../api/api';

import { useAppSelector } from './store';

type HubSpotChatFunctions = {
  openHubspotChat: () => void;
  closeHubspotChat: () => void;
};

export const useHubspotChat = (): HubSpotChatFunctions => {
  const user = useAppSelector(userModel.selectors.selectCurrentUser);

  const email = useMemo(() => user.PersonalData?.Emails[0], [user]);

  const onConversationsAPIReady = useCallback(async () => {
    window.HubSpotConversations.clear({ resetWidget: true });

    if (email) {
      try {
        const { Token } = await api.hubspot.GetChatIdentificationToken({});

        window.hsConversationsSettings = {
          loadImmediately: false,
          identificationEmail: email,
          identificationToken: Token,
        };
      } catch (error) {
        const parsedMessage = JSON.parse((error as ApiError)?.message);

        // eslint-disable-next-line no-console
        console.error(parsedMessage);
      }
    } else {
      window.hsConversationsSettings = {
        loadImmediately: false,
      };
    }

    setTimeout(() => {
      window.HubSpotConversations.widget.load();
    }, 1000);
  }, [email]);

  const openHubspotChat = () => {
    window.HubSpotConversations.widget.open();
  };

  const closeHubspotChat = () => {
    window.HubSpotConversations.widget.close();
  };

  useEffect(() => {
    if (!window.hsConversationsSettings) {
      window.hsConversationsSettings = {
        loadImmediately: false,
      };
    }

    if (window.HubSpotConversations) {
      onConversationsAPIReady();
    } else {
      // eslint-disable-next-line no-console
      console.error(
        'hubspot api connection is not working, please reload the page',
      );
    }
  }, [onConversationsAPIReady]);

  return {
    openHubspotChat,
    closeHubspotChat,
  };
};
