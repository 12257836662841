import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';

import {
  billingModel,
  BillingZones,
  getLinksAgreementsByBillingZone,
} from '@/entities/billing';

import styles from './Footer.module.scss';

type FooterProps = {
  fullWidth?: boolean;
  fixed?: boolean;
  className?: string;
  testID?: string;
};

export const Footer: FC<FooterProps> = (props) => {
  const { fullWidth = false, fixed = false, className, testID } = props;
  const { billingZone: billingZoneString, country } = useAppSelector(
    billingModel.selectors.selectBillingZoneAndCountry,
  );

  const {
    termsAndConditions,
    dataProcessingAddendum,
    privacyPolicy,
    agreementOffer,
    personalDataProcessing,
  } = getLinksAgreementsByBillingZone({
    billingZone: BillingZones[billingZoneString as BillingZones],
    country,
  });

  const currentYear = new Date().getFullYear();

  return (
    <div
      className={cn(
        styles.container,
        fixed && styles.fixed,
        fullWidth && styles.fullWidth,
        className,
        'p2',
      )}
      data-testid={testID}
    >
      <span>&copy; Diagnocat {currentYear}</span>

      {agreementOffer && (
        <a
          href={agreementOffer}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          <FormattedMessage
            id="footer.licenseAgreementOffer"
            defaultMessage="License agreement-offer"
          />
        </a>
      )}

      {personalDataProcessing && (
        <a
          href={personalDataProcessing}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          <FormattedMessage
            id="footer.termsOfPersonalDataProcessing"
            defaultMessage="Terms of personal data processing"
          />
        </a>
      )}

      {termsAndConditions && (
        <a
          href={termsAndConditions}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          <FormattedMessage
            id="footer.termsAndConditions"
            defaultMessage="Terms and conditions"
          />
        </a>
      )}

      {dataProcessingAddendum && (
        <a
          href={dataProcessingAddendum}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          <FormattedMessage
            id="footer.dataProcessingAddendum"
            defaultMessage="Data Processing Addendum"
          />
        </a>
      )}

      {privacyPolicy && (
        <a
          href={privacyPolicy}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          <FormattedMessage
            id="footer.thePrivacyPolicy"
            defaultMessage="The Privacy Policy"
          />
        </a>
      )}
    </div>
  );
};
