import { FC, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { MPRBase } from '@/shared/graphics/MPRBase/MPRBase';
import { Switch } from '@/shared/ui';

import { MPR, MPRInputMode } from 'graphics';

import { MPRControlTools } from '../MPRControlTools/MPRControlTools';

import styles from './MPRWidget.module.scss';

type MPRWidgetProps = {
  className?: string;
  mprUrl: string;
  initialWw?: number;
  initialWc?: number;
};

const cursorStyle = {
  normal: styles.move,
  brightness: styles.brightness,
  pan: styles.pan,
  zoom: styles.zoom,
  depth: styles.brightness,
  default: styles.noScroll,
};

export const MPRWidget: FC<MPRWidgetProps> = (props) => {
  const { className, mprUrl, initialWw, initialWc } = props;
  const [mpr, setMpr] = useState<MPR>();
  const [inputMode, setInputMode] = useState<MPRInputMode | 'default'>(
    'default',
  );
  const [zoomSynced, setZoomSynced] = useState<boolean>(true);
  const [axesVisible, setAxesVisible] = useState<boolean>(true);

  useEffect(() => {
    if (mpr) {
      mpr.inputMode = inputMode as MPRInputMode;
      mpr.axesVisible = axesVisible;
      mpr.zoomSynced = zoomSynced;
    }
  }, [mpr, inputMode, zoomSynced, axesVisible]);

  const handleControlClick = useCallback(
    (control: MPRInputMode | 'reset') => {
      if (!mpr) return;

      if (control === 'reset') {
        mpr.reset();
        mpr.ww = mpr.defaultWW;
        mpr.wc = mpr.defaultWC;
      } else {
        setInputMode(control);
      }
    },
    [mpr],
  );

  return (
    <div className={className}>
      <div className={styles.controls}>
        <MPRControlTools
          activeTool={inputMode}
          setActiveTool={handleControlClick}
        />
        <div className={styles.extendedControls}>
          {inputMode === 'zoom' && (
            <Switch
              onChange={() => setZoomSynced(!zoomSynced)}
              checked={zoomSynced}
            >
              <FormattedMessage
                id="mpr.tools.zoomSynced"
                defaultMessage="Zoom synced"
              />
            </Switch>
          )}
          <Switch
            onChange={() => setAxesVisible(!axesVisible)}
            checked={axesVisible}
          >
            <FormattedMessage
              id="mpr.tools.axesVisible"
              defaultMessage="Axis"
            />
          </Switch>
        </div>
      </div>

      <div className={cn(styles.mprViewer, cursorStyle[inputMode])}>
        <MPRBase
          nrrdUrl={mprUrl}
          initialWw={initialWw}
          initialWc={initialWc}
          onMPRReady={(mprInstance) => setMpr(mprInstance)}
        />
      </div>
    </div>
  );
};
