import { InferType, object, string } from '@/shared/config/yup';

export const orderImplantOrOrthoModelFormSchema = object({
  cbct: string().required(),
  ios: string().required(),
});

export type OrderImplantOrOrthoModelFormPayload = InferType<
  typeof orderImplantOrOrthoModelFormSchema
>;
