import {
  StartUploadSessionReq,
  StartUploadSessionResp,
  CloseSessionReq,
  CloseSessionResp,
  NotifyFileUploadStartedReq,
  NotifyFileUploadStartedResp,
  NotifyFileUploadProgressReq,
  NotifyFileUploadProgressResp,
  NotifyFileUploadFinishedReq,
  NotifyFileUploadFinishedResp,
  FailSessionReq,
  FailSessionResp,
  StorageServiceClientImpl,
} from '@/shared/api/protocol_gen/api/core/svc_upload';
import api from '@/shared/api/api';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import {
  DeleteStudyReq,
  DeleteStudyResp,
  StudyServiceClientImpl,
} from '@/shared/api/protocol_gen/api/core/svc_study';

export type StorageRequestsType = keyof StorageServiceClientImpl;

type StudyRequestsType = keyof StudyServiceClientImpl;

const generateStorageThunk = createThunkGenerator<StorageRequestsType>(
  SliceName.storage,
);

const generateStudyThunk = createThunkGenerator<StudyRequestsType>(
  SliceName.study,
);

export const deleteStudy = generateStudyThunk<DeleteStudyReq, DeleteStudyResp>(
  'DeleteStudy',
  api.study.DeleteStudy,
);

export const startUploadSession = generateStorageThunk<
  StartUploadSessionReq,
  StartUploadSessionResp
>('StartUploadSession', api.storage.StartUploadSession);

export const closeSession = generateStorageThunk<
  CloseSessionReq,
  CloseSessionResp
>('CloseSession', api.storage.CloseSession);

export const notifyFileUploadStarted = generateStorageThunk<
  NotifyFileUploadStartedReq,
  NotifyFileUploadStartedResp
>('NotifyFileUploadStarted', api.storage.NotifyFileUploadStarted);

export const notifyFileUploadProgress = generateStorageThunk<
  NotifyFileUploadProgressReq,
  NotifyFileUploadProgressResp
>('NotifyFileUploadProgress', api.storage.NotifyFileUploadProgress);

export const notifyFileUploadFinished = generateStorageThunk<
  NotifyFileUploadFinishedReq,
  NotifyFileUploadFinishedResp
>('NotifyFileUploadFinished', api.storage.NotifyFileUploadFinished);

export const FailSession = generateStorageThunk<
  FailSessionReq,
  FailSessionResp
>('FailSession', api.storage.FailSession);
