// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScanUidCopy-module__container--fBxo5rl3{width:100%;display:flex;align-items:center;gap:12px}.ScanUidCopy-module__button--u96H20CE{display:flex;align-items:center}.ScanUidCopy-module__scanElemRow--etRj1Ibj{width:100%;display:flex;justify-content:flex-start;align-items:center}.ScanUidCopy-module__title--rObXDo34{min-width:204px;color:var(--greytext)}.ScanUidCopy-module__scanUid--eyfq5wbI{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:var(--bw)}\n\n.ScanUidCopy-module__copyButton--iH5bp3rB{margin-left:auto}\n\n[dir=\"rtl\"] .ScanUidCopy-module__copyButton--iH5bp3rB{margin-left:0;margin-right:auto}.ScanUidCopy-module__copied--KrSsXCLR{cursor:auto}", "",{"version":3,"sources":["webpack://./src/features/scanUidCopy/ui/ScanUidCopy/ScanUidCopy.module.scss"],"names":[],"mappings":"AAAA,yCACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,sCACE,YAAA,CACA,kBAAA,CAGF,2CACE,UAAA,CACA,YAAA,CACA,0BAAA,CACA,kBAAA,CAGF,qCACE,eAAA,CACA,qBAAA,CAGF,uCACE,kBAAA,CACA,eAAA,CACA,sBAAA,CACA,eAAA;;AAGF,0CACE,gBAAA;;AADF,sDACE,aAAA,CAAA,iBAAA,CAGF,sCACE,WAAA","sourcesContent":[".container {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 12px;\n}\n\n.button {\n  display: flex;\n  align-items: center;\n}\n\n.scanElemRow {\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.title {\n  min-width: 204px;\n  color: var(--greytext);\n}\n\n.scanUid {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  color: var(--bw);\n}\n\n.copyButton {\n  margin-left: auto;\n}\n\n.copied {\n  cursor: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ScanUidCopy-module__container--fBxo5rl3",
	"button": "ScanUidCopy-module__button--u96H20CE",
	"scanElemRow": "ScanUidCopy-module__scanElemRow--etRj1Ibj",
	"title": "ScanUidCopy-module__title--rObXDo34",
	"scanUid": "ScanUidCopy-module__scanUid--eyfq5wbI",
	"copyButton": "ScanUidCopy-module__copyButton--iH5bp3rB",
	"copied": "ScanUidCopy-module__copied--KrSsXCLR"
};
export default ___CSS_LOADER_EXPORT___;
