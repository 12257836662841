import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { groupBy, prop } from 'ramda';
import { generatePath, useLocation, useNavigate } from 'react-router';

import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { LocationStateType } from '@/shared/config';

import { i18n, reportsModel } from '@/entities/reports';
import { ModalID, modalModel } from '@/entities/modal';

import {
  RemoveReportModal,
  useRemoveReportModal,
} from '@/features/removeReport';
import { ReportRow } from '@/features/reportRow';
import { useCopyReportStream } from '@/features/copyReport';
import { getReportPathType } from '@/features/reportRow/helpers/getReportPathType';

import styles from './ReportAccordion.module.scss';

// Can we add remove callback here and move remove report modal upper in StudyCard?
type ReportAccordionProps = {
  studyID: string;
};

// TODO: refactore and rename without accordions
export const ReportAccordion: FC<ReportAccordionProps> = (props) => {
  const { studyID } = props;

  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const reports = useAppSelector(
    reportsModel.selectors.selectByStudyID(studyID),
  );

  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  const filteredReports = reports.filter((item) => !item.Deleted?.Deleted);

  const reportGroups = useMemo(
    () => groupBy(prop<keyof Report>('Type'))(filteredReports),
    [filteredReports],
  ) as Record<ReportType, Report[]>;

  const {
    currentReportID,
    isRemoveReportModalOpen,
    openRemoveReportModal,
    closeRemoveReportModal,
  } = useRemoveReportModal();

  const studyReportTypes = Object.keys(reportGroups);

  // Copy Report
  const openCopyReportStream = useCopyReportStream({
    onComplete: () => {
      dispatch(modalModel.actions.closeModal(ModalID.Confirm));
      dispatch(modalModel.actions.setConfirmModalLoading(false));
    },
  });

  const handleCopyReport = useCallback(
    (reportID: string) => {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.Confirm,
          data: {
            title: formatMessage({
              id: 'copyReportModalTitle',
              defaultMessage: 'Copy report',
            }),
            okText: formatMessage({
              id: 'copyReportModalOkText',
              defaultMessage: 'Copy',
            }),
            onConfirm: () => {
              dispatch(modalModel.actions.setConfirmModalLoading(true));
              openCopyReportStream(reportID);
            },
          },
        }),
      );
    },
    [dispatch, formatMessage, openCopyReportStream],
  );
  // Copy Report end

  const navigateToReportHandle = ({
    reportType,
    patientID,
    reportID,
  }: {
    reportType: ReportType;
    patientID: string;
    reportID: string;
  }) =>
    navigate(
      generatePath(getReportPathType(reportType), {
        patientID,
        reportID,
      }),
      {
        state: {
          ...locationState,
          lastPositionPatientProfile: window.pageYOffset,
          lastOpenTabStudyID: studyID,
        },
      },
    );

  return (
    <>
      {studyReportTypes
        .filter((reportType) =>
          [
            ReportType.ReportType_CBCT_GP,
            ReportType.ReportType_CBCT_Endo,
            ReportType.ReportType_IOXRay_GP,
            ReportType.ReportType_Pano_GP,
          ].includes(+reportType),
        )
        .map((reportType) => (
          <React.Fragment key={reportType}>
            <h3 className={styles.subtitle}>
              {formatMessage(i18n[reportType as unknown as ReportType])}
            </h3>
            <div className={styles.reportRowContainer}>
              {reportGroups[reportType as unknown as ReportType].map(
                (report) => (
                  <ReportRow
                    key={report.ID}
                    report={report}
                    onDelete={() => openRemoveReportModal(report.ID)}
                    onCopy={handleCopyReport}
                    onClick={() =>
                      navigateToReportHandle({
                        reportType: report.Type,
                        reportID: report.ID,
                        patientID: report.PatientID,
                      })
                    }
                  />
                ),
              )}
            </div>
          </React.Fragment>
        ))}

      <RemoveReportModal
        reportID={currentReportID}
        isOpen={isRemoveReportModalOpen}
        onClose={closeRemoveReportModal}
      />
    </>
  );
};
