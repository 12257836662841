// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Invoice-module__container--5gC4bB9C{padding:16px;display:grid;grid-template-columns:1fr 2fr 1fr;row-gap:16px;column-gap:24px;border:2px solid var(--lavand);border-radius:16px;align-items:center}.Invoice-module__downloadLink--sWfJ2oGx{display:inline;padding-block:4px;color:var(--purpleflat)}.Invoice-module__downloadLink--sWfJ2oGx:hover{text-decoration:none;color:var(--purpletext1)}.Invoice-module__newInvoice--BglAUwyY{background-color:var(--lavand)}.Invoice-module__overdueInvoice--Pg4iOinZ{background-color:rgba(var(--redrose-rgb), 0.05)}.Invoice-module__paidInvoiceText--HZvfiXlO{color:var(--darkgreen)}.Invoice-module__overdueInvoiceText--ipz8AWB5{color:var(--redrose)}", "",{"version":3,"sources":["webpack://./src/widgets/Invoices/ui/Invoice/Invoice.module.scss"],"names":[],"mappings":"AAAA,qCACE,YAAA,CACA,YAAA,CACA,iCAAA,CACA,YAAA,CACA,eAAA,CACA,8BAAA,CACA,kBAAA,CACA,kBAAA,CAGF,wCACE,cAAA,CACA,iBAAA,CACA,uBAAA,CAEA,8CACE,oBAAA,CACA,wBAAA,CAIJ,sCACE,8BAAA,CAGF,0CACE,+CAAA,CAGF,2CACE,sBAAA,CAGF,8CACE,oBAAA","sourcesContent":[".container {\n  padding: 16px;\n  display: grid;\n  grid-template-columns: 1fr 2fr 1fr;\n  row-gap: 16px;\n  column-gap: 24px;\n  border: 2px solid var(--lavand);\n  border-radius: 16px;\n  align-items: center;\n}\n\n.downloadLink {\n  display: inline;\n  padding-block: 4px;\n  color: var(--purpleflat);\n\n  &:hover {\n    text-decoration: none;\n    color: var(--purpletext1);\n  }\n}\n\n.newInvoice {\n  background-color: var(--lavand);\n}\n\n.overdueInvoice {\n  background-color: rgba(var(--redrose-rgb), 0.05);\n}\n\n.paidInvoiceText {\n  color: var(--darkgreen);\n}\n\n.overdueInvoiceText {\n  color: var(--redrose);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Invoice-module__container--5gC4bB9C",
	"downloadLink": "Invoice-module__downloadLink--sWfJ2oGx",
	"newInvoice": "Invoice-module__newInvoice--BglAUwyY",
	"overdueInvoice": "Invoice-module__overdueInvoice--Pg4iOinZ",
	"paidInvoiceText": "Invoice-module__paidInvoiceText--HZvfiXlO",
	"overdueInvoiceText": "Invoice-module__overdueInvoiceText--ipz8AWB5"
};
export default ___CSS_LOADER_EXPORT___;
