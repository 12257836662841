// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProtectedSharingModal-module__sharingInfo--9nVgxRgy{background-color:var(--grey2);padding:12px 16px;border-radius:12px;margin-top:24px}.ProtectedSharingModal-module__modalBody--x9YqWCB8{padding-top:24px;padding-bottom:24px}.ProtectedSharingModal-module__accessCode--JpHTwskM{margin-top:16px;margin-bottom:8px}.ProtectedSharingModal-module__link--rEz4j9rt{display:block;color:var(--purpleflat);white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.ProtectedSharingModal-module__copied--TXN1lDha{cursor:auto}", "",{"version":3,"sources":["webpack://./src/features/sharePatient/ui/ProtectedSharingModal/ProtectedSharingModal.module.scss"],"names":[],"mappings":"AAAA,qDACE,6BAAA,CACA,iBAAA,CACA,kBAAA,CACA,eAAA,CAGF,mDACE,gBAAA,CACA,mBAAA,CAGF,oDACE,eAAA,CACA,iBAAA,CAGF,8CACE,aAAA,CACA,uBAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CAGF,gDACE,WAAA","sourcesContent":[".sharingInfo {\n  background-color: var(--grey2);\n  padding: 12px 16px;\n  border-radius: 12px;\n  margin-top: 24px;\n}\n\n.modalBody {\n  padding-top: 24px;\n  padding-bottom: 24px;\n}\n\n.accessCode {\n  margin-top: 16px;\n  margin-bottom: 8px;\n}\n\n.link {\n  display: block;\n  color: var(--purpleflat);\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.copied {\n  cursor: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sharingInfo": "ProtectedSharingModal-module__sharingInfo--9nVgxRgy",
	"modalBody": "ProtectedSharingModal-module__modalBody--x9YqWCB8",
	"accessCode": "ProtectedSharingModal-module__accessCode--JpHTwskM",
	"link": "ProtectedSharingModal-module__link--rEz4j9rt",
	"copied": "ProtectedSharingModal-module__copied--TXN1lDha"
};
export default ___CSS_LOADER_EXPORT___;
