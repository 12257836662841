// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShowAllSharedWithModal-module__modalBody--g7sltqD\\+{padding-top:32px}.ShowAllSharedWithModal-module__content--zEzzIu50{max-height:264px;overflow-y:auto;padding:8px}.ShowAllSharedWithModal-module__content--zEzzIu50::-webkit-scrollbar{width:5px;border-radius:8px;background-color:var(--grey10)}.ShowAllSharedWithModal-module__content--zEzzIu50::-webkit-scrollbar-thumb{width:5px;border-radius:8px;background-color:var(--grey9)}", "",{"version":3,"sources":["webpack://./src/features/showAllSharedWithList/ui/ShowAllSharedWithModal/ShowAllSharedWithModal.module.scss"],"names":[],"mappings":"AAAA,qDACE,gBAAA,CAGF,kDACE,gBAAA,CACA,eAAA,CACA,WAAA,CAEA,qEACE,SAAA,CACA,iBAAA,CACA,8BAAA,CAGF,2EACE,SAAA,CACA,iBAAA,CACA,6BAAA","sourcesContent":[".modalBody {\n  padding-top: 32px;\n}\n\n.content {\n  max-height: 264px;\n  overflow-y: auto;\n  padding: 8px;\n\n  &::-webkit-scrollbar {\n    width: 5px;\n    border-radius: 8px;\n    background-color: var(--grey10);\n  }\n\n  &::-webkit-scrollbar-thumb {\n    width: 5px;\n    border-radius: 8px;\n    background-color: var(--grey9);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "ShowAllSharedWithModal-module__modalBody--g7sltqD+",
	"content": "ShowAllSharedWithModal-module__content--zEzzIu50"
};
export default ___CSS_LOADER_EXPORT___;
