import { FC, useCallback, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Input, Modal, Select, toastCaller } from '@/shared/ui';
import { Organization } from '@/shared/api/protocol_gen/model/dto_organization';
import { ToastErrors, toastErrors } from '@/shared/config';
import { ApiError } from '@/shared/api/api';

import { ModalID, modalModel } from '@/entities/modal';
import { organizationModel } from '@/entities/organization';

import {
  EditCompanyInfoFormPayload,
  editCompanyInfoFormSchema,
} from '../../config/formSchema';

import styles from './EditCompanyInfoModal.module.scss';

export type EditCompanyInfoModalProps = {
  organizationID: string;
  className?: string;
};

export const EditCompanyInfoModal: FC<EditCompanyInfoModalProps> = ({
  organizationID,
  className,
}) => {
  const dispatch = useAppDispatch();
  const { visible } = useAppSelector(
    (state) => state.modal.EditCompanyInfoModal,
  );

  const { formatMessage } = useIntl();

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.EditCompanyInfoModal));
  }, [dispatch]);

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isSubmitted },
  } = useForm<EditCompanyInfoFormPayload>({
    resolver: yupResolver(editCompanyInfoFormSchema),
    mode: 'all',
  });

  const organization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );

  // TODO change country options hook

  const getEditCompanyDefaultValues = (companyInfo: Organization) => ({
    companyName: companyInfo.Name,
    workEmail: companyInfo.Contacts?.Emails[0],
    phoneNumber: companyInfo.Contacts?.Phones[0],
    stateRegion: companyInfo.Contacts?.Region,
    city: companyInfo.Contacts?.City,
    zipCode: companyInfo.Contacts?.ZipCode,
    address: companyInfo.Contacts?.Address,
    webSite: companyInfo.Contacts?.WebSite,
  });

  const onSubmit: SubmitHandler<EditCompanyInfoFormPayload> = async (data) => {
    const {
      companyName,
      phoneNumber,
      address,
      city,
      webSite,
      country,
      stateRegion,
      workEmail,
      zipCode,
    } = data;

    try {
      if (companyName) {
        await dispatch(
          organizationModel.thunks.setOrganizationName({
            OrganizationID: organizationID,
            Name: companyName,
          }),
        ).unwrap();
      }

      const { Organization: updatedOrganization } = await dispatch(
        organizationModel.thunks.setOrganizationContacts({
          OrganizationID: organizationID,
          Contacts: {
            Address: address ?? '',
            City: city ?? '',
            Emails: workEmail ? [workEmail] : [],
            Phones: phoneNumber ? [phoneNumber] : [],
            Country: country ? country.toString() : '',
            Region: stateRegion ?? '',
            WebSite: webSite ?? '',
            ZipCode: zipCode ?? '',
          },
        }),
      ).unwrap();

      dispatch(organizationModel.actions.setOne(updatedOrganization));
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    } finally {
      handleClose();
    }
  };

  // Set default
  useEffect(() => {
    if (organization?.Contacts) {
      reset(getEditCompanyDefaultValues(organization));
    }

    return () => {
      reset(getEditCompanyDefaultValues({} as Organization));
    };
  }, [organization, reset, visible]);

  return (
    <Modal
      title={formatMessage({
        id: 'global.edit.clinic.info',
        defaultMessage: 'Edit clinic info',
      })}
      isOpen={visible}
      onClose={handleClose}
      className={cn(className, styles.modal)}
      bodyClassName={styles.modalBody}
      containerClassName={styles.modalContainer}
      borderless
      applyButtonText={formatMessage({
        id: 'global.save',
        defaultMessage: 'Save',
      })}
      applyButtonProps={{
        type: 'submit',
        form: 'editCompanyInfoForm',
        loading: isSubmitting,
      }}
    >
      <form id="editCompanyInfoForm" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="companyName"
          render={({
            field: { ref, value, name, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Input
              required
              ref={ref}
              value={value}
              name={name}
              onBlur={() => {
                if (isSubmitted) {
                  onBlur();
                }
              }}
              onChange={(event) => {
                if (!isSubmitted) {
                  setValue(name, event.target.value);
                } else {
                  onChange(event);
                }
              }}
              type="text"
              label={formatMessage({
                id: 'global.companyName',
                defaultMessage: 'Company name',
              })}
              error={error?.message}
            />
          )}
        />

        <div className={styles.row}>
          <Controller
            control={control}
            name="workEmail"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error, isTouched },
            }) => (
              <Input
                ref={ref}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={(event) => {
                  if (!isTouched) {
                    setValue(name, event.target.value);
                  } else {
                    onChange(event);
                  }
                }}
                autoComplete="email"
                label={formatMessage({
                  id: 'global.email',
                  defaultMessage: 'Email',
                })}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="phoneNumber"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                ref={ref}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                type="tel"
                inputMode="tel"
                autoComplete="tel"
                label={formatMessage({
                  id: 'editCompanyInfo.phone',
                  defaultMessage: 'Phone',
                })}
                error={error?.message}
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="webSite"
          render={({
            field: { ref, value, name, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Input
              ref={ref}
              value={value}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              type="text"
              label={formatMessage({
                id: 'editCompanyInfo.website',
                defaultMessage: 'Website',
              })}
              error={error?.message}
            />
          )}
        />

        <div className={styles.row}>
          <Controller
            control={control}
            name="country"
            render={({
              field: { ref, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Select
                className={styles.countrySelect}
                label={formatMessage({
                  id: 'global.country',
                  defaultMessage: 'Country',
                })}
                options={[
                  {
                    value: organization?.Contacts?.Country,
                    label: organization?.Contacts?.Country,
                  },
                ]}
                isDisabled
                ref={ref}
                value={`${organization?.Contacts?.Country}`}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="stateRegion"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                ref={ref}
                value={value}
                name={name}
                inputMode="text"
                type="text"
                onBlur={onBlur}
                onChange={onChange}
                label={formatMessage({
                  id: 'global.stateRegion',
                  defaultMessage: 'State/Region',
                })}
                error={error?.message}
              />
            )}
          />
        </div>

        <div className={styles.row}>
          <Controller
            control={control}
            name="city"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                ref={ref}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                type="text"
                label={formatMessage({
                  id: 'editCompanyInfo.city',
                  defaultMessage: 'City',
                })}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="zipCode"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                ref={ref}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                type="number"
                label={formatMessage({
                  id: 'editCompanyInfo.zipCode',
                  defaultMessage: 'Zip code',
                })}
                error={error?.message}
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="address"
          render={({
            field: { ref, value, name, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Input
              ref={ref}
              value={value}
              name={name}
              inputMode="text"
              type="text"
              onBlur={onBlur}
              onChange={onChange}
              label={formatMessage({
                id: 'editCompanyInfo.address',
                defaultMessage: 'Address',
              })}
              error={error?.message}
            />
          )}
        />
      </form>
    </Modal>
  );
};
