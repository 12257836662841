import { prop, groupBy } from 'ramda';

import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { ConditionGroups, ChildConditionGroups } from '../config/const';
import { ConditionInterface, ChildConditionInterface } from '../config/types';

// Filter both condition interfaces and child condition interfaces
export const filterConditionInterfacesBySearch = (
  search: string,
  conditionInterface: ConditionInterface[],
) =>
  conditionInterface.filter((data) => {
    const lowerCaseSearch = search.toLowerCase();
    const searchIsMatch = data.text.toLowerCase().includes(lowerCaseSearch);

    if (!searchIsMatch) {
      data.childConditionInterfaces = data.childConditionInterfaces?.filter(
        (child) => child.text.toLowerCase().includes(lowerCaseSearch),
      );
    }

    return searchIsMatch || (data.childConditionInterfaces?.length ?? 0) > 0;
  });

export const sortConditionInterfaceAlphabetically = (
  a: ConditionInterface,
  b: ConditionInterface,
) => a.text.localeCompare(b.text);

export const sortConditionInterfaceByProbability = (
  a: ConditionInterface,
  b: ConditionInterface,
) => (b.probability ?? 0) * 100 - (a.probability ?? 0) * 100;

export const sortChildConditionInterfaceByProbability = (
  a: ChildConditionInterface,
  b: ChildConditionInterface,
) => (b.probability ?? 0) * 100 - (a.probability ?? 0) * 100;

export const groupConditionInterfaceByGroup = (
  conditionItems: ConditionInterface[],
) =>
  groupBy(prop('group'), conditionItems) as Record<
    ConditionGroups,
    ConditionInterface[]
  >;

export const groupChildConditionInterfaces = (
  childConditionInterfaces: ChildConditionInterface[],
) =>
  groupBy(
    prop<keyof ChildConditionInterface>('group'),
    childConditionInterfaces,
  ) as Record<ChildConditionGroups, ChildConditionInterface[]>;

// export const selectChildConditionInterfacesByGroup = (group: ConditionGroups) => (items: ConditionInterface[]) => compose(
//   filter(prop<keyof ConditionInterface>('isChecked')),
//   prop<keyof ConditionInterface>('childConditionInterfaces'),
//   find(propEq<keyof ConditionInterface>('group', group)),
// )(items);

export const selectChildConditionInterfacesByGroup =
  (conditionCode: ConditionCode) => (items: ConditionInterface[]): ChildConditionInterface[] | undefined =>
    items.find((group) => group?.code === conditionCode)?.childConditionInterfaces?.filter((tag) => tag.isChecked);

