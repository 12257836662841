import { Annotation } from '@/shared/api/protocol_gen/model/dto_common_image_annotations';
import { Annotation as GraphicsAnnotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';

// TODO: this should be refactored after migration to connect lib
// Do not use this as an example of how to write code
export const formatAnnotations = (annotations: GraphicsAnnotation[]): Annotation[] => annotations.map((item) => {
  const kind = item.kind.charAt(0).toUpperCase() + item.kind.slice(1);

  switch (item.kind) {
    case 'ruler':
    case 'arrow': {
      return {
        [kind as keyof Annotation]: {
          Start: {
            X: item.x1,
            Y: item.y1,
          },
          End: {
            X: item.x2,
            Y: item.y2,
          },
        },
      } as unknown as Annotation;
    }

    case 'angle': {
      return {
        [kind as keyof Annotation]: {
          Start: {
            X: item.x1,
            Y: item.y1,
          },
          End: {
            X: item.x2,
            Y: item.y2,
          },
          Corner: {
            X: item.x3,
            Y: item.y3,
          },
        },
      } as unknown as Annotation;
    }

    default: {
      return {
        Ruler: {
          Start: {
            X: 0,
            Y: 0,
          },
          End: {
            X: 0,
            Y: 0,
          },
        },
      } as unknown as Annotation; // TODO add other types for Angle and PolyLine
    }
  }
});

