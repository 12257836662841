import { sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { SelectOptionType } from '@/shared/config';
import { getLocalizedLanguageName } from '@/shared/lib';

type Languages = Record<string, string[]>;

const getLanguages = async (): Promise<Languages> =>
  import('../config/languages.json').then((languages) => languages.default);

const transformLanguages = (languages: Languages) =>
  sortBy(
    Object.keys(languages).map((langKey) => {
      const [languageEnglishName, languageNativeName] = languages[langKey];
      return {
        value: langKey,
        label: `${languageEnglishName} (${languageNativeName})`,
      };
    }),
    'label',
  );

// hook returns two arrays because InviteSignup requires "languageOptions"
export const useLanguages = () => {
  const [languageOptions, setLanguageOptions] = useState<SelectOptionType[]>(
    [],
  );

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const langs = await getLanguages();
        setLanguageOptions(transformLanguages(langs));
      } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    fetchLanguages();
  }, []);

  const localizedLanguageOptions = useMemo(
    () =>
      languageOptions.map((languageOption) => ({
        value: languageOption.value,
        label: getLocalizedLanguageName(languageOption),
      })),
    [languageOptions],
  );

  return { languageOptions, localizedLanguageOptions };
};
