import { FormattedMessage } from 'react-intl';

import { toastCaller } from '@/shared/ui';

export const callInternalServerError = () => {
  const errorHeading = (
    <FormattedMessage id="global.error" defaultMessage="Error" />
  );

  const internalErrorMessage = (
    <FormattedMessage
      id="global.internalServerError"
      defaultMessage="Something went wrong. Try again later or contact our support team."
    />
  );

  toastCaller({
    type: 'error',
    heading: errorHeading,
    message: internalErrorMessage,
  });
};
