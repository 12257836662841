// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PreviewFooter-module__container--fs3mbnEB{display:flex;justify-content:space-between;align-items:flex-end;gap:16px;background-color:var(--white);padding-top:16px;padding-right:16px}\n\n[dir=\"rtl\"] .PreviewFooter-module__container--fs3mbnEB{padding-right:0;padding-left:16px}@media print{.PreviewFooter-module__container--fs3mbnEB{position:fixed;left:0;bottom:0;right:0;padding:0;background-color:transparent}}.PreviewFooter-module__rightSide--4nxCjzTK{display:flex;flex-direction:column;align-items:flex-end;gap:4px}\n\n.PreviewFooter-module__text--QRcpi7m0{text-align:left;opacity:.5;max-width:80%;color:var(--bw)}\n\n[dir=\"rtl\"] .PreviewFooter-module__text--QRcpi7m0{text-align:right}.PreviewFooter-module__date--WZ35VEER{font-size:8px}", "",{"version":3,"sources":["webpack://./src/pages/PreviewReport/ui/PreviewFooter/PreviewFooter.module.scss"],"names":[],"mappings":"AAAA,2CACE,YAAA,CACA,6BAAA,CACA,oBAAA,CACA,QAAA,CACA,6BAAA,CACA,gBAAA,CACA,kBAAA;;AAPF,uDAOE,eAAA,CAAA,iBAAA,CAEA,aATF,2CAUI,cAAA,CACA,MAAA,CACA,QAAA,CACA,OAAA,CACA,SAAA,CACA,4BAAA,CAAA,CAIJ,2CACE,YAAA,CACA,qBAAA,CACA,oBAAA,CACA,OAAA;;AAGF,sCACE,eAAA,CACA,UAAA,CACA,aAAA,CACA,eAAA;;AAJF,kDACE,gBAGA,CAGF,sCACE,aAAA","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n  gap: 16px;\n  background-color: var(--white);\n  padding-top: 16px;\n  padding-right: 16px;\n\n  @media print {\n    position: fixed;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    padding: 0;\n    background-color: transparent;\n  }\n}\n\n.rightSide {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  gap: 4px;\n}\n\n.text {\n  text-align: left;\n  opacity: 0.5;\n  max-width: 80%;\n  color: var(--bw);\n}\n\n.date {\n  font-size: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PreviewFooter-module__container--fs3mbnEB",
	"rightSide": "PreviewFooter-module__rightSide--4nxCjzTK",
	"text": "PreviewFooter-module__text--QRcpi7m0",
	"date": "PreviewFooter-module__date--WZ35VEER"
};
export default ___CSS_LOADER_EXPORT___;
