// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LanguageRow-module__container--Fptl-N-s{min-height:44px;display:flex;align-items:center;justify-content:space-between;padding:4px 12px;border:2px solid var(--bg2);border-radius:24px;cursor:pointer;transition:var(--transition-time);white-space:nowrap}.LanguageRow-module__container--Fptl-N-s:hover{border:2px solid var(--purpleflat)}.LanguageRow-module__checkIcon--dXJnBfSa{color:var(--purpledec)}", "",{"version":3,"sources":["webpack://./src/widgets/Header/ui/LanguageSelector/ui/LanguageRow/LanguageRow.module.scss"],"names":[],"mappings":"AAAA,yCACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,gBAAA,CACA,2BAAA,CACA,kBAAA,CACA,cAAA,CACA,iCAAA,CACA,kBAAA,CAEA,+CACE,kCAAA,CAIJ,yCACE,sBAAA","sourcesContent":[".container {\n  min-height: 44px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 4px 12px;\n  border: 2px solid var(--bg2);\n  border-radius: 24px;\n  cursor: pointer;\n  transition: var(--transition-time);\n  white-space: nowrap;\n\n  &:hover {\n    border: 2px solid var(--purpleflat);\n  }\n}\n\n.checkIcon {\n  color: var(--purpledec);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LanguageRow-module__container--Fptl-N-s",
	"checkIcon": "LanguageRow-module__checkIcon--dXJnBfSa"
};
export default ___CSS_LOADER_EXPORT___;
