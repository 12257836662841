import { QuestionnaireSteps } from '@/entities/marketing/config/types';

export const IMAGES_NAME = {
  [QuestionnaireSteps.CompanyName]: 'companyName',
  [QuestionnaireSteps.OwnerSpecialization]: 'ownerSpecialization',
  [QuestionnaireSteps.CompanyProfile]: 'companyProfile',
  [QuestionnaireSteps.CBCTMachineType]: 'CBCTMachineType',
  [QuestionnaireSteps.CBCTImagingSoftware]: 'CBCTImagingSoftware',
  [QuestionnaireSteps.XRayImagingSoftware]: 'XRayImagingSoftware',
  [QuestionnaireSteps.FocusOfInterest]: 'focusOfInterest',
};
