// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Steps-module__container--obQQO-jE{display:grid;gap:24px}.Steps-module__selectWithText--Tp6UE7LM{width:100%;display:flex;flex-direction:column;justify-content:center;align-items:flex-start;gap:8px}.Steps-module__selectWithText--Tp6UE7LM>div{width:100%}.Steps-module__severalOptionsText---vcLzTzM{color:var(--greytext)}.Steps-module__headerOfOptionalSelect--h8sIW5xU{display:flex;flex-direction:column;justify-content:center;align-items:flex-start;gap:4px}", "",{"version":3,"sources":["webpack://./src/pages/AdditionalInformation/Steps.module.scss"],"names":[],"mappings":"AAAA,mCACE,YAAA,CACA,QAAA,CAGF,wCACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,sBAAA,CACA,OAAA,CAEA,4CACE,UAAA,CAIJ,4CACE,qBAAA,CAGF,gDACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,sBAAA,CACA,OAAA","sourcesContent":[".container {\n  display: grid;\n  gap: 24px;\n}\n\n.selectWithText {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  gap: 8px;\n\n  & > div {\n    width: 100%;\n  }\n}\n\n.severalOptionsText {\n  color: var(--greytext);\n}\n\n.headerOfOptionalSelect {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Steps-module__container--obQQO-jE",
	"selectWithText": "Steps-module__selectWithText--Tp6UE7LM",
	"severalOptionsText": "Steps-module__severalOptionsText---vcLzTzM",
	"headerOfOptionalSelect": "Steps-module__headerOfOptionalSelect--h8sIW5xU"
};
export default ___CSS_LOADER_EXPORT___;
