import { MedicalImageInterface } from '@/shared/config';
import { Localization } from '@/shared/api/protocol_gen/model/dto_report_localization';

import { makeAnnotations, makeImageViewOptions } from '@/entities/assets';

export const makeCroppedMedicalImagesFromLocalizations = (
  localizations: Localization[] = [],
): MedicalImageInterface[] =>
  localizations.map((localization) => {
    const viewOptions = makeImageViewOptions(
      localization?.CropMedicalImageFeatures?.ViewOptions,
    );

    const annotations = makeAnnotations(
      localization?.CropMedicalImageFeatures?.Annotations?.Layers,
    );

    return {
      ...localization,
      id: localization.ID,
      src: localization.TargetAssetID,
      assetID: localization.TargetAssetID,
      localizationID: localization.ID,
      viewOptions,
      annotations,
    };
  });
