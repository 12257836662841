/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Created, Revision } from '../model/dto_base';
import {
  OrganizationUserRoleType,
  OrganizationJobPosition,
  organizationUserRoleTypeFromJSON,
  organizationJobPositionFromJSON,
  organizationUserRoleTypeToJSON,
  organizationJobPositionToJSON,
} from '../model/dto_organization';

export const protobufPackage = 'com.diagnocat.model';

export interface Invitation {
  ID: string;
  /** if Recipient.UserID == Me.ID && Status == StatusPending */
  Token: string;
  OrganizationID: string;
  Recipient: InvitationRecipient | undefined;
  Target: InvitationTarget | undefined;
  TargetDetails: InvitationTargetDetails | undefined;
  Status: Invitation_InvitationStatus;
  Comment: string;
  Secret: string;
  /** creation information */
  Created: Created | undefined;
  /** information about the latest update */
  Revision: Revision | undefined;
}

export enum Invitation_InvitationStatus {
  StatusPending = 0,
  StatusAccepted = 1,
  StatusCancelled = 2,
  StatusDeleted = 3,
  UNRECOGNIZED = -1,
}

export function invitation_InvitationStatusFromJSON(
  object: any,
): Invitation_InvitationStatus {
  switch (object) {
    case 0:
    case 'StatusPending':
      return Invitation_InvitationStatus.StatusPending;
    case 1:
    case 'StatusAccepted':
      return Invitation_InvitationStatus.StatusAccepted;
    case 2:
    case 'StatusCancelled':
      return Invitation_InvitationStatus.StatusCancelled;
    case 3:
    case 'StatusDeleted':
      return Invitation_InvitationStatus.StatusDeleted;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Invitation_InvitationStatus.UNRECOGNIZED;
  }
}

export function invitation_InvitationStatusToJSON(
  object: Invitation_InvitationStatus,
): string {
  switch (object) {
    case Invitation_InvitationStatus.StatusPending:
      return 'StatusPending';
    case Invitation_InvitationStatus.StatusAccepted:
      return 'StatusAccepted';
    case Invitation_InvitationStatus.StatusCancelled:
      return 'StatusCancelled';
    case Invitation_InvitationStatus.StatusDeleted:
      return 'StatusDeleted';
    case Invitation_InvitationStatus.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface InvitationRecipient {
  UserID: string;
  Phone: string;
  Email: string;
}

export interface InvitationTarget {
  Organization: InvitationTarget_OrganizationMembership | undefined;
  Patient: InvitationTarget_PatientSharing | undefined;
}

export interface InvitationTarget_OrganizationMembership {
  OrganizationID: string;
  FirstName: string;
  LastName: string;
  Roles: OrganizationUserRoleType[];
  JobPositions: OrganizationJobPosition[];
}

export interface InvitationTarget_PatientSharing {
  PatientID: string;
}

export interface InvitationTargetDetails {
  OrganizationName: string;
  PatientFirstName: string;
  PatientLastName: string;
  SenderFirstName: string;
  SenderLastName: string;
  SenderEmail: string;
}

function createBaseInvitation(): Invitation {
  return {
    ID: '',
    Token: '',
    OrganizationID: '',
    Recipient: undefined,
    Target: undefined,
    TargetDetails: undefined,
    Status: 0,
    Comment: '',
    Secret: '',
    Created: undefined,
    Revision: undefined,
  };
}

export const Invitation = {
  encode(
    message: Invitation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Token !== '') {
      writer.uint32(18).string(message.Token);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(26).string(message.OrganizationID);
    }
    if (message.Recipient !== undefined) {
      InvitationRecipient.encode(
        message.Recipient,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.Target !== undefined) {
      InvitationTarget.encode(
        message.Target,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.TargetDetails !== undefined) {
      InvitationTargetDetails.encode(
        message.TargetDetails,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    if (message.Status !== 0) {
      writer.uint32(56).int32(message.Status);
    }
    if (message.Comment !== '') {
      writer.uint32(66).string(message.Comment);
    }
    if (message.Secret !== '') {
      writer.uint32(90).string(message.Secret);
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      Revision.encode(message.Revision, writer.uint32(80322).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Invitation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvitation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Token = reader.string();
          break;
        case 3:
          message.OrganizationID = reader.string();
          break;
        case 4:
          message.Recipient = InvitationRecipient.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 5:
          message.Target = InvitationTarget.decode(reader, reader.uint32());
          break;
        case 6:
          message.TargetDetails = InvitationTargetDetails.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 7:
          message.Status = reader.int32() as any;
          break;
        case 8:
          message.Comment = reader.string();
          break;
        case 11:
          message.Secret = reader.string();
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = Revision.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Invitation {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Token: isSet(object.Token) ? String(object.Token) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      Recipient: isSet(object.Recipient)
        ? InvitationRecipient.fromJSON(object.Recipient)
        : undefined,
      Target: isSet(object.Target)
        ? InvitationTarget.fromJSON(object.Target)
        : undefined,
      TargetDetails: isSet(object.TargetDetails)
        ? InvitationTargetDetails.fromJSON(object.TargetDetails)
        : undefined,
      Status: isSet(object.Status)
        ? invitation_InvitationStatusFromJSON(object.Status)
        : 0,
      Comment: isSet(object.Comment) ? String(object.Comment) : '',
      Secret: isSet(object.Secret) ? String(object.Secret) : '',
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? Revision.fromJSON(object.Revision)
        : undefined,
    };
  },

  toJSON(message: Invitation): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Token !== undefined && (obj.Token = message.Token);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.Recipient !== undefined &&
      (obj.Recipient = message.Recipient
        ? InvitationRecipient.toJSON(message.Recipient)
        : undefined);
    message.Target !== undefined &&
      (obj.Target = message.Target
        ? InvitationTarget.toJSON(message.Target)
        : undefined);
    message.TargetDetails !== undefined &&
      (obj.TargetDetails = message.TargetDetails
        ? InvitationTargetDetails.toJSON(message.TargetDetails)
        : undefined);
    message.Status !== undefined &&
      (obj.Status = invitation_InvitationStatusToJSON(message.Status));
    message.Comment !== undefined && (obj.Comment = message.Comment);
    message.Secret !== undefined && (obj.Secret = message.Secret);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? Revision.toJSON(message.Revision)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Invitation>, I>>(
    object: I,
  ): Invitation {
    const message = createBaseInvitation();
    message.ID = object.ID ?? '';
    message.Token = object.Token ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.Recipient =
      object.Recipient !== undefined && object.Recipient !== null
        ? InvitationRecipient.fromPartial(object.Recipient)
        : undefined;
    message.Target =
      object.Target !== undefined && object.Target !== null
        ? InvitationTarget.fromPartial(object.Target)
        : undefined;
    message.TargetDetails =
      object.TargetDetails !== undefined && object.TargetDetails !== null
        ? InvitationTargetDetails.fromPartial(object.TargetDetails)
        : undefined;
    message.Status = object.Status ?? 0;
    message.Comment = object.Comment ?? '';
    message.Secret = object.Secret ?? '';
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? Revision.fromPartial(object.Revision)
        : undefined;
    return message;
  },
};

function createBaseInvitationRecipient(): InvitationRecipient {
  return { UserID: '', Phone: '', Email: '' };
}

export const InvitationRecipient = {
  encode(
    message: InvitationRecipient,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.Phone !== '') {
      writer.uint32(18).string(message.Phone);
    }
    if (message.Email !== '') {
      writer.uint32(26).string(message.Email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InvitationRecipient {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvitationRecipient();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.Phone = reader.string();
          break;
        case 3:
          message.Email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InvitationRecipient {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      Phone: isSet(object.Phone) ? String(object.Phone) : '',
      Email: isSet(object.Email) ? String(object.Email) : '',
    };
  },

  toJSON(message: InvitationRecipient): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.Phone !== undefined && (obj.Phone = message.Phone);
    message.Email !== undefined && (obj.Email = message.Email);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<InvitationRecipient>, I>>(
    object: I,
  ): InvitationRecipient {
    const message = createBaseInvitationRecipient();
    message.UserID = object.UserID ?? '';
    message.Phone = object.Phone ?? '';
    message.Email = object.Email ?? '';
    return message;
  },
};

function createBaseInvitationTarget(): InvitationTarget {
  return { Organization: undefined, Patient: undefined };
}

export const InvitationTarget = {
  encode(
    message: InvitationTarget,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Organization !== undefined) {
      InvitationTarget_OrganizationMembership.encode(
        message.Organization,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.Patient !== undefined) {
      InvitationTarget_PatientSharing.encode(
        message.Patient,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InvitationTarget {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvitationTarget();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Organization = InvitationTarget_OrganizationMembership.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.Patient = InvitationTarget_PatientSharing.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InvitationTarget {
    return {
      Organization: isSet(object.Organization)
        ? InvitationTarget_OrganizationMembership.fromJSON(object.Organization)
        : undefined,
      Patient: isSet(object.Patient)
        ? InvitationTarget_PatientSharing.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: InvitationTarget): unknown {
    const obj: any = {};
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? InvitationTarget_OrganizationMembership.toJSON(message.Organization)
        : undefined);
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? InvitationTarget_PatientSharing.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<InvitationTarget>, I>>(
    object: I,
  ): InvitationTarget {
    const message = createBaseInvitationTarget();
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? InvitationTarget_OrganizationMembership.fromPartial(
            object.Organization,
          )
        : undefined;
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? InvitationTarget_PatientSharing.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseInvitationTarget_OrganizationMembership(): InvitationTarget_OrganizationMembership {
  return {
    OrganizationID: '',
    FirstName: '',
    LastName: '',
    Roles: [],
    JobPositions: [],
  };
}

export const InvitationTarget_OrganizationMembership = {
  encode(
    message: InvitationTarget_OrganizationMembership,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    if (message.FirstName !== '') {
      writer.uint32(18).string(message.FirstName);
    }
    if (message.LastName !== '') {
      writer.uint32(26).string(message.LastName);
    }
    writer.uint32(34).fork();
    for (const v of message.Roles) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.JobPositions) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): InvitationTarget_OrganizationMembership {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvitationTarget_OrganizationMembership();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.FirstName = reader.string();
          break;
        case 3:
          message.LastName = reader.string();
          break;
        case 4:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.Roles.push(reader.int32() as any);
            }
          } else {
            message.Roles.push(reader.int32() as any);
          }
          break;
        case 5:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.JobPositions.push(reader.int32() as any);
            }
          } else {
            message.JobPositions.push(reader.int32() as any);
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InvitationTarget_OrganizationMembership {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      FirstName: isSet(object.FirstName) ? String(object.FirstName) : '',
      LastName: isSet(object.LastName) ? String(object.LastName) : '',
      Roles: Array.isArray(object?.Roles)
        ? object.Roles.map((e: any) => organizationUserRoleTypeFromJSON(e))
        : [],
      JobPositions: Array.isArray(object?.JobPositions)
        ? object.JobPositions.map((e: any) =>
            organizationJobPositionFromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: InvitationTarget_OrganizationMembership): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.FirstName !== undefined && (obj.FirstName = message.FirstName);
    message.LastName !== undefined && (obj.LastName = message.LastName);
    if (message.Roles) {
      obj.Roles = message.Roles.map((e) => organizationUserRoleTypeToJSON(e));
    } else {
      obj.Roles = [];
    }
    if (message.JobPositions) {
      obj.JobPositions = message.JobPositions.map((e) =>
        organizationJobPositionToJSON(e),
      );
    } else {
      obj.JobPositions = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<InvitationTarget_OrganizationMembership>, I>,
  >(object: I): InvitationTarget_OrganizationMembership {
    const message = createBaseInvitationTarget_OrganizationMembership();
    message.OrganizationID = object.OrganizationID ?? '';
    message.FirstName = object.FirstName ?? '';
    message.LastName = object.LastName ?? '';
    message.Roles = object.Roles?.map((e) => e) || [];
    message.JobPositions = object.JobPositions?.map((e) => e) || [];
    return message;
  },
};

function createBaseInvitationTarget_PatientSharing(): InvitationTarget_PatientSharing {
  return { PatientID: '' };
}

export const InvitationTarget_PatientSharing = {
  encode(
    message: InvitationTarget_PatientSharing,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): InvitationTarget_PatientSharing {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvitationTarget_PatientSharing();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InvitationTarget_PatientSharing {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
    };
  },

  toJSON(message: InvitationTarget_PatientSharing): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<InvitationTarget_PatientSharing>, I>>(
    object: I,
  ): InvitationTarget_PatientSharing {
    const message = createBaseInvitationTarget_PatientSharing();
    message.PatientID = object.PatientID ?? '';
    return message;
  },
};

function createBaseInvitationTargetDetails(): InvitationTargetDetails {
  return {
    OrganizationName: '',
    PatientFirstName: '',
    PatientLastName: '',
    SenderFirstName: '',
    SenderLastName: '',
    SenderEmail: '',
  };
}

export const InvitationTargetDetails = {
  encode(
    message: InvitationTargetDetails,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationName !== '') {
      writer.uint32(10).string(message.OrganizationName);
    }
    if (message.PatientFirstName !== '') {
      writer.uint32(90).string(message.PatientFirstName);
    }
    if (message.PatientLastName !== '') {
      writer.uint32(98).string(message.PatientLastName);
    }
    if (message.SenderFirstName !== '') {
      writer.uint32(170).string(message.SenderFirstName);
    }
    if (message.SenderLastName !== '') {
      writer.uint32(178).string(message.SenderLastName);
    }
    if (message.SenderEmail !== '') {
      writer.uint32(186).string(message.SenderEmail);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): InvitationTargetDetails {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvitationTargetDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationName = reader.string();
          break;
        case 11:
          message.PatientFirstName = reader.string();
          break;
        case 12:
          message.PatientLastName = reader.string();
          break;
        case 21:
          message.SenderFirstName = reader.string();
          break;
        case 22:
          message.SenderLastName = reader.string();
          break;
        case 23:
          message.SenderEmail = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InvitationTargetDetails {
    return {
      OrganizationName: isSet(object.OrganizationName)
        ? String(object.OrganizationName)
        : '',
      PatientFirstName: isSet(object.PatientFirstName)
        ? String(object.PatientFirstName)
        : '',
      PatientLastName: isSet(object.PatientLastName)
        ? String(object.PatientLastName)
        : '',
      SenderFirstName: isSet(object.SenderFirstName)
        ? String(object.SenderFirstName)
        : '',
      SenderLastName: isSet(object.SenderLastName)
        ? String(object.SenderLastName)
        : '',
      SenderEmail: isSet(object.SenderEmail) ? String(object.SenderEmail) : '',
    };
  },

  toJSON(message: InvitationTargetDetails): unknown {
    const obj: any = {};
    message.OrganizationName !== undefined &&
      (obj.OrganizationName = message.OrganizationName);
    message.PatientFirstName !== undefined &&
      (obj.PatientFirstName = message.PatientFirstName);
    message.PatientLastName !== undefined &&
      (obj.PatientLastName = message.PatientLastName);
    message.SenderFirstName !== undefined &&
      (obj.SenderFirstName = message.SenderFirstName);
    message.SenderLastName !== undefined &&
      (obj.SenderLastName = message.SenderLastName);
    message.SenderEmail !== undefined &&
      (obj.SenderEmail = message.SenderEmail);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<InvitationTargetDetails>, I>>(
    object: I,
  ): InvitationTargetDetails {
    const message = createBaseInvitationTargetDetails();
    message.OrganizationName = object.OrganizationName ?? '';
    message.PatientFirstName = object.PatientFirstName ?? '';
    message.PatientLastName = object.PatientLastName ?? '';
    message.SenderFirstName = object.SenderFirstName ?? '';
    message.SenderLastName = object.SenderLastName ?? '';
    message.SenderEmail = object.SenderEmail ?? '';
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
