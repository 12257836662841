import { SubImageInterface } from '@/features/IOXRayMatrix';
import { GroupedIOXRayImages } from '@/features/IOXRayMatrix/lib/groupIOXRayImagesByPartition';

const getSubImageHeight = (subImage: SubImageInterface): number => (subImage.BBox?.Y?.Max ?? 0) - (subImage.BBox?.Y?.Min ?? 0);

const getMaxRowHeight = (rowElements: number[]) =>
  rowElements.length > 0 ? Math.max(...rowElements) : 0;
export const getMatrixMaxHeight = (
  groupedImages: GroupedIOXRayImages,
): number => {
  const upperMaximum = getMaxRowHeight(
    [
      ...groupedImages.UpperLeft,
      ...groupedImages.UpperMiddle,
      ...groupedImages.UpperRight,
    ].map(getSubImageHeight),
  );

  const middleMaximum = getMaxRowHeight(
    [
      ...groupedImages.MiddleLeft,
      ...groupedImages.MiddleMiddle,
      ...groupedImages.MiddleRight,
    ].map(getSubImageHeight),
  );

  const lowerMaximum = getMaxRowHeight(
    [
      ...groupedImages.LowerLeft,
      ...groupedImages.LowerMiddle,
      ...groupedImages.LowerRight,
    ].map(getSubImageHeight),
  );

  return upperMaximum + middleMaximum + lowerMaximum;
};
