import { FC } from 'react';
import cn from 'classnames';

import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import { teethForNewReportMock } from '@/entities/tooth/mocks/teethForNewReport.mock';

import { TeethChartDEPRECATED } from '@/features/toothChart';

import styles from './NewReportToothChart.module.scss';

type NewReportToothChartProps = {
  className?: string;
  testID?: string;
};

export const NewReportToothChart: FC<NewReportToothChartProps> = (props) => {
  const { className, testID } = props;

  // TODO: add logic and remove teethForNewReporMock

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      {/* TODO: there is no report id on creationg new report. Need to handle optional reportID inside TeethChart */}
      <TeethChartDEPRECATED
        reportID="sdkjfh873gsjhf"
        teeth={teethForNewReportMock as unknown as Tooth[]}
      />
    </div>
  );
};
