import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ReportCBCTIOSSuperimpositionOrientation } from '@/shared/api/protocol_gen/model/dto_report_type_cbct_ios_superimposition';

export const useGetOrientationOptions = () => {
  const { formatMessage } = useIntl();

  const orientationOptions = useMemo(
    () => [
      {
        value:
          ReportCBCTIOSSuperimpositionOrientation.CBCT_IOS_Superimposition_Orientation_CBCT,
        label: formatMessage({
          id: 'orderModel.CBCT',
          defaultMessage: 'CBCT',
        }),
      },
      {
        value:
          ReportCBCTIOSSuperimpositionOrientation.CBCT_IOS_Superimposition_Orientation_Zero_index,
        label: formatMessage({
          id: 'orderModel.ZeroIndex',
          defaultMessage: 'Zero-index',
        }),
      },
      {
        value:
          ReportCBCTIOSSuperimpositionOrientation.CBCT_IOS_Superimposition_Orientation_IOS,
        label: formatMessage({
          id: 'orderModel.IOS',
          defaultMessage: 'IOS',
        }),
      },
    ],
    [formatMessage],
  );

  return orientationOptions;
};
