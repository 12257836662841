// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UploadStudydropzone-module__container--vXmvejGJ{display:flex;flex-direction:column;gap:24px}.UploadStudydropzone-module__uploadButton--JBq\\+MLX1{display:flex;flex-direction:column;align-items:center;gap:12px;padding:24px;border:1px dashed var(--purpleflat);border-radius:12px;transition:background-color var(--transition-time)}.UploadStudydropzone-module__uploadButton--JBq\\+MLX1:hover{cursor:pointer}.UploadStudydropzone-module__uploadButton--JBq\\+MLX1:hover,.UploadStudydropzone-module__uploadButton--JBq\\+MLX1.UploadStudydropzone-module__isFocused--iCaXbYmP{outline:none;background-color:var(--transparentlavand)}.UploadStudydropzone-module__title--3TKFy6wd{color:var(--purpletext1)}.UploadStudydropzone-module__text--pI-IhRFJ{color:var(--greytext2)}", "",{"version":3,"sources":["webpack://./src/widgets/UploadStudyModalForm/ui/UploadStudyDropzone/UploadStudydropzone.module.scss"],"names":[],"mappings":"AAAA,iDACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,qDACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,QAAA,CACA,YAAA,CACA,mCAAA,CACA,kBAAA,CACA,kDAAA,CAEA,2DACE,cAAA,CAGF,gKAEE,YAAA,CACA,yCAAA,CAIJ,6CACE,wBAAA,CAGF,4CACE,sBAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.uploadButton {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 12px;\n  padding: 24px;\n  border: 1px dashed var(--purpleflat);\n  border-radius: 12px;\n  transition: background-color var(--transition-time);\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  &:hover,\n  &.isFocused {\n    outline: none;\n    background-color: var(--transparentlavand);\n  }\n}\n\n.title {\n  color: var(--purpletext1);\n}\n\n.text {\n  color: var(--greytext2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UploadStudydropzone-module__container--vXmvejGJ",
	"uploadButton": "UploadStudydropzone-module__uploadButton--JBq+MLX1",
	"isFocused": "UploadStudydropzone-module__isFocused--iCaXbYmP",
	"title": "UploadStudydropzone-module__title--3TKFy6wd",
	"text": "UploadStudydropzone-module__text--pI-IhRFJ"
};
export default ___CSS_LOADER_EXPORT___;
