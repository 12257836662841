// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IOXRaySubImage-module__masksContainer--ME5n4OVv canvas{mix-blend-mode:multiply}.IOXRaySubImage-module__disabled--RHnbgusT{opacity:.4}.IOXRaySubImage-module__pointerEventsNone--sFlYr2WL{pointer-events:none}", "",{"version":3,"sources":["webpack://./src/widgets/IOXRayMatrix/ui/IOXRaySubImage/IOXRaySubImage.module.scss"],"names":[],"mappings":"AACE,wDACE,uBAAA,CAIJ,2CACE,UAAA,CAGF,oDACE,mBAAA","sourcesContent":[".masksContainer {\n  canvas {\n    mix-blend-mode: multiply;\n  }\n}\n\n.disabled {\n  opacity: 0.4;\n}\n\n.pointerEventsNone {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"masksContainer": "IOXRaySubImage-module__masksContainer--ME5n4OVv",
	"disabled": "IOXRaySubImage-module__disabled--RHnbgusT",
	"pointerEventsNone": "IOXRaySubImage-module__pointerEventsNone--sFlYr2WL"
};
export default ___CSS_LOADER_EXPORT___;
