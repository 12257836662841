// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddStaffModal-module__row--FGz1pqm1{display:flex;gap:20px;flex-wrap:wrap}@media screen and (max-width: 420px){.AddStaffModal-module__row--FGz1pqm1{flex-direction:column;gap:32px}}.AddStaffModal-module__modalBody--7DMKqQSM{padding-bottom:24px}.AddStaffModal-module__form--j9\\+rJA5o{display:flex;flex-direction:column;gap:24px}.AddStaffModal-module__comment--f6Y57pzh{color:var(--grey8)}.AddStaffModal-module__inlineFormError--71IXg8Cn{padding:12px}", "",{"version":3,"sources":["webpack://./src/widgets/AddStaffModal/ui/AddStaffModal.module.scss","webpack://./src/shared/styles/media.scss"],"names":[],"mappings":"AAEA,qCACE,YAAA,CACA,QAAA,CACA,cAAA,CCEA,qCDLF,qCAMI,qBAAA,CACA,QAAA,CAAA,CAIJ,2CACE,mBAAA,CAGF,uCACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,yCACE,kBAAA,CAGF,iDACE,YAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.row {\n  display: flex;\n  gap: 20px;\n  flex-wrap: wrap;\n\n  @include mobile {\n    flex-direction: column;\n    gap: 32px;\n  }\n}\n\n.modalBody {\n  padding-bottom: 24px;\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.comment {\n  color: var(--grey8);\n}\n\n.inlineFormError {\n  padding: 12px;\n}\n","$mobile: 420px;\n$tablet: 768px;\n$smallDesktop: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1400px;\n\n@mixin mobile {\n  @media screen and (max-width: $mobile) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media screen and (max-width: $tablet) {\n    @content;\n  }\n}\n\n@mixin smallDesktop {\n  @media screen and (max-width: $smallDesktop) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media screen and (max-width: $desktop) {\n    @content;\n  }\n}\n\n@mixin largeDesktop {\n  @media screen and (max-width: $largeDesktop) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "AddStaffModal-module__row--FGz1pqm1",
	"modalBody": "AddStaffModal-module__modalBody--7DMKqQSM",
	"form": "AddStaffModal-module__form--j9+rJA5o",
	"comment": "AddStaffModal-module__comment--f6Y57pzh",
	"inlineFormError": "AddStaffModal-module__inlineFormError--71IXg8Cn"
};
export default ___CSS_LOADER_EXPORT___;
