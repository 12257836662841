import { useIntl } from 'react-intl';

import { useAppDispatch } from '@/shared/hooks';
import { ToastErrors, toastErrors } from '@/shared/config';
import { toastCaller } from '@/shared/ui';

import { studyModel } from '@/entities/study';
import { patientModel } from '@/entities/patient';

export const useRemoveStudy = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  return async (studyID: string) => {
    try {
      const { DeletedStudyID, ChangedPatient } = await dispatch(
        studyModel.thunks.deleteStudy({ StudyID: studyID }),
      ).unwrap();

      dispatch(studyModel.actions.removeOne(DeletedStudyID));
      dispatch(patientModel.actions.setNewestOne(ChangedPatient));
    } catch (error) {
      const parsedMessage = JSON.parse(error?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };
};
