// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddDoctorsModal-module__container--sNo-707h{margin-top:90px}@media screen and (max-width: 420px){.AddDoctorsModal-module__container--sNo-707h{margin-top:16px}}@media screen and (orientation: landscape)and (max-width: 768px){.AddDoctorsModal-module__container--sNo-707h{margin-top:0}}.AddDoctorsModal-module__form--fopbPuxs{display:flex;flex-direction:column;gap:16px}.AddDoctorsModal-module__formGlobalError--yoxLE4uZ{text-align:center}", "",{"version":3,"sources":["webpack://./src/features/addDoctors/ui/AddDoctorsModal/AddDoctorsModal.module.scss","webpack://./src/shared/styles/media.scss"],"names":[],"mappings":"AAEA,6CACE,eAAA,CCIA,qCDLF,6CAII,eAAA,CAAA,CCOF,iEDFA,6CAEI,YAAA,CAAA,CAKN,wCACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,mDACE,iBAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.container {\n  margin-top: 90px;\n\n  @include mobile {\n    margin-top: 16px;\n  }\n}\n\n@media (orientation: landscape) {\n  .container {\n    @include tablet {\n      margin-top: 0;\n    }\n  }\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.formGlobalError {\n  text-align: center;\n}\n","$mobile: 420px;\n$tablet: 768px;\n$smallDesktop: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1400px;\n\n@mixin mobile {\n  @media screen and (max-width: $mobile) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media screen and (max-width: $tablet) {\n    @content;\n  }\n}\n\n@mixin smallDesktop {\n  @media screen and (max-width: $smallDesktop) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media screen and (max-width: $desktop) {\n    @content;\n  }\n}\n\n@mixin largeDesktop {\n  @media screen and (max-width: $largeDesktop) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AddDoctorsModal-module__container--sNo-707h",
	"form": "AddDoctorsModal-module__form--fopbPuxs",
	"formGlobalError": "AddDoctorsModal-module__formGlobalError--yoxLE4uZ"
};
export default ___CSS_LOADER_EXPORT___;
