import { MedicalImageViewOptions } from '@/shared/api/protocol_gen/model/dto_common_image_view_options';

export const makeImageViewOptions = (viewOptions?: MedicalImageViewOptions) => {
  const options = {
    sharpness: viewOptions?.Sharpness ?? 0,
    invert: viewOptions?.Invert ?? false,
    wwwc: {
      ww: viewOptions?.WindowWidth ?? 0,
      wc: viewOptions?.WindowLevel ?? 0,
    },
  };

  return options;
};
