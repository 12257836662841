import React, { ReactNode } from 'react';
import cn from 'classnames';

import { useAppDispatch, useMedia } from '@/shared/hooks';

import * as logicalConditionModel from '../../../logicalCondition/model';
import { ConditionColor, ConditionInterface } from '../../config/types';

import styles from './ConditionButton.module.scss';

type ConditionButtonProps = {
  className?: string;
  rounded?: boolean;
  disabled?: boolean;
  isChild?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  showProbabilityText?: boolean;
  text: ReactNode;
  conditionInterface?: ConditionInterface;
  showLocalizationsOnHover?: boolean;
};

const colorStyle: Record<ConditionColor, string> = {
  yellow: styles.colorYellow,
  red: styles.colorRed,
  purple: styles.colorPurple,
  white: styles.colorWhite,
};

// TODO: 1) Add memoization
// TODO: 2) Simplify props. Why do we need to pass prop one by one when we need the whole ConditionInterface?
const InternalConditionButton: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  ConditionButtonProps
> = (props, ref) => {
  const {
    className,
    text,
    rounded = false,
    isChild = false,
    disabled,
    showProbabilityText = true,
    showLocalizationsOnHover = true,
    conditionInterface = {} as ConditionInterface,
    ...restConditionItemProps
  } = props;

  const {
    color,
    probability,
    probabilityText,
    toothID,
    parentID,
    code,
    hasLocalizations,
  } = conditionInterface;

  const { isMobile } = useMedia();
  const dispatch = useAppDispatch();

  const activeHover = showLocalizationsOnHover && hasLocalizations;

  // Is it better to put this outside and use useCallback? Aslo, make this component with React.memo?
  const onMouseEnterHandler = () => {
    if (activeHover) {
      dispatch(
        logicalConditionModel.actions.setHoveredConditionLink({
          toothID,
          code,
          parentID,
        }),
      );
    }
  };

  const onMouseLeaveHandler = () => {
    if (activeHover) {
      dispatch(
        logicalConditionModel.actions.setHoveredConditionLink({
          toothID: null,
          code: null,
          parentID: null,
        }),
      );
    }
  };

  return (
    <button
      ref={ref}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...restConditionItemProps}
      type="button"
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      className={cn(
        styles.container,
        isChild && styles.isChild,
        isMobile ? 'p3' : 'p2',
        colorStyle[color],
        rounded && styles.rounded,
        !showProbabilityText && styles.withoutBorder,
        className,
      )}
    >
      <span>{text}</span>

      {probability > 0 && !disabled && showProbabilityText && (
        <span>{probabilityText}</span>
      )}
    </button>
  );
};

export const ConditionButton = React.forwardRef<
  HTMLButtonElement,
  ConditionButtonProps
>(InternalConditionButton);
