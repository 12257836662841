// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer-module__container--79Cg8HiS{grid-area:footer;height:64px;display:flex;align-items:center;justify-content:center}.Footer-module__settingsFooter--GOuMAhGE{background-color:var(--wb);width:100%;z-index:3;position:fixed;bottom:0}.Footer-module__sticky--TPw95sPo{position:sticky;bottom:0}", "",{"version":3,"sources":["webpack://./src/shared/ui/Layout/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA,oCACE,gBAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,yCACE,0BAAA,CACA,UAAA,CACA,SAAA,CACA,cAAA,CACA,QAAA,CAGF,iCACE,eAAA,CACA,QAAA","sourcesContent":[".container {\n  grid-area: footer;\n  height: 64px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.settingsFooter {\n  background-color: var(--wb);\n  width: 100%;\n  z-index: 3;\n  position: fixed;\n  bottom: 0;\n}\n\n.sticky {\n  position: sticky;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Footer-module__container--79Cg8HiS",
	"settingsFooter": "Footer-module__settingsFooter--GOuMAhGE",
	"sticky": "Footer-module__sticky--TPw95sPo"
};
export default ___CSS_LOADER_EXPORT___;
