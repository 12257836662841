// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderImplantOrOrthoModel-module__container--iiBvLx4F{min-height:400px}.OrderImplantOrOrthoModel-module__actionsWrapper--LkO3a69e{height:294px;display:flex;gap:8px;margin-top:32px}.OrderImplantOrOrthoModel-module__action--BJW4S0xq{height:100%;width:100%}.OrderImplantOrOrthoModel-module__action--BJW4S0xq button{width:100%;height:100% !important;padding:0 !important}.OrderImplantOrOrthoModel-module__rightActions--0x4gASk9{display:flex;flex-direction:column;gap:8px}", "",{"version":3,"sources":["webpack://./src/pages/PatientProfile/ui/OrderModel/OrderImplantOrOrthoModel.module.scss"],"names":[],"mappings":"AAAA,sDACE,gBAAA,CAGF,2DACE,YAAA,CACA,YAAA,CACA,OAAA,CACA,eAAA,CAGF,mDACE,WAAA,CACA,UAAA,CAEA,0DACE,UAAA,CACA,sBAAA,CACA,oBAAA,CAIJ,yDACE,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":[".container {\n  min-height: 400px;\n}\n\n.actionsWrapper {\n  height: 294px;\n  display: flex;\n  gap: 8px;\n  margin-top: 32px;\n}\n\n.action {\n  height: 100%;\n  width: 100%;\n\n  button {\n    width: 100%;\n    height: 100% !important;\n    padding: 0 !important;\n  }\n}\n\n.rightActions {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OrderImplantOrOrthoModel-module__container--iiBvLx4F",
	"actionsWrapper": "OrderImplantOrOrthoModel-module__actionsWrapper--LkO3a69e",
	"action": "OrderImplantOrOrthoModel-module__action--BJW4S0xq",
	"rightActions": "OrderImplantOrOrthoModel-module__rightActions--0x4gASk9"
};
export default ___CSS_LOADER_EXPORT___;
