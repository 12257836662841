import { useState } from 'react';
import { useIntl } from 'react-intl';

import { toastCaller } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { ToastErrors, toastErrors } from '@/shared/config';
import { ApiError } from '@/shared/api/api';

import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import {
  getConditionIDsByColor,
  useToothConditions,
} from '@/entities/condition';

export const useApproveTooth = (tooth: Tooth) => {
  const [isApproveToothLoading, setApproveToothLoading] = useState(false);
  const { formatMessage } = useIntl();

  // TODO: needed only for useToothConditions [FSD, 2]
  const report = useAppSelector(
    reportsModel.selectors.selectByID(tooth?.ReportID),
  );
  const { generalConditionItems } = useToothConditions({
    toothID: tooth?.ID,
    showLowProbability: report?.Settings?.LowProbabilityMode ?? false,
  });

  const yellowConditionsIDs = getConditionIDsByColor(
    'yellow',
    generalConditionItems,
  );

  const dispatch = useAppDispatch();

  // TODO change heading for errors into formatMessages
  const toggleApproveTooth = async () => {
    setApproveToothLoading(true);

    try {
      const currentTooth = !tooth?.IsApproved
        ? await dispatch(
            reportsModel.thunks.setReportToothApproved({
              ToothID: tooth.ID,
              ToothConditionIDs: yellowConditionsIDs,
            }),
          ).unwrap()
        : await dispatch(
            reportsModel.thunks.setReportToothDisapproved({
              ToothID: tooth.ID,
            }),
          ).unwrap();
      dispatch(toothModel.actions.setNewestOne(currentTooth.Tooth));
    } catch (error) {
      const parsedMessage = JSON.parse(error?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    } finally {
      setApproveToothLoading(false);
    }
  };

  return { toggleApproveTooth, isApproveToothLoading };
};
