import { defineMessages } from 'react-intl';

import { ToothType } from './tooth.type';

export const toothTypeMessages = defineMessages<ToothType>({
  unhealthy: {
    id: 'studyToothChart.unhealthy',
    defaultMessage: 'Unhealthy',
  },
  lowProbability: {
    id: 'studyToothChart.lowProbability',
    defaultMessage: 'Low probability',
  },
  treated: {
    id: 'studyToothChart.treated',
    defaultMessage: 'Treated',
  },
  healthy: {
    id: 'studyToothChart.healthy',
    defaultMessage: 'Healthy',
  },
  missing: {
    id: 'studyToothChart.missing',
    defaultMessage: 'Missing',
  },
  all: {
    id: 'global.all',
    defaultMessage: 'All',
  },
});
