/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import {
  OrganizationType,
  Organization,
  OrganizationContacts,
  OrganizationSettings,
  OrganizationJobPosition,
  organizationTypeFromJSON,
  organizationTypeToJSON,
  organizationJobPositionFromJSON,
  organizationJobPositionToJSON,
} from '../../model/dto_organization';
import { Observable } from 'rxjs';
import { BrowserHeaders } from 'browser-headers';
import { share } from 'rxjs/operators';
import { Invitation } from '../../model/dto_access';
import { Empty } from '../../google/protobuf/empty';
import { User } from '../../model/dto_user';

export const protobufPackage = 'com.diagnocat.core';

export interface OrganizationSettingsStreamReq {
  /** `ResumeToken`: [optional]: the last resumeToken obtained from `PatientListStreamResp`. */
  ResumeToken: string;
  /** `OrganizationID`: [required]: Organization you gonna work with */
  OrganizationID: string;
}

export interface OrganizationSettingsStreamResp {
  /** If the stream was closed, we need `ResumeToken` to know which event you received the last. */
  ResumeToken: string;
  /** Current organization */
  HistoricalOrganization: Organization | undefined;
  /** Organization's pending invitations */
  HistoricalInvitation: Invitation | undefined;
  /** `EndOfHistoricalInvitations` comes right after all historical invitations are received */
  EndOfHistoricalInvitations: Empty | undefined;
  UpdatedOrganization: Organization | undefined;
  UpdatedInvitation: Invitation | undefined;
  /** `Ping` - just a stub ping message to ensure that the stream won't break by timeout */
  Ping: Empty | undefined;
}

export interface CreateOrganizationReq {
  /** `Name`: [required] */
  Name: string;
  /** `ParentID`: [optional]: should be provided only if creating a branch(child organization) */
  ParentID: string;
  /** `Type`: [required] */
  Type: OrganizationType;
  /** `Contacts`: [optional] */
  Contacts?: OrganizationContacts | undefined;
}

export interface CreateOrganizationResp {
  /** organization model */
  Organization: Organization | undefined;
}

export interface SetOrganizationNameReq {
  /** `OrganizationID`: [required] */
  OrganizationID: string;
  /** `Name`: [required] */
  Name: string;
}

export interface SetOrganizationNameResp {
  /** organization model */
  Organization: Organization | undefined;
}

export interface SetOrganizationTypeReq {
  /** `OrganizationID`: [required] */
  OrganizationID: string;
  /** `Type`: [required] */
  Type: OrganizationType;
}

export interface SetOrganizationTypeResp {
  /** organization model */
  Organization: Organization | undefined;
}

export interface SetOrganizationContactsReq {
  /** `OrganizationID`: [required] */
  OrganizationID: string;
  /**
   * `Contacts`: [required]:
   * but all Contact fields are optional
   */
  Contacts: OrganizationContacts | undefined;
}

export interface SetOrganizationContactsResp {
  /** organization model */
  Organization: Organization | undefined;
}

export interface SetOrganizationSettingsReq {
  /** `OrganizationID`: [required] */
  OrganizationID: string;
  /**
   * `Settings`: [required]:
   * but all Settings fields are optional
   */
  Settings: OrganizationSettings | undefined;
}

export interface SetOrganizationSettingsResp {
  /** organization model */
  Organization: Organization | undefined;
}

export interface DeleteOrganizationReq {
  /** `OrganizationID`: [required] */
  OrganizationID: string;
}

export interface DeleteOrganizationResp {
  DeletedOrganizationID: string;
  DeletedInvitationIDs: string[];
}

export interface HackSetOrganizationUserContactsReq {
  UserID: string;
  /** user phone */
  Phone: string;
  /** user email */
  Email?: string | undefined;
}

export interface HackSetOrganizationUserContactsResp {
  /** user model */
  User: User | undefined;
}

export interface SetOrganizationUserJobPositionsReq {
  /** `OrganizationID`: [required] */
  OrganizationID: string;
  /** `UserID`: [required] */
  UserID: string;
  /** `JobPosition`: [required] */
  JobPositions: OrganizationJobPosition[];
}

export interface SetOrganizationUserJobPositionsResp {
  /** organization model */
  Organization: Organization | undefined;
}

export interface SetOrganizationUserNameReq {
  /** `OrganizationID`: [required] */
  OrganizationID: string;
  /** `UserID`: [required] */
  UserID: string;
  /** `FirstName`: [required] */
  FirstName: string;
  /** `LastName`: [required] */
  LastName: string;
}

export interface SetOrganizationUserNameResp {
  /** organization model */
  Organization: Organization | undefined;
}

function createBaseOrganizationSettingsStreamReq(): OrganizationSettingsStreamReq {
  return { ResumeToken: '', OrganizationID: '' };
}

export const OrganizationSettingsStreamReq = {
  encode(
    message: OrganizationSettingsStreamReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ResumeToken !== '') {
      writer.uint32(10).string(message.ResumeToken);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(18).string(message.OrganizationID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): OrganizationSettingsStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationSettingsStreamReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ResumeToken = reader.string();
          break;
        case 2:
          message.OrganizationID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationSettingsStreamReq {
    return {
      ResumeToken: isSet(object.ResumeToken) ? String(object.ResumeToken) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
    };
  },

  toJSON(message: OrganizationSettingsStreamReq): unknown {
    const obj: any = {};
    message.ResumeToken !== undefined &&
      (obj.ResumeToken = message.ResumeToken);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationSettingsStreamReq>, I>>(
    object: I,
  ): OrganizationSettingsStreamReq {
    const message = createBaseOrganizationSettingsStreamReq();
    message.ResumeToken = object.ResumeToken ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    return message;
  },
};

function createBaseOrganizationSettingsStreamResp(): OrganizationSettingsStreamResp {
  return {
    ResumeToken: '',
    HistoricalOrganization: undefined,
    HistoricalInvitation: undefined,
    EndOfHistoricalInvitations: undefined,
    UpdatedOrganization: undefined,
    UpdatedInvitation: undefined,
    Ping: undefined,
  };
}

export const OrganizationSettingsStreamResp = {
  encode(
    message: OrganizationSettingsStreamResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ResumeToken !== '') {
      writer.uint32(10).string(message.ResumeToken);
    }
    if (message.HistoricalOrganization !== undefined) {
      Organization.encode(
        message.HistoricalOrganization,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.HistoricalInvitation !== undefined) {
      Invitation.encode(
        message.HistoricalInvitation,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    if (message.EndOfHistoricalInvitations !== undefined) {
      Empty.encode(
        message.EndOfHistoricalInvitations,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    if (message.UpdatedOrganization !== undefined) {
      Organization.encode(
        message.UpdatedOrganization,
        writer.uint32(170).fork(),
      ).ldelim();
    }
    if (message.UpdatedInvitation !== undefined) {
      Invitation.encode(
        message.UpdatedInvitation,
        writer.uint32(178).fork(),
      ).ldelim();
    }
    if (message.Ping !== undefined) {
      Empty.encode(message.Ping, writer.uint32(810).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): OrganizationSettingsStreamResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationSettingsStreamResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ResumeToken = reader.string();
          break;
        case 11:
          message.HistoricalOrganization = Organization.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 12:
          message.HistoricalInvitation = Invitation.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 13:
          message.EndOfHistoricalInvitations = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 21:
          message.UpdatedOrganization = Organization.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 22:
          message.UpdatedInvitation = Invitation.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 101:
          message.Ping = Empty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationSettingsStreamResp {
    return {
      ResumeToken: isSet(object.ResumeToken) ? String(object.ResumeToken) : '',
      HistoricalOrganization: isSet(object.HistoricalOrganization)
        ? Organization.fromJSON(object.HistoricalOrganization)
        : undefined,
      HistoricalInvitation: isSet(object.HistoricalInvitation)
        ? Invitation.fromJSON(object.HistoricalInvitation)
        : undefined,
      EndOfHistoricalInvitations: isSet(object.EndOfHistoricalInvitations)
        ? Empty.fromJSON(object.EndOfHistoricalInvitations)
        : undefined,
      UpdatedOrganization: isSet(object.UpdatedOrganization)
        ? Organization.fromJSON(object.UpdatedOrganization)
        : undefined,
      UpdatedInvitation: isSet(object.UpdatedInvitation)
        ? Invitation.fromJSON(object.UpdatedInvitation)
        : undefined,
      Ping: isSet(object.Ping) ? Empty.fromJSON(object.Ping) : undefined,
    };
  },

  toJSON(message: OrganizationSettingsStreamResp): unknown {
    const obj: any = {};
    message.ResumeToken !== undefined &&
      (obj.ResumeToken = message.ResumeToken);
    message.HistoricalOrganization !== undefined &&
      (obj.HistoricalOrganization = message.HistoricalOrganization
        ? Organization.toJSON(message.HistoricalOrganization)
        : undefined);
    message.HistoricalInvitation !== undefined &&
      (obj.HistoricalInvitation = message.HistoricalInvitation
        ? Invitation.toJSON(message.HistoricalInvitation)
        : undefined);
    message.EndOfHistoricalInvitations !== undefined &&
      (obj.EndOfHistoricalInvitations = message.EndOfHistoricalInvitations
        ? Empty.toJSON(message.EndOfHistoricalInvitations)
        : undefined);
    message.UpdatedOrganization !== undefined &&
      (obj.UpdatedOrganization = message.UpdatedOrganization
        ? Organization.toJSON(message.UpdatedOrganization)
        : undefined);
    message.UpdatedInvitation !== undefined &&
      (obj.UpdatedInvitation = message.UpdatedInvitation
        ? Invitation.toJSON(message.UpdatedInvitation)
        : undefined);
    message.Ping !== undefined &&
      (obj.Ping = message.Ping ? Empty.toJSON(message.Ping) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationSettingsStreamResp>, I>>(
    object: I,
  ): OrganizationSettingsStreamResp {
    const message = createBaseOrganizationSettingsStreamResp();
    message.ResumeToken = object.ResumeToken ?? '';
    message.HistoricalOrganization =
      object.HistoricalOrganization !== undefined &&
      object.HistoricalOrganization !== null
        ? Organization.fromPartial(object.HistoricalOrganization)
        : undefined;
    message.HistoricalInvitation =
      object.HistoricalInvitation !== undefined &&
      object.HistoricalInvitation !== null
        ? Invitation.fromPartial(object.HistoricalInvitation)
        : undefined;
    message.EndOfHistoricalInvitations =
      object.EndOfHistoricalInvitations !== undefined &&
      object.EndOfHistoricalInvitations !== null
        ? Empty.fromPartial(object.EndOfHistoricalInvitations)
        : undefined;
    message.UpdatedOrganization =
      object.UpdatedOrganization !== undefined &&
      object.UpdatedOrganization !== null
        ? Organization.fromPartial(object.UpdatedOrganization)
        : undefined;
    message.UpdatedInvitation =
      object.UpdatedInvitation !== undefined &&
      object.UpdatedInvitation !== null
        ? Invitation.fromPartial(object.UpdatedInvitation)
        : undefined;
    message.Ping =
      object.Ping !== undefined && object.Ping !== null
        ? Empty.fromPartial(object.Ping)
        : undefined;
    return message;
  },
};

function createBaseCreateOrganizationReq(): CreateOrganizationReq {
  return { Name: '', ParentID: '', Type: 0, Contacts: undefined };
}

export const CreateOrganizationReq = {
  encode(
    message: CreateOrganizationReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Name !== '') {
      writer.uint32(10).string(message.Name);
    }
    if (message.ParentID !== '') {
      writer.uint32(18).string(message.ParentID);
    }
    if (message.Type !== 0) {
      writer.uint32(24).int32(message.Type);
    }
    if (message.Contacts !== undefined) {
      OrganizationContacts.encode(
        message.Contacts,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateOrganizationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrganizationReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Name = reader.string();
          break;
        case 2:
          message.ParentID = reader.string();
          break;
        case 3:
          message.Type = reader.int32() as any;
          break;
        case 4:
          message.Contacts = OrganizationContacts.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateOrganizationReq {
    return {
      Name: isSet(object.Name) ? String(object.Name) : '',
      ParentID: isSet(object.ParentID) ? String(object.ParentID) : '',
      Type: isSet(object.Type) ? organizationTypeFromJSON(object.Type) : 0,
      Contacts: isSet(object.Contacts)
        ? OrganizationContacts.fromJSON(object.Contacts)
        : undefined,
    };
  },

  toJSON(message: CreateOrganizationReq): unknown {
    const obj: any = {};
    message.Name !== undefined && (obj.Name = message.Name);
    message.ParentID !== undefined && (obj.ParentID = message.ParentID);
    message.Type !== undefined &&
      (obj.Type = organizationTypeToJSON(message.Type));
    message.Contacts !== undefined &&
      (obj.Contacts = message.Contacts
        ? OrganizationContacts.toJSON(message.Contacts)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateOrganizationReq>, I>>(
    object: I,
  ): CreateOrganizationReq {
    const message = createBaseCreateOrganizationReq();
    message.Name = object.Name ?? '';
    message.ParentID = object.ParentID ?? '';
    message.Type = object.Type ?? 0;
    message.Contacts =
      object.Contacts !== undefined && object.Contacts !== null
        ? OrganizationContacts.fromPartial(object.Contacts)
        : undefined;
    return message;
  },
};

function createBaseCreateOrganizationResp(): CreateOrganizationResp {
  return { Organization: undefined };
}

export const CreateOrganizationResp = {
  encode(
    message: CreateOrganizationResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateOrganizationResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrganizationResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateOrganizationResp {
    return {
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
    };
  },

  toJSON(message: CreateOrganizationResp): unknown {
    const obj: any = {};
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateOrganizationResp>, I>>(
    object: I,
  ): CreateOrganizationResp {
    const message = createBaseCreateOrganizationResp();
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    return message;
  },
};

function createBaseSetOrganizationNameReq(): SetOrganizationNameReq {
  return { OrganizationID: '', Name: '' };
}

export const SetOrganizationNameReq = {
  encode(
    message: SetOrganizationNameReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    if (message.Name !== '') {
      writer.uint32(18).string(message.Name);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetOrganizationNameReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetOrganizationNameReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetOrganizationNameReq {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      Name: isSet(object.Name) ? String(object.Name) : '',
    };
  },

  toJSON(message: SetOrganizationNameReq): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.Name !== undefined && (obj.Name = message.Name);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetOrganizationNameReq>, I>>(
    object: I,
  ): SetOrganizationNameReq {
    const message = createBaseSetOrganizationNameReq();
    message.OrganizationID = object.OrganizationID ?? '';
    message.Name = object.Name ?? '';
    return message;
  },
};

function createBaseSetOrganizationNameResp(): SetOrganizationNameResp {
  return { Organization: undefined };
}

export const SetOrganizationNameResp = {
  encode(
    message: SetOrganizationNameResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetOrganizationNameResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetOrganizationNameResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetOrganizationNameResp {
    return {
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
    };
  },

  toJSON(message: SetOrganizationNameResp): unknown {
    const obj: any = {};
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetOrganizationNameResp>, I>>(
    object: I,
  ): SetOrganizationNameResp {
    const message = createBaseSetOrganizationNameResp();
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    return message;
  },
};

function createBaseSetOrganizationTypeReq(): SetOrganizationTypeReq {
  return { OrganizationID: '', Type: 0 };
}

export const SetOrganizationTypeReq = {
  encode(
    message: SetOrganizationTypeReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    if (message.Type !== 0) {
      writer.uint32(16).int32(message.Type);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetOrganizationTypeReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetOrganizationTypeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.Type = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetOrganizationTypeReq {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      Type: isSet(object.Type) ? organizationTypeFromJSON(object.Type) : 0,
    };
  },

  toJSON(message: SetOrganizationTypeReq): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.Type !== undefined &&
      (obj.Type = organizationTypeToJSON(message.Type));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetOrganizationTypeReq>, I>>(
    object: I,
  ): SetOrganizationTypeReq {
    const message = createBaseSetOrganizationTypeReq();
    message.OrganizationID = object.OrganizationID ?? '';
    message.Type = object.Type ?? 0;
    return message;
  },
};

function createBaseSetOrganizationTypeResp(): SetOrganizationTypeResp {
  return { Organization: undefined };
}

export const SetOrganizationTypeResp = {
  encode(
    message: SetOrganizationTypeResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetOrganizationTypeResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetOrganizationTypeResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetOrganizationTypeResp {
    return {
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
    };
  },

  toJSON(message: SetOrganizationTypeResp): unknown {
    const obj: any = {};
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetOrganizationTypeResp>, I>>(
    object: I,
  ): SetOrganizationTypeResp {
    const message = createBaseSetOrganizationTypeResp();
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    return message;
  },
};

function createBaseSetOrganizationContactsReq(): SetOrganizationContactsReq {
  return { OrganizationID: '', Contacts: undefined };
}

export const SetOrganizationContactsReq = {
  encode(
    message: SetOrganizationContactsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    if (message.Contacts !== undefined) {
      OrganizationContacts.encode(
        message.Contacts,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetOrganizationContactsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetOrganizationContactsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.Contacts = OrganizationContacts.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetOrganizationContactsReq {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      Contacts: isSet(object.Contacts)
        ? OrganizationContacts.fromJSON(object.Contacts)
        : undefined,
    };
  },

  toJSON(message: SetOrganizationContactsReq): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.Contacts !== undefined &&
      (obj.Contacts = message.Contacts
        ? OrganizationContacts.toJSON(message.Contacts)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetOrganizationContactsReq>, I>>(
    object: I,
  ): SetOrganizationContactsReq {
    const message = createBaseSetOrganizationContactsReq();
    message.OrganizationID = object.OrganizationID ?? '';
    message.Contacts =
      object.Contacts !== undefined && object.Contacts !== null
        ? OrganizationContacts.fromPartial(object.Contacts)
        : undefined;
    return message;
  },
};

function createBaseSetOrganizationContactsResp(): SetOrganizationContactsResp {
  return { Organization: undefined };
}

export const SetOrganizationContactsResp = {
  encode(
    message: SetOrganizationContactsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetOrganizationContactsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetOrganizationContactsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetOrganizationContactsResp {
    return {
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
    };
  },

  toJSON(message: SetOrganizationContactsResp): unknown {
    const obj: any = {};
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetOrganizationContactsResp>, I>>(
    object: I,
  ): SetOrganizationContactsResp {
    const message = createBaseSetOrganizationContactsResp();
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    return message;
  },
};

function createBaseSetOrganizationSettingsReq(): SetOrganizationSettingsReq {
  return { OrganizationID: '', Settings: undefined };
}

export const SetOrganizationSettingsReq = {
  encode(
    message: SetOrganizationSettingsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    if (message.Settings !== undefined) {
      OrganizationSettings.encode(
        message.Settings,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetOrganizationSettingsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetOrganizationSettingsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.Settings = OrganizationSettings.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetOrganizationSettingsReq {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      Settings: isSet(object.Settings)
        ? OrganizationSettings.fromJSON(object.Settings)
        : undefined,
    };
  },

  toJSON(message: SetOrganizationSettingsReq): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.Settings !== undefined &&
      (obj.Settings = message.Settings
        ? OrganizationSettings.toJSON(message.Settings)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetOrganizationSettingsReq>, I>>(
    object: I,
  ): SetOrganizationSettingsReq {
    const message = createBaseSetOrganizationSettingsReq();
    message.OrganizationID = object.OrganizationID ?? '';
    message.Settings =
      object.Settings !== undefined && object.Settings !== null
        ? OrganizationSettings.fromPartial(object.Settings)
        : undefined;
    return message;
  },
};

function createBaseSetOrganizationSettingsResp(): SetOrganizationSettingsResp {
  return { Organization: undefined };
}

export const SetOrganizationSettingsResp = {
  encode(
    message: SetOrganizationSettingsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetOrganizationSettingsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetOrganizationSettingsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetOrganizationSettingsResp {
    return {
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
    };
  },

  toJSON(message: SetOrganizationSettingsResp): unknown {
    const obj: any = {};
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetOrganizationSettingsResp>, I>>(
    object: I,
  ): SetOrganizationSettingsResp {
    const message = createBaseSetOrganizationSettingsResp();
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    return message;
  },
};

function createBaseDeleteOrganizationReq(): DeleteOrganizationReq {
  return { OrganizationID: '' };
}

export const DeleteOrganizationReq = {
  encode(
    message: DeleteOrganizationReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeleteOrganizationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteOrganizationReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteOrganizationReq {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
    };
  },

  toJSON(message: DeleteOrganizationReq): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteOrganizationReq>, I>>(
    object: I,
  ): DeleteOrganizationReq {
    const message = createBaseDeleteOrganizationReq();
    message.OrganizationID = object.OrganizationID ?? '';
    return message;
  },
};

function createBaseDeleteOrganizationResp(): DeleteOrganizationResp {
  return { DeletedOrganizationID: '', DeletedInvitationIDs: [] };
}

export const DeleteOrganizationResp = {
  encode(
    message: DeleteOrganizationResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.DeletedOrganizationID !== '') {
      writer.uint32(10).string(message.DeletedOrganizationID);
    }
    for (const v of message.DeletedInvitationIDs) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeleteOrganizationResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteOrganizationResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DeletedOrganizationID = reader.string();
          break;
        case 2:
          message.DeletedInvitationIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteOrganizationResp {
    return {
      DeletedOrganizationID: isSet(object.DeletedOrganizationID)
        ? String(object.DeletedOrganizationID)
        : '',
      DeletedInvitationIDs: Array.isArray(object?.DeletedInvitationIDs)
        ? object.DeletedInvitationIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: DeleteOrganizationResp): unknown {
    const obj: any = {};
    message.DeletedOrganizationID !== undefined &&
      (obj.DeletedOrganizationID = message.DeletedOrganizationID);
    if (message.DeletedInvitationIDs) {
      obj.DeletedInvitationIDs = message.DeletedInvitationIDs.map((e) => e);
    } else {
      obj.DeletedInvitationIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteOrganizationResp>, I>>(
    object: I,
  ): DeleteOrganizationResp {
    const message = createBaseDeleteOrganizationResp();
    message.DeletedOrganizationID = object.DeletedOrganizationID ?? '';
    message.DeletedInvitationIDs =
      object.DeletedInvitationIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseHackSetOrganizationUserContactsReq(): HackSetOrganizationUserContactsReq {
  return { UserID: '', Phone: '', Email: undefined };
}

export const HackSetOrganizationUserContactsReq = {
  encode(
    message: HackSetOrganizationUserContactsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.Phone !== '') {
      writer.uint32(18).string(message.Phone);
    }
    if (message.Email !== undefined) {
      writer.uint32(26).string(message.Email);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): HackSetOrganizationUserContactsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHackSetOrganizationUserContactsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.Phone = reader.string();
          break;
        case 3:
          message.Email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): HackSetOrganizationUserContactsReq {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      Phone: isSet(object.Phone) ? String(object.Phone) : '',
      Email: isSet(object.Email) ? String(object.Email) : undefined,
    };
  },

  toJSON(message: HackSetOrganizationUserContactsReq): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.Phone !== undefined && (obj.Phone = message.Phone);
    message.Email !== undefined && (obj.Email = message.Email);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<HackSetOrganizationUserContactsReq>, I>,
  >(object: I): HackSetOrganizationUserContactsReq {
    const message = createBaseHackSetOrganizationUserContactsReq();
    message.UserID = object.UserID ?? '';
    message.Phone = object.Phone ?? '';
    message.Email = object.Email ?? undefined;
    return message;
  },
};

function createBaseHackSetOrganizationUserContactsResp(): HackSetOrganizationUserContactsResp {
  return { User: undefined };
}

export const HackSetOrganizationUserContactsResp = {
  encode(
    message: HackSetOrganizationUserContactsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.User !== undefined) {
      User.encode(message.User, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): HackSetOrganizationUserContactsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHackSetOrganizationUserContactsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.User = User.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): HackSetOrganizationUserContactsResp {
    return {
      User: isSet(object.User) ? User.fromJSON(object.User) : undefined,
    };
  },

  toJSON(message: HackSetOrganizationUserContactsResp): unknown {
    const obj: any = {};
    message.User !== undefined &&
      (obj.User = message.User ? User.toJSON(message.User) : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<HackSetOrganizationUserContactsResp>, I>,
  >(object: I): HackSetOrganizationUserContactsResp {
    const message = createBaseHackSetOrganizationUserContactsResp();
    message.User =
      object.User !== undefined && object.User !== null
        ? User.fromPartial(object.User)
        : undefined;
    return message;
  },
};

function createBaseSetOrganizationUserJobPositionsReq(): SetOrganizationUserJobPositionsReq {
  return { OrganizationID: '', UserID: '', JobPositions: [] };
}

export const SetOrganizationUserJobPositionsReq = {
  encode(
    message: SetOrganizationUserJobPositionsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    if (message.UserID !== '') {
      writer.uint32(18).string(message.UserID);
    }
    writer.uint32(26).fork();
    for (const v of message.JobPositions) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetOrganizationUserJobPositionsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetOrganizationUserJobPositionsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.UserID = reader.string();
          break;
        case 3:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.JobPositions.push(reader.int32() as any);
            }
          } else {
            message.JobPositions.push(reader.int32() as any);
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetOrganizationUserJobPositionsReq {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      JobPositions: Array.isArray(object?.JobPositions)
        ? object.JobPositions.map((e: any) =>
            organizationJobPositionFromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: SetOrganizationUserJobPositionsReq): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.UserID !== undefined && (obj.UserID = message.UserID);
    if (message.JobPositions) {
      obj.JobPositions = message.JobPositions.map((e) =>
        organizationJobPositionToJSON(e),
      );
    } else {
      obj.JobPositions = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetOrganizationUserJobPositionsReq>, I>,
  >(object: I): SetOrganizationUserJobPositionsReq {
    const message = createBaseSetOrganizationUserJobPositionsReq();
    message.OrganizationID = object.OrganizationID ?? '';
    message.UserID = object.UserID ?? '';
    message.JobPositions = object.JobPositions?.map((e) => e) || [];
    return message;
  },
};

function createBaseSetOrganizationUserJobPositionsResp(): SetOrganizationUserJobPositionsResp {
  return { Organization: undefined };
}

export const SetOrganizationUserJobPositionsResp = {
  encode(
    message: SetOrganizationUserJobPositionsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetOrganizationUserJobPositionsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetOrganizationUserJobPositionsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetOrganizationUserJobPositionsResp {
    return {
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
    };
  },

  toJSON(message: SetOrganizationUserJobPositionsResp): unknown {
    const obj: any = {};
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetOrganizationUserJobPositionsResp>, I>,
  >(object: I): SetOrganizationUserJobPositionsResp {
    const message = createBaseSetOrganizationUserJobPositionsResp();
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    return message;
  },
};

function createBaseSetOrganizationUserNameReq(): SetOrganizationUserNameReq {
  return { OrganizationID: '', UserID: '', FirstName: '', LastName: '' };
}

export const SetOrganizationUserNameReq = {
  encode(
    message: SetOrganizationUserNameReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    if (message.UserID !== '') {
      writer.uint32(18).string(message.UserID);
    }
    if (message.FirstName !== '') {
      writer.uint32(26).string(message.FirstName);
    }
    if (message.LastName !== '') {
      writer.uint32(34).string(message.LastName);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetOrganizationUserNameReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetOrganizationUserNameReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.UserID = reader.string();
          break;
        case 3:
          message.FirstName = reader.string();
          break;
        case 4:
          message.LastName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetOrganizationUserNameReq {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      FirstName: isSet(object.FirstName) ? String(object.FirstName) : '',
      LastName: isSet(object.LastName) ? String(object.LastName) : '',
    };
  },

  toJSON(message: SetOrganizationUserNameReq): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.FirstName !== undefined && (obj.FirstName = message.FirstName);
    message.LastName !== undefined && (obj.LastName = message.LastName);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetOrganizationUserNameReq>, I>>(
    object: I,
  ): SetOrganizationUserNameReq {
    const message = createBaseSetOrganizationUserNameReq();
    message.OrganizationID = object.OrganizationID ?? '';
    message.UserID = object.UserID ?? '';
    message.FirstName = object.FirstName ?? '';
    message.LastName = object.LastName ?? '';
    return message;
  },
};

function createBaseSetOrganizationUserNameResp(): SetOrganizationUserNameResp {
  return { Organization: undefined };
}

export const SetOrganizationUserNameResp = {
  encode(
    message: SetOrganizationUserNameResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetOrganizationUserNameResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetOrganizationUserNameResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetOrganizationUserNameResp {
    return {
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
    };
  },

  toJSON(message: SetOrganizationUserNameResp): unknown {
    const obj: any = {};
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetOrganizationUserNameResp>, I>>(
    object: I,
  ): SetOrganizationUserNameResp {
    const message = createBaseSetOrganizationUserNameResp();
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    return message;
  },
};

/**
 * Common errors:
 * - type: RequiredArgumentMissingError, Argument: OrganizationID
 * - type: NotFoundError, Entity: organization (if organization not found by OrganizationID)
 * - type: BadRequestError, Reason: organization deleted (if remote organization found by OrganizationID)
 */
export interface OrganizationService {
  /**
   * Get organization and organization's invitations
   *
   * Permission: `OrganizationPermissions.CanViewStaff`
   */
  OrganizationSettingsStream(
    request: DeepPartial<OrganizationSettingsStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<OrganizationSettingsStreamResp>;
  /**
   * Create new organization or organization branch
   * - Any user can create a new organization
   * - The user who created the new organization is automatically assigned the Owner role
   * - Only Owner or Admin can create branches
   * - If branches are created, then all Owner or Admin user roles are duplicated from the parent organization to the branch
   *
   * Errors:
   * - type: BadRequestError, Reason: parent deleted (if remote organization found by `CreateOrganizationReq.ParentID`)
   * - type: BadRequestError, Reason: solo practitioners organization cannot have branches (if organization with type OrganizationTypeSoloPractitioner found by `CreateOrganizationReq.ParentID`)
   * - type: PermissionDeniedError, Reason: you do not have organization permission `CanEditBranches` (if user create branch and does not have the appropriate permission)
   */
  CreateOrganization(
    request: DeepPartial<CreateOrganizationReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateOrganizationResp>;
  /**
   * Set organization name
   *
   * Permission: `OrganizationPermissions.CanEditInfo`
   *
   * Errors:
   * - type: RequiredArgumentMissingError, Argument: Name
   */
  SetOrganizationName(
    request: DeepPartial<SetOrganizationNameReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetOrganizationNameResp>;
  /**
   * Set organization type
   *
   * Permission: `OrganizationPermissions.CanEditInfo`
   *
   * Errors:
   * - type: BadRequestError, Reason: cannot set type SoloPractitioner because organization has branches
   */
  SetOrganizationType(
    request: DeepPartial<SetOrganizationTypeReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetOrganizationTypeResp>;
  /**
   * Set organization contacts
   *
   * Permission: `OrganizationPermissions.CanEditInfo`
   *
   * Errors:
   * - type: RequiredArgumentMissingError, Argument: OrganizationID/Contacts - (if the corresponding argument is empty)
   */
  SetOrganizationContacts(
    request: DeepPartial<SetOrganizationContactsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetOrganizationContactsResp>;
  /**
   * Set organization settings
   *
   * Permission: `OrganizationPermissions.CanEditSettings`
   *
   * Errors:
   * - type: RequiredArgumentMissingError, Argument: OrganizationID/Settings - (if the corresponding argument is empty)
   */
  SetOrganizationSettings(
    request: DeepPartial<SetOrganizationSettingsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetOrganizationSettingsResp>;
  /**
   * Mark organization as deleted using organization id
   * - this organization mark as deleted
   * - all invitations to this organization are closed
   * - all invitations to the patients/studies/reports of this organization are closed
   *
   * Permission: `OrganizationPermissions.CanEditInfo`
   *
   * Errors:
   * - type: BadRequestError, Reason: organization has alive patients
   * - type: BadRequestError, Reason: organization has employees
   * - type: BadRequestError, Reason: organization has branches
   */
  DeleteOrganization(
    request: DeepPartial<DeleteOrganizationReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteOrganizationResp>;
  Hack_SetUserContacts(
    request: DeepPartial<HackSetOrganizationUserContactsReq>,
    metadata?: grpc.Metadata,
  ): Promise<HackSetOrganizationUserContactsResp>;
  /**
   * Set job positions of an organization employee
   *
   * Permission: `OrganizationPermissions.CanEditStaff`
   */
  SetOrganizationUserJobPositions(
    request: DeepPartial<SetOrganizationUserJobPositionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetOrganizationUserJobPositionsResp>;
  /**
   * Set new user's name to organization role
   *
   * Permission: `OrganizationPermissions.CanEditStaff`
   */
  SetOrganizationUserName(
    request: DeepPartial<SetOrganizationUserNameReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetOrganizationUserNameResp>;
}

export class OrganizationServiceClientImpl implements OrganizationService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.OrganizationSettingsStream =
      this.OrganizationSettingsStream.bind(this);
    this.CreateOrganization = this.CreateOrganization.bind(this);
    this.SetOrganizationName = this.SetOrganizationName.bind(this);
    this.SetOrganizationType = this.SetOrganizationType.bind(this);
    this.SetOrganizationContacts = this.SetOrganizationContacts.bind(this);
    this.SetOrganizationSettings = this.SetOrganizationSettings.bind(this);
    this.DeleteOrganization = this.DeleteOrganization.bind(this);
    this.Hack_SetUserContacts = this.Hack_SetUserContacts.bind(this);
    this.SetOrganizationUserJobPositions =
      this.SetOrganizationUserJobPositions.bind(this);
    this.SetOrganizationUserName = this.SetOrganizationUserName.bind(this);
  }

  OrganizationSettingsStream(
    request: DeepPartial<OrganizationSettingsStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<OrganizationSettingsStreamResp> {
    return this.rpc.invoke(
      OrganizationServiceOrganizationSettingsStreamDesc,
      OrganizationSettingsStreamReq.fromPartial(request),
      metadata,
    );
  }

  CreateOrganization(
    request: DeepPartial<CreateOrganizationReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateOrganizationResp> {
    return this.rpc.unary(
      OrganizationServiceCreateOrganizationDesc,
      CreateOrganizationReq.fromPartial(request),
      metadata,
    );
  }

  SetOrganizationName(
    request: DeepPartial<SetOrganizationNameReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetOrganizationNameResp> {
    return this.rpc.unary(
      OrganizationServiceSetOrganizationNameDesc,
      SetOrganizationNameReq.fromPartial(request),
      metadata,
    );
  }

  SetOrganizationType(
    request: DeepPartial<SetOrganizationTypeReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetOrganizationTypeResp> {
    return this.rpc.unary(
      OrganizationServiceSetOrganizationTypeDesc,
      SetOrganizationTypeReq.fromPartial(request),
      metadata,
    );
  }

  SetOrganizationContacts(
    request: DeepPartial<SetOrganizationContactsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetOrganizationContactsResp> {
    return this.rpc.unary(
      OrganizationServiceSetOrganizationContactsDesc,
      SetOrganizationContactsReq.fromPartial(request),
      metadata,
    );
  }

  SetOrganizationSettings(
    request: DeepPartial<SetOrganizationSettingsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetOrganizationSettingsResp> {
    return this.rpc.unary(
      OrganizationServiceSetOrganizationSettingsDesc,
      SetOrganizationSettingsReq.fromPartial(request),
      metadata,
    );
  }

  DeleteOrganization(
    request: DeepPartial<DeleteOrganizationReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteOrganizationResp> {
    return this.rpc.unary(
      OrganizationServiceDeleteOrganizationDesc,
      DeleteOrganizationReq.fromPartial(request),
      metadata,
    );
  }

  Hack_SetUserContacts(
    request: DeepPartial<HackSetOrganizationUserContactsReq>,
    metadata?: grpc.Metadata,
  ): Promise<HackSetOrganizationUserContactsResp> {
    return this.rpc.unary(
      OrganizationServiceHack_SetUserContactsDesc,
      HackSetOrganizationUserContactsReq.fromPartial(request),
      metadata,
    );
  }

  SetOrganizationUserJobPositions(
    request: DeepPartial<SetOrganizationUserJobPositionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetOrganizationUserJobPositionsResp> {
    return this.rpc.unary(
      OrganizationServiceSetOrganizationUserJobPositionsDesc,
      SetOrganizationUserJobPositionsReq.fromPartial(request),
      metadata,
    );
  }

  SetOrganizationUserName(
    request: DeepPartial<SetOrganizationUserNameReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetOrganizationUserNameResp> {
    return this.rpc.unary(
      OrganizationServiceSetOrganizationUserNameDesc,
      SetOrganizationUserNameReq.fromPartial(request),
      metadata,
    );
  }
}

export const OrganizationServiceDesc = {
  serviceName: 'com.diagnocat.core.OrganizationService',
};

export const OrganizationServiceOrganizationSettingsStreamDesc: UnaryMethodDefinitionish =
  {
    methodName: 'OrganizationSettingsStream',
    service: OrganizationServiceDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
      serializeBinary() {
        return OrganizationSettingsStreamReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...OrganizationSettingsStreamResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const OrganizationServiceCreateOrganizationDesc: UnaryMethodDefinitionish =
  {
    methodName: 'CreateOrganization',
    service: OrganizationServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return CreateOrganizationReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...CreateOrganizationResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const OrganizationServiceSetOrganizationNameDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetOrganizationName',
    service: OrganizationServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetOrganizationNameReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetOrganizationNameResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const OrganizationServiceSetOrganizationTypeDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetOrganizationType',
    service: OrganizationServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetOrganizationTypeReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetOrganizationTypeResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const OrganizationServiceSetOrganizationContactsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetOrganizationContacts',
    service: OrganizationServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetOrganizationContactsReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetOrganizationContactsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const OrganizationServiceSetOrganizationSettingsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetOrganizationSettings',
    service: OrganizationServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetOrganizationSettingsReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetOrganizationSettingsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const OrganizationServiceDeleteOrganizationDesc: UnaryMethodDefinitionish =
  {
    methodName: 'DeleteOrganization',
    service: OrganizationServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return DeleteOrganizationReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...DeleteOrganizationResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const OrganizationServiceHack_SetUserContactsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'Hack_SetUserContacts',
    service: OrganizationServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return HackSetOrganizationUserContactsReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...HackSetOrganizationUserContactsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const OrganizationServiceSetOrganizationUserJobPositionsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetOrganizationUserJobPositions',
    service: OrganizationServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetOrganizationUserJobPositionsReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetOrganizationUserJobPositionsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const OrganizationServiceSetOrganizationUserNameDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetOrganizationUserName',
    service: OrganizationServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetOrganizationUserNameReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetOrganizationUserNameResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
    const upStreamCodes = [2, 4, 8, 9, 10, 13, 14, 15];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              observer.error(new Error(`Error ${code} ${message}`));
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
