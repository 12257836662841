// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignIn-module__container--UwyU8zYQ{width:100vw;height:100vh;display:flex;align-items:center;justify-content:center;background-color:rgb(var(--purpletext2-rgb), 0.8);padding-left:8px;padding-right:8px}.SignIn-module__wrapper--ELYSA0hF{padding:24px;border-radius:16px;background-color:var(--wb);flex-basis:680px}.SignIn-module__header--CVcCAul\\+{margin-bottom:8px}.SignIn-module__email--k1dfU9EY{margin-bottom:22px}.SignIn-module__password--XWqothKE{margin-bottom:32px}.SignIn-module__formFooter--WAiVdKIe{display:flex;justify-content:flex-end;margin-top:34px}", "",{"version":3,"sources":["webpack://./src/pages/SignIn/SignIn.module.scss"],"names":[],"mappings":"AAAA,oCACE,WAAA,CACA,YAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iDAAA,CACA,gBAAA,CACA,iBAAA,CAGF,kCACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,gBAAA,CAGF,kCACE,iBAAA,CAGF,gCACE,kBAAA,CAGF,mCACE,kBAAA,CAGF,qCACE,YAAA,CACA,wBAAA,CACA,eAAA","sourcesContent":[".container {\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: rgb(var(--purpletext2-rgb), 0.8);\n  padding-left: 8px;\n  padding-right: 8px;\n}\n\n.wrapper {\n  padding: 24px;\n  border-radius: 16px;\n  background-color: var(--wb);\n  flex-basis: 680px;\n}\n\n.header {\n  margin-bottom: 8px;\n}\n\n.email {\n  margin-bottom: 22px;\n}\n\n.password {\n  margin-bottom: 32px;\n}\n\n.formFooter {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 34px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SignIn-module__container--UwyU8zYQ",
	"wrapper": "SignIn-module__wrapper--ELYSA0hF",
	"header": "SignIn-module__header--CVcCAul+",
	"email": "SignIn-module__email--k1dfU9EY",
	"password": "SignIn-module__password--XWqothKE",
	"formFooter": "SignIn-module__formFooter--WAiVdKIe"
};
export default ___CSS_LOADER_EXPORT___;
