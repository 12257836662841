/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { File } from '../model/dto_asset_common';
import { CBCTSeriesGeometryData } from '../model/dto_common_geometry';

export const protobufPackage = 'com.diagnocat.model';

/**
 * - ReportType_CBCT_Segmentation
 * - ReportType_IOS_Segmentation
 * - ReportType_CBCT_IOS_Superimposition
 * - ReportType_StudioImplant
 * - ReportType_StudioOrtho
 */
export interface AssetContentGeneratedCBCTTile {
  Tile: File | undefined;
  Geometry: CBCTSeriesGeometryData | undefined;
}

export interface AssetContentReportStudioState {
  State: File | undefined;
}

function createBaseAssetContentGeneratedCBCTTile(): AssetContentGeneratedCBCTTile {
  return { Tile: undefined, Geometry: undefined };
}

export const AssetContentGeneratedCBCTTile = {
  encode(
    message: AssetContentGeneratedCBCTTile,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tile !== undefined) {
      File.encode(message.Tile, writer.uint32(10).fork()).ldelim();
    }
    if (message.Geometry !== undefined) {
      CBCTSeriesGeometryData.encode(
        message.Geometry,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTTile {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTTile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tile = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Geometry = CBCTSeriesGeometryData.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTTile {
    return {
      Tile: isSet(object.Tile) ? File.fromJSON(object.Tile) : undefined,
      Geometry: isSet(object.Geometry)
        ? CBCTSeriesGeometryData.fromJSON(object.Geometry)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTTile): unknown {
    const obj: any = {};
    message.Tile !== undefined &&
      (obj.Tile = message.Tile ? File.toJSON(message.Tile) : undefined);
    message.Geometry !== undefined &&
      (obj.Geometry = message.Geometry
        ? CBCTSeriesGeometryData.toJSON(message.Geometry)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentGeneratedCBCTTile>, I>>(
    object: I,
  ): AssetContentGeneratedCBCTTile {
    const message = createBaseAssetContentGeneratedCBCTTile();
    message.Tile =
      object.Tile !== undefined && object.Tile !== null
        ? File.fromPartial(object.Tile)
        : undefined;
    message.Geometry =
      object.Geometry !== undefined && object.Geometry !== null
        ? CBCTSeriesGeometryData.fromPartial(object.Geometry)
        : undefined;
    return message;
  },
};

function createBaseAssetContentReportStudioState(): AssetContentReportStudioState {
  return { State: undefined };
}

export const AssetContentReportStudioState = {
  encode(
    message: AssetContentReportStudioState,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.State !== undefined) {
      File.encode(message.State, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentReportStudioState {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentReportStudioState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.State = File.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentReportStudioState {
    return {
      State: isSet(object.State) ? File.fromJSON(object.State) : undefined,
    };
  },

  toJSON(message: AssetContentReportStudioState): unknown {
    const obj: any = {};
    message.State !== undefined &&
      (obj.State = message.State ? File.toJSON(message.State) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentReportStudioState>, I>>(
    object: I,
  ): AssetContentReportStudioState {
    const message = createBaseAssetContentReportStudioState();
    message.State =
      object.State !== undefined && object.State !== null
        ? File.fromPartial(object.State)
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
