import {
  ApproveReportToothReq,
  ApproveReportToothResp,
  CreateReportToothConditionsReq,
  CreateReportToothConditionsResp,
  DeleteReportReq,
  DisApproveReportToothReq,
  DisApproveReportToothResp,
  ReportServiceClientImpl,
  RequestReportReq,
  ResetReportToothReq,
  SetReportConclusionReq,
  SetReportPrintSettingsReq,
  SetReportSettingsReq,
  SetReportToothCommentReq,
  SetReportToothControlReq,
  SetROIReq,
  SignReportReq,
  SetROIResp,
  ResetReportToothResp,
  SetReportToothCommentResp,
  SignReportResp,
  SetReportConclusionResp,
  ApproveAllTeethAndSignReportReq,
  ApproveAllTeethAndSignReportResp,
  AddToothDisplaySliceReq,
  AddToothDisplaySliceResp,
  RemoveToothDisplaySliceReq,
  RemoveToothDisplaySliceResp,
  SetReportSettingsResp,
  SetReportPrintSettingsResp,
  RequestReportResp,
  DeleteReportResp,
  SetReportToothControlResp,
  SetReportToothNumerationReq,
  SetReportToothNumerationResp,
  SetToothLocalizationCropTopLayerAnnotationsReq,
  SetToothLocalizationCropTopLayerAnnotationsResp,
  SetToothLocalizationCropMedicalImageViewOptionsReq,
  SetToothLocalizationCropMedicalImageViewOptionsResp,
  ChangeToothLocalizationNumerationResp,
  ChangeToothLocalizationNumerationReq,
  SetReportToothConditionsUserDecisionReq,
  SetReportToothConditionsUserDecisionResp,
  SetReportIOXRaySubimageMedicalImageViewOptionsReq,
  SetReportIOXRaySubimageMedicalImageViewOptionsResp,
  SetReportMedicalImageViewOptionsReq,
  SetReportMedicalImageViewOptionsResp,
} from '@/shared/api/protocol_gen/api/core/svc_report';
import api from '@/shared/api/api';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';

export type ReportRequests = keyof ReportServiceClientImpl;

const generateReportThunk = createThunkGenerator<ReportRequests>(
  SliceName.reports,
);

export const setReportToothConditionsUserDecision = generateReportThunk<
  SetReportToothConditionsUserDecisionReq,
  SetReportToothConditionsUserDecisionResp
>(
  'SetReportToothConditionsUserDecision',
  api.report.SetReportToothConditionsUserDecision,
);

export const createReportToothConditions = generateReportThunk<
  CreateReportToothConditionsReq,
  CreateReportToothConditionsResp
>('CreateReportToothConditions', api.report.CreateReportToothConditions);

export const setReportSettings = generateReportThunk<
  SetReportSettingsReq,
  SetReportSettingsResp
>('SetReportSettings', api.report.SetReportSettings);

export const requestReport = generateReportThunk<
  RequestReportReq,
  RequestReportResp
>('RequestReport', api.report.RequestReport);

export const deleteReport = generateReportThunk<
  DeleteReportReq,
  DeleteReportResp
>('DeleteReport', api.report.DeleteReport);

export const setROI = generateReportThunk<SetROIReq, SetROIResp>(
  'SetROI',
  api.report.SetROI,
);

export const setReportToothControl = generateReportThunk<
  SetReportToothControlReq,
  SetReportToothControlResp
>('SetReportToothControl', api.report.SetReportToothControl);

export const setReportConclusion = generateReportThunk<
  SetReportConclusionReq,
  SetReportConclusionResp
>('SetReportConclusion', api.report.SetReportConclusion);

export const setReportPrintSettings = generateReportThunk<
  SetReportPrintSettingsReq,
  SetReportPrintSettingsResp
>('SetReportPrintSettings', api.report.SetReportPrintSettings);

export const signReport = generateReportThunk<SignReportReq, SignReportResp>(
  'SignReport',
  api.report.SignReport,
);

export const setReportToothComment = generateReportThunk<
  SetReportToothCommentReq,
  SetReportToothCommentResp
>('SetReportToothComment', api.report.SetReportToothComment);

export const setReportToothNumeration = generateReportThunk<
  SetReportToothNumerationReq,
  SetReportToothNumerationResp
>('SetReportToothNumeration', api.report.SetReportToothNumeration);

export const resetReportTooth = generateReportThunk<
  ResetReportToothReq,
  ResetReportToothResp
>('ResetReportTooth', api.report.ResetReportTooth);

// Changing teeth number to 404 instead of delete
export const deleteTooth = generateReportThunk<
  ChangeToothLocalizationNumerationReq,
  ChangeToothLocalizationNumerationResp
>(
  'ChangeToothLocalizationNumeration',
  api.report.ChangeToothLocalizationNumeration,
);

export const approveAllTeethAndSignReport = generateReportThunk<
  ApproveAllTeethAndSignReportReq,
  ApproveAllTeethAndSignReportResp
>('ApproveAllTeethAndSignReport', api.report.ApproveAllTeethAndSignReport);

export const setReportToothApproved = generateReportThunk<
  ApproveReportToothReq,
  ApproveReportToothResp
>('ApproveReportTooth', api.report.ApproveReportTooth);

export const setReportToothDisapproved = generateReportThunk<
  DisApproveReportToothReq,
  DisApproveReportToothResp
>('DisApproveReportTooth', api.report.DisApproveReportTooth);

export const addToothDisplaySlice = generateReportThunk<
  AddToothDisplaySliceReq,
  AddToothDisplaySliceResp
>('AddToothDisplaySlice', api.report.AddToothDisplaySlice);

export const removeToothDisplaySlice = generateReportThunk<
  RemoveToothDisplaySliceReq,
  RemoveToothDisplaySliceResp
>('RemoveToothDisplaySlice', api.report.RemoveToothDisplaySlice);

export const setToothLocalizationCropTopLayerAnnotations = generateReportThunk<
  SetToothLocalizationCropTopLayerAnnotationsReq,
  SetToothLocalizationCropTopLayerAnnotationsResp
>(
  'SetToothLocalizationCropTopLayerAnnotations',
  api.report.SetToothLocalizationCropTopLayerAnnotations,
);

export const setToothLocalizationCropMedicalImageViewOptions =
  generateReportThunk<
    SetToothLocalizationCropMedicalImageViewOptionsReq,
    SetToothLocalizationCropMedicalImageViewOptionsResp
  >(
    'SetToothLocalizationCropMedicalImageViewOptions',
    api.report.SetToothLocalizationCropMedicalImageViewOptions,
  );

export const setReportIOXRaySubimageMedicalImageViewOptions =
  generateReportThunk<
    SetReportIOXRaySubimageMedicalImageViewOptionsReq,
    SetReportIOXRaySubimageMedicalImageViewOptionsResp
  >(
    'SetReportIOXRaySubimageMedicalImageViewOptions',
    api.report.SetReportIOXRaySubimageMedicalImageViewOptions,
  );

export const setReportMedicalImageViewOptions = generateReportThunk<
  SetReportMedicalImageViewOptionsReq,
  SetReportMedicalImageViewOptionsResp
>(
  'SetReportMedicalImageViewOptions',
  api.report.SetReportMedicalImageViewOptions,
);
