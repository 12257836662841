// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrganizationInvite-module__modalBody--nL53vU4-{display:flex;flex-direction:column;align-items:center;gap:24px;padding:0 24px 24px}.OrganizationInvite-module__notification--QvSIC81q{text-align:center}.OrganizationInvite-module__awaitCatImage--KgOSLxnf{width:130px;height:130px}.OrganizationInvite-module__input---9FSnMac{width:302px;align-items:center;gap:8px}\n\n.OrganizationInvite-module__input---9FSnMac>span{width:calc(100% + 160px);margin-left:0;text-align:center}\n\n[dir=\"rtl\"] .OrganizationInvite-module__input---9FSnMac>span{margin-left:0;margin-right:0}.OrganizationInvite-module__input---9FSnMac>label{width:100%}.OrganizationInvite-module__addLater--4-fExU5f{color:var(--purpleflat)}", "",{"version":3,"sources":["webpack://./src/pages/OrganizationInvite/OrganizationInvite.module.scss"],"names":[],"mappings":"AAAA,gDACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,QAAA,CACA,mBAAA,CAGF,mDACE,iBAAA,CAGF,oDACE,WAAA,CACA,YAAA,CAGF,4CACE,WAAA,CACA,kBAAA,CACA,OAAA;;AAEA,iDACE,wBAAA,CACA,aAAA,CACA,iBAAA;;AAHF,6DAEE,aAAA,CAAA,cACA,CAGF,kDACE,UAAA,CAIJ,+CACE,uBAAA","sourcesContent":[".modalBody {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 24px;\n  padding: 0 24px 24px;\n}\n\n.notification {\n  text-align: center;\n}\n\n.awaitCatImage {\n  width: 130px;\n  height: 130px;\n}\n\n.input {\n  width: 302px;\n  align-items: center;\n  gap: 8px;\n\n  & > span {\n    width: calc(100% + 160px);\n    margin-left: 0;\n    text-align: center;\n  }\n\n  & > label {\n    width: 100%;\n  }\n}\n\n.addLater {\n  color: var(--purpleflat);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "OrganizationInvite-module__modalBody--nL53vU4-",
	"notification": "OrganizationInvite-module__notification--QvSIC81q",
	"awaitCatImage": "OrganizationInvite-module__awaitCatImage--KgOSLxnf",
	"input": "OrganizationInvite-module__input---9FSnMac",
	"addLater": "OrganizationInvite-module__addLater--4-fExU5f"
};
export default ___CSS_LOADER_EXPORT___;
