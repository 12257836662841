import { useIntl } from 'react-intl';

import { toastCaller } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';
import { ToastErrors, toastErrors } from '@/shared/config';

import * as reportsModel from '../../reports/model';
import * as toothModel from '../model';

export const useAddAndRemoveMedicalImage = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const addMedicalImageToSelected = async (
    assetID: string,
    toothID: string,
  ) => {
    try {
      const currentTooth = await dispatch(
        reportsModel.thunks.addToothDisplaySlice({
          AssetID: assetID,
          ToothID: toothID,
        }),
      ).unwrap();
      dispatch(toothModel.actions.setNewestOne(currentTooth.Tooth));
    } catch (error) {
      const parsedMessage = JSON.parse(error?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };

  const removeMedicalImageFromSelected = async (
    assetId: string,
    toothId: string,
  ) => {
    try {
      const currentTooth = await dispatch(
        reportsModel.thunks.removeToothDisplaySlice({
          AssetID: assetId,
          ToothID: toothId,
        }),
      ).unwrap();
      dispatch(toothModel.actions.setNewestOne(currentTooth.Tooth));
    } catch (error) {
      const parsedMessage = JSON.parse(error?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };

  return { addMedicalImageToSelected, removeMedicalImageFromSelected };
};
