// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".studiesOptions-module__selectOption--5MMYuyTm{display:flex;align-items:center;gap:8px}.studiesOptions-module__selectOption--5MMYuyTm img{max-height:46px;border-radius:24px}.studiesOptions-module__optionId--6SRvXH4W{color:var(--grey9)}", "",{"version":3,"sources":["webpack://./src/entities/study/ui/studiesOptions.module.scss"],"names":[],"mappings":"AAAA,+CACE,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,mDACE,eAAA,CACA,kBAAA,CAIJ,2CACE,kBAAA","sourcesContent":[".selectOption {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n  img {\n    max-height: 46px;\n    border-radius: 24px;\n  }\n}\n\n.optionId {\n  color: var(--grey9);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectOption": "studiesOptions-module__selectOption--5MMYuyTm",
	"optionId": "studiesOptions-module__optionId--6SRvXH4W"
};
export default ___CSS_LOADER_EXPORT___;
