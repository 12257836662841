import { useIntl } from 'react-intl';

import { useAppDispatch } from '@/shared/hooks';
import { StudyType } from '@/shared/api/protocol_gen/model/dto_study';
import { ToastErrors, toastErrors } from '@/shared/config';
import { toastCaller } from '@/shared/ui';
import { ApiError } from '@/shared/api/api';
import { RequestReportReq } from '@/shared/api/protocol_gen/api/core/svc_report';

import { reportsModel } from '@/entities/reports';

export const useNewReport = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  // TODO: add FMX and PANO report request
  const requestPanoOrFMXReport = async (
    studyID: string,
    studyType: StudyType,
  ) => {
    try {
      if (studyType === StudyType.StudyType_IntraoralXRay) {
        const { Report } = await dispatch(
          reportsModel.thunks.requestReport({
            Request: {
              InputIOXRayGP: {
                IntraoralXRayStudyID: studyID,
              },
            },
          } as RequestReportReq),
        ).unwrap();

        dispatch(reportsModel.actions.setOne(Report));
      } else {
        const { Report } = await dispatch(
          reportsModel.thunks.requestReport({
            Request: {
              InputPanoGP: {
                PanoramicXRayStudyID: studyID,
              },
            },
          } as RequestReportReq),
        ).unwrap();

        dispatch(reportsModel.actions.setOne(Report));
      }
    } catch (error) {
      const parsedMessage = JSON.parse(error?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };

  // TODO: add CBCT request report
  const requestCBCTNewReport = async (studyID: string) => {
    try {
      const { Report } = await dispatch(
        reportsModel.thunks.requestReport({
          Request: {
            InputCBCTGP: {
              CBCTStudyID: studyID,
            },
          },
        } as RequestReportReq),
      ).unwrap();

      dispatch(reportsModel.actions.setOne(Report));
    } catch (error) {
      const parsedMessage = JSON.parse(error?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };

  return {
    requestPanoOrFMXReport,
    requestCBCTNewReport,
  };
};
