/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { ProductModel } from '../../api/billing_new/dto_product_new';

export const protobufPackage = 'com.diagnocat.billing_new';

export interface Order {
  ID: string;
  Description: string;
  SequentialID: number;
  AccountID: string;
  Processed: boolean;
  CreatedAt: number;
  Effect: Effect | undefined;
  Billed: Order_BilledOrderKind | undefined;
  Service: Order_ServiceOrderKind | undefined;
}

export interface Order_BilledOrderKind {
  Amount: string;
  Currency: string;
  DueDate: number;
  Invoiced: boolean;
  InvoiceID: string;
  InvoicePDFURL: string;
  Paid: boolean;
  Cancelled: boolean;
  Overdue: boolean;
  DocumentID: string;
  TransactionNumber: string;
  MarkedAsPaid: boolean;
}

export interface Order_ServiceOrderKind {}

export interface Effect {
  ProductRemove: EffectProductRemove | undefined;
  SubscriptionAdd: EffectSubscriptionAdd | undefined;
  SubscriptionRenew: EffectSubscriptionRenew | undefined;
  SubscriptionReplace: EffectSubscriptionReplace | undefined;
  SubscriptionActivate: EffectSubscriptionActivate | undefined;
  AddonAdd: EffectAddonAdd | undefined;
  PackageAdd: EffectPackageAdd | undefined;
  BonusAdd: EffectBonusAdd | undefined;
}

export interface EffectProductRemove {
  RemoveProductStateID: string;
}

export interface EffectSubscriptionAdd {
  Subscription: ProductModel | undefined;
  IsAutoRenewal: boolean;
  IsManualActivation: boolean;
  IsAutoCharge: boolean;
}

export interface EffectSubscriptionRenew {
  RenewSubscriptionStateID: string;
}

export interface EffectSubscriptionActivate {
  ActivateSubscriptionStateID: string;
}

export interface EffectSubscriptionReplace {
  ReplaceSubscriptionStateID: string;
  NewSubscription: ProductModel | undefined;
  IsAutoRenewal: boolean;
  IsAutoCharge: boolean;
}

export interface EffectAddonAdd {
  ToSubscriptionStateID: string;
  Addon: ProductModel | undefined;
}

export interface EffectPackageAdd {
  Package: ProductModel | undefined;
}

export interface EffectBonusAdd {
  Addon: ProductModel | undefined;
  ToSubscriptionStateID: string;
  AdditionalMonths: number;
}

function createBaseOrder(): Order {
  return {
    ID: '',
    Description: '',
    SequentialID: 0,
    AccountID: '',
    Processed: false,
    CreatedAt: 0,
    Effect: undefined,
    Billed: undefined,
    Service: undefined,
  };
}

export const Order = {
  encode(message: Order, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Description !== '') {
      writer.uint32(18).string(message.Description);
    }
    if (message.SequentialID !== 0) {
      writer.uint32(24).int64(message.SequentialID);
    }
    if (message.AccountID !== '') {
      writer.uint32(34).string(message.AccountID);
    }
    if (message.Processed === true) {
      writer.uint32(40).bool(message.Processed);
    }
    if (message.CreatedAt !== 0) {
      writer.uint32(48).int64(message.CreatedAt);
    }
    if (message.Effect !== undefined) {
      Effect.encode(message.Effect, writer.uint32(82).fork()).ldelim();
    }
    if (message.Billed !== undefined) {
      Order_BilledOrderKind.encode(
        message.Billed,
        writer.uint32(162).fork(),
      ).ldelim();
    }
    if (message.Service !== undefined) {
      Order_ServiceOrderKind.encode(
        message.Service,
        writer.uint32(170).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Order {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Description = reader.string();
          break;
        case 3:
          message.SequentialID = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.AccountID = reader.string();
          break;
        case 5:
          message.Processed = reader.bool();
          break;
        case 6:
          message.CreatedAt = longToNumber(reader.int64() as Long);
          break;
        case 10:
          message.Effect = Effect.decode(reader, reader.uint32());
          break;
        case 20:
          message.Billed = Order_BilledOrderKind.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 21:
          message.Service = Order_ServiceOrderKind.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Order {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Description: isSet(object.Description) ? String(object.Description) : '',
      SequentialID: isSet(object.SequentialID)
        ? Number(object.SequentialID)
        : 0,
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      Processed: isSet(object.Processed) ? Boolean(object.Processed) : false,
      CreatedAt: isSet(object.CreatedAt) ? Number(object.CreatedAt) : 0,
      Effect: isSet(object.Effect) ? Effect.fromJSON(object.Effect) : undefined,
      Billed: isSet(object.Billed)
        ? Order_BilledOrderKind.fromJSON(object.Billed)
        : undefined,
      Service: isSet(object.Service)
        ? Order_ServiceOrderKind.fromJSON(object.Service)
        : undefined,
    };
  },

  toJSON(message: Order): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.SequentialID !== undefined &&
      (obj.SequentialID = Math.round(message.SequentialID));
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.Processed !== undefined && (obj.Processed = message.Processed);
    message.CreatedAt !== undefined &&
      (obj.CreatedAt = Math.round(message.CreatedAt));
    message.Effect !== undefined &&
      (obj.Effect = message.Effect ? Effect.toJSON(message.Effect) : undefined);
    message.Billed !== undefined &&
      (obj.Billed = message.Billed
        ? Order_BilledOrderKind.toJSON(message.Billed)
        : undefined);
    message.Service !== undefined &&
      (obj.Service = message.Service
        ? Order_ServiceOrderKind.toJSON(message.Service)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Order>, I>>(object: I): Order {
    const message = createBaseOrder();
    message.ID = object.ID ?? '';
    message.Description = object.Description ?? '';
    message.SequentialID = object.SequentialID ?? 0;
    message.AccountID = object.AccountID ?? '';
    message.Processed = object.Processed ?? false;
    message.CreatedAt = object.CreatedAt ?? 0;
    message.Effect =
      object.Effect !== undefined && object.Effect !== null
        ? Effect.fromPartial(object.Effect)
        : undefined;
    message.Billed =
      object.Billed !== undefined && object.Billed !== null
        ? Order_BilledOrderKind.fromPartial(object.Billed)
        : undefined;
    message.Service =
      object.Service !== undefined && object.Service !== null
        ? Order_ServiceOrderKind.fromPartial(object.Service)
        : undefined;
    return message;
  },
};

function createBaseOrder_BilledOrderKind(): Order_BilledOrderKind {
  return {
    Amount: '',
    Currency: '',
    DueDate: 0,
    Invoiced: false,
    InvoiceID: '',
    InvoicePDFURL: '',
    Paid: false,
    Cancelled: false,
    Overdue: false,
    DocumentID: '',
    TransactionNumber: '',
    MarkedAsPaid: false,
  };
}

export const Order_BilledOrderKind = {
  encode(
    message: Order_BilledOrderKind,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Amount !== '') {
      writer.uint32(10).string(message.Amount);
    }
    if (message.Currency !== '') {
      writer.uint32(18).string(message.Currency);
    }
    if (message.DueDate !== 0) {
      writer.uint32(24).int64(message.DueDate);
    }
    if (message.Invoiced === true) {
      writer.uint32(32).bool(message.Invoiced);
    }
    if (message.InvoiceID !== '') {
      writer.uint32(42).string(message.InvoiceID);
    }
    if (message.InvoicePDFURL !== '') {
      writer.uint32(50).string(message.InvoicePDFURL);
    }
    if (message.Paid === true) {
      writer.uint32(56).bool(message.Paid);
    }
    if (message.Cancelled === true) {
      writer.uint32(64).bool(message.Cancelled);
    }
    if (message.Overdue === true) {
      writer.uint32(72).bool(message.Overdue);
    }
    if (message.DocumentID !== '') {
      writer.uint32(82).string(message.DocumentID);
    }
    if (message.TransactionNumber !== '') {
      writer.uint32(90).string(message.TransactionNumber);
    }
    if (message.MarkedAsPaid === true) {
      writer.uint32(96).bool(message.MarkedAsPaid);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Order_BilledOrderKind {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrder_BilledOrderKind();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Amount = reader.string();
          break;
        case 2:
          message.Currency = reader.string();
          break;
        case 3:
          message.DueDate = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.Invoiced = reader.bool();
          break;
        case 5:
          message.InvoiceID = reader.string();
          break;
        case 6:
          message.InvoicePDFURL = reader.string();
          break;
        case 7:
          message.Paid = reader.bool();
          break;
        case 8:
          message.Cancelled = reader.bool();
          break;
        case 9:
          message.Overdue = reader.bool();
          break;
        case 10:
          message.DocumentID = reader.string();
          break;
        case 11:
          message.TransactionNumber = reader.string();
          break;
        case 12:
          message.MarkedAsPaid = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Order_BilledOrderKind {
    return {
      Amount: isSet(object.Amount) ? String(object.Amount) : '',
      Currency: isSet(object.Currency) ? String(object.Currency) : '',
      DueDate: isSet(object.DueDate) ? Number(object.DueDate) : 0,
      Invoiced: isSet(object.Invoiced) ? Boolean(object.Invoiced) : false,
      InvoiceID: isSet(object.InvoiceID) ? String(object.InvoiceID) : '',
      InvoicePDFURL: isSet(object.InvoicePDFURL)
        ? String(object.InvoicePDFURL)
        : '',
      Paid: isSet(object.Paid) ? Boolean(object.Paid) : false,
      Cancelled: isSet(object.Cancelled) ? Boolean(object.Cancelled) : false,
      Overdue: isSet(object.Overdue) ? Boolean(object.Overdue) : false,
      DocumentID: isSet(object.DocumentID) ? String(object.DocumentID) : '',
      TransactionNumber: isSet(object.TransactionNumber)
        ? String(object.TransactionNumber)
        : '',
      MarkedAsPaid: isSet(object.MarkedAsPaid)
        ? Boolean(object.MarkedAsPaid)
        : false,
    };
  },

  toJSON(message: Order_BilledOrderKind): unknown {
    const obj: any = {};
    message.Amount !== undefined && (obj.Amount = message.Amount);
    message.Currency !== undefined && (obj.Currency = message.Currency);
    message.DueDate !== undefined &&
      (obj.DueDate = Math.round(message.DueDate));
    message.Invoiced !== undefined && (obj.Invoiced = message.Invoiced);
    message.InvoiceID !== undefined && (obj.InvoiceID = message.InvoiceID);
    message.InvoicePDFURL !== undefined &&
      (obj.InvoicePDFURL = message.InvoicePDFURL);
    message.Paid !== undefined && (obj.Paid = message.Paid);
    message.Cancelled !== undefined && (obj.Cancelled = message.Cancelled);
    message.Overdue !== undefined && (obj.Overdue = message.Overdue);
    message.DocumentID !== undefined && (obj.DocumentID = message.DocumentID);
    message.TransactionNumber !== undefined &&
      (obj.TransactionNumber = message.TransactionNumber);
    message.MarkedAsPaid !== undefined &&
      (obj.MarkedAsPaid = message.MarkedAsPaid);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Order_BilledOrderKind>, I>>(
    object: I,
  ): Order_BilledOrderKind {
    const message = createBaseOrder_BilledOrderKind();
    message.Amount = object.Amount ?? '';
    message.Currency = object.Currency ?? '';
    message.DueDate = object.DueDate ?? 0;
    message.Invoiced = object.Invoiced ?? false;
    message.InvoiceID = object.InvoiceID ?? '';
    message.InvoicePDFURL = object.InvoicePDFURL ?? '';
    message.Paid = object.Paid ?? false;
    message.Cancelled = object.Cancelled ?? false;
    message.Overdue = object.Overdue ?? false;
    message.DocumentID = object.DocumentID ?? '';
    message.TransactionNumber = object.TransactionNumber ?? '';
    message.MarkedAsPaid = object.MarkedAsPaid ?? false;
    return message;
  },
};

function createBaseOrder_ServiceOrderKind(): Order_ServiceOrderKind {
  return {};
}

export const Order_ServiceOrderKind = {
  encode(
    _: Order_ServiceOrderKind,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Order_ServiceOrderKind {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrder_ServiceOrderKind();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): Order_ServiceOrderKind {
    return {};
  },

  toJSON(_: Order_ServiceOrderKind): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Order_ServiceOrderKind>, I>>(
    _: I,
  ): Order_ServiceOrderKind {
    const message = createBaseOrder_ServiceOrderKind();
    return message;
  },
};

function createBaseEffect(): Effect {
  return {
    ProductRemove: undefined,
    SubscriptionAdd: undefined,
    SubscriptionRenew: undefined,
    SubscriptionReplace: undefined,
    SubscriptionActivate: undefined,
    AddonAdd: undefined,
    PackageAdd: undefined,
    BonusAdd: undefined,
  };
}

export const Effect = {
  encode(
    message: Effect,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ProductRemove !== undefined) {
      EffectProductRemove.encode(
        message.ProductRemove,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.SubscriptionAdd !== undefined) {
      EffectSubscriptionAdd.encode(
        message.SubscriptionAdd,
        writer.uint32(162).fork(),
      ).ldelim();
    }
    if (message.SubscriptionRenew !== undefined) {
      EffectSubscriptionRenew.encode(
        message.SubscriptionRenew,
        writer.uint32(170).fork(),
      ).ldelim();
    }
    if (message.SubscriptionReplace !== undefined) {
      EffectSubscriptionReplace.encode(
        message.SubscriptionReplace,
        writer.uint32(178).fork(),
      ).ldelim();
    }
    if (message.SubscriptionActivate !== undefined) {
      EffectSubscriptionActivate.encode(
        message.SubscriptionActivate,
        writer.uint32(186).fork(),
      ).ldelim();
    }
    if (message.AddonAdd !== undefined) {
      EffectAddonAdd.encode(
        message.AddonAdd,
        writer.uint32(322).fork(),
      ).ldelim();
    }
    if (message.PackageAdd !== undefined) {
      EffectPackageAdd.encode(
        message.PackageAdd,
        writer.uint32(330).fork(),
      ).ldelim();
    }
    if (message.BonusAdd !== undefined) {
      EffectBonusAdd.encode(
        message.BonusAdd,
        writer.uint32(338).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Effect {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEffect();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ProductRemove = EffectProductRemove.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 20:
          message.SubscriptionAdd = EffectSubscriptionAdd.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 21:
          message.SubscriptionRenew = EffectSubscriptionRenew.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 22:
          message.SubscriptionReplace = EffectSubscriptionReplace.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 23:
          message.SubscriptionActivate = EffectSubscriptionActivate.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 40:
          message.AddonAdd = EffectAddonAdd.decode(reader, reader.uint32());
          break;
        case 41:
          message.PackageAdd = EffectPackageAdd.decode(reader, reader.uint32());
          break;
        case 42:
          message.BonusAdd = EffectBonusAdd.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Effect {
    return {
      ProductRemove: isSet(object.ProductRemove)
        ? EffectProductRemove.fromJSON(object.ProductRemove)
        : undefined,
      SubscriptionAdd: isSet(object.SubscriptionAdd)
        ? EffectSubscriptionAdd.fromJSON(object.SubscriptionAdd)
        : undefined,
      SubscriptionRenew: isSet(object.SubscriptionRenew)
        ? EffectSubscriptionRenew.fromJSON(object.SubscriptionRenew)
        : undefined,
      SubscriptionReplace: isSet(object.SubscriptionReplace)
        ? EffectSubscriptionReplace.fromJSON(object.SubscriptionReplace)
        : undefined,
      SubscriptionActivate: isSet(object.SubscriptionActivate)
        ? EffectSubscriptionActivate.fromJSON(object.SubscriptionActivate)
        : undefined,
      AddonAdd: isSet(object.AddonAdd)
        ? EffectAddonAdd.fromJSON(object.AddonAdd)
        : undefined,
      PackageAdd: isSet(object.PackageAdd)
        ? EffectPackageAdd.fromJSON(object.PackageAdd)
        : undefined,
      BonusAdd: isSet(object.BonusAdd)
        ? EffectBonusAdd.fromJSON(object.BonusAdd)
        : undefined,
    };
  },

  toJSON(message: Effect): unknown {
    const obj: any = {};
    message.ProductRemove !== undefined &&
      (obj.ProductRemove = message.ProductRemove
        ? EffectProductRemove.toJSON(message.ProductRemove)
        : undefined);
    message.SubscriptionAdd !== undefined &&
      (obj.SubscriptionAdd = message.SubscriptionAdd
        ? EffectSubscriptionAdd.toJSON(message.SubscriptionAdd)
        : undefined);
    message.SubscriptionRenew !== undefined &&
      (obj.SubscriptionRenew = message.SubscriptionRenew
        ? EffectSubscriptionRenew.toJSON(message.SubscriptionRenew)
        : undefined);
    message.SubscriptionReplace !== undefined &&
      (obj.SubscriptionReplace = message.SubscriptionReplace
        ? EffectSubscriptionReplace.toJSON(message.SubscriptionReplace)
        : undefined);
    message.SubscriptionActivate !== undefined &&
      (obj.SubscriptionActivate = message.SubscriptionActivate
        ? EffectSubscriptionActivate.toJSON(message.SubscriptionActivate)
        : undefined);
    message.AddonAdd !== undefined &&
      (obj.AddonAdd = message.AddonAdd
        ? EffectAddonAdd.toJSON(message.AddonAdd)
        : undefined);
    message.PackageAdd !== undefined &&
      (obj.PackageAdd = message.PackageAdd
        ? EffectPackageAdd.toJSON(message.PackageAdd)
        : undefined);
    message.BonusAdd !== undefined &&
      (obj.BonusAdd = message.BonusAdd
        ? EffectBonusAdd.toJSON(message.BonusAdd)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Effect>, I>>(object: I): Effect {
    const message = createBaseEffect();
    message.ProductRemove =
      object.ProductRemove !== undefined && object.ProductRemove !== null
        ? EffectProductRemove.fromPartial(object.ProductRemove)
        : undefined;
    message.SubscriptionAdd =
      object.SubscriptionAdd !== undefined && object.SubscriptionAdd !== null
        ? EffectSubscriptionAdd.fromPartial(object.SubscriptionAdd)
        : undefined;
    message.SubscriptionRenew =
      object.SubscriptionRenew !== undefined &&
      object.SubscriptionRenew !== null
        ? EffectSubscriptionRenew.fromPartial(object.SubscriptionRenew)
        : undefined;
    message.SubscriptionReplace =
      object.SubscriptionReplace !== undefined &&
      object.SubscriptionReplace !== null
        ? EffectSubscriptionReplace.fromPartial(object.SubscriptionReplace)
        : undefined;
    message.SubscriptionActivate =
      object.SubscriptionActivate !== undefined &&
      object.SubscriptionActivate !== null
        ? EffectSubscriptionActivate.fromPartial(object.SubscriptionActivate)
        : undefined;
    message.AddonAdd =
      object.AddonAdd !== undefined && object.AddonAdd !== null
        ? EffectAddonAdd.fromPartial(object.AddonAdd)
        : undefined;
    message.PackageAdd =
      object.PackageAdd !== undefined && object.PackageAdd !== null
        ? EffectPackageAdd.fromPartial(object.PackageAdd)
        : undefined;
    message.BonusAdd =
      object.BonusAdd !== undefined && object.BonusAdd !== null
        ? EffectBonusAdd.fromPartial(object.BonusAdd)
        : undefined;
    return message;
  },
};

function createBaseEffectProductRemove(): EffectProductRemove {
  return { RemoveProductStateID: '' };
}

export const EffectProductRemove = {
  encode(
    message: EffectProductRemove,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.RemoveProductStateID !== '') {
      writer.uint32(10).string(message.RemoveProductStateID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EffectProductRemove {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEffectProductRemove();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.RemoveProductStateID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EffectProductRemove {
    return {
      RemoveProductStateID: isSet(object.RemoveProductStateID)
        ? String(object.RemoveProductStateID)
        : '',
    };
  },

  toJSON(message: EffectProductRemove): unknown {
    const obj: any = {};
    message.RemoveProductStateID !== undefined &&
      (obj.RemoveProductStateID = message.RemoveProductStateID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EffectProductRemove>, I>>(
    object: I,
  ): EffectProductRemove {
    const message = createBaseEffectProductRemove();
    message.RemoveProductStateID = object.RemoveProductStateID ?? '';
    return message;
  },
};

function createBaseEffectSubscriptionAdd(): EffectSubscriptionAdd {
  return {
    Subscription: undefined,
    IsAutoRenewal: false,
    IsManualActivation: false,
    IsAutoCharge: false,
  };
}

export const EffectSubscriptionAdd = {
  encode(
    message: EffectSubscriptionAdd,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Subscription !== undefined) {
      ProductModel.encode(
        message.Subscription,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.IsAutoRenewal === true) {
      writer.uint32(16).bool(message.IsAutoRenewal);
    }
    if (message.IsManualActivation === true) {
      writer.uint32(24).bool(message.IsManualActivation);
    }
    if (message.IsAutoCharge === true) {
      writer.uint32(32).bool(message.IsAutoCharge);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): EffectSubscriptionAdd {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEffectSubscriptionAdd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Subscription = ProductModel.decode(reader, reader.uint32());
          break;
        case 2:
          message.IsAutoRenewal = reader.bool();
          break;
        case 3:
          message.IsManualActivation = reader.bool();
          break;
        case 4:
          message.IsAutoCharge = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EffectSubscriptionAdd {
    return {
      Subscription: isSet(object.Subscription)
        ? ProductModel.fromJSON(object.Subscription)
        : undefined,
      IsAutoRenewal: isSet(object.IsAutoRenewal)
        ? Boolean(object.IsAutoRenewal)
        : false,
      IsManualActivation: isSet(object.IsManualActivation)
        ? Boolean(object.IsManualActivation)
        : false,
      IsAutoCharge: isSet(object.IsAutoCharge)
        ? Boolean(object.IsAutoCharge)
        : false,
    };
  },

  toJSON(message: EffectSubscriptionAdd): unknown {
    const obj: any = {};
    message.Subscription !== undefined &&
      (obj.Subscription = message.Subscription
        ? ProductModel.toJSON(message.Subscription)
        : undefined);
    message.IsAutoRenewal !== undefined &&
      (obj.IsAutoRenewal = message.IsAutoRenewal);
    message.IsManualActivation !== undefined &&
      (obj.IsManualActivation = message.IsManualActivation);
    message.IsAutoCharge !== undefined &&
      (obj.IsAutoCharge = message.IsAutoCharge);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EffectSubscriptionAdd>, I>>(
    object: I,
  ): EffectSubscriptionAdd {
    const message = createBaseEffectSubscriptionAdd();
    message.Subscription =
      object.Subscription !== undefined && object.Subscription !== null
        ? ProductModel.fromPartial(object.Subscription)
        : undefined;
    message.IsAutoRenewal = object.IsAutoRenewal ?? false;
    message.IsManualActivation = object.IsManualActivation ?? false;
    message.IsAutoCharge = object.IsAutoCharge ?? false;
    return message;
  },
};

function createBaseEffectSubscriptionRenew(): EffectSubscriptionRenew {
  return { RenewSubscriptionStateID: '' };
}

export const EffectSubscriptionRenew = {
  encode(
    message: EffectSubscriptionRenew,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.RenewSubscriptionStateID !== '') {
      writer.uint32(10).string(message.RenewSubscriptionStateID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): EffectSubscriptionRenew {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEffectSubscriptionRenew();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.RenewSubscriptionStateID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EffectSubscriptionRenew {
    return {
      RenewSubscriptionStateID: isSet(object.RenewSubscriptionStateID)
        ? String(object.RenewSubscriptionStateID)
        : '',
    };
  },

  toJSON(message: EffectSubscriptionRenew): unknown {
    const obj: any = {};
    message.RenewSubscriptionStateID !== undefined &&
      (obj.RenewSubscriptionStateID = message.RenewSubscriptionStateID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EffectSubscriptionRenew>, I>>(
    object: I,
  ): EffectSubscriptionRenew {
    const message = createBaseEffectSubscriptionRenew();
    message.RenewSubscriptionStateID = object.RenewSubscriptionStateID ?? '';
    return message;
  },
};

function createBaseEffectSubscriptionActivate(): EffectSubscriptionActivate {
  return { ActivateSubscriptionStateID: '' };
}

export const EffectSubscriptionActivate = {
  encode(
    message: EffectSubscriptionActivate,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ActivateSubscriptionStateID !== '') {
      writer.uint32(10).string(message.ActivateSubscriptionStateID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): EffectSubscriptionActivate {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEffectSubscriptionActivate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ActivateSubscriptionStateID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EffectSubscriptionActivate {
    return {
      ActivateSubscriptionStateID: isSet(object.ActivateSubscriptionStateID)
        ? String(object.ActivateSubscriptionStateID)
        : '',
    };
  },

  toJSON(message: EffectSubscriptionActivate): unknown {
    const obj: any = {};
    message.ActivateSubscriptionStateID !== undefined &&
      (obj.ActivateSubscriptionStateID = message.ActivateSubscriptionStateID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EffectSubscriptionActivate>, I>>(
    object: I,
  ): EffectSubscriptionActivate {
    const message = createBaseEffectSubscriptionActivate();
    message.ActivateSubscriptionStateID =
      object.ActivateSubscriptionStateID ?? '';
    return message;
  },
};

function createBaseEffectSubscriptionReplace(): EffectSubscriptionReplace {
  return {
    ReplaceSubscriptionStateID: '',
    NewSubscription: undefined,
    IsAutoRenewal: false,
    IsAutoCharge: false,
  };
}

export const EffectSubscriptionReplace = {
  encode(
    message: EffectSubscriptionReplace,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReplaceSubscriptionStateID !== '') {
      writer.uint32(10).string(message.ReplaceSubscriptionStateID);
    }
    if (message.NewSubscription !== undefined) {
      ProductModel.encode(
        message.NewSubscription,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.IsAutoRenewal === true) {
      writer.uint32(24).bool(message.IsAutoRenewal);
    }
    if (message.IsAutoCharge === true) {
      writer.uint32(32).bool(message.IsAutoCharge);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): EffectSubscriptionReplace {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEffectSubscriptionReplace();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReplaceSubscriptionStateID = reader.string();
          break;
        case 2:
          message.NewSubscription = ProductModel.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          message.IsAutoRenewal = reader.bool();
          break;
        case 4:
          message.IsAutoCharge = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EffectSubscriptionReplace {
    return {
      ReplaceSubscriptionStateID: isSet(object.ReplaceSubscriptionStateID)
        ? String(object.ReplaceSubscriptionStateID)
        : '',
      NewSubscription: isSet(object.NewSubscription)
        ? ProductModel.fromJSON(object.NewSubscription)
        : undefined,
      IsAutoRenewal: isSet(object.IsAutoRenewal)
        ? Boolean(object.IsAutoRenewal)
        : false,
      IsAutoCharge: isSet(object.IsAutoCharge)
        ? Boolean(object.IsAutoCharge)
        : false,
    };
  },

  toJSON(message: EffectSubscriptionReplace): unknown {
    const obj: any = {};
    message.ReplaceSubscriptionStateID !== undefined &&
      (obj.ReplaceSubscriptionStateID = message.ReplaceSubscriptionStateID);
    message.NewSubscription !== undefined &&
      (obj.NewSubscription = message.NewSubscription
        ? ProductModel.toJSON(message.NewSubscription)
        : undefined);
    message.IsAutoRenewal !== undefined &&
      (obj.IsAutoRenewal = message.IsAutoRenewal);
    message.IsAutoCharge !== undefined &&
      (obj.IsAutoCharge = message.IsAutoCharge);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EffectSubscriptionReplace>, I>>(
    object: I,
  ): EffectSubscriptionReplace {
    const message = createBaseEffectSubscriptionReplace();
    message.ReplaceSubscriptionStateID =
      object.ReplaceSubscriptionStateID ?? '';
    message.NewSubscription =
      object.NewSubscription !== undefined && object.NewSubscription !== null
        ? ProductModel.fromPartial(object.NewSubscription)
        : undefined;
    message.IsAutoRenewal = object.IsAutoRenewal ?? false;
    message.IsAutoCharge = object.IsAutoCharge ?? false;
    return message;
  },
};

function createBaseEffectAddonAdd(): EffectAddonAdd {
  return { ToSubscriptionStateID: '', Addon: undefined };
}

export const EffectAddonAdd = {
  encode(
    message: EffectAddonAdd,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToSubscriptionStateID !== '') {
      writer.uint32(10).string(message.ToSubscriptionStateID);
    }
    if (message.Addon !== undefined) {
      ProductModel.encode(message.Addon, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EffectAddonAdd {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEffectAddonAdd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToSubscriptionStateID = reader.string();
          break;
        case 2:
          message.Addon = ProductModel.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EffectAddonAdd {
    return {
      ToSubscriptionStateID: isSet(object.ToSubscriptionStateID)
        ? String(object.ToSubscriptionStateID)
        : '',
      Addon: isSet(object.Addon)
        ? ProductModel.fromJSON(object.Addon)
        : undefined,
    };
  },

  toJSON(message: EffectAddonAdd): unknown {
    const obj: any = {};
    message.ToSubscriptionStateID !== undefined &&
      (obj.ToSubscriptionStateID = message.ToSubscriptionStateID);
    message.Addon !== undefined &&
      (obj.Addon = message.Addon
        ? ProductModel.toJSON(message.Addon)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EffectAddonAdd>, I>>(
    object: I,
  ): EffectAddonAdd {
    const message = createBaseEffectAddonAdd();
    message.ToSubscriptionStateID = object.ToSubscriptionStateID ?? '';
    message.Addon =
      object.Addon !== undefined && object.Addon !== null
        ? ProductModel.fromPartial(object.Addon)
        : undefined;
    return message;
  },
};

function createBaseEffectPackageAdd(): EffectPackageAdd {
  return { Package: undefined };
}

export const EffectPackageAdd = {
  encode(
    message: EffectPackageAdd,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Package !== undefined) {
      ProductModel.encode(message.Package, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EffectPackageAdd {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEffectPackageAdd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Package = ProductModel.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EffectPackageAdd {
    return {
      Package: isSet(object.Package)
        ? ProductModel.fromJSON(object.Package)
        : undefined,
    };
  },

  toJSON(message: EffectPackageAdd): unknown {
    const obj: any = {};
    message.Package !== undefined &&
      (obj.Package = message.Package
        ? ProductModel.toJSON(message.Package)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EffectPackageAdd>, I>>(
    object: I,
  ): EffectPackageAdd {
    const message = createBaseEffectPackageAdd();
    message.Package =
      object.Package !== undefined && object.Package !== null
        ? ProductModel.fromPartial(object.Package)
        : undefined;
    return message;
  },
};

function createBaseEffectBonusAdd(): EffectBonusAdd {
  return { Addon: undefined, ToSubscriptionStateID: '', AdditionalMonths: 0 };
}

export const EffectBonusAdd = {
  encode(
    message: EffectBonusAdd,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Addon !== undefined) {
      ProductModel.encode(message.Addon, writer.uint32(10).fork()).ldelim();
    }
    if (message.ToSubscriptionStateID !== '') {
      writer.uint32(18).string(message.ToSubscriptionStateID);
    }
    if (message.AdditionalMonths !== 0) {
      writer.uint32(24).int32(message.AdditionalMonths);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EffectBonusAdd {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEffectBonusAdd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Addon = ProductModel.decode(reader, reader.uint32());
          break;
        case 2:
          message.ToSubscriptionStateID = reader.string();
          break;
        case 3:
          message.AdditionalMonths = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EffectBonusAdd {
    return {
      Addon: isSet(object.Addon)
        ? ProductModel.fromJSON(object.Addon)
        : undefined,
      ToSubscriptionStateID: isSet(object.ToSubscriptionStateID)
        ? String(object.ToSubscriptionStateID)
        : '',
      AdditionalMonths: isSet(object.AdditionalMonths)
        ? Number(object.AdditionalMonths)
        : 0,
    };
  },

  toJSON(message: EffectBonusAdd): unknown {
    const obj: any = {};
    message.Addon !== undefined &&
      (obj.Addon = message.Addon
        ? ProductModel.toJSON(message.Addon)
        : undefined);
    message.ToSubscriptionStateID !== undefined &&
      (obj.ToSubscriptionStateID = message.ToSubscriptionStateID);
    message.AdditionalMonths !== undefined &&
      (obj.AdditionalMonths = Math.round(message.AdditionalMonths));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EffectBonusAdd>, I>>(
    object: I,
  ): EffectBonusAdd {
    const message = createBaseEffectBonusAdd();
    message.Addon =
      object.Addon !== undefined && object.Addon !== null
        ? ProductModel.fromPartial(object.Addon)
        : undefined;
    message.ToSubscriptionStateID = object.ToSubscriptionStateID ?? '';
    message.AdditionalMonths = object.AdditionalMonths ?? 0;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
