// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Addons-module__container--tY25J1cE{padding:24px;background-color:var(--bg4);border-radius:16px}.Addons-module__header--u01UApRa{padding-bottom:8px;margin-bottom:24px;border-bottom:1px solid var(--mainbg)}", "",{"version":3,"sources":["webpack://./src/widgets/CurrentSubscription/ui/Addons/Addons.module.scss"],"names":[],"mappings":"AAAA,oCACE,YAAA,CACA,2BAAA,CACA,kBAAA,CAGF,iCACE,kBAAA,CACA,kBAAA,CACA,qCAAA","sourcesContent":[".container {\n  padding: 24px;\n  background-color: var(--bg4);\n  border-radius: 16px;\n}\n\n.header {\n  padding-bottom: 8px;\n  margin-bottom: 24px;\n  border-bottom: 1px solid var(--mainbg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Addons-module__container--tY25J1cE",
	"header": "Addons-module__header--u01UApRa"
};
export default ___CSS_LOADER_EXPORT___;
