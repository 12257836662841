/* eslint-disable */
import { grpc } from '@improbable-eng/grpc-web';
import { BrowserHeaders } from 'browser-headers';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { BASE_URI, ENVIRONMENT } from '@/shared/config/config';

import { MessengerClientImpl } from './protocol_gen/api/messenger/svc_messenger';
import { AccessServiceClientImpl } from './protocol_gen/api/core/svc_access';
import { PatientServiceClientImpl } from './protocol_gen/api/core/svc_patient';
import { UserServiceClientImpl } from './protocol_gen/api/core/svc_user';
import { PatientTaskServiceClientImpl } from './protocol_gen/api/core/svc_patient_task';
import { NotificationsClientImpl } from './protocol_gen/api/notifications/svc_notifications';
import { ReportServiceClientImpl } from './protocol_gen/api/core/svc_report';
import { OrganizationServiceClientImpl } from './protocol_gen/api/core/svc_organization';
import { StorageServiceClientImpl } from './protocol_gen/api/core/svc_upload';
import { AuthenticationClientImpl } from './protocol_gen/api/auth/svc_authentication';
import { AssetServiceClientImpl } from './protocol_gen/api/core/svc_asset';
import { QuestionnaireServiceClientImpl } from './protocol_gen/api/marketing/svc_questionnaire';
import { IntegrationServiceClientImpl } from './protocol_gen/api/marketing/svc_integration';
import { StudyServiceClientImpl } from './protocol_gen/api/core/svc_study';
import { BillingClientImpl } from './protocol_gen/api/billing_new/svc_billing_new';

const isDev = ['staging', 'development'].includes(ENVIRONMENT);

const grpcWebEndpoint = `${BASE_URI}/api/grpcweb_ws`;

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

export class ApiError {
  message: string;

  code: number;

  metadata: grpc.Metadata | undefined;

  type:
    | 'UnauthenticatedError'
    | 'PermissionDeniedError'
    | 'ConcurrencyError'
    | 'AlreadyExistError'
    | 'NotFoundError'
    | 'InvalidArgumentValueError'
    | 'RequiredArgumentMissingError'
    | 'UnknownError'
    | 'InternalServerError';

  constructor(
    message: string,
    code: number,
    metadata: grpc.Metadata | undefined,
  ) {
    this.message = message;
    this.code = code;
    this.metadata = metadata;

    try {
      const m = JSON.parse(message);
      this.type = m.type;
    } catch (e) {
      this.type = 'UnknownError';
    }
  }
}

export class Impl {
  private host: string;

  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  async unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options?.metadata
        ? new BrowserHeaders({
            ...this.options.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;

    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd(response) {
          const reportError = () => {
            const err = new ApiError(
              response.statusMessage,
              response.status,
              response.trailers,
            );
            reject(err);
          };

          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else if (response.status === grpc.Code.Unauthenticated) {
            localStorage.removeItem('user');
            window.dispatchEvent(new Event('storage'));
            reportError();
          } else {
            reportError();
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
    const upStreamCodes = [2, 4, 8, 9, 10, 14, 15];
    const DEFAULT_TIMEOUT_TIME = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options?.metadata
        ? new BrowserHeaders({
            ...this.options.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;

    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => {
            return observer.next(next);
          },
          onEnd: (code: grpc.Code, message: string) => {
            const reportError = () =>
              observer.error(new ApiError(message, code, undefined));
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else if (code === grpc.Code.Unauthenticated) {
              localStorage.removeItem('user');
              window.dispatchEvent(new Event('storage'));
              reportError();
            } else {
              reportError();
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

const enableDebug =
  window.location.hostname.search('staging.diagnocat.dev') !== -1 ||
  window.location.hostname.search('dev.diagnocat.dev') !== -1 ||
  window.location.hostname.search('localhost') !== -1 ||
  window.location.hostname.search('127.0.0.1') !== -1 ||
  isDev;

const transport = grpc.XhrTransport({
  withCredentials: true,
});

// const transport = grpc.FetchReadableStreamTransport({
//   credentials: "include",
//   mode: "no-cors",
//   cache: "no-cache",
//   keepalive: true,
// });

const rpc = new Impl(grpcWebEndpoint, {
  debug: enableDebug,
  metadata: new grpc.Metadata({}),
  transport: transport,
  streamingTransport: transport,
});

export default {
  user: new UserServiceClientImpl(rpc),
  access: new AccessServiceClientImpl(rpc),
  patient: new PatientServiceClientImpl(rpc),
  patientTask: new PatientTaskServiceClientImpl(rpc),
  messenger: new MessengerClientImpl(rpc),
  billing: new BillingClientImpl(rpc),
  notifications: new NotificationsClientImpl(rpc),
  report: new ReportServiceClientImpl(rpc),
  organization: new OrganizationServiceClientImpl(rpc),
  // StorageServiceClientImpl contains the study entity
  storage: new StorageServiceClientImpl(rpc),
  study: new StudyServiceClientImpl(rpc),
  auth: new AuthenticationClientImpl(rpc),
  assets: new AssetServiceClientImpl(rpc),
  marketing: new QuestionnaireServiceClientImpl(rpc),
  hubspot: new IntegrationServiceClientImpl(rpc),
};

/* eslint-enable */
