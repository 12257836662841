// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MPRWidget-module__controls--Mrhq5h28,.MPRWidget-module__extendedControls--KIo8XJ7L{display:flex;align-items:center}.MPRWidget-module__controls--Mrhq5h28{justify-content:space-between;margin-block:8px}.MPRWidget-module__extendedControls--KIo8XJ7L{gap:12px}.MPRWidget-module__mprViewer--OHBl4bSP{display:flex;border-radius:16px;overflow:hidden}.MPRWidget-module__zoom--05Ab8TT7{cursor:zoom-in}.MPRWidget-module__pan--PGid9rpF{cursor:grab}.MPRWidget-module__move--dH\\+5iHy9{cursor:all-scroll}.MPRWidget-module__brightness--6XHx0W-E{cursor:row-resize}.MPRWidget-module__noScroll--ZlqQPVte{pointer-events:none}", "",{"version":3,"sources":["webpack://./src/widgets/MPR/ui/MPRWidget/MPRWidget.module.scss"],"names":[],"mappings":"AAAA,oFAEE,YAAA,CACA,kBAAA,CAGF,sCACE,6BAAA,CACA,gBAAA,CAGF,8CACE,QAAA,CAGF,uCACE,YAAA,CACA,kBAAA,CACA,eAAA,CAGF,kCACE,cAAA,CAGF,iCACE,WAAA,CAGF,mCACE,iBAAA,CAGF,wCACE,iBAAA,CAGF,sCACE,mBAAA","sourcesContent":[".controls,\n.extendedControls {\n  display: flex;\n  align-items: center;\n}\n\n.controls {\n  justify-content: space-between;\n  margin-block: 8px;\n}\n\n.extendedControls {\n  gap: 12px;\n}\n\n.mprViewer {\n  display: flex;\n  border-radius: 16px;\n  overflow: hidden;\n}\n\n.zoom {\n  cursor: zoom-in;\n}\n\n.pan {\n  cursor: grab;\n}\n\n.move {\n  cursor: all-scroll;\n}\n\n.brightness {\n  cursor: row-resize;\n}\n\n.noScroll {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls": "MPRWidget-module__controls--Mrhq5h28",
	"extendedControls": "MPRWidget-module__extendedControls--KIo8XJ7L",
	"mprViewer": "MPRWidget-module__mprViewer--OHBl4bSP",
	"zoom": "MPRWidget-module__zoom--05Ab8TT7",
	"pan": "MPRWidget-module__pan--PGid9rpF",
	"move": "MPRWidget-module__move--dH+5iHy9",
	"brightness": "MPRWidget-module__brightness--6XHx0W-E",
	"noScroll": "MPRWidget-module__noScroll--ZlqQPVte"
};
export default ___CSS_LOADER_EXPORT___;
