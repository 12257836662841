// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RemoveAssetModal-module__modalBody--1bdG9f6v{padding-top:12px}", "",{"version":3,"sources":["webpack://./src/features/removeAsset/ui/RemoveAssetModal/RemoveAssetModal.module.scss"],"names":[],"mappings":"AAAA,8CACE,gBAAA","sourcesContent":[".modalBody {\n  padding-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "RemoveAssetModal-module__modalBody--1bdG9f6v"
};
export default ___CSS_LOADER_EXPORT___;
