import type { AnyAction, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';

import { ApiError } from '@/shared/api/api';

import { authModel } from '@/entities/auth';

import { callInternalServerError } from '@/features/callInternalServerError';

export const errorMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action: AnyAction) => {
    if (isRejectedWithValue(action)) {
      const errorMessage = (action.payload as ApiError).message;
      const errorType = (action.payload as ApiError).type;
      // eslint-disable-next-line no-console
      console.warn(
        `Receive api error: ${errorMessage}, error type: ${errorType}`,
      );
      if (errorType === 'UnauthenticatedError') {
        localStorage.removeItem('accessToken');

        dispatch(authModel.actions.setIsAuthenticated(false));
      }

      if (errorType === 'InternalServerError') {
        // TODO:  [1/l] remove after refactoring errors
        (action.payload as ApiError).message = '';

        callInternalServerError();
      }
    }

    return next(action);
  };
