import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { toothModel, ToothType, ToothTypesSet } from '@/entities/tooth';

import { ToothChartTag } from '../ToothChartTag/ToothChartTag';

import styles from './ToothChartTags.module.scss';

type ToothChartTagsProps = {
  toothTypesSet: ToothTypesSet;
};

type ToothChartTagItem = {
  toothType: ToothType;
  active: boolean;
  amount: number;
  toothIDs: string[];
};

export const ToothChartTags = (props: ToothChartTagsProps) => {
  const { toothTypesSet } = props;

  const dispatch = useAppDispatch();

  const allTeethIDs = useAppSelector(toothModel.selectors.selectAllIds);
  const localROITeethIDs = useAppSelector(
    toothModel.selectors.selectLocalROITeethIDs,
  );

  const toothTags = useMemo(
    () =>
      Object.entries(toothTypesSet).reduce(
        (acc, [toothId, toothType]) => {
          if (toothType in acc) {
            acc[toothType].push(toothId);
          } else {
            acc[toothType] = [toothId];
          }

          return acc;
        },
        { all: allTeethIDs } as Record<ToothType, string[]>,
      ),
    [allTeethIDs, toothTypesSet],
  );

  const toothTagItems = useMemo(
    () =>
      (Object.entries(toothTags) as [ToothType, string[]][]).map(
        ([toothType, toothIDs]): ToothChartTagItem => ({
          toothType,
          active: toothIDs.every((toothID) =>
            localROITeethIDs.includes(toothID),
          ),
          amount: toothIDs.length,
          toothIDs,
        }),
      ),
    [toothTags, localROITeethIDs],
  );

  const handleTagClick = useCallback(
    (toothType: ToothType) => {
      dispatch(toothModel.actions.setLocalROITeethIDs(toothTags[toothType]));
      dispatch(toothModel.thunks.updateCurrentReportROI());
    },
    [dispatch, toothTags],
  );

  return (
    <div className={styles.container}>
      {toothTagItems.map((item) => (
        <ToothChartTag
          key={item.toothType}
          toothType={item.toothType}
          amount={item.amount}
          active={item.active}
          onClick={handleTagClick}
        />
      ))}
    </div>
  );
};
