// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TreatingDoctors-module__container--sgUne\\+rp{flex:1;height:100%;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;gap:10px;padding:12px}.TreatingDoctors-module__header--5mSDfHxz{width:100%;display:flex;justify-content:space-between;align-items:flex-start}.TreatingDoctors-module__doctorsList--BgtITYAM{width:100%}.TreatingDoctors-module__plusIcon--\\+t1lKPYw{color:var(--purpleflat);cursor:pointer}", "",{"version":3,"sources":["webpack://./src/widgets/PatientInfo/ui/TreatingDoctors/TreatingDoctors.module.scss"],"names":[],"mappings":"AAAA,8CACE,MAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,0BAAA,CACA,sBAAA,CACA,QAAA,CACA,YAAA,CAGF,0CACE,UAAA,CACA,YAAA,CACA,6BAAA,CACA,sBAAA,CAGF,+CACE,UAAA,CAGF,6CACE,uBAAA,CACA,cAAA","sourcesContent":[".container {\n  flex: 1;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: 10px;\n  padding: 12px;\n}\n\n.header {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n\n.doctorsList {\n  width: 100%;\n}\n\n.plusIcon {\n  color: var(--purpleflat);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TreatingDoctors-module__container--sgUne+rp",
	"header": "TreatingDoctors-module__header--5mSDfHxz",
	"doctorsList": "TreatingDoctors-module__doctorsList--BgtITYAM",
	"plusIcon": "TreatingDoctors-module__plusIcon--+t1lKPYw"
};
export default ___CSS_LOADER_EXPORT___;
