export enum TOOLS {
  Ruler = 'ruler',
  Arrow = 'arrow',
  '3d' = 'angle',
  Erase = 'eraser',
  EyeFilled = 'view',
  Brightness = 'brightness',
  Shaprness = 'sharpening',
}

export type MedicalImageLabelsTypes =
  | 'frontal'
  | 'axial'
  | 'mesio-distal'
  | 'sagittal'
  | 'vestibulo-oral'
  | 'uploaded';

export type ZoomedSliceModalProps = {
  src: string;
  isOpen: boolean;
  onClose: () => void;
};

export type ConstraintsDimensionType = [number, number];
