import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { equals } from 'ramda';

import { Button, Description, Modal, toastCaller } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import {
  Localization,
  LocalizationType,
} from '@/shared/api/protocol_gen/model/dto_report_localization';
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { AbsoluteBlock } from '@/shared/graphics/RenderComponents/AbsoluteBlock';
import { RCDetectedTeeth } from '@/shared/graphics/RenderComponents/RCDetectedTeeth/RCDetectedTeeth';
import { ApiError } from '@/shared/api/api';
import { DentalNotationFormat } from '@/shared/api/protocol_gen/model/dto_organization';
import { ToothNumeration } from '@/shared/api/protocol_gen/model/dto_report_common';

import { ModalID, modalModel } from '@/entities/modal';
import { getPatientFullName, patientModel } from '@/entities/patient';
import { reportsModel } from '@/entities/reports';
import { studyModel } from '@/entities/study';
import {
  isDuplicate,
  toothModel,
  ToothWithLocalization,
} from '@/entities/tooth';
import { organizationModel } from '@/entities/organization';

import { PanoImage } from '@/widgets/PanoImage';
import { IOXRayMatrixDeprecated } from '@/widgets/IOXRayMatrix';

import { ChangeToothNumberPopup } from '../ChangeToothNumberPopup/ChangeToothNumberPopup';
import { makeDetectionsFromTeeth } from '../../lib/makeDetectionsFromTeeth';
import { makeDetectionsFromDeletedToothLocalizations } from '../../lib/makeDetectionsFromLocalizations';
import { TeethNumberingModalProps } from '../../config/types';

import styles from './TeethNumberingModal.module.scss';

export const TeethNumberingModal: FC<TeethNumberingModalProps> = ({
  title,
  patientID,
  reportID,
  modalWidth,
  report,
}) => {
  const {
    visible,
    data: { image },
  } = useAppSelector((state) => state.modal.TeethNumberingModal);

  const teeth = useAppSelector(toothModel.selectors.selectByReportID(reportID));

  const patient = useAppSelector(
    patientModel.selectors.selectPatientByID(patientID),
  );

  const studyID = report?.SourceStudyIDs?.[0];

  const study = useAppSelector(studyModel.selectors.selectByID(studyID));

  const organization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );

  const [selectedTooth, setSelectedTooth] = useState<
    ToothWithLocalization | undefined
  >(undefined);
  const [isToothRemoving, setIsToothRemoving] = useState(false);
  const [invalidISONumbersInIOXRay, setInvalidISONumbersInIOXRay] =
    useState(false);
  const [animatedToothNumber, setAnimatedToothNumber] = useState<number>(0);

  const panoImageContainer = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const [roiTeeth, setRoiTeeth] = useState<ToothWithLocalization[]>([]);

  const patientFullName = getPatientFullName(patient);

  const reportType = report?.Type;

  const isIOXRayMatrix = reportType === ReportType.ReportType_IOXRay_GP;

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.TeethNumberingModal));
  }, [dispatch]);

  const isPatientLoaded = !!patient;

  const assetID = study?.AssetIDs?.[0];

  const handleToothClick = (tooth?: ToothWithLocalization) => {
    setSelectedTooth(tooth);
  };

  const handleChangeToothNumber = async (
    tooth: ToothWithLocalization,
    toothNumber: number,
  ) => {
    // TODO: [2/m] add SupernumeraryIndex in ToothNumeration, otherwise it will be equal to 0
    try {
      if (tooth.ISONumber !== 404) {
        setAnimatedToothNumber(toothNumber);
        const { Tooth: changedTooth } = await dispatch(
          reportsModel.thunks.setReportToothNumeration({
            ToothID: tooth.toothID,
            ToothNumeration: { ISO: toothNumber } as unknown as ToothNumeration,
          }),
        ).unwrap();

        if (changedTooth) {
          dispatch(toothModel.actions.setOne(changedTooth));
          setAnimatedToothNumber(0);
        }
      } else {
        // its not delete, just updating deleted tooth number
        const { Teeth } = await dispatch(
          reportsModel.thunks.deleteTooth({
            ToothLocalizationID: tooth.Localization.ID,
            Numeration: { ISO: toothNumber } as unknown as ToothNumeration,
          }),
        ).unwrap();

        dispatch(toothModel.actions.addMany(Teeth));
      }
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);

      toastCaller({
        type: 'error',
        heading: 'Error',
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    } finally {
      setSelectedTooth(undefined);
    }
  };

  const handleRemoveTooth = async (tooth: ToothWithLocalization) => {
    setIsToothRemoving(true);
    try {
      const { Teeth } = await dispatch(
        reportsModel.thunks.deleteTooth({
          ToothLocalizationID: tooth.Localization.ID,
          Numeration: { ISO: 404 } as unknown as ToothNumeration,
        }),
      ).unwrap();

      dispatch(toothModel.actions.addMany(Teeth));
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);

      toastCaller({
        type: 'error',
        heading: 'Error',
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    } finally {
      setSelectedTooth(undefined);
      setIsToothRemoving(false);
    }
  };

  const invalidISONumbers = useMemo(
    () => roiTeeth.filter((item) => isDuplicate(roiTeeth, item)),
    [roiTeeth],
  );

  const handleDetectDuplicateInIOXRay = (isInvalid: boolean) => {
    setInvalidISONumbersInIOXRay(isInvalid);
  };

  useEffect(() => {
    if (teeth?.length) {
      const patientTeeth = teeth.reduce((modifiedTeeth, tooth: Tooth) => {
        if (
          tooth?.Localizations?.length &&
          tooth.Localizations.some(
            (localization) =>
              localization.Type === LocalizationType.LocalizationType2D,
          )
        ) {
          modifiedTeeth.push({
            toothID: tooth?.ID,
            ISONumber: tooth.Numeration?.ISO ?? 0,
            Localization:
              tooth?.Localizations.find(
                (localization) =>
                  localization.Type === LocalizationType.LocalizationType2D,
              ) ?? ({} as Localization),
          });
        }

        return modifiedTeeth;
      }, [] as ToothWithLocalization[]);

      if (!equals(patientTeeth, roiTeeth)) {
        setRoiTeeth(patientTeeth);
      }
    }
  }, [teeth, image]);

  const deletedTeethByReportID = useAppSelector(
    toothModel.selectors.selectDeletedByReportID(reportID),
  );

  const localizations = makeDetectionsFromTeeth(roiTeeth);

  const deletedTeethLocalizations = makeDetectionsFromDeletedToothLocalizations(
    deletedTeethByReportID?.Localizations || [],
  );

  return (
    <Modal
      title={title}
      isOpen={visible}
      onClose={handleClose}
      className={styles.modal}
      bodyClassName={styles.modalBody}
      containerClassName={styles.modalContainer}
      hideFooter
      borderless
      modalInlineStyle={{ width: `${modalWidth}px` }}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.imageContainer} ref={panoImageContainer}>
            {isIOXRayMatrix ? (
              <IOXRayMatrixDeprecated
                className={styles.IOXRayMatrix}
                reportID={reportID}
                showDetectedTeeth
                dentalNotationFormat={
                  organization?.Settings?.DentalNotationFormat
                }
                onToothClick={handleToothClick}
                onDetectDuplicateToothNumber={handleDetectDuplicateInIOXRay}
              />
            ) : (
              <PanoImage
                src={image.src}
                controls={[]}
                kind="dicom"
                objectFit="cover"
                style={{
                  width: '100%',
                  height: image.height,
                  objectFit: 'contain',
                }}
                containerWidth={image.width ?? 0}
                fixedHeight={image.height}
                assetID={assetID}
                viewOptions={image.viewOptions}
              />
            )}

            {reportType !== ReportType.ReportType_IOXRay_GP && (
              <AbsoluteBlock style={{ top: 0, left: 0 }}>
                <RCDetectedTeeth
                  deletedTeethLocalizations={deletedTeethLocalizations}
                  localizations={localizations}
                  imageSize={{
                    width: (image?.width ?? 0) / (image?.zoom ?? 0),
                    height: (image?.height ?? 0) / (image?.zoom ?? 0),
                  }}
                  onToothClick={handleToothClick}
                  dentalNotationFormat={
                    organization?.Settings?.DentalNotationFormat ||
                    DentalNotationFormat.DentalNotationFormatISO
                  }
                />
              </AbsoluteBlock>
            )}

            <ChangeToothNumberPopup
              isOpen={!!selectedTooth}
              onChangeToothNumber={handleChangeToothNumber}
              onChangeSelectedTooth={handleToothClick}
              title={title}
              selectedTooth={selectedTooth}
              isToothRemoving={isToothRemoving}
              dentalNotationFormat={
                organization?.Settings?.DentalNotationFormat ||
                DentalNotationFormat.DentalNotationFormatISO
              }
              onRemoveTooth={handleRemoveTooth}
              animatedToothNumber={animatedToothNumber}
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div>
          {isPatientLoaded && (
            <div className={styles.descriptionContainer}>
              <Description
                label={
                  <FormattedMessage
                    id="teethNymbering.patient"
                    defaultMessage="Patient"
                  />
                }
              >
                {patientFullName}
              </Description>{' '}
              <Description
                className={styles.descriptionText}
                label={
                  <FormattedMessage
                    id="teethNymbering.file"
                    defaultMessage="File"
                  />
                }
              >
                {image?.path}
              </Description>
            </div>
          )}
        </div>
        <Button
          size="medium"
          onClick={handleClose}
          disabled={!!invalidISONumbers.length || invalidISONumbersInIOXRay}
          className={styles.approveButton}
        >
          <FormattedMessage id="patient.confirm" defaultMessage="Confirm" />
        </Button>
      </div>
    </Modal>
  );
};
