import { useCallback, useState } from 'react';
import {
  DropzoneProps,
  DropzoneState,
  FileWithPath,
  useDropzone,
} from 'react-dropzone';

export const useUploadStudy = (
  props: DropzoneProps,
): {
  dropzoneState: DropzoneState;
  files: FileWithPath[];
  removeFile: (file: FileWithPath) => void;
  removeAllFiles: () => void;
} => {
  const [files, setFiles] = useState<FileWithPath[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files],
  );

  const removeAllFiles = () => {
    setFiles([]);
  };

  const removeFile = (file: File) => {
    setFiles((prevFiles) =>
      prevFiles.filter((prevFile) => prevFile.name !== file.name),
    );
  };

  const dropzoneState = useDropzone({ ...props, onDrop });

  return {
    dropzoneState,
    files,
    removeAllFiles,
    removeFile,
  };
};
