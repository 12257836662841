import { FC, useState } from 'react';
import cn from 'classnames';

import maskTypeButtonStyleBW from '@/shared/assets/images/showMasksButtonBW.png';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { MaskStyleTypes, reportsModel } from '@/entities/reports';

import { MASKS_TYPES } from '../../config/constants';
import { getMaskImageByType } from '../../utils/getMaskImageByType';

import styles from './SwitchMaskType.module.scss';

type SwitchMaskTypeProps = {
  className?: string;
};

export const SwitchMaskType: FC<SwitchMaskTypeProps> = (props) => {
  const { className } = props;

  const [toggled, setToggled] = useState(false);

  const dispatch = useAppDispatch();

  const maskType = useAppSelector(reportsModel.selectors.selectMaskStyle);

  const showMasks = useAppSelector(reportsModel.selectors.selectShowMasks);

  const handleChangeMaskType = (type: MaskStyleTypes) => {
    if (type === MaskStyleTypes.StyleBW) {
      dispatch(reportsModel.actions.setShowMasks(false));
    } else {
      dispatch(reportsModel.actions.setMaskStyleType(type));
      dispatch(reportsModel.actions.setShowMasks(true));
    }

    setToggled(false);
  };

  const handleBlur = () => {
    setToggled(false);
  };

  return (
    <div
      className={cn(styles.container, className)}
      // TODO: remove tabindex from div
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      onBlur={handleBlur}
    >
      <img
        src={showMasks ? getMaskImageByType(maskType) : maskTypeButtonStyleBW}
        onClick={() => setToggled(!toggled)}
        alt=""
      />
      <div
        className={cn(styles.maskTypesImagesWrapper, toggled && styles.toggled)}
      >
        {MASKS_TYPES.filter((item) => item !== maskType).map((item) => (
          <img
            key={item}
            src={getMaskImageByType(item)}
            onClick={() => handleChangeMaskType(item)}
            alt=""
          />
        ))}
      </div>
    </div>
  );
};
