import { useMemo } from 'react';

import { useAppSelector } from '@/shared/hooks';
import { MedicalImageInterface } from '@/shared/config';
import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';

import { makeAnnotations } from '../lib/makeAnnotations';
import { makeImageViewOptions } from '../lib/makeImageViewOptions';
import { toothModel } from '../../tooth';
import { getFileSrc } from '../lib/getFileSrc';
import * as assetsModel from '../model';
import { TOOTH_PAGE_GROUPS, TOOTH_PAGE_PATHOLOGY_GROUPS } from '../config';

// To find group name you have to check two places and then match the group groupCode
// by one of the two config arrays
const getMedicalImageGroupName = (medicalImage: Asset) => {
  if (medicalImage?.GeneratedReport?.CBCTGPToothSlice?.Orientation) {
    const groupCode =
      medicalImage?.GeneratedReport?.CBCTGPToothSlice?.Orientation;
    return TOOTH_PAGE_GROUPS.find((group) => group.groupCode === groupCode)
      ?.groupName;
  }

  if (medicalImage?.GeneratedReport?.CBCTToothPathologySlice?.Pathology) {
    const groupCode =
      medicalImage?.GeneratedReport?.CBCTToothPathologySlice?.Pathology;
    return TOOTH_PAGE_PATHOLOGY_GROUPS.find(
      (group) => group.groupCode === groupCode,
    )?.groupName;
  }

  return '';
};

export const useGetSelectedMedicalImages = (
  toothID: string,
): MedicalImageInterface[] => {
  const toothAssets = useAppSelector(
    assetsModel.selectors.selectByToothID(toothID),
  );

  const tooth = useAppSelector(toothModel.selectors.selectToothByID(toothID));

  return useMemo(
    () =>
      tooth?.DisplaySlices.reduce((acc, selectedMedicalImageID) => {
        const currentMedicalImage = toothAssets.find(
          (asset) => asset.ID === selectedMedicalImageID,
        );
        const medicalImageID =
          currentMedicalImage?.GeneratedReport?.CBCTGPToothSlice?.Slice?.ID;
        const medicalImagePath =
          currentMedicalImage?.GeneratedReport?.CBCTGPToothSlice?.Slice?.Path;
        const order =
          currentMedicalImage?.GeneratedReport?.CBCTGPToothSlice?.Regular
            ?.Order ?? 0;

        const annotations = makeAnnotations(
          currentMedicalImage?.GeneratedReport?.MedicalImageFeatures
            ?.Annotations?.Layers,
        );

        const viewOptions = makeImageViewOptions(
          currentMedicalImage?.GeneratedReport?.MedicalImageFeatures
            ?.ViewOptions,
        );

        if (medicalImageID) {
          acc.push({
            src: getFileSrc(medicalImageID),
            id: selectedMedicalImageID,
            path: medicalImagePath,
            assetID: currentMedicalImage?.ID,
            order,
            annotations,
            viewOptions,
            groupName: getMedicalImageGroupName(currentMedicalImage),
          });
        }

        return acc;
      }, [] as MedicalImageInterface[]) ?? [],
    [tooth?.DisplaySlices, toothAssets],
  ).sort((a, b) => a.order - b.order);
};
