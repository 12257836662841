import { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl/lib';

import { Description } from '@/shared/ui';
import { MedicalImageInterface } from '@/shared/config';
import { useMedia } from '@/shared/hooks';

import {
  PathologyGroup,
  Group,
  medicalImageTitles,
  GPMedicalImageGroupsTypes,
} from '@/entities/assets';

import { GroupMedicalImagesList } from '../GroupMedicalImagesList/GroupMedicalImagesList';
import GuidesList from '../GuidesList/GuidesList';

import styles from './MedicalImageGroupSection.module.scss';

type MedicalImageGroupSectionProps = {
  group: Group | PathologyGroup;
  onDragMedicalImage?: (medicalImage: MedicalImageInterface) => void;
  selectedMedicalImages?: MedicalImageInterface[];
  toggleSelected?: (medicalImage: MedicalImageInterface) => void;
  className?: string;
};

export const MedicalImageGroupSection: FC<MedicalImageGroupSectionProps> = (
  props,
) => {
  const {
    className,
    group,
    selectedMedicalImages,
    toggleSelected,
    onDragMedicalImage,
  } = props;
  const [hoveredGuide, setHoveredGuide] = useState('');
  const [zoomedImageID, setZoomedImageID] = useState('');
  const [medicalImages, setMedicalImages] = useState<MedicalImageInterface[]>([]);

  const { formatMessage } = useIntl();

  const { isMobile } = useMedia();

  const handleZoomImg = useCallback(() => {
    setZoomedImageID(hoveredGuide);
  }, [hoveredGuide]);

  const title = formatMessage(
    medicalImageTitles[group.type as GPMedicalImageGroupsTypes],
  );

  useEffect(() => {
    if (group?.medicalImages?.length) {
      setMedicalImages(group.medicalImages);
    }
  }, [group.medicalImages]);

  if (!group?.guides?.length && !group?.medicalImages?.length) {
    return null;
  }

  const firstMedicalImage = (group?.medicalImages || [{} as MedicalImageInterface])[0];
  const { thicknessMm, strideMm } = firstMedicalImage;

  const hasMedicalImages = !!(
    group?.medicalImages?.length && medicalImages.length
  );

  return (
    <div className={(styles.container, className)}>
      <div className={styles.sliceWrapper}>
        <h3 className={isMobile ? 'h4' : 'h3'}>{title}</h3>
        <div className={styles.sliceConfigsWrapper}>
          {strideMm && (
            <Description
              label={
                <FormattedMessage
                  id="report.slice_step"
                  defaultMessage="Slice step"
                />
              }
            >
              {strideMm}
              <FormattedMessage id="global.mm" defaultMessage="mm" />
            </Description>
          )}
          {thicknessMm && (
            <Description
              label={
                <FormattedMessage
                  id="report.slice_thickness"
                  defaultMessage="Slice thickness"
                />
              }
            >
              {thicknessMm}
              <FormattedMessage id="global.mm" defaultMessage="mm" />
            </Description>
          )}
        </div>
      </div>
      <div className={styles.medicalImageSection}>
        {!!group?.guides?.length && (
          <GuidesList
            group={group}
            hoveredGuide={hoveredGuide}
            handleZoomImg={handleZoomImg}
            setHoveredGuide={setHoveredGuide}
            className={styles.guidesWrapper}
          />
        )}
        <div className={styles.medicalImagesColumn}>
          {hasMedicalImages && (
            <GroupMedicalImagesList
              medicalImages={medicalImages}
              zoomedImageID={zoomedImageID}
              focusedImageID={hoveredGuide}
              selectedMedicalImages={selectedMedicalImages}
              toggleSelected={toggleSelected}
              onHoveredGuideChanged={setHoveredGuide}
              onDragMedicalImage={onDragMedicalImage}
            />
          )}
        </div>
      </div>
    </div>
  );
};
