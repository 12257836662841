/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

/** https://docs.google.com/document/d/1MzKZP-kaXVrOxkYGDmf-phEfvLnTr7dw1jJO4DSX-R4/edit */
export enum ToothLandmarkImageCode {
  ToothLandmark_Image_Code_InvalidValue = 0,
  ToothLandmark_Image_Code_CementoenamelJunctionMesial = 1,
  ToothLandmark_Image_Code_CementoenamelJunctionDistal = 2,
  ToothLandmark_Image_Code_BoneLevelMesial = 3,
  ToothLandmark_Image_Code_BoneLevelDistal = 4,
  ToothLandmark_Image_Code_Apex = 5,
  UNRECOGNIZED = -1,
}

export function toothLandmarkImageCodeFromJSON(
  object: any,
): ToothLandmarkImageCode {
  switch (object) {
    case 0:
    case 'ToothLandmark_Image_Code_InvalidValue':
      return ToothLandmarkImageCode.ToothLandmark_Image_Code_InvalidValue;
    case 1:
    case 'ToothLandmark_Image_Code_CementoenamelJunctionMesial':
      return ToothLandmarkImageCode.ToothLandmark_Image_Code_CementoenamelJunctionMesial;
    case 2:
    case 'ToothLandmark_Image_Code_CementoenamelJunctionDistal':
      return ToothLandmarkImageCode.ToothLandmark_Image_Code_CementoenamelJunctionDistal;
    case 3:
    case 'ToothLandmark_Image_Code_BoneLevelMesial':
      return ToothLandmarkImageCode.ToothLandmark_Image_Code_BoneLevelMesial;
    case 4:
    case 'ToothLandmark_Image_Code_BoneLevelDistal':
      return ToothLandmarkImageCode.ToothLandmark_Image_Code_BoneLevelDistal;
    case 5:
    case 'ToothLandmark_Image_Code_Apex':
      return ToothLandmarkImageCode.ToothLandmark_Image_Code_Apex;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ToothLandmarkImageCode.UNRECOGNIZED;
  }
}

export function toothLandmarkImageCodeToJSON(
  object: ToothLandmarkImageCode,
): string {
  switch (object) {
    case ToothLandmarkImageCode.ToothLandmark_Image_Code_InvalidValue:
      return 'ToothLandmark_Image_Code_InvalidValue';
    case ToothLandmarkImageCode.ToothLandmark_Image_Code_CementoenamelJunctionMesial:
      return 'ToothLandmark_Image_Code_CementoenamelJunctionMesial';
    case ToothLandmarkImageCode.ToothLandmark_Image_Code_CementoenamelJunctionDistal:
      return 'ToothLandmark_Image_Code_CementoenamelJunctionDistal';
    case ToothLandmarkImageCode.ToothLandmark_Image_Code_BoneLevelMesial:
      return 'ToothLandmark_Image_Code_BoneLevelMesial';
    case ToothLandmarkImageCode.ToothLandmark_Image_Code_BoneLevelDistal:
      return 'ToothLandmark_Image_Code_BoneLevelDistal';
    case ToothLandmarkImageCode.ToothLandmark_Image_Code_Apex:
      return 'ToothLandmark_Image_Code_Apex';
    case ToothLandmarkImageCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** https://diagnocat.atlassian.net/wiki/spaces/DATA/pages/89882743/Teeth+s+specification */
export enum ToothLandmarkCBCTCode {
  ToothLandmark_CBCT_Code_InvalidValue = 0,
  /** ToothLandmark_CBCT_Code_UpperToothMedian - utm */
  ToothLandmark_CBCT_Code_UpperToothMedian = 1,
  /** ToothLandmark_CBCT_Code_LowerToothMedian - ltm */
  ToothLandmark_CBCT_Code_LowerToothMedian = 2,
  /** ToothLandmark_CBCT_Code_EnamelJointMesial - ejm */
  ToothLandmark_CBCT_Code_EnamelJointMesial = 3,
  /** ToothLandmark_CBCT_Code_EnamelJointDistal - ejd */
  ToothLandmark_CBCT_Code_EnamelJointDistal = 4,
  /** ToothLandmark_CBCT_Code_EnamelJointVestibular - ejv */
  ToothLandmark_CBCT_Code_EnamelJointVestibular = 5,
  /** ToothLandmark_CBCT_Code_EnamelJointOral - ejo */
  ToothLandmark_CBCT_Code_EnamelJointOral = 6,
  /** ToothLandmark_CBCT_Code_BonePeakMesial - bpm */
  ToothLandmark_CBCT_Code_BonePeakMesial = 7,
  /** ToothLandmark_CBCT_Code_BonePeakDistal - bpd */
  ToothLandmark_CBCT_Code_BonePeakDistal = 8,
  /** ToothLandmark_CBCT_Code_BonePeakVestibular - bpv */
  ToothLandmark_CBCT_Code_BonePeakVestibular = 9,
  /** ToothLandmark_CBCT_Code_BonePeakOral - bpo */
  ToothLandmark_CBCT_Code_BonePeakOral = 10,
  /** ToothLandmark_CBCT_Code_AttachmentMesial - attm */
  ToothLandmark_CBCT_Code_AttachmentMesial = 11,
  /** ToothLandmark_CBCT_Code_AttachmentDistal - attd */
  ToothLandmark_CBCT_Code_AttachmentDistal = 12,
  /** ToothLandmark_CBCT_Code_AttachmentVestibular - attv */
  ToothLandmark_CBCT_Code_AttachmentVestibular = 13,
  /** ToothLandmark_CBCT_Code_AttachmentOral - atto */
  ToothLandmark_CBCT_Code_AttachmentOral = 14,
  /** ToothLandmark_CBCT_Code_Furcation - fur */
  ToothLandmark_CBCT_Code_Furcation = 15,
  /** ToothLandmark_CBCT_Code_Buccal - b */
  ToothLandmark_CBCT_Code_Buccal = 16,
  /** ToothLandmark_CBCT_Code_Lingual - l */
  ToothLandmark_CBCT_Code_Lingual = 17,
  /** ToothLandmark_CBCT_Code_Mesial1 - m1 */
  ToothLandmark_CBCT_Code_Mesial1 = 18,
  /** ToothLandmark_CBCT_Code_Mesial2 - m2 */
  ToothLandmark_CBCT_Code_Mesial2 = 19,
  /** ToothLandmark_CBCT_Code_Distal - d */
  ToothLandmark_CBCT_Code_Distal = 20,
  /** ToothLandmark_CBCT_Code_Palatal - p */
  ToothLandmark_CBCT_Code_Palatal = 21,
  /** ToothLandmark_CBCT_Code_A1 - a1 */
  ToothLandmark_CBCT_Code_A1 = 22,
  UNRECOGNIZED = -1,
}

export function toothLandmarkCBCTCodeFromJSON(
  object: any,
): ToothLandmarkCBCTCode {
  switch (object) {
    case 0:
    case 'ToothLandmark_CBCT_Code_InvalidValue':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_InvalidValue;
    case 1:
    case 'ToothLandmark_CBCT_Code_UpperToothMedian':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_UpperToothMedian;
    case 2:
    case 'ToothLandmark_CBCT_Code_LowerToothMedian':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_LowerToothMedian;
    case 3:
    case 'ToothLandmark_CBCT_Code_EnamelJointMesial':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_EnamelJointMesial;
    case 4:
    case 'ToothLandmark_CBCT_Code_EnamelJointDistal':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_EnamelJointDistal;
    case 5:
    case 'ToothLandmark_CBCT_Code_EnamelJointVestibular':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_EnamelJointVestibular;
    case 6:
    case 'ToothLandmark_CBCT_Code_EnamelJointOral':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_EnamelJointOral;
    case 7:
    case 'ToothLandmark_CBCT_Code_BonePeakMesial':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_BonePeakMesial;
    case 8:
    case 'ToothLandmark_CBCT_Code_BonePeakDistal':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_BonePeakDistal;
    case 9:
    case 'ToothLandmark_CBCT_Code_BonePeakVestibular':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_BonePeakVestibular;
    case 10:
    case 'ToothLandmark_CBCT_Code_BonePeakOral':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_BonePeakOral;
    case 11:
    case 'ToothLandmark_CBCT_Code_AttachmentMesial':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_AttachmentMesial;
    case 12:
    case 'ToothLandmark_CBCT_Code_AttachmentDistal':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_AttachmentDistal;
    case 13:
    case 'ToothLandmark_CBCT_Code_AttachmentVestibular':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_AttachmentVestibular;
    case 14:
    case 'ToothLandmark_CBCT_Code_AttachmentOral':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_AttachmentOral;
    case 15:
    case 'ToothLandmark_CBCT_Code_Furcation':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_Furcation;
    case 16:
    case 'ToothLandmark_CBCT_Code_Buccal':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_Buccal;
    case 17:
    case 'ToothLandmark_CBCT_Code_Lingual':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_Lingual;
    case 18:
    case 'ToothLandmark_CBCT_Code_Mesial1':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_Mesial1;
    case 19:
    case 'ToothLandmark_CBCT_Code_Mesial2':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_Mesial2;
    case 20:
    case 'ToothLandmark_CBCT_Code_Distal':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_Distal;
    case 21:
    case 'ToothLandmark_CBCT_Code_Palatal':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_Palatal;
    case 22:
    case 'ToothLandmark_CBCT_Code_A1':
      return ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_A1;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ToothLandmarkCBCTCode.UNRECOGNIZED;
  }
}

export function toothLandmarkCBCTCodeToJSON(
  object: ToothLandmarkCBCTCode,
): string {
  switch (object) {
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_InvalidValue:
      return 'ToothLandmark_CBCT_Code_InvalidValue';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_UpperToothMedian:
      return 'ToothLandmark_CBCT_Code_UpperToothMedian';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_LowerToothMedian:
      return 'ToothLandmark_CBCT_Code_LowerToothMedian';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_EnamelJointMesial:
      return 'ToothLandmark_CBCT_Code_EnamelJointMesial';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_EnamelJointDistal:
      return 'ToothLandmark_CBCT_Code_EnamelJointDistal';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_EnamelJointVestibular:
      return 'ToothLandmark_CBCT_Code_EnamelJointVestibular';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_EnamelJointOral:
      return 'ToothLandmark_CBCT_Code_EnamelJointOral';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_BonePeakMesial:
      return 'ToothLandmark_CBCT_Code_BonePeakMesial';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_BonePeakDistal:
      return 'ToothLandmark_CBCT_Code_BonePeakDistal';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_BonePeakVestibular:
      return 'ToothLandmark_CBCT_Code_BonePeakVestibular';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_BonePeakOral:
      return 'ToothLandmark_CBCT_Code_BonePeakOral';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_AttachmentMesial:
      return 'ToothLandmark_CBCT_Code_AttachmentMesial';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_AttachmentDistal:
      return 'ToothLandmark_CBCT_Code_AttachmentDistal';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_AttachmentVestibular:
      return 'ToothLandmark_CBCT_Code_AttachmentVestibular';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_AttachmentOral:
      return 'ToothLandmark_CBCT_Code_AttachmentOral';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_Furcation:
      return 'ToothLandmark_CBCT_Code_Furcation';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_Buccal:
      return 'ToothLandmark_CBCT_Code_Buccal';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_Lingual:
      return 'ToothLandmark_CBCT_Code_Lingual';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_Mesial1:
      return 'ToothLandmark_CBCT_Code_Mesial1';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_Mesial2:
      return 'ToothLandmark_CBCT_Code_Mesial2';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_Distal:
      return 'ToothLandmark_CBCT_Code_Distal';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_Palatal:
      return 'ToothLandmark_CBCT_Code_Palatal';
    case ToothLandmarkCBCTCode.ToothLandmark_CBCT_Code_A1:
      return 'ToothLandmark_CBCT_Code_A1';
    case ToothLandmarkCBCTCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** https://diagnocat.atlassian.net/wiki/spaces/DATA/pages/127238145/IOS+Surfaces+Landmarks */
export enum ToothLandmarkIOSCode {
  ToothLandmark_IOS_Code_InvalidValue = 0,
  /** ToothLandmark_IOS_Code_CenterOralFrontal - cof */
  ToothLandmark_IOS_Code_CenterOralFrontal = 1,
  /** ToothLandmark_IOS_Code_CenterOralPosterior - cop */
  ToothLandmark_IOS_Code_CenterOralPosterior = 2,
  /** ToothLandmark_IOS_Code_Mesial - m */
  ToothLandmark_IOS_Code_Mesial = 3,
  /** ToothLandmark_IOS_Code_Distal - d */
  ToothLandmark_IOS_Code_Distal = 4,
  /** ToothLandmark_IOS_Code_Cuspidal - cusp */
  ToothLandmark_IOS_Code_Cuspidal = 5,
  /** ToothLandmark_IOS_Code_CuspidalOral - co */
  ToothLandmark_IOS_Code_CuspidalOral = 6,
  /** ToothLandmark_IOS_Code_CuspidalBuccal - cb */
  ToothLandmark_IOS_Code_CuspidalBuccal = 7,
  /** ToothLandmark_IOS_Code_CuspidalBuccalMesial - cbm */
  ToothLandmark_IOS_Code_CuspidalBuccalMesial = 8,
  /** ToothLandmark_IOS_Code_CuspidalBuccalDistal - cbd */
  ToothLandmark_IOS_Code_CuspidalBuccalDistal = 9,
  /** ToothLandmark_IOS_Code_CuspidalOralMesial - com */
  ToothLandmark_IOS_Code_CuspidalOralMesial = 10,
  /** ToothLandmark_IOS_Code_CuspidalOralDistal - cod */
  ToothLandmark_IOS_Code_CuspidalOralDistal = 11,
  /** ToothLandmark_IOS_Code_CuspidalOralAccesorium - coa */
  ToothLandmark_IOS_Code_CuspidalOralAccesorium = 12,
  /** ToothLandmark_IOS_Code_CuspidalDistalAccesorium - cda */
  ToothLandmark_IOS_Code_CuspidalDistalAccesorium = 13,
  UNRECOGNIZED = -1,
}

export function toothLandmarkIOSCodeFromJSON(
  object: any,
): ToothLandmarkIOSCode {
  switch (object) {
    case 0:
    case 'ToothLandmark_IOS_Code_InvalidValue':
      return ToothLandmarkIOSCode.ToothLandmark_IOS_Code_InvalidValue;
    case 1:
    case 'ToothLandmark_IOS_Code_CenterOralFrontal':
      return ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CenterOralFrontal;
    case 2:
    case 'ToothLandmark_IOS_Code_CenterOralPosterior':
      return ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CenterOralPosterior;
    case 3:
    case 'ToothLandmark_IOS_Code_Mesial':
      return ToothLandmarkIOSCode.ToothLandmark_IOS_Code_Mesial;
    case 4:
    case 'ToothLandmark_IOS_Code_Distal':
      return ToothLandmarkIOSCode.ToothLandmark_IOS_Code_Distal;
    case 5:
    case 'ToothLandmark_IOS_Code_Cuspidal':
      return ToothLandmarkIOSCode.ToothLandmark_IOS_Code_Cuspidal;
    case 6:
    case 'ToothLandmark_IOS_Code_CuspidalOral':
      return ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalOral;
    case 7:
    case 'ToothLandmark_IOS_Code_CuspidalBuccal':
      return ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalBuccal;
    case 8:
    case 'ToothLandmark_IOS_Code_CuspidalBuccalMesial':
      return ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalBuccalMesial;
    case 9:
    case 'ToothLandmark_IOS_Code_CuspidalBuccalDistal':
      return ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalBuccalDistal;
    case 10:
    case 'ToothLandmark_IOS_Code_CuspidalOralMesial':
      return ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalOralMesial;
    case 11:
    case 'ToothLandmark_IOS_Code_CuspidalOralDistal':
      return ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalOralDistal;
    case 12:
    case 'ToothLandmark_IOS_Code_CuspidalOralAccesorium':
      return ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalOralAccesorium;
    case 13:
    case 'ToothLandmark_IOS_Code_CuspidalDistalAccesorium':
      return ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalDistalAccesorium;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ToothLandmarkIOSCode.UNRECOGNIZED;
  }
}

export function toothLandmarkIOSCodeToJSON(
  object: ToothLandmarkIOSCode,
): string {
  switch (object) {
    case ToothLandmarkIOSCode.ToothLandmark_IOS_Code_InvalidValue:
      return 'ToothLandmark_IOS_Code_InvalidValue';
    case ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CenterOralFrontal:
      return 'ToothLandmark_IOS_Code_CenterOralFrontal';
    case ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CenterOralPosterior:
      return 'ToothLandmark_IOS_Code_CenterOralPosterior';
    case ToothLandmarkIOSCode.ToothLandmark_IOS_Code_Mesial:
      return 'ToothLandmark_IOS_Code_Mesial';
    case ToothLandmarkIOSCode.ToothLandmark_IOS_Code_Distal:
      return 'ToothLandmark_IOS_Code_Distal';
    case ToothLandmarkIOSCode.ToothLandmark_IOS_Code_Cuspidal:
      return 'ToothLandmark_IOS_Code_Cuspidal';
    case ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalOral:
      return 'ToothLandmark_IOS_Code_CuspidalOral';
    case ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalBuccal:
      return 'ToothLandmark_IOS_Code_CuspidalBuccal';
    case ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalBuccalMesial:
      return 'ToothLandmark_IOS_Code_CuspidalBuccalMesial';
    case ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalBuccalDistal:
      return 'ToothLandmark_IOS_Code_CuspidalBuccalDistal';
    case ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalOralMesial:
      return 'ToothLandmark_IOS_Code_CuspidalOralMesial';
    case ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalOralDistal:
      return 'ToothLandmark_IOS_Code_CuspidalOralDistal';
    case ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalOralAccesorium:
      return 'ToothLandmark_IOS_Code_CuspidalOralAccesorium';
    case ToothLandmarkIOSCode.ToothLandmark_IOS_Code_CuspidalDistalAccesorium:
      return 'ToothLandmark_IOS_Code_CuspidalDistalAccesorium';
    case ToothLandmarkIOSCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** https://diagnocat.atlassian.net/wiki/spaces/DATA/pages/157777942/Ceph+landmarks+specification */
export enum CephalometricLandmarkCBCTCode {
  CephalometricLandmark_CBCT_Code_InvalidValue = 0,
  /** CephalometricLandmark_CBCT_Code_Nasion - Nasion (N) */
  CephalometricLandmark_CBCT_Code_Nasion = 1,
  /** CephalometricLandmark_CBCT_Code_Sella - Sella (S) */
  CephalometricLandmark_CBCT_Code_Sella = 2,
  /** CephalometricLandmark_CBCT_Code_Basion - Basion (Ba) */
  CephalometricLandmark_CBCT_Code_Basion = 3,
  /** CephalometricLandmark_CBCT_Code_AnteriorNasalSpine - Anterior Nasal Spine (ANS) */
  CephalometricLandmark_CBCT_Code_AnteriorNasalSpine = 4,
  /** CephalometricLandmark_CBCT_Code_PosteriorNasalSpine - Posterior Nasal Spine (PNS) */
  CephalometricLandmark_CBCT_Code_PosteriorNasalSpine = 5,
  /** CephalometricLandmark_CBCT_Code_APointSubspinale - A Point (subspinale) (A) */
  CephalometricLandmark_CBCT_Code_APointSubspinale = 6,
  /** CephalometricLandmark_CBCT_Code_ProsthionSupradentaleSuperiorProsthion - Prosthion (supradentale, superior prosthion) (Pr) */
  CephalometricLandmark_CBCT_Code_ProsthionSupradentaleSuperiorProsthion = 7,
  /** CephalometricLandmark_CBCT_Code_Interdentale - Interdentale (Id) */
  CephalometricLandmark_CBCT_Code_Interdentale = 8,
  /** CephalometricLandmark_CBCT_Code_BPointSupramentale - B Point (supramentale) (B) */
  CephalometricLandmark_CBCT_Code_BPointSupramentale = 9,
  /** CephalometricLandmark_CBCT_Code_ProtuberantiaMenti - Protuberantia Menti (PM) */
  CephalometricLandmark_CBCT_Code_ProtuberantiaMenti = 10,
  /** CephalometricLandmark_CBCT_Code_Pogonion - Pogonion (Pog) */
  CephalometricLandmark_CBCT_Code_Pogonion = 11,
  /** CephalometricLandmark_CBCT_Code_Gnathion - Gnathion (Gn) */
  CephalometricLandmark_CBCT_Code_Gnathion = 12,
  /** CephalometricLandmark_CBCT_Code_Menton - Menton (Me) */
  CephalometricLandmark_CBCT_Code_Menton = 13,
  /** CephalometricLandmark_CBCT_Code_OrbitaleRight - Orbitale, right side (Or_R) */
  CephalometricLandmark_CBCT_Code_OrbitaleRight = 14,
  /** CephalometricLandmark_CBCT_Code_OrbitaleLeft - Orbitale, left side (Or_L) */
  CephalometricLandmark_CBCT_Code_OrbitaleLeft = 15,
  /** CephalometricLandmark_CBCT_Code_PorionRight - Porion, right side (Po_R) */
  CephalometricLandmark_CBCT_Code_PorionRight = 16,
  /** CephalometricLandmark_CBCT_Code_PorionLeft - Porion, left side (Po_L) */
  CephalometricLandmark_CBCT_Code_PorionLeft = 17,
  /** CephalometricLandmark_CBCT_Code_CondylionRight - Condylion, right side (Co_R) */
  CephalometricLandmark_CBCT_Code_CondylionRight = 18,
  /** CephalometricLandmark_CBCT_Code_CondylionLeft - Condylion, left side (Co_L) */
  CephalometricLandmark_CBCT_Code_CondylionLeft = 19,
  /** CephalometricLandmark_CBCT_Code_CondyleLateralPointRight - Condyle lateral point, right side (R Co Lat pole) */
  CephalometricLandmark_CBCT_Code_CondyleLateralPointRight = 20,
  /** CephalometricLandmark_CBCT_Code_CondyleLateralPointLeft - Condyle lateral point, left side (L Co Lat pole) */
  CephalometricLandmark_CBCT_Code_CondyleLateralPointLeft = 21,
  /** CephalometricLandmark_CBCT_Code_CondyleMedialPointRight - Condyle medial point, right side (R Co Med pole) */
  CephalometricLandmark_CBCT_Code_CondyleMedialPointRight = 22,
  /** CephalometricLandmark_CBCT_Code_CondyleMedialPointLeft - Condyle medial point, left side (L Co Med pole) */
  CephalometricLandmark_CBCT_Code_CondyleMedialPointLeft = 23,
  /** CephalometricLandmark_CBCT_Code_ArticulareRight - Articulare, right side (Ar_R) */
  CephalometricLandmark_CBCT_Code_ArticulareRight = 24,
  /** CephalometricLandmark_CBCT_Code_ArticulareLeft - Articulare, left side (Ar_L) */
  CephalometricLandmark_CBCT_Code_ArticulareLeft = 25,
  /** CephalometricLandmark_CBCT_Code_UpperRamalPointRight - Upper ramal point, right side (R3R) */
  CephalometricLandmark_CBCT_Code_UpperRamalPointRight = 26,
  /** CephalometricLandmark_CBCT_Code_UpperRamalPointLeft - Upper ramal point, left side (R3L) */
  CephalometricLandmark_CBCT_Code_UpperRamalPointLeft = 27,
  /** CephalometricLandmark_CBCT_Code_AnteriorRamalPointRight - Anterior ramal point, right side (R1R) */
  CephalometricLandmark_CBCT_Code_AnteriorRamalPointRight = 28,
  /** CephalometricLandmark_CBCT_Code_GonionRight - Gonion, right side (Go_R) */
  CephalometricLandmark_CBCT_Code_GonionRight = 29,
  /** CephalometricLandmark_CBCT_Code_GonionLeft - Gonion, left side (Go_L) */
  CephalometricLandmark_CBCT_Code_GonionLeft = 30,
  /** CephalometricLandmark_CBCT_Code_AntegonionRight - Antegonion, right side (Ag_R) */
  CephalometricLandmark_CBCT_Code_AntegonionRight = 31,
  /** CephalometricLandmark_CBCT_Code_AntegonionLeft - Antegonion, left side (Ag_L) */
  CephalometricLandmark_CBCT_Code_AntegonionLeft = 32,
  /** CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalRootPointRight - Upper molar mesiobuccal root point, right side (UMroot_R) */
  CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalRootPointRight = 33,
  /** CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalRootPointLeft - Upper molar mesiobuccal root point, left side (UMroot_L) */
  CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalRootPointLeft = 34,
  /** CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalCuspPointRight - Upper molar mesiobuccal cusp point, right side (UMcusp_R) */
  CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalCuspPointRight = 35,
  /** CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalCuspPointLeft - Upper molar mesiobuccal cusp point, left side (UMcusp_L) */
  CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalCuspPointLeft = 36,
  /** CephalometricLandmark_CBCT_Code_LowerMolarMesiobuccalCuspPointRight - Lower molar mesiobuccal cusp point, right side (LMcusp_R) */
  CephalometricLandmark_CBCT_Code_LowerMolarMesiobuccalCuspPointRight = 37,
  /** CephalometricLandmark_CBCT_Code_LowerMolarMesiobuccalCuspPointLeft - Lower molar mesiobuccal cusp point, left side (LMcusp_L) */
  CephalometricLandmark_CBCT_Code_LowerMolarMesiobuccalCuspPointLeft = 38,
  /** CephalometricLandmark_CBCT_Code_LowerMolarMesialRootPointRight - Lower molar mesial root point, right side (LMroot_R) */
  CephalometricLandmark_CBCT_Code_LowerMolarMesialRootPointRight = 39,
  /** CephalometricLandmark_CBCT_Code_LowerMolarMesialRootPointLeft - Lower molar mesial root point, left side (LMroot_L) */
  CephalometricLandmark_CBCT_Code_LowerMolarMesialRootPointLeft = 40,
  /** CephalometricLandmark_CBCT_Code_UpperIncisorRootPointRight - Upper incisor root point, right side (UIroot_R) */
  CephalometricLandmark_CBCT_Code_UpperIncisorRootPointRight = 41,
  /** CephalometricLandmark_CBCT_Code_UpperIncisorRootPointLeft - Upper incisor root point, left side (UIroot_L) */
  CephalometricLandmark_CBCT_Code_UpperIncisorRootPointLeft = 42,
  /** CephalometricLandmark_CBCT_Code_UpperIncisorCrownPointRight - Upper incisor crown point, right side (UIcrown_R) */
  CephalometricLandmark_CBCT_Code_UpperIncisorCrownPointRight = 43,
  /** CephalometricLandmark_CBCT_Code_UpperIncisorCrownPointLeft - Upper incisor crown point, left side (UIcrown_L) */
  CephalometricLandmark_CBCT_Code_UpperIncisorCrownPointLeft = 44,
  /** CephalometricLandmark_CBCT_Code_LowerIncisorRootPointRight - Lower incisor root point, right side (LIroot_R) */
  CephalometricLandmark_CBCT_Code_LowerIncisorRootPointRight = 45,
  /** CephalometricLandmark_CBCT_Code_LowerIncisorRootPointLeft - Lower incisor root point, left side (LIroot_L) */
  CephalometricLandmark_CBCT_Code_LowerIncisorRootPointLeft = 46,
  /** CephalometricLandmark_CBCT_Code_LowerIncisorCrownPointRight - Lower incisor crown point, right side (LIcrown_R) */
  CephalometricLandmark_CBCT_Code_LowerIncisorCrownPointRight = 47,
  /** CephalometricLandmark_CBCT_Code_LowerIncisorCrownPointLeft - Lower incisor crown point, left side (LIcrown_L) */
  CephalometricLandmark_CBCT_Code_LowerIncisorCrownPointLeft = 48,
  /** CephalometricLandmark_CBCT_Code_Glabella - Glabella (Gl) */
  CephalometricLandmark_CBCT_Code_Glabella = 49,
  /** CephalometricLandmark_CBCT_Code_SoftTissueNasion - Soft Tissue Nasion (Soft N) */
  CephalometricLandmark_CBCT_Code_SoftTissueNasion = 50,
  /** CephalometricLandmark_CBCT_Code_Pronasale - Pronasale (Pn) */
  CephalometricLandmark_CBCT_Code_Pronasale = 51,
  /** CephalometricLandmark_CBCT_Code_Cartilage - Cartilage (Ctg) */
  CephalometricLandmark_CBCT_Code_Cartilage = 52,
  /** CephalometricLandmark_CBCT_Code_SubnasaleSubnasalSubnasion - Subnasale (subnasal, subnasion) (Sn) */
  CephalometricLandmark_CBCT_Code_SubnasaleSubnasalSubnasion = 53,
  /** CephalometricLandmark_CBCT_Code_LabialisSuperior - Labialis Superior (Ls) */
  CephalometricLandmark_CBCT_Code_LabialisSuperior = 54,
  /** CephalometricLandmark_CBCT_Code_StomionSuperius - Stomion Superius (Sts) */
  CephalometricLandmark_CBCT_Code_StomionSuperius = 55,
  /** CephalometricLandmark_CBCT_Code_StomionInferius - Stomion Inferius (Sti) */
  CephalometricLandmark_CBCT_Code_StomionInferius = 56,
  /** CephalometricLandmark_CBCT_Code_LabialInferior - Labial Inferior (Li) */
  CephalometricLandmark_CBCT_Code_LabialInferior = 57,
  /** CephalometricLandmark_CBCT_Code_MentolabialSulcus - Mentolabial Sulcus (Ils) */
  CephalometricLandmark_CBCT_Code_MentolabialSulcus = 58,
  /** CephalometricLandmark_CBCT_Code_SoftTissuePogonion - Soft Tissue Pogonion (Soft Pog) */
  CephalometricLandmark_CBCT_Code_SoftTissuePogonion = 59,
  /** CephalometricLandmark_CBCT_Code_SoftTissueMenton - Soft Tissue Menton (Soft Me) */
  CephalometricLandmark_CBCT_Code_SoftTissueMenton = 60,
  /** CephalometricLandmark_CBCT_Code_LateralLeft - Lateral, left side (Lat_L) */
  CephalometricLandmark_CBCT_Code_LateralLeft = 61,
  /** CephalometricLandmark_CBCT_Code_LateralRight - Lateral, right side (Lat_R) */
  CephalometricLandmark_CBCT_Code_LateralRight = 62,
  /** CephalometricLandmark_CBCT_Code_CristaGalli - Crista Galli (Cg) */
  CephalometricLandmark_CBCT_Code_CristaGalli = 63,
  /** CephalometricLandmark_CBCT_Code_MaxillareLeft - Maxillare, left side (Mx_L) */
  CephalometricLandmark_CBCT_Code_MaxillareLeft = 64,
  /** CephalometricLandmark_CBCT_Code_MaxillareRight - Maxillare, right side (Mx_R) */
  CephalometricLandmark_CBCT_Code_MaxillareRight = 65,
  /** CephalometricLandmark_CBCT_Code_WillAndrewsAndLarryAndrewsLeft - Will Andrews and Larry Andrews, left side (WALA_L) */
  CephalometricLandmark_CBCT_Code_WillAndrewsAndLarryAndrewsLeft = 66,
  /** CephalometricLandmark_CBCT_Code_WillAndrewsAndLarryAndrewsRight - Will Andrews and Larry Andrews, right side (WALA_R) */
  CephalometricLandmark_CBCT_Code_WillAndrewsAndLarryAndrewsRight = 67,
  /** CephalometricLandmark_CBCT_Code_ZygionLeft - Zygion, left side (Zy_L) */
  CephalometricLandmark_CBCT_Code_ZygionLeft = 68,
  /** CephalometricLandmark_CBCT_Code_ZygionRight - Zygion, right side (Zy_R) */
  CephalometricLandmark_CBCT_Code_ZygionRight = 69,
  /** CephalometricLandmark_CBCT_Code_CenterOfCondyleLeft - Center of condyle, left side (C_L) */
  CephalometricLandmark_CBCT_Code_CenterOfCondyleLeft = 70,
  /** CephalometricLandmark_CBCT_Code_CenterOfCondyleRight - Center of condyle, right side (C_R) */
  CephalometricLandmark_CBCT_Code_CenterOfCondyleRight = 71,
  /** CephalometricLandmark_CBCT_Code_EndocanthionLeft - Endocanthion, left side (En_L) */
  CephalometricLandmark_CBCT_Code_EndocanthionLeft = 72,
  /** CephalometricLandmark_CBCT_Code_EndocanthionRight - Endocanthion, right side (En_R) */
  CephalometricLandmark_CBCT_Code_EndocanthionRight = 73,
  /** CephalometricLandmark_CBCT_Code_PterygoidLeft - Pterygoid point, left (Pt_L) */
  CephalometricLandmark_CBCT_Code_PterygoidLeft = 74,
  /** CephalometricLandmark_CBCT_Code_PterygoidRight - Pterygoid point, right (Pt_R) */
  CephalometricLandmark_CBCT_Code_PterygoidRight = 75,
  UNRECOGNIZED = -1,
}

export function cephalometricLandmarkCBCTCodeFromJSON(
  object: any,
): CephalometricLandmarkCBCTCode {
  switch (object) {
    case 0:
    case 'CephalometricLandmark_CBCT_Code_InvalidValue':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_InvalidValue;
    case 1:
    case 'CephalometricLandmark_CBCT_Code_Nasion':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Nasion;
    case 2:
    case 'CephalometricLandmark_CBCT_Code_Sella':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Sella;
    case 3:
    case 'CephalometricLandmark_CBCT_Code_Basion':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Basion;
    case 4:
    case 'CephalometricLandmark_CBCT_Code_AnteriorNasalSpine':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_AnteriorNasalSpine;
    case 5:
    case 'CephalometricLandmark_CBCT_Code_PosteriorNasalSpine':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_PosteriorNasalSpine;
    case 6:
    case 'CephalometricLandmark_CBCT_Code_APointSubspinale':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_APointSubspinale;
    case 7:
    case 'CephalometricLandmark_CBCT_Code_ProsthionSupradentaleSuperiorProsthion':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_ProsthionSupradentaleSuperiorProsthion;
    case 8:
    case 'CephalometricLandmark_CBCT_Code_Interdentale':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Interdentale;
    case 9:
    case 'CephalometricLandmark_CBCT_Code_BPointSupramentale':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_BPointSupramentale;
    case 10:
    case 'CephalometricLandmark_CBCT_Code_ProtuberantiaMenti':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_ProtuberantiaMenti;
    case 11:
    case 'CephalometricLandmark_CBCT_Code_Pogonion':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Pogonion;
    case 12:
    case 'CephalometricLandmark_CBCT_Code_Gnathion':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Gnathion;
    case 13:
    case 'CephalometricLandmark_CBCT_Code_Menton':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Menton;
    case 14:
    case 'CephalometricLandmark_CBCT_Code_OrbitaleRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_OrbitaleRight;
    case 15:
    case 'CephalometricLandmark_CBCT_Code_OrbitaleLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_OrbitaleLeft;
    case 16:
    case 'CephalometricLandmark_CBCT_Code_PorionRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_PorionRight;
    case 17:
    case 'CephalometricLandmark_CBCT_Code_PorionLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_PorionLeft;
    case 18:
    case 'CephalometricLandmark_CBCT_Code_CondylionRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CondylionRight;
    case 19:
    case 'CephalometricLandmark_CBCT_Code_CondylionLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CondylionLeft;
    case 20:
    case 'CephalometricLandmark_CBCT_Code_CondyleLateralPointRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CondyleLateralPointRight;
    case 21:
    case 'CephalometricLandmark_CBCT_Code_CondyleLateralPointLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CondyleLateralPointLeft;
    case 22:
    case 'CephalometricLandmark_CBCT_Code_CondyleMedialPointRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CondyleMedialPointRight;
    case 23:
    case 'CephalometricLandmark_CBCT_Code_CondyleMedialPointLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CondyleMedialPointLeft;
    case 24:
    case 'CephalometricLandmark_CBCT_Code_ArticulareRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_ArticulareRight;
    case 25:
    case 'CephalometricLandmark_CBCT_Code_ArticulareLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_ArticulareLeft;
    case 26:
    case 'CephalometricLandmark_CBCT_Code_UpperRamalPointRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperRamalPointRight;
    case 27:
    case 'CephalometricLandmark_CBCT_Code_UpperRamalPointLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperRamalPointLeft;
    case 28:
    case 'CephalometricLandmark_CBCT_Code_AnteriorRamalPointRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_AnteriorRamalPointRight;
    case 29:
    case 'CephalometricLandmark_CBCT_Code_GonionRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_GonionRight;
    case 30:
    case 'CephalometricLandmark_CBCT_Code_GonionLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_GonionLeft;
    case 31:
    case 'CephalometricLandmark_CBCT_Code_AntegonionRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_AntegonionRight;
    case 32:
    case 'CephalometricLandmark_CBCT_Code_AntegonionLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_AntegonionLeft;
    case 33:
    case 'CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalRootPointRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalRootPointRight;
    case 34:
    case 'CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalRootPointLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalRootPointLeft;
    case 35:
    case 'CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalCuspPointRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalCuspPointRight;
    case 36:
    case 'CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalCuspPointLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalCuspPointLeft;
    case 37:
    case 'CephalometricLandmark_CBCT_Code_LowerMolarMesiobuccalCuspPointRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerMolarMesiobuccalCuspPointRight;
    case 38:
    case 'CephalometricLandmark_CBCT_Code_LowerMolarMesiobuccalCuspPointLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerMolarMesiobuccalCuspPointLeft;
    case 39:
    case 'CephalometricLandmark_CBCT_Code_LowerMolarMesialRootPointRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerMolarMesialRootPointRight;
    case 40:
    case 'CephalometricLandmark_CBCT_Code_LowerMolarMesialRootPointLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerMolarMesialRootPointLeft;
    case 41:
    case 'CephalometricLandmark_CBCT_Code_UpperIncisorRootPointRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperIncisorRootPointRight;
    case 42:
    case 'CephalometricLandmark_CBCT_Code_UpperIncisorRootPointLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperIncisorRootPointLeft;
    case 43:
    case 'CephalometricLandmark_CBCT_Code_UpperIncisorCrownPointRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperIncisorCrownPointRight;
    case 44:
    case 'CephalometricLandmark_CBCT_Code_UpperIncisorCrownPointLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperIncisorCrownPointLeft;
    case 45:
    case 'CephalometricLandmark_CBCT_Code_LowerIncisorRootPointRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerIncisorRootPointRight;
    case 46:
    case 'CephalometricLandmark_CBCT_Code_LowerIncisorRootPointLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerIncisorRootPointLeft;
    case 47:
    case 'CephalometricLandmark_CBCT_Code_LowerIncisorCrownPointRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerIncisorCrownPointRight;
    case 48:
    case 'CephalometricLandmark_CBCT_Code_LowerIncisorCrownPointLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerIncisorCrownPointLeft;
    case 49:
    case 'CephalometricLandmark_CBCT_Code_Glabella':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Glabella;
    case 50:
    case 'CephalometricLandmark_CBCT_Code_SoftTissueNasion':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_SoftTissueNasion;
    case 51:
    case 'CephalometricLandmark_CBCT_Code_Pronasale':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Pronasale;
    case 52:
    case 'CephalometricLandmark_CBCT_Code_Cartilage':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Cartilage;
    case 53:
    case 'CephalometricLandmark_CBCT_Code_SubnasaleSubnasalSubnasion':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_SubnasaleSubnasalSubnasion;
    case 54:
    case 'CephalometricLandmark_CBCT_Code_LabialisSuperior':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LabialisSuperior;
    case 55:
    case 'CephalometricLandmark_CBCT_Code_StomionSuperius':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_StomionSuperius;
    case 56:
    case 'CephalometricLandmark_CBCT_Code_StomionInferius':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_StomionInferius;
    case 57:
    case 'CephalometricLandmark_CBCT_Code_LabialInferior':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LabialInferior;
    case 58:
    case 'CephalometricLandmark_CBCT_Code_MentolabialSulcus':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_MentolabialSulcus;
    case 59:
    case 'CephalometricLandmark_CBCT_Code_SoftTissuePogonion':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_SoftTissuePogonion;
    case 60:
    case 'CephalometricLandmark_CBCT_Code_SoftTissueMenton':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_SoftTissueMenton;
    case 61:
    case 'CephalometricLandmark_CBCT_Code_LateralLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LateralLeft;
    case 62:
    case 'CephalometricLandmark_CBCT_Code_LateralRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LateralRight;
    case 63:
    case 'CephalometricLandmark_CBCT_Code_CristaGalli':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CristaGalli;
    case 64:
    case 'CephalometricLandmark_CBCT_Code_MaxillareLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_MaxillareLeft;
    case 65:
    case 'CephalometricLandmark_CBCT_Code_MaxillareRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_MaxillareRight;
    case 66:
    case 'CephalometricLandmark_CBCT_Code_WillAndrewsAndLarryAndrewsLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_WillAndrewsAndLarryAndrewsLeft;
    case 67:
    case 'CephalometricLandmark_CBCT_Code_WillAndrewsAndLarryAndrewsRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_WillAndrewsAndLarryAndrewsRight;
    case 68:
    case 'CephalometricLandmark_CBCT_Code_ZygionLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_ZygionLeft;
    case 69:
    case 'CephalometricLandmark_CBCT_Code_ZygionRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_ZygionRight;
    case 70:
    case 'CephalometricLandmark_CBCT_Code_CenterOfCondyleLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CenterOfCondyleLeft;
    case 71:
    case 'CephalometricLandmark_CBCT_Code_CenterOfCondyleRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CenterOfCondyleRight;
    case 72:
    case 'CephalometricLandmark_CBCT_Code_EndocanthionLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_EndocanthionLeft;
    case 73:
    case 'CephalometricLandmark_CBCT_Code_EndocanthionRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_EndocanthionRight;
    case 74:
    case 'CephalometricLandmark_CBCT_Code_PterygoidLeft':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_PterygoidLeft;
    case 75:
    case 'CephalometricLandmark_CBCT_Code_PterygoidRight':
      return CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_PterygoidRight;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CephalometricLandmarkCBCTCode.UNRECOGNIZED;
  }
}

export function cephalometricLandmarkCBCTCodeToJSON(
  object: CephalometricLandmarkCBCTCode,
): string {
  switch (object) {
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_InvalidValue:
      return 'CephalometricLandmark_CBCT_Code_InvalidValue';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Nasion:
      return 'CephalometricLandmark_CBCT_Code_Nasion';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Sella:
      return 'CephalometricLandmark_CBCT_Code_Sella';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Basion:
      return 'CephalometricLandmark_CBCT_Code_Basion';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_AnteriorNasalSpine:
      return 'CephalometricLandmark_CBCT_Code_AnteriorNasalSpine';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_PosteriorNasalSpine:
      return 'CephalometricLandmark_CBCT_Code_PosteriorNasalSpine';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_APointSubspinale:
      return 'CephalometricLandmark_CBCT_Code_APointSubspinale';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_ProsthionSupradentaleSuperiorProsthion:
      return 'CephalometricLandmark_CBCT_Code_ProsthionSupradentaleSuperiorProsthion';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Interdentale:
      return 'CephalometricLandmark_CBCT_Code_Interdentale';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_BPointSupramentale:
      return 'CephalometricLandmark_CBCT_Code_BPointSupramentale';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_ProtuberantiaMenti:
      return 'CephalometricLandmark_CBCT_Code_ProtuberantiaMenti';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Pogonion:
      return 'CephalometricLandmark_CBCT_Code_Pogonion';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Gnathion:
      return 'CephalometricLandmark_CBCT_Code_Gnathion';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Menton:
      return 'CephalometricLandmark_CBCT_Code_Menton';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_OrbitaleRight:
      return 'CephalometricLandmark_CBCT_Code_OrbitaleRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_OrbitaleLeft:
      return 'CephalometricLandmark_CBCT_Code_OrbitaleLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_PorionRight:
      return 'CephalometricLandmark_CBCT_Code_PorionRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_PorionLeft:
      return 'CephalometricLandmark_CBCT_Code_PorionLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CondylionRight:
      return 'CephalometricLandmark_CBCT_Code_CondylionRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CondylionLeft:
      return 'CephalometricLandmark_CBCT_Code_CondylionLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CondyleLateralPointRight:
      return 'CephalometricLandmark_CBCT_Code_CondyleLateralPointRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CondyleLateralPointLeft:
      return 'CephalometricLandmark_CBCT_Code_CondyleLateralPointLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CondyleMedialPointRight:
      return 'CephalometricLandmark_CBCT_Code_CondyleMedialPointRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CondyleMedialPointLeft:
      return 'CephalometricLandmark_CBCT_Code_CondyleMedialPointLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_ArticulareRight:
      return 'CephalometricLandmark_CBCT_Code_ArticulareRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_ArticulareLeft:
      return 'CephalometricLandmark_CBCT_Code_ArticulareLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperRamalPointRight:
      return 'CephalometricLandmark_CBCT_Code_UpperRamalPointRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperRamalPointLeft:
      return 'CephalometricLandmark_CBCT_Code_UpperRamalPointLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_AnteriorRamalPointRight:
      return 'CephalometricLandmark_CBCT_Code_AnteriorRamalPointRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_GonionRight:
      return 'CephalometricLandmark_CBCT_Code_GonionRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_GonionLeft:
      return 'CephalometricLandmark_CBCT_Code_GonionLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_AntegonionRight:
      return 'CephalometricLandmark_CBCT_Code_AntegonionRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_AntegonionLeft:
      return 'CephalometricLandmark_CBCT_Code_AntegonionLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalRootPointRight:
      return 'CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalRootPointRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalRootPointLeft:
      return 'CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalRootPointLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalCuspPointRight:
      return 'CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalCuspPointRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalCuspPointLeft:
      return 'CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalCuspPointLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerMolarMesiobuccalCuspPointRight:
      return 'CephalometricLandmark_CBCT_Code_LowerMolarMesiobuccalCuspPointRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerMolarMesiobuccalCuspPointLeft:
      return 'CephalometricLandmark_CBCT_Code_LowerMolarMesiobuccalCuspPointLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerMolarMesialRootPointRight:
      return 'CephalometricLandmark_CBCT_Code_LowerMolarMesialRootPointRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerMolarMesialRootPointLeft:
      return 'CephalometricLandmark_CBCT_Code_LowerMolarMesialRootPointLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperIncisorRootPointRight:
      return 'CephalometricLandmark_CBCT_Code_UpperIncisorRootPointRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperIncisorRootPointLeft:
      return 'CephalometricLandmark_CBCT_Code_UpperIncisorRootPointLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperIncisorCrownPointRight:
      return 'CephalometricLandmark_CBCT_Code_UpperIncisorCrownPointRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_UpperIncisorCrownPointLeft:
      return 'CephalometricLandmark_CBCT_Code_UpperIncisorCrownPointLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerIncisorRootPointRight:
      return 'CephalometricLandmark_CBCT_Code_LowerIncisorRootPointRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerIncisorRootPointLeft:
      return 'CephalometricLandmark_CBCT_Code_LowerIncisorRootPointLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerIncisorCrownPointRight:
      return 'CephalometricLandmark_CBCT_Code_LowerIncisorCrownPointRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LowerIncisorCrownPointLeft:
      return 'CephalometricLandmark_CBCT_Code_LowerIncisorCrownPointLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Glabella:
      return 'CephalometricLandmark_CBCT_Code_Glabella';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_SoftTissueNasion:
      return 'CephalometricLandmark_CBCT_Code_SoftTissueNasion';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Pronasale:
      return 'CephalometricLandmark_CBCT_Code_Pronasale';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_Cartilage:
      return 'CephalometricLandmark_CBCT_Code_Cartilage';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_SubnasaleSubnasalSubnasion:
      return 'CephalometricLandmark_CBCT_Code_SubnasaleSubnasalSubnasion';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LabialisSuperior:
      return 'CephalometricLandmark_CBCT_Code_LabialisSuperior';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_StomionSuperius:
      return 'CephalometricLandmark_CBCT_Code_StomionSuperius';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_StomionInferius:
      return 'CephalometricLandmark_CBCT_Code_StomionInferius';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LabialInferior:
      return 'CephalometricLandmark_CBCT_Code_LabialInferior';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_MentolabialSulcus:
      return 'CephalometricLandmark_CBCT_Code_MentolabialSulcus';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_SoftTissuePogonion:
      return 'CephalometricLandmark_CBCT_Code_SoftTissuePogonion';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_SoftTissueMenton:
      return 'CephalometricLandmark_CBCT_Code_SoftTissueMenton';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LateralLeft:
      return 'CephalometricLandmark_CBCT_Code_LateralLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_LateralRight:
      return 'CephalometricLandmark_CBCT_Code_LateralRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CristaGalli:
      return 'CephalometricLandmark_CBCT_Code_CristaGalli';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_MaxillareLeft:
      return 'CephalometricLandmark_CBCT_Code_MaxillareLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_MaxillareRight:
      return 'CephalometricLandmark_CBCT_Code_MaxillareRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_WillAndrewsAndLarryAndrewsLeft:
      return 'CephalometricLandmark_CBCT_Code_WillAndrewsAndLarryAndrewsLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_WillAndrewsAndLarryAndrewsRight:
      return 'CephalometricLandmark_CBCT_Code_WillAndrewsAndLarryAndrewsRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_ZygionLeft:
      return 'CephalometricLandmark_CBCT_Code_ZygionLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_ZygionRight:
      return 'CephalometricLandmark_CBCT_Code_ZygionRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CenterOfCondyleLeft:
      return 'CephalometricLandmark_CBCT_Code_CenterOfCondyleLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_CenterOfCondyleRight:
      return 'CephalometricLandmark_CBCT_Code_CenterOfCondyleRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_EndocanthionLeft:
      return 'CephalometricLandmark_CBCT_Code_EndocanthionLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_EndocanthionRight:
      return 'CephalometricLandmark_CBCT_Code_EndocanthionRight';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_PterygoidLeft:
      return 'CephalometricLandmark_CBCT_Code_PterygoidLeft';
    case CephalometricLandmarkCBCTCode.CephalometricLandmark_CBCT_Code_PterygoidRight:
      return 'CephalometricLandmark_CBCT_Code_PterygoidRight';
    case CephalometricLandmarkCBCTCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
