import { useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { toastCaller } from '@/shared/ui';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { ToastErrors, toastErrors } from '@/shared/config';

import { getPatientFullName, patientModel } from '@/entities/patient';
import { reportsModel } from '@/entities/reports';
import { getROITeethIDsAfterRemoveOne, toothModel } from '@/entities/tooth';

export const useReport = (reportID: string, patientID: string) => {
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const selectReport = useMemo(
    () => reportsModel.selectors.selectByID(reportID),
    [reportID],
  );
  const selectPatient = useMemo(
    () => patientModel.selectors.selectPatientByID(patientID),
    [patientID],
  );

  const report = useAppSelector(selectReport);
  const patient = useAppSelector(selectPatient);

  const reportLoading = useAppSelector(reportsModel.selectors.selectLoading);
  const patientLoading = useAppSelector(patientModel.selectors.selectLoading);

  const isReportLoading =
    reportLoading === 'idle' || reportLoading === 'pending';

  const isPatientLoading =
    patientLoading === 'idle' || patientLoading === 'pending';

  const ROITeeth: Tooth[] = useAppSelector(toothModel.selectors.selectROITeeth);

  const isAllTeethApproved = ROITeeth.every((tooth) => tooth.IsApproved);

  const isReportSigned = report?.Signature?.UserSignatures.length;

  const patientFullName = getPatientFullName(patient);
  const patientDoB =
    patient?.PersonalData?.DateOfBirth &&
    format(new Date(patient?.PersonalData?.DateOfBirth), 'd MMMM, y');

  const handleRemoveToothFromROI = useCallback(
    async (toothID: string) => {
      const newROITeethIDs = getROITeethIDsAfterRemoveOne(ROITeeth, toothID);

      try {
        const { AllReportTeeth } = await dispatch(
          reportsModel.thunks.setROI({
            ReportID: reportID,
            ROIToothIDs: newROITeethIDs,
          }),
        ).unwrap();

        dispatch(toothModel.actions.setMany(AllReportTeeth));
      } catch (error) {
        const parsedMessage = JSON.parse(error?.message);
        const errorHeading = formatMessage(
          toastErrors[ToastErrors.errorHeading],
        );

        toastCaller({
          type: 'error',
          heading: errorHeading,
          message: parsedMessage?.text ?? parsedMessage?.reason,
        });
      }
    },
    [ROITeeth, dispatch, reportID, formatMessage],
  );

  return {
    report,
    isReportLoading,
    ROITeeth,
    isAllTeethApproved,
    isReportSigned,
    handleRemoveToothFromROI,
    patient,
    isPatientLoading,
    patientFullName,
    patientDoB,
  };
};
