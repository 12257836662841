import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { ReportSettings } from '@/shared/api/protocol_gen/model/dto_report';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { toastCaller } from '@/shared/ui';
import { ToastErrors, toastErrors } from '@/shared/config';

import * as reportsModel from '../model';

type UseReportSettings = {
  isLoading: boolean;
  settings: ReportSettings;
  updateSettings: (newSettings: Partial<ReportSettings>) => void;
};

export const useReportSettings = (reportID: string): UseReportSettings => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const report = useAppSelector(reportsModel.selectors.selectByID(reportID));

  const settings = report?.Settings ?? {
    LowProbabilityMode: false,
    ShowProblemAreas: false,
    ShowDiagnosisDetails: false,
    ShowPanoramaSplitInsteadOfSingle: false,
    ShowSimpleConditionView: false,
  };

  const updateSettings = useCallback(
    async (newSettings: Partial<ReportSettings>) => {
      setIsLoading(true);

      try {
        const { Report } = await dispatch(
          reportsModel.thunks.setReportSettings({
            ReportID: reportID,
            Settings: { ...settings, ...newSettings },
          }),
        ).unwrap();

        dispatch(reportsModel.actions.setNewestOne(Report));
      } catch (error) {
        const parsedMessage = JSON.parse(error?.message);
        const errorHeading = formatMessage(
          toastErrors[ToastErrors.errorHeading],
        );

        toastCaller({
          type: 'error',
          heading: errorHeading,
          message: parsedMessage?.text ?? parsedMessage?.reason,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, settings, reportID],
  );

  return {
    isLoading,
    settings,
    updateSettings,
  };
};
