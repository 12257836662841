// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConditionListByCategory-module__list--sK9uPkpq{display:flex;flex-direction:column;gap:24px}\n\n.ConditionListByCategory-module__content--Ifd12nBt{margin-top:8px;margin-bottom:-12px;margin-left:3px}\n\n[dir=\"rtl\"] .ConditionListByCategory-module__content--Ifd12nBt{margin-left:0;margin-right:3px}", "",{"version":3,"sources":["webpack://./src/entities/condition/ui/ConditionListByCategory/ConditionListByCategory.module.scss"],"names":[],"mappings":"AAAA,gDACE,YAAA,CACA,qBAAA,CACA,QAAA;;AAGF,mDACE,cAAA,CACA,mBAAA,CACA,eAAA;;AAHF,+DAGE,aAAA,CAAA,gBAAA","sourcesContent":[".list {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.content {\n  margin-top: 8px;\n  margin-bottom: -12px;\n  margin-left: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "ConditionListByCategory-module__list--sK9uPkpq",
	"content": "ConditionListByCategory-module__content--Ifd12nBt"
};
export default ___CSS_LOADER_EXPORT___;
