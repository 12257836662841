// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SharedWith-module__container--m3bg6-3D{flex:1;height:100%;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;gap:10px;padding:12px;overflow-x:hidden}.SharedWith-module__header--rZtp3KJH{width:100%;display:flex;justify-content:space-between;align-items:flex-start}.SharedWith-module__title--aDlejHHt{color:var(--greytext)}.SharedWith-module__sharedList--MnP0xv7U{width:100%}.SharedWith-module__emptyList--\\+BHSw8uX{width:100%;height:100%;display:flex;justify-content:center;align-items:center}.SharedWith-module__icon--82BVLk5v{color:var(--purpleflat);cursor:pointer}", "",{"version":3,"sources":["webpack://./src/widgets/PatientInfo/ui/SharedWith/SharedWith.module.scss"],"names":[],"mappings":"AAAA,wCACE,MAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,0BAAA,CACA,sBAAA,CACA,QAAA,CACA,YAAA,CACA,iBAAA,CAGF,qCACE,UAAA,CACA,YAAA,CACA,6BAAA,CACA,sBAAA,CAGF,oCACE,qBAAA,CAGF,yCACE,UAAA,CAGF,yCACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAGF,mCACE,uBAAA,CACA,cAAA","sourcesContent":[".container {\n  flex: 1;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: 10px;\n  padding: 12px;\n  overflow-x: hidden;\n}\n\n.header {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n\n.title {\n  color: var(--greytext);\n}\n\n.sharedList {\n  width: 100%;\n}\n\n.emptyList {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.icon {\n  color: var(--purpleflat);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SharedWith-module__container--m3bg6-3D",
	"header": "SharedWith-module__header--rZtp3KJH",
	"title": "SharedWith-module__title--aDlejHHt",
	"sharedList": "SharedWith-module__sharedList--MnP0xv7U",
	"emptyList": "SharedWith-module__emptyList--+BHSw8uX",
	"icon": "SharedWith-module__icon--82BVLk5v"
};
export default ___CSS_LOADER_EXPORT___;
