// TODO: Resolve type issue with thunkAction
// @ts-nocheck
import {
  createSlice,
  createEntityAdapter,
  AsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Report } from '@/shared/api/protocol_gen/model/dto_report';
import { MedicalImageViewOptions } from '@/shared/api/protocol_gen/model/dto_common_image_view_options';
import { UltimateMedicalImageMode } from '@/shared/graphics/RenderComponents/Presets/UltimateMedicalImage';
import {
  DefaultInitialState,
  LoadingStateType,
  SliceName,
} from '@/shared/config';

import { FMXNavigationMode, MaskStyleTypes } from '../config/reports.type';

import { deleteReport } from './reportSlice.thunks';

const sortReports = (a: Report, b: Report) => {
  const aDate = a.Created?.At?.getTime() ?? 0;
  const bDate = b.Created?.At?.getTime() ?? 0;

  return bDate - aDate;
};

export const reportsAdapter = createEntityAdapter<Report>({
  selectId: (report) => report.ID,
  sortComparer: sortReports,
});

type ReportInitialState = DefaultInitialState & {
  flags: {
    isReportRequestPending: boolean;
    isDeleteReportPending: boolean;
    isReportROIPending: boolean;
  };
  focusedToothCardID: string;
  maskStyleType: MaskStyleTypes;
  showMasks: boolean;
  toothChartHeight: number;
  navigationMode: FMXNavigationMode;
  activeSubImageIndex: number;
  activeToothID: string;
  activeSubImagesIDs: string[];
  activePageNumber: number;
  disabledSubImagesIDs: string[];
  subImagesIDs: string[];
  ioxRayReportViewOptions: MedicalImageViewOptions;
  toolbarActiveControl: string;
  toolsMode: UltimateMedicalImageMode;
  lastUpdatedCropID: string;
  currentReport: Report | undefined;
};

const initialState: ReportInitialState = {
  flags: {
    isReportRequestPending: false,
    isDeleteReportPending: false,
    isReportROIPending: false,
  },
  focusedToothCardID: '',
  loading: 'idle',
  maskStyleType: MaskStyleTypes.Default,
  showMasks: true,
  toothChartHeight: 0,
  navigationMode: FMXNavigationMode.MatrixView,
  activeSubImageIndex: 0,
  activePageNumber: 0,
  activeToothID: '',
  activeSubImagesIDs: [],
  disabledSubImagesIDs: [],
  subImagesIDs: [],
  ioxRayReportViewOptions: {},
  toolbarActiveControl: 'view',
  toolsMode: 'view',
  lastUpdatedCropID: '',
};

type StateFlags = keyof typeof initialState.flags;

const reportsSlice = createSlice({
  name: SliceName.reports,
  initialState: reportsAdapter.getInitialState(initialState),
  reducers: {
    addOne: reportsAdapter.addOne,
    addMany: reportsAdapter.addMany,
    setOne: reportsAdapter.setOne,
    removeAll: reportsAdapter.removeAll,
    setNewestOne: (state, action: PayloadAction<Report>) => {
      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

      if (payloadRevisionNumber > currentRevisionNumber) {
        reportsAdapter.setOne(state, action.payload);
      }
    },
    setMany: reportsAdapter.setMany,
    removeOne: reportsAdapter.removeOne,
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
    setFocusedToothCardID: (state, action: PayloadAction<string>) => {
      state.focusedToothCardID = action.payload;
    },
    setMaskStyleType: (state, action) => {
      state.maskStyleType = action.payload;
    },
    setShowMasks: (state, action) => {
      state.showMasks = action.payload;
    },
    setToothChartHeight: (state, action) => {
      state.toothChartHeight = action.payload;
    },
    setNavigationMode: (state, action) => {
      state.navigationMode = action.payload;
    },
    setActiveSubImageIndex: (state, action) => {
      state.activeSubImageIndex = action.payload;
    },
    setActivePage: (state, action) => {
      state.activePageNumber = action.payload;
    },
    setActiveToothID: (state, action) => {
      state.activeToothID = action.payload;
    },
    setActiveSubImagesIDs: (state, action: PayloadAction<string[]>) => {
      state.activeSubImagesIDs = action.payload;
    },
    setDisabledSubImagesIDs: (state, action: PayloadAction<string[]>) => {
      state.disabledSubImagesIDs = action.payload;
    },
    setSubImagesIDs: (state, action: PayloadAction<string[]>) => {
      state.subImagesIDs = action.payload;
    },
    setToolbarActiveControl: (state, action: PayloadAction<string>) => {
      state.toolbarActiveControl = action.payload;
    },
    setLastUpdatedCropID: (state, action: PayloadAction<string>) => {
      state.lastUpdatedCropID = action.payload;
    },
    setToolsMode: (state, action: PayloadAction<string>) => {
      state.toolsMode = action.payload;
    },
    setIOXRayReportViewOptions: (
      state,
      action: PayloadAction<MedicalImageViewOptions>,
    ) => {
      state.ioxRayReportViewOptions = action.payload;
    },
    setCurrentReport: (state, action: PayloadAction<Report>) => {
      state.currentReport = action.payload;
    },
    removeCurrentReport: (state) => {
      state.currentReport = undefined;
    },
    reset: () => reportsAdapter.getInitialState(initialState),
  },
  extraReducers: (builder) => {
    const asyncRequestCreator = <A, B, C>(
      thunkAction: AsyncThunk<A, B, C>,
      pendingFlagKey: StateFlags,
      fulfilledCallback?: (payload: unknown) => void,
    ) => {
      builder.addCase(thunkAction.pending, (state) => {
        state.flags[pendingFlagKey] = true;
      });
      builder.addCase(thunkAction.fulfilled, (state, { payload }) => {
        state.flags[pendingFlagKey] = false;
        if (typeof fulfilledCallback === 'function') {
          fulfilledCallback(payload);
        }
      });
      builder.addCase(thunkAction.rejected, (state) => {
        state.flags[pendingFlagKey] = false;
      });
    };

    asyncRequestCreator(deleteReport, 'isDeleteReportPending');
  },
});

export const { actions } = reportsSlice;

export default reportsSlice.reducer;
