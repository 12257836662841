/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  CephalometricMeasureCode,
  cephalometricMeasureCodeFromJSON,
  cephalometricMeasureCodeToJSON,
} from '../model/dto_report_cephalometric_measurement_codes';
import {
  CBCTSeriesGeometryData,
  ProjectionTransform,
  PlanePosition,
} from '../model/dto_common_geometry';
import {
  ReportStudioColorProfile,
  ReportStudioToothPosition,
  ReportStudioPanoramaGeometry,
} from '../model/dto_report_type_studio_common';
import {
  ToothLandmark,
  CephalometricLandmark,
} from '../model/dto_report_landmark';
import { SegmentronPhotoIOSSuperimposition } from '../model/dto_report_segmentron_common';
import { ToothNumeration } from '../model/dto_report_common';
import { Empty } from '../google/protobuf/empty';

export const protobufPackage = 'com.diagnocat.model';

export interface ReportStudioOrtho {
  CropGeometryData: CBCTSeriesGeometryData | undefined;
  ColorProfile: ReportStudioColorProfile | undefined;
  TeethPositions: ReportStudioToothPosition[];
  PanoramaGeometry: ReportStudioPanoramaGeometry | undefined;
  NaturalHeadPositionTransform: ProjectionTransform | undefined;
  OrthoPlanes: ReportStudioOrthoOrthoPlanes | undefined;
  TeethMovementPlans: ReportStudioOrthoTeethMovementPlan[];
  CephalometricMeasurements: ReportStudioOrthoCephalometricMeasurements[];
  TeethLandmarks: ToothLandmark[];
  CephalometricLandmarks: CephalometricLandmark[];
  PhotoIOSSuperimposition: SegmentronPhotoIOSSuperimposition | undefined;
  TeethAnalysis: ReportStudioOrthoTeethAnalysis | undefined;
}

export interface ReportStudioOrthoOrthoPlanes {
  Midline: PlanePosition | undefined;
  OcclusionalUpper: PlanePosition | undefined;
  OcclusionalLower: PlanePosition | undefined;
}

export interface ReportStudioOrthoTeethMovementPlan {
  ID: string;
  Order: number;
  Steps: ReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep[];
}

export interface ReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep {
  Tooth: ToothNumeration | undefined;
  Transform: ProjectionTransform | undefined;
}

export interface ReportStudioOrthoCephalometricMeasurements {
  Measurements: ReportStudioOrthoCephalometricMeasurements_Measurement[];
  Initial: Empty | undefined;
  /** As of now, only the latest movement plan might have cephalometric measurements. */
  TeethMovementPlanID: string | undefined;
}

export interface ReportStudioOrthoCephalometricMeasurements_Measurement {
  Type: CephalometricMeasureCode;
  Values:
    | ReportStudioOrthoCephalometricMeasurementsMeasurementValues
    | undefined;
}

/**
 * Notes:
 * - if `IsPerSide` is True the measurement is defined and calculated for each patient's side.
 * - if `IsPerSide` is False `Left` and `Right` are guaranteed to be equal.
 * - if `Left` and `Right` are default valued, an error occured during calculation.
 */
export interface ReportStudioOrthoCephalometricMeasurementsMeasurementValues {
  Left: number;
  Right: number;
  IsPerSide: boolean;
  Norm: number;
  NormDeviation: number;
}

/** STL tooth analysis. */
export interface ReportStudioOrthoTeethAnalysis {
  Ponts: ReportStudioOrthoTeethAnalysisPonts | undefined;
  TonnBolton: ReportStudioOrthoTeethAnalysisTonnBolton | undefined;
  SpaceCrowding: ReportStudioOrthoTeethAnalysisLeftRightMeasure | undefined;
  CurveOfSpee: ReportStudioOrthoTeethAnalysisLeftRightMeasure | undefined;
}

export interface ReportStudioOrthoTeethAnalysisPonts {
  LowerMolars: ReportStudioOrthoTeethAnalysisPonts_Measure | undefined;
  UpperMolars: ReportStudioOrthoTeethAnalysisPonts_Measure | undefined;
  LowerPreMolars: ReportStudioOrthoTeethAnalysisPonts_Measure | undefined;
  UpperPreMolars: ReportStudioOrthoTeethAnalysisPonts_Measure | undefined;
}

export interface ReportStudioOrthoTeethAnalysisPonts_Measure {
  Norm: number;
  Fact: number;
  Diff: number;
}

export interface ReportStudioOrthoTeethAnalysisTonnBolton {
  Tonn: ReportStudioOrthoTeethAnalysisTonnBolton_Measure | undefined;
  Bolton: ReportStudioOrthoTeethAnalysisTonnBolton_Measure | undefined;
}

export interface ReportStudioOrthoTeethAnalysisTonnBolton_Measure {
  IndexNorm: number;
  IndexFact: number;
  DiffUpper: number;
  DiffLower: number;
}

export interface ReportStudioOrthoTeethAnalysisLeftRightMeasure {
  Lower: ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure | undefined;
  Upper: ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure | undefined;
}

export interface ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure {
  Left: number;
  Right: number;
  Total: number;
}

function createBaseReportStudioOrtho(): ReportStudioOrtho {
  return {
    CropGeometryData: undefined,
    ColorProfile: undefined,
    TeethPositions: [],
    PanoramaGeometry: undefined,
    NaturalHeadPositionTransform: undefined,
    OrthoPlanes: undefined,
    TeethMovementPlans: [],
    CephalometricMeasurements: [],
    TeethLandmarks: [],
    CephalometricLandmarks: [],
    PhotoIOSSuperimposition: undefined,
    TeethAnalysis: undefined,
  };
}

export const ReportStudioOrtho = {
  encode(
    message: ReportStudioOrtho,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CropGeometryData !== undefined) {
      CBCTSeriesGeometryData.encode(
        message.CropGeometryData,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.ColorProfile !== undefined) {
      ReportStudioColorProfile.encode(
        message.ColorProfile,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    for (const v of message.TeethPositions) {
      ReportStudioToothPosition.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.PanoramaGeometry !== undefined) {
      ReportStudioPanoramaGeometry.encode(
        message.PanoramaGeometry,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.NaturalHeadPositionTransform !== undefined) {
      ProjectionTransform.encode(
        message.NaturalHeadPositionTransform,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.OrthoPlanes !== undefined) {
      ReportStudioOrthoOrthoPlanes.encode(
        message.OrthoPlanes,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    for (const v of message.TeethMovementPlans) {
      ReportStudioOrthoTeethMovementPlan.encode(
        v!,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    for (const v of message.CephalometricMeasurements) {
      ReportStudioOrthoCephalometricMeasurements.encode(
        v!,
        writer.uint32(66).fork(),
      ).ldelim();
    }
    for (const v of message.TeethLandmarks) {
      ToothLandmark.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.CephalometricLandmarks) {
      CephalometricLandmark.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    if (message.PhotoIOSSuperimposition !== undefined) {
      SegmentronPhotoIOSSuperimposition.encode(
        message.PhotoIOSSuperimposition,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.TeethAnalysis !== undefined) {
      ReportStudioOrthoTeethAnalysis.encode(
        message.TeethAnalysis,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportStudioOrtho {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioOrtho();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CropGeometryData = CBCTSeriesGeometryData.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.ColorProfile = ReportStudioColorProfile.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          message.TeethPositions.push(
            ReportStudioToothPosition.decode(reader, reader.uint32()),
          );
          break;
        case 4:
          message.PanoramaGeometry = ReportStudioPanoramaGeometry.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 5:
          message.NaturalHeadPositionTransform = ProjectionTransform.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 6:
          message.OrthoPlanes = ReportStudioOrthoOrthoPlanes.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 7:
          message.TeethMovementPlans.push(
            ReportStudioOrthoTeethMovementPlan.decode(reader, reader.uint32()),
          );
          break;
        case 8:
          message.CephalometricMeasurements.push(
            ReportStudioOrthoCephalometricMeasurements.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        case 9:
          message.TeethLandmarks.push(
            ToothLandmark.decode(reader, reader.uint32()),
          );
          break;
        case 10:
          message.CephalometricLandmarks.push(
            CephalometricLandmark.decode(reader, reader.uint32()),
          );
          break;
        case 11:
          message.PhotoIOSSuperimposition =
            SegmentronPhotoIOSSuperimposition.decode(reader, reader.uint32());
          break;
        case 12:
          message.TeethAnalysis = ReportStudioOrthoTeethAnalysis.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioOrtho {
    return {
      CropGeometryData: isSet(object.CropGeometryData)
        ? CBCTSeriesGeometryData.fromJSON(object.CropGeometryData)
        : undefined,
      ColorProfile: isSet(object.ColorProfile)
        ? ReportStudioColorProfile.fromJSON(object.ColorProfile)
        : undefined,
      TeethPositions: Array.isArray(object?.TeethPositions)
        ? object.TeethPositions.map((e: any) =>
            ReportStudioToothPosition.fromJSON(e),
          )
        : [],
      PanoramaGeometry: isSet(object.PanoramaGeometry)
        ? ReportStudioPanoramaGeometry.fromJSON(object.PanoramaGeometry)
        : undefined,
      NaturalHeadPositionTransform: isSet(object.NaturalHeadPositionTransform)
        ? ProjectionTransform.fromJSON(object.NaturalHeadPositionTransform)
        : undefined,
      OrthoPlanes: isSet(object.OrthoPlanes)
        ? ReportStudioOrthoOrthoPlanes.fromJSON(object.OrthoPlanes)
        : undefined,
      TeethMovementPlans: Array.isArray(object?.TeethMovementPlans)
        ? object.TeethMovementPlans.map((e: any) =>
            ReportStudioOrthoTeethMovementPlan.fromJSON(e),
          )
        : [],
      CephalometricMeasurements: Array.isArray(
        object?.CephalometricMeasurements,
      )
        ? object.CephalometricMeasurements.map((e: any) =>
            ReportStudioOrthoCephalometricMeasurements.fromJSON(e),
          )
        : [],
      TeethLandmarks: Array.isArray(object?.TeethLandmarks)
        ? object.TeethLandmarks.map((e: any) => ToothLandmark.fromJSON(e))
        : [],
      CephalometricLandmarks: Array.isArray(object?.CephalometricLandmarks)
        ? object.CephalometricLandmarks.map((e: any) =>
            CephalometricLandmark.fromJSON(e),
          )
        : [],
      PhotoIOSSuperimposition: isSet(object.PhotoIOSSuperimposition)
        ? SegmentronPhotoIOSSuperimposition.fromJSON(
            object.PhotoIOSSuperimposition,
          )
        : undefined,
      TeethAnalysis: isSet(object.TeethAnalysis)
        ? ReportStudioOrthoTeethAnalysis.fromJSON(object.TeethAnalysis)
        : undefined,
    };
  },

  toJSON(message: ReportStudioOrtho): unknown {
    const obj: any = {};
    message.CropGeometryData !== undefined &&
      (obj.CropGeometryData = message.CropGeometryData
        ? CBCTSeriesGeometryData.toJSON(message.CropGeometryData)
        : undefined);
    message.ColorProfile !== undefined &&
      (obj.ColorProfile = message.ColorProfile
        ? ReportStudioColorProfile.toJSON(message.ColorProfile)
        : undefined);
    if (message.TeethPositions) {
      obj.TeethPositions = message.TeethPositions.map((e) =>
        e ? ReportStudioToothPosition.toJSON(e) : undefined,
      );
    } else {
      obj.TeethPositions = [];
    }
    message.PanoramaGeometry !== undefined &&
      (obj.PanoramaGeometry = message.PanoramaGeometry
        ? ReportStudioPanoramaGeometry.toJSON(message.PanoramaGeometry)
        : undefined);
    message.NaturalHeadPositionTransform !== undefined &&
      (obj.NaturalHeadPositionTransform = message.NaturalHeadPositionTransform
        ? ProjectionTransform.toJSON(message.NaturalHeadPositionTransform)
        : undefined);
    message.OrthoPlanes !== undefined &&
      (obj.OrthoPlanes = message.OrthoPlanes
        ? ReportStudioOrthoOrthoPlanes.toJSON(message.OrthoPlanes)
        : undefined);
    if (message.TeethMovementPlans) {
      obj.TeethMovementPlans = message.TeethMovementPlans.map((e) =>
        e ? ReportStudioOrthoTeethMovementPlan.toJSON(e) : undefined,
      );
    } else {
      obj.TeethMovementPlans = [];
    }
    if (message.CephalometricMeasurements) {
      obj.CephalometricMeasurements = message.CephalometricMeasurements.map(
        (e) =>
          e ? ReportStudioOrthoCephalometricMeasurements.toJSON(e) : undefined,
      );
    } else {
      obj.CephalometricMeasurements = [];
    }
    if (message.TeethLandmarks) {
      obj.TeethLandmarks = message.TeethLandmarks.map((e) =>
        e ? ToothLandmark.toJSON(e) : undefined,
      );
    } else {
      obj.TeethLandmarks = [];
    }
    if (message.CephalometricLandmarks) {
      obj.CephalometricLandmarks = message.CephalometricLandmarks.map((e) =>
        e ? CephalometricLandmark.toJSON(e) : undefined,
      );
    } else {
      obj.CephalometricLandmarks = [];
    }
    message.PhotoIOSSuperimposition !== undefined &&
      (obj.PhotoIOSSuperimposition = message.PhotoIOSSuperimposition
        ? SegmentronPhotoIOSSuperimposition.toJSON(
            message.PhotoIOSSuperimposition,
          )
        : undefined);
    message.TeethAnalysis !== undefined &&
      (obj.TeethAnalysis = message.TeethAnalysis
        ? ReportStudioOrthoTeethAnalysis.toJSON(message.TeethAnalysis)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportStudioOrtho>, I>>(
    object: I,
  ): ReportStudioOrtho {
    const message = createBaseReportStudioOrtho();
    message.CropGeometryData =
      object.CropGeometryData !== undefined && object.CropGeometryData !== null
        ? CBCTSeriesGeometryData.fromPartial(object.CropGeometryData)
        : undefined;
    message.ColorProfile =
      object.ColorProfile !== undefined && object.ColorProfile !== null
        ? ReportStudioColorProfile.fromPartial(object.ColorProfile)
        : undefined;
    message.TeethPositions =
      object.TeethPositions?.map((e) =>
        ReportStudioToothPosition.fromPartial(e),
      ) || [];
    message.PanoramaGeometry =
      object.PanoramaGeometry !== undefined && object.PanoramaGeometry !== null
        ? ReportStudioPanoramaGeometry.fromPartial(object.PanoramaGeometry)
        : undefined;
    message.NaturalHeadPositionTransform =
      object.NaturalHeadPositionTransform !== undefined &&
      object.NaturalHeadPositionTransform !== null
        ? ProjectionTransform.fromPartial(object.NaturalHeadPositionTransform)
        : undefined;
    message.OrthoPlanes =
      object.OrthoPlanes !== undefined && object.OrthoPlanes !== null
        ? ReportStudioOrthoOrthoPlanes.fromPartial(object.OrthoPlanes)
        : undefined;
    message.TeethMovementPlans =
      object.TeethMovementPlans?.map((e) =>
        ReportStudioOrthoTeethMovementPlan.fromPartial(e),
      ) || [];
    message.CephalometricMeasurements =
      object.CephalometricMeasurements?.map((e) =>
        ReportStudioOrthoCephalometricMeasurements.fromPartial(e),
      ) || [];
    message.TeethLandmarks =
      object.TeethLandmarks?.map((e) => ToothLandmark.fromPartial(e)) || [];
    message.CephalometricLandmarks =
      object.CephalometricLandmarks?.map((e) =>
        CephalometricLandmark.fromPartial(e),
      ) || [];
    message.PhotoIOSSuperimposition =
      object.PhotoIOSSuperimposition !== undefined &&
      object.PhotoIOSSuperimposition !== null
        ? SegmentronPhotoIOSSuperimposition.fromPartial(
            object.PhotoIOSSuperimposition,
          )
        : undefined;
    message.TeethAnalysis =
      object.TeethAnalysis !== undefined && object.TeethAnalysis !== null
        ? ReportStudioOrthoTeethAnalysis.fromPartial(object.TeethAnalysis)
        : undefined;
    return message;
  },
};

function createBaseReportStudioOrthoOrthoPlanes(): ReportStudioOrthoOrthoPlanes {
  return {
    Midline: undefined,
    OcclusionalUpper: undefined,
    OcclusionalLower: undefined,
  };
}

export const ReportStudioOrthoOrthoPlanes = {
  encode(
    message: ReportStudioOrthoOrthoPlanes,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Midline !== undefined) {
      PlanePosition.encode(message.Midline, writer.uint32(10).fork()).ldelim();
    }
    if (message.OcclusionalUpper !== undefined) {
      PlanePosition.encode(
        message.OcclusionalUpper,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.OcclusionalLower !== undefined) {
      PlanePosition.encode(
        message.OcclusionalLower,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioOrthoOrthoPlanes {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioOrthoOrthoPlanes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Midline = PlanePosition.decode(reader, reader.uint32());
          break;
        case 2:
          message.OcclusionalUpper = PlanePosition.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          message.OcclusionalLower = PlanePosition.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioOrthoOrthoPlanes {
    return {
      Midline: isSet(object.Midline)
        ? PlanePosition.fromJSON(object.Midline)
        : undefined,
      OcclusionalUpper: isSet(object.OcclusionalUpper)
        ? PlanePosition.fromJSON(object.OcclusionalUpper)
        : undefined,
      OcclusionalLower: isSet(object.OcclusionalLower)
        ? PlanePosition.fromJSON(object.OcclusionalLower)
        : undefined,
    };
  },

  toJSON(message: ReportStudioOrthoOrthoPlanes): unknown {
    const obj: any = {};
    message.Midline !== undefined &&
      (obj.Midline = message.Midline
        ? PlanePosition.toJSON(message.Midline)
        : undefined);
    message.OcclusionalUpper !== undefined &&
      (obj.OcclusionalUpper = message.OcclusionalUpper
        ? PlanePosition.toJSON(message.OcclusionalUpper)
        : undefined);
    message.OcclusionalLower !== undefined &&
      (obj.OcclusionalLower = message.OcclusionalLower
        ? PlanePosition.toJSON(message.OcclusionalLower)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportStudioOrthoOrthoPlanes>, I>>(
    object: I,
  ): ReportStudioOrthoOrthoPlanes {
    const message = createBaseReportStudioOrthoOrthoPlanes();
    message.Midline =
      object.Midline !== undefined && object.Midline !== null
        ? PlanePosition.fromPartial(object.Midline)
        : undefined;
    message.OcclusionalUpper =
      object.OcclusionalUpper !== undefined && object.OcclusionalUpper !== null
        ? PlanePosition.fromPartial(object.OcclusionalUpper)
        : undefined;
    message.OcclusionalLower =
      object.OcclusionalLower !== undefined && object.OcclusionalLower !== null
        ? PlanePosition.fromPartial(object.OcclusionalLower)
        : undefined;
    return message;
  },
};

function createBaseReportStudioOrthoTeethMovementPlan(): ReportStudioOrthoTeethMovementPlan {
  return { ID: '', Order: 0, Steps: [] };
}

export const ReportStudioOrthoTeethMovementPlan = {
  encode(
    message: ReportStudioOrthoTeethMovementPlan,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Order !== 0) {
      writer.uint32(16).uint32(message.Order);
    }
    for (const v of message.Steps) {
      ReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep.encode(
        v!,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioOrthoTeethMovementPlan {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioOrthoTeethMovementPlan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Order = reader.uint32();
          break;
        case 3:
          message.Steps.push(
            ReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioOrthoTeethMovementPlan {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Order: isSet(object.Order) ? Number(object.Order) : 0,
      Steps: Array.isArray(object?.Steps)
        ? object.Steps.map((e: any) =>
            ReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep.fromJSON(
              e,
            ),
          )
        : [],
    };
  },

  toJSON(message: ReportStudioOrthoTeethMovementPlan): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Order !== undefined && (obj.Order = Math.round(message.Order));
    if (message.Steps) {
      obj.Steps = message.Steps.map((e) =>
        e
          ? ReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep.toJSON(
              e,
            )
          : undefined,
      );
    } else {
      obj.Steps = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportStudioOrthoTeethMovementPlan>, I>,
  >(object: I): ReportStudioOrthoTeethMovementPlan {
    const message = createBaseReportStudioOrthoTeethMovementPlan();
    message.ID = object.ID ?? '';
    message.Order = object.Order ?? 0;
    message.Steps =
      object.Steps?.map((e) =>
        ReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep.fromPartial(
          e,
        ),
      ) || [];
    return message;
  },
};

function createBaseReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep(): ReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep {
  return { Tooth: undefined, Transform: undefined };
}

export const ReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep =
  {
    encode(
      message: ReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep,
      writer: _m0.Writer = _m0.Writer.create(),
    ): _m0.Writer {
      if (message.Tooth !== undefined) {
        ToothNumeration.encode(
          message.Tooth,
          writer.uint32(10).fork(),
        ).ldelim();
      }
      if (message.Transform !== undefined) {
        ProjectionTransform.encode(
          message.Transform,
          writer.uint32(18).fork(),
        ).ldelim();
      }
      return writer;
    },

    decode(
      input: _m0.Reader | Uint8Array,
      length?: number,
    ): ReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep {
      const reader =
        input instanceof _m0.Reader ? input : new _m0.Reader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message =
        createBaseReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.Tooth = ToothNumeration.decode(reader, reader.uint32());
            break;
          case 2:
            message.Transform = ProjectionTransform.decode(
              reader,
              reader.uint32(),
            );
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    },

    fromJSON(
      object: any,
    ): ReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep {
      return {
        Tooth: isSet(object.Tooth)
          ? ToothNumeration.fromJSON(object.Tooth)
          : undefined,
        Transform: isSet(object.Transform)
          ? ProjectionTransform.fromJSON(object.Transform)
          : undefined,
      };
    },

    toJSON(
      message: ReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep,
    ): unknown {
      const obj: any = {};
      message.Tooth !== undefined &&
        (obj.Tooth = message.Tooth
          ? ToothNumeration.toJSON(message.Tooth)
          : undefined);
      message.Transform !== undefined &&
        (obj.Transform = message.Transform
          ? ProjectionTransform.toJSON(message.Transform)
          : undefined);
      return obj;
    },

    fromPartial<
      I extends Exact<
        DeepPartial<ReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep>,
        I
      >,
    >(
      object: I,
    ): ReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep {
      const message =
        createBaseReportStudioOrthoTeethMovementPlan_ReportStudioOrthoTeethMovementPlanStep();
      message.Tooth =
        object.Tooth !== undefined && object.Tooth !== null
          ? ToothNumeration.fromPartial(object.Tooth)
          : undefined;
      message.Transform =
        object.Transform !== undefined && object.Transform !== null
          ? ProjectionTransform.fromPartial(object.Transform)
          : undefined;
      return message;
    },
  };

function createBaseReportStudioOrthoCephalometricMeasurements(): ReportStudioOrthoCephalometricMeasurements {
  return {
    Measurements: [],
    Initial: undefined,
    TeethMovementPlanID: undefined,
  };
}

export const ReportStudioOrthoCephalometricMeasurements = {
  encode(
    message: ReportStudioOrthoCephalometricMeasurements,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Measurements) {
      ReportStudioOrthoCephalometricMeasurements_Measurement.encode(
        v!,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.Initial !== undefined) {
      Empty.encode(message.Initial, writer.uint32(18).fork()).ldelim();
    }
    if (message.TeethMovementPlanID !== undefined) {
      writer.uint32(26).string(message.TeethMovementPlanID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioOrthoCephalometricMeasurements {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioOrthoCephalometricMeasurements();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Measurements.push(
            ReportStudioOrthoCephalometricMeasurements_Measurement.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        case 2:
          message.Initial = Empty.decode(reader, reader.uint32());
          break;
        case 3:
          message.TeethMovementPlanID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioOrthoCephalometricMeasurements {
    return {
      Measurements: Array.isArray(object?.Measurements)
        ? object.Measurements.map((e: any) =>
            ReportStudioOrthoCephalometricMeasurements_Measurement.fromJSON(e),
          )
        : [],
      Initial: isSet(object.Initial)
        ? Empty.fromJSON(object.Initial)
        : undefined,
      TeethMovementPlanID: isSet(object.TeethMovementPlanID)
        ? String(object.TeethMovementPlanID)
        : undefined,
    };
  },

  toJSON(message: ReportStudioOrthoCephalometricMeasurements): unknown {
    const obj: any = {};
    if (message.Measurements) {
      obj.Measurements = message.Measurements.map((e) =>
        e
          ? ReportStudioOrthoCephalometricMeasurements_Measurement.toJSON(e)
          : undefined,
      );
    } else {
      obj.Measurements = [];
    }
    message.Initial !== undefined &&
      (obj.Initial = message.Initial
        ? Empty.toJSON(message.Initial)
        : undefined);
    message.TeethMovementPlanID !== undefined &&
      (obj.TeethMovementPlanID = message.TeethMovementPlanID);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportStudioOrthoCephalometricMeasurements>, I>,
  >(object: I): ReportStudioOrthoCephalometricMeasurements {
    const message = createBaseReportStudioOrthoCephalometricMeasurements();
    message.Measurements =
      object.Measurements?.map((e) =>
        ReportStudioOrthoCephalometricMeasurements_Measurement.fromPartial(e),
      ) || [];
    message.Initial =
      object.Initial !== undefined && object.Initial !== null
        ? Empty.fromPartial(object.Initial)
        : undefined;
    message.TeethMovementPlanID = object.TeethMovementPlanID ?? undefined;
    return message;
  },
};

function createBaseReportStudioOrthoCephalometricMeasurements_Measurement(): ReportStudioOrthoCephalometricMeasurements_Measurement {
  return { Type: 0, Values: undefined };
}

export const ReportStudioOrthoCephalometricMeasurements_Measurement = {
  encode(
    message: ReportStudioOrthoCephalometricMeasurements_Measurement,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Type !== 0) {
      writer.uint32(8).int32(message.Type);
    }
    if (message.Values !== undefined) {
      ReportStudioOrthoCephalometricMeasurementsMeasurementValues.encode(
        message.Values,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioOrthoCephalometricMeasurements_Measurement {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseReportStudioOrthoCephalometricMeasurements_Measurement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Type = reader.int32() as any;
          break;
        case 2:
          message.Values =
            ReportStudioOrthoCephalometricMeasurementsMeasurementValues.decode(
              reader,
              reader.uint32(),
            );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(
    object: any,
  ): ReportStudioOrthoCephalometricMeasurements_Measurement {
    return {
      Type: isSet(object.Type)
        ? cephalometricMeasureCodeFromJSON(object.Type)
        : 0,
      Values: isSet(object.Values)
        ? ReportStudioOrthoCephalometricMeasurementsMeasurementValues.fromJSON(
            object.Values,
          )
        : undefined,
    };
  },

  toJSON(
    message: ReportStudioOrthoCephalometricMeasurements_Measurement,
  ): unknown {
    const obj: any = {};
    message.Type !== undefined &&
      (obj.Type = cephalometricMeasureCodeToJSON(message.Type));
    message.Values !== undefined &&
      (obj.Values = message.Values
        ? ReportStudioOrthoCephalometricMeasurementsMeasurementValues.toJSON(
            message.Values,
          )
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportStudioOrthoCephalometricMeasurements_Measurement>,
      I
    >,
  >(object: I): ReportStudioOrthoCephalometricMeasurements_Measurement {
    const message =
      createBaseReportStudioOrthoCephalometricMeasurements_Measurement();
    message.Type = object.Type ?? 0;
    message.Values =
      object.Values !== undefined && object.Values !== null
        ? ReportStudioOrthoCephalometricMeasurementsMeasurementValues.fromPartial(
            object.Values,
          )
        : undefined;
    return message;
  },
};

function createBaseReportStudioOrthoCephalometricMeasurementsMeasurementValues(): ReportStudioOrthoCephalometricMeasurementsMeasurementValues {
  return { Left: 0, Right: 0, IsPerSide: false, Norm: 0, NormDeviation: 0 };
}

export const ReportStudioOrthoCephalometricMeasurementsMeasurementValues = {
  encode(
    message: ReportStudioOrthoCephalometricMeasurementsMeasurementValues,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Left !== 0) {
      writer.uint32(13).float(message.Left);
    }
    if (message.Right !== 0) {
      writer.uint32(21).float(message.Right);
    }
    if (message.IsPerSide === true) {
      writer.uint32(24).bool(message.IsPerSide);
    }
    if (message.Norm !== 0) {
      writer.uint32(37).float(message.Norm);
    }
    if (message.NormDeviation !== 0) {
      writer.uint32(45).float(message.NormDeviation);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioOrthoCephalometricMeasurementsMeasurementValues {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseReportStudioOrthoCephalometricMeasurementsMeasurementValues();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Left = reader.float();
          break;
        case 2:
          message.Right = reader.float();
          break;
        case 3:
          message.IsPerSide = reader.bool();
          break;
        case 4:
          message.Norm = reader.float();
          break;
        case 5:
          message.NormDeviation = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(
    object: any,
  ): ReportStudioOrthoCephalometricMeasurementsMeasurementValues {
    return {
      Left: isSet(object.Left) ? Number(object.Left) : 0,
      Right: isSet(object.Right) ? Number(object.Right) : 0,
      IsPerSide: isSet(object.IsPerSide) ? Boolean(object.IsPerSide) : false,
      Norm: isSet(object.Norm) ? Number(object.Norm) : 0,
      NormDeviation: isSet(object.NormDeviation)
        ? Number(object.NormDeviation)
        : 0,
    };
  },

  toJSON(
    message: ReportStudioOrthoCephalometricMeasurementsMeasurementValues,
  ): unknown {
    const obj: any = {};
    message.Left !== undefined && (obj.Left = message.Left);
    message.Right !== undefined && (obj.Right = message.Right);
    message.IsPerSide !== undefined && (obj.IsPerSide = message.IsPerSide);
    message.Norm !== undefined && (obj.Norm = message.Norm);
    message.NormDeviation !== undefined &&
      (obj.NormDeviation = message.NormDeviation);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportStudioOrthoCephalometricMeasurementsMeasurementValues>,
      I
    >,
  >(object: I): ReportStudioOrthoCephalometricMeasurementsMeasurementValues {
    const message =
      createBaseReportStudioOrthoCephalometricMeasurementsMeasurementValues();
    message.Left = object.Left ?? 0;
    message.Right = object.Right ?? 0;
    message.IsPerSide = object.IsPerSide ?? false;
    message.Norm = object.Norm ?? 0;
    message.NormDeviation = object.NormDeviation ?? 0;
    return message;
  },
};

function createBaseReportStudioOrthoTeethAnalysis(): ReportStudioOrthoTeethAnalysis {
  return {
    Ponts: undefined,
    TonnBolton: undefined,
    SpaceCrowding: undefined,
    CurveOfSpee: undefined,
  };
}

export const ReportStudioOrthoTeethAnalysis = {
  encode(
    message: ReportStudioOrthoTeethAnalysis,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Ponts !== undefined) {
      ReportStudioOrthoTeethAnalysisPonts.encode(
        message.Ponts,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.TonnBolton !== undefined) {
      ReportStudioOrthoTeethAnalysisTonnBolton.encode(
        message.TonnBolton,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.SpaceCrowding !== undefined) {
      ReportStudioOrthoTeethAnalysisLeftRightMeasure.encode(
        message.SpaceCrowding,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.CurveOfSpee !== undefined) {
      ReportStudioOrthoTeethAnalysisLeftRightMeasure.encode(
        message.CurveOfSpee,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioOrthoTeethAnalysis {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioOrthoTeethAnalysis();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Ponts = ReportStudioOrthoTeethAnalysisPonts.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.TonnBolton = ReportStudioOrthoTeethAnalysisTonnBolton.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          message.SpaceCrowding =
            ReportStudioOrthoTeethAnalysisLeftRightMeasure.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 4:
          message.CurveOfSpee =
            ReportStudioOrthoTeethAnalysisLeftRightMeasure.decode(
              reader,
              reader.uint32(),
            );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioOrthoTeethAnalysis {
    return {
      Ponts: isSet(object.Ponts)
        ? ReportStudioOrthoTeethAnalysisPonts.fromJSON(object.Ponts)
        : undefined,
      TonnBolton: isSet(object.TonnBolton)
        ? ReportStudioOrthoTeethAnalysisTonnBolton.fromJSON(object.TonnBolton)
        : undefined,
      SpaceCrowding: isSet(object.SpaceCrowding)
        ? ReportStudioOrthoTeethAnalysisLeftRightMeasure.fromJSON(
            object.SpaceCrowding,
          )
        : undefined,
      CurveOfSpee: isSet(object.CurveOfSpee)
        ? ReportStudioOrthoTeethAnalysisLeftRightMeasure.fromJSON(
            object.CurveOfSpee,
          )
        : undefined,
    };
  },

  toJSON(message: ReportStudioOrthoTeethAnalysis): unknown {
    const obj: any = {};
    message.Ponts !== undefined &&
      (obj.Ponts = message.Ponts
        ? ReportStudioOrthoTeethAnalysisPonts.toJSON(message.Ponts)
        : undefined);
    message.TonnBolton !== undefined &&
      (obj.TonnBolton = message.TonnBolton
        ? ReportStudioOrthoTeethAnalysisTonnBolton.toJSON(message.TonnBolton)
        : undefined);
    message.SpaceCrowding !== undefined &&
      (obj.SpaceCrowding = message.SpaceCrowding
        ? ReportStudioOrthoTeethAnalysisLeftRightMeasure.toJSON(
            message.SpaceCrowding,
          )
        : undefined);
    message.CurveOfSpee !== undefined &&
      (obj.CurveOfSpee = message.CurveOfSpee
        ? ReportStudioOrthoTeethAnalysisLeftRightMeasure.toJSON(
            message.CurveOfSpee,
          )
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportStudioOrthoTeethAnalysis>, I>>(
    object: I,
  ): ReportStudioOrthoTeethAnalysis {
    const message = createBaseReportStudioOrthoTeethAnalysis();
    message.Ponts =
      object.Ponts !== undefined && object.Ponts !== null
        ? ReportStudioOrthoTeethAnalysisPonts.fromPartial(object.Ponts)
        : undefined;
    message.TonnBolton =
      object.TonnBolton !== undefined && object.TonnBolton !== null
        ? ReportStudioOrthoTeethAnalysisTonnBolton.fromPartial(
            object.TonnBolton,
          )
        : undefined;
    message.SpaceCrowding =
      object.SpaceCrowding !== undefined && object.SpaceCrowding !== null
        ? ReportStudioOrthoTeethAnalysisLeftRightMeasure.fromPartial(
            object.SpaceCrowding,
          )
        : undefined;
    message.CurveOfSpee =
      object.CurveOfSpee !== undefined && object.CurveOfSpee !== null
        ? ReportStudioOrthoTeethAnalysisLeftRightMeasure.fromPartial(
            object.CurveOfSpee,
          )
        : undefined;
    return message;
  },
};

function createBaseReportStudioOrthoTeethAnalysisPonts(): ReportStudioOrthoTeethAnalysisPonts {
  return {
    LowerMolars: undefined,
    UpperMolars: undefined,
    LowerPreMolars: undefined,
    UpperPreMolars: undefined,
  };
}

export const ReportStudioOrthoTeethAnalysisPonts = {
  encode(
    message: ReportStudioOrthoTeethAnalysisPonts,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.LowerMolars !== undefined) {
      ReportStudioOrthoTeethAnalysisPonts_Measure.encode(
        message.LowerMolars,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.UpperMolars !== undefined) {
      ReportStudioOrthoTeethAnalysisPonts_Measure.encode(
        message.UpperMolars,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.LowerPreMolars !== undefined) {
      ReportStudioOrthoTeethAnalysisPonts_Measure.encode(
        message.LowerPreMolars,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.UpperPreMolars !== undefined) {
      ReportStudioOrthoTeethAnalysisPonts_Measure.encode(
        message.UpperPreMolars,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioOrthoTeethAnalysisPonts {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioOrthoTeethAnalysisPonts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.LowerMolars =
            ReportStudioOrthoTeethAnalysisPonts_Measure.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 2:
          message.UpperMolars =
            ReportStudioOrthoTeethAnalysisPonts_Measure.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 3:
          message.LowerPreMolars =
            ReportStudioOrthoTeethAnalysisPonts_Measure.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 4:
          message.UpperPreMolars =
            ReportStudioOrthoTeethAnalysisPonts_Measure.decode(
              reader,
              reader.uint32(),
            );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioOrthoTeethAnalysisPonts {
    return {
      LowerMolars: isSet(object.LowerMolars)
        ? ReportStudioOrthoTeethAnalysisPonts_Measure.fromJSON(
            object.LowerMolars,
          )
        : undefined,
      UpperMolars: isSet(object.UpperMolars)
        ? ReportStudioOrthoTeethAnalysisPonts_Measure.fromJSON(
            object.UpperMolars,
          )
        : undefined,
      LowerPreMolars: isSet(object.LowerPreMolars)
        ? ReportStudioOrthoTeethAnalysisPonts_Measure.fromJSON(
            object.LowerPreMolars,
          )
        : undefined,
      UpperPreMolars: isSet(object.UpperPreMolars)
        ? ReportStudioOrthoTeethAnalysisPonts_Measure.fromJSON(
            object.UpperPreMolars,
          )
        : undefined,
    };
  },

  toJSON(message: ReportStudioOrthoTeethAnalysisPonts): unknown {
    const obj: any = {};
    message.LowerMolars !== undefined &&
      (obj.LowerMolars = message.LowerMolars
        ? ReportStudioOrthoTeethAnalysisPonts_Measure.toJSON(
            message.LowerMolars,
          )
        : undefined);
    message.UpperMolars !== undefined &&
      (obj.UpperMolars = message.UpperMolars
        ? ReportStudioOrthoTeethAnalysisPonts_Measure.toJSON(
            message.UpperMolars,
          )
        : undefined);
    message.LowerPreMolars !== undefined &&
      (obj.LowerPreMolars = message.LowerPreMolars
        ? ReportStudioOrthoTeethAnalysisPonts_Measure.toJSON(
            message.LowerPreMolars,
          )
        : undefined);
    message.UpperPreMolars !== undefined &&
      (obj.UpperPreMolars = message.UpperPreMolars
        ? ReportStudioOrthoTeethAnalysisPonts_Measure.toJSON(
            message.UpperPreMolars,
          )
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportStudioOrthoTeethAnalysisPonts>, I>,
  >(object: I): ReportStudioOrthoTeethAnalysisPonts {
    const message = createBaseReportStudioOrthoTeethAnalysisPonts();
    message.LowerMolars =
      object.LowerMolars !== undefined && object.LowerMolars !== null
        ? ReportStudioOrthoTeethAnalysisPonts_Measure.fromPartial(
            object.LowerMolars,
          )
        : undefined;
    message.UpperMolars =
      object.UpperMolars !== undefined && object.UpperMolars !== null
        ? ReportStudioOrthoTeethAnalysisPonts_Measure.fromPartial(
            object.UpperMolars,
          )
        : undefined;
    message.LowerPreMolars =
      object.LowerPreMolars !== undefined && object.LowerPreMolars !== null
        ? ReportStudioOrthoTeethAnalysisPonts_Measure.fromPartial(
            object.LowerPreMolars,
          )
        : undefined;
    message.UpperPreMolars =
      object.UpperPreMolars !== undefined && object.UpperPreMolars !== null
        ? ReportStudioOrthoTeethAnalysisPonts_Measure.fromPartial(
            object.UpperPreMolars,
          )
        : undefined;
    return message;
  },
};

function createBaseReportStudioOrthoTeethAnalysisPonts_Measure(): ReportStudioOrthoTeethAnalysisPonts_Measure {
  return { Norm: 0, Fact: 0, Diff: 0 };
}

export const ReportStudioOrthoTeethAnalysisPonts_Measure = {
  encode(
    message: ReportStudioOrthoTeethAnalysisPonts_Measure,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Norm !== 0) {
      writer.uint32(13).float(message.Norm);
    }
    if (message.Fact !== 0) {
      writer.uint32(21).float(message.Fact);
    }
    if (message.Diff !== 0) {
      writer.uint32(29).float(message.Diff);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioOrthoTeethAnalysisPonts_Measure {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioOrthoTeethAnalysisPonts_Measure();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Norm = reader.float();
          break;
        case 2:
          message.Fact = reader.float();
          break;
        case 3:
          message.Diff = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioOrthoTeethAnalysisPonts_Measure {
    return {
      Norm: isSet(object.Norm) ? Number(object.Norm) : 0,
      Fact: isSet(object.Fact) ? Number(object.Fact) : 0,
      Diff: isSet(object.Diff) ? Number(object.Diff) : 0,
    };
  },

  toJSON(message: ReportStudioOrthoTeethAnalysisPonts_Measure): unknown {
    const obj: any = {};
    message.Norm !== undefined && (obj.Norm = message.Norm);
    message.Fact !== undefined && (obj.Fact = message.Fact);
    message.Diff !== undefined && (obj.Diff = message.Diff);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportStudioOrthoTeethAnalysisPonts_Measure>,
      I
    >,
  >(object: I): ReportStudioOrthoTeethAnalysisPonts_Measure {
    const message = createBaseReportStudioOrthoTeethAnalysisPonts_Measure();
    message.Norm = object.Norm ?? 0;
    message.Fact = object.Fact ?? 0;
    message.Diff = object.Diff ?? 0;
    return message;
  },
};

function createBaseReportStudioOrthoTeethAnalysisTonnBolton(): ReportStudioOrthoTeethAnalysisTonnBolton {
  return { Tonn: undefined, Bolton: undefined };
}

export const ReportStudioOrthoTeethAnalysisTonnBolton = {
  encode(
    message: ReportStudioOrthoTeethAnalysisTonnBolton,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tonn !== undefined) {
      ReportStudioOrthoTeethAnalysisTonnBolton_Measure.encode(
        message.Tonn,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.Bolton !== undefined) {
      ReportStudioOrthoTeethAnalysisTonnBolton_Measure.encode(
        message.Bolton,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioOrthoTeethAnalysisTonnBolton {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioOrthoTeethAnalysisTonnBolton();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tonn =
            ReportStudioOrthoTeethAnalysisTonnBolton_Measure.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 2:
          message.Bolton =
            ReportStudioOrthoTeethAnalysisTonnBolton_Measure.decode(
              reader,
              reader.uint32(),
            );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioOrthoTeethAnalysisTonnBolton {
    return {
      Tonn: isSet(object.Tonn)
        ? ReportStudioOrthoTeethAnalysisTonnBolton_Measure.fromJSON(object.Tonn)
        : undefined,
      Bolton: isSet(object.Bolton)
        ? ReportStudioOrthoTeethAnalysisTonnBolton_Measure.fromJSON(
            object.Bolton,
          )
        : undefined,
    };
  },

  toJSON(message: ReportStudioOrthoTeethAnalysisTonnBolton): unknown {
    const obj: any = {};
    message.Tonn !== undefined &&
      (obj.Tonn = message.Tonn
        ? ReportStudioOrthoTeethAnalysisTonnBolton_Measure.toJSON(message.Tonn)
        : undefined);
    message.Bolton !== undefined &&
      (obj.Bolton = message.Bolton
        ? ReportStudioOrthoTeethAnalysisTonnBolton_Measure.toJSON(
            message.Bolton,
          )
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportStudioOrthoTeethAnalysisTonnBolton>, I>,
  >(object: I): ReportStudioOrthoTeethAnalysisTonnBolton {
    const message = createBaseReportStudioOrthoTeethAnalysisTonnBolton();
    message.Tonn =
      object.Tonn !== undefined && object.Tonn !== null
        ? ReportStudioOrthoTeethAnalysisTonnBolton_Measure.fromPartial(
            object.Tonn,
          )
        : undefined;
    message.Bolton =
      object.Bolton !== undefined && object.Bolton !== null
        ? ReportStudioOrthoTeethAnalysisTonnBolton_Measure.fromPartial(
            object.Bolton,
          )
        : undefined;
    return message;
  },
};

function createBaseReportStudioOrthoTeethAnalysisTonnBolton_Measure(): ReportStudioOrthoTeethAnalysisTonnBolton_Measure {
  return { IndexNorm: 0, IndexFact: 0, DiffUpper: 0, DiffLower: 0 };
}

export const ReportStudioOrthoTeethAnalysisTonnBolton_Measure = {
  encode(
    message: ReportStudioOrthoTeethAnalysisTonnBolton_Measure,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.IndexNorm !== 0) {
      writer.uint32(13).float(message.IndexNorm);
    }
    if (message.IndexFact !== 0) {
      writer.uint32(21).float(message.IndexFact);
    }
    if (message.DiffUpper !== 0) {
      writer.uint32(29).float(message.DiffUpper);
    }
    if (message.DiffLower !== 0) {
      writer.uint32(37).float(message.DiffLower);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioOrthoTeethAnalysisTonnBolton_Measure {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseReportStudioOrthoTeethAnalysisTonnBolton_Measure();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IndexNorm = reader.float();
          break;
        case 2:
          message.IndexFact = reader.float();
          break;
        case 3:
          message.DiffUpper = reader.float();
          break;
        case 4:
          message.DiffLower = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioOrthoTeethAnalysisTonnBolton_Measure {
    return {
      IndexNorm: isSet(object.IndexNorm) ? Number(object.IndexNorm) : 0,
      IndexFact: isSet(object.IndexFact) ? Number(object.IndexFact) : 0,
      DiffUpper: isSet(object.DiffUpper) ? Number(object.DiffUpper) : 0,
      DiffLower: isSet(object.DiffLower) ? Number(object.DiffLower) : 0,
    };
  },

  toJSON(message: ReportStudioOrthoTeethAnalysisTonnBolton_Measure): unknown {
    const obj: any = {};
    message.IndexNorm !== undefined && (obj.IndexNorm = message.IndexNorm);
    message.IndexFact !== undefined && (obj.IndexFact = message.IndexFact);
    message.DiffUpper !== undefined && (obj.DiffUpper = message.DiffUpper);
    message.DiffLower !== undefined && (obj.DiffLower = message.DiffLower);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportStudioOrthoTeethAnalysisTonnBolton_Measure>,
      I
    >,
  >(object: I): ReportStudioOrthoTeethAnalysisTonnBolton_Measure {
    const message =
      createBaseReportStudioOrthoTeethAnalysisTonnBolton_Measure();
    message.IndexNorm = object.IndexNorm ?? 0;
    message.IndexFact = object.IndexFact ?? 0;
    message.DiffUpper = object.DiffUpper ?? 0;
    message.DiffLower = object.DiffLower ?? 0;
    return message;
  },
};

function createBaseReportStudioOrthoTeethAnalysisLeftRightMeasure(): ReportStudioOrthoTeethAnalysisLeftRightMeasure {
  return { Lower: undefined, Upper: undefined };
}

export const ReportStudioOrthoTeethAnalysisLeftRightMeasure = {
  encode(
    message: ReportStudioOrthoTeethAnalysisLeftRightMeasure,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Lower !== undefined) {
      ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure.encode(
        message.Lower,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Upper !== undefined) {
      ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure.encode(
        message.Upper,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioOrthoTeethAnalysisLeftRightMeasure {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStudioOrthoTeethAnalysisLeftRightMeasure();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.Lower =
            ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 1:
          message.Upper =
            ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure.decode(
              reader,
              reader.uint32(),
            );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStudioOrthoTeethAnalysisLeftRightMeasure {
    return {
      Lower: isSet(object.Lower)
        ? ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure.fromJSON(
            object.Lower,
          )
        : undefined,
      Upper: isSet(object.Upper)
        ? ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure.fromJSON(
            object.Upper,
          )
        : undefined,
    };
  },

  toJSON(message: ReportStudioOrthoTeethAnalysisLeftRightMeasure): unknown {
    const obj: any = {};
    message.Lower !== undefined &&
      (obj.Lower = message.Lower
        ? ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure.toJSON(
            message.Lower,
          )
        : undefined);
    message.Upper !== undefined &&
      (obj.Upper = message.Upper
        ? ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure.toJSON(
            message.Upper,
          )
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportStudioOrthoTeethAnalysisLeftRightMeasure>,
      I
    >,
  >(object: I): ReportStudioOrthoTeethAnalysisLeftRightMeasure {
    const message = createBaseReportStudioOrthoTeethAnalysisLeftRightMeasure();
    message.Lower =
      object.Lower !== undefined && object.Lower !== null
        ? ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure.fromPartial(
            object.Lower,
          )
        : undefined;
    message.Upper =
      object.Upper !== undefined && object.Upper !== null
        ? ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure.fromPartial(
            object.Upper,
          )
        : undefined;
    return message;
  },
};

function createBaseReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure(): ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure {
  return { Left: 0, Right: 0, Total: 0 };
}

export const ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure = {
  encode(
    message: ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Left !== 0) {
      writer.uint32(13).float(message.Left);
    }
    if (message.Right !== 0) {
      writer.uint32(21).float(message.Right);
    }
    if (message.Total !== 0) {
      writer.uint32(29).float(message.Total);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Left = reader.float();
          break;
        case 2:
          message.Right = reader.float();
          break;
        case 3:
          message.Total = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(
    object: any,
  ): ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure {
    return {
      Left: isSet(object.Left) ? Number(object.Left) : 0,
      Right: isSet(object.Right) ? Number(object.Right) : 0,
      Total: isSet(object.Total) ? Number(object.Total) : 0,
    };
  },

  toJSON(
    message: ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure,
  ): unknown {
    const obj: any = {};
    message.Left !== undefined && (obj.Left = message.Left);
    message.Right !== undefined && (obj.Right = message.Right);
    message.Total !== undefined && (obj.Total = message.Total);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure>,
      I
    >,
  >(object: I): ReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure {
    const message =
      createBaseReportStudioOrthoTeethAnalysisLeftRightMeasure_Measure();
    message.Left = object.Left ?? 0;
    message.Right = object.Right ?? 0;
    message.Total = object.Total ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
