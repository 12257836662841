import { LogicalCondition } from '@/entities/logicalCondition';

const findMaskAssetIdInCondition = (
  condition: LogicalCondition,
  maskAssetID: string,
) =>
  condition.Localizations.find(
    (localization) => localization.MaskAssetID === maskAssetID,
  );

export const findMaskCondition = (
  conditions: LogicalCondition[],
  maskAssetID: string,
) =>
  conditions.find((condition) =>
    findMaskAssetIdInCondition(condition, maskAssetID),
  );
