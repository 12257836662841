import { AccountBillingInformation } from '@/shared/api/protocol_gen/api/billing/dto_account';

import { BILLING_ZONE_WITHOUT_STRIPE_REGEXP } from '../config/constants';

export const shouldAskAdditionalInfo = (
  billingZone: string,
  billingInformation: AccountBillingInformation,
): boolean =>
  !BILLING_ZONE_WITHOUT_STRIPE_REGEXP.test(billingZone) &&
  (!billingInformation?.Address?.Address ||
    !billingInformation?.Phone ||
    !billingInformation?.CompanyName ||
    !billingInformation?.TaxID);
