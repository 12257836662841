import { Localization } from '@/shared/api/protocol_gen/model/dto_report_localization';

// Can we unite and unify this with condition colors?
export type ToothType =
  | 'unhealthy'
  | 'lowProbability'
  | 'treated'
  | 'healthy'
  | 'missing'
  | 'all';

export type ToothTypesSet = Record<string, ToothType>;

export enum DentalNotations {
  ISO = 'ISO',
  UNIVERSAL = 'UNIVERSAL',
}

export type DentalNotation = keyof typeof DentalNotations;

export type TeethChartFilter = {
  type: ToothType;
  title: string;
  ISONumbers: number[];
  active: boolean;
};

export type ToothWithLocalization = {
  toothID: string;
  ISONumber: number;
  Localization: Localization;
};

export type RudimentTeeth =
  | 55
  | 54
  | 53
  | 52
  | 51
  | 61
  | 62
  | 63
  | 64
  | 65
  | 71
  | 72
  | 73
  | 74
  | 75
  | 85
  | 84
  | 83
  | 82;
