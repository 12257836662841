// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReportImage-module__IOXRayMatrix--WpNSmfTT{height:400px;min-height:300px;max-height:400px;padding:24px;margin-bottom:10px;overflow:hidden}", "",{"version":3,"sources":["webpack://./src/pages/GpReport/ui/ReportImage/ReportImage.module.scss"],"names":[],"mappings":"AAAA,4CACE,YAAA,CACA,gBAAA,CACA,gBAAA,CACA,YAAA,CACA,kBAAA,CACA,eAAA","sourcesContent":[".IOXRayMatrix {\n  height: 400px;\n  min-height: 300px;\n  max-height: 400px;\n  padding: 24px;\n  margin-bottom: 10px;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IOXRayMatrix": "ReportImage-module__IOXRayMatrix--WpNSmfTT"
};
export default ___CSS_LOADER_EXPORT___;
