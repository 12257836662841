import React, { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { MedicalImage } from '@/shared/ui';
// TODO: Move it to lower slice. We have to many widgets - this is not reusable and we lack in flexibility
import { useAppSelector } from '@/shared/hooks';
import { DEFAULT_MEDICAL_IMAGE_HEIGHT, ToothStatus } from '@/shared/config';
import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { RCCropImage } from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImage';
import { RCContainer } from '@/shared/graphics/RenderComponents/RCContainer/RCContainer';
import { ObjectFitContain } from '@/shared/graphics/RenderComponents/ObjectFitContain';
import { AnnotationsLayer } from '@/shared/graphics/RenderComponents/AnnotationsLayer/AnnotationsLayer';
import { AbsoluteBlock } from '@/shared/graphics/RenderComponents/AbsoluteBlock';

import { isMissingThirdMolarTooth } from '@/entities/tooth';
import {
  getImageSrc,
  makeAnnotations,
  makeImageViewOptions,
  useGetSelectedMedicalImages,
} from '@/entities/assets';
import {
  ConditionColor,
  conditionModel,
  getToothStatus,
  selectChildConditionInterfacesByGroup,
  useToothConditions,
} from '@/entities/condition';
import { transformBBoxToCrop } from '@/entities/tooth/lib/transformBBoxToCropp';

import {
  MedicalImageLabelsTypes,
  medicalImagesLabels,
  getMedicalImageLabel,
} from '@/widgets/ToothCard';

import { ContextRequest } from 'graphics';

import styles from './PreviewToothCard.module.scss';

type PreviewToothCardProps = {
  tooth: Tooth;
  showProbability: boolean;
  showConditions: boolean;
  showMedicalImages: boolean;
  inverted: boolean;
  blackAndWhiteMode: boolean;
  report?: Report;
};

const colorStyle: Record<ConditionColor, string> = {
  yellow: styles.colorYellow,
  red: styles.colorRed,
  purple: styles.colorPurple,
  white: styles.colorWhite,
};

export const PreviewToothCard: FC<PreviewToothCardProps> = (props) => {
  const {
    tooth,
    showProbability,
    showConditions,
    showMedicalImages,
    inverted,
    blackAndWhiteMode,
    report,
  } = props;

  const toothConditions = useAppSelector(
    conditionModel.selectors.selectByToothID(tooth.ID),
  );

  const { formatMessage } = useIntl();

  const reportType = report?.Type;

  const showLowProbability = report?.Settings?.LowProbabilityMode ?? false;

  const showToothCard = !!tooth.Comment || showProbability || showMedicalImages;

  const {
    anatomyConditionItems,
    generalConditionItems,
    groupedConditionItems,
  } = useToothConditions({
    toothID: tooth.ID,
    showLowProbability,
  });

  const medicalImages = useGetSelectedMedicalImages(tooth.ID);

  const conditionItems = [...anatomyConditionItems, ...generalConditionItems];

  const toothStatus = getToothStatus(toothConditions, showLowProbability);

  const isMissingThirdMolar = isMissingThirdMolarTooth(
    toothStatus,
    tooth.Numeration?.ISO ?? 0,
  );

  const shouldShowCroppedImages =
    (reportType === ReportType.ReportType_IOXRay_GP ||
      reportType === ReportType.ReportType_Pano_GP) &&
    showMedicalImages;

  return (
    <div
      className={cn(styles.container, { [styles.invisible]: !showToothCard })}
    >
      <div
        className={cn(
          styles.header,
          toothStatus === ToothStatus.missing && styles.missingTooth,
        )}
      >
        <div className={styles.toothNumberWrapper}>
          {blackAndWhiteMode && !isMissingThirdMolar && (
            <img
              className={styles.blackAndWhiteIcon}
              src={
                // eslint-disable-next-line import/no-dynamic-require, global-require
                require(`../../../../shared/assets/images/ToothStatusIcon/${toothStatus}IconB&W.svg`)
              }
              alt={`${toothStatus}`}
            />
          )}

          <h3
            className={cn(
              'h3',
              !blackAndWhiteMode
                ? !isMissingThirdMolar && `${toothStatus}ToothNumber`
                : styles.blackAndWhiteToothNumber,
            )}
          >
            <FormattedMessage
              id="toothCard.title"
              defaultMessage="Tooth {number}"
              values={{
                number: tooth?.Numeration?.ISO,
              }}
            />
          </h3>
        </div>

        {showConditions && (
          <div className={styles.toothConditionContainer}>
            {conditionItems.map((item, index) => {
              const conditionInterfaceGroup = groupedConditionItems[item.group];

              const selectedChildConditions =
                selectChildConditionInterfacesByGroup(item.code)(
                  conditionInterfaceGroup,
                );

              const showTags = !!selectedChildConditions?.length;

              const isLastCondition = index === conditionItems.length - 1;
              const withChildConditions = selectedChildConditions?.length;

              return (
                <React.Fragment key={item.id}>
                  <span
                    className={cn(
                      styles.toothCondition,
                      !blackAndWhiteMode &&
                        !isMissingThirdMolar &&
                        colorStyle[item.color],
                      'p1',
                    )}
                  >
                    <span className={styles.toothConditionText}>
                      {item.text}
                      {showProbability && (
                        <sup className={styles.percent}>
                          {item.probabilityText}
                        </sup>
                      )}
                      {!isLastCondition && !withChildConditions && ', '}
                    </span>

                    {/* TODO: DRY - conditions and tags looks similar */}
                  </span>
                  {showTags &&
                    selectedChildConditions.map((tag, tagIndex) => (
                      <span
                        key={tag.id}
                        className={cn(
                          styles.toothCondition,
                          !blackAndWhiteMode &&
                            !isMissingThirdMolar &&
                            colorStyle[tag.color],
                          'p2',
                        )}
                      >
                        {tagIndex === 0 && showProbability ? `  (` : ` (`}
                        <span className={styles.toothConditionText}>
                          {tag.text}
                        </span>

                        {showProbability && (
                          <sup className={styles.percent}>
                            {tag.probabilityText}
                          </sup>
                        )}
                        {tagIndex !== selectedChildConditions.length - 1
                          ? ',  '
                          : `)  `}
                      </span>
                    ))}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>

      {shouldShowCroppedImages && (
        <div className={styles.medicalImages}>
          {tooth.Localizations.map((localization) => {
            const { BBox, TargetAssetID } = localization;
            const crop = transformBBoxToCrop(BBox);
            const cropImageSrc: ContextRequest = {
              url: getImageSrc(TargetAssetID, 'original'),
              kind: 'raster',
            };

            const viewOptions = makeImageViewOptions(
              localization?.CropMedicalImageFeatures?.ViewOptions,
            );

            const annotations = makeAnnotations(
              localization?.CropMedicalImageFeatures?.Annotations?.Layers,
            );

            return (
              <RCContainer
                style={{ height: DEFAULT_MEDICAL_IMAGE_HEIGHT }}
                key={localization.ID}
              >
                <RCCropImage
                  style={{
                    height: DEFAULT_MEDICAL_IMAGE_HEIGHT,
                    boxShadow: '0 0 0 1px rgba(var(--black-rgb), .2)',
                    borderRadius: '8px',
                  }}
                  uniforms={{
                    invert: inverted,
                    ww: viewOptions.wwwc.ww,
                    wc: viewOptions.wwwc.wc,
                    sharpness: viewOptions.sharpness,
                  }}
                  crop={crop}
                  src={cropImageSrc}
                />
                <AbsoluteBlock style={{ top: 0 }}>
                  <ObjectFitContain objectWidth={crop.w} objectHeight={crop.h}>
                    <AnnotationsLayer
                      pixelSpacing={[1, 1]}
                      annotations={annotations}
                      annotationKind="eraser"
                      setAnnotations={() => {}}
                      editable={false}
                    />
                  </ObjectFitContain>
                </AbsoluteBlock>
              </RCContainer>
            );
          })}
        </div>
      )}
      {showMedicalImages && (
        <div className={styles.medicalImages}>
          {medicalImages?.map((medicalImage) => {
            const medicalImageLabel = getMedicalImageLabel(
              medicalImage?.path ?? '',
            ) as MedicalImageLabelsTypes;
            const label = medicalImagesLabels[medicalImageLabel]
              ? formatMessage(medicalImagesLabels[medicalImageLabel])
              : '';

            return (
              <MedicalImage
                key={medicalImage.src}
                medicalImage={medicalImage}
                medicalImageLabel={label}
                annotations={medicalImage.annotations}
                inverted={inverted}
                sharpness={medicalImage.viewOptions?.sharpness}
                ww={medicalImage.viewOptions?.wwwc.ww}
                wc={medicalImage.viewOptions?.wwwc.wc}
                className={styles.notHovered}
              />
            );
          })}
        </div>
      )}

      {tooth?.Comment && <p className="p2">{tooth.Comment}</p>}
    </div>
  );
};
