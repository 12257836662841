// TODO: Resolve the issue with the types
// @ts-nocheck
import { useMemo } from 'react';
import { find, propEq } from 'ramda';
import { useIntl } from 'react-intl';

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { LogicalCondition } from '@/entities/logicalCondition';

import {
  getConditionGroupByCode,
  isConditionUncertain,
  getConditionCodeColor,
  getConditionModelScorePercentage,
  shouldConditionItemBeShown,
} from '../lib';
import {
  ChildConditionGroups,
  FLAT_CONDITION_GROUPS,
  CHILD_CONDITION_GROUPS,
  ONE_OF_CONDITION_GROUPS,
  ConditionGroups,
} from '../config/const';
import { ConditionInterface, ChildConditionInterface } from '../config/types';
import { conditionText } from '../config/i18n';

// Return all possible condition items or only transform received conditions
export const useTransformCondition = (
  conditions: LogicalCondition[],
  allCodes: boolean = true,
  showLowProbability: boolean = false,
): ConditionInterface[] => {
  const { formatMessage } = useIntl();

  const conditionItems = useMemo((): ConditionInterface[] => {
    if (allCodes) {
      return FLAT_CONDITION_GROUPS.map((code) => {
        const condition = find<Condition>(
          propEq<keyof Condition>('Code')(code),
        )(conditions);

        const conditionColor = getConditionCodeColor(
          code,
          isConditionUncertain(condition),
          showLowProbability,
        );

        const isEndo = code === ConditionCode.EndoTreated;

        const conditionGroup = getConditionGroupByCode(code);

        const childConditionInterfaces: ChildConditionInterface[] =
          code in CHILD_CONDITION_GROUPS
            ? Object.entries(
                CHILD_CONDITION_GROUPS[code] as Record<
                  Exclude<
                    ConditionCode,
                    ConditionCode.ConditionCodeInvalidValue
                  >,
                  Record<ConditionGroups, ConditionCode[]>
                >,
              ).flatMap(
                ([group, codes]: [ChildConditionGroups, ConditionCode[]]) =>
                  codes.map((childCode): ChildConditionInterface => {
                    const childCondition = find<Condition>(
                      propEq<keyof Condition>('Code')(childCode),
                    )(conditions);

                    const childConditionColor = getConditionCodeColor(
                      childCode,
                      isConditionUncertain(childCondition),
                      showLowProbability,
                    );

                    return {
                      id: childCondition?.ID ?? '',
                      ids: childCondition?.IDs ?? [],
                      code: childCode,
                      parentConditionCode: code,
                      parentConditionId: condition?.ID,
                      group,
                      text: conditionText[childCode]
                        ? formatMessage(conditionText[childCode])
                        : '',
                      color: isEndo ? childConditionColor : conditionColor,
                      probability: childCondition?.Certainty?.ModelScore,
                      probabilityText:
                        getConditionModelScorePercentage(childCondition),
                      isChecked:
                        shouldConditionItemBeShown(showLowProbability)(
                          childCondition,
                        ),
                      isOneOf: false,
                    };
                  }),
              )
            : [];

        return {
          id: condition?.ID ?? '',
          ids: condition?.IDs ?? [],
          code,
          group: conditionGroup,
          text: conditionText[code] && formatMessage(conditionText[code]),
          color: conditionColor,
          probability: condition?.Certainty?.ModelScore,
          probabilityText: getConditionModelScorePercentage(condition),
          isChecked: shouldConditionItemBeShown(showLowProbability)(condition),
          isOneOf: ONE_OF_CONDITION_GROUPS.includes(conditionGroup),
          childConditionInterfaces,
        };
      });
    }

    return conditions.map((condition) => {
      const code = condition.Code;
      const conditionColor = getConditionCodeColor(
        code,
        isConditionUncertain(condition),
        showLowProbability,
      );
      const conditionGroup = getConditionGroupByCode(code);

      return {
        id: condition?.ID,
        ids: condition?.IDs ?? [],
        code: condition.Code,
        group: conditionGroup,
        text: conditionText[code] && formatMessage(conditionText[code]),
        color: conditionColor,
        probability: condition?.Certainty?.ModelScore,
        probabilityText: getConditionModelScorePercentage(condition),
        isChecked: shouldConditionItemBeShown(showLowProbability)(condition),
        isOneOf: ONE_OF_CONDITION_GROUPS.includes(conditionGroup),
      };
    });
  }, [conditions, allCodes, showLowProbability, formatMessage]);

  return conditionItems;
};
