import React, { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileWithPath } from 'react-dropzone';

import { Button } from '@/shared/ui';

import styles from './UploadPreview.module.scss';

type UploadPreviewProps = {
  className?: string;
  previewImage: string;
  onChange: (image: File | FileWithPath) => void;
  onRemove?: (assetID: string) => void;
  imageAssetID?: string;
};

export const UploadPreview: FC<UploadPreviewProps> = (props) => {
  const { className, previewImage, onRemove, onChange, imageAssetID } = props;

  const { formatMessage } = useIntl();

  const handleRemove = () => {
    onChange({} as File);

    if (onRemove && imageAssetID) {
      onRemove(imageAssetID);
    }
  };

  return (
    <div className={cn(styles.container, className)}>
      <img
        src={previewImage}
        className={styles.previewImage}
        alt={formatMessage({
          id: 'uploadImage.imagePreview',
          defaultMessage: 'Image Preview',
        })}
      />

      {onRemove && (
        <Button
          size="medium"
          onClick={handleRemove}
          className={cn(styles.removeButton, 'p2')}
        >
          <FormattedMessage
            id="global.RemoveImage"
            defaultMessage="Remove image"
          />
        </Button>
      )}
    </div>
  );
};
