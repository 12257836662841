/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  ServiceType,
  serviceTypeFromJSON,
  serviceTypeToJSON,
} from '../../api/billing_new/dto_services_new';

export const protobufPackage = 'com.diagnocat.billing_new';

export interface Consumption {
  ID: string;
  AccountID: string;
  ServiceType: ServiceType;
  CreatedAt: number;
}

function createBaseConsumption(): Consumption {
  return { ID: '', AccountID: '', ServiceType: 0, CreatedAt: 0 };
}

export const Consumption = {
  encode(
    message: Consumption,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.AccountID !== '') {
      writer.uint32(18).string(message.AccountID);
    }
    if (message.ServiceType !== 0) {
      writer.uint32(24).int32(message.ServiceType);
    }
    if (message.CreatedAt !== 0) {
      writer.uint32(32).int64(message.CreatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumption {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumption();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.AccountID = reader.string();
          break;
        case 3:
          message.ServiceType = reader.int32() as any;
          break;
        case 4:
          message.CreatedAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Consumption {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      ServiceType: isSet(object.ServiceType)
        ? serviceTypeFromJSON(object.ServiceType)
        : 0,
      CreatedAt: isSet(object.CreatedAt) ? Number(object.CreatedAt) : 0,
    };
  },

  toJSON(message: Consumption): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.ServiceType !== undefined &&
      (obj.ServiceType = serviceTypeToJSON(message.ServiceType));
    message.CreatedAt !== undefined &&
      (obj.CreatedAt = Math.round(message.CreatedAt));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Consumption>, I>>(
    object: I,
  ): Consumption {
    const message = createBaseConsumption();
    message.ID = object.ID ?? '';
    message.AccountID = object.AccountID ?? '';
    message.ServiceType = object.ServiceType ?? 0;
    message.CreatedAt = object.CreatedAt ?? 0;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
