// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScanInfo-module__container--O7yq9ks7{width:100%;display:flex;flex-direction:column;gap:8px}.ScanInfo-module__scanElemRow--VHIrnAbO{width:100%;display:flex;justify-content:flex-start;align-items:center;gap:24px}.ScanInfo-module__scanLabel---dIqcIBz{min-width:180px;color:var(--greytext)}", "",{"version":3,"sources":["webpack://./src/widgets/StudyInformation/ui/ScanInfo/ScanInfo.module.scss"],"names":[],"mappings":"AAEA,sCACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,wCACE,UAAA,CACA,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,QAAA,CAGF,sCACE,eAAA,CACA,qBAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.scanElemRow {\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 24px;\n}\n\n.scanLabel {\n  min-width: 180px;\n  color: var(--greytext);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ScanInfo-module__container--O7yq9ks7",
	"scanElemRow": "ScanInfo-module__scanElemRow--VHIrnAbO",
	"scanLabel": "ScanInfo-module__scanLabel---dIqcIBz"
};
export default ___CSS_LOADER_EXPORT___;
