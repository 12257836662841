/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

export interface StudyPermissions {
  CanView: boolean;
  CanRequestCBCTGPReport: boolean;
  CanRequestCBCTEndoReport: boolean;
  CanRequestCBCTImplantReport: boolean;
  CanRequestCBCTOrthoSlidesReport: boolean;
  CanRequestCBCTSegmentationReport: boolean;
  CanRequestIOXRayReport: boolean;
  CanRequestPanoReport: boolean;
  CanRequestDentalPhotoReport: boolean;
  CanChangeMedicalImageViewOptions: boolean;
  CanDelete: boolean;
}

function createBaseStudyPermissions(): StudyPermissions {
  return {
    CanView: false,
    CanRequestCBCTGPReport: false,
    CanRequestCBCTEndoReport: false,
    CanRequestCBCTImplantReport: false,
    CanRequestCBCTOrthoSlidesReport: false,
    CanRequestCBCTSegmentationReport: false,
    CanRequestIOXRayReport: false,
    CanRequestPanoReport: false,
    CanRequestDentalPhotoReport: false,
    CanChangeMedicalImageViewOptions: false,
    CanDelete: false,
  };
}

export const StudyPermissions = {
  encode(
    message: StudyPermissions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CanView === true) {
      writer.uint32(8).bool(message.CanView);
    }
    if (message.CanRequestCBCTGPReport === true) {
      writer.uint32(16).bool(message.CanRequestCBCTGPReport);
    }
    if (message.CanRequestCBCTEndoReport === true) {
      writer.uint32(24).bool(message.CanRequestCBCTEndoReport);
    }
    if (message.CanRequestCBCTImplantReport === true) {
      writer.uint32(32).bool(message.CanRequestCBCTImplantReport);
    }
    if (message.CanRequestCBCTOrthoSlidesReport === true) {
      writer.uint32(40).bool(message.CanRequestCBCTOrthoSlidesReport);
    }
    if (message.CanRequestCBCTSegmentationReport === true) {
      writer.uint32(48).bool(message.CanRequestCBCTSegmentationReport);
    }
    if (message.CanRequestIOXRayReport === true) {
      writer.uint32(56).bool(message.CanRequestIOXRayReport);
    }
    if (message.CanRequestPanoReport === true) {
      writer.uint32(64).bool(message.CanRequestPanoReport);
    }
    if (message.CanRequestDentalPhotoReport === true) {
      writer.uint32(72).bool(message.CanRequestDentalPhotoReport);
    }
    if (message.CanChangeMedicalImageViewOptions === true) {
      writer.uint32(80).bool(message.CanChangeMedicalImageViewOptions);
    }
    if (message.CanDelete === true) {
      writer.uint32(88).bool(message.CanDelete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StudyPermissions {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStudyPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CanView = reader.bool();
          break;
        case 2:
          message.CanRequestCBCTGPReport = reader.bool();
          break;
        case 3:
          message.CanRequestCBCTEndoReport = reader.bool();
          break;
        case 4:
          message.CanRequestCBCTImplantReport = reader.bool();
          break;
        case 5:
          message.CanRequestCBCTOrthoSlidesReport = reader.bool();
          break;
        case 6:
          message.CanRequestCBCTSegmentationReport = reader.bool();
          break;
        case 7:
          message.CanRequestIOXRayReport = reader.bool();
          break;
        case 8:
          message.CanRequestPanoReport = reader.bool();
          break;
        case 9:
          message.CanRequestDentalPhotoReport = reader.bool();
          break;
        case 10:
          message.CanChangeMedicalImageViewOptions = reader.bool();
          break;
        case 11:
          message.CanDelete = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StudyPermissions {
    return {
      CanView: isSet(object.CanView) ? Boolean(object.CanView) : false,
      CanRequestCBCTGPReport: isSet(object.CanRequestCBCTGPReport)
        ? Boolean(object.CanRequestCBCTGPReport)
        : false,
      CanRequestCBCTEndoReport: isSet(object.CanRequestCBCTEndoReport)
        ? Boolean(object.CanRequestCBCTEndoReport)
        : false,
      CanRequestCBCTImplantReport: isSet(object.CanRequestCBCTImplantReport)
        ? Boolean(object.CanRequestCBCTImplantReport)
        : false,
      CanRequestCBCTOrthoSlidesReport: isSet(
        object.CanRequestCBCTOrthoSlidesReport,
      )
        ? Boolean(object.CanRequestCBCTOrthoSlidesReport)
        : false,
      CanRequestCBCTSegmentationReport: isSet(
        object.CanRequestCBCTSegmentationReport,
      )
        ? Boolean(object.CanRequestCBCTSegmentationReport)
        : false,
      CanRequestIOXRayReport: isSet(object.CanRequestIOXRayReport)
        ? Boolean(object.CanRequestIOXRayReport)
        : false,
      CanRequestPanoReport: isSet(object.CanRequestPanoReport)
        ? Boolean(object.CanRequestPanoReport)
        : false,
      CanRequestDentalPhotoReport: isSet(object.CanRequestDentalPhotoReport)
        ? Boolean(object.CanRequestDentalPhotoReport)
        : false,
      CanChangeMedicalImageViewOptions: isSet(
        object.CanChangeMedicalImageViewOptions,
      )
        ? Boolean(object.CanChangeMedicalImageViewOptions)
        : false,
      CanDelete: isSet(object.CanDelete) ? Boolean(object.CanDelete) : false,
    };
  },

  toJSON(message: StudyPermissions): unknown {
    const obj: any = {};
    message.CanView !== undefined && (obj.CanView = message.CanView);
    message.CanRequestCBCTGPReport !== undefined &&
      (obj.CanRequestCBCTGPReport = message.CanRequestCBCTGPReport);
    message.CanRequestCBCTEndoReport !== undefined &&
      (obj.CanRequestCBCTEndoReport = message.CanRequestCBCTEndoReport);
    message.CanRequestCBCTImplantReport !== undefined &&
      (obj.CanRequestCBCTImplantReport = message.CanRequestCBCTImplantReport);
    message.CanRequestCBCTOrthoSlidesReport !== undefined &&
      (obj.CanRequestCBCTOrthoSlidesReport =
        message.CanRequestCBCTOrthoSlidesReport);
    message.CanRequestCBCTSegmentationReport !== undefined &&
      (obj.CanRequestCBCTSegmentationReport =
        message.CanRequestCBCTSegmentationReport);
    message.CanRequestIOXRayReport !== undefined &&
      (obj.CanRequestIOXRayReport = message.CanRequestIOXRayReport);
    message.CanRequestPanoReport !== undefined &&
      (obj.CanRequestPanoReport = message.CanRequestPanoReport);
    message.CanRequestDentalPhotoReport !== undefined &&
      (obj.CanRequestDentalPhotoReport = message.CanRequestDentalPhotoReport);
    message.CanChangeMedicalImageViewOptions !== undefined &&
      (obj.CanChangeMedicalImageViewOptions =
        message.CanChangeMedicalImageViewOptions);
    message.CanDelete !== undefined && (obj.CanDelete = message.CanDelete);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<StudyPermissions>, I>>(
    object: I,
  ): StudyPermissions {
    const message = createBaseStudyPermissions();
    message.CanView = object.CanView ?? false;
    message.CanRequestCBCTGPReport = object.CanRequestCBCTGPReport ?? false;
    message.CanRequestCBCTEndoReport = object.CanRequestCBCTEndoReport ?? false;
    message.CanRequestCBCTImplantReport =
      object.CanRequestCBCTImplantReport ?? false;
    message.CanRequestCBCTOrthoSlidesReport =
      object.CanRequestCBCTOrthoSlidesReport ?? false;
    message.CanRequestCBCTSegmentationReport =
      object.CanRequestCBCTSegmentationReport ?? false;
    message.CanRequestIOXRayReport = object.CanRequestIOXRayReport ?? false;
    message.CanRequestPanoReport = object.CanRequestPanoReport ?? false;
    message.CanRequestDentalPhotoReport =
      object.CanRequestDentalPhotoReport ?? false;
    message.CanChangeMedicalImageViewOptions =
      object.CanChangeMedicalImageViewOptions ?? false;
    message.CanDelete = object.CanDelete ?? false;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
