import maskTypeButtonStyle1 from '@/shared/assets/images/maskTypeButtonStyle-01.png';
import maskTypeButtonStyle2 from '@/shared/assets/images/maskTypeButtonStyle-02.png';
import maskTypeButtonStyle3 from '@/shared/assets/images/maskTypeButtonStyle-03.png';
import maskTypeButtonStyle4 from '@/shared/assets/images/maskTypeButtonStyle-04.png';
import maskTypeButtonStyleBW from '@/shared/assets/images/showMasksButtonBW.png';

import { MaskStyleTypes } from '@/entities/reports';

export const getMaskImageByType = (type: MaskStyleTypes) => {
  switch (type) {
    case MaskStyleTypes.Default:
      return maskTypeButtonStyle1;
    case MaskStyleTypes.Style2:
      return maskTypeButtonStyle2;
    case MaskStyleTypes.Style3:
      return maskTypeButtonStyle3;
    case MaskStyleTypes.Style4:
      return maskTypeButtonStyle4;
    default:
      return maskTypeButtonStyleBW;
  }
};
