// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Conclusion-module__editorContainer--el9\\+u3wt{margin:16px 0;word-break:break-all}.Conclusion-module__preview--xa6GMwXb{margin-top:16px}.Conclusion-module__legacyConclusion--kglRLHWw{display:flex;flex-direction:column;margin-top:16px;gap:16px;word-break:break-all}.Conclusion-module__legacyConclusion--kglRLHWw img{max-width:300px}.Conclusion-module__editButton--AWbBMZyc{align-self:flex-end}.Conclusion-module__buttonsContainer--CHuIQ711{display:flex;justify-content:flex-end;gap:8px;margin-top:16px}", "",{"version":3,"sources":["webpack://./src/widgets/Conclusion/ui/Conclusion/Conclusion.module.scss"],"names":[],"mappings":"AAAA,+CACE,aAAA,CACA,oBAAA,CAGF,sCACE,eAAA,CAGF,+CACE,YAAA,CACA,qBAAA,CACA,eAAA,CACA,QAAA,CACA,oBAAA,CAEA,mDACE,eAAA,CAIJ,yCACE,mBAAA,CAGF,+CACE,YAAA,CACA,wBAAA,CACA,OAAA,CACA,eAAA","sourcesContent":[".editorContainer {\n  margin: 16px 0;\n  word-break: break-all;\n}\n\n.preview {\n  margin-top: 16px;\n}\n\n.legacyConclusion {\n  display: flex;\n  flex-direction: column;\n  margin-top: 16px;\n  gap: 16px;\n  word-break: break-all;\n\n  img {\n    max-width: 300px;\n  }\n}\n\n.editButton {\n  align-self: flex-end;\n}\n\n.buttonsContainer {\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editorContainer": "Conclusion-module__editorContainer--el9+u3wt",
	"preview": "Conclusion-module__preview--xa6GMwXb",
	"legacyConclusion": "Conclusion-module__legacyConclusion--kglRLHWw",
	"editButton": "Conclusion-module__editButton--AWbBMZyc",
	"buttonsContainer": "Conclusion-module__buttonsContainer--CHuIQ711"
};
export default ___CSS_LOADER_EXPORT___;
