import { difference } from 'lodash';
import { compose } from 'ramda';
import { useCallback, useState } from 'react';

import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { mapTeethToIds, toothModel } from '@/entities/tooth';

import { useUpdateReportROI } from './useUpdateReportROI';

const filterTeethByISONumbers =
  (ISONumbers: number[] = []) =>
  (teeth: Tooth[] = []) =>
    teeth.filter((tooth) => ISONumbers.includes(tooth.Numeration?.ISO ?? 0));

type UseTeethChart = (props: { teeth: Tooth[] }) => {
  ROIISONumbers: number[];
  isROIEditing: boolean;
  toggleROIByISONumbers: (ISONumbers: number[]) => void;
  saveROIEditing: (reportID: string) => void;
  enableROIEditing: () => void;
};

// ROI: Region of interest
export const useTeethChart: UseTeethChart = ({ teeth }) => {
  const [isROIEditing, setIsROIEditing] = useState(false);

  const dispatch = useAppDispatch();

  // This is a local state that accumulates changes in ROI. This data needs for the backend request to update ROI in some report
  const ROIISONumbers = useAppSelector(
    toothModel.selectors.selectLocalROITeethISONumbers,
  );

  const updateReportROI = useUpdateReportROI({
    onSuccess: () => setIsROIEditing(false),
  });

  const saveROIEditing = useCallback(
    (reportID: string) => {
      const ROITeethIDs = compose(
        mapTeethToIds,
        filterTeethByISONumbers(ROIISONumbers),
      )(teeth);

      updateReportROI({ reportID, ROIToothIDs: ROITeethIDs });
    },
    [teeth, ROIISONumbers, updateReportROI],
  );

  const toggleROIByISONumbers = useCallback(
    (ISONumbers: number[]) => {
      const isAllReceivedISONumbersIsInROI = ISONumbers.every((ISONumber) =>
        ROIISONumbers.includes(ISONumber),
      );

      const newROIISONumbers = isAllReceivedISONumbersIsInROI
        ? difference(ROIISONumbers, ISONumbers)
        : [...ROIISONumbers, ...ISONumbers];

      dispatch(toothModel.actions.setLocalROITeethISONumbers(newROIISONumbers));
    },
    [ROIISONumbers, dispatch],
  );

  const enableROIEditing = () => {
    setIsROIEditing(true);
  };

  return {
    ROIISONumbers,
    toggleROIByISONumbers,
    isROIEditing,
    enableROIEditing,
    saveROIEditing,
  };
};
