import { isEmpty } from 'ramda';

import { ToothConditionCollectionByID } from '@/entities/logicalCondition/model/logicalConditionSlice';

import { LogicalCondition } from '../../logicalCondition/config/types';

export const mergeConditions = (
  conditionCollection: ToothConditionCollectionByID,
): LogicalCondition => {
  if (!conditionCollection || isEmpty(conditionCollection)) return undefined;

  const conditions = Object.values(conditionCollection);

  return conditions.reduce(
    (logicalCondition, condition, index) => {
      if (index === 0) {
        return logicalCondition;
      }

      const logicalConditionModelScore =
        logicalCondition?.Certainty?.ModelScore ?? 0;
      const conditionModelScore = condition?.Certainty?.ModelScore ?? 0;

      if (logicalConditionModelScore >= conditionModelScore) {
        return {
          ...logicalCondition,
          Localizations: logicalCondition.Localizations.concat(
            condition.Localizations,
          ),
          IDs: [...logicalCondition.IDs, condition.ID],
        };
      }

      return {
        ...condition,
        Localizations: logicalCondition.Localizations.concat(
          condition.Localizations,
        ),
        IDs: [...logicalCondition.IDs, condition.ID],
      };
    },
    { ...conditions[0], IDs: [conditions[0].ID] } as LogicalCondition,
  );
};
