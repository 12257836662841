import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'ramda';
import cn from 'classnames';

import { useAppSelector } from '@/shared/hooks';
import {
  ReportPrintSettings,
  ReportType,
} from '@/shared/api/protocol_gen/model/dto_report';

import { ToothChartLegends } from '@/entities/tooth';
import { getReportSignStatus, i18n } from '@/entities/reports';
import { useFormatPatientDateOfBirth } from '@/entities/patient';
import { organizationModel } from '@/entities/organization';
import { conditionModel } from '@/entities/condition';

import { TeethChart, useToothTransform } from '@/features/toothChart';
import { useReportDataStream } from '@/features/reportStream';

import { PanoImagePreview } from '@/widgets/PanoImage/ui/PanoImage/PanoImage';
import { PreviewConclusion } from '@/widgets/Conclusion/ui/Conclusion/Conclusion';
import { IOXRayMatrixDeprecated } from '@/widgets/IOXRayMatrix';
import { MedicalImagesGroup } from '@/widgets/MedicalImageGroup';
import { RarePathologies } from '@/widgets/NonDentalFindings';

import { PreviewToothCard } from '../PreviewToothCard/PreviewToothCard';
import { PreviewFooter } from '../PreviewFooter/PreviewFooter';
import { PreviewHeader } from '../PreviewHeader/PreviewHeader';
import { usePreviewDocument } from '../../hooks/usePreviewDocument';
import { previewFooterDisclaimer } from '../../config/i18n';
import { PREVIEW_DOCUMENT_PANO_WIDTH } from '../../config/constants';
import { Signature } from '../Signature/Signature';
import { PreviewScanInfo } from '../PreviewScanInfo/PreviewScanInfo';

import styles from './PreviewDocument.module.scss';

type PreviewDocumentType = {
  settings: ReportPrintSettings;
  reportID: string;
  patientID: string;
  showLowProbability: boolean;
  organizationID: string;
};

export const PreviewDocument: FC<PreviewDocumentType> = (props) => {
  const {
    showLowProbability,
    settings: {
      IsInverted,
      BlackAndWhiteMode,
      ShowBottomJaw,
      ShowTopJaw,
      ShowSlices,
      ShowDentalChart,
      ShowDiagnosesProbabilities,
      ShowDiagnoses,
      ShowBBoxes,
      ShowStudyMetadata,
    },
    reportID,
    patientID,
    organizationID,
  } = props;

  const maxFaxConditions = useAppSelector(
    conditionModel.selectors.selectMaxFaxConditionsByReportID(reportID),
  );

  const { formatMessage, formatDate } = useIntl();

  const {
    teeth = [],
    patient,
    report,
    CBCTPanoImage,
    CBCTPanoImageUrl,
    panoImageURL,
    patientFullName,
    endoImageURL,
    endodonticGroups,
    studyMeta,
    isLoading,
  } = usePreviewDocument({
    reportID,
    patientID,
    showBottomJaw: ShowBottomJaw,
    showTopJaw: ShowTopJaw,
  });

  const organization = useAppSelector((state) =>
    organizationModel.selectors.organizationSelectors.selectById(
      state,
      organizationID,
    ),
  );

  const teethItems = useToothTransform({
    teeth,
    showLowProbability: report?.Settings?.LowProbabilityMode,
    // ROIISONumbers,
  });

  const patientDateOfBirth = patient?.PersonalData?.DateOfBirth;

  const patientDoB = useFormatPatientDateOfBirth({ patientDateOfBirth });

  const isReportSigned = report?.Signature?.Signed;

  const allUserSignatures = report?.Signature?.UserSignatures;

  useReportDataStream(reportID);

  if (isLoading) {
    return null;
  }

  // Get view data after patient and report data is fetched
  const reportCreatedDate = formatDate(report?.Created?.At ?? 0, {
    dateStyle: 'medium',
    timeStyle: 'short',
  });

  const reportSignedDate = formatDate(report.Signature.SignedAt ?? 0, {
    dateStyle: 'medium',
    timeStyle: 'short',
  });

  const reportTitle = report?.Type ? formatMessage(i18n[report.Type]) : '';
  const reportSignStatus = getReportSignStatus(report);

  const descriptionLabel = isReportSigned
    ? formatMessage({
        id: 'previewDocument.reportSigned',
        defaultMessage: 'Report signed:',
      })
    : formatMessage({
        id: 'previewDocument.reportCreated',
        defaultMessage: 'Report created:',
      });

  const descriptionDate = isReportSigned ? reportSignedDate : reportCreatedDate;

  return (
    <table className={styles.table}>
      <tbody>
        <tr>
          <td>
            <div className={styles.document}>
              <PreviewHeader
                title={reportTitle}
                date={reportCreatedDate}
                patientName={patientFullName}
                patientDateOfBirth={patientDoB}
                organization={organization}
              />

              <div className={styles.panoContainer}>
                {!isEmpty(CBCTPanoImage) && (
                  <PanoImagePreview
                    src={CBCTPanoImageUrl}
                    inverted={IsInverted}
                    kind="dicom"
                    containerWidth={PREVIEW_DOCUMENT_PANO_WIDTH}
                  />
                )}

                {report?.Type === ReportType.ReportType_IOXRay_GP && (
                  <div className={styles.IOXRayMatrixWrapper}>
                    <IOXRayMatrixDeprecated reportID={report.ID} />
                  </div>
                )}

                {report?.Type === ReportType.ReportType_Pano_GP && (
                  <PanoImagePreview
                    src={panoImageURL}
                    inverted={IsInverted}
                    kind="raster"
                    containerWidth={PREVIEW_DOCUMENT_PANO_WIDTH}
                  />
                )}

                {report?.Type === ReportType.ReportType_CBCT_Endo && (
                  <PanoImagePreview
                    src={endoImageURL ?? ''}
                    inverted={IsInverted}
                    kind="dicom"
                    containerWidth={PREVIEW_DOCUMENT_PANO_WIDTH}
                  />
                )}
              </div>

              {ShowDentalChart &&
                report?.Type !== ReportType.ReportType_CBCT_Endo && (
                  <>
                    <TeethChart
                      teethItems={teethItems}
                      showUpperJaw={ShowTopJaw}
                      showLowerJaw={ShowBottomJaw}
                      blackAndWhiteMode={BlackAndWhiteMode}
                    />

                    <ToothChartLegends
                      blackAndWhiteMode={BlackAndWhiteMode}
                      showLowProbability={showLowProbability}
                    />
                  </>
                )}

              {ShowBBoxes && !!maxFaxConditions.length && (
                <div className={styles.dontBreakInside}>
                  <h3 className="h3">
                    <FormattedMessage
                      id="report.rarePathology.header"
                      defaultMessage="Non-dental findings"
                    />
                  </h3>
                  <RarePathologies
                    className={styles.rarePathologies}
                    conditions={maxFaxConditions}
                  />
                </div>
              )}

              {!!teeth.length && (
                <div className={styles.teethContainer}>
                  {teeth.map((tooth) => (
                    <PreviewToothCard
                      key={tooth.ID}
                      tooth={tooth}
                      showProbability={ShowDiagnosesProbabilities}
                      showConditions={ShowDiagnoses}
                      showMedicalImages={ShowSlices}
                      inverted={IsInverted}
                      blackAndWhiteMode={BlackAndWhiteMode}
                      report={report}
                    />
                  ))}
                </div>
              )}

              {report?.Type === ReportType.ReportType_CBCT_Endo &&
                !!endodonticGroups.length && (
                  <MedicalImagesGroup groups={endodonticGroups} />
                )}

              {!!report?.LegacyConclusion && report?.Signature?.Signed && (
                <PreviewConclusion conclusion={report?.LegacyConclusion} />
              )}

              <div className={styles.signatureAndDate}>
                {isReportSigned &&
                  allUserSignatures &&
                  allUserSignatures.map((signature) => (
                    <Signature signature={signature} key={signature.UserID} />
                  ))}

                <div className={styles.reportDate}>
                  <p className={cn(styles.descriptionLabel, 'p3t')}>
                    {descriptionLabel}
                  </p>

                  <p className="p2">{descriptionDate}</p>
                </div>
              </div>

              {ShowStudyMetadata && (
                <PreviewScanInfo
                  className={styles.scanInfo}
                  studyMeta={studyMeta}
                />
              )}

              <PreviewFooter
                text={formatMessage(previewFooterDisclaimer[reportSignStatus])}
                date={reportCreatedDate}
              />
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div className={styles.footerSpace}>&nbsp;</div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};
