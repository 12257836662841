import { ReactNode } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import cn from 'classnames';

import styles from './Avatar.module.scss';

interface AvatarTooltipProps {
  className?: string;
  testID?: string;
  children?: ReactNode;
  content: ReactNode;
}

export const AvatarTooltip = ({
  className,
  testID,
  children,
  content,
}: AvatarTooltipProps) => (
  <RadixTooltip.Root delayDuration={0}>
    <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>

    <RadixTooltip.Portal>
      <RadixTooltip.Content
        className={cn(styles.avatarTooltip, 'p3', className)}
        data-testid={testID}
        side="right"
        align="end"
        alignOffset={-9}
      >
        {content}
      </RadixTooltip.Content>
    </RadixTooltip.Portal>
  </RadixTooltip.Root>
);
