// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CroppedMedicalImage-module__container--k1fj9yB0{overflow:hidden;border-radius:8px}.CroppedMedicalImage-module__container--k1fj9yB0:hover{cursor:pointer;box-shadow:0 0 0 5px var(--purpleflat)}", "",{"version":3,"sources":["webpack://./src/widgets/ToothCard/ui/CroppedMedicalImage/CroppedMedicalImage.module.scss"],"names":[],"mappings":"AAAA,iDACE,eAAA,CACA,iBAAA,CAEA,uDACE,cAAA,CACA,sCAAA","sourcesContent":[".container {\n  overflow: hidden;\n  border-radius: 8px;\n\n  &:hover {\n    cursor: pointer;\n    box-shadow: 0 0 0 5px var(--purpleflat);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CroppedMedicalImage-module__container--k1fj9yB0"
};
export default ___CSS_LOADER_EXPORT___;
