import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';
import { LoadingStateType, SliceName } from '@/shared/config';

export const assetsAdapter = createEntityAdapter<Asset>({
  selectId: (asset: Asset) => asset?.ID,
});

const initialStateFlags = {
  loading: 'idle',
};

const assetsSlice = createSlice({
  name: SliceName.assets,
  initialState: assetsAdapter.getInitialState(initialStateFlags),
  reducers: {
    addOne: assetsAdapter.addOne,
    addMany: assetsAdapter.addMany,
    setAll: assetsAdapter.setAll,
    upsertMany: assetsAdapter.upsertMany,
    updateMany: assetsAdapter.updateMany,
    setNewestOne: (state, action: PayloadAction<Asset>) => {
      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

      if (payloadRevisionNumber > currentRevisionNumber) {
        assetsAdapter.setOne(state, action.payload);
      }
    },
    setMany: assetsAdapter.setMany,
    removeOne: assetsAdapter.removeOne,
    removeAll: assetsAdapter.removeAll,
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
  },
});

export const { actions } = assetsSlice;

export default assetsSlice.reducer;
