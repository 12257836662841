import { useIntl } from 'react-intl';

import { ToothStatus } from '@/shared/config';

import { TeethChartFilter, toothTypeMessages } from '@/entities/tooth';

import { ToothInterface } from './useToothTransform';

export const useTeethChartROIFilters = ({
  teethItems,
  ROIISONumbers,
}: {
  teethItems: ToothInterface[];
  ROIISONumbers: number[];
}): TeethChartFilter[] => {
  const { formatMessage } = useIntl();

  const allROIISONumbers = teethItems.map((toothItem) => toothItem.ISONumber);

  const ROIFilters = teethItems.reduce(
    (ROIFiltersAccumulator, toothItem) => {
      const { status, ISONumber } = toothItem;

      if (status in ROIFiltersAccumulator) {
        ROIFiltersAccumulator[status].ISONumbers.push(ISONumber);
      } else {
        ROIFiltersAccumulator[status] = {
          type: status,
          title: formatMessage(toothTypeMessages[status]),
          ISONumbers: [ISONumber],
          active: false,
        };
      }

      return ROIFiltersAccumulator;
    },
    {
      all: {
        type: 'all',
        title: formatMessage(toothTypeMessages.all),
        ISONumbers: allROIISONumbers,
        active: false,
      },
    } as Record<ToothStatus | 'all', TeethChartFilter>,
  );

  return Object.values(ROIFilters).map((ROIFilter) => ({
    ...ROIFilter,
    active: ROIFilter.ISONumbers.every((ISONumber) =>
      ROIISONumbers.includes(ISONumber),
    ),
  }));
};
