import { defineMessages } from 'react-intl';

import { SortKeyEnum } from '@/shared/config';

export const ColumnValues = defineMessages({
  [SortKeyEnum.Patient]: {
    id: 'sharedPatient.Patient',
    defaultMessage: 'Patient name',
  },
  [SortKeyEnum.ClinicName]: {
    id: 'sharedPatient.clinicName',
    defaultMessage: 'Clinic name',
  },
  [SortKeyEnum.SharedBy]: {
    id: 'sharedPatient.sharedBy',
    defaultMessage: 'Shared by',
  },
  [SortKeyEnum.SenderEmail]: {
    id: 'sharedPatient.senderEmail',
    defaultMessage: 'Sender Email',
  },
  [SortKeyEnum.SharingDate]: {
    id: 'sharedPatient.sharedDate',
    defaultMessage: 'Sharing date',
  },
  [SortKeyEnum.SharedWith]: {
    id: 'sharedPatient.sharedWith',
    defaultMessage: 'Shared with',
  },
  [SortKeyEnum.Status]: {
    id: 'sharedPatient.Status',
    defaultMessage: 'Status',
  },
});
