/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import { Observable } from 'rxjs';
import { BrowserHeaders } from 'browser-headers';
import { share } from 'rxjs/operators';
import { Empty } from '../../google/protobuf/empty';
import {
  Order,
  EffectSubscriptionAdd,
  EffectSubscriptionReplace,
  EffectAddonAdd,
  EffectPackageAdd,
  EffectBonusAdd,
} from '../../api/billing_new/dto_order_new';
import {
  ProductState,
  ProductModel,
} from '../../api/billing_new/dto_product_new';
import {
  Account,
  AccountBillingInformation,
} from '../../api/billing_new/dto_account_new';
import { Consumption } from '../../api/billing_new/dto_consumption_new';

export const protobufPackage = 'com.diagnocat.billing_new';

export interface AccountInfoStreamReq {
  OrganizationID: string;
}

export interface AccountInfoStreamResp {
  /** `EndOfHistoricalOrder` comes right after all historical orders are received */
  EndOfHistoricalOrder: Empty | undefined;
  HistoricalOrder: Order | undefined;
  /** `EndOfHistoricalInventory` comes right after all historical orders are received */
  EndOfHistoricalInventory: Empty | undefined;
  HistoricalInventory: ProductState | undefined;
  AccountInfo: Account | undefined;
  Order: Order | undefined;
  Inventory: ProductState | undefined;
  Consumption: Consumption | undefined;
  Ping: Empty | undefined;
}

export interface CreateOrderReq {
  AccountID: string;
  ToChargeImmediately: boolean;
  SubscriptionAdd: EffectSubscriptionAdd | undefined;
  SubscriptionReplace: EffectSubscriptionReplace | undefined;
  AddonAdd: EffectAddonAdd | undefined;
  PackageAdd: EffectPackageAdd | undefined;
  BonusAdd: EffectBonusAdd | undefined;
}

export interface CreateOrderResp {
  Order: Order | undefined;
  InvoicePaymentURL: string;
}

export interface ProcessOrderReq {
  OrderID: string;
}

export interface ProcessOrderResp {}

export interface PayOrderReq {
  OrderID: string;
  ToChargeImmediately: boolean;
}

export interface PayOrderResp {
  Paid: boolean | undefined;
  URL: string | undefined;
  ManualPaymentOnly: boolean | undefined;
}

export interface CreateAccountPaymentMethodReq {
  AccountID: string;
  SuccessURL: string;
  CancelURL: string;
}

export interface CreateAccountPaymentMethodResp {
  URL: string;
}

export interface DeleteAccountPaymentMethodReq {
  AccountID: string;
}

export interface DeleteAccountPaymentMethodResp {}

export interface RemoveProductStateReq {
  AccountID: string;
  ProductStateID: string;
}

export interface RemoveProductStateResp {
  Order: Order | undefined;
}

export interface ActivateSubscriptionStateReq {
  AccountID: string;
  SubscriptionStateID: string;
}

export interface ActivateSubscriptionStateResp {
  Order: Order | undefined;
}

export interface GetAvailableProductsReq {
  ByID: string | undefined;
  ByAccountID: string | undefined;
  ByBillingZone: string | undefined;
}

export interface GetAvailableProductsResp {
  AvailableProducts: ProductModel[];
}

export interface GetAllAvailableProductsReq {}

export interface GetAllAvailableProductsResp {
  AvailableProducts: ProductModel[];
}

export interface SetSubscriptionAutoRenewalReq {
  AccountID: string;
  SubscriptionStateID: string;
  AutoRenewal: boolean;
}

export interface SetSubscriptionAutoRenewalResp {}

export interface SetSubscriptionAutoChargeReq {
  AccountID: string;
  SubscriptionStateID: string;
  AutoCharge: boolean;
}

export interface SetSubscriptionAutoChargeResp {}

export interface SetAccountBillingInformationReq {
  AccountID: string;
  BillingInformation: AccountBillingInformation | undefined;
}

export interface SetAccountBillingInformationResp {}

export interface GetPaymentDocumentByOrderReq {
  OrderID: string;
}

export interface GetPaymentDocumentByOrderResp {
  document: Uint8Array;
}

export interface SetTransactionNumberToOrderReq {
  OrderID: string;
  TransactionNumber: string;
}

export interface SetTransactionNumberToOrderResp {}

export interface TestSetAccountWithoutStripeInvoicesReq {
  AccountID: string;
  WithoutStripeInvoices: boolean;
}

export interface TestSetAccountWithoutStripeInvoicesResp {}

export interface AddBillingZoneToAccountReq {
  AccountID: string;
  BillingZone: string;
}

export interface AddBillingZoneToAccountResp {}

export interface RemoveBillingZoneToAccountReq {
  AccountID: string;
  BillingZone: string;
}

export interface RemoveBillingZoneToAccountResp {}

function createBaseAccountInfoStreamReq(): AccountInfoStreamReq {
  return { OrganizationID: '' };
}

export const AccountInfoStreamReq = {
  encode(
    message: AccountInfoStreamReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AccountInfoStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountInfoStreamReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountInfoStreamReq {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
    };
  },

  toJSON(message: AccountInfoStreamReq): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AccountInfoStreamReq>, I>>(
    object: I,
  ): AccountInfoStreamReq {
    const message = createBaseAccountInfoStreamReq();
    message.OrganizationID = object.OrganizationID ?? '';
    return message;
  },
};

function createBaseAccountInfoStreamResp(): AccountInfoStreamResp {
  return {
    EndOfHistoricalOrder: undefined,
    HistoricalOrder: undefined,
    EndOfHistoricalInventory: undefined,
    HistoricalInventory: undefined,
    AccountInfo: undefined,
    Order: undefined,
    Inventory: undefined,
    Consumption: undefined,
    Ping: undefined,
  };
}

export const AccountInfoStreamResp = {
  encode(
    message: AccountInfoStreamResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.EndOfHistoricalOrder !== undefined) {
      Empty.encode(
        message.EndOfHistoricalOrder,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.HistoricalOrder !== undefined) {
      Order.encode(message.HistoricalOrder, writer.uint32(18).fork()).ldelim();
    }
    if (message.EndOfHistoricalInventory !== undefined) {
      Empty.encode(
        message.EndOfHistoricalInventory,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.HistoricalInventory !== undefined) {
      ProductState.encode(
        message.HistoricalInventory,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.AccountInfo !== undefined) {
      Account.encode(message.AccountInfo, writer.uint32(82).fork()).ldelim();
    }
    if (message.Order !== undefined) {
      Order.encode(message.Order, writer.uint32(90).fork()).ldelim();
    }
    if (message.Inventory !== undefined) {
      ProductState.encode(message.Inventory, writer.uint32(98).fork()).ldelim();
    }
    if (message.Consumption !== undefined) {
      Consumption.encode(
        message.Consumption,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    if (message.Ping !== undefined) {
      Empty.encode(message.Ping, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AccountInfoStreamResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountInfoStreamResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.EndOfHistoricalOrder = Empty.decode(reader, reader.uint32());
          break;
        case 2:
          message.HistoricalOrder = Order.decode(reader, reader.uint32());
          break;
        case 3:
          message.EndOfHistoricalInventory = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 4:
          message.HistoricalInventory = ProductState.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10:
          message.AccountInfo = Account.decode(reader, reader.uint32());
          break;
        case 11:
          message.Order = Order.decode(reader, reader.uint32());
          break;
        case 12:
          message.Inventory = ProductState.decode(reader, reader.uint32());
          break;
        case 13:
          message.Consumption = Consumption.decode(reader, reader.uint32());
          break;
        case 14:
          message.Ping = Empty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountInfoStreamResp {
    return {
      EndOfHistoricalOrder: isSet(object.EndOfHistoricalOrder)
        ? Empty.fromJSON(object.EndOfHistoricalOrder)
        : undefined,
      HistoricalOrder: isSet(object.HistoricalOrder)
        ? Order.fromJSON(object.HistoricalOrder)
        : undefined,
      EndOfHistoricalInventory: isSet(object.EndOfHistoricalInventory)
        ? Empty.fromJSON(object.EndOfHistoricalInventory)
        : undefined,
      HistoricalInventory: isSet(object.HistoricalInventory)
        ? ProductState.fromJSON(object.HistoricalInventory)
        : undefined,
      AccountInfo: isSet(object.AccountInfo)
        ? Account.fromJSON(object.AccountInfo)
        : undefined,
      Order: isSet(object.Order) ? Order.fromJSON(object.Order) : undefined,
      Inventory: isSet(object.Inventory)
        ? ProductState.fromJSON(object.Inventory)
        : undefined,
      Consumption: isSet(object.Consumption)
        ? Consumption.fromJSON(object.Consumption)
        : undefined,
      Ping: isSet(object.Ping) ? Empty.fromJSON(object.Ping) : undefined,
    };
  },

  toJSON(message: AccountInfoStreamResp): unknown {
    const obj: any = {};
    message.EndOfHistoricalOrder !== undefined &&
      (obj.EndOfHistoricalOrder = message.EndOfHistoricalOrder
        ? Empty.toJSON(message.EndOfHistoricalOrder)
        : undefined);
    message.HistoricalOrder !== undefined &&
      (obj.HistoricalOrder = message.HistoricalOrder
        ? Order.toJSON(message.HistoricalOrder)
        : undefined);
    message.EndOfHistoricalInventory !== undefined &&
      (obj.EndOfHistoricalInventory = message.EndOfHistoricalInventory
        ? Empty.toJSON(message.EndOfHistoricalInventory)
        : undefined);
    message.HistoricalInventory !== undefined &&
      (obj.HistoricalInventory = message.HistoricalInventory
        ? ProductState.toJSON(message.HistoricalInventory)
        : undefined);
    message.AccountInfo !== undefined &&
      (obj.AccountInfo = message.AccountInfo
        ? Account.toJSON(message.AccountInfo)
        : undefined);
    message.Order !== undefined &&
      (obj.Order = message.Order ? Order.toJSON(message.Order) : undefined);
    message.Inventory !== undefined &&
      (obj.Inventory = message.Inventory
        ? ProductState.toJSON(message.Inventory)
        : undefined);
    message.Consumption !== undefined &&
      (obj.Consumption = message.Consumption
        ? Consumption.toJSON(message.Consumption)
        : undefined);
    message.Ping !== undefined &&
      (obj.Ping = message.Ping ? Empty.toJSON(message.Ping) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AccountInfoStreamResp>, I>>(
    object: I,
  ): AccountInfoStreamResp {
    const message = createBaseAccountInfoStreamResp();
    message.EndOfHistoricalOrder =
      object.EndOfHistoricalOrder !== undefined &&
      object.EndOfHistoricalOrder !== null
        ? Empty.fromPartial(object.EndOfHistoricalOrder)
        : undefined;
    message.HistoricalOrder =
      object.HistoricalOrder !== undefined && object.HistoricalOrder !== null
        ? Order.fromPartial(object.HistoricalOrder)
        : undefined;
    message.EndOfHistoricalInventory =
      object.EndOfHistoricalInventory !== undefined &&
      object.EndOfHistoricalInventory !== null
        ? Empty.fromPartial(object.EndOfHistoricalInventory)
        : undefined;
    message.HistoricalInventory =
      object.HistoricalInventory !== undefined &&
      object.HistoricalInventory !== null
        ? ProductState.fromPartial(object.HistoricalInventory)
        : undefined;
    message.AccountInfo =
      object.AccountInfo !== undefined && object.AccountInfo !== null
        ? Account.fromPartial(object.AccountInfo)
        : undefined;
    message.Order =
      object.Order !== undefined && object.Order !== null
        ? Order.fromPartial(object.Order)
        : undefined;
    message.Inventory =
      object.Inventory !== undefined && object.Inventory !== null
        ? ProductState.fromPartial(object.Inventory)
        : undefined;
    message.Consumption =
      object.Consumption !== undefined && object.Consumption !== null
        ? Consumption.fromPartial(object.Consumption)
        : undefined;
    message.Ping =
      object.Ping !== undefined && object.Ping !== null
        ? Empty.fromPartial(object.Ping)
        : undefined;
    return message;
  },
};

function createBaseCreateOrderReq(): CreateOrderReq {
  return {
    AccountID: '',
    ToChargeImmediately: false,
    SubscriptionAdd: undefined,
    SubscriptionReplace: undefined,
    AddonAdd: undefined,
    PackageAdd: undefined,
    BonusAdd: undefined,
  };
}

export const CreateOrderReq = {
  encode(
    message: CreateOrderReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.ToChargeImmediately === true) {
      writer.uint32(16).bool(message.ToChargeImmediately);
    }
    if (message.SubscriptionAdd !== undefined) {
      EffectSubscriptionAdd.encode(
        message.SubscriptionAdd,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.SubscriptionReplace !== undefined) {
      EffectSubscriptionReplace.encode(
        message.SubscriptionReplace,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.AddonAdd !== undefined) {
      EffectAddonAdd.encode(
        message.AddonAdd,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.PackageAdd !== undefined) {
      EffectPackageAdd.encode(
        message.PackageAdd,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    if (message.BonusAdd !== undefined) {
      EffectBonusAdd.encode(
        message.BonusAdd,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrderReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrderReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.ToChargeImmediately = reader.bool();
          break;
        case 3:
          message.SubscriptionAdd = EffectSubscriptionAdd.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 4:
          message.SubscriptionReplace = EffectSubscriptionReplace.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 5:
          message.AddonAdd = EffectAddonAdd.decode(reader, reader.uint32());
          break;
        case 6:
          message.PackageAdd = EffectPackageAdd.decode(reader, reader.uint32());
          break;
        case 7:
          message.BonusAdd = EffectBonusAdd.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateOrderReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      ToChargeImmediately: isSet(object.ToChargeImmediately)
        ? Boolean(object.ToChargeImmediately)
        : false,
      SubscriptionAdd: isSet(object.SubscriptionAdd)
        ? EffectSubscriptionAdd.fromJSON(object.SubscriptionAdd)
        : undefined,
      SubscriptionReplace: isSet(object.SubscriptionReplace)
        ? EffectSubscriptionReplace.fromJSON(object.SubscriptionReplace)
        : undefined,
      AddonAdd: isSet(object.AddonAdd)
        ? EffectAddonAdd.fromJSON(object.AddonAdd)
        : undefined,
      PackageAdd: isSet(object.PackageAdd)
        ? EffectPackageAdd.fromJSON(object.PackageAdd)
        : undefined,
      BonusAdd: isSet(object.BonusAdd)
        ? EffectBonusAdd.fromJSON(object.BonusAdd)
        : undefined,
    };
  },

  toJSON(message: CreateOrderReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.ToChargeImmediately !== undefined &&
      (obj.ToChargeImmediately = message.ToChargeImmediately);
    message.SubscriptionAdd !== undefined &&
      (obj.SubscriptionAdd = message.SubscriptionAdd
        ? EffectSubscriptionAdd.toJSON(message.SubscriptionAdd)
        : undefined);
    message.SubscriptionReplace !== undefined &&
      (obj.SubscriptionReplace = message.SubscriptionReplace
        ? EffectSubscriptionReplace.toJSON(message.SubscriptionReplace)
        : undefined);
    message.AddonAdd !== undefined &&
      (obj.AddonAdd = message.AddonAdd
        ? EffectAddonAdd.toJSON(message.AddonAdd)
        : undefined);
    message.PackageAdd !== undefined &&
      (obj.PackageAdd = message.PackageAdd
        ? EffectPackageAdd.toJSON(message.PackageAdd)
        : undefined);
    message.BonusAdd !== undefined &&
      (obj.BonusAdd = message.BonusAdd
        ? EffectBonusAdd.toJSON(message.BonusAdd)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateOrderReq>, I>>(
    object: I,
  ): CreateOrderReq {
    const message = createBaseCreateOrderReq();
    message.AccountID = object.AccountID ?? '';
    message.ToChargeImmediately = object.ToChargeImmediately ?? false;
    message.SubscriptionAdd =
      object.SubscriptionAdd !== undefined && object.SubscriptionAdd !== null
        ? EffectSubscriptionAdd.fromPartial(object.SubscriptionAdd)
        : undefined;
    message.SubscriptionReplace =
      object.SubscriptionReplace !== undefined &&
      object.SubscriptionReplace !== null
        ? EffectSubscriptionReplace.fromPartial(object.SubscriptionReplace)
        : undefined;
    message.AddonAdd =
      object.AddonAdd !== undefined && object.AddonAdd !== null
        ? EffectAddonAdd.fromPartial(object.AddonAdd)
        : undefined;
    message.PackageAdd =
      object.PackageAdd !== undefined && object.PackageAdd !== null
        ? EffectPackageAdd.fromPartial(object.PackageAdd)
        : undefined;
    message.BonusAdd =
      object.BonusAdd !== undefined && object.BonusAdd !== null
        ? EffectBonusAdd.fromPartial(object.BonusAdd)
        : undefined;
    return message;
  },
};

function createBaseCreateOrderResp(): CreateOrderResp {
  return { Order: undefined, InvoicePaymentURL: '' };
}

export const CreateOrderResp = {
  encode(
    message: CreateOrderResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Order !== undefined) {
      Order.encode(message.Order, writer.uint32(10).fork()).ldelim();
    }
    if (message.InvoicePaymentURL !== '') {
      writer.uint32(18).string(message.InvoicePaymentURL);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrderResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrderResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Order = Order.decode(reader, reader.uint32());
          break;
        case 2:
          message.InvoicePaymentURL = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateOrderResp {
    return {
      Order: isSet(object.Order) ? Order.fromJSON(object.Order) : undefined,
      InvoicePaymentURL: isSet(object.InvoicePaymentURL)
        ? String(object.InvoicePaymentURL)
        : '',
    };
  },

  toJSON(message: CreateOrderResp): unknown {
    const obj: any = {};
    message.Order !== undefined &&
      (obj.Order = message.Order ? Order.toJSON(message.Order) : undefined);
    message.InvoicePaymentURL !== undefined &&
      (obj.InvoicePaymentURL = message.InvoicePaymentURL);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateOrderResp>, I>>(
    object: I,
  ): CreateOrderResp {
    const message = createBaseCreateOrderResp();
    message.Order =
      object.Order !== undefined && object.Order !== null
        ? Order.fromPartial(object.Order)
        : undefined;
    message.InvoicePaymentURL = object.InvoicePaymentURL ?? '';
    return message;
  },
};

function createBaseProcessOrderReq(): ProcessOrderReq {
  return { OrderID: '' };
}

export const ProcessOrderReq = {
  encode(
    message: ProcessOrderReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrderID !== '') {
      writer.uint32(10).string(message.OrderID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProcessOrderReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcessOrderReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrderID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ProcessOrderReq {
    return {
      OrderID: isSet(object.OrderID) ? String(object.OrderID) : '',
    };
  },

  toJSON(message: ProcessOrderReq): unknown {
    const obj: any = {};
    message.OrderID !== undefined && (obj.OrderID = message.OrderID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProcessOrderReq>, I>>(
    object: I,
  ): ProcessOrderReq {
    const message = createBaseProcessOrderReq();
    message.OrderID = object.OrderID ?? '';
    return message;
  },
};

function createBaseProcessOrderResp(): ProcessOrderResp {
  return {};
}

export const ProcessOrderResp = {
  encode(
    _: ProcessOrderResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProcessOrderResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcessOrderResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ProcessOrderResp {
    return {};
  },

  toJSON(_: ProcessOrderResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProcessOrderResp>, I>>(
    _: I,
  ): ProcessOrderResp {
    const message = createBaseProcessOrderResp();
    return message;
  },
};

function createBasePayOrderReq(): PayOrderReq {
  return { OrderID: '', ToChargeImmediately: false };
}

export const PayOrderReq = {
  encode(
    message: PayOrderReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrderID !== '') {
      writer.uint32(10).string(message.OrderID);
    }
    if (message.ToChargeImmediately === true) {
      writer.uint32(16).bool(message.ToChargeImmediately);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PayOrderReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayOrderReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrderID = reader.string();
          break;
        case 2:
          message.ToChargeImmediately = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PayOrderReq {
    return {
      OrderID: isSet(object.OrderID) ? String(object.OrderID) : '',
      ToChargeImmediately: isSet(object.ToChargeImmediately)
        ? Boolean(object.ToChargeImmediately)
        : false,
    };
  },

  toJSON(message: PayOrderReq): unknown {
    const obj: any = {};
    message.OrderID !== undefined && (obj.OrderID = message.OrderID);
    message.ToChargeImmediately !== undefined &&
      (obj.ToChargeImmediately = message.ToChargeImmediately);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PayOrderReq>, I>>(
    object: I,
  ): PayOrderReq {
    const message = createBasePayOrderReq();
    message.OrderID = object.OrderID ?? '';
    message.ToChargeImmediately = object.ToChargeImmediately ?? false;
    return message;
  },
};

function createBasePayOrderResp(): PayOrderResp {
  return { Paid: undefined, URL: undefined, ManualPaymentOnly: undefined };
}

export const PayOrderResp = {
  encode(
    message: PayOrderResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Paid !== undefined) {
      writer.uint32(8).bool(message.Paid);
    }
    if (message.URL !== undefined) {
      writer.uint32(18).string(message.URL);
    }
    if (message.ManualPaymentOnly !== undefined) {
      writer.uint32(24).bool(message.ManualPaymentOnly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PayOrderResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayOrderResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Paid = reader.bool();
          break;
        case 2:
          message.URL = reader.string();
          break;
        case 3:
          message.ManualPaymentOnly = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PayOrderResp {
    return {
      Paid: isSet(object.Paid) ? Boolean(object.Paid) : undefined,
      URL: isSet(object.URL) ? String(object.URL) : undefined,
      ManualPaymentOnly: isSet(object.ManualPaymentOnly)
        ? Boolean(object.ManualPaymentOnly)
        : undefined,
    };
  },

  toJSON(message: PayOrderResp): unknown {
    const obj: any = {};
    message.Paid !== undefined && (obj.Paid = message.Paid);
    message.URL !== undefined && (obj.URL = message.URL);
    message.ManualPaymentOnly !== undefined &&
      (obj.ManualPaymentOnly = message.ManualPaymentOnly);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PayOrderResp>, I>>(
    object: I,
  ): PayOrderResp {
    const message = createBasePayOrderResp();
    message.Paid = object.Paid ?? undefined;
    message.URL = object.URL ?? undefined;
    message.ManualPaymentOnly = object.ManualPaymentOnly ?? undefined;
    return message;
  },
};

function createBaseCreateAccountPaymentMethodReq(): CreateAccountPaymentMethodReq {
  return { AccountID: '', SuccessURL: '', CancelURL: '' };
}

export const CreateAccountPaymentMethodReq = {
  encode(
    message: CreateAccountPaymentMethodReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.SuccessURL !== '') {
      writer.uint32(18).string(message.SuccessURL);
    }
    if (message.CancelURL !== '') {
      writer.uint32(26).string(message.CancelURL);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateAccountPaymentMethodReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAccountPaymentMethodReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.SuccessURL = reader.string();
          break;
        case 3:
          message.CancelURL = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateAccountPaymentMethodReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      SuccessURL: isSet(object.SuccessURL) ? String(object.SuccessURL) : '',
      CancelURL: isSet(object.CancelURL) ? String(object.CancelURL) : '',
    };
  },

  toJSON(message: CreateAccountPaymentMethodReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.SuccessURL !== undefined && (obj.SuccessURL = message.SuccessURL);
    message.CancelURL !== undefined && (obj.CancelURL = message.CancelURL);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateAccountPaymentMethodReq>, I>>(
    object: I,
  ): CreateAccountPaymentMethodReq {
    const message = createBaseCreateAccountPaymentMethodReq();
    message.AccountID = object.AccountID ?? '';
    message.SuccessURL = object.SuccessURL ?? '';
    message.CancelURL = object.CancelURL ?? '';
    return message;
  },
};

function createBaseCreateAccountPaymentMethodResp(): CreateAccountPaymentMethodResp {
  return { URL: '' };
}

export const CreateAccountPaymentMethodResp = {
  encode(
    message: CreateAccountPaymentMethodResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.URL !== '') {
      writer.uint32(10).string(message.URL);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateAccountPaymentMethodResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAccountPaymentMethodResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.URL = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateAccountPaymentMethodResp {
    return {
      URL: isSet(object.URL) ? String(object.URL) : '',
    };
  },

  toJSON(message: CreateAccountPaymentMethodResp): unknown {
    const obj: any = {};
    message.URL !== undefined && (obj.URL = message.URL);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateAccountPaymentMethodResp>, I>>(
    object: I,
  ): CreateAccountPaymentMethodResp {
    const message = createBaseCreateAccountPaymentMethodResp();
    message.URL = object.URL ?? '';
    return message;
  },
};

function createBaseDeleteAccountPaymentMethodReq(): DeleteAccountPaymentMethodReq {
  return { AccountID: '' };
}

export const DeleteAccountPaymentMethodReq = {
  encode(
    message: DeleteAccountPaymentMethodReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeleteAccountPaymentMethodReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAccountPaymentMethodReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteAccountPaymentMethodReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
    };
  },

  toJSON(message: DeleteAccountPaymentMethodReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteAccountPaymentMethodReq>, I>>(
    object: I,
  ): DeleteAccountPaymentMethodReq {
    const message = createBaseDeleteAccountPaymentMethodReq();
    message.AccountID = object.AccountID ?? '';
    return message;
  },
};

function createBaseDeleteAccountPaymentMethodResp(): DeleteAccountPaymentMethodResp {
  return {};
}

export const DeleteAccountPaymentMethodResp = {
  encode(
    _: DeleteAccountPaymentMethodResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeleteAccountPaymentMethodResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAccountPaymentMethodResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): DeleteAccountPaymentMethodResp {
    return {};
  },

  toJSON(_: DeleteAccountPaymentMethodResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteAccountPaymentMethodResp>, I>>(
    _: I,
  ): DeleteAccountPaymentMethodResp {
    const message = createBaseDeleteAccountPaymentMethodResp();
    return message;
  },
};

function createBaseRemoveProductStateReq(): RemoveProductStateReq {
  return { AccountID: '', ProductStateID: '' };
}

export const RemoveProductStateReq = {
  encode(
    message: RemoveProductStateReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.ProductStateID !== '') {
      writer.uint32(18).string(message.ProductStateID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RemoveProductStateReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveProductStateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.ProductStateID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveProductStateReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      ProductStateID: isSet(object.ProductStateID)
        ? String(object.ProductStateID)
        : '',
    };
  },

  toJSON(message: RemoveProductStateReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.ProductStateID !== undefined &&
      (obj.ProductStateID = message.ProductStateID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RemoveProductStateReq>, I>>(
    object: I,
  ): RemoveProductStateReq {
    const message = createBaseRemoveProductStateReq();
    message.AccountID = object.AccountID ?? '';
    message.ProductStateID = object.ProductStateID ?? '';
    return message;
  },
};

function createBaseRemoveProductStateResp(): RemoveProductStateResp {
  return { Order: undefined };
}

export const RemoveProductStateResp = {
  encode(
    message: RemoveProductStateResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Order !== undefined) {
      Order.encode(message.Order, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RemoveProductStateResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveProductStateResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Order = Order.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveProductStateResp {
    return {
      Order: isSet(object.Order) ? Order.fromJSON(object.Order) : undefined,
    };
  },

  toJSON(message: RemoveProductStateResp): unknown {
    const obj: any = {};
    message.Order !== undefined &&
      (obj.Order = message.Order ? Order.toJSON(message.Order) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RemoveProductStateResp>, I>>(
    object: I,
  ): RemoveProductStateResp {
    const message = createBaseRemoveProductStateResp();
    message.Order =
      object.Order !== undefined && object.Order !== null
        ? Order.fromPartial(object.Order)
        : undefined;
    return message;
  },
};

function createBaseActivateSubscriptionStateReq(): ActivateSubscriptionStateReq {
  return { AccountID: '', SubscriptionStateID: '' };
}

export const ActivateSubscriptionStateReq = {
  encode(
    message: ActivateSubscriptionStateReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.SubscriptionStateID !== '') {
      writer.uint32(18).string(message.SubscriptionStateID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ActivateSubscriptionStateReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivateSubscriptionStateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.SubscriptionStateID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ActivateSubscriptionStateReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      SubscriptionStateID: isSet(object.SubscriptionStateID)
        ? String(object.SubscriptionStateID)
        : '',
    };
  },

  toJSON(message: ActivateSubscriptionStateReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.SubscriptionStateID !== undefined &&
      (obj.SubscriptionStateID = message.SubscriptionStateID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ActivateSubscriptionStateReq>, I>>(
    object: I,
  ): ActivateSubscriptionStateReq {
    const message = createBaseActivateSubscriptionStateReq();
    message.AccountID = object.AccountID ?? '';
    message.SubscriptionStateID = object.SubscriptionStateID ?? '';
    return message;
  },
};

function createBaseActivateSubscriptionStateResp(): ActivateSubscriptionStateResp {
  return { Order: undefined };
}

export const ActivateSubscriptionStateResp = {
  encode(
    message: ActivateSubscriptionStateResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Order !== undefined) {
      Order.encode(message.Order, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ActivateSubscriptionStateResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivateSubscriptionStateResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Order = Order.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ActivateSubscriptionStateResp {
    return {
      Order: isSet(object.Order) ? Order.fromJSON(object.Order) : undefined,
    };
  },

  toJSON(message: ActivateSubscriptionStateResp): unknown {
    const obj: any = {};
    message.Order !== undefined &&
      (obj.Order = message.Order ? Order.toJSON(message.Order) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ActivateSubscriptionStateResp>, I>>(
    object: I,
  ): ActivateSubscriptionStateResp {
    const message = createBaseActivateSubscriptionStateResp();
    message.Order =
      object.Order !== undefined && object.Order !== null
        ? Order.fromPartial(object.Order)
        : undefined;
    return message;
  },
};

function createBaseGetAvailableProductsReq(): GetAvailableProductsReq {
  return { ByID: undefined, ByAccountID: undefined, ByBillingZone: undefined };
}

export const GetAvailableProductsReq = {
  encode(
    message: GetAvailableProductsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ByID !== undefined) {
      writer.uint32(10).string(message.ByID);
    }
    if (message.ByAccountID !== undefined) {
      writer.uint32(18).string(message.ByAccountID);
    }
    if (message.ByBillingZone !== undefined) {
      writer.uint32(26).string(message.ByBillingZone);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetAvailableProductsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAvailableProductsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ByID = reader.string();
          break;
        case 2:
          message.ByAccountID = reader.string();
          break;
        case 3:
          message.ByBillingZone = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAvailableProductsReq {
    return {
      ByID: isSet(object.ByID) ? String(object.ByID) : undefined,
      ByAccountID: isSet(object.ByAccountID)
        ? String(object.ByAccountID)
        : undefined,
      ByBillingZone: isSet(object.ByBillingZone)
        ? String(object.ByBillingZone)
        : undefined,
    };
  },

  toJSON(message: GetAvailableProductsReq): unknown {
    const obj: any = {};
    message.ByID !== undefined && (obj.ByID = message.ByID);
    message.ByAccountID !== undefined &&
      (obj.ByAccountID = message.ByAccountID);
    message.ByBillingZone !== undefined &&
      (obj.ByBillingZone = message.ByBillingZone);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetAvailableProductsReq>, I>>(
    object: I,
  ): GetAvailableProductsReq {
    const message = createBaseGetAvailableProductsReq();
    message.ByID = object.ByID ?? undefined;
    message.ByAccountID = object.ByAccountID ?? undefined;
    message.ByBillingZone = object.ByBillingZone ?? undefined;
    return message;
  },
};

function createBaseGetAvailableProductsResp(): GetAvailableProductsResp {
  return { AvailableProducts: [] };
}

export const GetAvailableProductsResp = {
  encode(
    message: GetAvailableProductsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.AvailableProducts) {
      ProductModel.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetAvailableProductsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAvailableProductsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AvailableProducts.push(
            ProductModel.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAvailableProductsResp {
    return {
      AvailableProducts: Array.isArray(object?.AvailableProducts)
        ? object.AvailableProducts.map((e: any) => ProductModel.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetAvailableProductsResp): unknown {
    const obj: any = {};
    if (message.AvailableProducts) {
      obj.AvailableProducts = message.AvailableProducts.map((e) =>
        e ? ProductModel.toJSON(e) : undefined,
      );
    } else {
      obj.AvailableProducts = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetAvailableProductsResp>, I>>(
    object: I,
  ): GetAvailableProductsResp {
    const message = createBaseGetAvailableProductsResp();
    message.AvailableProducts =
      object.AvailableProducts?.map((e) => ProductModel.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetAllAvailableProductsReq(): GetAllAvailableProductsReq {
  return {};
}

export const GetAllAvailableProductsReq = {
  encode(
    _: GetAllAvailableProductsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetAllAvailableProductsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllAvailableProductsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): GetAllAvailableProductsReq {
    return {};
  },

  toJSON(_: GetAllAvailableProductsReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetAllAvailableProductsReq>, I>>(
    _: I,
  ): GetAllAvailableProductsReq {
    const message = createBaseGetAllAvailableProductsReq();
    return message;
  },
};

function createBaseGetAllAvailableProductsResp(): GetAllAvailableProductsResp {
  return { AvailableProducts: [] };
}

export const GetAllAvailableProductsResp = {
  encode(
    message: GetAllAvailableProductsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.AvailableProducts) {
      ProductModel.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetAllAvailableProductsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllAvailableProductsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AvailableProducts.push(
            ProductModel.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAllAvailableProductsResp {
    return {
      AvailableProducts: Array.isArray(object?.AvailableProducts)
        ? object.AvailableProducts.map((e: any) => ProductModel.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetAllAvailableProductsResp): unknown {
    const obj: any = {};
    if (message.AvailableProducts) {
      obj.AvailableProducts = message.AvailableProducts.map((e) =>
        e ? ProductModel.toJSON(e) : undefined,
      );
    } else {
      obj.AvailableProducts = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetAllAvailableProductsResp>, I>>(
    object: I,
  ): GetAllAvailableProductsResp {
    const message = createBaseGetAllAvailableProductsResp();
    message.AvailableProducts =
      object.AvailableProducts?.map((e) => ProductModel.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetSubscriptionAutoRenewalReq(): SetSubscriptionAutoRenewalReq {
  return { AccountID: '', SubscriptionStateID: '', AutoRenewal: false };
}

export const SetSubscriptionAutoRenewalReq = {
  encode(
    message: SetSubscriptionAutoRenewalReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.SubscriptionStateID !== '') {
      writer.uint32(18).string(message.SubscriptionStateID);
    }
    if (message.AutoRenewal === true) {
      writer.uint32(24).bool(message.AutoRenewal);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetSubscriptionAutoRenewalReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSubscriptionAutoRenewalReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.SubscriptionStateID = reader.string();
          break;
        case 3:
          message.AutoRenewal = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetSubscriptionAutoRenewalReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      SubscriptionStateID: isSet(object.SubscriptionStateID)
        ? String(object.SubscriptionStateID)
        : '',
      AutoRenewal: isSet(object.AutoRenewal)
        ? Boolean(object.AutoRenewal)
        : false,
    };
  },

  toJSON(message: SetSubscriptionAutoRenewalReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.SubscriptionStateID !== undefined &&
      (obj.SubscriptionStateID = message.SubscriptionStateID);
    message.AutoRenewal !== undefined &&
      (obj.AutoRenewal = message.AutoRenewal);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetSubscriptionAutoRenewalReq>, I>>(
    object: I,
  ): SetSubscriptionAutoRenewalReq {
    const message = createBaseSetSubscriptionAutoRenewalReq();
    message.AccountID = object.AccountID ?? '';
    message.SubscriptionStateID = object.SubscriptionStateID ?? '';
    message.AutoRenewal = object.AutoRenewal ?? false;
    return message;
  },
};

function createBaseSetSubscriptionAutoRenewalResp(): SetSubscriptionAutoRenewalResp {
  return {};
}

export const SetSubscriptionAutoRenewalResp = {
  encode(
    _: SetSubscriptionAutoRenewalResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetSubscriptionAutoRenewalResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSubscriptionAutoRenewalResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): SetSubscriptionAutoRenewalResp {
    return {};
  },

  toJSON(_: SetSubscriptionAutoRenewalResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetSubscriptionAutoRenewalResp>, I>>(
    _: I,
  ): SetSubscriptionAutoRenewalResp {
    const message = createBaseSetSubscriptionAutoRenewalResp();
    return message;
  },
};

function createBaseSetSubscriptionAutoChargeReq(): SetSubscriptionAutoChargeReq {
  return { AccountID: '', SubscriptionStateID: '', AutoCharge: false };
}

export const SetSubscriptionAutoChargeReq = {
  encode(
    message: SetSubscriptionAutoChargeReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.SubscriptionStateID !== '') {
      writer.uint32(18).string(message.SubscriptionStateID);
    }
    if (message.AutoCharge === true) {
      writer.uint32(24).bool(message.AutoCharge);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetSubscriptionAutoChargeReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSubscriptionAutoChargeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.SubscriptionStateID = reader.string();
          break;
        case 3:
          message.AutoCharge = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetSubscriptionAutoChargeReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      SubscriptionStateID: isSet(object.SubscriptionStateID)
        ? String(object.SubscriptionStateID)
        : '',
      AutoCharge: isSet(object.AutoCharge) ? Boolean(object.AutoCharge) : false,
    };
  },

  toJSON(message: SetSubscriptionAutoChargeReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.SubscriptionStateID !== undefined &&
      (obj.SubscriptionStateID = message.SubscriptionStateID);
    message.AutoCharge !== undefined && (obj.AutoCharge = message.AutoCharge);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetSubscriptionAutoChargeReq>, I>>(
    object: I,
  ): SetSubscriptionAutoChargeReq {
    const message = createBaseSetSubscriptionAutoChargeReq();
    message.AccountID = object.AccountID ?? '';
    message.SubscriptionStateID = object.SubscriptionStateID ?? '';
    message.AutoCharge = object.AutoCharge ?? false;
    return message;
  },
};

function createBaseSetSubscriptionAutoChargeResp(): SetSubscriptionAutoChargeResp {
  return {};
}

export const SetSubscriptionAutoChargeResp = {
  encode(
    _: SetSubscriptionAutoChargeResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetSubscriptionAutoChargeResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSubscriptionAutoChargeResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): SetSubscriptionAutoChargeResp {
    return {};
  },

  toJSON(_: SetSubscriptionAutoChargeResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetSubscriptionAutoChargeResp>, I>>(
    _: I,
  ): SetSubscriptionAutoChargeResp {
    const message = createBaseSetSubscriptionAutoChargeResp();
    return message;
  },
};

function createBaseSetAccountBillingInformationReq(): SetAccountBillingInformationReq {
  return { AccountID: '', BillingInformation: undefined };
}

export const SetAccountBillingInformationReq = {
  encode(
    message: SetAccountBillingInformationReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.BillingInformation !== undefined) {
      AccountBillingInformation.encode(
        message.BillingInformation,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetAccountBillingInformationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAccountBillingInformationReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.BillingInformation = AccountBillingInformation.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetAccountBillingInformationReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      BillingInformation: isSet(object.BillingInformation)
        ? AccountBillingInformation.fromJSON(object.BillingInformation)
        : undefined,
    };
  },

  toJSON(message: SetAccountBillingInformationReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.BillingInformation !== undefined &&
      (obj.BillingInformation = message.BillingInformation
        ? AccountBillingInformation.toJSON(message.BillingInformation)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetAccountBillingInformationReq>, I>>(
    object: I,
  ): SetAccountBillingInformationReq {
    const message = createBaseSetAccountBillingInformationReq();
    message.AccountID = object.AccountID ?? '';
    message.BillingInformation =
      object.BillingInformation !== undefined &&
      object.BillingInformation !== null
        ? AccountBillingInformation.fromPartial(object.BillingInformation)
        : undefined;
    return message;
  },
};

function createBaseSetAccountBillingInformationResp(): SetAccountBillingInformationResp {
  return {};
}

export const SetAccountBillingInformationResp = {
  encode(
    _: SetAccountBillingInformationResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetAccountBillingInformationResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAccountBillingInformationResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): SetAccountBillingInformationResp {
    return {};
  },

  toJSON(_: SetAccountBillingInformationResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetAccountBillingInformationResp>, I>,
  >(_: I): SetAccountBillingInformationResp {
    const message = createBaseSetAccountBillingInformationResp();
    return message;
  },
};

function createBaseGetPaymentDocumentByOrderReq(): GetPaymentDocumentByOrderReq {
  return { OrderID: '' };
}

export const GetPaymentDocumentByOrderReq = {
  encode(
    message: GetPaymentDocumentByOrderReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrderID !== '') {
      writer.uint32(10).string(message.OrderID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetPaymentDocumentByOrderReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPaymentDocumentByOrderReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrderID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetPaymentDocumentByOrderReq {
    return {
      OrderID: isSet(object.OrderID) ? String(object.OrderID) : '',
    };
  },

  toJSON(message: GetPaymentDocumentByOrderReq): unknown {
    const obj: any = {};
    message.OrderID !== undefined && (obj.OrderID = message.OrderID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetPaymentDocumentByOrderReq>, I>>(
    object: I,
  ): GetPaymentDocumentByOrderReq {
    const message = createBaseGetPaymentDocumentByOrderReq();
    message.OrderID = object.OrderID ?? '';
    return message;
  },
};

function createBaseGetPaymentDocumentByOrderResp(): GetPaymentDocumentByOrderResp {
  return { document: new Uint8Array() };
}

export const GetPaymentDocumentByOrderResp = {
  encode(
    message: GetPaymentDocumentByOrderResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.document.length !== 0) {
      writer.uint32(10).bytes(message.document);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetPaymentDocumentByOrderResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPaymentDocumentByOrderResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.document = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetPaymentDocumentByOrderResp {
    return {
      document: isSet(object.document)
        ? bytesFromBase64(object.document)
        : new Uint8Array(),
    };
  },

  toJSON(message: GetPaymentDocumentByOrderResp): unknown {
    const obj: any = {};
    message.document !== undefined &&
      (obj.document = base64FromBytes(
        message.document !== undefined ? message.document : new Uint8Array(),
      ));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetPaymentDocumentByOrderResp>, I>>(
    object: I,
  ): GetPaymentDocumentByOrderResp {
    const message = createBaseGetPaymentDocumentByOrderResp();
    message.document = object.document ?? new Uint8Array();
    return message;
  },
};

function createBaseSetTransactionNumberToOrderReq(): SetTransactionNumberToOrderReq {
  return { OrderID: '', TransactionNumber: '' };
}

export const SetTransactionNumberToOrderReq = {
  encode(
    message: SetTransactionNumberToOrderReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrderID !== '') {
      writer.uint32(10).string(message.OrderID);
    }
    if (message.TransactionNumber !== '') {
      writer.uint32(18).string(message.TransactionNumber);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetTransactionNumberToOrderReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetTransactionNumberToOrderReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrderID = reader.string();
          break;
        case 2:
          message.TransactionNumber = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetTransactionNumberToOrderReq {
    return {
      OrderID: isSet(object.OrderID) ? String(object.OrderID) : '',
      TransactionNumber: isSet(object.TransactionNumber)
        ? String(object.TransactionNumber)
        : '',
    };
  },

  toJSON(message: SetTransactionNumberToOrderReq): unknown {
    const obj: any = {};
    message.OrderID !== undefined && (obj.OrderID = message.OrderID);
    message.TransactionNumber !== undefined &&
      (obj.TransactionNumber = message.TransactionNumber);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetTransactionNumberToOrderReq>, I>>(
    object: I,
  ): SetTransactionNumberToOrderReq {
    const message = createBaseSetTransactionNumberToOrderReq();
    message.OrderID = object.OrderID ?? '';
    message.TransactionNumber = object.TransactionNumber ?? '';
    return message;
  },
};

function createBaseSetTransactionNumberToOrderResp(): SetTransactionNumberToOrderResp {
  return {};
}

export const SetTransactionNumberToOrderResp = {
  encode(
    _: SetTransactionNumberToOrderResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetTransactionNumberToOrderResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetTransactionNumberToOrderResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): SetTransactionNumberToOrderResp {
    return {};
  },

  toJSON(_: SetTransactionNumberToOrderResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetTransactionNumberToOrderResp>, I>>(
    _: I,
  ): SetTransactionNumberToOrderResp {
    const message = createBaseSetTransactionNumberToOrderResp();
    return message;
  },
};

function createBaseTestSetAccountWithoutStripeInvoicesReq(): TestSetAccountWithoutStripeInvoicesReq {
  return { AccountID: '', WithoutStripeInvoices: false };
}

export const TestSetAccountWithoutStripeInvoicesReq = {
  encode(
    message: TestSetAccountWithoutStripeInvoicesReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.WithoutStripeInvoices === true) {
      writer.uint32(16).bool(message.WithoutStripeInvoices);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): TestSetAccountWithoutStripeInvoicesReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestSetAccountWithoutStripeInvoicesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.WithoutStripeInvoices = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TestSetAccountWithoutStripeInvoicesReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      WithoutStripeInvoices: isSet(object.WithoutStripeInvoices)
        ? Boolean(object.WithoutStripeInvoices)
        : false,
    };
  },

  toJSON(message: TestSetAccountWithoutStripeInvoicesReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.WithoutStripeInvoices !== undefined &&
      (obj.WithoutStripeInvoices = message.WithoutStripeInvoices);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<TestSetAccountWithoutStripeInvoicesReq>, I>,
  >(object: I): TestSetAccountWithoutStripeInvoicesReq {
    const message = createBaseTestSetAccountWithoutStripeInvoicesReq();
    message.AccountID = object.AccountID ?? '';
    message.WithoutStripeInvoices = object.WithoutStripeInvoices ?? false;
    return message;
  },
};

function createBaseTestSetAccountWithoutStripeInvoicesResp(): TestSetAccountWithoutStripeInvoicesResp {
  return {};
}

export const TestSetAccountWithoutStripeInvoicesResp = {
  encode(
    _: TestSetAccountWithoutStripeInvoicesResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): TestSetAccountWithoutStripeInvoicesResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestSetAccountWithoutStripeInvoicesResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): TestSetAccountWithoutStripeInvoicesResp {
    return {};
  },

  toJSON(_: TestSetAccountWithoutStripeInvoicesResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<TestSetAccountWithoutStripeInvoicesResp>, I>,
  >(_: I): TestSetAccountWithoutStripeInvoicesResp {
    const message = createBaseTestSetAccountWithoutStripeInvoicesResp();
    return message;
  },
};

function createBaseAddBillingZoneToAccountReq(): AddBillingZoneToAccountReq {
  return { AccountID: '', BillingZone: '' };
}

export const AddBillingZoneToAccountReq = {
  encode(
    message: AddBillingZoneToAccountReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.BillingZone !== '') {
      writer.uint32(18).string(message.BillingZone);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddBillingZoneToAccountReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddBillingZoneToAccountReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.BillingZone = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddBillingZoneToAccountReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      BillingZone: isSet(object.BillingZone) ? String(object.BillingZone) : '',
    };
  },

  toJSON(message: AddBillingZoneToAccountReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.BillingZone !== undefined &&
      (obj.BillingZone = message.BillingZone);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AddBillingZoneToAccountReq>, I>>(
    object: I,
  ): AddBillingZoneToAccountReq {
    const message = createBaseAddBillingZoneToAccountReq();
    message.AccountID = object.AccountID ?? '';
    message.BillingZone = object.BillingZone ?? '';
    return message;
  },
};

function createBaseAddBillingZoneToAccountResp(): AddBillingZoneToAccountResp {
  return {};
}

export const AddBillingZoneToAccountResp = {
  encode(
    _: AddBillingZoneToAccountResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddBillingZoneToAccountResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddBillingZoneToAccountResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): AddBillingZoneToAccountResp {
    return {};
  },

  toJSON(_: AddBillingZoneToAccountResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AddBillingZoneToAccountResp>, I>>(
    _: I,
  ): AddBillingZoneToAccountResp {
    const message = createBaseAddBillingZoneToAccountResp();
    return message;
  },
};

function createBaseRemoveBillingZoneToAccountReq(): RemoveBillingZoneToAccountReq {
  return { AccountID: '', BillingZone: '' };
}

export const RemoveBillingZoneToAccountReq = {
  encode(
    message: RemoveBillingZoneToAccountReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.BillingZone !== '') {
      writer.uint32(18).string(message.BillingZone);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RemoveBillingZoneToAccountReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveBillingZoneToAccountReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.BillingZone = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveBillingZoneToAccountReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      BillingZone: isSet(object.BillingZone) ? String(object.BillingZone) : '',
    };
  },

  toJSON(message: RemoveBillingZoneToAccountReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.BillingZone !== undefined &&
      (obj.BillingZone = message.BillingZone);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RemoveBillingZoneToAccountReq>, I>>(
    object: I,
  ): RemoveBillingZoneToAccountReq {
    const message = createBaseRemoveBillingZoneToAccountReq();
    message.AccountID = object.AccountID ?? '';
    message.BillingZone = object.BillingZone ?? '';
    return message;
  },
};

function createBaseRemoveBillingZoneToAccountResp(): RemoveBillingZoneToAccountResp {
  return {};
}

export const RemoveBillingZoneToAccountResp = {
  encode(
    _: RemoveBillingZoneToAccountResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RemoveBillingZoneToAccountResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveBillingZoneToAccountResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): RemoveBillingZoneToAccountResp {
    return {};
  },

  toJSON(_: RemoveBillingZoneToAccountResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RemoveBillingZoneToAccountResp>, I>>(
    _: I,
  ): RemoveBillingZoneToAccountResp {
    const message = createBaseRemoveBillingZoneToAccountResp();
    return message;
  },
};

export interface Billing {
  /** Get stream client with account info */
  AccountInfoStream(
    request: DeepPartial<AccountInfoStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<AccountInfoStreamResp>;
  /** Get available product objects by available product id or geo zone or account id */
  GetAvailableProducts(
    request: DeepPartial<GetAvailableProductsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetAvailableProductsResp>;
  /** Get all available products (salesman only) */
  GetAllAvailableProducts(
    request: DeepPartial<GetAllAvailableProductsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetAllAvailableProductsResp>;
  /** Create order */
  CreateOrder(
    request: DeepPartial<CreateOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateOrderResp>;
  /** Process order by id (salesman only) */
  ProcessOrder(
    request: DeepPartial<ProcessOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<ProcessOrderResp>;
  /** Pay order (owner only) */
  PayOrder(
    request: DeepPartial<PayOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<PayOrderResp>;
  /** Get stripe's page with payment method creation */
  CreateAccountPaymentMethod(
    request: DeepPartial<CreateAccountPaymentMethodReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateAccountPaymentMethodResp>;
  /** Delete account payment method by stripe's id */
  DeleteAccountPaymentMethod(
    request: DeepPartial<DeleteAccountPaymentMethodReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteAccountPaymentMethodResp>;
  /** (salesman only) */
  RemoveProductState(
    request: DeepPartial<RemoveProductStateReq>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveProductStateResp>;
  /** (salesman only) */
  ActivateSubscriptionState(
    request: DeepPartial<ActivateSubscriptionStateReq>,
    metadata?: grpc.Metadata,
  ): Promise<ActivateSubscriptionStateResp>;
  /** Set/unset autorenewal for a specific subscription */
  SetSubscriptionAutoRenewal(
    request: DeepPartial<SetSubscriptionAutoRenewalReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetSubscriptionAutoRenewalResp>;
  /** Allow/disallow autocharge for a specific subscription */
  SetSubscriptionAutoCharge(
    request: DeepPartial<SetSubscriptionAutoChargeReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetSubscriptionAutoChargeResp>;
  SetAccountBillingInformation(
    request: DeepPartial<SetAccountBillingInformationReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetAccountBillingInformationResp>;
  GetPaymentDocumentByOrder(
    request: DeepPartial<GetPaymentDocumentByOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetPaymentDocumentByOrderResp>;
  SetTransactionNumberToOrder(
    request: DeepPartial<SetTransactionNumberToOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetTransactionNumberToOrderResp>;
  /** (salesman only) */
  AddBillingZoneToAccount(
    request: DeepPartial<AddBillingZoneToAccountReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddBillingZoneToAccountResp>;
  /** (salesman only) */
  RemoveBillingZoneToAccount(
    request: DeepPartial<RemoveBillingZoneToAccountReq>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveBillingZoneToAccountResp>;
  /** Only for QA */
  Test_SetAccountWithoutStripeInvoices(
    request: DeepPartial<TestSetAccountWithoutStripeInvoicesReq>,
    metadata?: grpc.Metadata,
  ): Promise<TestSetAccountWithoutStripeInvoicesResp>;
}

export class BillingClientImpl implements Billing {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.AccountInfoStream = this.AccountInfoStream.bind(this);
    this.GetAvailableProducts = this.GetAvailableProducts.bind(this);
    this.GetAllAvailableProducts = this.GetAllAvailableProducts.bind(this);
    this.CreateOrder = this.CreateOrder.bind(this);
    this.ProcessOrder = this.ProcessOrder.bind(this);
    this.PayOrder = this.PayOrder.bind(this);
    this.CreateAccountPaymentMethod =
      this.CreateAccountPaymentMethod.bind(this);
    this.DeleteAccountPaymentMethod =
      this.DeleteAccountPaymentMethod.bind(this);
    this.RemoveProductState = this.RemoveProductState.bind(this);
    this.ActivateSubscriptionState = this.ActivateSubscriptionState.bind(this);
    this.SetSubscriptionAutoRenewal =
      this.SetSubscriptionAutoRenewal.bind(this);
    this.SetSubscriptionAutoCharge = this.SetSubscriptionAutoCharge.bind(this);
    this.SetAccountBillingInformation =
      this.SetAccountBillingInformation.bind(this);
    this.GetPaymentDocumentByOrder = this.GetPaymentDocumentByOrder.bind(this);
    this.SetTransactionNumberToOrder =
      this.SetTransactionNumberToOrder.bind(this);
    this.AddBillingZoneToAccount = this.AddBillingZoneToAccount.bind(this);
    this.RemoveBillingZoneToAccount =
      this.RemoveBillingZoneToAccount.bind(this);
    this.Test_SetAccountWithoutStripeInvoices =
      this.Test_SetAccountWithoutStripeInvoices.bind(this);
  }

  AccountInfoStream(
    request: DeepPartial<AccountInfoStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<AccountInfoStreamResp> {
    return this.rpc.invoke(
      BillingAccountInfoStreamDesc,
      AccountInfoStreamReq.fromPartial(request),
      metadata,
    );
  }

  GetAvailableProducts(
    request: DeepPartial<GetAvailableProductsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetAvailableProductsResp> {
    return this.rpc.unary(
      BillingGetAvailableProductsDesc,
      GetAvailableProductsReq.fromPartial(request),
      metadata,
    );
  }

  GetAllAvailableProducts(
    request: DeepPartial<GetAllAvailableProductsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetAllAvailableProductsResp> {
    return this.rpc.unary(
      BillingGetAllAvailableProductsDesc,
      GetAllAvailableProductsReq.fromPartial(request),
      metadata,
    );
  }

  CreateOrder(
    request: DeepPartial<CreateOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateOrderResp> {
    return this.rpc.unary(
      BillingCreateOrderDesc,
      CreateOrderReq.fromPartial(request),
      metadata,
    );
  }

  ProcessOrder(
    request: DeepPartial<ProcessOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<ProcessOrderResp> {
    return this.rpc.unary(
      BillingProcessOrderDesc,
      ProcessOrderReq.fromPartial(request),
      metadata,
    );
  }

  PayOrder(
    request: DeepPartial<PayOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<PayOrderResp> {
    return this.rpc.unary(
      BillingPayOrderDesc,
      PayOrderReq.fromPartial(request),
      metadata,
    );
  }

  CreateAccountPaymentMethod(
    request: DeepPartial<CreateAccountPaymentMethodReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateAccountPaymentMethodResp> {
    return this.rpc.unary(
      BillingCreateAccountPaymentMethodDesc,
      CreateAccountPaymentMethodReq.fromPartial(request),
      metadata,
    );
  }

  DeleteAccountPaymentMethod(
    request: DeepPartial<DeleteAccountPaymentMethodReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteAccountPaymentMethodResp> {
    return this.rpc.unary(
      BillingDeleteAccountPaymentMethodDesc,
      DeleteAccountPaymentMethodReq.fromPartial(request),
      metadata,
    );
  }

  RemoveProductState(
    request: DeepPartial<RemoveProductStateReq>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveProductStateResp> {
    return this.rpc.unary(
      BillingRemoveProductStateDesc,
      RemoveProductStateReq.fromPartial(request),
      metadata,
    );
  }

  ActivateSubscriptionState(
    request: DeepPartial<ActivateSubscriptionStateReq>,
    metadata?: grpc.Metadata,
  ): Promise<ActivateSubscriptionStateResp> {
    return this.rpc.unary(
      BillingActivateSubscriptionStateDesc,
      ActivateSubscriptionStateReq.fromPartial(request),
      metadata,
    );
  }

  SetSubscriptionAutoRenewal(
    request: DeepPartial<SetSubscriptionAutoRenewalReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetSubscriptionAutoRenewalResp> {
    return this.rpc.unary(
      BillingSetSubscriptionAutoRenewalDesc,
      SetSubscriptionAutoRenewalReq.fromPartial(request),
      metadata,
    );
  }

  SetSubscriptionAutoCharge(
    request: DeepPartial<SetSubscriptionAutoChargeReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetSubscriptionAutoChargeResp> {
    return this.rpc.unary(
      BillingSetSubscriptionAutoChargeDesc,
      SetSubscriptionAutoChargeReq.fromPartial(request),
      metadata,
    );
  }

  SetAccountBillingInformation(
    request: DeepPartial<SetAccountBillingInformationReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetAccountBillingInformationResp> {
    return this.rpc.unary(
      BillingSetAccountBillingInformationDesc,
      SetAccountBillingInformationReq.fromPartial(request),
      metadata,
    );
  }

  GetPaymentDocumentByOrder(
    request: DeepPartial<GetPaymentDocumentByOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetPaymentDocumentByOrderResp> {
    return this.rpc.unary(
      BillingGetPaymentDocumentByOrderDesc,
      GetPaymentDocumentByOrderReq.fromPartial(request),
      metadata,
    );
  }

  SetTransactionNumberToOrder(
    request: DeepPartial<SetTransactionNumberToOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetTransactionNumberToOrderResp> {
    return this.rpc.unary(
      BillingSetTransactionNumberToOrderDesc,
      SetTransactionNumberToOrderReq.fromPartial(request),
      metadata,
    );
  }

  AddBillingZoneToAccount(
    request: DeepPartial<AddBillingZoneToAccountReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddBillingZoneToAccountResp> {
    return this.rpc.unary(
      BillingAddBillingZoneToAccountDesc,
      AddBillingZoneToAccountReq.fromPartial(request),
      metadata,
    );
  }

  RemoveBillingZoneToAccount(
    request: DeepPartial<RemoveBillingZoneToAccountReq>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveBillingZoneToAccountResp> {
    return this.rpc.unary(
      BillingRemoveBillingZoneToAccountDesc,
      RemoveBillingZoneToAccountReq.fromPartial(request),
      metadata,
    );
  }

  Test_SetAccountWithoutStripeInvoices(
    request: DeepPartial<TestSetAccountWithoutStripeInvoicesReq>,
    metadata?: grpc.Metadata,
  ): Promise<TestSetAccountWithoutStripeInvoicesResp> {
    return this.rpc.unary(
      BillingTest_SetAccountWithoutStripeInvoicesDesc,
      TestSetAccountWithoutStripeInvoicesReq.fromPartial(request),
      metadata,
    );
  }
}

export const BillingDesc = {
  serviceName: 'com.diagnocat.billing_new.Billing',
};

export const BillingAccountInfoStreamDesc: UnaryMethodDefinitionish = {
  methodName: 'AccountInfoStream',
  service: BillingDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return AccountInfoStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AccountInfoStreamResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingGetAvailableProductsDesc: UnaryMethodDefinitionish = {
  methodName: 'GetAvailableProducts',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAvailableProductsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...GetAvailableProductsResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingGetAllAvailableProductsDesc: UnaryMethodDefinitionish = {
  methodName: 'GetAllAvailableProducts',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAllAvailableProductsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...GetAllAvailableProductsResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingCreateOrderDesc: UnaryMethodDefinitionish = {
  methodName: 'CreateOrder',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateOrderReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...CreateOrderResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingProcessOrderDesc: UnaryMethodDefinitionish = {
  methodName: 'ProcessOrder',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ProcessOrderReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ProcessOrderResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingPayOrderDesc: UnaryMethodDefinitionish = {
  methodName: 'PayOrder',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PayOrderReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...PayOrderResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingCreateAccountPaymentMethodDesc: UnaryMethodDefinitionish = {
  methodName: 'CreateAccountPaymentMethod',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateAccountPaymentMethodReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...CreateAccountPaymentMethodResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingDeleteAccountPaymentMethodDesc: UnaryMethodDefinitionish = {
  methodName: 'DeleteAccountPaymentMethod',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteAccountPaymentMethodReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DeleteAccountPaymentMethodResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingRemoveProductStateDesc: UnaryMethodDefinitionish = {
  methodName: 'RemoveProductState',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveProductStateReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...RemoveProductStateResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingActivateSubscriptionStateDesc: UnaryMethodDefinitionish = {
  methodName: 'ActivateSubscriptionState',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ActivateSubscriptionStateReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ActivateSubscriptionStateResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingSetSubscriptionAutoRenewalDesc: UnaryMethodDefinitionish = {
  methodName: 'SetSubscriptionAutoRenewal',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetSubscriptionAutoRenewalReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetSubscriptionAutoRenewalResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingSetSubscriptionAutoChargeDesc: UnaryMethodDefinitionish = {
  methodName: 'SetSubscriptionAutoCharge',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetSubscriptionAutoChargeReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetSubscriptionAutoChargeResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingSetAccountBillingInformationDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetAccountBillingInformation',
    service: BillingDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetAccountBillingInformationReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetAccountBillingInformationResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const BillingGetPaymentDocumentByOrderDesc: UnaryMethodDefinitionish = {
  methodName: 'GetPaymentDocumentByOrder',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetPaymentDocumentByOrderReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...GetPaymentDocumentByOrderResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingSetTransactionNumberToOrderDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetTransactionNumberToOrder',
    service: BillingDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetTransactionNumberToOrderReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetTransactionNumberToOrderResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const BillingAddBillingZoneToAccountDesc: UnaryMethodDefinitionish = {
  methodName: 'AddBillingZoneToAccount',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddBillingZoneToAccountReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AddBillingZoneToAccountResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingRemoveBillingZoneToAccountDesc: UnaryMethodDefinitionish = {
  methodName: 'RemoveBillingZoneToAccount',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveBillingZoneToAccountReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...RemoveBillingZoneToAccountResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingTest_SetAccountWithoutStripeInvoicesDesc: UnaryMethodDefinitionish =
  {
    methodName: 'Test_SetAccountWithoutStripeInvoices',
    service: BillingDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return TestSetAccountWithoutStripeInvoicesReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...TestSetAccountWithoutStripeInvoicesResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
    const upStreamCodes = [2, 4, 8, 9, 10, 13, 14, 15];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              observer.error(new Error(`Error ${code} ${message}`));
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

const atob: (b64: string) => string =
  globalThis.atob ||
  ((b64) => globalThis.Buffer.from(b64, 'base64').toString('binary'));
function bytesFromBase64(b64: string): Uint8Array {
  const bin = atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

const btoa: (bin: string) => string =
  globalThis.btoa ||
  ((bin) => globalThis.Buffer.from(bin, 'binary').toString('base64'));
function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  arr.forEach((byte) => {
    bin.push(String.fromCharCode(byte));
  });
  return btoa(bin.join(''));
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
