// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrganizationRow-module__container--Ce6zxvvy{border:4px solid var(--bg2);padding:4px;border-radius:32px;transition:var(--transition-time);cursor:pointer;user-select:none}.OrganizationRow-module__container--Ce6zxvvy>div{width:100%}.OrganizationRow-module__container--Ce6zxvvy:hover{border:4px solid var(--purpleflat)}.OrganizationRow-module__container--Ce6zxvvy:focus-visible{outline:none}.OrganizationRow-module__info--t3ZDFc4k{display:flex;align-items:center;gap:8px}.OrganizationRow-module__textEllipsis--IFCY36Yb{text-overflow:ellipsis;overflow:hidden;white-space:pre}.OrganizationRow-module__currentOrganizationText--DaV0hQwr{white-space:pre-line}", "",{"version":3,"sources":["webpack://./src/widgets/Header/ui/OrganizationRow/OrganizationRow.module.scss"],"names":[],"mappings":"AAAA,6CACE,2BAAA,CACA,WAAA,CACA,kBAAA,CACA,iCAAA,CACA,cAAA,CACA,gBAAA,CAEA,iDACE,UAAA,CAGF,mDACE,kCAAA,CAGF,2DACE,YAAA,CAIJ,wCACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,gDACE,sBAAA,CACA,eAAA,CACA,eAAA,CAGF,2DACE,oBAAA","sourcesContent":[".container {\n  border: 4px solid var(--bg2);\n  padding: 4px;\n  border-radius: 32px;\n  transition: var(--transition-time);\n  cursor: pointer;\n  user-select: none;\n\n  > div {\n    width: 100%;\n  }\n\n  &:hover {\n    border: 4px solid var(--purpleflat);\n  }\n\n  &:focus-visible {\n    outline: none;\n  }\n}\n\n.info {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.textEllipsis {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: pre;\n}\n\n.currentOrganizationText {\n  white-space: pre-line;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OrganizationRow-module__container--Ce6zxvvy",
	"info": "OrganizationRow-module__info--t3ZDFc4k",
	"textEllipsis": "OrganizationRow-module__textEllipsis--IFCY36Yb",
	"currentOrganizationText": "OrganizationRow-module__currentOrganizationText--DaV0hQwr"
};
export default ___CSS_LOADER_EXPORT___;
