import React, { FC, useCallback, useEffect } from 'react';
import { Outlet, useParams } from 'react-router';
import { useIntl } from 'react-intl';

import { ToastErrors, toastErrors } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';
import { Spinner, toastCaller } from '@/shared/ui';
import { ApiError } from '@/shared/api/api';

import { userModel } from '@/entities/user';

import { UploadAssetProvider } from '@/features/uploadAsset';

import { useUserDataStream } from '@/app/hooks/useUserDataStream';

import { useCheckInviteToken } from '../hooks/useCheckInviteToken';
import { useAccountInfoStream } from '../hooks/useAccountInfoStream';
import { UploadAssetStatus } from '../ui/UploadAssetStatus/UploadAssetStatus';

import styles from './ProtectedRoutes.module.scss';

export const ProtectedRoutes: FC = () => {
  const { formatMessage } = useIntl();
  const { token } = useParams();

  useUserDataStream();
  useAccountInfoStream();

  const checkInviteToken = useCheckInviteToken();

  const currentUser = useAppSelector(userModel.selectors.selectCurrentUser);
  const userLoading = useAppSelector(userModel.selectors.selectLoading);

  const userIsLoading = userLoading === 'pending' || userLoading === 'idle';

  const errorHandler = useCallback(
    (error: ApiError) => {
      const parsedMessage = JSON.parse(error?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    },
    [formatMessage],
  );

  // check invite token
  useEffect(() => {
    try {
      if (token) {
        checkInviteToken(token);
      }
    } catch (error) {
      errorHandler(error);
    }
  }, []);

  if (userIsLoading)
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner className={styles.spinner} size={96} />
      </div>
    );

  return (
    currentUser?.ID && (
      <UploadAssetProvider>
        <Outlet />
        <UploadAssetStatus />
      </UploadAssetProvider>
    )
  );
};
