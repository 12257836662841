// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PatientInfo-module__container--Y5lfh8KI{display:flex;justify-content:center;align-items:center;gap:24px}", "",{"version":3,"sources":["webpack://./src/widgets/PatientInfo/ui/PatientInfo.module.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,QAAA","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PatientInfo-module__container--Y5lfh8KI"
};
export default ___CSS_LOADER_EXPORT___;
