import { FC } from 'react';
import cn from 'classnames';

import { TeethChartFilter } from '../../config/tooth.type';

import styles from './TeethChartFilters.module.scss';

export type TeethChartFiltersProps = {
  filters: TeethChartFilter[];
  onClick?: (ISONumbers: number[]) => void;
  className?: string;
};

// TODO: Styles handling in this component seems overcomplicated, maybe there is a simpler solution?
export const TeethChartFilters: FC<TeethChartFiltersProps> = (props) => {
  const { className, filters, onClick } = props;

  return (
    <div className={cn(styles.container, className)}>
      {filters.map((filter) => {
        const { type, ISONumbers, title, active } = filter;

        return (
          <div
            className={cn(
              styles.legend,
              `${type}ToothChartLegend`,
              active && styles.active,
              onClick && styles.pointer,
            )}
            key={type}
            onClick={() => {
              if (onClick) {
                onClick(ISONumbers);
              }
            }}
          >
            <span
              className={cn(
                styles.legendTitle,
                (type === 'missing' || type === 'all' || type === 'healthy') &&
                  styles.legendGreyInfo,
                'p3t',
              )}
            >
              {title}
            </span>

            <span
              className={cn(
                styles.legendCount,
                (type === 'missing' || type === 'all' || type === 'healthy') &&
                  styles.legendGreyInfo,
                'p3t',
              )}
            >
              {ISONumbers.length}
            </span>
          </div>
        );
      })}
    </div>
  );
};
