import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import BasicCBCTToStlImage from '@/shared/assets/images/basic-CBCT-to-Stl.png';
import AdvancedCBCTAndIOSImage from '@/shared/assets/images/Advanced-CBCT-and-IOS.png';
import AdvancedCBCTAndDentalImage from '@/shared/assets/images/Advanced-CBCT-and-dental.png';

import { SegmentronModelType } from '../config/i18n';
import styles from '../ui/Order3DSegmentronModal/Order3DSegmentronModal.module.scss';

export const useGetSegmentronModelTypeOptions = () => {
  const { formatMessage } = useIntl();

  const segmentronModelTypes = useMemo(
    () => [
      {
        value: SegmentronModelType.BasicCBCTToSTL,
        label: (
          <div className={styles.radioLabel}>
            <p className="p2">
              <FormattedMessage
                id="Order3dSegmentronModal.BasicCBCTtoSTL"
                defaultMessage="Basic CBCT to STL segmentation"
              />
            </p>
            <img
              src={BasicCBCTToStlImage}
              alt={formatMessage({
                id: 'Order3dSegmentronModal.BasicCBCTtoSTL',
                defaultMessage: 'Basic CBCT to STL segmentation',
              })}
            />
          </div>
        ),
      },
      {
        value: SegmentronModelType.AdvancedCBCTAndIOS,
        label: (
          <div className={styles.radioLabel}>
            <p className="p2">
              <FormattedMessage
                id="Order3dSegmentronModal.AdvancedCBCTandIOS"
                defaultMessage="Advanced CBCT and IOS fusion"
              />
            </p>
            <img
              src={AdvancedCBCTAndIOSImage}
              alt={formatMessage({
                id: 'Order3dSegmentronModal.AdvancedCBCTandIOS',
                defaultMessage: 'Advanced CBCT and IOS fusion',
              })}
            />
          </div>
        ),
      },
      {
        value: SegmentronModelType.AdvancedCBCTAndDental,
        label: (
          <div className={styles.radioLabel}>
            <p className="p2">
              <FormattedMessage
                id="Order3dSegmentronModal.AdvancedCBCTanddental"
                defaultMessage="Advanced CBCT and dental photo"
              />
            </p>
            <img
              src={AdvancedCBCTAndDentalImage}
              alt={formatMessage({
                id: 'Order3dSegmentronModal.AdvancedCBCTanddental',
                defaultMessage: 'Advanced CBCT and dental photo',
              })}
            />
          </div>
        ),
      },
    ],
    [formatMessage],
  );

  return segmentronModelTypes;
};
