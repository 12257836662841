import { FC, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Modal, ModalProps, toastCaller } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { ToastErrors, toastErrors } from '@/shared/config';
import { ReportPrintSettings } from '@/shared/api/protocol_gen/model/dto_report';

import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';

import styles from './ConfirmSignReportModal.module.scss';

type ConfirmSignReportProps = {
  className?: string;
  reportID: string;
  yellowConditionsIDs: string[];
} & Pick<ModalProps, 'isOpen' | 'onClose' | 'onApply'>;

export const ConfirmSignReportModal: FC<ConfirmSignReportProps> = (props) => {
  const { isOpen, reportID, onClose, className, yellowConditionsIDs } = props;

  const [loading, setLoading] = useState(false);

  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const report = useAppSelector(reportsModel.selectors.selectByID(reportID));

  const newPrintSettings: ReportPrintSettings = {
    ...report?.PrintSettings,
    ShowDiagnosesProbabilities: false,
  };

  const handleApproveAndSign = async () => {
    setLoading(true);
    try {
      await dispatch(
        reportsModel.thunks.setReportPrintSettings({
          ReportID: reportID,
          PrintSettings: newPrintSettings,
        }),
      );

      const currentReport = await dispatch(
        reportsModel.thunks.approveAllTeethAndSignReport({
          ReportID: reportID,
          TeethConditionIDs: yellowConditionsIDs,
        }),
      ).unwrap();

      dispatch(reportsModel.actions.setNewestOne(currentReport.Report));
      dispatch(toothModel.actions.setMany(currentReport.Teeth));
    } catch (error) {
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);
      const parsedMessage = JSON.parse(error?.message);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    } finally {
      setLoading(false);
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={formatMessage({
        id: 'consfirmSignReportModal.approveAll',
        defaultMessage: 'Approve all teeth',
      })}
      onApply={handleApproveAndSign}
      onClose={onClose}
      className={cn(styles.container, className)}
      applyButtonText={
        <FormattedMessage
          id="report.confirm.approveAndSign"
          defaultMessage="Yes, approve all and sign"
        />
      }
      applyButtonProps={{ loading }}
    >
      <FormattedMessage
        id="report.warning.approveAndSign"
        defaultMessage="Are you sure you want to approve all the teeth and include in the report?"
      />
    </Modal>
  );
};
