import { defineMessages } from 'react-intl';

import { MedicalImageLabelsTypes } from './types';

export const medicalImagesLabels = defineMessages<MedicalImageLabelsTypes>({
  frontal: {
    id: 'sliceLabel.frontal',
    defaultMessage: 'Frontal',
  },
  axial: {
    id: 'sliceLabel.axial',
    defaultMessage: 'Axial',
  },
  'mesio-distal': {
    id: 'sliceLabel.mesioDistal',
    defaultMessage: 'Mesio-distal',
  },
  sagittal: {
    id: 'sliceLabel.sagittal',
    defaultMessage: 'Sagittal',
  },
  'vestibulo-oral': {
    id: 'sliceLabel.vestibuloOral',
    defaultMessage: 'Vestibulo-oral',
  },
  uploaded: {
    id: 'sliceLabel.uploaded',
    defaultMessage: 'Uploaded',
  },
});
