// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Description-module__container--8PxGygsi{display:inline-flex;gap:4px;width:96%}@media screen and (max-width: 768px){.Description-module__container--8PxGygsi{display:block}}.Description-module__label--ic3VG\\+Vw{color:var(--grey9)}@media screen and (max-width: 768px){.Description-module__label--ic3VG\\+Vw{display:block}}.Description-module__content--3NMiiT8Q{color:var(--bw);overflow-wrap:anywhere}.Description-module__vertical--Lz8qSTRt{flex-direction:column}", "",{"version":3,"sources":["webpack://./src/shared/ui/Description/Description.module.scss","webpack://./src/shared/styles/media.scss"],"names":[],"mappings":"AAEA,yCACE,mBAAA,CACA,OAAA,CACA,SAAA,CCQA,qCDXF,yCAMI,aAAA,CAAA,CAIJ,sCACE,kBAAA,CAAA,qCADF,sCAII,aAAA,CAAA,CAIJ,uCACE,eAAA,CACA,sBAAA,CAGF,wCACE,qBAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.container {\n  display: inline-flex;\n  gap: 4px;\n  width: 96%;\n\n  @include tablet {\n    display: block;\n  }\n}\n\n.label {\n  color: var(--grey9);\n\n  @include tablet {\n    display: block;\n  }\n}\n\n.content {\n  color: var(--bw);\n  overflow-wrap: anywhere;\n}\n\n.vertical {\n  flex-direction: column;\n}\n","$mobile: 420px;\n$tablet: 768px;\n$smallDesktop: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1400px;\n\n@mixin mobile {\n  @media screen and (max-width: $mobile) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media screen and (max-width: $tablet) {\n    @content;\n  }\n}\n\n@mixin smallDesktop {\n  @media screen and (max-width: $smallDesktop) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media screen and (max-width: $desktop) {\n    @content;\n  }\n}\n\n@mixin largeDesktop {\n  @media screen and (max-width: $largeDesktop) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Description-module__container--8PxGygsi",
	"label": "Description-module__label--ic3VG+Vw",
	"content": "Description-module__content--3NMiiT8Q",
	"vertical": "Description-module__vertical--Lz8qSTRt"
};
export default ___CSS_LOADER_EXPORT___;
