// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToothChartRoot-module__container--0KwFfDZk{display:flex;flex-direction:column}.ToothChartRoot-module__container--0KwFfDZk.ToothChartRoot-module__sizeDefault--gq8tRE5S{gap:12px}.ToothChartRoot-module__container--0KwFfDZk.ToothChartRoot-module__sizeSmall--D4u\\+Bk46{gap:8px}", "",{"version":3,"sources":["webpack://./src/features/toothChart/ui/ToothChartRoot/ToothChartRoot.module.scss"],"names":[],"mappings":"AAAA,4CACE,YAAA,CACA,qBAAA,CAEA,yFACE,QAAA,CAGF,wFACE,OAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n\n  &.sizeDefault {\n    gap: 12px;\n  }\n\n  &.sizeSmall {\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ToothChartRoot-module__container--0KwFfDZk",
	"sizeDefault": "ToothChartRoot-module__sizeDefault--gq8tRE5S",
	"sizeSmall": "ToothChartRoot-module__sizeSmall--D4u+Bk46"
};
export default ___CSS_LOADER_EXPORT___;
