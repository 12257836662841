import { useMemo } from 'react';
import { uniq } from 'ramda';

import { IOXRaySubimage } from '@/shared/api/protocol_gen/model/dto_report_type_ioxray_gp';
import { useAppSelector } from '@/shared/hooks';
import {
  LocalizationType,
  Localization,
} from '@/shared/api/protocol_gen/model/dto_report_localization';
import { Report } from '@/shared/api/protocol_gen/model/dto_report';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import { reportsModel } from '@/entities/reports';
import { toothModel, ToothWithLocalization } from '@/entities/tooth';

import { doBBoxOverlap } from '../lib/doBBoxOverlap';
import { isSubImageHasToothLocalization } from '../lib/isSubImageHasToothLocalization';

export type SubImageInterface = IOXRaySubimage & {
  teethISONumbers: number[];
  teethLocalizations: ToothWithLocalization[];
};

// TODO: Refactor for IOXRay images from assets
// Need to add case when in report has no subImages just only IOXRay image(assetsSlice) and rename hook
export const useTransformSubImage = (reportID: string): SubImageInterface[] => {
  const selectReport = useMemo(
    () => reportsModel.selectors.selectByID(reportID),
    [reportID],
  );
  const selectTeeth = useMemo(
    () => toothModel.selectors.selectByReportID(reportID),
    [reportID],
  );

  const report: Report = useAppSelector(selectReport);
  const teeth: Tooth[] = useAppSelector(selectTeeth);

  const subImageInterface = useMemo(
    () =>
      teeth?.length
        ? report?.DataIOXRayGP?.Subimages?.map((subImage) => {
            const teethLocalizations: ToothWithLocalization[] = [];
            const teethISONumbers: number[] = teeth.reduce(
              (isoNumbers, tooth) => {
                if (isSubImageHasToothLocalization(subImage, tooth)) {
                  tooth.Localizations.forEach((localization) => {
                    if (
                      subImage?.BBox &&
                      localization.BBox &&
                      tooth.Numeration?.ISO
                    ) {
                      const isBBoxOverlap = doBBoxOverlap(
                        subImage?.BBox,
                        localization.BBox,
                      );
                      if (isBBoxOverlap) {
                        isoNumbers.push(tooth.Numeration?.ISO);
                        teethLocalizations.push({
                          toothID: tooth.ID,
                          ISONumber: tooth.Numeration.ISO,
                          Localization: tooth.Localizations.find(
                            (local) =>
                              local.Type ===
                              LocalizationType.LocalizationType2D,
                          ) as Localization,
                        });
                      }
                    }
                  });
                }

                return isoNumbers;
              },
              [] as number[],
            );

            return {
              ...subImage,
              teethISONumbers: uniq(teethISONumbers),
              teethLocalizations,
            };
          })
        : [],
    [teeth, report?.DataIOXRayGP?.Subimages],
  );

  return subImageInterface || [];
};
