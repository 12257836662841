import { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { isEqual } from 'lodash';

import { UltimateMedicalImage } from '@/shared/graphics/RenderComponents/Presets/UltimateMedicalImage';
import { useDebouncedFunction, useMedia } from '@/shared/hooks';
import { ToolNames } from '@/shared/config';
import { RCContainerStyle } from '@/shared/graphics/RenderComponents/RCContainer/RCContainer';
import { AbsoluteBlock } from '@/shared/graphics/RenderComponents/AbsoluteBlock';
import { RCRuler } from '@/shared/graphics/RenderComponents/RCRuler/RCRuler';
import { AnnotationOrEraser } from '@/shared/graphics/RenderComponents/Presets/DicomImageWithAnnotations';
import {
  Localization,
  RCLocalizations,
} from '@/shared/graphics/RenderComponents/RCLocalizations/RCLocalizations';

import {
  shouldGetWwwcFromImageContext,
  useMedicalImageControls,
} from '@/entities/reports';
import { increaseImageHeight } from '@/entities/assets';

import { ImageToolbar } from '@/features/imageToolbar';

import { ProcessingUnitContext } from 'graphics';

import { ExpandedPanoModal } from '../ExpandedPanoModal/ExpandedPanoModal';

import styles from './PanoImage.module.scss';

type PanoImageProps = {
  src: string;
  controls?: ToolNames[];
  toggled?: boolean;
  toolbarPosition?: 'inside' | 'left';
  className?: string;
  style?: RCContainerStyle;
  ruler?: boolean;
  kind?: 'dicom' | 'raster' | 'dicom-mask';
  containerWidth: number;
  splitUrl?: string;
  handlePanoContext?: (context: ProcessingUnitContext) => void;
  fixedHeight?: number;
  assetID?: string;
  viewOptions?: {
    sharpness: number;
    invert: boolean;
    wwwc: { ww: number; wc: number };
  };
  objectFit?: 'cover' | 'contain' | 'fill';
  localizations?: Localization[];
};

type PanoImagePreviewProps = {
  src: string;
  inverted: boolean;
  kind: 'dicom' | 'raster' | 'dicom-mask';
  containerWidth?: number;
};

export const PanoImagePreview: FC<PanoImagePreviewProps> = (props) => {
  const { src, inverted, kind, containerWidth } = props;

  const [imageContext, setImageContext] = useState<ProcessingUnitContext>();
  const [imageHeight, setImageHeight] = useState(0);

  const container = useRef<HTMLDivElement>(null);

  const {
    mode,
    annotations,
    setAnnotations,
    sharpness,
    setSharpness,
    currentAnnotation,
    wwwc,
    setWWWC,
  } = useMedicalImageControls(src);

  const onLoaded = (context: ProcessingUnitContext) => {
    setImageContext(context);
  };

  useEffect(() => {
    if (imageContext) {
      const calculatedImageHeight = increaseImageHeight({
        width: imageContext?.width,
        height: imageContext?.height,
        newWidth: containerWidth,
      });

      setImageHeight(Number(calculatedImageHeight));
    }
  }, [containerWidth, imageContext]);

  // TODO: Try MedicalImage components instead of Ultimate
  return (
    <div ref={container}>
      <UltimateMedicalImage
        src={{ url: src, kind }}
        mode={mode}
        onLoaded={onLoaded}
        currentAnnotation={currentAnnotation as AnnotationOrEraser}
        annotations={annotations}
        onAnnotationsChanged={setAnnotations}
        onWWWCChanged={setWWWC}
        ww={wwwc?.ww}
        wc={wwwc?.wc}
        sharpness={sharpness}
        onSharpnessChanged={setSharpness}
        inverted={inverted}
        className={styles.ultimateDicom}
        style={{ height: imageHeight }}
      />
    </div>
  );
};

export const PanoImage: FC<PanoImageProps> = (props) => {
  const {
    src,
    controls = [],
    toggled,
    toolbarPosition = 'left',
    className,
    style,
    ruler,
    kind = 'dicom',
    containerWidth,
    handlePanoContext,
    splitUrl,
    fixedHeight,
    assetID,
    viewOptions,
    objectFit,
    localizations,
  } = props;

  const [imageContext, setImageContext] = useState<ProcessingUnitContext>();
  const [actualUrl, setActualUrl] = useState(src);

  const { isMobile } = useMedia();

  const {
    activeControl,
    setActiveControl,
    mode,
    annotations,
    setAnnotations,
    sharpness,
    setSharpness,
    currentAnnotation,
    viewAnnotations,
    wwwc: currentWwwc,
    setWWWC,
    expandedImage,
    setExpandedImage,
    splited,
    setSplited,
    inverted,
    setInverted,
    handleSetMedicalImageViewOptions,
  } = useMedicalImageControls(actualUrl, assetID, viewOptions);

  const onLoaded = (context: ProcessingUnitContext) => {
    setImageContext(context);

    if (shouldGetWwwcFromImageContext(viewOptions)) {
      setWWWC({
        ww: context.sourceWindowWidth,
        wc: context.sourceWindowCenter,
      });
    }

    if (typeof handlePanoContext === 'function') {
      handlePanoContext(context);
    }
  };

  const handleCloseModal = () => {
    setExpandedImage('');
    setActiveControl('');
  };

  const shouldShowToolbar = !isMobile && !toggled && imageContext && controls;

  const [innerContainerWidth, setInnerContainerWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);

  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clientWidth = container?.current?.clientWidth ?? 0;
    setInnerContainerWidth(clientWidth);
  }, [toggled]);

  useEffect(() => {
    const url = splited ? splitUrl : src;
    setActualUrl(url ?? '');
  }, [splited, splitUrl, src]);

  useEffect(() => {
    if (imageContext) {
      const calculatedImageHeight = increaseImageHeight({
        width: imageContext?.width,
        height: imageContext?.height,
        newWidth: containerWidth || innerContainerWidth,
      });

      setImageHeight(Number(calculatedImageHeight));
    }
  }, [innerContainerWidth, containerWidth, imageContext, toggled]);

  const delayedChangedViewOptions = useDebouncedFunction(
    handleSetMedicalImageViewOptions,
    900,
  );

  useEffect(() => {
    if (currentWwwc) {
      const updatedViewOptions = {
        wwwc: {
          ww: currentWwwc.ww,
          wc: currentWwwc.wc,
        },
        sharpness,
        invert: inverted,
      };

      if (!isEqual(updatedViewOptions, viewOptions)) {
        delayedChangedViewOptions({
          WindowWidth: currentWwwc?.ww,
          WindowLevel: currentWwwc?.wc,
          Sharpness: sharpness,
        });
      }
    }
  }, [currentWwwc, sharpness]);

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.image}>
        {shouldShowToolbar && (
          <ImageToolbar
            controls={controls}
            activeControl={activeControl}
            setActiveControl={setActiveControl}
            inverted={inverted}
            setSplited={setSplited}
            setInverted={setInverted}
            className={cn(
              styles.toolbar,
              toolbarPosition === 'inside' && styles.toolbarIn,
            )}
            tooltipPosition="right"
          />
        )}
        <div ref={container}>
          <UltimateMedicalImage
            src={{ url: actualUrl, kind }}
            onLoaded={onLoaded}
            mode={mode}
            currentAnnotation={currentAnnotation as AnnotationOrEraser}
            annotations={annotations}
            onAnnotationsChanged={setAnnotations}
            onWWWCChanged={setWWWC}
            ww={currentWwwc?.ww}
            wc={currentWwwc?.wc}
            sharpness={sharpness}
            onSharpnessChanged={setSharpness}
            inverted={inverted}
            objectFit={objectFit}
            className={cn(
              styles.ultimateDicom,
              toolbarPosition === 'left' && styles.ultimateDicomPositionLeft,
            )}
            style={{
              ...style,
              height: fixedHeight || imageHeight,
              transition: 'height 300ms',
            }}
          />
          {imageContext && ruler && (
            <AbsoluteBlock style={{ top: 0 }}>
              <RCRuler
                imageSize={{
                  width: innerContainerWidth,
                  height: imageContext?.height,
                }}
                pixelSpacing={{ x: 6, y: 6 }}
              />
            </AbsoluteBlock>
          )}

          <AbsoluteBlock
            style={{ left: 0, top: 0, zIndex: localizations?.length ? 3 : 0 }}
          >
            <RCLocalizations
              detections={localizations ?? []}
              imageSize={{
                width: imageContext?.width,
                height: imageContext?.height,
              }}
              isNonDentalFindings
            />
          </AbsoluteBlock>
        </div>
        <ExpandedPanoModal
          src={actualUrl}
          controls={controls}
          isOpen={!!expandedImage}
          onClose={handleCloseModal}
          setSplited={setSplited}
          activeControl={activeControl}
          setActiveControl={setActiveControl}
          mode={mode}
          annotations={annotations}
          setAnnotations={setAnnotations}
          sharpness={sharpness}
          setSharpness={setSharpness}
          currentAnnotation={currentAnnotation as AnnotationOrEraser}
          wwwc={currentWwwc}
          viewAnnotations={viewAnnotations}
          setWWWC={setWWWC}
          inverted={inverted}
          setInverted={setInverted}
          handleSetMedicalImageViewOptions={handleSetMedicalImageViewOptions}
        />
      </div>
    </div>
  );
};
