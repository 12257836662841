import {
  Invitation,
  Invitation_InvitationStatus,
} from '@/shared/api/protocol_gen/model/dto_access';

export const getSortedInvitationsListByStatus = (
  a: Invitation,
  b: Invitation,
) => {
  if (a.Status !== Invitation_InvitationStatus.StatusAccepted) {
    return 1;
  }
  if (b.Status !== Invitation_InvitationStatus.StatusAccepted) {
    return -1;
  }
  return 0;
};
