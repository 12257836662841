import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { PATHS } from '@/shared/config';

export const getReportPath = (reportType: ReportType) => {
  if (
    reportType === ReportType.ReportType_CBCT_GP ||
    reportType === ReportType.ReportType_Pano_GP ||
    reportType === ReportType.ReportType_IOXRay_GP ||
    reportType === ReportType.ReportType_DentalPhoto_GP
  ) {
    return PATHS.gpReport;
  }
  if (reportType === ReportType.ReportType_CBCT_Endo) {
    return PATHS.endodonticReport;
  }
  if (reportType === ReportType.ReportType_CBCT_Implant) {
    return PATHS.implantReport;
  }
  if (reportType === ReportType.ReportType_CBCT_Molar) {
    return PATHS.molarReport;
  }
  if (
    reportType === ReportType.ReportType_IOS_Segmentation ||
    reportType === ReportType.ReportType_CBCT_Segmentation
  ) {
    return PATHS.segmentationReport;
  }

  return PATHS.error;
};
