import { generatePath, useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  LocalStorageKey,
  PATHS,
  ToastErrors,
  toastErrors,
  UserBadRequestError,
} from '@/shared/config';
import { useAppDispatch } from '@/shared/hooks';
import { toastCaller } from '@/shared/ui';
import { UTMParameters } from '@/shared/api/protocol_gen/model/dto_marketing';
import { ApiError } from '@/shared/api/api';
import { AuthState_AuthStatus } from '@/shared/api/protocol_gen/api/auth/dto_auth';

import { authModel } from '@/entities/auth';
import { userModel } from '@/entities/user';
import { ModalID, modalModel } from '@/entities/modal';
import {
  BillingZones,
  getBillingZoneByCountry,
  getLinksAgreementsByBillingZone,
} from '@/entities/billing';
import { PATH_TO_PATIENTS_SHARED_WITH_ME_LIST } from '@/entities/patient';

import {
  SignUpFormDataType,
  SignUpHandleType,
  SignUpViaOrganizationInviteHandleType,
  SignUpViaSharedPatientHandleType,
} from '../config/types';
import {
  signUpFormSchema,
  signUpViaOrganizationInviteFormSchema,
  signUpViaSharedPatientFormSchema,
} from '../config/formConfig';

export const useSignUp = (invitationToken: string) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

  const [selectCountry, setSelectCountry] = useState('');

  const userLinksAgreements = getLinksAgreementsByBillingZone({
    country: selectCountry,
    billingZone: getBillingZoneByCountry(selectCountry) as BillingZones,
  });

  // set UTM
  useEffect(() => {
    if (searchParams) {
      const UTMSource = searchParams.get('utm_source') ?? undefined;
      const UTMMedium = searchParams.get('utm_medium') ?? undefined;
      const UTMCampaign = searchParams.get('utm_campaign') ?? undefined;
      const UTMTerm = searchParams.get('utm_term') ?? undefined;
      const UTMContent = searchParams.get('utm_content') ?? undefined;

      if (UTMSource && UTMMedium && UTMCampaign && UTMTerm && UTMContent) {
        const utmParameters: UTMParameters = {
          UTMSource,
          UTMMedium,
          UTMCampaign,
          UTMTerm,
          UTMContent,
        };

        localStorage.setItem(
          LocalStorageKey.UTMParameters,
          JSON.stringify(utmParameters),
        );
      }
    }
  }, [searchParams]);

  const [isInviteFromOrganization, setIsInviteFromOrganization] =
    useState(false);

  const [invitingOrganizationName, setInvitingOrganizationName] = useState('');

  const [isInviteFromDoctor, setIsInviteFromDoctor] = useState(false);

  const [sharedDoctorName, setSharedDoctorName] = useState('');

  const [isInviteFromSalesman, setIsInviteFromSalesman] = useState(false);

  const schema = useMemo(() => {
    if (isInviteFromOrganization) {
      return signUpViaOrganizationInviteFormSchema;
    }

    if (isInviteFromDoctor) {
      return signUpViaSharedPatientFormSchema;
    }

    return signUpFormSchema;
  }, [isInviteFromOrganization, isInviteFromDoctor]);

  const hookFormData = useForm<SignUpFormDataType>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const { setError } = hookFormData;

  const errorHandler = (error: ApiError) => {
    const parsedMessage = JSON.parse(error?.message);
    const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

    if (parsedMessage?.code === UserBadRequestError.EMAIL_TAKEN) {
      const errorMessage = formatMessage(
        {
          id: 'signUp.errors.emailTakenError',
          defaultMessage:
            'There is already an account with this email address. <a>Sign in</a> instead.',
        },
        {
          a: (chunks: ReactNode) => (
            <a style={{ color: 'var(--purpleflat)' }} href={PATHS.signIn}>
              {chunks}
            </a>
          ),
        },
      ) as string;

      setError('email', {
        message: errorMessage,
        type: UserBadRequestError.EMAIL_TAKEN,
      });
    } else {
      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };

  const checkInviteToken = async (inviteToken: string) => {
    try {
      const { InvitationInfo, SignUpViaSalesmanInfo } = await dispatch(
        authModel.thunks.checkInviteToken({ Token: inviteToken }),
      ).unwrap();

      if (InvitationInfo.Target?.Organization) {
        setIsInviteFromOrganization(true);

        setInvitingOrganizationName(
          InvitationInfo.TargetDetails.OrganizationName,
        );

        setSelectCountry(InvitationInfo.TargetOrganizationCountry);
      }

      if (InvitationInfo.Target?.Patient) {
        setIsInviteFromDoctor(true);

        setSharedDoctorName(
          `${InvitationInfo.TargetDetails.SenderFirstName} ${InvitationInfo.TargetDetails.SenderLastName}`,
        );
      }

      if (SignUpViaSalesmanInfo) {
        setIsInviteFromSalesman(true);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  // check invite token
  useEffect(() => {
    if (invitationToken) {
      checkInviteToken(invitationToken);
    }
  }, [invitationToken]);

  const saveSignUpFormDataHandle = (name: string, value: string) => {
    const formData = localStorage.getItem(LocalStorageKey.signUpFormData);

    const updatedFormData = formData
      ? { ...JSON.parse(formData), [name]: value }
      : { [name]: value };

    localStorage.setItem(
      LocalStorageKey.signUpFormData,
      JSON.stringify(updatedFormData),
    );
  };

  const changeLanguageHandle = useCallback(
    (language: string) => {
      dispatch(userModel.thunks.setSettings({ locale: language }));
    },
    [dispatch],
  );

  const signUpHandle = async ({
    email,
    country,
    language,
    firstName,
    lastName,
    phoneNumber,
  }: SignUpHandleType) => {
    setIsLoading(true);

    try {
      const signUpResponse = await dispatch(
        authModel.thunks.signUp({
          Email: email,
          Country: country,
          Language: language,
          FirstName: firstName,
          LastName: lastName,
          PhoneNumber: phoneNumber ?? '',
        }),
      ).unwrap();

      if (signUpResponse.Status === AuthState_AuthStatus.IN_PROGRESS) {
        navigate(PATHS.confirmEmail, {
          state: {
            email,
          },
        });
      }

      localStorage.removeItem(LocalStorageKey.signUpFormData);
    } catch (error) {
      errorHandler(error);
    }

    setIsLoading(false);
  };

  const signUpViaOrganizationInviteHandle = async ({
    firstName,
    lastName,
    password,
    language,
  }: SignUpViaOrganizationInviteHandleType) => {
    setIsLoading(true);

    try {
      const signUpViaOrganizationInviteResponse = await dispatch(
        authModel.thunks.signUpViaOrganizationInvite({
          InviteToken: invitationToken,
          FirstName: firstName,
          LastName: lastName,
          Password: password,
          Language: language,
        }),
      ).unwrap();

      if (
        signUpViaOrganizationInviteResponse.Status ===
        AuthState_AuthStatus.SUCCESSFUL
      ) {
        dispatch(
          modalModel.actions.openModal({
            modalID: ModalID.CongratsModal,
            data: {
              redirectTo: PATHS.patients,
              message: isInviteFromOrganization
                ? formatMessage(
                    {
                      id: 'signUp.inviteFromOrganization.attachedToOrganization',
                      defaultMessage: `Now you are attached to organization "{invitingOrganizationName}"`,
                    },
                    { invitingOrganizationName },
                  )
                : '',
            },
          }),
        );
      }

      localStorage.removeItem(LocalStorageKey.signUpFormData);
    } catch (error) {
      errorHandler(error);
    }

    setIsLoading(false);
  };

  const signUpViaSharedPatientHandle = async ({
    firstName,
    lastName,
    password,
    language,
    country,
  }: SignUpViaSharedPatientHandleType) => {
    setIsLoading(true);

    try {
      const signUpViaSharedPatientResponse = await dispatch(
        authModel.thunks.signUpViaSharedPatient({
          InviteToken: invitationToken,
          FirstName: firstName,
          LastName: lastName,
          Password: password,
          Language: language,
          Country: country,
        }),
      ).unwrap();

      if (
        signUpViaSharedPatientResponse.Status ===
        AuthState_AuthStatus.SUCCESSFUL
      ) {
        dispatch(
          modalModel.actions.openModal({
            modalID: ModalID.CongratsModal,
            data: {
              redirectTo: generatePath(PATH_TO_PATIENTS_SHARED_WITH_ME_LIST),
            },
          }),
        );
      }

      localStorage.removeItem(LocalStorageKey.signUpFormData);
    } catch (error) {
      errorHandler(error);
    }

    setIsLoading(false);
  };

  const navigateToFinishRegistration = () =>
    navigate(PATHS.finishRegistration, {
      state: { isInviteFromSalesman, invitationToken },
    });

  return {
    isInviteFromOrganization,
    isInviteFromDoctor,
    isInviteFromSalesman,
    isLoading,
    invitingOrganizationName,
    sharedDoctorName,
    userLinksAgreements,
    signUpHandle,
    signUpViaOrganizationInviteHandle,
    signUpViaSharedPatientHandle,
    saveSignUpFormDataHandle,
    setSelectCountry,
    changeLanguageHandle,
    navigateToFinishRegistration,
    hookFormData,
  };
};
