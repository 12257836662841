import { FC } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import {
  ChildConditionInterface,
  ConditionDecisionChange,
} from '../../config/types';
import { ChildConditionGroups } from '../../config/const';
import { childConditionGroupText } from '../../config/i18n';
import { ConditionToggle } from '../ConditionToggle/ConditionToggle';
import { ConditionToggleList } from '../ConditionToggleList/ConditionToggleList';
import { groupChildConditionInterfaces } from '../../lib';

import styles from './ConditionChildGroup.module.scss';

type ConditionChildGroupType = {
  className?: string;
  data: ChildConditionInterface[];
  // data: ChildConditionInterfaceGroup;
  onChange: ConditionDecisionChange;
  disabled?: boolean;
};

export const ConditionChildGroup: FC<ConditionChildGroupType> = (props) => {
  const { className, data, onChange, disabled } = props;

  const { formatMessage } = useIntl();

  const groups = groupChildConditionInterfaces(data);

  return (
    <div className={cn(styles.container, className)}>
      {(Object.keys(groups) as ChildConditionGroups[]).map((childGroupCode: ChildConditionGroups) => (
        <div key={childGroupCode} className={styles.group}>
          <h4 className="p2">
            {formatMessage(childConditionGroupText[childGroupCode])}:
          </h4>

          <ConditionToggleList>
            {groups[childGroupCode].map((childConditionInterface) => (
              <ConditionToggle
                key={childConditionInterface.code}
                data={childConditionInterface}
                onChange={onChange}
                disabled={disabled}
                className={styles.marginBottomInGroupsConditions}
              />
            ))}
          </ConditionToggleList>
        </div>
      ))}
    </div>
  );
};
