import cn from 'classnames';
import { useIntl } from 'react-intl';
import { createColumnHelper } from '@tanstack/react-table';

import { Patient } from '@/shared/api/protocol_gen/model/dto_patient';
import studyPlaceholderImage from '@/shared/assets/images/cats/patientListStudyPlaceholder.png';

import { getImageSrc } from '@/entities/assets';
import { StudiesCounters } from '@/entities/patient';

import { PatientDoctrosCell } from '../ui/PatientDoctrosCell/PatientDoctrosCell';
import styles from '../ui/PatientList/PatientList.module.scss';

export const useGetPatientsColumnsScheme = () => {
  const { formatMessage, formatDate } = useIntl();

  const columnHelper = createColumnHelper<Patient>();

  const columnsScheme = [
    columnHelper.accessor(
      (row) => ({
        patientFullName: `${row.PersonalData?.FirstName} ${row.PersonalData?.LastName}`,
        displayAssetID: row.DisplayAssetID,
      }),
      {
        id: 'PatientName',
        enableSorting: true,
        minSize: 180,
        header: formatMessage({
          id: 'patientList.PatientName',
          defaultMessage: 'Patient name',
        }),
        cell: (cell) => {
          const { patientFullName, displayAssetID } = cell.getValue();
          const patientStudyImage = getImageSrc(displayAssetID, 'thumbnail');

          return (
            <div className={styles.infoInCell}>
              {displayAssetID ? (
                <object
                  data={patientStudyImage}
                  type="image/png"
                  className={cn(styles.patientStudyImage)}
                >
                  <img
                    src={studyPlaceholderImage}
                    alt={`${patientFullName}-avatar`}
                    className={cn(styles.patientStudyImage)}
                  />
                </object>
              ) : (
                <img
                  src={studyPlaceholderImage}
                  alt={`${patientFullName}-avatar`}
                  className={styles.studyPlaceholderImage}
                />
              )}

              <div
                className={cn(
                  styles.fullName,
                  !displayAssetID && styles.paddingForFullName,
                )}
              >
                {patientFullName}
              </div>
            </div>
          );
        },
      },
    ),
    columnHelper.accessor((row) => row.ExternalID, {
      id: 'ExternalID',
      enableSorting: true,
      minSize: 50,
      header: formatMessage({
        id: 'patientList.PatientID',
        defaultMessage: 'Patient ID',
      }),
      cell: (cell) => {
        const patientExternalID = cell.getValue();

        return (
          <div className={styles.infoInCell}>
            <span className={styles.forWhiteSpace}>{patientExternalID}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.PersonalData?.DateOfBirth, {
      id: 'DateOfBirth',
      enableSorting: true,
      minSize: 50,
      header: formatMessage({
        id: 'patientList.dateOfBirth',
        defaultMessage: 'Date of birth',
      }),
      cell: (cell) => {
        const dateOfBirth = cell.getValue();
        const patientBirthDate =
          dateOfBirth &&
          formatDate(new Date(dateOfBirth), {
            dateStyle: 'long',
          });

        return (
          <div className={styles.infoInCell}>
            <span className={styles.forWhiteSpace}>{patientBirthDate}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.ID, {
      id: 'Doctors',
      enableSorting: false,
      minSize: 50,
      header: formatMessage({
        id: 'patientList.TreatingDoctors',
        defaultMessage: 'Treating doctors',
      }),
      cell: (cell) => {
        const patientID = cell.getValue();

        return <PatientDoctrosCell patientID={patientID} />;
      },
    }),
    columnHelper.accessor((row) => row.ID, {
      id: 'studies',
      enableSorting: false,
      minSize: 50,
      header: formatMessage({
        id: 'patientList.Studies',
        defaultMessage: 'Studies',
      }),
      cell: (cell) => {
        const patientID = cell.getValue();

        return (
          <div>
            <StudiesCounters patientID={patientID} />
          </div>
        );
      },
    }),
  ];

  return columnsScheme;
};
