// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RoiColumn-module__container--i3r8DRl6{color:var(--purpletext2)}.RoiColumn-module__tooltipContent--grw6lz6W{display:flex;flex-direction:column;gap:8px}.RoiColumn-module__tooltipTitle--iSSKUI5m{color:var(--white)}", "",{"version":3,"sources":["webpack://./src/features/reportRow/ui/RoiColumn/RoiColumn.module.scss"],"names":[],"mappings":"AAAA,uCACE,wBAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,0CACE,kBAAA","sourcesContent":[".container {\n  color: var(--purpletext2);\n}\n\n.tooltipContent {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.tooltipTitle {\n  color: var(--white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RoiColumn-module__container--i3r8DRl6",
	"tooltipContent": "RoiColumn-module__tooltipContent--grw6lz6W",
	"tooltipTitle": "RoiColumn-module__tooltipTitle--iSSKUI5m"
};
export default ___CSS_LOADER_EXPORT___;
