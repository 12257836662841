import { FC } from 'react';
import cn from 'classnames';
import { generatePath, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useAppSelector, useMedia } from '@/shared/hooks';
import patientImg from '@/shared/assets/images/patient.png';
import { Avatar } from '@/shared/ui';
import { PATHS } from '@/shared/config';
import studyPlaceholderImage from '@/shared/assets/images/cats/patientListStudyPlaceholder.png';
import { getUserFullName, getUserInitials } from '@/shared/lib';

import { patientModel, StudiesCounters } from '@/entities/patient';
import { getAvatarSrcByUserID, getImageSrc } from '@/entities/assets';
import { organizationModel } from '@/entities/organization';

import styles from './PatientListRow.module.scss';

type PatientListRowProps = {
  patientID: string;
  patientName: string;
  patientExternalID: string;
  patientBirthDate: string;
  className?: string;
  displayAssetID: string;
  testID?: string;
};

export const PatientListRow: FC<PatientListRowProps> = (props) => {
  const {
    patientID,
    patientName,
    patientBirthDate,
    displayAssetID,
    className,
    patientExternalID,
    testID,
  } = props;

  const { isPhone, isMobile, isSmallDesktop } = useMedia();

  const { formatMessage } = useIntl();

  const patientDoctorsIDs = useAppSelector(
    patientModel.selectors.selectPatientDoctorsIDs(patientID),
  );

  const patientDoctorsAsUserRole = useAppSelector(
    organizationModel.selectors.selectDoctorsByIDs(patientDoctorsIDs as string[]),
  );

  const patientStudyImage = getImageSrc(displayAssetID, 'thumbnail');

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <Link to={generatePath(PATHS.patientProfile, { patientID })}>
        <div className={styles.patient}>
          <div
            className={cn(
              styles.imageWrapper,
              !displayAssetID && styles.placeholder,
            )}
          >
            <img
              src={displayAssetID ? patientStudyImage : patientImg}
              alt={formatMessage({
                id: 'imgAltText.patientImage',
                defaultMessage: 'Patient image',
              })}
              className={styles.img}
            />
            {!displayAssetID && (
              <img
                src={studyPlaceholderImage}
                alt={formatMessage({
                  id: 'imgAltText.studyPlaceholder',
                  defaultMessage: 'Study placeholder',
                })}
                className={styles.studyPlaceholderImage}
              />
            )}
          </div>

          <div className={styles.patientInfo}>
            <p className={cn(isPhone ? 'p3' : 'p2', styles.name)}>
              {patientName}
            </p>

            <div className={styles.patientData}>
              <p className={cn(isPhone ? 'p3' : 'p2', styles.id)}>
                {formatMessage({
                  id: 'patientInfo.externalID',
                  defaultMessage: 'ID',
                })}{' '}
                {patientExternalID}
              </p>

              <p className={cn(isPhone ? 'p3' : 'p2', styles.date)}>
                {patientBirthDate}
              </p>
            </div>
          </div>
        </div>
      </Link>

      <div className={styles.doctors}>
        <Avatar.Group>
          {patientDoctorsAsUserRole?.map((doctorUser) => {
            const doctorFullName = getUserFullName(doctorUser);

            const doctorInitials = getUserInitials(doctorUser);

            const doctorUserAvatarSrc = getAvatarSrcByUserID(
              doctorUser.UserID,
              'thumbnail',
            );

            return (
              <Avatar
                key={doctorUser.UserID}
                src={doctorUserAvatarSrc}
                alt={doctorFullName}
                initials={doctorInitials}
              />
            );
          })}
        </Avatar.Group>
      </div>

      {!isMobile && !isSmallDesktop && (
        <StudiesCounters patientID={patientID} />
      )}
    </div>
  );
};

// export const PatientListRow = memo(PatientListRowImplementation);
