// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Breadcrumbs-module__breadcrumbsList--tIYvYY-S{display:flex;align-items:center;gap:20px;margin-right:auto;list-style-position:inside;list-style-type:none;padding:0}\n\n[dir=\"rtl\"] .Breadcrumbs-module__breadcrumbsList--tIYvYY-S{margin-right:0;margin-left:auto}.Breadcrumbs-module__listItem--21FDoByX{position:relative}\n\n.Breadcrumbs-module__listItem--21FDoByX:not(:first-child)::before{content:\".\";position:absolute;left:-12px;bottom:25%;color:var(--black)}\n\n[dir=\"rtl\"] .Breadcrumbs-module__listItem--21FDoByX:not(:first-child)::before{left:auto;right:-12px}.Breadcrumbs-module__link--qTDVzQ46{color:var(--purpletext1)}", "",{"version":3,"sources":["webpack://./src/shared/ui/Breadcrumbs/Breadcrumbs.module.scss"],"names":[],"mappings":"AAAA,+CACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CACA,0BAAA,CACA,oBAAA,CACA,SAAA;;AAPF,2DAIE,cAAA,CAAA,gBAGA,CAGF,wCACE,iBAAA;;AAEA,kEACE,WAAA,CACA,iBAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA;;AALF,8EAGE,SAAA,CAAA,WAEA,CAIJ,oCACE,wBAAA","sourcesContent":[".breadcrumbsList {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  margin-right: auto;\n  list-style-position: inside;\n  list-style-type: none;\n  padding: 0;\n}\n\n.listItem {\n  position: relative;\n\n  &:not(:first-child)::before {\n    content: '.';\n    position: absolute;\n    left: -12px;\n    bottom: 25%;\n    color: var(--black);\n  }\n}\n\n.link {\n  color: var(--purpletext1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbsList": "Breadcrumbs-module__breadcrumbsList--tIYvYY-S",
	"listItem": "Breadcrumbs-module__listItem--21FDoByX",
	"link": "Breadcrumbs-module__link--qTDVzQ46"
};
export default ___CSS_LOADER_EXPORT___;
