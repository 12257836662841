import api from '@/shared/api/api';
import {
  PatientServiceClientImpl,
  CreatePatientReq,
  CreatePatientResp,
  DeletePatientReq,
  DeletePatientResp,
  SetPatientExternalIDReq,
  SetPatientExternalIDResp,
  SetPatientGenderReq,
  SetPatientGenderResp,
  SetPatientPersonalDataReq,
  SetPatientPersonalDataResp,
  SetPatientStatusReq,
  SetPatientStatusResp,
} from '@/shared/api/protocol_gen/api/core/svc_patient';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';

type PatientRequests = keyof PatientServiceClientImpl;

const generatePatientThunk = createThunkGenerator<PatientRequests>(
  SliceName.patient,
);

export const createPatient = generatePatientThunk<
  CreatePatientReq,
  CreatePatientResp
>('CreatePatient', api.patient.CreatePatient);

export const deletePatient = generatePatientThunk<
  DeletePatientReq,
  DeletePatientResp
>('DeletePatient', api.patient.DeletePatient);

export const setPatientPersonalData = generatePatientThunk<
  SetPatientPersonalDataReq,
  SetPatientPersonalDataResp
>('SetPatientPersonalData', api.patient.SetPatientPersonalData);

export const setPatientExternalID = generatePatientThunk<
  SetPatientExternalIDReq,
  SetPatientExternalIDResp
>('SetPatientExternalID', api.patient.SetPatientExternalID);

export const setPatientGender = generatePatientThunk<
  SetPatientGenderReq,
  SetPatientGenderResp
>('SetPatientGender', api.patient.SetPatientGender);

export const setPatientStatus = generatePatientThunk<
  SetPatientStatusReq,
  SetPatientStatusResp
>('SetPatientStatus', api.patient.SetPatientStatus);
