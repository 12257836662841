import { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Button, toastCaller } from '@/shared/ui';

import { ConfirmModal, ModalID, modalModel } from '@/entities/modal';
import { organizationModel } from '@/entities/organization';

type ChangeOrganizationModalProps = {
  invitationOrganizationID: string;
  invitationOrganizationName: string;
};

export const ChangeOrganizationModal: FC<ChangeOrganizationModalProps> = (
  props,
) => {
  const { invitationOrganizationID, invitationOrganizationName } = props;

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const organizationID = useAppSelector(
    organizationModel.selectors.selectCurrentOrganizationID,
  );

  const changeOrganizationModalHandle = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.Confirm,
        data: {
          title: formatMessage({
            id: 'changeOrganizationModal.title',
            defaultMessage: 'New account',
          }),
          description: formatMessage({
            id: 'changeOrganizationModal.description',
            defaultMessage:
              'You are going to switch to the new account. Are you sure?',
          }),
          okText: formatMessage({
            id: 'changeOrganizationModal.okText',
            defaultMessage: 'Yes, I do',
          }),

          cancelText: formatMessage({
            id: 'changeOrganizationModal.cancelText',
            defaultMessage: 'No, stay on this account',
          }),
          onConfirm: () => {
            dispatch(modalModel.actions.closeModal(ModalID.Confirm));

            dispatch(
              organizationModel.thunks.setCurrentOrganizationByID(
                invitationOrganizationID,
              ),
            );
          },
        },
      }),
    );
  }, [dispatch, formatMessage, invitationOrganizationID]);

  // Check if, when logging in through an invitation via an organization, the
  // current organization ID matches the organization ID that sent the invite
  useEffect(() => {
    if (
      invitationOrganizationID !== undefined &&
      organizationID !== invitationOrganizationID
    ) {
      toastCaller({
        type: 'success',
        heading: (
          <FormattedMessage
            id="patientList.inviteNotification.title"
            defaultMessage="New access account"
          />
        ),
        message: (
          <FormattedMessage
            id="patientList.inviteNotification.message"
            defaultMessage="You have got access to {invitationOrganizationName}. Do you want to switch to the new account now?"
            values={{
              invitationOrganizationName,
            }}
          />
        ),
        autoClose: false,
        position: 'bottom-left',
        children: (
          <Button
            variant="tertiary"
            onClick={changeOrganizationModalHandle}
            size="medium"
          >
            <FormattedMessage
              id="patientList.inviteNotification.agreeButton"
              defaultMessage="Yes, I do"
            />
          </Button>
        ),
      });
    }
  }, [
    organizationID,
    invitationOrganizationID,
    changeOrganizationModalHandle,
    invitationOrganizationName,
  ]);

  return <ConfirmModal />;
};
