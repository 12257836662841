// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SharePatientModalForm-module__modal--fGSI0WTY{padding:0}.SharePatientModalForm-module__modalBody--8vM499K7{display:grid;gap:24px}.SharePatientModalForm-module__alertInfo--MRc2LPwo{display:flex;gap:12px}.SharePatientModalForm-module__alertIcon--U62ooRTv{color:var(--orange);flex-shrink:0}.SharePatientModalForm-module__sharePatientForm--VQ623WnK{display:grid;gap:24px}", "",{"version":3,"sources":["webpack://./src/features/sharePatient/ui/SharePatientModalForm/SharePatientModalForm.module.scss"],"names":[],"mappings":"AAAA,+CACE,SAAA,CAGF,mDACE,YAAA,CACA,QAAA,CAGF,mDACE,YAAA,CACA,QAAA,CAGF,mDACE,mBAAA,CACA,aAAA,CAGF,0DACE,YAAA,CACA,QAAA","sourcesContent":[".modal {\n  padding: 0;\n}\n\n.modalBody {\n  display: grid;\n  gap: 24px;\n}\n\n.alertInfo {\n  display: flex;\n  gap: 12px;\n}\n\n.alertIcon {\n  color: var(--orange);\n  flex-shrink: 0;\n}\n\n.sharePatientForm {\n  display: grid;\n  gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "SharePatientModalForm-module__modal--fGSI0WTY",
	"modalBody": "SharePatientModalForm-module__modalBody--8vM499K7",
	"alertInfo": "SharePatientModalForm-module__alertInfo--MRc2LPwo",
	"alertIcon": "SharePatientModalForm-module__alertIcon--U62ooRTv",
	"sharePatientForm": "SharePatientModalForm-module__sharePatientForm--VQ623WnK"
};
export default ___CSS_LOADER_EXPORT___;
