import { useMemo } from 'react';
import { groupBy, pathOr } from 'ramda';

import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { ToothStatus } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';

import {
  getDisplayToothNumber,
  isMissingThirdMolarTooth,
  LOWER_JAW_TEETH_ISO_NUMBERS,
} from '@/entities/tooth';
import {
  conditionModel,
  getToothStatus,
  shouldConditionItemBeShown,
} from '@/entities/condition';
import { organizationModel } from '@/entities/organization';

export type ToothInterface = {
  id: string;
  ISONumber: number;
  toothNumberView: number;
  status: ToothStatus;
  isLowerJaw?: boolean;
  isMissingThirdMolar: boolean;
  isActive?: boolean;
  gridArea: string;
};

type UseToothTransform = {
  teeth: Tooth[];
  showLowProbability?: boolean;
  ROIISONumbers?: number[];
};

export const useToothTransform = ({
  teeth,
  showLowProbability = false,
  ROIISONumbers,
}: UseToothTransform): ToothInterface[] => {
  const teethIDs = useMemo(() => teeth.map((tooth) => tooth.ID), [teeth]);
  const teethISO = useMemo(
    () => teeth.map((tooth) => tooth.Numeration?.ISO),
    [teeth],
  );

  const organization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );

  const teethConditions = useAppSelector(
    conditionModel.selectors.selectByTeethIDs(teethIDs),
  );

  const filteredConditions = useMemo(
    () =>
      teethConditions.filter((condition) =>
        shouldConditionItemBeShown(showLowProbability)(condition),
      ),
    [showLowProbability, teethConditions],
  );

  const conditionsGroupedByToothID = useMemo(
    () => groupBy(pathOr('', ['Tooth', 'ToothID']), filteredConditions),
    [filteredConditions],
  ) as Record<string, Condition[]>;

  const transformedTeeth = useMemo(
    () =>
      teeth.map((tooth): ToothInterface => {
        let gridArea: string;
        const { ID, Numeration } = tooth;

        const ISONumber = Numeration?.ISO ?? 0;

        // TODO: Why not just make simple map when each ICO number equals differnt dental notaion number?
        const toothNumberView = getDisplayToothNumber(
          ISONumber,
          organization.Settings?.DentalNotationFormat,
        );

        const currentToothConditions = conditionsGroupedByToothID[ID];
        const status = currentToothConditions
          ? getToothStatus(currentToothConditions, showLowProbability)
          : ToothStatus.healthy;

        const isLowerJaw = LOWER_JAW_TEETH_ISO_NUMBERS.includes(ISONumber);

        const isMissingThirdMolar = isMissingThirdMolarTooth(status, ISONumber);

        const isActive = ROIISONumbers
          ? ROIISONumbers?.includes(ISONumber)
          : true;

        if (ISONumber > 50 && teethISO.includes(ISONumber - 40)) {
          gridArea = `t${ISONumber - 40}`;
        } else if (ISONumber < 50 && teethISO.includes(ISONumber + 40)) {
          gridArea = `t${ISONumber + 40}`;
        } else if (ISONumber > 50 && !teethISO.includes(ISONumber - 40)) {
          gridArea = `t${ISONumber - 40}`;
        } else {
          gridArea = `t${ISONumber}`;
        }

        return {
          id: tooth.ID,
          ISONumber,
          toothNumberView,
          status,
          isLowerJaw,
          isActive,
          isMissingThirdMolar,
          gridArea, // TODO: Add function that will return position of tooth in chart depends on rules
        };
      }),
    [
      teeth,
      organization.Settings?.DentalNotationFormat,
      conditionsGroupedByToothID,
      showLowProbability,
      ROIISONumbers,
      teethISO,
    ],
  );

  // TODO: Think about default data to use it for create report
  return transformedTeeth;
};
