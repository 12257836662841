import { Dispatch, FC, SetStateAction, useRef } from 'react';
import cn from 'classnames';

import { ToothView } from '@/shared/ui';
import { scrollToElementByID } from '@/shared/lib';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { ToothStatus } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';

import { organizationModel } from '@/entities/organization';

import {
  getDisplayToothNumber,
  isLowerJaw,
  isMissingThirdMolarTooth,
  isRightQuartile,
} from '../../lib';
import { useToothProps } from '../../hooks';
import { JAW, Quartiles } from '../../config';

import styles from './JawQuartile.module.scss';

export type JawQuartileProps = {
  quartile: Quartiles;
  teeth: Tooth[];
  reportID: string;
  ROIList?: number[];
  setROIList?: Dispatch<SetStateAction<number[]>>;
  isROIOpen?: boolean;
  isSimplify?: boolean;
  onToothClick?: (id: string) => void;
  className?: string;
  toothOfInterestNumber?: number;
  blackAndWhiteMode?: boolean;
};

export const JawQuartile: FC<JawQuartileProps> = (props) => {
  // TODO: Should be refactored later to decrease complexity
  const {
    className,
    quartile,
    reportID,
    ROIList,
    isROIOpen,
    setROIList,
    teeth,
    isSimplify,
    onToothClick,
    toothOfInterestNumber,
    blackAndWhiteMode,
  } = props;

  const jaw = useRef(JAW[quartile]);

  const isRightJaw = isRightQuartile(quartile);
  const isJawLower = isLowerJaw(quartile);
  // TODO: This is impossible if we want to use teeth compilation from many reports
  // We need to make TeethChard that will be take just teeth array as an argument without any information about report

  const { teethWithStatus } = useToothProps({ reportID, teeth });

  const organization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );

  Object.keys(teethWithStatus).forEach((id: string) => {
    const { Numeration } = teethWithStatus[id];

    const ISONumber = Numeration?.ISO;

    jaw.current = jaw.current.filter((jawItem) => jawItem !== ISONumber);
  });

  const handleToothClick = (id: string, isActive: boolean = true) => {
    if (isROIOpen && ROIList && setROIList) {
      if (ROIList.includes(teethWithStatus[id].Numeration?.ISO ?? 0)) {
        setROIList((prev) =>
          prev.filter(
            (ISONumber) => ISONumber !== teethWithStatus[id].Numeration?.ISO,
          ),
        );
      } else {
        setROIList((prev) => [
          ...prev,
          teethWithStatus[id].Numeration?.ISO ?? 0,
        ]);
      }
    } else if (typeof onToothClick === 'function' && isActive) {
      onToothClick(id);
    } else if (isActive) {
      scrollToElementByID(id);
    }
  };

  return (
    <div
      className={cn(
        styles.container,
        isJawLower && styles.isLowerJaw,
        className,
      )}
    >
      <div
        className={cn(styles.toothContainer, isRightJaw && styles.isRightJaw)}
      >
        {jaw.current.map((jawItem) => (
          <div
            key={jawItem}
            className={cn(
              styles[`t${jawItem.toString().slice(-1)}`],
              isSimplify ? styles.simpleNotDetected : styles.notDetected,
            )}
          />
        ))}

        {Object.keys(teethWithStatus).map((id) => {
          // TODO: move ToothView out of JawQuartile
          const { ID, Numeration, toothStatus } = teethWithStatus[id];

          const ISONumber = Numeration?.ISO ?? 0;

          const isMissingThirdMolar = isMissingThirdMolarTooth(
            toothStatus,
            ISONumber,
          );

          const toothNumberView = getDisplayToothNumber(
            ISONumber,
            organization.Settings?.DentalNotationFormat,
          );

          const isToothActive = ROIList ? ROIList?.includes(ISONumber) : true;

          return (
            <ToothView
              key={ID}
              id={id}
              className={cn(styles[`t${ISONumber.toString().slice(-1)}`])}
              isLowerJaw={isJawLower}
              ISONumber={ISONumber}
              viewNumber={toothNumberView}
              disabled={toothStatus === ToothStatus.missing}
              onClick={({ id: toothID, isActive }) =>
                handleToothClick(toothID, isActive)
              }
              isActive={isToothActive}
              status={toothStatus}
              isROIOpen={isROIOpen}
              isSimplify={isSimplify}
              toothOfInterestNumber={toothOfInterestNumber}
              blackAndWhiteMode={blackAndWhiteMode}
              isMissingThirdMolar={isMissingThirdMolar}
            />
          );
        })}
      </div>
    </div>
  );
};
