// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WidgetCard-module__container--Xeu-XEYq{padding:24px;border-radius:16px;background-color:var(--bg2)}.WidgetCard-module__container--Xeu-XEYq img{width:100%}@media screen and (max-width: 420px){.WidgetCard-module__container--Xeu-XEYq{padding:16px}}", "",{"version":3,"sources":["webpack://./src/shared/ui/WidgetCard/WidgetCard.module.scss","webpack://./src/shared/styles/media.scss"],"names":[],"mappings":"AAEA,wCACE,YAAA,CACA,kBAAA,CACA,2BAAA,CAEA,4CACE,UAAA,CCDF,qCDLF,wCAUI,YAAA,CAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.container {\n  padding: 24px;\n  border-radius: 16px;\n  background-color: var(--bg2);\n\n  img {\n    width: 100%;\n  }\n\n  @include mobile {\n    padding: 16px;\n  }\n}\n","$mobile: 420px;\n$tablet: 768px;\n$smallDesktop: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1400px;\n\n@mixin mobile {\n  @media screen and (max-width: $mobile) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media screen and (max-width: $tablet) {\n    @content;\n  }\n}\n\n@mixin smallDesktop {\n  @media screen and (max-width: $smallDesktop) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media screen and (max-width: $desktop) {\n    @content;\n  }\n}\n\n@mixin largeDesktop {\n  @media screen and (max-width: $largeDesktop) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "WidgetCard-module__container--Xeu-XEYq"
};
export default ___CSS_LOADER_EXPORT___;
