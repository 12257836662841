import { useIntl } from 'react-intl';

import { useAppDispatch } from '@/shared/hooks';
import { ToastErrors, toastErrors } from '@/shared/config';
import { toastCaller } from '@/shared/ui';

import { assetsModel } from '@/entities/assets';
import { organizationModel } from '@/entities/organization';

export const useRemoveAsset = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  return async (assetID: string) => {
    try {
      const { Organization } = await dispatch(
        assetsModel.thunks.deleteAsset({ AssetID: assetID }),
      ).unwrap();

      dispatch(organizationModel.actions.setOne(Organization));
    } catch (error) {
      const parsedMessage = JSON.parse(error?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };
};
