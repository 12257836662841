import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';

import { RefreshPage } from '@/widgets/RefreshPage';

import { ErrorPage } from '@/pages/ErrorPage/ErrorPage';

import { ClientCoreRoutes } from './model/routes';
import { IntlProvider } from './providers/IntlProvider';
import { store } from './model/store';

export const App = Sentry.withProfiler(() => (
  <Provider store={store}>
    <IntlProvider>
      <RadixTooltip.Provider>
        <Router>
          <Sentry.ErrorBoundary fallback={<ErrorPage />}>
            <ClientCoreRoutes />
            <RefreshPage />
            <ToastContainer icon={false} hideProgressBar />
          </Sentry.ErrorBoundary>
        </Router>
      </RadixTooltip.Provider>
    </IntlProvider>
  </Provider>
));
