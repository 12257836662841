import { take } from 'ramda';

import { Invitation } from '@/shared/api/protocol_gen/model/dto_access';
import { OrganizationUserRole } from '@/shared/api/protocol_gen/model/dto_organization';

import { MAX_ROWS_COUNT } from '../config/constants';

export const getShortedList = (
  listData: (Invitation | OrganizationUserRole)[],
) => take(MAX_ROWS_COUNT, listData);
