import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { Icon, IconNames, Tooltip } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { FMXNavigationMode, reportsModel } from '@/entities/reports';
import { ModalID, modalModel } from '@/entities/modal';

import { toolsMessages } from '@/features/imageToolbar';

import { SwitchMaskType } from '../SwitchMaksType/SwitchMaskType';

import styles from './Toolbar.module.scss';

type ToolNames = 'brightness' | 'sharpening' | 'reset';

export const CONTROL_TOOLS: Record<ToolNames, string> = {
  brightness: 'brightness',
  sharpening: 'shaprness',
  reset: 'back',
};

type ToolbarProps = {
  className?: string;
  controls: ToolNames[];
  tooltipPosition?: 'top' | 'right' | 'left' | 'bottom';
  handleScrollToothCardsToTop: () => void;
};

const ToolsAndModes = {
  brightness: 'windowing',
  sharpening: 'sharpening',
  reset: 'view',
};

export const Toolbar: FC<ToolbarProps> = (props) => {
  // TODO will be removed after ImageToolbar refactoring
  const { className, controls, tooltipPosition, handleScrollToothCardsToTop } =
    props;

  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const activeControl = useAppSelector(
    reportsModel.selectors.selectToolbarActiveControl,
  );

  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );

  const handleChangeNavigationMode = () => {
    if (navigationMode === FMXNavigationMode.MatrixView) {
      dispatch(reportsModel.actions.setActiveSubImagesIDs([]));
    }
    dispatch(
      reportsModel.actions.setNavigationMode(
        navigationMode === FMXNavigationMode.ToothChart
          ? FMXNavigationMode.MatrixView
          : FMXNavigationMode.ToothChart,
      ),
    );
    dispatch(reportsModel.actions.setActivePage(0));
    dispatch(reportsModel.actions.setActiveToothID(''));
    handleScrollToothCardsToTop();
  };

  const handleOpenEditModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.TeethNumberingModal,
        data: {},
      }),
    );
  }, [dispatch]);

  const handleSetActiveControl = (control: ToolNames) => {
    if (control === activeControl) {
      dispatch(reportsModel.actions.setToolbarActiveControl('view'));
      dispatch(reportsModel.actions.setToolsMode('view'));
      return;
    }

    dispatch(reportsModel.actions.setToolbarActiveControl(control));
    dispatch(reportsModel.actions.setToolsMode(ToolsAndModes[control]));
  };

  return (
    <div className={cn(styles.container, className)}>
      {controls.map((control) => {
        const toolIcon = (
          <Icon
            name={CONTROL_TOOLS[control] as IconNames}
            className={cn(
              styles.icon,
              activeControl && activeControl === control && styles.active,
            )}
            size={32}
            onClick={() => handleSetActiveControl(control)}
          />
        );
        if (tooltipPosition) {
          return (
            <Tooltip.Primary
              key={control}
              side={tooltipPosition}
              content={
                <span className="p3">
                  {formatMessage(toolsMessages[control])}
                </span>
              }
            >
              {toolIcon}
            </Tooltip.Primary>
          );
        }
        return toolIcon;
      })}

      <Tooltip.Primary
        side={tooltipPosition}
        content={<span className="p3">{formatMessage(toolsMessages.pen)}</span>}
      >
        <Icon
          name="pen"
          className={cn(styles.icon)}
          size={32}
          onClick={handleOpenEditModal}
        />
      </Tooltip.Primary>

      <Tooltip.Primary
        side={tooltipPosition}
        content={<span className="p3">{formatMessage(toolsMessages.ios)}</span>}
      >
        <Icon
          name="ios"
          className={cn(
            styles.icon,
            navigationMode === FMXNavigationMode.ToothChart && styles.active,
          )}
          size={32}
          onClick={handleChangeNavigationMode}
        />
      </Tooltip.Primary>

      <SwitchMaskType />
    </div>
  );
};
