// TODO: Resolve the issue with the types
// @ts-nocheck
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { ToothCondition } from '@/entities/logicalCondition/model/logicalConditionSlice';

import {
  getConditionGroupByCode,
  isConditionUncertain,
  getConditionCodeColor,
  getConditionModelScorePercentage,
  shouldConditionItemBeShown,
} from '../lib';
import {
  ChildConditionGroups,
  FLAT_CONDITION_GROUPS,
  CHILD_CONDITION_GROUPS,
  ONE_OF_CONDITION_GROUPS,
  ConditionGroups,
} from '../config/const';
import { ConditionInterface, ChildConditionInterface } from '../config/types';
import { conditionText } from '../config/i18n';
import { mergeConditions } from '../lib/mergeConditions';

// Return all possible condition items or only transform received conditions
export const useTransformLogicalCondition = (
  toothConditions: ToothCondition,
  allCodes: boolean = true,
  showLowProbability: boolean = false, // Why not just use report selector for this?
): ConditionInterface[] => {
  const { formatMessage } = useIntl();

  const { conditions, childConditions } =
    toothConditions ??
    ({ conditions: {}, childConditions: {} } as ToothCondition);

  const allConditions = useMemo(() => {
    const conditionList = Object.values(conditions).map(mergeConditions);

    const childConditionList = Object.values(childConditions).flatMap((item) =>
      Object.values(item).flatMap(mergeConditions),
    );

    return [...conditionList, ...childConditionList];
  }, [conditions, childConditions]);

  const conditionItems = useMemo((): ConditionInterface[] => {
    if (allCodes) {
      return FLAT_CONDITION_GROUPS.map((code) => {
        const condition = mergeConditions(conditions[code]);

        const conditionColor = getConditionCodeColor(
          code,
          isConditionUncertain(condition),
          showLowProbability,
        );

        const isEndo = code === ConditionCode.EndoTreated;

        const conditionGroup = getConditionGroupByCode(code);

        const childConditionInterfaces: ChildConditionInterface[] =
          code in CHILD_CONDITION_GROUPS
            ? Object.entries(
                CHILD_CONDITION_GROUPS[code] as Record<
                  Exclude<
                    ConditionCode,
                    ConditionCode.ConditionCodeInvalidValue
                  >,
                  Record<ConditionGroups, ConditionCode[]>
                >,
              ).flatMap(
                ([group, codes]: [ChildConditionGroups, ConditionCode[]]) =>
                  codes.map((childCode): ChildConditionInterface => {
                    const childConditionCollection =
                      childConditions[condition?.ID ?? '']?.[childCode];
                    const childCondition = mergeConditions(
                      childConditionCollection,
                    );
                    const childConditionColor = getConditionCodeColor(
                      childCode,
                      isConditionUncertain(childCondition),
                      showLowProbability,
                    );

                    return {
                      id: childCondition?.ID ?? '',
                      ids: childCondition?.IDs ?? [],
                      toothID: childCondition?.Tooth?.ToothID,
                      parentID: childCondition?.ParentID ?? '',
                      code: childCode,
                      parentConditionCode: code,
                      parentConditionId: condition?.ID,
                      group,
                      text: conditionText[childCode]
                        ? formatMessage(conditionText[childCode])
                        : '',
                      color: isEndo ? childConditionColor : conditionColor,
                      probability: childCondition?.Certainty?.ModelScore,
                      probabilityText:
                        getConditionModelScorePercentage(childCondition),
                      isChecked:
                        shouldConditionItemBeShown(showLowProbability)(
                          childCondition,
                        ),
                      isOneOf: false,
                      hasLocalizations: !!childCondition?.Localizations?.length,
                    };
                  }),
              )
            : [];

        return {
          id: condition?.ID ?? '',
          ids: condition?.IDs ?? [],
          toothID: condition?.Tooth?.ToothID,
          code,
          group: conditionGroup,
          text: conditionText[code] && formatMessage(conditionText[code]),
          color: conditionColor,
          probability: condition?.Certainty?.ModelScore,
          probabilityText: getConditionModelScorePercentage(condition),
          isChecked: shouldConditionItemBeShown(showLowProbability)(condition),
          isOneOf: ONE_OF_CONDITION_GROUPS.includes(conditionGroup),
          hasLocalizations: !!condition?.Localizations?.length,
          childConditionInterfaces,
        };
      });
    }

    return allConditions.map((condition) => {
      const code = condition.Code;
      const conditionColor = getConditionCodeColor(
        code,
        isConditionUncertain(condition),
        showLowProbability,
      );
      const conditionGroup = getConditionGroupByCode(code);

      return {
        id: condition?.ID,
        ids: condition?.IDs,
        toothID: condition?.Tooth?.ToothID,
        code: condition.Code,
        group: conditionGroup,
        text: conditionText[code] && formatMessage(conditionText[code]),
        color: conditionColor,
        probability: condition?.Certainty?.ModelScore,
        probabilityText: getConditionModelScorePercentage(condition),
        isChecked: shouldConditionItemBeShown(showLowProbability)(condition),
        isOneOf: ONE_OF_CONDITION_GROUPS.includes(conditionGroup),
        hasLocalizations: !!condition?.Localizations?.length,
      };
    });
  }, [
    allCodes,
    allConditions,
    conditions,
    showLowProbability,
    formatMessage,
    childConditions,
  ]);

  return conditionItems;
};
