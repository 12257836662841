import { FC } from 'react';
import cn from 'classnames';
import { generatePath, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Avatar } from '@/shared/ui';
import { PATHS } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';

import { patientModel, StudiesCounters } from '@/entities/patient';
import { getImageSrc } from '@/entities/assets';
import { organizationModel } from '@/entities/organization';
import { DoctorsAvatars } from '@/entities/doctors';

import styles from './PatientListCard.module.scss';

type PatientListCardProps = {
  patientID: string;
  patientExternalID: string;
  patientName: string;
  patientBirthDate: string;
  displayAssetID: string;
  className?: string;
  testID?: string;
};

export const PatientListCard: FC<PatientListCardProps> = (props) => {
  const {
    patientID,
    patientExternalID,
    patientName,
    patientBirthDate,
    displayAssetID,
    className,
    testID,
  } = props;

  const patientDoctorsIDs = useAppSelector(
    patientModel.selectors.selectPatientDoctorsIDs(patientID),
  );

  const patientDoctorsAsUserRole = useAppSelector(
    organizationModel.selectors.selectDoctorsByIDs(
      patientDoctorsIDs as string[],
    ),
  );

  const isOnlyOneDoctor = patientDoctorsAsUserRole?.length === 1;

  const { formatMessage } = useIntl();

  const patientStudyImage = getImageSrc(displayAssetID, 'thumbnail');

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <header className={styles.patientCardHeader}>
        <Link to={generatePath(PATHS.patientProfile, { patientID })}>
          <div className={styles.previewContainer}>
            {displayAssetID ? (
              <img
                className={styles.preview}
                src={patientStudyImage}
                alt={formatMessage({
                  id: 'imgAltText.patientPreview',
                  defaultMessage: 'Patient preview',
                })}
              />
            ) : (
              <div className={styles.previewDefaultImage} />
            )}
          </div>
        </Link>
      </header>
      <div className={styles.patientInfo}>
        <Link to={generatePath(PATHS.patientProfile, { patientID })}>
          <h3 className={cn(styles.patientName, 'h3')}>{patientName}</h3>
          <p className={cn(styles.patientID, 'p2')}>
            {formatMessage({
              id: 'patientInfo.externalID',
              defaultMessage: 'ID',
            })}
            {': '}
            {patientExternalID}
          </p>
          <p className={cn(styles.date, 'p2')}>
            {formatMessage({
              id: 'patientInfo.DoB',
              defaultMessage: 'DoB',
            })}
            {': '}
            {patientBirthDate}
          </p>
          <Avatar.Group className={styles.avatars}>
            {patientDoctorsAsUserRole?.map((doctorAsUserRole) => (
              <DoctorsAvatars
                doctor={doctorAsUserRole}
                isOnlyOneDoctor={isOnlyOneDoctor}
              />
            ))}
          </Avatar.Group>
          <StudiesCounters patientID={patientID} />
        </Link>
      </div>
    </div>
  );
};
