import { OrganizationUserRoleType } from '@/shared/api/protocol_gen/model/dto_organization';

import { TabValue } from '../config/constants';

export const getTabValuesByDoctorRoles = (
  roles: OrganizationUserRoleType[],
) => {
  if (roles?.includes(OrganizationUserRoleType.OrganizationUserRoleTypeOwner)) {
    return [
      TabValue.CompanyInfo,
      TabValue.Staff,
      TabValue.BillingInfo,
      TabValue.SubscriptionPlans,
    ];
  }
  if (
    roles?.includes(
      OrganizationUserRoleType.OrganizationUserRoleTypeClinicalLeader,
    )
  ) {
    return [TabValue.CompanyInfo, TabValue.Staff];
  }

  return [TabValue.CompanyInfo];
};
