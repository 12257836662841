// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DoctorLabel-module__container--3yjeh8qo{display:flex;align-items:center;gap:8px}.DoctorLabel-module__text--Im08hz9C{color:var(--bw)}", "",{"version":3,"sources":["webpack://./src/entities/doctors/ui/DoctorLabel/DoctorLabel.module.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,oCACE,eAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.text {\n  color: var(--bw);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DoctorLabel-module__container--3yjeh8qo",
	"text": "DoctorLabel-module__text--Im08hz9C"
};
export default ___CSS_LOADER_EXPORT___;
