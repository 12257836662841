import { useAppDispatch } from '@/shared/hooks';

import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';

// TODO: And try/catch for error handling, maybe add onError callback if it needs
export const useUpdateReportROI = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const dispatch = useAppDispatch();

  const updateReportROI = async ({
    reportID,
    ROIToothIDs,
  }: {
    reportID: string;
    ROIToothIDs: string[];
  }) => {
    const newROI = await dispatch(
      reportsModel.thunks.setROI({
        ReportID: reportID,
        ROIToothIDs,
      }),
    ).unwrap();

    dispatch(toothModel.actions.setMany(newROI.AllReportTeeth));

    if (onSuccess) {
      onSuccess();
    }
  };

  return updateReportROI;
};
