import { useMemo } from 'react';

import { useAppSelector } from '@/shared/hooks';

import {
  getToothStatus,
  logicalConditionModel,
} from '@/entities/logicalCondition';
import { toothModel, ToothTypesSet } from '@/entities/tooth';

export const useToothChart = (): { toothTypesSet: ToothTypesSet } => {
  const teeth = useAppSelector(toothModel.selectors.selectAll);
  const toothConditions = useAppSelector(
    logicalConditionModel.selectors.selectAll,
  );

  const toothTypesSet = useMemo(
    () =>
      teeth.reduce((acc, tooth) => {
        acc[tooth.ID] = getToothStatus(toothConditions[tooth.ID]);

        return acc;
      }, {} as ToothTypesSet),
    [teeth, toothConditions],
  );

  return {
    toothTypesSet,
  };
};
