// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TeethChartFilters-module__container--JwQCwutr{display:flex;gap:4px}.TeethChartFilters-module__legend--c6Lc8TWs{border-radius:30px;padding:2px 8px;opacity:.5}.TeethChartFilters-module__active--JQQOl\\+Au{opacity:1;display:flex;justify-content:center;align-items:center}.TeethChartFilters-module__pointer--9xVwyKRf{cursor:pointer}.TeethChartFilters-module__opac--c\\+\\+\\+pwbE{opacity:.5}\n\n.TeethChartFilters-module__legendTitle--YVEcZ34R{color:var(--white);margin-right:2px}\n\n[dir=\"rtl\"] .TeethChartFilters-module__legendTitle--YVEcZ34R{margin-right:0;margin-left:2px}.TeethChartFilters-module__legendCount--zd9Lm\\+dW{color:var(--white60)}.TeethChartFilters-module__legendGreyInfo--sktMP16M{color:var(--grey8)}", "",{"version":3,"sources":["webpack://./src/entities/tooth/ui/TeethChartFilters/TeethChartFilters.module.scss"],"names":[],"mappings":"AAAA,+CACE,YAAA,CACA,OAAA,CAGF,4CACE,kBAAA,CACA,eAAA,CACA,UAAA,CAGF,6CACE,SAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA,CAGF,6CACE,cAAA,CAGF,6CACE,UAAA;;AAGF,iDACE,kBAAA,CACA,gBAAA;;AAFF,6DAEE,cAAA,CAAA,eAAA,CAGF,kDACE,oBAAA,CAGF,oDACE,kBAAA","sourcesContent":[".container {\n  display: flex;\n  gap: 4px;\n}\n\n.legend {\n  border-radius: 30px;\n  padding: 2px 8px;\n  opacity: 0.5;\n}\n\n.active {\n  opacity: 1;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.opac {\n  opacity: 0.5;\n}\n\n.legendTitle {\n  color: var(--white);\n  margin-right: 2px;\n}\n\n.legendCount {\n  color: var(--white60);\n}\n\n.legendGreyInfo {\n  color: var(--grey8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TeethChartFilters-module__container--JwQCwutr",
	"legend": "TeethChartFilters-module__legend--c6Lc8TWs",
	"active": "TeethChartFilters-module__active--JQQOl+Au",
	"pointer": "TeethChartFilters-module__pointer--9xVwyKRf",
	"opac": "TeethChartFilters-module__opac--c+++pwbE",
	"legendTitle": "TeethChartFilters-module__legendTitle--YVEcZ34R",
	"legendCount": "TeethChartFilters-module__legendCount--zd9Lm+dW",
	"legendGreyInfo": "TeethChartFilters-module__legendGreyInfo--sktMP16M"
};
export default ___CSS_LOADER_EXPORT___;
