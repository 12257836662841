import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'ramda';

import {
  Study,
  StudyStatus,
  StudyType,
} from '@/shared/api/protocol_gen/model/dto_study';
import { Button, Icon, Skeleton, Tabs, WidgetCard } from '@/shared/ui';
import {
  useAppSelector,
  useLocationState,
  useMedia,
  useTab,
} from '@/shared/hooks';

import { i18n } from '@/entities/study';
import { getImageSrc } from '@/entities/assets';
import { userModel } from '@/entities/user';
import {
  STUDY_TO_SERVICES_MAP,
  StudyToServiceMap,
  useBillingAccess,
} from '@/entities/billing';

import {
  CBCTNewReportModal,
  useCBCTNewReportModal,
} from '@/features/addNewReport';
import { useNewReport } from '@/features/addNewReport/hooks';

import { IOXRayMatrixDeprecated } from '@/widgets/IOXRayMatrix';

import { ReportAccordion } from '../ReportAccordion/ReportAccordion';
import { UploadingStatusFrame } from '../UploadingStatusFrame/UploadingStatusFrame';
import { orderReportButtonMessages, studyCardTitle } from '../../config';

import styles from './StudyCard.module.scss';

type StudyCardProps = {
  className?: string;
  studies: Study[];
  // Get study type from internal data 'studies'
  onViewDetails: (studyID: string) => void;
};

// TODO: rewrite tabs logic and preview images logic
export const StudyCard: FC<StudyCardProps> = (props) => {
  const { className, studies, onViewDetails } = props;

  const { formatMessage, formatDate } = useIntl();

  const { isPhone } = useMedia();

  const studyLoading = useAppSelector((state) => state.study.loading);

  const user = useAppSelector(userModel.selectors.selectCurrentUser);

  const services = useBillingAccess();

  const { lastOpenTabStudyID } = useLocationState();

  const { CanEditInfo, CanEditPersonalData } = user?.YourPermissions ?? {};

  const isLastOpenTabInThisStudyCard = studies.some(
    (study) => study.ID === lastOpenTabStudyID,
  );

  const isUserCanEdit = CanEditInfo && CanEditPersonalData;

  const showSkeleton = studyLoading === 'idle' || studyLoading === 'pending';

  const studyType = studies[0]?.Type;

  const { tabValue: currentStudyID, handleTabChange } = useTab({
    name: `studyCard-${studyType}`,
  });

  const {
    showCBCTNewReportModal,
    closeCBCTNewReportModal,
    shouldOpenCBCTNewReport,
  } = useCBCTNewReportModal();

  const { requestPanoOrFMXReport, requestCBCTNewReport } = useNewReport();

  const needOpenCBCTNewReport = shouldOpenCBCTNewReport(studyType);

  const CBCTPreviewImageSrc = needOpenCBCTNewReport
    ? getImageSrc(studies[0].DisplayAssetID, 'preview')
    : '';

  const notDeletedStudies = studies.filter((study) => !study.Deleted.Deleted);

  const hasStudyIDInFilteredStudies = notDeletedStudies.some(
    (study) => study.ID === currentStudyID,
  );

  const studyID =
    (hasStudyIDInFilteredStudies && currentStudyID) ||
    (isLastOpenTabInThisStudyCard && lastOpenTabStudyID) ||
    notDeletedStudies[0]?.ID;

  const requestCBCTGPReportHandle = async () => {
    await requestCBCTNewReport(studyID);
  };

  const isDisabledReportButton = (currentID: string) => {
    const currentStudy = studies.find((study) => study.ID === currentID);

    return currentStudy?.Status !== StudyStatus?.StudyStatus_Finished;
  };

  if (showSkeleton) {
    return <Skeleton width="100%" borderRadius="16px" height="390px" />;
  }

  if (isEmpty(notDeletedStudies)) {
    return null;
  }

  return (
    <>
      <WidgetCard className={cn(styles.container, className)}>
        <Tabs.Root value={studyID} onValueChange={handleTabChange}>
          <Tabs.List size="small">
            <h3 className={cn(styles.title, 'h3')}>
              {formatMessage(studyCardTitle[studyType])}
            </h3>

            {notDeletedStudies.map(
              (study) =>
                study?.Created?.At && (
                  <Tabs.Trigger
                    className={cn({
                      [styles.noClickable]: studies.length === 1,
                    })}
                    key={study?.ID}
                    value={study?.ID}
                    size="small"
                  >
                    {formatDate(study.Created.At, {
                      dateStyle: 'medium',
                      timeStyle: 'short',
                    })}
                  </Tabs.Trigger>
                ),
            )}

            <Button
              className={styles.detailsButton}
              variant="tertiary"
              size="medium"
              onClick={() => {
                onViewDetails(studyID);
              }}
            >
              {isPhone ? (
                <Icon name="info" size={32} />
              ) : (
                <FormattedMessage
                  id="studyCard.viewDetailsLink"
                  defaultMessage="View details"
                />
              )}
            </Button>
          </Tabs.List>

          {studies.map((study) => {
            const previewImageSrc = getImageSrc(
              study.DisplayAssetID,
              'preview',
            );
            const typeOfStudy = study.Type;
            const shouldShowIOXRayImage =
              typeOfStudy === StudyType.StudyType_IntraoralXRay;

            return (
              <Tabs.Content key={study.ID} value={study.ID}>
                <div className={styles.content}>
                  <div
                    className={cn(
                      styles.imgContainer,
                      shouldShowIOXRayImage && styles.IOXRayPreview,
                    )}
                  >
                    <UploadingStatusFrame study={study} />
                    {shouldShowIOXRayImage ? (
                      <IOXRayMatrixDeprecated
                        studyID={study.ID}
                        assetIDs={study?.AssetIDs}
                      />
                    ) : (
                      <img
                        className={styles.img}
                        src={previewImageSrc}
                        alt={formatMessage(i18n.studyTypes[study.Type])}
                      />
                    )}

                    {typeOfStudy === StudyType.StudyType_IntraoralXRay ? (
                      <>
                        <div className={styles.layer}>
                          <div className={styles.info}>
                            <h4
                              className={cn(
                                styles.reportType,
                                isPhone ? 'h5' : 'h4',
                              )}
                            >
                              {formatMessage(studyCardTitle[studyType])}
                            </h4>

                            {study?.Created?.At && (
                              <span
                                className={cn(
                                  styles.date,
                                  isPhone ? 'p4' : 'p3',
                                )}
                              >
                                {formatDate(study.Created.At, {
                                  dateStyle: 'medium',
                                })}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className={styles.backgroundGradient} />
                      </>
                    ) : (
                      <div
                        className={cn(
                          styles.imgText,
                          shouldShowIOXRayImage && styles.IOXRayStudyLabel,
                        )}
                      >
                        <h4 className={cn('h4', styles.imgTextItem)}>
                          {formatMessage(studyCardTitle[studyType])}
                        </h4>

                        {study?.Created?.At && (
                          <span className={cn('p3', styles.imgTextItem)}>
                            {formatDate(study.Created.At, {
                              dateStyle: 'medium',
                            })}
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                  <ReportAccordion studyID={study.ID} />
                </div>
              </Tabs.Content>
            );
          })}
        </Tabs.Root>

        {isUserCanEdit && (
          <footer>
            <Button
              variant="secondary"
              size="medium"
              block
              icon="plus"
              disabled={
                !services[
                  STUDY_TO_SERVICES_MAP[studyType as keyof StudyToServiceMap]
                ] || isDisabledReportButton(studyID)
              }
              onClick={() =>
                needOpenCBCTNewReport
                  ? requestCBCTNewReport(studyID)
                  : requestPanoOrFMXReport(studyID, studyType)
              }
            >
              {formatMessage(orderReportButtonMessages[studyType])}
            </Button>
          </footer>
        )}
      </WidgetCard>

      <CBCTNewReportModal
        isOpen={showCBCTNewReportModal}
        onClose={closeCBCTNewReportModal}
        onRequestReport={requestCBCTGPReportHandle}
        CBCTPreviewImageSrc={CBCTPreviewImageSrc}
      />
    </>
  );
};
