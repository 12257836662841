import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { TooltipContentProps } from '@radix-ui/react-tooltip';
import { FormattedMessage } from 'react-intl';

import { Button, Tooltip } from '@/shared/ui';
import { useHubspotChat } from '@/shared/hooks';

import styles from './SupportTooltip.module.scss';

type SupportTooltipProps = {
  className?: string;
  children: ReactNode;
  side?: TooltipContentProps['side'];
  sideOffset?: number;
  errorMessage?: ReactNode;
  messageClassName?: string;
};

export const SupportTooltip: FC<SupportTooltipProps> = (props) => {
  const {
    children,
    className,
    sideOffset,
    side,
    errorMessage,
    messageClassName = 'p4',
  } = props;

  const { openHubspotChat } = useHubspotChat();

  return (
    <Tooltip.Primary
      className={cn(styles.tooltip, className)}
      side={side}
      sideOffset={sideOffset}
      content={
        <div className={styles.content}>
          <p className={cn(styles.errorMessage, messageClassName)}>
            {errorMessage}
          </p>

          <Button
            className={styles.supportButton}
            size="small"
            variant="tertiary"
            onClick={openHubspotChat}
          >
            <FormattedMessage
              id="global.contactSupport"
              defaultMessage="Contact support"
            />
          </Button>
        </div>
      }
    >
      {children}
    </Tooltip.Primary>
  );
};
