import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Tabs } from '@/shared/ui';

import { SliceConfigType, SliceConfigValues } from '../../config';

import styles from './SliceConfig.module.scss';

type SliceConfigProps = {
  className?: string;
  testID?: string;
  sliceConfig: SliceConfigType;
};

export const SliceConfig: FC<SliceConfigProps> = (props) => {
  const { className, testID, sliceConfig } = props;

  const { formatMessage } = useIntl();

  return (
    <h5 className={cn(styles.container, className, 'h5')} data-testid={testID}>
      {formatMessage(SliceConfigValues[sliceConfig.name])}
      <Tabs.Root defaultValue={sliceConfig.values[0]}>
        <Tabs.List size="small">
          {sliceConfig.values.map((sliceConfigValue) => (
            <Tabs.Trigger
              value={sliceConfigValue}
              size="small"
              key={sliceConfigValue}
            >
              {formatMessage(SliceConfigValues[sliceConfigValue])}
            </Tabs.Trigger>
          ))}
        </Tabs.List>
      </Tabs.Root>
    </h5>
  );
};
