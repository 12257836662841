// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderImplantOrOrthoModelModal-module__modalContainer--AYuAGmtk form>div{margin:16px 0}.OrderImplantOrOrthoModelModal-module__uploadImage--kOSg7MDE{position:relative;display:flex;justify-content:center;align-items:center;flex-direction:column;max-width:inherit}.OrderImplantOrOrthoModelModal-module__uploadImage--kOSg7MDE>div{box-sizing:content-box;display:flex;justify-content:center;align-items:center;padding:0;height:46px;width:100%}.OrderImplantOrOrthoModelModal-module__uploadImage--kOSg7MDE img{width:100%;height:100%;object-fit:cover}", "",{"version":3,"sources":["webpack://./src/widgets/OrderModelModal/ui/OrderImplantOrOrthoModelModal.module.scss"],"names":[],"mappings":"AACE,yEACE,aAAA,CAIJ,6DACE,iBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,iBAAA,CAEA,iEACE,sBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,SAAA,CACA,WAAA,CACA,UAAA,CAGF,iEACE,UAAA,CACA,WAAA,CACA,gBAAA","sourcesContent":[".modalContainer {\n  form > div {\n    margin: 16px 0;\n  }\n}\n\n.uploadImage {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  max-width: inherit;\n\n  > div {\n    box-sizing: content-box;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 0;\n    height: 46px;\n    width: 100%;\n  }\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": "OrderImplantOrOrthoModelModal-module__modalContainer--AYuAGmtk",
	"uploadImage": "OrderImplantOrOrthoModelModal-module__uploadImage--kOSg7MDE"
};
export default ___CSS_LOADER_EXPORT___;
