import {
  AuthenticationClientImpl,
  CheckInviteTokenReq,
  CheckInviteTokenResp,
  LogoutReq,
  RecoverByEmail1StartReq,
  RecoverByEmail2SetPasswordReq,
  RefreshResp,
  SignInReq,
  SignUpByOneself1SendUserInfoReq,
  SignUpByOneself2ConfirmIdentityReq,
  SignUpByOneself3SetPasswordReq,
  SignUpViaOrganizationInviteReq,
  SignUpViaSalesmanInviteReq,
  SignUpViaSharedPatientReq,
} from '@/shared/api/protocol_gen/api/auth/svc_authentication';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import api from '@/shared/api/api';
import { AuthState } from '@/shared/api/protocol_gen/api/auth/dto_auth';
import { Empty } from '@/shared/api/protocol_gen/google/protobuf/empty';

export type AuthRequests = keyof AuthenticationClientImpl;

const generateAuthThunk = createThunkGenerator<AuthRequests>(SliceName.auth);

export const refresh = generateAuthThunk<Empty, RefreshResp>(
  'Refresh',
  api.auth.Refresh,
);

export const startSignIn = generateAuthThunk<SignInReq, AuthState>(
  'SignIn',
  api.auth.SignIn,
);

export const logout = generateAuthThunk<LogoutReq, Empty>(
  'Logout',
  api.auth.Logout,
);

export const signUp = generateAuthThunk<
  SignUpByOneself1SendUserInfoReq,
  AuthState
>('SignUp_ByOneself_1_SendUserInfo', api.auth.SignUp_ByOneself_1_SendUserInfo);

export const signUpViaSharedPatient = generateAuthThunk<
  SignUpViaSharedPatientReq,
  AuthState
>('SignUp_ViaSharedPatient', api.auth.SignUp_ViaSharedPatient);

export const signUpViaOrganizationInvite = generateAuthThunk<
  SignUpViaOrganizationInviteReq,
  AuthState
>('SignUp_ViaOrganizationInvite', api.auth.SignUp_ViaOrganizationInvite);

export const SignUpViaSalesmanInvite = generateAuthThunk<
  SignUpViaSalesmanInviteReq,
  AuthState
>('SignUp_ViaSalesmanInvite', api.auth.SignUp_ViaSalesmanInvite);

export const confirmEmail = generateAuthThunk<
  SignUpByOneself2ConfirmIdentityReq,
  AuthState
>(
  'SignUp_ByOneself_2_ConfirmIdentity',
  api.auth.SignUp_ByOneself_2_ConfirmIdentity,
);

export const confirmPassword = generateAuthThunk<
  SignUpByOneself3SetPasswordReq,
  AuthState
>('SignUp_ByOneself_3_SetPassword', api.auth.SignUp_ByOneself_3_SetPassword);

export const checkInviteToken = generateAuthThunk<
  CheckInviteTokenReq,
  CheckInviteTokenResp
>('CheckInviteToken', api.auth.CheckInviteToken);

export const recoverByEmail = generateAuthThunk<
  RecoverByEmail1StartReq,
  AuthState
>('Recover_ByEmail_1_Start', api.auth.Recover_ByEmail_1_Start);

export const setPassword = generateAuthThunk<
  RecoverByEmail2SetPasswordReq,
  AuthState
>('Recover_ByEmail_2_SetPassword', api.auth.Recover_ByEmail_2_SetPassword);
