// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommentTooth-module__comment--6iJiYzhd{display:flex;align-items:center;word-break:break-all;white-space:pre-wrap;cursor:pointer}.CommentTooth-module__footer--Hg8zIKTf{display:flex;justify-content:end;align-items:center;padding-top:12px;gap:8px}", "",{"version":3,"sources":["webpack://./src/features/commentTooth/ui/CommentTooth/CommentTooth.module.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CACA,kBAAA,CACA,oBAAA,CACA,oBAAA,CACA,cAAA,CAGF,uCACE,YAAA,CACA,mBAAA,CACA,kBAAA,CACA,gBAAA,CACA,OAAA","sourcesContent":[".comment {\n  display: flex;\n  align-items: center;\n  word-break: break-all;\n  white-space: pre-wrap;\n  cursor: pointer;\n}\n\n.footer {\n  display: flex;\n  justify-content: end;\n  align-items: center;\n  padding-top: 12px;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comment": "CommentTooth-module__comment--6iJiYzhd",
	"footer": "CommentTooth-module__footer--Hg8zIKTf"
};
export default ___CSS_LOADER_EXPORT___;
