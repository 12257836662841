import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './SmartGrid.module.scss';

type SmartGridProps = {
  children: ReactNode;
  className?: string;
};

const getGridType = (amountOfChildren: number): string => {
  if (amountOfChildren > 4) {
    return styles.tripleColumn;
  }

  if (amountOfChildren > 1) {
    return styles.doubleColumn;
  }

  return styles.single;
};

export const SmartGrid: FC<SmartGridProps> = (props) => {
  const { children, className } = props;

  const amountOfChildren = React.Children.count(children);

  return (
    <div
      className={cn(
        styles.smartGridWrapper,
        getGridType(amountOfChildren),
        className,
      )}
    >
      {children}
    </div>
  );
};
