import { GroupedIOXRayImages } from '@/features/IOXRayMatrix/lib/groupIOXRayImagesByPartition';
import { SubImageInterface } from '@/features/IOXRayMatrix';

import { IOXRayMatrixType } from '../config/IOXRayMatrix.type';

export const getMatrixMaxWidth = (groupedImages: GroupedIOXRayImages) => {
  const IOXRayMatrixWidth: IOXRayMatrixType = [
    [[], [], []],
    [[], [], []],
    [[], [], []],
  ];

  const getSubImageWidth = (subImage: SubImageInterface): number => (subImage.BBox?.X?.Max ?? 0) - (subImage.BBox?.X?.Min ?? 0);

  IOXRayMatrixWidth[0][0] = groupedImages.UpperLeft.map(
    getSubImageWidth,
  );
  IOXRayMatrixWidth[0][1] = groupedImages.MiddleLeft.map(
    getSubImageWidth,
  );
  IOXRayMatrixWidth[0][2] = groupedImages.LowerLeft.map(
    getSubImageWidth,
  );

  IOXRayMatrixWidth[1][0] = groupedImages.UpperMiddle.map(
    getSubImageWidth,
  );
  IOXRayMatrixWidth[1][1] = groupedImages.MiddleMiddle.map(
    getSubImageWidth,
  );
  IOXRayMatrixWidth[1][2] = groupedImages.LowerMiddle.map(
    getSubImageWidth,
  );

  IOXRayMatrixWidth[2][0] = groupedImages.UpperRight.map(
    getSubImageWidth,
  );
  IOXRayMatrixWidth[2][1] = groupedImages.MiddleRight.map(
    getSubImageWidth,
  );
  IOXRayMatrixWidth[2][2] = groupedImages.LowerRight.map(
    getSubImageWidth,
  );

  return IOXRayMatrixWidth.reduce((maxWidth, column) => {
    const maxColValue = column.reduce((columnMaxWidth, row) => {
      const maxRowValue = row.reduce(
        (rowMaxWidth, width) => rowMaxWidth + width,
        0,
      );

      if (maxRowValue > columnMaxWidth) {
        return maxRowValue;
      }

      return columnMaxWidth;
    }, 0);

    return maxWidth + maxColValue;
  }, 0);
};
