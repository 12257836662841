import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormattedDate } from 'react-intl/lib';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Button } from '@/shared/ui';
import { PATHS } from '@/shared/config';
// import { ProductStateStatus } from '@/shared/api/protocol_gen/api/billing_new/dto_product_new';

import { billingModel } from '@/entities/billing';
import { ModalID, modalModel } from '@/entities/modal';

import { NoSubscription } from '../NoSubscription/NoSubscription';
// import { PauseOrActivateSubscriptionModal } from '../PauseOrActivateSubscriptionModal/PauseOrActivateSubscriptionModal';
import { TopUpLimitsModal } from '../TopUpLimitsModal/TopUpLimitsModal';
import { InventoryItemView } from '../InventoryItemView/InventoryItemView';

import styles from './CurrentSubscription.module.scss';

type CurrentSubscriptionProps = {
  className?: string;
};

export const CurrentSubscription: FC<CurrentSubscriptionProps> = (props) => {
  const { className } = props;

  const { formatNumber } = useIntl();

  const currentSubscription = useAppSelector(
    billingModel.selectors.selectCurrentSubscription,
  );

  const availableAddons = useAppSelector(
    billingModel.selectors.selectAvailableAddons,
  );

  const dispatch = useAppDispatch();

  const hasActiveSubscription = Boolean(currentSubscription);
  const canUserBuyAddons =
    currentSubscription?.Model?.Subscription?.CanUserAddAddons ?? false;
  const endDate = (currentSubscription?.End ?? 0) * 1000;
  const price = Number(currentSubscription?.Model?.Price ?? 0);
  const currency = currentSubscription?.Model?.Currency ?? 'USD';
  const cost = formatNumber(price, {
    style: 'currency',
    maximumFractionDigits: 2,
    currencyDisplay: 'narrowSymbol',
    currency,
  });

  return (
    <div className={cn(styles.container, className)}>
      <header className={styles.header}>
        <h3 className="h3">
          <FormattedMessage
            id="subscription.currentSubscription.header"
            defaultMessage="Current subscription"
          />
        </h3>

        {/* button will be connected with top up limits flow later */}
        {hasActiveSubscription && (
          <Button
            variant="tertiary"
            disabled={!canUserBuyAddons}
            onClick={() =>
              dispatch(
                modalModel.actions.openModal({
                  modalID: ModalID.TopUpReportLimitsModal,
                }),
              )
            }
          >
            <FormattedMessage
              id="subscription.topUpLimits"
              defaultMessage="Top up report limits"
            />
          </Button>
        )}
      </header>

      {hasActiveSubscription ? (
        <>
          <InventoryItemView inventoryItem={currentSubscription} cost={cost} />

          <div className={cn(styles.billingPeriod, 'p2')}>
            <div className={styles.billingPeriodCell}>
              <span className={styles.label}>
                <FormattedMessage
                  id="subscription.nextBillingDate"
                  defaultMessage="Next billing date:"
                />
              </span>

              <FormattedDate value={new Date(endDate)} dateStyle="short" />
            </div>

            <div className={styles.billingPeriodCell}>
              <span className={styles.label}>
                <FormattedMessage id="global.cost" defaultMessage="Cost:" />
              </span>

              {cost}
            </div>
          </div>

          <footer className={styles.footer}>
            <Link
              to={`${PATHS.companySettings}?tab=SubscriptionPlans`}
              className={cn(styles.viewAllPlans, 'p2')}
            >
              <FormattedMessage
                id="subscription.viewAllPlans"
                defaultMessage="View all plans"
              />
            </Link>

            {/* TODO: [2|m] add method to activate/pause subscription when backend will be ready */}
            {/* <Button */}
            {/*   variant="secondary" */}
            {/*   onClick={() => */}
            {/*     dispatch( */}
            {/*       modalModel.actions.openModal({ */}
            {/*         modalID: ModalID.PauseOrActivateSubscriptionModal, */}
            {/*       }), */}
            {/*     ) */}
            {/*   } */}
            {/* > */}
            {/*   {currentSubscription?.Status === */}
            {/*   ProductStateStatus.ProductStateStatusActive ? ( */}
            {/*     <FormattedMessage id="global.pause" defaultMessage="Pause" /> */}
            {/*   ) : ( */}
            {/*     <FormattedMessage */}
            {/*       id="global.activate" */}
            {/*       defaultMessage="Activate" */}
            {/*     /> */}
            {/*   )} */}
            {/* </Button> */}
          </footer>
        </>
      ) : (
        <NoSubscription />
      )}

      {hasActiveSubscription && availableAddons.length > 0 && (
        <TopUpLimitsModal availableAddons={availableAddons} />
      )}
      {/* TODO: uncomment when backend will be ready */}
      {/* {(currentSubscription?.Status === */}
      {/*   ProductStateStatus.ProductStateStatusActive || */}
      {/*   currentSubscription?.Status === */}
      {/*     ProductStateStatus.ProductStateStatusAwaitingActivation) && ( */}
      {/*   <PauseOrActivateSubscriptionModal */}
      {/*     subscriptionStatus={currentSubscription.Status} */}
      {/*   /> */}
      {/* )} */}
    </div>
  );
};
