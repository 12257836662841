// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StudiesCounters-module__container--JuMZfVrI{display:flex;align-items:center;width:100%;height:46px;gap:4px;padding-top:3px;background-color:transparent}.StudiesCounters-module__container--JuMZfVrI.StudiesCounters-module__cardContainer--muiKKXrH{height:40px;width:40px}", "",{"version":3,"sources":["webpack://./src/entities/patient/ui/StudiesCounters/StudiesCounters.module.scss"],"names":[],"mappings":"AAAA,6CACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,OAAA,CACA,eAAA,CACA,4BAAA,CAEA,6FACE,WAAA,CACA,UAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 46px;\n  gap: 4px;\n  padding-top: 3px;\n  background-color: transparent;\n\n  &.cardContainer {\n    height: 40px;\n    width: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "StudiesCounters-module__container--JuMZfVrI",
	"cardContainer": "StudiesCounters-module__cardContainer--muiKKXrH"
};
export default ___CSS_LOADER_EXPORT___;
