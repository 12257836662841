import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { Icon, IconNames, Tooltip } from '@/shared/ui';
import { ToolNames } from '@/shared/config';
import { MedicalImageViewOptions } from '@/shared/api/protocol_gen/model/dto_common_image_view_options';

import { toolsMessages } from '../../config/i18n';

import styles from './ImageToolbar.module.scss';

export const CONTROL_TOOLS: Record<ToolNames, string> = {
  ruler: 'ruler',
  arrow: 'arrow',
  angle: 'angle',
  eraser: 'erase',
  view: 'eyeFilled',
  brightness: 'brightness',
  sharpening: 'shaprness',
  expand: 'expand',
  invert: 'invert',
  reset: 'back',
  move: 'move',
  zoom: 'zoom',
  split: 'split',
  download: 'download',
  sharpness: 'shaprness',
};

type SliceToolbarProps = {
  controls: ToolNames[];
  activeControl: string;
  setActiveControl: (newControl: string) => void;
  inverted?: boolean;
  setInverted?: Dispatch<SetStateAction<boolean>>;
  tooltipPosition?: 'top' | 'right' | 'left' | 'bottom';
  className?: string;
  setSplited?: Dispatch<SetStateAction<boolean>>;
  handleSetMedicalImageViewOptions?: (
    viewOptions: Partial<MedicalImageViewOptions>,
  ) => void;
};

export const ImageToolbar: FC<SliceToolbarProps> = (props) => {
  // TODO need global refactoring including reports where its used
  const [activeIcon, setActiveIcon] = useState('');
  const {
    controls,
    activeControl,
    setActiveControl,
    inverted,
    setInverted,
    tooltipPosition,
    setSplited,
    className,
    handleSetMedicalImageViewOptions,
  } = props;

  const { formatMessage } = useIntl();

  const handleControlClick = (control: ToolNames) => {
    if (activeControl === 'eyeFilled' && control === 'view') {
      setActiveIcon('');
      return setActiveControl('');
    }
    setActiveIcon(control);
    if (control === 'invert') {
      if (setInverted && handleSetMedicalImageViewOptions) {
        setInverted((prev) => !prev);
        handleSetMedicalImageViewOptions({ Invert: inverted });
      }
    }

    if (control === 'split') {
      if (setSplited) {
        setSplited((prevState) => !prevState);
      }
    }

    return control === activeControl && control !== 'invert'
      ? setActiveControl('')
      : control !== 'invert' && setActiveControl(control);
  };

  return (
    <div className={cn(styles.container, className)}>
      {controls.map((control) => {
        const toolIcon = (
          <Icon
            key={control}
            name={CONTROL_TOOLS[control] as IconNames}
            className={cn(
              styles.icon,
              activeIcon === control && activeControl && styles.active,
              control === 'invert' && inverted && styles.active,
            )}
            size={32}
            onClick={() => handleControlClick(control)}
          />
        );
        if (tooltipPosition) {
          return (
            <Tooltip.Primary
              key={control}
              side={tooltipPosition}
              content={
                <span className="p3">
                  {formatMessage(toolsMessages[control])}
                </span>
              }
            >
              {toolIcon}
            </Tooltip.Primary>
          );
        }
        return toolIcon;
      })}
    </div>
  );
};
