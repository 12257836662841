import { RCCropImageCrop } from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImage';

type CroppedImageSizes = {
  crop: RCCropImageCrop;
  fixWidth?: number;
  fixHeight?: number;
};
export const getCroppedImageSizes = ({
  crop,
  fixHeight,
  fixWidth,
}: CroppedImageSizes) => {
  if (fixHeight) {
    return {
      width: (fixHeight / crop.h) * crop.w,
      height: fixHeight,
    };
  }
  return {
    width: fixWidth,
    height: (fixWidth / crop.w) * crop.h,
  };
};
