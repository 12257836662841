/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { File } from '../model/dto_asset_common';
import { CBCTSeriesGeometryData } from '../model/dto_common_geometry';

export const protobufPackage = 'com.diagnocat.model';

export interface AssetContentGeneratedStudioCBCTDownscaled {
  Volume: File | undefined;
  DownscaleFactor: number;
  Geometry: CBCTSeriesGeometryData | undefined;
}

export interface AssetContentReportStudioImplantGuide {
  Guide: File | undefined;
}

function createBaseAssetContentGeneratedStudioCBCTDownscaled(): AssetContentGeneratedStudioCBCTDownscaled {
  return { Volume: undefined, DownscaleFactor: 0, Geometry: undefined };
}

export const AssetContentGeneratedStudioCBCTDownscaled = {
  encode(
    message: AssetContentGeneratedStudioCBCTDownscaled,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Volume !== undefined) {
      File.encode(message.Volume, writer.uint32(10).fork()).ldelim();
    }
    if (message.DownscaleFactor !== 0) {
      writer.uint32(21).float(message.DownscaleFactor);
    }
    if (message.Geometry !== undefined) {
      CBCTSeriesGeometryData.encode(
        message.Geometry,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedStudioCBCTDownscaled {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedStudioCBCTDownscaled();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Volume = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.DownscaleFactor = reader.float();
          break;
        case 3:
          message.Geometry = CBCTSeriesGeometryData.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedStudioCBCTDownscaled {
    return {
      Volume: isSet(object.Volume) ? File.fromJSON(object.Volume) : undefined,
      DownscaleFactor: isSet(object.DownscaleFactor)
        ? Number(object.DownscaleFactor)
        : 0,
      Geometry: isSet(object.Geometry)
        ? CBCTSeriesGeometryData.fromJSON(object.Geometry)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedStudioCBCTDownscaled): unknown {
    const obj: any = {};
    message.Volume !== undefined &&
      (obj.Volume = message.Volume ? File.toJSON(message.Volume) : undefined);
    message.DownscaleFactor !== undefined &&
      (obj.DownscaleFactor = message.DownscaleFactor);
    message.Geometry !== undefined &&
      (obj.Geometry = message.Geometry
        ? CBCTSeriesGeometryData.toJSON(message.Geometry)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedStudioCBCTDownscaled>, I>,
  >(object: I): AssetContentGeneratedStudioCBCTDownscaled {
    const message = createBaseAssetContentGeneratedStudioCBCTDownscaled();
    message.Volume =
      object.Volume !== undefined && object.Volume !== null
        ? File.fromPartial(object.Volume)
        : undefined;
    message.DownscaleFactor = object.DownscaleFactor ?? 0;
    message.Geometry =
      object.Geometry !== undefined && object.Geometry !== null
        ? CBCTSeriesGeometryData.fromPartial(object.Geometry)
        : undefined;
    return message;
  },
};

function createBaseAssetContentReportStudioImplantGuide(): AssetContentReportStudioImplantGuide {
  return { Guide: undefined };
}

export const AssetContentReportStudioImplantGuide = {
  encode(
    message: AssetContentReportStudioImplantGuide,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Guide !== undefined) {
      File.encode(message.Guide, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentReportStudioImplantGuide {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentReportStudioImplantGuide();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Guide = File.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentReportStudioImplantGuide {
    return {
      Guide: isSet(object.Guide) ? File.fromJSON(object.Guide) : undefined,
    };
  },

  toJSON(message: AssetContentReportStudioImplantGuide): unknown {
    const obj: any = {};
    message.Guide !== undefined &&
      (obj.Guide = message.Guide ? File.toJSON(message.Guide) : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentReportStudioImplantGuide>, I>,
  >(object: I): AssetContentReportStudioImplantGuide {
    const message = createBaseAssetContentReportStudioImplantGuide();
    message.Guide =
      object.Guide !== undefined && object.Guide !== null
        ? File.fromPartial(object.Guide)
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
