// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CBCTNewReportFormModal-module__modalBody--CKVlLocV{display:grid;gap:12px;grid-auto-flow:dense}.CBCTNewReportFormModal-module__image--ocUQLszZ{width:100%;border-radius:16px}.CBCTNewReportFormModal-module__sliceConfig--3o01N75X{display:grid;gap:26px}.CBCTNewReportFormModal-module__footer--4kU1pnrO{gap:16px}", "",{"version":3,"sources":["webpack://./src/features/addNewReport/ui/CBCTNewReportFormModal/CBCTNewReportFormModal.module.scss"],"names":[],"mappings":"AAAA,oDACE,YAAA,CACA,QAAA,CACA,oBAAA,CAGF,gDACE,UAAA,CACA,kBAAA,CAGF,sDACE,YAAA,CACA,QAAA,CAGF,iDACE,QAAA","sourcesContent":[".modalBody {\n  display: grid;\n  gap: 12px;\n  grid-auto-flow: dense;\n}\n\n.image {\n  width: 100%;\n  border-radius: 16px;\n}\n\n.sliceConfig {\n  display: grid;\n  gap: 26px;\n}\n\n.footer {\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "CBCTNewReportFormModal-module__modalBody--CKVlLocV",
	"image": "CBCTNewReportFormModal-module__image--ocUQLszZ",
	"sliceConfig": "CBCTNewReportFormModal-module__sliceConfig--3o01N75X",
	"footer": "CBCTNewReportFormModal-module__footer--4kU1pnrO"
};
export default ___CSS_LOADER_EXPORT___;
