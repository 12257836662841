import { FC, useState } from 'react';
import Popup from 'reactjs-popup';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { useLanguages } from '@/shared/hooks/useLanguages';
import { Icon } from '@/shared/ui';

import { userModel } from '@/entities/user';

import styles from './LanguageSelector.module.scss';
import { LanguageRow } from './ui/LanguageRow/LanguageRow';

type LanguageSelectorProps = {
  className?: string;
  testID?: string;
};

export const LanguageSelector: FC<LanguageSelectorProps> = (props) => {
  const { className, testID } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const { localizedLanguageOptions } = useLanguages();

  const changeLanguageHandle = (languageLocale: string) => {
    setIsOpen(false);
    dispatch(userModel.thunks.setSettings({ locale: languageLocale }));
  };

  const handleWorldIconClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Popup
      trigger={
        <div
          data-testid={testID}
          className={cn(className, styles.worldIconContainer)}
        >
          <Icon
            name="world"
            className={styles.worldIcon}
            onClick={handleWorldIconClick}
          />
        </div>
      }
      position="bottom right"
      open={isOpen}
      arrow={false}
    >
      <div className={styles.dropdownContainer}>
        {localizedLanguageOptions.map((language) => (
          <LanguageRow
            languageName={language.label}
            changeLanguage={() => changeLanguageHandle(language.value)}
            key={language.value}
            checked={language.value === userLocale}
          />
        ))}
      </div>
    </Popup>
  );
};
