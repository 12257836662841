import { ToolNames } from '@/shared/config';

export const PANO_TOOLS: ToolNames[] = [
  'expand',
  'split',
  'sharpening',
  'brightness',
  'invert',
  'reset',
];

export const PANO_DEFAULT_HEIGHT = 300;
export const SCELETON_DEFAULT_HEIGHT = 150;
