// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LanguageSelector-module__worldIconContainer--XvpYvixP{display:flex;justify-content:center;align-items:center}.LanguageSelector-module__worldIcon--dl0fR6WS{cursor:pointer;user-select:none;border-radius:50%;transition:background-color .3s ease}.LanguageSelector-module__worldIcon--dl0fR6WS:hover,.LanguageSelector-module__worldIcon--dl0fR6WS:active{background-color:var(--bg3)}\n\n.LanguageSelector-module__dropdownContainer--HOA1vWyT{min-width:280px;display:flex;flex-direction:column;gap:4px;padding:2px;margin-top:8px;position:fixed;top:58px;transform:translateX(-100%);background-color:var(--bg2);border-radius:24px;box-shadow:0 0 24px rgb(var(--bw), 50%)}\n\n[dir=\"rtl\"] .LanguageSelector-module__dropdownContainer--HOA1vWyT{transform:translateX(100%)}", "",{"version":3,"sources":["webpack://./src/widgets/Header/ui/LanguageSelector/LanguageSelector.module.scss"],"names":[],"mappings":"AAAA,uDACE,YAAA,CACA,sBAAA,CACA,kBAAA,CAGF,8CACE,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,oCAAA,CAEA,yGAEE,2BAAA;;AAIJ,sDACE,eAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CACA,WAAA,CACA,cAAA,CACA,cAAA,CACA,QAAA,CACA,2BAAA,CACA,2BAAA,CACA,kBAAA,CACA,uCAAA;;AAZF,kEASE,0BAGA","sourcesContent":[".worldIconContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.worldIcon {\n  cursor: pointer;\n  user-select: none;\n  border-radius: 50%;\n  transition: background-color 0.3s ease;\n\n  &:hover,\n  &:active {\n    background-color: var(--bg3);\n  }\n}\n\n.dropdownContainer {\n  min-width: 280px;\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  padding: 2px;\n  margin-top: 8px;\n  position: fixed;\n  top: 58px;\n  transform: translateX(-100%);\n  background-color: var(--bg2);\n  border-radius: 24px;\n  box-shadow: 0 0 24px rgb(var(--bw), 50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"worldIconContainer": "LanguageSelector-module__worldIconContainer--XvpYvixP",
	"worldIcon": "LanguageSelector-module__worldIcon--dl0fR6WS",
	"dropdownContainer": "LanguageSelector-module__dropdownContainer--HOA1vWyT"
};
export default ___CSS_LOADER_EXPORT___;
