import { MedicalImageViewOptions } from '@/shared/api/protocol_gen/model/dto_common_image_view_options';

import { ViewOptionsInterface } from '../config/reports.type';

export const transformViewOptions = (
  viewOptions: ViewOptionsInterface,
): MedicalImageViewOptions => ({
  WindowWidth: viewOptions.wwwc.ww,
  WindowLevel: viewOptions.wwwc.wc,
  Sharpness: viewOptions.sharpness,
  Invert: viewOptions.invert,
  ColorMap: undefined,
  ViewportCoords: undefined,
});
