import { StylesConfig } from "react-select";

export const additionalInfoSelectStyles: StylesConfig = {
  placeholder: (base) => ({
    ...base,
    margin: '0 16px',
  }),
  valueContainer: (base) => ({
    ...base,
    gap: '8px 16px',
    margin: 0,
    padding: 0,
  }),
  multiValue: (base) => ({
    ...base,
    gap: 8,
    borderRadius: 24,
    backgroundColor: 'rgba(var(--grey10-rgb), 0.4)',
    border: 'none',
    minHeight: 42,
    padding: 4,
  }),
  multiValueLabel: (base) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: 0,
    paddingLeft: 8,
    color: 'var(--bw)',
    whiteSpace: 'break-spaces',
  }),
  multiValueRemove: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    color: 'var(--grey9)',
    ':hover': {
      color: 'var(--redrose)',
    },
  }),
};
