// @ts-nocheck
// fix todos about types in this file
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
  ColumnSort,
} from '@tanstack/react-table';
import { useVirtual } from 'react-virtual';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';
import sadCatImg from '@/shared/assets/images/sad-cat.png';
import { SliceName, SortByFunctionType, SortKeyEnum } from '@/shared/config';
import { Invitation_InvitationStatus } from '@/shared/api/protocol_gen/model/dto_access';

import { RootState } from '@/app/model/store';

import { Icon } from '../Icon/Icon';
import { Result } from '../Result/Result';
import { Skeleton } from '../Skeleton/Skeleton';

import styles from './TableWithInfiniteScroll.module.scss';

type TableWithInfiniteScrollProps<DataType> = {
  itemCounter: number;
  columnsScheme: ColumnDef<DataType>[];
  listData: DataType[];
  reFetchList: (id: string) => void;
  loadingState: keyof typeof SliceName;
  testID?: string;
  className?: string;
  showSharedByColumn?: boolean;
  showNothingFound?: boolean;
  showSkeleton?: boolean;
  showEmpty?: boolean;
  serverSideSorting?: boolean;
  sortBy?: SortByFunctionType;
  title?: string | React.ReactNode;
  emptyComponent?: React.ReactNode;
  clickOnRow?: (id: string) => void;
  conditionalAdditionalClickOnRow?: (id: string) => void;
};

// TODO: [4/m]: there is no loadind state an all slices
const getLoadingStateSelector =
  (loadingState: keyof typeof SliceName) => (state: RootState) =>
    state[loadingState]?.loading;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TableWithInfiniteScroll = <DataType extends Record<string, any>>(
  props: TableWithInfiniteScrollProps<DataType>,
) => {
  const {
    className,
    testID,
    showSharedByColumn = true,
    title,
    reFetchList,
    listData,
    itemCounter,
    clickOnRow,
    conditionalAdditionalClickOnRow,
    columnsScheme,
    sortBy,
    showSkeleton,
    showNothingFound,
    showEmpty,
    emptyComponent,
    loadingState,
    serverSideSorting,
  } = props;

  // we need a reference to the scrolling element for logic down below
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const prevListDataLength = useRef(listData.length);

  const selectLoadingState = useMemo(
    () => getLoadingStateSelector(loadingState),
    [loadingState],
  );

  const listLoading = useAppSelector(selectLoadingState);

  const [sorting, setSorting] = useState<SortingState>([]);

  const totalDBRowCount = itemCounter ?? 0;

  const totalFetched = listData.length;

  const lastItemInListID = listData[listData.length - 1]?.ID;

  const { formatMessage } = useIntl();

  // TODO: [1/l] The script ignores \n during translation compilation. Remove it after connecting to CrowdIn
  const newLine = `\n`;

  // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        const shouldFetchMore =
          scrollTop &&
          scrollHeight - scrollTop - clientHeight < 300 &&
          listLoading === 'succeeded' &&
          totalFetched < totalDBRowCount;
        // && prevListDataLength.current !== listData.length;

        // once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (shouldFetchMore) {
          prevListDataLength.current = listData.length;
          reFetchList(lastItemInListID);
        }
      }
    },
    [reFetchList, listLoading, totalFetched, totalDBRowCount, lastItemInListID],
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const handleSortChange = (sort: () => ColumnSort[]) => {
    if (sort().length) {
      const { id, desc } = sort()[0];

      if (sortBy) {
        sortBy(id as SortKeyEnum, desc);
      }
    }

    setSorting(sort);
  };

  const table = useReactTable({
    data: listData,
    columns: columnsScheme,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: serverSideSorting,
    state: {
      sorting,
    },
    // Question: [1/l] What is the purpose of separate setSorting if setSorting calls inside handleSortChange?
    onSortingChange: serverSideSorting ? handleSortChange : setSorting,
    enableSortingRemoval: false,
  });

  const { rows } = table.getRowModel();

  // Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 1200,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;

  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return (
    <div
      className={cn(
        styles.container,
        showNothingFound && styles.displayGrid,
        className,
      )}
      data-testid={testID}
      onScroll={(event) => {
        fetchMoreOnBottomReached(event.target as HTMLDivElement);
      }}
      ref={tableContainerRef}
    >
      {title && <h2 className={cn(styles.title, 'h3')}>{title}</h2>}

      {showNothingFound && (
        <Result
          className={styles.empty}
          icon={
            <img
              src={sadCatImg}
              width={130}
              height={130}
              alt={formatMessage({
                id: 'imgAltText.sadCat',
                defaultMessage: 'Sad cat',
              })}
            />
          }
          text={formatMessage(
            {
              id: 'patientList.nothingFound',
              defaultMessage: `It looks like there are no patients with these search parameters.{newLine}Try to change search or filtering parameters`,
            },
            { newLine },
          )}
        />
      )}

      {showEmpty && emptyComponent}

      {!showEmpty && !showNothingFound && (
        <table>
          <thead className={styles.tableHeader}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const isTabletColumnHidden =
                    header.id === SortKeyEnum.SharedBy ||
                    header.id === SortKeyEnum.SharingDate;

                  const isSortActive =
                    header.column.columnDef.enableSorting ?? true;

                  const isHideColumn =
                    header.column.columnDef.enableHiding ?? false;

                  const isSharedByColumnHidden =
                    !showSharedByColumn && isHideColumn;

                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={cn(
                        styles.tableHeaderColumn,
                        isTabletColumnHidden && styles.displayNoneForTablet,
                        isSharedByColumnHidden && styles.displayNone,
                        'p2',
                      )}
                      style={{ width: header.getSize() }}
                    >
                      <div
                        className={styles.tableHeaderColumnWrapper}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}

                        {isSortActive && (
                          <div className={styles.arrowIconsWrapper}>
                            <Icon
                              name="arrowUp2"
                              size={8}
                              className={cn(
                                styles.arrowIcon,
                                header.column.getIsSorted() === 'desc' &&
                                  styles.activeIcon,
                              )}
                            />
                            <Icon
                              name="arrowDown2"
                              size={8}
                              className={cn(
                                styles.arrowIcon,
                                header.column.getIsSorted() === 'asc' &&
                                  styles.activeIcon,
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          {showSkeleton && (
            <div className={styles.skeletonContainer}>
              <Skeleton.Row rowsQuantity={4} />
            </div>
          )}

          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<DataType>;

              return (
                <tr
                  key={row.id}
                  className={cn(
                    styles.tableRow,
                    !(row.original?.Paid ?? true) &&
                      !(row.original?.Overdue ?? true) &&
                      styles.newInvoice,
                    row.original?.Overdue && styles.overdueInvoice,
                    typeof clickOnRow === 'function' && styles.cursorPointer,
                    'p2',
                  )}
                >
                  {row.getVisibleCells().map((cell) => {
                    const isDeleteColumn = cell.column.id === 'delete';

                    const isHideColumn = cell.column.columnDef.enableHiding;

                    const isTabletColumnHidden =
                      cell.column.id === SortKeyEnum.SharedBy ||
                      cell.column.id === SortKeyEnum.SharingDate;

                    const isSharedByColumnHidden =
                      !showSharedByColumn && isHideColumn;

                    const isTokenAndStatusPending =
                      row.original.Token &&
                      row.original.Status ===
                        Invitation_InvitationStatus.StatusPending;

                    return (
                      <td
                        key={cell.id}
                        className={cn(
                          styles.tableCell,
                          isDeleteColumn && styles.deleteColum,
                          isTabletColumnHidden && styles.displayNoneForTablet,
                          isSharedByColumnHidden && styles.displayNone,
                        )}
                        onClick={() => {
                          if (isDeleteColumn) {
                            return;
                          }

                          if (isTokenAndStatusPending && conditionalAdditionalClickOnRow) {
                            conditionalAdditionalClickOnRow(row.original.Token);
                          } else {
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                            typeof clickOnRow === 'function' &&
                              clickOnRow(
                                row.original.Target?.Patient?.PatientID ||
                                  row.original?.ID,
                              );
                          }
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,

                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};
