import { FC, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { throttle } from 'lodash';

import { RCCropImage } from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImage';
import { useAppSelector } from '@/shared/hooks';
import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { DentalNotationFormat } from '@/shared/api/protocol_gen/model/dto_organization';
import { AbsoluteBlock } from '@/shared/graphics/RenderComponents/AbsoluteBlock';
import { RCDetectedTeeth } from '@/shared/graphics/RenderComponents/RCDetectedTeeth/RCDetectedTeeth';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import {
  Localization,
  RCLocalizations,
} from '@/shared/graphics/RenderComponents/RCLocalizations/RCLocalizations';
import { CORS_POLICY } from '@/shared/config';

import { reportsModel } from '@/entities/reports';
import { getImageSrc } from '@/entities/assets';
import { isDuplicate, ToothWithLocalization } from '@/entities/tooth';

import { useTransformSubImageDeprecated } from '@/features/IOXRayMatrix/hooks/useTransformSubImagesDeprecated';
import { groupIOXRayImagesByPartition } from '@/features/IOXRayMatrix/lib/groupIOXRayImagesByPartition';

import { ProcessingUnitContext } from 'graphics';

import { IOXRAY_MATRIX_PADDINGS } from '../../config/constants';
import { getMatrixMaxHeight } from '../../lib/getMaxRowHeight';
import { getMatrixMaxWidth } from '../../lib/getMatrixMaxWidth';

import styles from './IOXRayMatrix.module.scss';

type FmxMatrixProps = {
  assetIDs?: string[];
  className?: string;
  reportID?: string;
  studyID?: string;
  onToothClick?: (tooth: ToothWithLocalization) => void;
  showDetectedTeeth?: boolean;
  onDetectDuplicateToothNumber?: (isInvalid: boolean) => void;
  dentalNotationFormat?: DentalNotationFormat;
  handlePanoContext?: (context: ProcessingUnitContext) => void;
  hoveredConditionBBox?: Localization[];
  hoveredCondition?: Condition;
};

export const IOXRayMatrixDeprecated: FC<FmxMatrixProps> = (props) => {
  const {
    assetIDs,
    className,
    reportID = '',
    studyID = '',
    onToothClick,
    showDetectedTeeth,
    onDetectDuplicateToothNumber,
    dentalNotationFormat,
    handlePanoContext,
    hoveredConditionBBox,
    hoveredCondition,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const [scale, setScale] = useState<number>(0);

  const imageWrapperRef = useRef<HTMLDivElement>(null);

  const reports: Report[] = useAppSelector(
    reportsModel.selectors.selectByStudyIDAndType(
      studyID,
      ReportType.ReportType_IOXRay_GP,
    ),
  );

  const currentReport = useAppSelector(
    reportsModel.selectors.selectByID(reportID),
  );

  const lastCompletedReport =
    reports?.find((report) => report?.Status.Completed) ?? reports[0];

  const isReportStatusComplete =
    reports?.length > 0 && !reportID
      ? lastCompletedReport?.Status.Completed
      : currentReport?.Status.Completed;

  const subImages = useTransformSubImageDeprecated(
    reportID || lastCompletedReport?.ID,
  );

  const groupedSubImages = useMemo(
    () => groupIOXRayImagesByPartition(subImages),
    [subImages],
  );

  useEffect(() => {
    const calculateScale = throttle(() => {
      if (
        Object.values(groupedSubImages).every(
          (partition) => partition.length === 0,
        )
      ) {
        return;
      }

      const wrapperWidth =
        containerRef.current?.parentElement?.clientWidth || 300;
      const wrapperHeight =
        containerRef.current?.parentElement?.clientHeight || 300;

      const IOXRaysOriginalWidth = getMatrixMaxWidth(groupedSubImages);
      const IOXRaysOriginalHeight = getMatrixMaxHeight(groupedSubImages);

      const scaleByWidth =
        (wrapperWidth - IOXRAY_MATRIX_PADDINGS) / IOXRaysOriginalWidth;
      const scaleByHeight =
        (wrapperHeight - IOXRAY_MATRIX_PADDINGS) / IOXRaysOriginalHeight;

      const IOXRaysOriginalAspect =
        IOXRaysOriginalHeight / IOXRaysOriginalWidth;

      const wrapperAspect = wrapperHeight / wrapperWidth;

      if (IOXRaysOriginalAspect >= wrapperAspect) {
        setScale(scaleByHeight);
      } else {
        setScale(scaleByWidth);
      }
    }, 200);

    calculateScale();

    window.addEventListener('resize', calculateScale);

    return () => window.removeEventListener('resize', calculateScale);
  }, [scale, groupedSubImages]);

  useEffect(() => {
    const hasDuplicates = subImages?.some((subImage) =>
      subImage.teethLocalizations.some((tooth) =>
        isDuplicate(subImage.teethLocalizations, tooth),
      ),
    );

    if (onDetectDuplicateToothNumber) {
      onDetectDuplicateToothNumber(hasDuplicates);
    }
  }, [subImages]);

  return !isReportStatusComplete ? (
    <div
      className={cn(
        styles.withoutReport,
        (assetIDs?.length ?? 0) > 1 && styles.gridContainerWithAutoFlow,
      )}
    >
      {assetIDs?.map((assetID) => (
        <img
          key={assetID}
          src={getImageSrc(assetID, 'original')}
          alt={`IOXRay ${assetID}`}
        />
      ))}
    </div>
  ) : (
    <div ref={containerRef} className={cn(styles.container, className)}>
      {scale > 0 &&
        Object.entries(groupedSubImages).map(([group, subImageInterface]) => (
          <div className={styles[group]} key={group}>
            {subImageInterface.map((subImage) => {
              const imageWidth =
                (subImage?.BBox?.X?.Max ?? 0) - (subImage?.BBox?.X?.Min ?? 0);
              const imageHeight =
                (subImage?.BBox?.Y?.Max ?? 0) - (subImage?.BBox?.Y?.Min ?? 0);

              const localizations = subImage.teethLocalizations.flatMap(
                (tooth) => {
                  const {
                    Localization: { BBox },
                  } = tooth;
                  return {
                    tooth,
                    xmin: BBox?.X?.Min ?? 0,
                    ymin: BBox?.Y?.Min ?? 0,
                    xmax: BBox?.X?.Max ?? 0,
                    ymax: BBox?.Y?.Max ?? 0,
                  };
                },
              );

              const hoveredConditionIsInSubImage =
                subImage.teethLocalizations.some(
                  (toothLocalization) =>
                    toothLocalization.toothID ===
                    hoveredCondition?.Tooth.ToothID,
                );

              return (
                <div
                  key={subImage.ID}
                  id={subImage.ID}
                  ref={imageWrapperRef}
                  className={styles.imageWrapper}
                >
                  <RCCropImage
                    crop={{
                      x: subImage?.BBox?.X?.Min ?? 0,
                      y: subImage?.BBox?.Y?.Min ?? 0,
                      w: imageWidth,
                      h: imageHeight,
                    }}
                    style={{
                      height: imageHeight * scale,
                      width: imageWidth * scale,
                      overflow: 'hidden',
                      borderRadius: '8px',
                      objectFit: 'contain',
                    }}
                    onLoaded={handlePanoContext}
                    src={{
                      url: getImageSrc(subImage.TargetAssetID, 'original'),
                      kind: 'raster',
                      credentials: CORS_POLICY,
                    }}
                  />
                  {showDetectedTeeth && (
                    <AbsoluteBlock style={{ top: 0, left: 0 }}>
                      <RCDetectedTeeth
                        localizations={localizations}
                        imageSize={{ width: imageWidth, height: imageHeight }}
                        onToothClick={onToothClick}
                        dentalNotationFormat={
                          dentalNotationFormat ||
                          DentalNotationFormat.DentalNotationFormatISO
                        }
                        crop={{
                          x: subImage?.BBox?.X?.Min ?? 0,
                          y: subImage?.BBox?.Y?.Min ?? 0,
                          w: imageWidth,
                          h: imageHeight,
                        }}
                      />
                    </AbsoluteBlock>
                  )}

                  {hoveredConditionIsInSubImage && (
                    <AbsoluteBlock style={{ left: 0, top: 0, zIndex: 3 }}>
                      <RCLocalizations
                        detections={hoveredConditionBBox ?? []}
                        imageSize={{
                          width: imageWidth,
                          height: imageHeight,
                        }}
                        isNonDentalFindings
                      />
                    </AbsoluteBlock>
                  )}
                </div>
              );
            })}
          </div>
        ))}
    </div>
  );
};
