// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UploadingStatusFrame-module__frame--TrZg9SnK{display:flex;flex-direction:column;justify-content:center;align-items:center;gap:24px;position:absolute}.UploadingStatusFrame-module__inVisible--OZwdOty0{display:none}.UploadingStatusFrame-module__placeholderImage--sQ0X6n4D{max-width:140px;height:140px}", "",{"version":3,"sources":["webpack://./src/widgets/StudyCard/ui/UploadingStatusFrame/UploadingStatusFrame.module.scss"],"names":[],"mappings":"AAAA,8CACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CAGF,kDACE,YAAA,CAGF,yDACE,eAAA,CACA,YAAA","sourcesContent":[".frame {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 24px;\n  position: absolute;\n}\n\n.inVisible {\n  display: none;\n}\n\n.placeholderImage {\n  max-width: 140px;\n  height: 140px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"frame": "UploadingStatusFrame-module__frame--TrZg9SnK",
	"inVisible": "UploadingStatusFrame-module__inVisible--OZwdOty0",
	"placeholderImage": "UploadingStatusFrame-module__placeholderImage--sQ0X6n4D"
};
export default ___CSS_LOADER_EXPORT___;
