import { StylesConfig } from "react-select";

export const getDropdownSelectStyles = (
  isSearchVisible: boolean,
): StylesConfig => ({
  container: (base) => ({
    ...base,
  }),
  control: (base, { isFocused }) => ({
    ...base,
    display: isSearchVisible ? base.display : 'none',
    padding: '12px 16px',
    borderRadius: '12px',
    borderColor: isFocused ? 'var(--purpleflat)' : 'rgba(var(--bg3-rgb), .2)',
    borderWidth: 2,
    boxShadow: 'none',
    fontSize: '18px',
    lineHeight: '24px',
    ':hover': {
      borderColor: 'var(--purpleflat)',
      cursor: 'pointer',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  input: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
  }),
  menu: (base) => ({
    ...base,
    position: 'relative',
    margin: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  }),
  menuList: (base) => ({
    ...base,
    margin: '12px 0 0 0',
    padding: 0,
    position: 'relative',
  }),
  option: (base, { isMulti }) => ({
    ...base,
    padding: '12px 16px',
    backgroundColor: 'transparent',
    borderRadius: '12px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 18px center',
    cursor: 'pointer',
    color: 'var(--bw)',
    ':active': {
      backgroundColor: 'transparent',
    },
    ':hover': {
      boxShadow: isMulti ? 'inset 0 0 0 2px var(--purpleflat)' : 'none',
    },
    ':not(:last-child)': {
      marginBottom: '4px',
    },
  }),
});
