import difference from 'lodash/difference';
import { intersection, sortBy } from 'lodash';

import {
  JAW,
  LOWER_JAW_TEETH_ISO_NUMBERS,
  UPPER_JAW_TEETH_ISO_NUMBERS,
} from '@/entities/tooth';

import { SubImageInterface } from '../hooks';

// TODO: write documentation for FMX matrix

type FMXMatrixPartition =
  | 'UpperRight'
  | 'UpperMiddle'
  | 'UpperLeft'
  | 'MiddleRight'
  | 'MiddleMiddle'
  | 'MiddleLeft'
  | 'LowerRight'
  | 'LowerMiddle'
  | 'LowerLeft';

export type GroupedIOXRayImages = Record<
  FMXMatrixPartition,
  SubImageInterface[]
>;

export const getCenterToothNumber = (teethNumbers: number[]) =>
  teethNumbers[Math.floor(teethNumbers.length / 2)];

export const isUpperJaw = (teethNumbers: number[]) =>
  teethNumbers.every((tooth) => UPPER_JAW_TEETH_ISO_NUMBERS.includes(tooth));
export const isLowerJaw = (teethNumbers: number[]) =>
  teethNumbers.every((tooth) => LOWER_JAW_TEETH_ISO_NUMBERS.includes(tooth));
export const isTeethInBothJaws = (teethNumbers: number[]) =>
  !isUpperJaw(teethNumbers) && !isLowerJaw(teethNumbers);

export const isUpperLeft = (subImageInterface: SubImageInterface) => {
  const sortedTeeth = subImageInterface.teethISONumbers.sort();

  const upperLeftQuartile = [...JAW.quartile1, ...JAW.primaryQuartile1];
  const isTeethInFirstQuartile =
    difference(sortedTeeth, upperLeftQuartile).length === 0;
  const centerToothNumber = getCenterToothNumber(sortedTeeth);

  return (
    isTeethInFirstQuartile &&
    ((centerToothNumber <= 18 && centerToothNumber >= 15) ||
      (centerToothNumber <= 55 && centerToothNumber >= 53))
  );
};
export const isUpperRight = (subImageInterface: SubImageInterface) => {
  const { teethISONumbers } = subImageInterface;

  const upperRightQuartile = [...JAW.quartile2, ...JAW.primaryQuartile2];
  const isTeethInSecondQuartile =
    difference(teethISONumbers, upperRightQuartile).length === 0;
  const centerToothNumber = getCenterToothNumber(teethISONumbers);

  return (
    isTeethInSecondQuartile &&
    ((centerToothNumber <= 28 && centerToothNumber >= 25) ||
      (centerToothNumber <= 65 && centerToothNumber >= 63))
  );
};
export const isUpperMiddle = (subImageInterface: SubImageInterface) => {
  const { teethISONumbers } = subImageInterface;

  return (
    isUpperJaw(teethISONumbers) &&
    !isUpperLeft(subImageInterface) &&
    !isUpperRight(subImageInterface)
  );
};

export const isMiddleLeft = (subImageInterface: SubImageInterface) => {
  const { teethISONumbers } = subImageInterface;

  const leftJawsTeethNumbers = [
    ...JAW.quartile1,
    ...JAW.quartile4,
    ...JAW.primaryQuartile1,
    ...JAW.primaryQuartile4,
  ];
  const isOnlyLeftTeeth = teethISONumbers.some((tooth) =>
    leftJawsTeethNumbers.includes(tooth),
  );

  return isTeethInBothJaws(teethISONumbers) && isOnlyLeftTeeth;
};
export const isMiddleRight = (subImageInterface: SubImageInterface) => {
  const { teethISONumbers } = subImageInterface;

  const rightJawsTeethNumbers = [
    ...JAW.quartile2,
    ...JAW.quartile3,
    ...JAW.primaryQuartile2,
    ...JAW.primaryQuartile3,
  ];
  const isOnlyRightTeeth = teethISONumbers.some((tooth) =>
    rightJawsTeethNumbers.includes(tooth),
  );

  return isTeethInBothJaws(teethISONumbers) && isOnlyRightTeeth;
};
export const isMiddleMiddle = (subImageInterface: SubImageInterface) => {
  const { teethISONumbers } = subImageInterface;

  return (
    isTeethInBothJaws(teethISONumbers) &&
    !isMiddleLeft(subImageInterface) &&
    !isMiddleRight(subImageInterface)
  );
};

export const isLowerLeft = (subImageInterface: SubImageInterface) => {
  const { teethISONumbers } = subImageInterface;

  const lowerLeftQuartiles = [...JAW.quartile4, ...JAW.primaryQuartile4];
  const isTeethInThirdQuartile =
    difference(teethISONumbers, lowerLeftQuartiles).length === 0;
  const centerToothNumber = getCenterToothNumber(teethISONumbers);
  const hasFirstTooth = teethISONumbers.includes(41);

  return (
    isTeethInThirdQuartile &&
    !hasFirstTooth &&
    ((centerToothNumber <= 48 && centerToothNumber >= 45) ||
      (centerToothNumber <= 85 && centerToothNumber >= 83))
  );
};
export const isLowerRight = (subImageInterface: SubImageInterface) => {
  const { teethISONumbers } = subImageInterface;

  const lowerRightQuartiles = [...JAW.quartile3, ...JAW.primaryQuartile3];
  const isTeethInThirdQuartile =
    difference(teethISONumbers, lowerRightQuartiles).length === 0;
  const centerToothNumber = getCenterToothNumber(teethISONumbers);
  const hasFirstToothInQuartile = teethISONumbers.includes(31);

  return (
    isTeethInThirdQuartile &&
    !hasFirstToothInQuartile &&
    ((centerToothNumber <= 38 && centerToothNumber >= 35) ||
      (centerToothNumber <= 75 && centerToothNumber >= 73))
  );
};
export const isLowerMiddle = (subImageInterface: SubImageInterface) => {
  const { teethISONumbers } = subImageInterface;

  return (
    isLowerJaw(teethISONumbers) &&
    !isLowerLeft(subImageInterface) &&
    !isLowerRight(subImageInterface)
  );
};

export const sortSubImagesByCenterTooth = (
  subImagesInterface: SubImageInterface[],
) =>
  sortBy(subImagesInterface, (subImage) => {
    const { teethISONumbers } = subImage;
    const centerNumber = getCenterToothNumber(teethISONumbers);
    const maxNumber = Math.max(...teethISONumbers);

    return [maxNumber, centerNumber];
  });

export const sortSubImagesInMiddlePartitionByCenterTooth = (
  subImagesInterface: SubImageInterface[],
) =>
  sortBy(subImagesInterface, (subImage) => {
    const { teethISONumbers } = subImage;
    const upperTeeth = intersection(
      teethISONumbers,
      UPPER_JAW_TEETH_ISO_NUMBERS,
    );
    const centerNumber = getCenterToothNumber(upperTeeth);
    const maxNumber = Math.max(...upperTeeth);

    return [maxNumber, centerNumber];
  });

export const groupIOXRayImagesByPartition = (
  iOXRayImages: SubImageInterface[],
): GroupedIOXRayImages => {
  const groupedIOXRayImages: GroupedIOXRayImages = {
    UpperLeft: [],
    UpperMiddle: [],
    UpperRight: [],
    MiddleLeft: [],
    MiddleMiddle: [],
    MiddleRight: [],
    LowerLeft: [],
    LowerMiddle: [],
    LowerRight: [],
    // TODO: [1|h] add field for images with no detected teeth
  };

  if (!iOXRayImages) {
    return groupedIOXRayImages;
  }

  iOXRayImages.forEach((subImage) => {
    if (isUpperLeft(subImage)) {
      groupedIOXRayImages.UpperLeft.push(subImage);
    } else if (isUpperRight(subImage)) {
      groupedIOXRayImages.UpperRight.push(subImage);
    } else if (isUpperMiddle(subImage)) {
      groupedIOXRayImages.UpperMiddle.push(subImage);
    } else if (isMiddleLeft(subImage)) {
      groupedIOXRayImages.MiddleLeft.push(subImage);
    } else if (isMiddleRight(subImage)) {
      groupedIOXRayImages.MiddleRight.push(subImage);
    } else if (isMiddleMiddle(subImage)) {
      groupedIOXRayImages.MiddleMiddle.push(subImage);
    } else if (isLowerLeft(subImage)) {
      groupedIOXRayImages.LowerLeft.push(subImage);
    } else if (isLowerRight(subImage)) {
      groupedIOXRayImages.LowerRight.push(subImage);
    } else if (isLowerMiddle(subImage)) {
      groupedIOXRayImages.LowerMiddle.push(subImage);
    }
  });

  groupedIOXRayImages.UpperLeft = sortSubImagesByCenterTooth(
    groupedIOXRayImages.UpperLeft,
  ).reverse();
  groupedIOXRayImages.UpperRight = sortSubImagesByCenterTooth(
    groupedIOXRayImages.UpperRight,
  );
  groupedIOXRayImages.UpperMiddle = sortSubImagesInMiddlePartitionByCenterTooth(
    groupedIOXRayImages.UpperMiddle,
  );
  groupedIOXRayImages.MiddleLeft = sortSubImagesInMiddlePartitionByCenterTooth(
    groupedIOXRayImages.MiddleLeft,
  ).reverse();
  groupedIOXRayImages.MiddleRight = sortSubImagesInMiddlePartitionByCenterTooth(
    groupedIOXRayImages.MiddleRight,
  );
  groupedIOXRayImages.LowerLeft = sortSubImagesByCenterTooth(
    groupedIOXRayImages.LowerLeft,
  ).reverse();
  groupedIOXRayImages.LowerRight = sortSubImagesInMiddlePartitionByCenterTooth(
    groupedIOXRayImages.LowerRight,
  );
  groupedIOXRayImages.LowerMiddle = sortSubImagesByCenterTooth(
    groupedIOXRayImages.LowerMiddle,
  );

  return groupedIOXRayImages;
};
