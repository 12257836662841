export enum SliceThicknessConfig {
  name = 'SliceThicknessConfig',
  OneVoxel = 'One voxel',
  FiveTenthsMillimeter = '0.5 mm',
  OneMillimeter = '1.0 mm',
  ThreeMillimeters = '3.0 mm',
  FiveMillimeters = '5.0 mm',
}

export enum SliceStepConfig {
  name = 'SliceStepConfig',
  OneMillimeter = '1.0 mm',
  OneAndAHalfMillimeters = '1.5 mm',
  TwoMillimeters = '2.0 mm',
  TwoAndAHalfMillimeters = '2.5 mm',
  ThreeMillimeters = '3.0 mm',
}

export type SliceConfigValuesType = SliceThicknessConfig | SliceStepConfig;

export type SliceConfigType = {
  name: SliceConfigValuesType;
  values: SliceConfigValuesType[];
};
