import { FC, useCallback } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, WidgetCard } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';
// import { ServiceType } from '@/shared/api/protocol_gen/api/billing/dto_services';

import { ModalID, modalModel } from '@/entities/modal';
// TODO: [2|h] add segmentron access based on billing services
// import { useBillingAccess } from '@/entities/billing';

import styles from './OrderImplantOrOrthoModel.module.scss';

type OrderImplantOrOrthoModelProps = {
  className?: string;
};
export const OrderImplantOrOrthoModel: FC<OrderImplantOrOrthoModelProps> = (
  props,
) => {
  const { className } = props;
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  // const services = useBillingAccess();

  const openOrderModelModal = useCallback(
    (modalTitle: string, type: 'InputStudioImplant' | 'InputStudioOrtho') => {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.OrderOrthoORImplantModal,
          data: { title: modalTitle, type },
        }),
      );
    },
    [dispatch],
  );

  const openOrder3DSegmentronModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.Order3DSegmentronModal,
      }),
    );
  }, [dispatch]);

  return (
    <WidgetCard className={cn(styles.container, className)}>
      <h3 className="h3">
        <FormattedMessage
          id="PatientProfile.oreder3DModel"
          defaultMessage="Order 3D models or treatment planning"
        />
      </h3>

      <div className={styles.actionsWrapper}>
        <div className={styles.action}>
          <Button onClick={openOrder3DSegmentronModal} variant="secondary">
            <FormattedMessage
              id="PatientProfile.new3DModel"
              defaultMessage="New 3D models"
            />
          </Button>
        </div>

        <div className={cn(styles.action, styles.rightActions)}>
          <Button
            // disabled={!services[ServiceType.ServiceType_Order_ImplantStudio_Analysis]}
            onClick={() =>
              openOrderModelModal(
                formatMessage({
                  id: 'OrderModel.OrderImplantStudio ',
                  defaultMessage: 'Order Implant Studio ',
                }),
                'InputStudioImplant',
              )
            }
            variant="secondary"
          >
            <FormattedMessage
              id="PatientProfile.NewImplantPlanning"
              defaultMessage="New implant planning"
            />
          </Button>

          <Button
            // disabled={!services[ServiceType.ServiceType_Order_OrthoStudio_Analysis]}
            onClick={() =>
              openOrderModelModal(
                formatMessage({
                  id: 'OrderModel.OrderOrthoStudio',
                  defaultMessage: 'Order Ortho Studio',
                }),
                'InputStudioOrtho',
              )
            }
            variant="secondary"
          >
            <FormattedMessage
              id="PatientProfile.NewOrthoPlanning"
              defaultMessage="New ortho planning"
            />
          </Button>
        </div>
      </div>
    </WidgetCard>
  );
};
