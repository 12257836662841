import { ToothChartCustomModeToggle } from './ToothChartCustomModeToggle/ToothChartCustomModeToggle';
import { ToothChartGrid } from './ToothChartGrid/ToothChartGrid';
import { ToothChartHeader } from './ToothChartHeader/ToothChartHeader';
import { ToothChartRoot } from './ToothChartRoot/ToothChartRoot';
import { ToothChartTags } from './ToothChartTags/ToothChartTags';

type CompoundedComponent = {
  Root: typeof ToothChartRoot;
  Header: typeof ToothChartHeader;
  CustomModeToggle: typeof ToothChartCustomModeToggle;
  Tags: typeof ToothChartTags;
  Grid: typeof ToothChartGrid;
};

export const ToothChart: CompoundedComponent = {
  Root: ToothChartRoot,
  Header: ToothChartHeader,
  CustomModeToggle: ToothChartCustomModeToggle,
  Grid: ToothChartGrid,
  Tags: ToothChartTags,
};
