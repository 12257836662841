import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';

import { billingModel } from '@/entities/billing';

import { InventoryItemView } from '../InventoryItemView/InventoryItemView';

import styles from './Addons.module.scss';

type AddonsProps = {
  className?: string;
  testID?: string;
};

export const Addons: FC<AddonsProps> = (props) => {
  const { className, testID } = props;

  const addons = useAppSelector(billingModel.selectors.selectCurrentAddons);

  if (!addons?.length) return null;

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <header className={styles.header}>
        <h3 className="h3">
          <FormattedMessage
            id="subscription.addons.header"
            defaultMessage="Add ons"
          />
        </h3>
      </header>

      {addons.map((addon) => (
        <InventoryItemView key={addon.ID} inventoryItem={addon} />
      ))}
    </div>
  );
};
