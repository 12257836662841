import { useIntl } from 'react-intl';

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';
import api from '@/shared/api/api';
import { useAppDispatch } from '@/shared/hooks';
import { toastCaller } from '@/shared/ui';
import {
  StreamDataAccumulatorKey,
  ToastErrors,
  toastErrors,
} from '@/shared/config';

import { assetsModel } from '@/entities/assets';
import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import { conditionModel } from '@/entities/condition';

type CopyReportStreamAccumulators = {
  [StreamDataAccumulatorKey.conditions]: Condition[];
  [StreamDataAccumulatorKey.teeth]: Tooth[];
  [StreamDataAccumulatorKey.assets]: Asset[];
};

const dataAccumulators: CopyReportStreamAccumulators = {
  [StreamDataAccumulatorKey.conditions]: [],
  [StreamDataAccumulatorKey.teeth]: [],
  [StreamDataAccumulatorKey.assets]: [],
};

type UseCopyReportStreamArgs = {
  onComplete: () => void;
};

export const useCopyReportStream = ({
  onComplete,
}: UseCopyReportStreamArgs) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const openReportStream = (reportID: string) => {
    api.report.CopyReport({ ReportID: reportID }).subscribe({
      next: (data) => {
        if (data.Asset) {
          dataAccumulators[StreamDataAccumulatorKey.assets].push(data.Asset);
        }

        if (data.Tooth) {
          dataAccumulators[StreamDataAccumulatorKey.teeth].push(data.Tooth);
        }

        if (data.Report) {
          dispatch(reportsModel.actions.setNewestOne(data.Report));
        }

        if (data.Condition) {
          dataAccumulators[StreamDataAccumulatorKey.conditions].push(
            data.Condition,
          );
        }
      },
      error: (error) => {
        const errorHeading = formatMessage(
          toastErrors[ToastErrors.errorHeading],
        );

        toastCaller({
          type: 'error',
          heading: errorHeading,
          message: JSON.parse(error?.message).text,
        });
      },
      complete: () => {
        dispatch(
          assetsModel.actions.setMany(
            dataAccumulators[StreamDataAccumulatorKey.assets],
          ),
        );
        dataAccumulators[StreamDataAccumulatorKey.assets] = [];

        dispatch(
          toothModel.actions.setMany(
            dataAccumulators[StreamDataAccumulatorKey.teeth],
          ),
        );
        dataAccumulators[StreamDataAccumulatorKey.teeth] = [];

        dispatch(
          conditionModel.actions.setMany(
            dataAccumulators[StreamDataAccumulatorKey.conditions],
          ),
        );
        dataAccumulators[StreamDataAccumulatorKey.conditions] = [];

        onComplete();
      },
    });
  };

  return openReportStream;
};
