import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import { CustomSTLModels, customSTLModels } from '../config/i18n';

export const useGetSTLModelCustomOptions = () => {
  const { formatMessage } = useIntl();

  const stlCustomOptions = useMemo(
    () => [
      {
        label: formatMessage(
          customSTLModels[CustomSTLModels.AllCBCTAsSeparateSTLFiles],
        ),
        value: CustomSTLModels.AllCBCTAsSeparateSTLFiles,
      },
      {
        label: formatMessage(
          customSTLModels[CustomSTLModels.AllCBCTTeethAsOneSTLFile],
        ),
        value: CustomSTLModels.AllCBCTTeethAsOneSTLFile,
      },
      {
        label: formatMessage(customSTLModels[CustomSTLModels.Maxilla]),
        value: CustomSTLModels.Maxilla,
      },
      {
        label: formatMessage(customSTLModels[CustomSTLModels.Mandible]),
        value: CustomSTLModels.Mandible,
      },
      {
        label: formatMessage(customSTLModels[CustomSTLModels.CranialBase]),
        value: CustomSTLModels.CranialBase,
      },
      {
        label: formatMessage(customSTLModels[CustomSTLModels.MandibularCanal]),
        value: CustomSTLModels.MandibularCanal,
      },
      {
        label: formatMessage(customSTLModels[CustomSTLModels.MaxillarySinus]),
        value: CustomSTLModels.MaxillarySinus,
      },
      {
        label: formatMessage(customSTLModels[CustomSTLModels.Airways]),
        value: CustomSTLModels.Airways,
      },
      {
        label: formatMessage(customSTLModels[CustomSTLModels.SoftTissues]),
        value: CustomSTLModels.SoftTissues,
      },
      {
        label: formatMessage(
          customSTLModels[CustomSTLModels.MaxillaPlusCranialBase],
        ),
        value: CustomSTLModels.MaxillaPlusCranialBase,
      },
      {
        label: formatMessage(customSTLModels[CustomSTLModels.OriginalIOS]),
        value: CustomSTLModels.OriginalIOS,
      },
      {
        label: formatMessage(
          customSTLModels[CustomSTLModels.IOSTeethAsSeparateSTLFiles],
        ),
        value: CustomSTLModels.IOSTeethAsSeparateSTLFiles,
      },
      {
        label: formatMessage(
          customSTLModels[CustomSTLModels.IOSTeethAsOneSTLFile],
        ),
        value: CustomSTLModels.IOSTeethAsOneSTLFile,
      },
      {
        label: formatMessage(customSTLModels[CustomSTLModels.Gingiva]),
        value: CustomSTLModels.Gingiva,
      },
      {
        label: formatMessage(
          customSTLModels[CustomSTLModels.IOSTeethFusedWithCBCT],
        ),
        value: CustomSTLModels.IOSTeethFusedWithCBCT,
      },
      {
        label: formatMessage(
          customSTLModels[CustomSTLModels.IOSTeethFusedWithCBCTRoots],
        ),
        value: CustomSTLModels.IOSTeethFusedWithCBCTRoots,
      },
    ],
    [formatMessage],
  );

  return stlCustomOptions;
};
