import { FC } from 'react';

import { SecondaryTooltip } from './components/SecondaryTooltip/SecondaryTooltip';
import { PrimaryTooltip } from './components/PrimaryTooltip/PrimaryTooltip';

type CompoundedComponent = FC & {
  Primary: typeof PrimaryTooltip;
  Secondary: typeof SecondaryTooltip;
};
const Tooltip = {
  Primary: PrimaryTooltip,
  Secondary: SecondaryTooltip,
} as CompoundedComponent;

export { Tooltip };
