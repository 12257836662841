import { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import { Modal, ModalProps, toastCaller } from '@/shared/ui';
import { ToastErrors, toastErrors } from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { accessModel } from '@/entities/access';
import { organizationModel } from '@/entities/organization';

type RemoveStaffMemberModalProps = {
  className?: string;
  testID?: string;
  deletingUserId: string;
  isInvite?: boolean;
} & Pick<ModalProps, 'isOpen' | 'onClose'>;

export const RemoveStaffMemberModal: FC<RemoveStaffMemberModalProps> = (
  props,
) => {
  const { className, testID, isOpen, onClose, deletingUserId, isInvite } =
    props;
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = useState(false);

  const organizationID = useAppSelector(
    organizationModel.selectors.selectCurrentOrganizationID,
  );

  const { formatMessage } = useIntl();

  const handleDeleteStaffMember = async () => {
    setIsDeleting(true);
    try {
      const { Organization: updatedOrganization } = await dispatch(
        accessModel.thunks.setUserOrganizationRoles({
          UserID: deletingUserId,
          OrganizationID: organizationID,
          Roles: [],
        }),
      ).unwrap();

      dispatch(organizationModel.actions.setOne(updatedOrganization));
    } catch (error) {
      const parsedMessage = JSON.parse(error?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    } finally {
      setIsDeleting(false);
      if (onClose) {
        onClose();
      }
    }
  };

  const handleDeleteInviteStaffMember = async () => {
    setIsDeleting(true);
    try {
      const { Invitation } = await dispatch(
        accessModel.thunks.cancelInvitation({
          InvitationID: deletingUserId,
        }),
      ).unwrap();

      dispatch(accessModel.actions.removeOne(Invitation.ID));
    } catch (error) {
      const parsedMessage = JSON.parse(error?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    } finally {
      setIsDeleting(false);
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <Modal
      className={className}
      data-testid={testID}
      title={formatMessage({
        id: 'removeStaffMemberModal.title',
        defaultMessage: 'Remove this staff member?',
      })}
      isOpen={isOpen}
      onClose={onClose}
      onApply={
        isInvite ? handleDeleteInviteStaffMember : handleDeleteStaffMember
      }
      applyButtonText={formatMessage({
        id: 'global.applyRemove',
        defaultMessage: 'Yes, remove',
      })}
      applyButtonProps={{
        danger: true,
        loading: isDeleting,
      }}
    />
  );
};
