import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ToastErrors, toastErrors } from '@/shared/config';
import { toastCaller } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';
import { ApiError } from '@/shared/api/api';

import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import { logicalConditionModel } from '@/entities/logicalCondition';

export const useResetTooth = (toothId: string) => {
  const [isResetToothLoading, setResetToothLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const resetTooth = async () => {
    setResetToothLoading(true);

    try {
      const { Teeth, Conditions } = await dispatch(
        reportsModel.thunks.resetReportTooth({
          ToothID: toothId,
        }),
      ).unwrap();

      const updatedTooth = Teeth.find(
        (changedTooth) => changedTooth.ID === toothId,
      );

      dispatch(toothModel.actions.setNewestOne(updatedTooth));

      Conditions.forEach((condition) => {
        dispatch(logicalConditionModel.actions.updateOne(condition));
      });
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    } finally {
      setResetToothLoading(false);
    }
  };

  return { resetTooth, isResetToothLoading };
};
