import { defineMessages } from 'react-intl';

export enum SegmentronModelType {
  BasicCBCTToSTL = '0',
  AdvancedCBCTAndIOS = '1',
  AdvancedCBCTAndDental = '2',
}

export enum OutputSTLModels {
  AllAnatomiesWithFusedTeethInOneFile = '0',
  AllAnatomiesWithFusedTeethInSeparateFiles = '1',
  AllAnatomiesInSeparateFiles = '2',
  Custom = '3',
}
export enum OutputFilesForSegmentation {
  STLOfTeethInOneFile,
  STLOfTeethAsSeparateFiles,
  STLItemsSelectedBelowAsSeparateFiles,
}

export enum OrientationType {
  CBCT,
  'Zero-index',
  IOS,
}

export enum CustomSTLModels {
  AllCBCTAsSeparateSTLFiles,
  AllCBCTTeethAsOneSTLFile,
  Maxilla,
  Mandible,
  CranialBase,
  MandibularCanal,
  MaxillarySinus,
  Airways,
  SoftTissues,
  MaxillaPlusCranialBase,
  OriginalIOS,
  IOSTeethAsSeparateSTLFiles,
  IOSTeethAsOneSTLFile,
  Gingiva,
  IOSTeethFusedWithCBCT,
  IOSTeethFusedWithCBCTRoots,
}

export const outputFilesForSegmentationTypes =
  defineMessages<SegmentronModelType>({
    [OutputFilesForSegmentation.STLOfTeethInOneFile]: {
      id: 'OutputFilesForSegmentation.STLOfTeethAndFullMaxillofacialAnatomyInOneFile',
      defaultMessage: 'STL of teeth and full maxillofacial anatomy in one file',
    },
    [OutputFilesForSegmentation.STLItemsSelectedBelowAsSeparateFiles]: {
      id: 'OutputFilesForSegmentation.STLItemsSelectedBelowAsSeparateFiles',
      defaultMessage: 'STL items selected below as separate files',
    },
    [OutputFilesForSegmentation.STLOfTeethAsSeparateFiles]: {
      id: 'OutputFilesForSegmentation.STLOfTeethAndFullMaxillofacialAnatomyAsSeparateFiles',
      defaultMessage:
        'STL of teeth and full maxillofacial anatomy as separate files',
    },
  });

export const orientationTypes = defineMessages<SegmentronModelType>({
  [SegmentronModelType.BasicCBCTToSTL]: {
    id: 'SegmentronModelType.basic',
    defaultMessage: 'Basic CBCT to STL segmentation',
  },
  [SegmentronModelType.AdvancedCBCTAndIOS]: {
    id: 'SegmentronModelType.advacedCBCTAndIOS',
    defaultMessage: 'Advanced CBCT and IOS fusion',
  },
  [SegmentronModelType.AdvancedCBCTAndDental]: {
    id: 'SegmentronModelType.advacedCBCTAndDental',
    defaultMessage: 'Advanced CBCT and IOS fusion',
  },
});

export const outputSTLModelTypes = defineMessages<OutputSTLModels>({
  [OutputSTLModels.AllAnatomiesInSeparateFiles]: {
    id: 'OutputSTLModels.AllAnatomiesFromIOSAndCBCTInSeparateFiles',
    defaultMessage: 'All anatomies from IOS and CBCT in separate files',
  },
  [OutputSTLModels.AllAnatomiesWithFusedTeethInOneFile]: {
    id: 'OutputSTLModels.AllAnatomiesFromIOSAndCBCTWithFusedTeethInOneFile',
    defaultMessage:
      'All anatomies from IOS and CBCT with fused teeth in one file',
  },
  [OutputSTLModels.AllAnatomiesWithFusedTeethInSeparateFiles]: {
    id: 'OutputSTLModels.AllAnatomiesFromIOSAndCBCTWithFusedTeethInSeparateFiles',
    defaultMessage:
      'All anatomies from IOS and CBCT with fused teeth in separate files',
  },
  [OutputSTLModels.Custom]: {
    id: 'OutputSTLModels.Custom',
    defaultMessage: 'Custom',
  },
});

export const customSTLModels = defineMessages<CustomSTLModels>({
  [CustomSTLModels.AllCBCTAsSeparateSTLFiles]: {
    id: 'CustomSTLModels.AllCBCTTeethAsSeparateSTLfiles',
    defaultMessage: 'All CBCT Teeth (as separate STL files)',
  },
  [CustomSTLModels.AllCBCTTeethAsOneSTLFile]: {
    id: 'CustomSTLModels.AllCBCTTeethAsOneSTLFilePerJaw',
    defaultMessage: 'All CBCT Teeth (as one STL file per jaw)',
  },
  [CustomSTLModels.Maxilla]: {
    id: 'CustomSTLModels.CBCTMaxilla',
    defaultMessage: 'CBCT Maxilla',
  },
  [CustomSTLModels.Mandible]: {
    id: 'CustomSTLModels.CBCTMandible',
    defaultMessage: 'CBCT Mandible',
  },
  [CustomSTLModels.CranialBase]: {
    id: 'CustomSTLModels.CBCTCranialBase',
    defaultMessage: 'CBCT Cranial base',
  },
  [CustomSTLModels.MandibularCanal]: {
    id: 'CustomSTLModels.CBCTMandibularCanal',
    defaultMessage: 'CBCT Mandibular canal',
  },

  [CustomSTLModels.MaxillarySinus]: {
    id: 'CustomSTLModels.CBCTMaxillarySinus',
    defaultMessage: 'CBCT Maxillary sinus',
  },
  [CustomSTLModels.Airways]: {
    id: 'CustomSTLModels.CBCTAirways',
    defaultMessage: 'CBCT Airways',
  },
  [CustomSTLModels.SoftTissues]: {
    id: 'CustomSTLModels.CBCTSoftTissues',
    defaultMessage: 'CBCT Soft Tissues',
  },
  [CustomSTLModels.MaxillaPlusCranialBase]: {
    id: 'CustomSTLModels.CBCTMaxillaCranialBaseAsOneSTLFile',
    defaultMessage: 'CBCT Maxilla + Cranial base (as one STL file)',
  },
  [CustomSTLModels.OriginalIOS]: {
    id: 'CustomSTLModels.OriginalIOS',
    defaultMessage: 'Original IOS',
  },
  [CustomSTLModels.IOSTeethAsSeparateSTLFiles]: {
    id: 'CustomSTLModels.IOSTeethAsSeparateSTLFiles',
    defaultMessage: 'IOS Teeth (as separate STL files)',
  },
  [CustomSTLModels.IOSTeethAsOneSTLFile]: {
    id: 'CustomSTLModels.IOSTeethAsOneSTLFilePerJaw',
    defaultMessage: 'IOS Teeth (as one STL file per jaw)',
  },
  [CustomSTLModels.Gingiva]: {
    id: 'CustomSTLModels.IOSGingiva',
    defaultMessage: 'IOS Gingiva',
  },
  [CustomSTLModels.IOSTeethFusedWithCBCT]: {
    id: 'CustomSTLModels.IOSTeethFusedWithCBCTAsSeparateSTLFiles',
    defaultMessage: 'IOS Teeth fused with CBCT (as separate STL files)',
  },
  [CustomSTLModels.IOSTeethFusedWithCBCTRoots]: {
    id: 'CustomSTLModels.IOSTeethFusedWithCBCTRootsAsOneSTLFilePerJaw',
    defaultMessage: 'IOS Teeth fused with CBCT Roots (as one STL file per jaw)',
  },
});
