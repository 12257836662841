import { FC, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { ErrorText, Modal, ModalProps } from '@/shared/ui';
import { ApiError } from '@/shared/api/api';

import { useRemoveStudy } from '../../hooks/useRemoveStudy';

import styles from './RemoveStudyModal.module.scss';

type RemoveStudyModalProps = {
  studyID?: string;
  className?: string;
  testID?: string;
  onRemove?: () => void;
} & Pick<ModalProps, 'isOpen' | 'onClose'>;

export const RemoveStudyModal: FC<RemoveStudyModalProps> = (props) => {
  const { className, testID, isOpen, onClose, studyID = '', onRemove } = props;

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { formatMessage } = useIntl();

  const removeStudy = useRemoveStudy();

  const handleClose = () => {
    setErrorMessage(undefined);
    if (onClose) {
      onClose();
    }
  };

  const handleRemoveStudy = async () => {
    setLoading(true);

    try {
      await removeStudy(studyID);
    } catch (error) {
      setErrorMessage((error as ApiError)?.message);
    } finally {
      setLoading(false);
      if (onRemove) {
        onRemove();
      }
      handleClose();
    }
  };

  return (
    <Modal
      className={cn(styles.container, className)}
      data-testid={testID}
      bodyClassName={styles.modalBody}
      title={formatMessage({
        id: 'removeStudyModal.title',
        defaultMessage: 'Remove study',
      })}
      isOpen={isOpen}
      onClose={handleClose}
      onApply={handleRemoveStudy}
      applyButtonText={formatMessage({
        id: 'global.applyRemove',
        defaultMessage: 'Yes, remove',
      })}
      applyButtonProps={{
        danger: true,
        loading: isLoading,
      }}
    >
      <FormattedMessage
        id="removeStudyModal.message"
        defaultMessage="After removing, all study data will be lost. Are you sure?"
      />
      <ErrorText className={styles.errorText} error={errorMessage} />
    </Modal>
  );
};
