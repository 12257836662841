import { isEmpty, omit } from 'ramda';
import { FormattedMessage, useIntl } from 'react-intl';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import cn from 'classnames';
import { FC, useState, useCallback } from 'react';

import { Accordion, Icon, toastCaller } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';
import { ToastErrors, toastErrors } from '@/shared/config';
import { ApiError } from '@/shared/api/api';

import { ModalID, modalModel } from '@/entities/modal';

import { useRemoveStudy } from '@/features/removeStudy';
import {
  UploadSessionPull,
  useUploadAssetContext,
} from '@/features/uploadAsset';

import { StudyUploadProgressItem } from '../StudyUploadProgressItem/StudyUploadProgressItem';

import styles from './UploadAssetStatus.module.scss';

export const UploadAssetStatus: FC = () => {
  const {
    uploadSessionPullStack,
    isUploading,
    handleChangeUploadSessionPullStack,
    canceledSessions,
    handleChangeCanceledSessionID,
  } = useUploadAssetContext();

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const sessionList = omit(canceledSessions, uploadSessionPullStack);
  const uploadSessionPullStackList: UploadSessionPull[] =
    Object.values(sessionList);

  const [accordionIsOpen, setAccordionIsOpen] = useState(false);

  const removeStudy = useRemoveStudy();

  const handleHideProgressItem = (sessionID: string) => {
    handleChangeUploadSessionPullStack(
      omit([sessionID], uploadSessionPullStack),
    );
  };

  const cancelUploadModalHandle = useCallback(
    (sessionID: string, studyID: string) => {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.Confirm,
          data: {
            title: formatMessage({
              id: 'cancelUploadAssetModal.cancelUploading',
              defaultMessage: 'Cancel Uploading',
            }),
            description: formatMessage({
              id: 'cancelUploadAssetModal.message',
              defaultMessage: 'Are you sure you want to cancel the upload?',
            }),
            okText: formatMessage({
              id: 'global.yes',
              defaultMessage: 'Yes',
            }),
            cancelText: formatMessage({
              id: 'global.no',
              defaultMessage: 'No',
            }),
            onConfirm: async () => {
              dispatch(modalModel.actions.closeModal(ModalID.Confirm));

              try {
                handleChangeCanceledSessionID(sessionID);
                await removeStudy(studyID);
                handleHideProgressItem(sessionID);
              } catch (error) {
                const parsedMessage = JSON.parse((error as ApiError)?.message);
                const errorHeading = formatMessage(
                  toastErrors[ToastErrors.errorHeading],
                );

                toastCaller({
                  type: 'error',
                  heading: errorHeading,
                  message: parsedMessage?.text ?? parsedMessage?.reason,
                });
              }
            },
          },
        }),
      );
    },
    [
      dispatch,
      formatMessage,
      handleChangeCanceledSessionID,
      handleHideProgressItem,
      removeStudy,
    ],
  );

  if (isEmpty(uploadSessionPullStackList) || !isUploading) {
    return null;
  }

  return (
    <div className={cn(styles.container, !accordionIsOpen && styles.closed)}>
      <Accordion.Root
        type="single"
        collapsible
        defaultChecked
        defaultValue="one"
        onValueChange={(value) => setAccordionIsOpen(!!value)}
      >
        <Accordion.Item value="one">
          <Accordion.Header className={styles.header}>
            <AccordionPrimitive.Trigger className={styles.trigger}>
              <span className={cn(styles.title, 'h3')}>
                <FormattedMessage
                  id="uploadAsset.title"
                  defaultMessage="Uploads"
                />
              </span>

              {uploadSessionPullStackList.length > 0 && (
                <div className={styles.counter}>
                  {uploadSessionPullStackList.length}
                </div>
              )}

              <Icon className={styles.arrow} name="arrowDown" size={24} />
            </AccordionPrimitive.Trigger>
          </Accordion.Header>

          <Accordion.Content>
            <div className={styles.fileList}>
              {uploadSessionPullStackList.map((fileStack) => (
                <StudyUploadProgressItem
                  key={fileStack.sessionID}
                  onHideProgressItem={handleHideProgressItem}
                  onCancelProgress={cancelUploadModalHandle}
                  data={fileStack}
                  sessionStartAt={
                    uploadSessionPullStack[fileStack.sessionID]?.startAt ?? 0
                  }
                />
              ))}
            </div>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </div>
  );
};
