import { useAppDispatch } from '@/shared/hooks';

import { accessModel } from '@/entities/access';

export const useCancelInvitation = () => {
  const dispatch = useAppDispatch();

  return async ({ invitationID }: { invitationID: string }) => {
    const { Invitation } = await dispatch(
      accessModel.thunks.cancelInvitation({
        InvitationID: invitationID,
      }),
    ).unwrap();

    dispatch(accessModel.actions.removeOne(Invitation.ID));
  };
};
