// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SupportTooltip-module__tooltip--Yp9CJXa9{max-width:420px}\n\n.SupportTooltip-module__supportButton--w2k8WrgV{color:var(--white) !important;padding-left:0 !important}\n\n[dir=\"rtl\"] .SupportTooltip-module__supportButton--w2k8WrgV{padding-left:0 !important;padding-right:0 !important}.SupportTooltip-module__content--JZPj9-q1{display:flex;flex-direction:column;align-items:flex-start;gap:4px}.SupportTooltip-module__errorMessage---RvU6HB6{color:var(--wb)}", "",{"version":3,"sources":["webpack://./src/features/supportTooltip/ui/SupportTooltip/SupportTooltip.module.scss"],"names":[],"mappings":"AAAA,0CACE,eAAA;;AAGF,gDACE,6BAAA,CACA,yBAAA;;AAFF,4DAEE,yBAAA,CAAA,0BAAA,CAGF,0CACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,OAAA,CAGF,+CACE,eAAA","sourcesContent":[".tooltip {\n  max-width: 420px;\n}\n\n.supportButton {\n  color: var(--white) !important;\n  padding-left: 0 !important;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 4px;\n}\n\n.errorMessage {\n  color: var(--wb);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "SupportTooltip-module__tooltip--Yp9CJXa9",
	"supportButton": "SupportTooltip-module__supportButton--w2k8WrgV",
	"content": "SupportTooltip-module__content--JZPj9-q1",
	"errorMessage": "SupportTooltip-module__errorMessage---RvU6HB6"
};
export default ___CSS_LOADER_EXPORT___;
