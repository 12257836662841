import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';

import { billingModel } from '@/entities/billing';

// import { InventoryItemView } from '../InventoryItemView/InventoryItemView';

import styles from './Packages.module.scss';

type PackagesProps = {
  className?: string;
  testID?: string;
};
// TODO: [4|H] refactor package
export const Packages: FC<PackagesProps> = (props) => {
  const { className, testID } = props;

  const packages = useAppSelector(billingModel.selectors.selectInventory);

  if (!packages?.length) return null;

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <header className={styles.header}>
        <h3 className="h3">
          <FormattedMessage
            id="subscription.packages.header"
            defaultMessage="Packages"
          />
        </h3>
      </header>

      {/* {packages.map((addon) => ( */}
      {/*   <InventoryItemView key={addon.ID} inventoryItem={addon} /> */}
      {/* ))} */}
    </div>
  );
};
