// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IOXRayNavigation-module__container--fMQ1rAZt{max-width:720px;margin-top:16px;width:100%;position:relative}.IOXRayNavigation-module__ioxrayMatrix--6CSihB\\+2{min-height:200px;width:100%;padding:4px;border-radius:8px;background-color:var(--wb)}.IOXRayNavigation-module__displayNone--r-L8Tx2A{display:none}.IOXRayNavigation-module__switch--ltw7ss0b{margin-top:4px}.IOXRayNavigation-module__modeActions--cOGYsf\\+9{display:flex;gap:4px;margin-bottom:4px}", "",{"version":3,"sources":["webpack://./src/pages/IOXRayReport/ui/IOXRayNavigation/IOXRayNavigation.module.scss"],"names":[],"mappings":"AAAA,8CACE,eAAA,CACA,eAAA,CACA,UAAA,CACA,iBAAA,CAGF,kDACE,gBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,0BAAA,CAGF,gDACE,YAAA,CAGF,2CACE,cAAA,CAGF,iDACE,YAAA,CACA,OAAA,CACA,iBAAA","sourcesContent":[".container {\n  max-width: 720px;\n  margin-top: 16px;\n  width: 100%;\n  position: relative;\n}\n\n.ioxrayMatrix {\n  min-height: 200px;\n  width: 100%;\n  padding: 4px;\n  border-radius: 8px;\n  background-color: var(--wb);\n}\n\n.displayNone {\n  display: none;\n}\n\n.switch {\n  margin-top: 4px;\n}\n\n.modeActions {\n  display: flex;\n  gap: 4px;\n  margin-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "IOXRayNavigation-module__container--fMQ1rAZt",
	"ioxrayMatrix": "IOXRayNavigation-module__ioxrayMatrix--6CSihB+2",
	"displayNone": "IOXRayNavigation-module__displayNone--r-L8Tx2A",
	"switch": "IOXRayNavigation-module__switch--ltw7ss0b",
	"modeActions": "IOXRayNavigation-module__modeActions--cOGYsf+9"
};
export default ___CSS_LOADER_EXPORT___;
