import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { FieldValues, UseFormSetError } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { toastCaller } from '@/shared/ui';
import {
  PATHS,
  ToastErrors,
  toastErrors,
  UserBadRequestError,
} from '@/shared/config';

import { accessModel, getSenderFullName } from '@/entities/access';
import { PATH_TO_PATIENTS_SHARED_WITH_ME_LIST } from '@/entities/patient';

import { useAcceptSharingPatientInvite } from '@/features/sharePatient/hooks/useAcceptSharingPatientInvite';

export const useOrganizationInvite = (invitationToken: string) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const handleGetInvitationByToken = async (token: string) => {
    try {
      const { Invitation } = await dispatch(
        accessModel.thunks.getInvitationByToken({ Token: token }),
      ).unwrap();

      dispatch(accessModel.actions.setOne(Invitation));
    } catch (error) {
      const parsedMessage = JSON.parse(error?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });

      navigate(PATHS.patients);
    }
  };

  // initialize the invite
  useEffect(() => {
    handleGetInvitationByToken(invitationToken);
  }, []);

  const invitation = useAppSelector(
    accessModel.selectors.selectInvitationByToken(invitationToken),
  );

  const invitationStatus = invitation?.Status;

  const invitationCreatedAt = invitation?.Created?.At;

  const senderFullName = getSenderFullName(invitation);

  const acceptSharingPatientInvite = useAcceptSharingPatientInvite();

  const navigateToPatientProfile = () =>
    navigate({
      pathname: generatePath(PATHS.patientProfile, {
        patientID: invitation?.Target?.Patient?.PatientID,
      }),
    });

  const navigateToSharedWithMeList = () =>
    navigate({
      pathname: generatePath(PATH_TO_PATIENTS_SHARED_WITH_ME_LIST),
    });

  const acceptSharingPatientInviteHandle = async (
    accessCode: string,
    setError: UseFormSetError<FieldValues>,
  ) => {
    try {
      if (invitation) {
        await acceptSharingPatientInvite({
          invitationID: invitation.ID,
          accessCode,
        });
      }

      navigateToPatientProfile();
    } catch (error) {
      const parsedMessage = JSON.parse(error?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      if (
        parsedMessage?.code === UserBadRequestError.INCORRECT_INVITATION_SECRET
      ) {
        setError('accessCode', {
          message: formatMessage({
            id: 'addSharingPatient.error.incorrectAccessCode',
            defaultMessage:
              'Access code is incorrect. Contact the sender to receive the correct access code.',
          }),
        });
      } else if (
        parsedMessage?.code ===
        UserBadRequestError.INVITATION_SECRET_INPUT_LIMIT_EXCEEDED
      ) {
        setError('accessCode', {
          type: 'canceledCode',
          message: formatMessage({
            id: 'addSharingPatient.error.canceledAccessCode',
            defaultMessage:
              'You have entered access code too many times. Your access to the patient has been canceled. Contact the sender to send you a patient profile once again.',
          }),
        });
      } else {
        toastCaller({
          type: 'error',
          heading: errorHeading,
          message: parsedMessage?.text ?? parsedMessage?.reason,
        });
      }
    }
  };

  const showCancelledInvitationToast = () => {
    const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

    toastCaller({
      type: 'error',
      heading: errorHeading,
      message: formatMessage({
        id: 'addSharingPatient.error.noAccess',
        defaultMessage:
          'The invitation has been canceled. You no longer have access to the patient.',
      }),
    });
  };

  return {
    invitationStatus,
    senderFullName,
    invitationCreatedAt,
    acceptSharingPatientInviteHandle,
    navigateToPatientProfile,
    navigateToSharedWithMeList,
    showCancelledInvitationToast,
  };
};
