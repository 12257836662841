import { is } from 'ramda';

import {
  CBCTAnatomy,
  ReportCBCTIOSSegment,
} from '@/shared/api/protocol_gen/model/dto_report_common';

export const getSegmentsOfAllCBCTAnatomies = () => {
  const isNumber = is(Number);

  return Object.values(CBCTAnatomy).reduce(
    (acc: ReportCBCTIOSSegment[], anatomyItem: CBCTAnatomy) => {
      if (
        isNumber(anatomyItem) &&
        anatomyItem !== CBCTAnatomy.CBCT_Anatomy_InvalidValue &&
        anatomyItem !== CBCTAnatomy.UNRECOGNIZED
      ) {
        return [...acc, { CBCTAnatomy: anatomyItem }];
      }
      return acc;
    },
    [],
  );
};
