import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import {
  outputFilesForSegmentationTypes,
  OutputFilesForSegmentation,
} from '../config/i18n';

export const useGetOutputFilesForSegmentation = () => {
  const { formatMessage } = useIntl();

  const outputFilesForSegmentation = useMemo(
    () => [
      {
        label: formatMessage(
          outputFilesForSegmentationTypes[
            OutputFilesForSegmentation.STLOfTeethInOneFile
          ],
        ),
        value: OutputFilesForSegmentation.STLOfTeethInOneFile,
      },
      {
        label: formatMessage(
          outputFilesForSegmentationTypes[
            OutputFilesForSegmentation.STLOfTeethAsSeparateFiles
          ],
        ),
        value: OutputFilesForSegmentation.STLOfTeethAsSeparateFiles,
      },
      {
        label: formatMessage(
          outputFilesForSegmentationTypes[
            OutputFilesForSegmentation.STLItemsSelectedBelowAsSeparateFiles
          ],
        ),
        value: OutputFilesForSegmentation.STLItemsSelectedBelowAsSeparateFiles,
      },
    ],
    [formatMessage],
  );

  return outputFilesForSegmentation;
};
