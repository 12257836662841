import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useLocation, useNavigate } from 'react-router';
import { find, isEmpty, propEq, reject } from 'ramda';

import { Button, Icon, Skeleton, Tooltip, WidgetCard } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { LocationStateType, PATHS } from '@/shared/config';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { scrollToTop, scrollToElementWithParent } from '@/shared/lib';

import { reportsModel } from '@/entities/reports';
import { getDisplayToothNumber } from '@/entities/tooth';
import { ModalID, modalModel } from '@/entities/modal';
import { useGetSelectedMedicalImages } from '@/entities/assets';
import {
  ConditionButton,
  ConditionGroups,
  ConditionInterface,
  SimpleConditionGroup,
  useCombinedConditionGroups,
  useToothConditions,
} from '@/entities/condition';
import { organizationModel } from '@/entities/organization';

import { useApproveTooth } from '@/features/approveTooth';
import { ConditionControl } from '@/features/conditionControl';
import { CommentTooth, useCommentTooth } from '@/features/commentTooth';

import { useResetTooth } from '../hooks/useResetToth';
import {
  TOOTH_CHART_CONTAINER_HEIGHT,
  TOOTH_CHART_PADDINGS,
} from '../config/constants';

import { ToothImagesList } from './ToothImagesList/ToothImagesList';
import styles from './ToothCard.module.scss';

type ToothCardProps = {
  className?: string;
  tooth: Tooth;
  patientID: string;
  reportID: string;
  showViewerButton?: boolean;
  medicalImages?: React.ReactNode;
  dndComponent?: React.ReactNode;
  onRemove?: (toothID: string) => void;
  showSlices?: boolean;
};

export const InternalToothCard: FC<ToothCardProps> = (props) => {
  const {
    className,
    tooth,
    reportID,
    patientID,
    dndComponent,
    medicalImages,
    showViewerButton = true,
    onRemove, // = () => {},
    showSlices = true,
  } = props;

  const [approveButtonHovered, setApproveButtonHovered] = useState(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  // TODO [8|m]: We have tooth cards on report page, so do we need this selector which is run on each card in report?
  // This is iterable component so it should have selectors and other potential hard stuff as less as possible
  // #performance
  const report = useAppSelector(reportsModel.selectors.selectByID(reportID));

  const focusedToothCardID = useAppSelector(
    reportsModel.selectors.selectActiveToothID,
  );

  const reportType = report?.Type;

  const organization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );

  const toothChartHeight = useAppSelector(
    reportsModel.selectors.selectToothChartHeight,
  );

  const showLowProbability = report?.Settings?.LowProbabilityMode ?? false;

  const {
    allConditionItems,
    anatomyConditionItems,
    generalConditionItems,
    groupedConditionItems,
    conditionIsLoaded,
  } = useToothConditions({
    toothID: tooth.ID,
    showLowProbability,
  });

  const combinedConditionGroupItems =
    useCombinedConditionGroups(allConditionItems);

  const { isApproveToothLoading, toggleApproveTooth } = useApproveTooth(tooth);

  const {
    newComment,
    isCommentButtonDisabled,
    isCommentEditorVisible,
    isNewCommentLoading,
    openCommentEditor,
    closeAndResetTextInEditor,
    openChangeComment,
    changeCommentInEditor,
    saveComment,
  } = useCommentTooth(tooth.Comment);

  const location = useLocation();

  const { resetTooth, isResetToothLoading } = useResetTooth(tooth.ID);

  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  const selectedMedicalImages = useGetSelectedMedicalImages(tooth.ID); // TODO add reportType to filter necessary images

  // const selectToothConditions = useMemo(() => logicalConditionModel.selectors.selectToothConditions(tooth.ID), [tooth.ID]);

  const showSimpleConditionView = report?.Settings?.ShowSimpleConditionView;

  // Move this up to the report page?
  const handleAddCondition = useCallback(() => {
    if (report) {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.AddCondition,
          data: { toothID: tooth.ID, reportID: report?.ID },
        }),
      );
    }
  }, [report, dispatch, tooth.ID]);

  const handleApproveButtonHover = (isHovered: boolean) => {
    setApproveButtonHovered(isHovered);
  };

  const handle3DSlicesClick = () => {
    scrollToTop();
    navigate(
      generatePath(PATHS.tooth, {
        patientID,
        reportID,
        toothID: tooth?.ID,
      }),
      {
        state: {
          ...locationState,
          toothID: tooth?.ID,
        },
      },
    );
  };

  const isToothApprovedMessage = tooth?.IsApproved && !approveButtonHovered;
  const isToothDisapproveMessage = tooth?.IsApproved && approveButtonHovered;

  const isToothApproved = !!tooth?.IsApproved;

  const toothTitleCondition = find<ConditionInterface>(
    propEq<keyof ConditionInterface>('group', ConditionGroups.toothType),
    anatomyConditionItems,
  );

  const conditionItems = reject(
    propEq('group', ConditionGroups.toothType),
    anatomyConditionItems,
  );

  const showLocalizationsOnHover =
    report?.Type !== ReportType.ReportType_CBCT_GP;

  useEffect(() => {
    // TODO: maybe affected in other report pages, (PANO, CBCT)
    scrollToElementWithParent(
      focusedToothCardID,
      toothChartHeight + TOOTH_CHART_PADDINGS ?? TOOTH_CHART_CONTAINER_HEIGHT,
    );
  }, [focusedToothCardID, toothChartHeight]);

  return (
    <div id={tooth.ID} className={cn(styles.toothCardWrapper)}>
      <div
        className={cn({ [styles.focused]: tooth.ID === focusedToothCardID })}
      >
        <WidgetCard className={cn(styles.container, className)}>
          <header className={styles.header}>
            {toothTitleCondition && (
              <ConditionControl
                toothID={tooth.ID}
                conditionInterface={toothTitleCondition}
                conditionInterfaceGroup={
                  groupedConditionItems[toothTitleCondition.group]
                }
                popupTriggerTitle={
                  <h3 className="h3">
                    {toothTitleCondition.text}{' '}
                    {getDisplayToothNumber(
                      tooth.Numeration?.ISO ?? 0,
                      organization.Settings?.DentalNotationFormat,
                    )}
                  </h3>
                }
              />
            )}

            {isEmpty(anatomyConditionItems) && !conditionIsLoaded && (
              <Skeleton.Filter
                filtersQuantity={2}
                width={100}
                height={36}
                gap={4}
              />
            )}
            {!showSimpleConditionView && (
              <div className={styles.conditions}>
                {conditionItems.map((conditionItem) =>
                  tooth?.IsApproved ? (
                    <ConditionButton
                      key={conditionItem.id}
                      text={conditionItem.text}
                      conditionInterface={conditionItem}
                      showLocalizationsOnHover={showLocalizationsOnHover}
                    />
                  ) : (
                    <ConditionControl
                      key={conditionItem.id}
                      toothID={tooth.ID}
                      conditionInterface={conditionItem}
                      conditionInterfaceGroup={
                        groupedConditionItems[conditionItem.group]
                      }
                      showLocalizationsOnHover={showLocalizationsOnHover}
                    />
                  ),
                )}
              </div>
            )}

            {typeof onRemove === 'function' && (
              <button
                disabled={tooth?.IsApproved}
                type="button"
                className={styles.removeButton}
                onClick={() => onRemove(tooth.ID)}
              >
                <Icon name="close" size={40} />
              </button>
            )}
          </header>

          <div className={styles.conditions}>
            {showSimpleConditionView ? (
              <SimpleConditionGroup
                handleAddCondition={handleAddCondition}
                combinedConditionItems={combinedConditionGroupItems}
              />
            ) : (
              generalConditionItems.map((conditionItem) =>
                tooth?.IsApproved ? (
                  <ConditionButton
                    key={conditionItem.id}
                    text={conditionItem.text}
                    conditionInterface={conditionItem}
                    showLocalizationsOnHover={showLocalizationsOnHover}
                  />
                ) : (
                  <ConditionControl
                    key={conditionItem.id}
                    toothID={tooth.ID}
                    conditionInterface={conditionItem}
                    conditionInterfaceGroup={
                      groupedConditionItems[conditionItem.group]
                    }
                    showLocalizationsOnHover={showLocalizationsOnHover}
                  />
                ),
              )
            )}

            <Button
              className={styles.actionButton}
              size="small"
              variant="tertiary"
              icon="plus"
              onClick={handleAddCondition}
              disabled={tooth?.IsApproved}
            >
              <FormattedMessage
                id="toothCard.openAddConditionModalButton"
                defaultMessage="Add condition"
              />
            </Button>
          </div>

          {!tooth.Comment && (
            <Button
              className={cn(styles.actionButton, styles.commentButton)}
              size="small"
              variant="tertiary"
              icon="plus"
              onClick={openCommentEditor}
              disabled={isCommentButtonDisabled || tooth?.IsApproved}
            >
              <FormattedMessage
                id="toothCard.addCommentButton"
                defaultMessage="Add comment"
              />
            </Button>
          )}

          <CommentTooth
            autoFocus
            comment={tooth.Comment}
            newComment={newComment}
            isNewCommentLoading={isNewCommentLoading}
            isCommentEditorVisible={isCommentEditorVisible}
            onSaveComment={() => saveComment(tooth.ID)}
            onCancel={closeAndResetTextInEditor}
            onOpenChangeComment={openChangeComment}
            onChangeCommentInEditor={changeCommentInEditor}
          />

          {showSlices && (
            <div className={styles.imageList}>
              {medicalImages || (
                <ToothImagesList
                  reportType={report?.Type}
                  toothID={tooth.ID}
                  toothLocalizations={tooth?.Localizations}
                  images={selectedMedicalImages}
                />
              )}

              {dndComponent}
            </div>
          )}

          <footer className={styles.footer}>
            <div className={styles.topFooterWrapper}>
              <Tooltip.Primary
                content={formatMessage({
                  id: 'toothCard.restoreConditionsButton',
                  defaultMessage: 'Restore the original conditions',
                })}
                side="bottom"
              >
                <Button
                  variant="secondary"
                  size="medium"
                  icon="back"
                  onClick={resetTooth}
                  loading={isResetToothLoading}
                  disabled={tooth?.IsApproved}
                  className={styles.restoreButton}
                />
              </Tooltip.Primary>
            </div>

            <div className={styles.bottomFooterWrapper}>
              {reportType === ReportType.ReportType_CBCT_GP &&
                showViewerButton && (
                  <Button
                    variant="secondary"
                    size="medium"
                    onClick={handle3DSlicesClick}
                  >
                    <FormattedMessage
                      id="toothCard.3dviewer"
                      defaultMessage="3D viewer and slices"
                    />
                  </Button>
                )}

              <Button
                size="medium"
                onMouseMove={() => handleApproveButtonHover(true)}
                onMouseLeave={() => handleApproveButtonHover(false)}
                className={cn(tooth.IsApproved && styles.buttonApproved)}
                loading={isApproveToothLoading}
                onClick={toggleApproveTooth}
                disabled={!report?.YourPermissions?.CanChangeToothApproved}
                success={!approveButtonHovered && tooth.IsApproved}
                danger={approveButtonHovered && tooth.IsApproved}
              >
                {isToothApprovedMessage && (
                  <FormattedMessage
                    id="toothCard.approved"
                    defaultMessage="Approved"
                  />
                )}

                {isToothDisapproveMessage && (
                  <FormattedMessage
                    id="toothCard.disapprove"
                    defaultMessage="Disapprove"
                  />
                )}

                {!isToothApproved && (
                  <FormattedMessage
                    id="toothCard.approve"
                    defaultMessage="Approve"
                  />
                )}
              </Button>
            </div>
          </footer>
        </WidgetCard>
      </div>
    </div>
  );
};

export const ToothCard = memo(InternalToothCard);
