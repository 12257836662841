// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RCDetecedTooth-module__RCContainer--vXP10r72{width:100%;height:100%;overflow:hidden}\n\n.RCDetecedTooth-module__toothNumber--wXu1jS8Y{position:absolute;padding:1px;z-index:1;cursor:pointer;background-color:var(--wb);border-radius:4px;height:14px;min-width:18px;line-height:14px;border:2px solid var(--grey-20, var(--bg3));text-align:center;transform:translate(-50%, -50%);transition:transform .2s}\n\n[dir=\"rtl\"] .RCDetecedTooth-module__toothNumber--wXu1jS8Y{transform:translate(50%, -50%)}\n\n.RCDetecedTooth-module__toothNumber--wXu1jS8Y:hover{z-index:2;transform:scale(1.5) translate(-50%, -50%)}\n\n[dir=\"rtl\"] .RCDetecedTooth-module__toothNumber--wXu1jS8Y:hover{transform:scale(1.5) translate(50%, -50%)}.RCDetecedTooth-module__invalid--ZpXHcv3Q{border:1px solid var(--redrose)}", "",{"version":3,"sources":["webpack://./src/shared/graphics/RenderComponents/RCDetectedTeeth/RCDetecedTooth.module.scss"],"names":[],"mappings":"AAAA,8CACE,UAAA,CACA,WAAA,CACA,eAAA;;AAGF,8CACE,iBAAA,CACA,WAAA,CACA,SAAA,CACA,cAAA,CACA,0BAAA,CACA,iBAAA,CACA,WAAA,CACA,cAAA,CACA,gBAAA,CACA,2CAAA,CACA,iBAAA,CACA,+BAAA,CACA,wBAAA;;AAbF,0DAYE,8BACA;;AACA,oDACE,SAAA,CACA,0CAAA;;AAFF,gEAEE,yCAAA,CAIJ,0CACE,+BAAA","sourcesContent":[".RCContainer {\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n\n.toothNumber {\n  position: absolute;\n  padding: 1px;\n  z-index: 1;\n  cursor: pointer;\n  background-color: var(--wb);\n  border-radius: 4px;\n  height: 14px;\n  min-width: 18px;\n  line-height: 14px;\n  border: 2px solid var(--grey-20, var(--bg3));\n  text-align: center;\n  transform: translate(-50%, -50%);\n  transition: transform 0.2s;\n  &:hover {\n    z-index: 2;\n    transform: scale(1.5) translate(-50%, -50%);\n  }\n}\n\n.invalid {\n  border: 1px solid var(--redrose);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RCContainer": "RCDetecedTooth-module__RCContainer--vXP10r72",
	"toothNumber": "RCDetecedTooth-module__toothNumber--wXu1jS8Y",
	"invalid": "RCDetecedTooth-module__invalid--ZpXHcv3Q"
};
export default ___CSS_LOADER_EXPORT___;
