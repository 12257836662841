import { SharedWithMeStreamSortBy } from '@/shared/api/protocol_gen/api/core/svc_access';
import { SortKeyEnum } from '@/shared/config';

export const getSortByOptionsForSharingWith = (
  sortKey: SortKeyEnum,
  sortAsc: boolean,
): { By?: SharedWithMeStreamSortBy; Ascending?: boolean }[] => {
  switch (sortKey) {
    case SortKeyEnum.PatientName:
      return [
        {
          Ascending: sortAsc,
          By: SharedWithMeStreamSortBy.SharedWithMeStreamSortByPatientLastName,
        },
        {
          Ascending: sortAsc,
          By: SharedWithMeStreamSortBy.SharedWithMeStreamSortByPatientFirstName,
        },
      ];
    case SortKeyEnum.ClinicName:
      return [
        {
          Ascending: sortAsc,
          By: SharedWithMeStreamSortBy.SharedWithMeStreamSortByClinicName,
        },
      ];
    case SortKeyEnum.SharedBy:
      return [
        {
          Ascending: sortAsc,
          By: SharedWithMeStreamSortBy.SharedWithMeStreamSortBySenderLastName,
        },
        {
          Ascending: sortAsc,
          By: SharedWithMeStreamSortBy.SharedWithMeStreamSortBySenderFirstName,
        },
      ];
    case SortKeyEnum.SenderEmail:
      return [
        {
          Ascending: sortAsc,
          By: SharedWithMeStreamSortBy.SharedWithMeStreamSortBySenderEmail,
        },
      ];
    case SortKeyEnum.SharingDate:
      return [
        {
          Ascending: sortAsc,
          By: SharedWithMeStreamSortBy.SharedWithMeStreamSortBySharingDate,
        },
      ];
    default:
      return [];
  }
};
