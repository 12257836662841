import { ChangeEvent, FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './Checkbox.module.scss';

export type CheckboxProps = {
  checked: boolean;
  onChange: (event: boolean | ChangeEvent<Element>) => void;
  className?: string;
  boxClassName?: string;
  testID?: string;
  disabled?: boolean;
  error?: string;
  name?: string;
  children?: ReactNode;
};

export const Checkbox: FC<CheckboxProps> = ({
  checked,
  className,
  boxClassName,
  testID,
  disabled = false,
  onChange,
  error = '',
  name,
  children,
}) => (
  <label
    className={cn(
      styles.container,
      disabled && styles.disabled,
      'p2',
      className,
    )}
    data-testid={testID}
  >
    <input
      type="checkbox"
      className={cn(styles.input, error && styles.isError)}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      name={name}
    />
    <div className={cn(styles.checkbox, boxClassName)} />
    {children}
  </label>
);
