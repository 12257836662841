import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import { Icon, Switch, Tooltip } from '@/shared/ui';

import { useReportSettings } from '../../hooks';

import styles from './ReportSettingsToggle.module.scss';

type ReportSettingsToggleProps = {
  reportID: string;
  className?: string;
};

export const ReportSettingsToggle: FC<ReportSettingsToggleProps> = (props) => {
  const { reportID, className } = props;

  const { formatMessage } = useIntl();
  const { isLoading, settings, updateSettings } = useReportSettings(reportID);

  return (
    <div className={cn(styles.contaner, className)}>
      <div className={styles.lowProbability}>
        <Switch
          checked={settings.LowProbabilityMode}
          disabled={isLoading}
          onChange={() =>
            updateSettings({ LowProbabilityMode: !settings.LowProbabilityMode })
          }
        >
          <FormattedMessage
            id="reportSettings.showLowProbability"
            defaultMessage="Show low probability"
          />
        </Switch>

        <Tooltip.Primary
          content={formatMessage({
            id: 'lowProbabilityInfo',
            defaultMessage:
              'Switch on to see caries signs and periradicular pathologies, with probability rate 30-50%',
          })}
        >
          <div className={styles.lowProbabilityInfoIcon}>
            <Icon name="info" size={24} />
          </div>
        </Tooltip.Primary>
      </div>

      {/* DISABLED WHILE REQUIREMENTS UPDATE */}
      {/* <Switch
        checked={settings.ShowSimpleConditionView}
        disabled={isLoading}
        onChange={() =>
          updateSettings({
            ShowSimpleConditionView: !settings.ShowSimpleConditionView,
          })
        }
      >
        <FormattedMessage
          id="reportSettings.showSimpleConditionView"
          defaultMessage="Simple condition view"
        />
      </Switch> */}
    </div>
  );
};
