import { FC, memo } from 'react';

import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { MedicalImageInterface } from '@/shared/config';
import { Localization } from '@/shared/api/protocol_gen/model/dto_report_localization';

import { IOXRayToothImagesList } from '../IOXRayToothImagesList/IOXRayToothImagesList';
import { CroppedImagesList } from '../CroppedImagesList/CroppedImagesList';
import { GPMedicalImageList } from '../MedicalImageList/MedicalImageList';

type ToothImagesListProps = {
  reportType?: ReportType;
  toothLocalizations?: Localization[];
  images?: MedicalImageInterface[];
  toothID: string;
  className?: string;
};

export const ToothImagesList: FC<ToothImagesListProps> = memo(
  ({ images, toothLocalizations, reportType, className, toothID }) => {
    switch (reportType) {
      case ReportType.ReportType_IOXRay_GP:
        return (
          <IOXRayToothImagesList
            toothLocalizations={toothLocalizations ?? []}
            toothID={toothID}
          />
        );
      case ReportType.ReportType_Pano_GP:
        return (
          <CroppedImagesList
            className={className}
            localizations={toothLocalizations}
            toothID={toothID}
          />
        );
      case ReportType.ReportType_CBCT_GP:
        return (
          <GPMedicalImageList
            className={className}
            medicalImages={images}
            reportType={reportType}
            localizations={toothLocalizations}
          />
        );
      default:
        return null;
    }
  },
);
