import { FC } from 'react';

import { Popup } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { ModalID, modalModel } from '@/entities/modal';

import { MPRWidget } from '../MPRWidget/MPRWidget';

import styles from './MPRViewerModal.module.scss';

export const MprViewerModal: FC = () => {
  const { visible, data } = useAppSelector(
    modalModel.selectors.selectMPRViewerModal,
  );
  const dispatch = useAppDispatch();

  return (
    <Popup
      className={styles.container}
      isOpen={visible}
      onClose={() =>
        dispatch(modalModel.actions.closeModal(ModalID.MPRFullScreenModal))
      }
    >
      <MPRWidget
        mprUrl={data.nrrd}
        // TODO Pass real window width and window center
        initialWw={3200}
        initialWc={600}
      />
    </Popup>
  );
};
