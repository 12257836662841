// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToothChartHeader-module__container--yAREjmKs{display:flex;justify-content:space-between;gap:8px}", "",{"version":3,"sources":["webpack://./src/features/toothChart/ui/ToothChartHeader/ToothChartHeader.module.scss"],"names":[],"mappings":"AAAA,8CACE,YAAA,CACA,6BAAA,CACA,OAAA","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ToothChartHeader-module__container--yAREjmKs"
};
export default ___CSS_LOADER_EXPORT___;
