/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.billing_new';

export interface Account {
  ID: string;
  Email: string;
  OwnerID: string;
  OrganizationIDs: string[];
  BillingZones: string[];
  Country: string;
  StripeIntegration: AccountStripeIntegration | undefined;
  WithoutStripeInvoices: boolean;
  BillingInformation: AccountBillingInformation | undefined;
}

export interface AccountBillingInformation {
  Address: Address | undefined;
  Phone: string;
  CompanyName: string;
  TaxID: string;
}

export interface AccountStripeIntegration {
  CustomerID: string;
  PaymentMethodID: string;
}

export interface Address {
  Region: string;
  City: string;
  ZIP: string;
  Address: string;
}

function createBaseAccount(): Account {
  return {
    ID: '',
    Email: '',
    OwnerID: '',
    OrganizationIDs: [],
    BillingZones: [],
    Country: '',
    StripeIntegration: undefined,
    WithoutStripeInvoices: false,
    BillingInformation: undefined,
  };
}

export const Account = {
  encode(
    message: Account,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Email !== '') {
      writer.uint32(18).string(message.Email);
    }
    if (message.OwnerID !== '') {
      writer.uint32(26).string(message.OwnerID);
    }
    for (const v of message.OrganizationIDs) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.BillingZones) {
      writer.uint32(42).string(v!);
    }
    if (message.Country !== '') {
      writer.uint32(50).string(message.Country);
    }
    if (message.StripeIntegration !== undefined) {
      AccountStripeIntegration.encode(
        message.StripeIntegration,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    if (message.WithoutStripeInvoices === true) {
      writer.uint32(64).bool(message.WithoutStripeInvoices);
    }
    if (message.BillingInformation !== undefined) {
      AccountBillingInformation.encode(
        message.BillingInformation,
        writer.uint32(74).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Account {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Email = reader.string();
          break;
        case 3:
          message.OwnerID = reader.string();
          break;
        case 4:
          message.OrganizationIDs.push(reader.string());
          break;
        case 5:
          message.BillingZones.push(reader.string());
          break;
        case 6:
          message.Country = reader.string();
          break;
        case 7:
          message.StripeIntegration = AccountStripeIntegration.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 8:
          message.WithoutStripeInvoices = reader.bool();
          break;
        case 9:
          message.BillingInformation = AccountBillingInformation.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Account {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Email: isSet(object.Email) ? String(object.Email) : '',
      OwnerID: isSet(object.OwnerID) ? String(object.OwnerID) : '',
      OrganizationIDs: Array.isArray(object?.OrganizationIDs)
        ? object.OrganizationIDs.map((e: any) => String(e))
        : [],
      BillingZones: Array.isArray(object?.BillingZones)
        ? object.BillingZones.map((e: any) => String(e))
        : [],
      Country: isSet(object.Country) ? String(object.Country) : '',
      StripeIntegration: isSet(object.StripeIntegration)
        ? AccountStripeIntegration.fromJSON(object.StripeIntegration)
        : undefined,
      WithoutStripeInvoices: isSet(object.WithoutStripeInvoices)
        ? Boolean(object.WithoutStripeInvoices)
        : false,
      BillingInformation: isSet(object.BillingInformation)
        ? AccountBillingInformation.fromJSON(object.BillingInformation)
        : undefined,
    };
  },

  toJSON(message: Account): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Email !== undefined && (obj.Email = message.Email);
    message.OwnerID !== undefined && (obj.OwnerID = message.OwnerID);
    if (message.OrganizationIDs) {
      obj.OrganizationIDs = message.OrganizationIDs.map((e) => e);
    } else {
      obj.OrganizationIDs = [];
    }
    if (message.BillingZones) {
      obj.BillingZones = message.BillingZones.map((e) => e);
    } else {
      obj.BillingZones = [];
    }
    message.Country !== undefined && (obj.Country = message.Country);
    message.StripeIntegration !== undefined &&
      (obj.StripeIntegration = message.StripeIntegration
        ? AccountStripeIntegration.toJSON(message.StripeIntegration)
        : undefined);
    message.WithoutStripeInvoices !== undefined &&
      (obj.WithoutStripeInvoices = message.WithoutStripeInvoices);
    message.BillingInformation !== undefined &&
      (obj.BillingInformation = message.BillingInformation
        ? AccountBillingInformation.toJSON(message.BillingInformation)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Account>, I>>(object: I): Account {
    const message = createBaseAccount();
    message.ID = object.ID ?? '';
    message.Email = object.Email ?? '';
    message.OwnerID = object.OwnerID ?? '';
    message.OrganizationIDs = object.OrganizationIDs?.map((e) => e) || [];
    message.BillingZones = object.BillingZones?.map((e) => e) || [];
    message.Country = object.Country ?? '';
    message.StripeIntegration =
      object.StripeIntegration !== undefined &&
      object.StripeIntegration !== null
        ? AccountStripeIntegration.fromPartial(object.StripeIntegration)
        : undefined;
    message.WithoutStripeInvoices = object.WithoutStripeInvoices ?? false;
    message.BillingInformation =
      object.BillingInformation !== undefined &&
      object.BillingInformation !== null
        ? AccountBillingInformation.fromPartial(object.BillingInformation)
        : undefined;
    return message;
  },
};

function createBaseAccountBillingInformation(): AccountBillingInformation {
  return { Address: undefined, Phone: '', CompanyName: '', TaxID: '' };
}

export const AccountBillingInformation = {
  encode(
    message: AccountBillingInformation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Address !== undefined) {
      Address.encode(message.Address, writer.uint32(10).fork()).ldelim();
    }
    if (message.Phone !== '') {
      writer.uint32(18).string(message.Phone);
    }
    if (message.CompanyName !== '') {
      writer.uint32(26).string(message.CompanyName);
    }
    if (message.TaxID !== '') {
      writer.uint32(34).string(message.TaxID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AccountBillingInformation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountBillingInformation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Address = Address.decode(reader, reader.uint32());
          break;
        case 2:
          message.Phone = reader.string();
          break;
        case 3:
          message.CompanyName = reader.string();
          break;
        case 4:
          message.TaxID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountBillingInformation {
    return {
      Address: isSet(object.Address)
        ? Address.fromJSON(object.Address)
        : undefined,
      Phone: isSet(object.Phone) ? String(object.Phone) : '',
      CompanyName: isSet(object.CompanyName) ? String(object.CompanyName) : '',
      TaxID: isSet(object.TaxID) ? String(object.TaxID) : '',
    };
  },

  toJSON(message: AccountBillingInformation): unknown {
    const obj: any = {};
    message.Address !== undefined &&
      (obj.Address = message.Address
        ? Address.toJSON(message.Address)
        : undefined);
    message.Phone !== undefined && (obj.Phone = message.Phone);
    message.CompanyName !== undefined &&
      (obj.CompanyName = message.CompanyName);
    message.TaxID !== undefined && (obj.TaxID = message.TaxID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AccountBillingInformation>, I>>(
    object: I,
  ): AccountBillingInformation {
    const message = createBaseAccountBillingInformation();
    message.Address =
      object.Address !== undefined && object.Address !== null
        ? Address.fromPartial(object.Address)
        : undefined;
    message.Phone = object.Phone ?? '';
    message.CompanyName = object.CompanyName ?? '';
    message.TaxID = object.TaxID ?? '';
    return message;
  },
};

function createBaseAccountStripeIntegration(): AccountStripeIntegration {
  return { CustomerID: '', PaymentMethodID: '' };
}

export const AccountStripeIntegration = {
  encode(
    message: AccountStripeIntegration,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CustomerID !== '') {
      writer.uint32(10).string(message.CustomerID);
    }
    if (message.PaymentMethodID !== '') {
      writer.uint32(18).string(message.PaymentMethodID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AccountStripeIntegration {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountStripeIntegration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CustomerID = reader.string();
          break;
        case 2:
          message.PaymentMethodID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountStripeIntegration {
    return {
      CustomerID: isSet(object.CustomerID) ? String(object.CustomerID) : '',
      PaymentMethodID: isSet(object.PaymentMethodID)
        ? String(object.PaymentMethodID)
        : '',
    };
  },

  toJSON(message: AccountStripeIntegration): unknown {
    const obj: any = {};
    message.CustomerID !== undefined && (obj.CustomerID = message.CustomerID);
    message.PaymentMethodID !== undefined &&
      (obj.PaymentMethodID = message.PaymentMethodID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AccountStripeIntegration>, I>>(
    object: I,
  ): AccountStripeIntegration {
    const message = createBaseAccountStripeIntegration();
    message.CustomerID = object.CustomerID ?? '';
    message.PaymentMethodID = object.PaymentMethodID ?? '';
    return message;
  },
};

function createBaseAddress(): Address {
  return { Region: '', City: '', ZIP: '', Address: '' };
}

export const Address = {
  encode(
    message: Address,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Region !== '') {
      writer.uint32(10).string(message.Region);
    }
    if (message.City !== '') {
      writer.uint32(18).string(message.City);
    }
    if (message.ZIP !== '') {
      writer.uint32(26).string(message.ZIP);
    }
    if (message.Address !== '') {
      writer.uint32(34).string(message.Address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Address {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Region = reader.string();
          break;
        case 2:
          message.City = reader.string();
          break;
        case 3:
          message.ZIP = reader.string();
          break;
        case 4:
          message.Address = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Address {
    return {
      Region: isSet(object.Region) ? String(object.Region) : '',
      City: isSet(object.City) ? String(object.City) : '',
      ZIP: isSet(object.ZIP) ? String(object.ZIP) : '',
      Address: isSet(object.Address) ? String(object.Address) : '',
    };
  },

  toJSON(message: Address): unknown {
    const obj: any = {};
    message.Region !== undefined && (obj.Region = message.Region);
    message.City !== undefined && (obj.City = message.City);
    message.ZIP !== undefined && (obj.ZIP = message.ZIP);
    message.Address !== undefined && (obj.Address = message.Address);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Address>, I>>(object: I): Address {
    const message = createBaseAddress();
    message.Region = object.Region ?? '';
    message.City = object.City ?? '';
    message.ZIP = object.ZIP ?? '';
    message.Address = object.Address ?? '';
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
