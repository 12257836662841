import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { sort } from 'ramda';

import { OrganizationJobPosition } from '@/shared/api/protocol_gen/model/dto_organization';

import { sortSpecializationOptions } from '@/entities/organization/lib/sortSpecializationOptions';

import * as i18n from '../config/i18n';

export const useSpecializationsOptions = () => {
  const { formatMessage } = useIntl();

  const specializationOptions = useMemo(
    () => [
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionChiefDentalOfficer
          ],
        ),
        value:
          OrganizationJobPosition.OrganizationJobPositionChiefDentalOfficer,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionExecutiveDirector
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionExecutiveDirector,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionAdministrator
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionAdministrator,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionTreatmentCoordinator
          ],
        ),
        value:
          OrganizationJobPosition.OrganizationJobPositionTreatmentCoordinator,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionDentalAssistant
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionDentalAssistant,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionDentalTechnician
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionDentalTechnician,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionGeneralDentist
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionGeneralDentist,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionEndodontist
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionEndodontist,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionPeriodontist
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionPeriodontist,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionProsthodontist
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionProsthodontist,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionOrthodontist
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionOrthodontist,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionPediatricDentist
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionPediatricDentist,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition
              .OrganizationJobPositionOralMedicineSpecialist
          ],
        ),
        value:
          OrganizationJobPosition.OrganizationJobPositionOralMedicineSpecialist,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionOralSurgeon
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionOralSurgeon,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition
              .OrganizationJobPositionOralAndMaxillofacialSurgeon
          ],
        ),
        value:
          OrganizationJobPosition.OrganizationJobPositionOralAndMaxillofacialSurgeon,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition
              .OrganizationJobPositionOralAndMaxillofacialRadiologist
          ],
        ),
        value:
          OrganizationJobPosition.OrganizationJobPositionOralAndMaxillofacialRadiologist,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionGeneralRadiologist
          ],
        ),
        value:
          OrganizationJobPosition.OrganizationJobPositionGeneralRadiologist,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionImplantologist
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionImplantologist,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionAestheticDentist
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionAestheticDentist,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionDenturist
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionDenturist,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionDentalHygienist
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionDentalHygienist,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionDentalTherapist
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionDentalTherapist,
      },
      {
        label: formatMessage(
          i18n.specialityType[
            OrganizationJobPosition.OrganizationJobPositionOther
          ],
        ),
        value: OrganizationJobPosition.OrganizationJobPositionOther,
      },
    ],
    [formatMessage],
  );

  return sort(sortSpecializationOptions, specializationOptions);
};
