export const scrollToElementWithParent = (
  ID: string,
  offset: number,
  scrollBehavior: ScrollBehavior = 'smooth',
) => {
  const element = document.getElementById(ID);
  const topPosition = element?.offsetTop;

  element?.parentElement.scrollTo({
    behavior: scrollBehavior,
    top: topPosition - offset,
  });
};
