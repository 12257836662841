import { FormattedMessage } from 'react-intl';

import {
  OrganizationJobPosition,
  OrganizationUserRoleType,
} from '@/shared/api/protocol_gen/model/dto_organization';
import { object, string, number, array } from '@/shared/config/yup';

export type StaffFormPayload = {
  firstName: string;
  lastName: string;
  email: string;
  accessLevel: OrganizationUserRoleType[];
  position: OrganizationJobPosition[];
};

export const getValidationSchema = (validateEmail: boolean) =>
  object({
    firstName: string().required(),
    lastName: string().required(),
    email: validateEmail ? string().email().required() : string().required(),
    accessLevel: array()
      .of(number())
      .required(() => (
        <FormattedMessage
          id="addStaffModal.accessLevelError"
          defaultMessage="accessLevel field must have at least 1 items"
        />
      ))
      .min(1)
      .nullable(),
    position: array()
      .of(number())
      .required(() => (
        <FormattedMessage
          id="addStaffModal.jobPositionError"
          defaultMessage="position field must have at least 1 items"
        />
      ))
      .min(1)
      .nullable(),
  });
