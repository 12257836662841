import { FC, useEffect, useRef } from 'react';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { FMXNavigationMode, reportsModel } from '@/entities/reports';

import { SubImageInterface } from '@/features/IOXRayMatrix';

import { ReportToothChart } from '@/widgets/ReportToothChart';
import { IOXRayMatrix } from '@/widgets/IOXRayMatrix';

import styles from './IOXRayNavigation.module.scss';

type IOXRAYNavigationProps = {
  className?: string;
  subImages: SubImageInterface[];
  reportID: string;
  activeSubImages?: SubImageInterface[];
  onSubImageClick?: (subImage: SubImageInterface) => void;
  onToothClick: (ID: string) => void;
};

export const IOXRAYNavigation: FC<IOXRAYNavigationProps> = (props) => {
  const {
    className,
    subImages,
    reportID,
    onSubImageClick,
    activeSubImages,
    onToothClick,
  } = props;

  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );

  const isToothChartMode = navigationMode === FMXNavigationMode.ToothChart;

  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (containerRef.current) {
      dispatch(
        reportsModel.actions.setToothChartHeight(
          containerRef.current.offsetHeight,
        ),
      );
    }
  }, [dispatch, isToothChartMode]);

  return (
    <div className={className}>
      <div className={styles.container} ref={containerRef}>
        {navigationMode === FMXNavigationMode.ToothChart ? (
          <ReportToothChart
            className={styles.teethChart}
            reportID={reportID}
            onToothClick={onToothClick}
            showActions
          />
        ) : (
          <IOXRayMatrix
            className={cn(styles.ioxrayMatrix)}
            subImages={subImages}
            reportID={reportID}
            showMasks={false}
            onSubImageClick={onSubImageClick}
            activeSubImages={activeSubImages}
            containerSizes={{
              width: containerRef.current?.clientWidth,
              height: containerRef.current?.clientHeight,
            }}
            isPreviewMode
          />
        )}
      </div>
    </div>
  );
};
