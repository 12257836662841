import { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { DropdownSelect } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';

import { userModel } from '@/entities/user';

import { useOrganizationUsersOptions } from '../hooks/useOrganizationUsersOptions';

type DoctorsDropdownFilterProps = {
  onChange: (doctorsIDs: string[]) => void;
};

// TODO: add updating search params and connect with patient list stream reopening
export const DoctorsDropdownFilter: FC<DoctorsDropdownFilterProps> = (
  props,
) => {
  const { onChange } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const { formatMessage } = useIntl();

  const doctorIDs = searchParams.get('doctorIDs')?.split(',') || [];

  const [doctors, setDoctors] = useState<string[]>(doctorIDs);

  const usersOptions = useOrganizationUsersOptions();

  const user = useAppSelector(userModel.selectors.selectCurrentUser);

  const multiValuesCount = useMemo(
    () => (doctors.includes('all') ? doctors.length - 1 : doctors.length),
    [doctors],
  );

  const handleChange = (value: string | string[]) => {
    if (typeof value === 'string') return;

    if (value.includes('all') && !doctors.includes('all')) {
      // check all
      setDoctors(usersOptions.map((option) => option.value));
    } else if (doctors.includes('all') && !value.includes('all')) {
      // uncheck all without current
      if (user) {
        setDoctors([user?.ID]);
      }
    } else {
      setDoctors(value);
    }
  };

  const handleApply = (filters: string | string[]) => {
    if (typeof filters === 'string') return;

    if (filters.includes('all')) {
      searchParams.set('doctorIDs', '');
      setSearchParams([]);
      onChange([]);
    } else {
      const withoutAllKey = filters.filter((item) => item !== 'all');
      onChange(withoutAllKey);
      searchParams.set('doctorIDs', withoutAllKey.join(','));
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    if (doctors.length && doctors.length === usersOptions.length - 1) {
      if (doctors.includes('all')) {
        setDoctors(doctors.filter((doc) => doc !== 'all'));
      } else {
        setDoctors([...doctors, 'all']);
      }
    }
  }, [doctors]);

  if (usersOptions.length === 1) return null;

  return (
    <DropdownSelect
      isMulti
      value={doctors}
      label={formatMessage({
        id: 'patientListFilters.doctorSelectLabel',
        defaultMessage: 'Doctors',
      })}
      options={usersOptions}
      onChange={handleChange}
      onApply={handleApply}
      multiValuesCount={multiValuesCount}
    />
  );
};
