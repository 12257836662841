import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Button } from '@/shared/ui';

import { toothModel } from '@/entities/tooth';

export const ToothChartCustomModeToggle = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const customMode = useAppSelector(
    toothModel.selectors.selectToothChartCustomMode,
  );
  const updatigROI = useAppSelector(toothModel.selectors.selectUpdatingROI);

  const toggleCustomMode = () => {
    if (customMode) {
      dispatch(toothModel.thunks.updateCurrentReportROI()).then(() => {
        dispatch(toothModel.actions.setToothChartCustomMode(!customMode));
      });
      return;
    }

    dispatch(toothModel.actions.setToothChartCustomMode(!customMode));
  };

  const buttonText = customMode
    ? formatMessage({
        id: 'toothChart.customModeEnabled',
        defaultMessage: 'Ok',
      })
    : formatMessage({
        id: 'toothChart.customModeDisabled',
        defaultMessage: 'Custom',
      });

  return (
    <Button
      variant="tertiary"
      size="small"
      icon={customMode ? undefined : 'pen'}
      loading={updatigROI}
      onClick={toggleCustomMode}
    >
      {buttonText}
    </Button>
  );
};
