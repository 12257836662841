// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DoctorsAvatars-module__container--3UJUk3yi{display:flex;align-items:center;gap:8px}.DoctorsAvatars-module__fullName--wkFbjmdg{overflow-wrap:anywhere}", "",{"version":3,"sources":["webpack://./src/entities/doctors/ui/DoctorsAvatars/DoctorsAvatars.module.scss"],"names":[],"mappings":"AAAA,4CACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,2CACE,sBAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.fullName {\n  overflow-wrap: anywhere;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DoctorsAvatars-module__container--3UJUk3yi",
	"fullName": "DoctorsAvatars-module__fullName--wkFbjmdg"
};
export default ___CSS_LOADER_EXPORT___;
