import { FC } from 'react';

import { Localization } from '@/shared/api/protocol_gen/model/dto_report_localization';
import { useAppSelector } from '@/shared/hooks';

import { logicalConditionModel } from '@/entities/logicalCondition';

import { makeCroppedMedicalImagesFromLocalizations } from '../../lib/makeCroppedMedicalImagesFromLocalizations';
import { IOXRayToothImageDeprecated } from '../IOXRayToothImage/IOXRayToothImageDeprecated';

type IOXRayToothImagesProps = {
  toothLocalizations: Localization[];
  toothID?: string;
};

export const IOXRayToothImagesList: FC<IOXRayToothImagesProps> = (props) => {
  const { toothLocalizations, toothID } = props;
  // TODO: Refactor for cropped tooth images
  const croppedImages =
    makeCroppedMedicalImagesFromLocalizations(toothLocalizations);

  const { code, parentID } = useAppSelector(
    logicalConditionModel.selectors.selectHoveredConditionLink,
  );

  const hoveredCondition = useAppSelector(
    logicalConditionModel.selectors.selectCondition({
      toothID,
      code,
      parentID,
    }),
  );

  return (
    <>
      {toothLocalizations.map((localization, index) => (
        <IOXRayToothImageDeprecated
          key={localization.ID}
          croppedImage={croppedImages[index]}
          toothLocalization={localization}
          hoveredCondition={
            hoveredCondition?.Tooth.ToothID === toothID && hoveredCondition
          }
        />
      ))}
    </>
  );
};
