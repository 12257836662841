import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import { OutputSTLModels, outputSTLModelTypes } from '../config/i18n';

export const useGetOutputSTLModelsOptions = () => {
  const { formatMessage } = useIntl();

  const outputSTLModels = useMemo(
    () => [
      {
        label: formatMessage(
          outputSTLModelTypes[OutputSTLModels.AllAnatomiesInSeparateFiles],
        ),
        value: OutputSTLModels.AllAnatomiesInSeparateFiles,
      },
      {
        label: formatMessage(
          outputSTLModelTypes[
            OutputSTLModels.AllAnatomiesWithFusedTeethInOneFile
          ],
        ),
        value: OutputSTLModels.AllAnatomiesWithFusedTeethInOneFile,
      },
      {
        label: formatMessage(
          outputSTLModelTypes[
            OutputSTLModels.AllAnatomiesWithFusedTeethInSeparateFiles
          ],
        ),
        value: OutputSTLModels.AllAnatomiesWithFusedTeethInSeparateFiles,
      },
      {
        label: formatMessage(outputSTLModelTypes[OutputSTLModels.Custom]),
        value: OutputSTLModels.Custom,
      },
    ],
    [formatMessage],
  );

  return outputSTLModels;
};
