import { FormattedMessage } from 'react-intl';
import { boolean, InferType, number, object, string, ref } from 'yup';

import { PASSWORD_REGEX } from '@/shared/config';

export const inviteSignUpFormSchema = object({
  firstName: string().required(),
  lastName: string().required(),
  password: string()
    .required()
    .test('password', (value) => PASSWORD_REGEX.test(value || '')),
  confirmPassword: string()
    .oneOf([ref('password')], () => (
      <FormattedMessage
        id="signUp.passwordsMustMatch"
        defaultMessage="Passwords must match"
      />
    ))
    .required(),
  language: number().required(),
  termsAndConditions: boolean().required(),
  privacyPolicy: boolean().required(),
  marketingCommunicationConsent: boolean(),
});

export type InviteSignUpFormPayload = InferType<typeof inviteSignUpFormSchema>;
