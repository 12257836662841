import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { FormattedDate } from 'react-intl/lib';

import { Report } from '@/shared/api/protocol_gen/model/dto_report';
import { Icon } from '@/shared/ui';
import { useAppSelector, useMedia } from '@/shared/hooks';

import { getDisplayToothNumber, toothModel } from '@/entities/tooth';
import { getIsAllTeethInROI, getReportSignStatus } from '@/entities/reports';
import { organizationModel } from '@/entities/organization';

import { ReportGeneratingProgress } from '@/features/reportRow/ui/ReportGeneratingProgress/ReportGeneratingProgress';

import { RoiColumn } from '../RoiColumn/RoiColumn';

import styles from './ReportRow.module.scss';

type ReportRowProps = {
  report: Report;
  onClick: () => void;
  className?: string;
  testID?: string;
  onDelete?: () => void;
  onDownload?: () => void;
  onCopy?: (reportID: string) => void;
};

export const ReportRow: FC<ReportRowProps> = (props) => {
  const { report, className, testID, onClick, onDelete, onDownload, onCopy } =
    props;

  const { isPhone } = useMedia();

  const organization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );

  const size = isPhone ? 24 : 32;

  const reportID = report.ID;

  const ROI = useAppSelector(
    toothModel.selectors.selectISONumbersByReportIDAndROI(reportID),
  );

  const reportTeeth = useAppSelector(
    toothModel.selectors.selectByReportID(reportID),
  );

  const displayROI = ROI.map((ISOToothNumber) =>
    getDisplayToothNumber(
      ISOToothNumber,
      organization.Settings?.DentalNotationFormat,
    ),
  );

  const isAllTeethInROI = getIsAllTeethInROI(reportTeeth);

  const signStatus = getReportSignStatus(report);

  const reportCreatedAtDate = report?.Created?.At ?? new Date();

  const handleDownloadReport = () => {
    if (onDownload) {
      onDownload();
    }
  };

  return !report?.Status.Completed ? (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <div className={cn(styles.link)}>
        <div className={styles.linkWrapper}>
          <div className={cn(styles.column)}>
            <span className={cn(styles.name, 'p3')}>
              <FormattedMessage
                id="reportRow.createdAt"
                defaultMessage="Creation date"
              />
            </span>

            <p className={cn(styles.date, styles.disabled, 'p2')}>
              <FormattedDate value={reportCreatedAtDate} dateStyle="medium" />
            </p>
          </div>

          {!isPhone && (
            <div className={cn(styles.column)}>
              <span className={cn(styles.name, 'p3')}>
                <FormattedMessage
                  id="reportRow.roi"
                  defaultMessage="Region of interest"
                />
              </span>
              <RoiColumn
                className={styles.disabled}
                roi={displayROI}
                isAllTeethInROI
              />
            </div>
          )}

          <ReportGeneratingProgress report={report} />
        </div>
      </div>

      <div
        className={cn(styles.controls)}
        style={{ minWidth: isPhone ? '60px' : '78px' }}
      >
        {report?.Status.Failed && (
          <button
            className={cn(styles.button, styles.deleteButton)}
            type="button"
            onClick={onDelete}
          >
            <Icon name="delete" size={size} />
          </button>
        )}
      </div>
    </div>
  ) : (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <div className={cn(styles.link)} onClick={onClick}>
        <div
          className={cn(
            styles.linkWrapper,
            signStatus === 'withoutSign' && styles.gridWithoutSign,
          )}
        >
          <div className={cn(styles.column)}>
            <span className={cn(styles.name, 'p3')}>
              <FormattedMessage
                id="reportRow.createdAt"
                defaultMessage="Creation date"
              />
            </span>

            <p className={cn(styles.date, 'p2')}>
              <FormattedDate value={reportCreatedAtDate} dateStyle="medium" />
            </p>
          </div>

          {!isPhone && (
            <div className={cn(styles.column)}>
              <span className={cn(styles.name, 'p3')}>
                <FormattedMessage
                  id="reportRow.roi"
                  defaultMessage="Region of interest"
                />
              </span>
              <RoiColumn roi={displayROI} isAllTeethInROI={isAllTeethInROI} />
            </div>
          )}

          {signStatus !== 'withoutSign' && (
            <div className={cn(styles.column)}>
              <span className={cn(styles.name, 'p3')}>
                <FormattedMessage
                  id="reportRow.status"
                  defaultMessage="Status"
                />
              </span>

              {signStatus === 'signed' && (
                <p className={cn(styles.info, styles.signed, 'p2')}>
                  <FormattedMessage
                    id="reportRow.signed"
                    defaultMessage="Signed"
                  />
                </p>
              )}

              {signStatus === 'notSigned' && (
                <p className={cn(styles.info, styles.notSigned, 'p2')}>
                  <FormattedMessage
                    id="reportRow.notSigned"
                    defaultMessage="Not signed"
                  />
                </p>
              )}
            </div>
          )}
        </div>
      </div>

      <div className={cn(styles.controls)}>
        {signStatus === 'signed' && (
          <button
            className={cn(styles.button, styles.downloadButton)}
            onClick={() => {
              if (onCopy) {
                onCopy(reportID);
              }
            }}
            type="button"
          >
            <Icon name="copy" size={size} />
          </button>
        )}

        <button
          className={cn(styles.button, styles.downloadButton)}
          onClick={handleDownloadReport}
          type="button"
        >
          <Icon name="download" size={size} />
        </button>

        <button
          className={cn(styles.button, styles.deleteButton)}
          type="button"
          onClick={onDelete}
        >
          <Icon name="delete" size={size} />
        </button>
      </div>
    </div>
  );
};
