import { useIntl } from 'react-intl';

import { useAppDispatch } from '@/shared/hooks';
import { ToastErrors, toastErrors } from '@/shared/config';
import { toastCaller } from '@/shared/ui';

import { reportsModel } from '@/entities/reports';

export const useRemoveReport = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  return async (reportID: string) => {
    try {
      const { DeletedReportID } = await dispatch(
        reportsModel.thunks.deleteReport({ ReportID: reportID }),
      ).unwrap();

      dispatch(reportsModel.actions.removeOne(DeletedReportID));
    } catch (error) {
      const parsedMessage = JSON.parse(error?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };
};
