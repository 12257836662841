// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Packages-module__container--2uISSR7A{padding:24px;background-color:var(--bg4);border-radius:16px}.Packages-module__header--J\\+AzCQJH{padding-bottom:8px;margin-bottom:24px;border-bottom:1px solid var(--mainbg)}", "",{"version":3,"sources":["webpack://./src/widgets/CurrentSubscription/ui/Packages/Packages.module.scss"],"names":[],"mappings":"AAAA,sCACE,YAAA,CACA,2BAAA,CACA,kBAAA,CAGF,oCACE,kBAAA,CACA,kBAAA,CACA,qCAAA","sourcesContent":[".container {\n  padding: 24px;\n  background-color: var(--bg4);\n  border-radius: 16px;\n}\n\n.header {\n  padding-bottom: 8px;\n  margin-bottom: 24px;\n  border-bottom: 1px solid var(--mainbg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Packages-module__container--2uISSR7A",
	"header": "Packages-module__header--J+AzCQJH"
};
export default ___CSS_LOADER_EXPORT___;
