import { MedicalImageInterface } from '@/shared/config';

import { SubImageInterface } from '@/widgets/IOXRayMatrix';

import { isBiteWing } from './isBiteWing';

type SortedCroppedImagesByPosition = {
  left: MedicalImageInterface[];
  right: MedicalImageInterface[];
};
export const sortToothImages = (
  croppedImages: MedicalImageInterface[],
  biteWingSubImages: SubImageInterface[],
): SortedCroppedImagesByPosition =>
  croppedImages.reduce(
    (sortedImages, image) => {
      if (isBiteWing(biteWingSubImages, image)) {
        sortedImages.right.push(image);
      } else {
        sortedImages.left.push(image);
      }

      return sortedImages;
    },
    { left: [], right: [] },
  );
