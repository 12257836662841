import { InferType, object, number, string, array } from '@/shared/config/yup';

import { SegmentronModelType } from '@/widgets/Order3DSegmentronModal/config/i18n';

export const order3DSegmentronFormSchema = object({
  segmentronModelType: string()
    .oneOf([
      SegmentronModelType.BasicCBCTToSTL,
      SegmentronModelType.AdvancedCBCTAndIOS,
      SegmentronModelType.AdvancedCBCTAndDental,
    ])
    .required(),
  outputFilesForSegmentation: number(),
  cbct: string().required(),
  ios: string(),
  outputSTL: array().of(number()),
  outputSTLPreset: string(),
  orientation: number(),
  dentalPhoto: string(),
});

export type Order3DSegmentronFormSchemaFormPayload = InferType<
  typeof order3DSegmentronFormSchema
>;
