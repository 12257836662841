import { useMemo, useState } from 'react';

import { ToothStatus } from '@/shared/config';

import { JAW } from '@/entities/tooth';

import { ToothInterface } from '@/features/toothChart';

export const useMakeTeethItems = (
  onActiveTeethChangeHandler?: (isoNumbers: number[]) => void,
) => {
  const teethIsoNumbers = [
    ...JAW.quartile1,
    ...JAW.quartile2,
    ...JAW.quartile3,
    ...JAW.quartile4,
  ];

  const emptyTeeth: ToothInterface[] = teethIsoNumbers.map(
    (toothISONumber) => ({
      id: toothISONumber.toString(),
      ISONumber: toothISONumber,
      toothNumberView: toothISONumber,
      status: ToothStatus.healthy,
      isActive: true,
      isMissingThirdMolar: true,
      gridArea: `t${toothISONumber}`,
    }),
  );

  const [teethItems, setTeethItems] = useState(emptyTeeth);

  const changeToothState = (isoNumber: number) => {
    setTeethItems((prevState) =>
      prevState.map((item) => {
        if (item.ISONumber === isoNumber) {
          return { ...item, isActive: !item.isActive };
        }
        return item;
      }),
    );
  };

  const changeTeethState = (isoNumbers: number[], status: boolean = false) => {
    const updatedTeeth = teethItems.map((item) => {
      if (isoNumbers.includes(item.ISONumber)) {
        return {
          ...item,
          isActive: status,
        };
      }

      return item;
    });
    setTeethItems(updatedTeeth);
  };

  const activeTeeth = useMemo(() => {
    const activeISONumbers = teethItems.reduce((teeth, tooth) => {
      if (tooth.isActive) {
        return [...teeth, tooth.ISONumber];
      }

      return teeth;
    }, [] as number[]);

    if (typeof onActiveTeethChangeHandler === 'function') {
      onActiveTeethChangeHandler(activeISONumbers);
    }

    return activeISONumbers;
  }, [teethItems]);

  return { teethItems, changeToothState, changeTeethState, activeTeeth };
};
