import { createSelector } from '@reduxjs/toolkit';
import { compose, uniq } from 'ramda';

import { RootState } from '@/app/model/store';

import {
  filterTeethByPatientID,
  filterTeethByReportID,
  filterTeethByReportIDAndROI,
  findTeethByReportID,
  mapTeethToISONumbers,
} from '../lib';

import { toothAdapter } from './toothSlice';

const toothSelectors = toothAdapter.getSelectors(
  (state: RootState) => state.tooth,
);

export const { selectById, selectEntities, selectIds } = toothSelectors;

export const selectToothByID = (toothID: string) => (state: RootState) =>
  selectById(state, toothID);

// IMPORTANT!!!
// TOOTH 404 USED FOR DELETED DETECTIONS
export const selectAll = createSelector(toothSelectors.selectAll, (teeth) =>
  teeth.filter((tooth) => tooth?.Numeration?.ISO !== 404),
);

export const selectAllIds = createSelector(selectAll, (teeth) =>
  teeth.map((tooth) => tooth.ID),
);

export const selectAllDeleted = (state: RootState) =>
  toothSelectors
    .selectAll(state)
    .filter((tooth) => tooth?.Numeration?.ISO === 404);

// Do we need select teeth by patient ID?
export const selectByPatientID = (patientID: string) =>
  createSelector(selectAll, (teeth) =>
    filterTeethByPatientID(teeth, patientID),
  );

export const selectISONumbers = createSelector(selectAll, (teeth) =>
  compose(uniq, mapTeethToISONumbers)(teeth),
);

export const selectByReportID = (reportID: string) =>
  createSelector(selectAll, (teeth) => filterTeethByReportID(teeth, reportID));

export const selectDeletedByReportID = (reportID: string) =>
  createSelector(selectAllDeleted, (teeth) =>
    findTeethByReportID(teeth, reportID),
  );

export const selectByReportIDAndROI = (reportID: string) =>
  createSelector(selectAll, (teeth) =>
    filterTeethByReportIDAndROI(teeth, reportID),
  );

export const selectROITeeth = createSelector(selectAll, (teeth) =>
  teeth.filter((tooth) => tooth.IsInROI),
);

export const selectISONumbersByReportIDAndROI = (reportID: string) =>
  createSelector(selectAll, (teeth) =>
    compose(mapTeethToISONumbers, filterTeethByReportIDAndROI)(teeth, reportID),
  );

export const selectLocalROITeethISONumbers = (state: RootState) =>
  state.tooth.localROITeethISONumbers;

export const selectLocalROITeethIDs = (state: RootState) =>
  state.tooth.localROITeethIDs;

export const selectToothChartCustomMode = (state: RootState) =>
  state.tooth.toothChartCustomMode;

export const selectUpdatingROI = (state: RootState) => state.tooth.updatingROI;

export const selectLoading = (state: RootState) => state.tooth.loading;
