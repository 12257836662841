// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResetPassword-module__modalBody--MsiSTsgP{padding:8px 24px 40px}.ResetPassword-module__info--8dM7qHDD{color:var(--grey9);margin-bottom:10px}.ResetPassword-module__inlineError--hJOvHg8T{margin:48px 0 16px}", "",{"version":3,"sources":["webpack://./src/pages/ResetPassword/ResetPassword.module.scss"],"names":[],"mappings":"AAAA,2CACE,qBAAA,CAGF,sCACE,kBAAA,CACA,kBAAA,CAGF,6CACE,kBAAA","sourcesContent":[".modalBody {\n  padding: 8px 24px 40px;\n}\n\n.info {\n  color: var(--grey9);\n  margin-bottom: 10px;\n}\n\n.inlineError {\n  margin: 48px 0 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "ResetPassword-module__modalBody--MsiSTsgP",
	"info": "ResetPassword-module__info--8dM7qHDD",
	"inlineError": "ResetPassword-module__inlineError--hJOvHg8T"
};
export default ___CSS_LOADER_EXPORT___;
