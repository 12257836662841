// TODO: [4|m] Fix TS errors
// @ts-nocheck
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { isEqual } from 'lodash';
import { useIntl } from 'react-intl/lib';

import { Checkbox, Description, Icon, Popup, Switch } from '@/shared/ui';
import { UltimateMedicalImage } from '@/shared/graphics/RenderComponents/Presets/UltimateMedicalImage';
import { RCCropImageWithPTools } from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImageWithTools';
import { Annotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';
import { RCCropImageCrop } from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImage';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MedicalImageInterface } from '@/shared/config';

import { ModalID, modalModel } from '@/entities/modal';
import {
  getImageSrc,
  GPMedicalImageGroupsTypes,
  medicalImageTitles,
} from '@/entities/assets';
import {
  shouldGetWwwcFromImageContext,
  useMedicalImageControls,
} from '@/entities/reports';

import { ImageToolbar } from '@/features/imageToolbar';

import { ProcessingUnitContext } from 'graphics';

import { POPUP_DEFAULT_SIZE, CONTROLS } from '../config/constants';
import { getMedicalImageSize } from '../utils/getMedicalImageSize';
import { MedicalImageSizeType } from '../config/types';

import styles from './ZoomedMedicalImageModal.module.scss';

export type ZoomedMedicalImageModalProps = {
  toggleSelected?: (medicalImage: MedicalImageInterface) => void;
  selectedMedicalImages?: MedicalImageInterface[];
};
// TODO: [4|h] refactor component, rewrite from local state to redux
export const ZoomedMedicalImageModal: FC<ZoomedMedicalImageModalProps> = (
  props,
) => {
  const { toggleSelected, selectedMedicalImages } = props;

  const [size, setSize] = useState<MedicalImageSizeType>({
    width: 0,
    height: 0,
  });

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const {
    visible,
    data: { medicalImage, medicalImageType },
  } = useAppSelector(modalModel.selectors.selectMedicalImage);

  const isTheMedicalImageSelected = useMemo(
    () => selectedMedicalImages?.some((item) => item.src === medicalImage?.src),
    [selectedMedicalImages, medicalImage],
  );

  const {
    activeControl,
    setActiveControl,
    mode,
    setMode,
    annotations,
    viewAnnotations,
    setViewAnnotations,
    sharpness,
    setSharpness,
    currentAnnotation,
    wwwc: currentWwwc,
    setWWWC,
    handleSetAnnotations,
    handleSetMedicalImageViewOptions,
    handleSetCroppedImageAnnotations,
    handleSetCroppedMedicalImageViewOptions,
  } = useMedicalImageControls(
    medicalImage?.src,
    medicalImage?.assetID,
    medicalImage?.viewOptions,
    medicalImage?.annotations,
  );

  const crop = medicalImage?.TargetAssetID
    ? {
        x: medicalImage.BBox?.X?.Min ?? 0,
        y: medicalImage.BBox?.Y?.Min ?? 0,
        w: (medicalImage.BBox?.X?.Max ?? 0) - (medicalImage.BBox?.X?.Min ?? 0),
        h: (medicalImage.BBox?.Y?.Max ?? 0) - (medicalImage.BBox?.Y?.Min ?? 0),
      }
    : ({} as RCCropImageCrop);

  const onImageLoaded = (newDicom: ProcessingUnitContext) => {
    const imageWidth = medicalImage?.TargetAssetID ? crop?.w : newDicom.width;
    const imageHeight = medicalImage?.TargetAssetID ? crop?.h : newDicom.height;

    const imageSize = getMedicalImageSize(imageWidth, imageHeight);

    if (shouldGetWwwcFromImageContext(medicalImage.viewOptions)) {
      setWWWC({
        ww: newDicom.sourceWindowWidth,
        wc: newDicom.sourceWindowCenter,
      });
    }

    setSize(imageSize);
  };

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.ZoomedMedicalImage));
  }, [dispatch]);

  const handleClosePopup = () => {
    if (currentWwwc) {
      const updatedViewOptions = {
        wwwc: {
          ww: currentWwwc.ww,
          wc: currentWwwc.wc,
        },
        sharpness,
        invert: false,
      };

      if (
        !isEqual(updatedViewOptions, medicalImage?.viewOptions) &&
        medicalImageType === 'cropped'
      ) {
        handleSetCroppedMedicalImageViewOptions(
          medicalImage?.localizationID ?? '',
          {
            WindowWidth: currentWwwc?.ww,
            WindowLevel: currentWwwc?.wc,
            Sharpness: sharpness,
            Invert: false,
            ColorMap: undefined,
            ViewportCoords: undefined,
          },
        );

        return;
      }
      if (!isEqual(updatedViewOptions, medicalImage?.viewOptions)) {
        handleSetMedicalImageViewOptions({
          WindowWidth: currentWwwc?.ww,
          WindowLevel: currentWwwc?.wc,
          Sharpness: sharpness,
        });
      }
    }
    setActiveControl('');
    setMode('view');
    setSize({ width: POPUP_DEFAULT_SIZE, height: POPUP_DEFAULT_SIZE });
    setWWWC({ wc: 0, ww: 0 });
    setSharpness(0);
    handleClose();
  };

  const tools = medicalImage?.TargetAssetID
    ? CONTROLS.filter((control) => control !== 'ruler')
    : CONTROLS;

  const handleShowHideAnnotations = () => {
    setViewAnnotations(!viewAnnotations);
  };

  const indexInSelected = selectedMedicalImages?.length
    ? selectedMedicalImages.findIndex(
        (item) => item.src === medicalImage?.src,
      ) + 1
    : 0;

  const groupTitle = medicalImage?.groupName
    ? formatMessage(
        medicalImageTitles[medicalImage.groupName as GPMedicalImageGroupsTypes],
      )
    : null;

  const onChangeCroppedMedicalImageAnnotations = (
    changedAnnotations: Annotation[],
  ) => {
    handleSetCroppedImageAnnotations(
      medicalImage?.localizationID ?? '',
      changedAnnotations,
    );
  };

  return (
    <Popup isOpen={visible} onClose={handleClosePopup} className={styles.popup}>
      {groupTitle && <h1 className={styles.popupTitle}>{groupTitle}</h1>}

      <div className={styles.thicknessAndStride}>
        {medicalImage?.strideMm && (
          <Description
            label={
              <FormattedMessage
                id="report.slice_step"
                defaultMessage="Slice step"
              />
            }
          >
            {medicalImage.strideMm}
            <FormattedMessage id="global.mm" defaultMessage="mm" />,
          </Description>
        )}
        {medicalImage?.thicknessMm && (
          <Description
            className={styles.thicknessDescription}
            label={
              <FormattedMessage
                id="report.slice_thickness"
                defaultMessage="Slice thickness"
              />
            }
          >
            {medicalImage.thicknessMm}
            <FormattedMessage id="global.mm" defaultMessage="mm" />
          </Description>
        )}
      </div>
      <div className={styles.resizableContentWrapper}>
        <div>
          <div className={styles.controls}>
            <div className={styles.imageContainer}>
              {medicalImage?.TargetAssetID ? (
                <div
                  style={{ width: size.width, height: size.height }}
                  className={cn(styles.cropContainer)}
                >
                  <RCCropImageWithPTools
                    mode={mode}
                    currentAnnotation={currentAnnotation}
                    annotations={viewAnnotations ? annotations : []}
                    onAnnotationsChanged={
                      onChangeCroppedMedicalImageAnnotations
                    }
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                    onLoaded={onImageLoaded}
                    onWWWCChanged={(wwwc) => setWWWC(wwwc)}
                    ww={currentWwwc?.ww}
                    wc={currentWwwc?.wc}
                    sharpness={sharpness}
                    inverted={medicalImage?.viewOptions?.invert}
                    onSharpnessChanged={(currentSharpness) =>
                      setSharpness(currentSharpness)
                    }
                    src={{
                      url: getImageSrc(medicalImage.TargetAssetID, 'original'),
                      kind: 'raster',
                    }}
                    crop={crop}
                  />
                </div>
              ) : (
                <UltimateMedicalImage
                  src={{ url: medicalImage?.src, kind: 'dicom' }}
                  mode={mode}
                  currentAnnotation={currentAnnotation}
                  annotations={viewAnnotations ? annotations : []}
                  onAnnotationsChanged={handleSetAnnotations}
                  style={{
                    width: size.width,
                    height: size.height,
                    objectFit: 'contain',
                  }}
                  onLoaded={onImageLoaded}
                  onWWWCChanged={(wwwc) => setWWWC(wwwc)}
                  ww={currentWwwc?.ww}
                  wc={currentWwwc?.wc}
                  sharpness={sharpness}
                  inverted={medicalImage?.viewOptions?.invert}
                  onSharpnessChanged={(currentSharpness) =>
                    setSharpness(currentSharpness)
                  }
                />
              )}
              {!!indexInSelected && (
                <div className={styles.selectedIcon}>
                  <Icon name="check" size={24} />
                  <span className={styles.medicalImageSelectedOrderText}>
                    {indexInSelected}
                  </span>
                </div>
              )}
              <div className={styles.orientWrapper}>
                <div className={styles.orientContainer}>
                  <span className={styles.medicalImageText}>
                    {medicalImage?.leftSideOrient}
                  </span>
                  <span className={styles.medicalImageText}>
                    {medicalImage?.rightSideOrient}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.actions}>
            {toggleSelected && (
              <Checkbox
                checked={isTheMedicalImageSelected}
                onChange={() => toggleSelected(medicalImage)}
              >
                <FormattedMessage
                  id="medicalImages.Selected"
                  defaultMessage="Selected"
                />
              </Checkbox>
            )}

            {!!annotations.length && (
              <Switch
                checked={!viewAnnotations}
                onChange={handleShowHideAnnotations}
                className={styles.switch}
              >
                <FormattedMessage
                  id="medicalImages.hideMarks"
                  defaultMessage="Hide marks"
                />
              </Switch>
            )}
          </div>
        </div>
        <ImageToolbar
          controls={tools}
          activeControl={activeControl}
          setActiveControl={setActiveControl}
          className={styles.toolbar}
          tooltipPosition="right"
        />
      </div>
    </Popup>
  );
};
