import { FC, memo, useCallback, useState } from 'react';
import cn from 'classnames';

import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import {
  DEFAULT_MEDICAL_IMAGE_HEIGHT,
  MedicalImageInterface,
} from '@/shared/config';
import { useAppDispatch } from '@/shared/hooks';
import { RCCropImageCrop } from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImage';
import { AbsoluteBlock } from '@/shared/graphics/RenderComponents/AbsoluteBlock';
import { RCContainer } from '@/shared/graphics/RenderComponents/RCContainer/RCContainer';
import { Skeleton } from '@/shared/ui';
import { RCCropImageWithPTools } from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImageWithTools';
import {
  Localization,
  RCLocalizations,
} from '@/shared/graphics/RenderComponents/RCLocalizations/RCLocalizations';
import { BBox } from '@/shared/api/protocol_gen/model/dto_common_geometry';

import { ModalID, modalModel } from '@/entities/modal';
import { transformBBoxToCrop } from '@/entities/tooth';
import { getImageSrc, increaseImageWidth } from '@/entities/assets';

import { ContextRequest } from 'graphics';

import styles from './CroppedMedicalImage.module.scss';

type CroppedMedicalImageProps = {
  croppedImage: MedicalImageInterface;
  className?: string;
  reportType?: ReportType;
  localizations?: Localization[];
};

export const CroppedMedicalImage: FC<CroppedMedicalImageProps> = memo(
  (props) => {
    const { croppedImage, className, localizations } = props;

    const { BBox: croppedImageBBox, TargetAssetID } = croppedImage;

    const crop: RCCropImageCrop = transformBBoxToCrop(croppedImageBBox as BBox);

    const [size, setSize] = useState({
      width: 80,
      height: DEFAULT_MEDICAL_IMAGE_HEIGHT,
    });
    const [isImageReady, setIsImageReady] = useState(false);

    const dispatch = useAppDispatch();

    const handleOpenModal = useCallback(
      (medicalImage: MedicalImageInterface) => {
        dispatch(
          modalModel.actions.openModal({
            modalID: ModalID.ZoomedMedicalImage,
            data: { medicalImage, medicalImageType: 'cropped' },
          }),
        );
      },
      [dispatch],
    );

    const subImageCrop: RCCropImageCrop = transformBBoxToCrop(croppedImageBBox);

    const onDicomLoaded = () => {
      setSize({
        width: increaseImageWidth({
          height: subImageCrop.h,
          width: subImageCrop.w,
          newHeight: DEFAULT_MEDICAL_IMAGE_HEIGHT,
        }),
        height: DEFAULT_MEDICAL_IMAGE_HEIGHT,
      });
      setIsImageReady(true);
    };

    const cropSrc: ContextRequest = {
      url: getImageSrc(TargetAssetID ?? '', 'original'),
      kind: 'raster',
    };

    return (
      <div
        style={{ width: size.width, height: DEFAULT_MEDICAL_IMAGE_HEIGHT }}
        className={cn(styles.container, className)}
        onClick={() => isImageReady && handleOpenModal(croppedImage)}
      >
        <RCContainer
          style={{ width: size.width, height: DEFAULT_MEDICAL_IMAGE_HEIGHT }}
        >
          <AbsoluteBlock>
            {!isImageReady && (
              <Skeleton
                width="80px"
                borderRadius="12px"
                height={`${DEFAULT_MEDICAL_IMAGE_HEIGHT}px`}
                className={styles.panoImageWidgetSkeleton}
              />
            )}

            <RCCropImageWithPTools
              mode="view"
              currentAnnotation="eraser"
              annotations={croppedImage.annotations ?? []}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              onLoaded={onDicomLoaded}
              ww={croppedImage.viewOptions?.wwwc.ww ?? 0}
              wc={croppedImage.viewOptions?.wwwc.wc ?? 0}
              sharpness={croppedImage.viewOptions?.sharpness ?? 0}
              inverted={croppedImage.viewOptions?.invert ?? false}
              src={cropSrc}
              crop={subImageCrop}
            />
          </AbsoluteBlock>

          <AbsoluteBlock style={{ left: 0, top: 0, zIndex: 3 }}>
            <RCLocalizations
              detections={localizations ?? []}
              imageSize={{
                width: crop.w,
                height: crop.h,
              }}
              isNonDentalFindings
              crop={subImageCrop}
            />
          </AbsoluteBlock>
        </RCContainer>
      </div>
    );
  },
);
