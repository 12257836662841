// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Invoices-module__container--kLgLPArw{padding:24px;background-color:var(--bg4);border-radius:16px}.Invoices-module__header--gp6sdSxn{margin-bottom:32px}.Invoices-module__invoiceContainer--gzdLafAR{max-height:453px;overflow-y:scroll;display:flex;flex-direction:column;gap:16px}.Invoices-module__invoiceContainer--gzdLafAR::-webkit-scrollbar{display:none}", "",{"version":3,"sources":["webpack://./src/widgets/Invoices/ui/Invoices.module.scss"],"names":[],"mappings":"AAAA,sCACE,YAAA,CACA,2BAAA,CACA,kBAAA,CAGF,mCACE,kBAAA,CAGF,6CAEE,gBAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,gEACE,YAAA","sourcesContent":[".container {\n  padding: 24px;\n  background-color: var(--bg4);\n  border-radius: 16px;\n}\n\n.header {\n  margin-bottom: 32px;\n}\n\n.invoiceContainer {\n  // Temporary solution while pagination is being designed\n  max-height: 453px;\n  overflow-y: scroll;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Invoices-module__container--kLgLPArw",
	"header": "Invoices-module__header--gp6sdSxn",
	"invoiceContainer": "Invoices-module__invoiceContainer--gzdLafAR"
};
export default ___CSS_LOADER_EXPORT___;
