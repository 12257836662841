// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header-module__container--4nP8IRJ9{grid-area:header;height:76px;display:flex;justify-content:center;background-color:var(--mainbg)}.Header-module__sticky--66u-Eafk{position:sticky;top:0}", "",{"version":3,"sources":["webpack://./src/shared/ui/Layout/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA,oCACE,gBAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,8BAAA,CAGF,iCACE,eAAA,CACA,KAAA","sourcesContent":[".container {\n  grid-area: header;\n  height: 76px;\n  display: flex;\n  justify-content: center;\n  background-color: var(--mainbg);\n}\n\n.sticky {\n  position: sticky;\n  top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Header-module__container--4nP8IRJ9",
	"sticky": "Header-module__sticky--66u-Eafk"
};
export default ___CSS_LOADER_EXPORT___;
