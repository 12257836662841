import { createContext } from 'react';
import cn from 'classnames';

import { ToothChartSize } from '../../config/types';

import styles from './ToothChartRoot.module.scss';

export const ToothChartContext = createContext<ToothChartSize>('default');

type ToothChartRootProps = {
  children: React.ReactNode;
  size?: ToothChartSize;
};

const sizeStyles: Record<ToothChartSize, string> = {
  default: styles.sizeDefault,
  small: styles.sizeSmall,
};

export const ToothChartRoot = (props: ToothChartRootProps) => {
  const { size = 'default', children } = props;

  return (
    <ToothChartContext.Provider value={size}>
      <div className={cn(styles.container, sizeStyles[size])}>{children}</div>
    </ToothChartContext.Provider>
  );
};
