import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  LoadingStateType,
  DefaultInitialState,
  SliceName,
} from '@/shared/config';
import { Invitation } from '@/shared/api/protocol_gen/model/dto_access';

export const accessAdapter = createEntityAdapter<Invitation>({
  selectId: (invitation) => invitation.ID,
});

type AccessSliceInitialState = DefaultInitialState & {
  accessSharedWithMeCount: number;
  accessSharedByMeCount: number;
  accessTotalOrganizationPatientCount: number;
};

const initialState: AccessSliceInitialState = {
  accessSharedWithMeCount: 0,
  accessSharedByMeCount: 0,
  accessTotalOrganizationPatientCount: 0,
  loading: 'idle',
};

const accessSlice = createSlice({
  name: SliceName.access,
  initialState: accessAdapter.getInitialState(initialState),
  reducers: {
    addOne: accessAdapter.addOne,
    addMany: accessAdapter.addMany,
    removeOne: accessAdapter.removeOne,
    removeAll: accessAdapter.removeAll,
    setOne: accessAdapter.setOne,
    setMany: accessAdapter.setMany,
    setNewestOne: (state, action: PayloadAction<Invitation>) => {
      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision.Number;

      if (payloadRevisionNumber > currentRevisionNumber) {
        accessAdapter.setOne(state, action.payload);
      }
    },

    setSharedWithMeCount: (state, action: PayloadAction<number>) => {
      state.accessSharedWithMeCount = action.payload;
    },
    setSharedByMeCount: (state, action: PayloadAction<number>) => {
      state.accessSharedByMeCount = action.payload;
    },
    setTotalOrganizationPatientCount: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.accessTotalOrganizationPatientCount = action.payload;
    },
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
  },
});

export const { actions } = accessSlice;

export default accessSlice.reducer;
