import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';
import { MedicalImageInterface } from '@/shared/config';

import { getFileSrc } from './getFileSrc';
import { makeAnnotations } from './makeAnnotations';
import { makeImageViewOptions } from './makeImageViewOptions';

export const getGroupMedicalImages = (
  groupItems: Asset[],
  groupName: string | number,
) =>
  groupItems.reduce((medicalImages, asset) => {
    const medicalImage = asset.GeneratedReport?.CBCTGPToothSlice;

    if (!medicalImage?.Regular) {
      return medicalImages;
    }

    const annotations = makeAnnotations(
      asset?.GeneratedReport?.MedicalImageFeatures?.Annotations?.Layers,
    );
    const viewOptions = makeImageViewOptions(
      asset?.GeneratedReport?.MedicalImageFeatures?.ViewOptions,
    );

    if (medicalImage?.Slice) {
      medicalImages.push({
        id: medicalImage?.Slice?.ID,
        src: getFileSrc(medicalImage?.Slice.ID),
        image: medicalImage?.Slice.Image,
        assetID: asset.ID,
        thicknessMm: medicalImage?.Regular?.Thickness,
        strideMm: medicalImage?.Regular?.Stride,
        annotations,
        viewOptions,
        groupName,
        order: asset?.GeneratedReport?.CBCTGPToothSlice?.Regular?.Order,
      });
    }

    return medicalImages;
  }, [] as MedicalImageInterface[])
  .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0));
