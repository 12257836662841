// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmptyResult-module__container--Va11DTEE{max-height:396px;border-radius:16px;background:var(--bg2)}.EmptyResult-module__sadCat--CUssInum{width:130px;height:130px}", "",{"version":3,"sources":["webpack://./src/pages/Patients/ui/EmptyResult/EmptyResult.module.scss"],"names":[],"mappings":"AAAA,yCACE,gBAAA,CACA,kBAAA,CACA,qBAAA,CAGF,sCACE,WAAA,CACA,YAAA","sourcesContent":[".container {\n  max-height: 396px;\n  border-radius: 16px;\n  background: var(--bg2);\n}\n\n.sadCat {\n  width: 130px;\n  height: 130px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EmptyResult-module__container--Va11DTEE",
	"sadCat": "EmptyResult-module__sadCat--CUssInum"
};
export default ___CSS_LOADER_EXPORT___;
