import {
  MEDICAL_IMAGE_CONTAINER_MARGINS,
  MEDICAL_IMAGE_HORIZONTAL_PADDING,
  MEDICAL_IMAGE_MAX_SIZE,
  MEDICAL_IMAGE_VERTICAL_PADDING,
} from '../config/constants';
import { MedicalImageSizeType } from '../config/types';

export const getMedicalImageSize = (
  width: number,
  height: number,
): MedicalImageSizeType => {
  // Determine if the image is horizontal (wider than tall)
  const hasHorizontalImage: boolean = width > height;
  // Determine if the screen is horizontal (wider than tall)
  const hasHorizontalScreen: boolean = window.innerWidth > window.innerHeight;

  // Calculate the aspect ratio of the image
  const aspectRatio: number = hasHorizontalImage
    ? height / width
    : width / height;

  // Calculate the medical image height, considering padding and margins from the top and bottom of the screen
  const medicalImageAutoHeight =
    window.innerHeight -
    MEDICAL_IMAGE_VERTICAL_PADDING -
    MEDICAL_IMAGE_CONTAINER_MARGINS;

  // Determine the image height on a horizontal screen, limiting it to MEDICAL_IMAGE_MAX_SIZE if it exceeds that value
  const imageHeightInHorizontalScreen: number =
    medicalImageAutoHeight > MEDICAL_IMAGE_MAX_SIZE
      ? MEDICAL_IMAGE_MAX_SIZE
      : medicalImageAutoHeight;

  // Calculate the image width on a horizontal screen
  const imageWidthInHorizontalScreen: number =
    imageHeightInHorizontalScreen * aspectRatio;

  // Calculate the medical image width, considering padding and margins from the left and right of the screen
  const medicalImageAutoWidth =
    window.innerWidth -
    MEDICAL_IMAGE_HORIZONTAL_PADDING -
    MEDICAL_IMAGE_CONTAINER_MARGINS;

  // Determine the image width on a vertical screen, limiting it to MEDICAL_IMAGE_MAX_SIZE if it exceeds that value
  const imageWidthInVerticalScreen: number =
    medicalImageAutoWidth > MEDICAL_IMAGE_MAX_SIZE
      ? MEDICAL_IMAGE_MAX_SIZE
      : medicalImageAutoWidth;

  // Calculate the image height on a vertical screen
  const imageHeightInVerticalScreen: number =
    imageWidthInVerticalScreen * aspectRatio;

  // Return an object with the width and height of the image, depending on the screen orientation
  return {
    width: hasHorizontalScreen
      ? imageWidthInHorizontalScreen
      : imageWidthInVerticalScreen,
    height: hasHorizontalScreen
      ? imageHeightInHorizontalScreen
      : imageHeightInVerticalScreen,
  };
};
