import { createSelector } from '@reduxjs/toolkit';

import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { mergeConditions } from '@/entities/condition/lib/mergeConditions';

import { RootState } from '@/app/model/store';

import { shouldConditionItemBeShown } from '../../condition/lib/shouldConditionItemBeShown';

import { ToothCondition } from './logicalConditionSlice';

export const selectAll = (state: RootState) =>
  state.logicalCondition.toothConditions;

export const selectToothConditions = (toothID: string) => (state: RootState) =>
  state.logicalCondition.toothConditions[toothID] ||
  ({ conditions: {}, childConditions: {} } as ToothCondition);

// return merged conditions by code
export const selectCondition =
  ({
    toothID,
    code,
    parentID,
  }: {
    toothID: string;
    code: ConditionCode;
    parentID: string;
  }) =>
  (state: RootState) => {
    if (code < 5000) {
      return mergeConditions(
        state.logicalCondition.toothConditions[toothID]?.conditions?.[code],
      );
    }
    return mergeConditions(
      state.logicalCondition.toothConditions[toothID]?.childConditions?.[
        parentID
      ]?.[code],
    );
  };

export const selectChildCondition =
  ({
    toothID,
    parentID,
    code,
  }: {
    toothID: string;
    parentID: string;
    code: ConditionCode;
  }) =>
  (state: RootState) =>
    state.logicalCondition.toothConditions[toothID]?.childConditions[parentID][
      code
    ];

export const selectHoveredConditionLink = (state: RootState) =>
  state.logicalCondition.hoveredConditionLink;

export const selectLoading = (state: RootState) =>
  state.logicalCondition.loading;

export const makeSelectToothMasksAssetIDs = (toothID: string) =>
  createSelector(
    (state: RootState) =>
      state.logicalCondition.toothConditions[toothID]?.conditions ||
      ({} as ToothCondition['conditions']),
    (state: RootState) =>
      state.logicalCondition.toothConditions[toothID]?.childConditions ||
      ({} as ToothCondition['childConditions']),
    (conditions, childConditions) => {
      const conditionsList = Object.values(conditions).flatMap((item) =>
        Object.values(item),
      );
      const childConditionsList = Object.values(childConditions).flatMap(
        (collectionByCode) =>
          Object.values(collectionByCode).flatMap((collectionByID) =>
            Object.values(collectionByID),
          ),
      );

      return conditionsList
        .concat(childConditionsList)
        .filter(shouldConditionItemBeShown(true))
        .flatMap((condition) =>
          condition.Localizations.filter(
            (localization) => localization.MaskAssetID,
          ).map((localization) => localization.MaskAssetID),
        );
    },
  );

// export const makeSelectExtendedConditionsByToothID = (toothID: string) =>
//   createSelector(
//     (state: RootState) => state.logicalCondition.toothConditions[toothID]?.conditions || {} as ToothCondition['conditions'],
