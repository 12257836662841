import { Decision } from '@/shared/api/protocol_gen/model/dto_report_common';
import { ToothLandmark } from '@/shared/api/protocol_gen/model/dto_report_landmark';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { PblConditionWithChildren } from '../config';

import { getPBLColor } from './getPBLColor';

const findPositiveCondition = (condition: PblConditionWithChildren) =>
  condition.children.find(
    (childCond: Condition) =>
      childCond.Certainty.ModelDecision === Decision.PositiveDecision,
  );

const getPblLowPoint = (
  landmarks: ToothLandmark[],
  condition: PblConditionWithChildren,
) =>
  landmarks.find(
    (landmark) =>
      landmark.ID ===
      condition.Localizations[0].PeriodontalBoneLossLandmarks
        .BoneLevelLandmarkID,
  );

const getPblUpPoint = (
  landmarks: ToothLandmark[],
  condition: PblConditionWithChildren,
) =>
  landmarks.find(
    (landmark) =>
      landmark.ID ===
      condition.Localizations[0].PeriodontalBoneLossLandmarks
        .CementoenamelJunctionLandmarkID,
  );

export const getPblLandmarksFromConditions = (
  pblConditions: PblConditionWithChildren[],
  toothLandmarks: ToothLandmark[],
) =>
  pblConditions.map((condition) => {
    const subImageTargetAssetID = condition.Localizations[0].TargetAssetID; //        ////
    const positiveChildCondition = findPositiveCondition(condition);
    const color = getPBLColor(positiveChildCondition);
    const lowPoint = getPblLowPoint(toothLandmarks, condition);
    const upPoint = getPblUpPoint(toothLandmarks, condition);

    return {
      subImageTargetAssetID,
      toothID: condition.Tooth.ToothID,
      lowPoint,
      upPoint,
      color,
    };
  });
