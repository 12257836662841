import { ToolNames } from '@/shared/config';

export const PANO_TOOLS: ToolNames[] = [
  'expand',
  'split',
  'sharpness',
  'brightness',
  'invert',
  'reset',
];

export const PANO_REPORT_TOOLS: ToolNames[] = [
  'expand',
  'sharpening',
  'brightness',
  'reset',
];

export const PANO_DEFAULT_HEIGHT = 450;
export const MODAL_FIXED_WIDTH = 632;

export const REPORT_SIGNED_DATE_FORMAT = 'EEE dd, yyyy HH:mm';
