// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UploadStudyModalForm-module__container--gLtoBDWp{display:flex;flex-direction:column;gap:24px}.UploadStudyModalForm-module__modalcontainer--ynQddXCo{max-height:initial}.UploadStudyModalForm-module__modalBody--dD1J9iuB{padding-top:8px;padding-bottom:8px}.UploadStudyModalForm-module__helpText--5pi8It1S{color:var(--greytext2)}.UploadStudyModalForm-module__invalidText--Rt41kCkR{color:var(--redrose)}.UploadStudyModalForm-module__reportSwitch--cF7aCsrI{align-items:flex-start}.UploadStudyModalForm-module__reportsCounter--vK3\\+GzAW{padding-top:4px;color:var(--grey100)}", "",{"version":3,"sources":["webpack://./src/widgets/UploadStudyModalForm/ui/UploadStudyModalForm/UploadStudyModalForm.module.scss"],"names":[],"mappings":"AAAA,kDACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,uDACE,kBAAA,CAGF,kDACE,eAAA,CACA,kBAAA,CAGF,iDACE,sBAAA,CAGF,oDACE,oBAAA,CAGF,qDACE,sBAAA,CAGF,wDACE,eAAA,CACA,oBAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.modalcontainer {\n  max-height: initial;\n}\n\n.modalBody {\n  padding-top: 8px;\n  padding-bottom: 8px;\n}\n\n.helpText {\n  color: var(--greytext2);\n}\n\n.invalidText {\n  color: var(--redrose);\n}\n\n.reportSwitch {\n  align-items: flex-start;\n}\n\n.reportsCounter {\n  padding-top: 4px;\n  color: var(--grey100);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UploadStudyModalForm-module__container--gLtoBDWp",
	"modalcontainer": "UploadStudyModalForm-module__modalcontainer--ynQddXCo",
	"modalBody": "UploadStudyModalForm-module__modalBody--dD1J9iuB",
	"helpText": "UploadStudyModalForm-module__helpText--5pi8It1S",
	"invalidText": "UploadStudyModalForm-module__invalidText--Rt41kCkR",
	"reportSwitch": "UploadStudyModalForm-module__reportSwitch--cF7aCsrI",
	"reportsCounter": "UploadStudyModalForm-module__reportsCounter--vK3+GzAW"
};
export default ___CSS_LOADER_EXPORT___;
