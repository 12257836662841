import { generatePath, useNavigate } from 'react-router';

import { PATHS } from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { authModel } from '@/entities/auth';

export const useCheckInviteToken = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isAuth = useAppSelector(authModel.selectors.selectIsAuth);

  const checkInviteToken = async (invitationToken: string) => {
    const { IsAlreadyRegistered, InvitationInfo, SignUpViaSalesmanInfo } =
      await dispatch(
        authModel.thunks.checkInviteToken({ Token: invitationToken }),
      ).unwrap();

    if (!IsAlreadyRegistered) {
      navigate(PATHS.signUp, { state: { invitationToken } });

      return;
    }

    // invite from Organization
    if (InvitationInfo.Target?.Organization) {
      const invitationOrganizationID =
        InvitationInfo.Target.Organization.OrganizationID;

      const invitationOrganizationName =
        InvitationInfo.TargetDetails.OrganizationName;

      if (isAuth) {
        navigate(PATHS.patients, {
          state: { invitationOrganizationID, invitationOrganizationName },
        });
      } else {
        navigate(PATHS.signIn, {
          state: {
            from: PATHS.patients,
            invitationOrganizationID,
            invitationOrganizationName,
          },
        });
      }

      return;
    }

    // invite from doctor
    if (InvitationInfo.Target?.Patient) {
      if (isAuth) {
        navigate(
          generatePath(PATHS.organization_invite, { token: invitationToken }),
        );
      } else {
        navigate(PATHS.signIn, {
          state: {
            from: generatePath(PATHS.organization_invite, {
              token: invitationToken,
            }),
          },
        });
      }

      return;
    }

    // invite from Salesman
    if (SignUpViaSalesmanInfo) {
      if (isAuth) {
        // TODO: [1/l] сhange the link to billing settings when they're ready
        navigate(PATHS.personalSettings);
      } else {
        navigate(PATHS.signIn, {
          state: {
            from: PATHS.personalSettings,
          },
        });
      }
    }
  };

  return checkInviteToken;
};
