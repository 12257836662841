import { FC } from 'react';
import cn from 'classnames';

import { Tooltip } from '@/shared/ui';

import { CombinedConditionGroupInterface } from '../../hooks';

import styles from './SimpleConditionGroup.module.scss';

type SimpleConditionGroupProps = {
  className?: string;
  combinedConditionItems: CombinedConditionGroupInterface[];
  handleAddCondition: () => void;
};

export const SimpleConditionGroup: FC<SimpleConditionGroupProps> = (props) => {
  const { className, combinedConditionItems, handleAddCondition } = props;

  if (combinedConditionItems.length === 0) {
    return null;
  }

  return (
    <div className={cn(styles.container, className)}>
      {combinedConditionItems.map(({ code, text, color, conditionItems }) => (
        <Tooltip.Primary
          key={code}
          content={
            <div className={styles.conditions}>
              {conditionItems.map((item) => (
                <span key={item.code}>{item.text}</span>
              ))}
            </div>
          }
        >
          <span onClick={handleAddCondition}>
            <span
              className={cn(
                styles.combinedConditionGroupText,
                styles[color],
                'p2',
              )}
            >
              {text}
            </span>
          </span>
        </Tooltip.Primary>
      ))}
    </div>
  );
};
