import { useMemo } from 'react';
import { isEqual } from 'lodash';

import { useAppSelector } from '@/shared/hooks';
import { ServiceType } from '@/shared/api/protocol_gen/api/billing_new/dto_services_new';
import { ProductState } from '@/shared/api/protocol_gen/api/billing_new/dto_product_new';

import * as billingModel from '../model';
import { getServicesFromSubscriptionList } from '../lib/getServicesFromSubscriptionList';

export const useBillingAccess = () => {
  const productList: ProductState[] = useAppSelector(
    billingModel.selectors.selectActiveProducts,
    isEqual,
  );

  const services = useMemo(
    () => getServicesFromSubscriptionList(productList),
    [productList],
  );

  const activeServicesList = useMemo(
    () =>
      services.reduce((activeServices, service) => {
        service.Types.forEach((serviceType) => {
          activeServices[serviceType] = service.Enabler
            ? 1
            : service.Countable.Leftover;
        });

        return activeServices;
      }, {} as Record<ServiceType, number>),
    [services],
  );

  return activeServicesList;
};
