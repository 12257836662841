import { SortKeyEnum } from '@/shared/config';
import { PatientListStreamSortBy } from '@/shared/api/protocol_gen/api/core/svc_patient';

export const getSortByOptions = (
  sortKey: SortKeyEnum,
  sortAsc: boolean,
): { By?: PatientListStreamSortBy; Ascending?: boolean }[] => {
  switch (sortKey) {
    case SortKeyEnum.PatientName:
      return [
        {
          Ascending: sortAsc,
          By: PatientListStreamSortBy.PatientListStreamSortByLastName,
        },
        {
          Ascending: sortAsc,
          By: PatientListStreamSortBy.PatientListStreamSortByFirstName,
        },
      ];
    case SortKeyEnum.DateOfBirth:
      return [
        {
          Ascending: sortAsc,
          By: PatientListStreamSortBy.PatientListStreamSortByDateOfBirth,
        },
      ];
    case SortKeyEnum.ExternalID:
      return [
        {
          Ascending: sortAsc,
          By: PatientListStreamSortBy.PatientListStreamSortByExternalID,
        },
      ];
    default:
      return [];
  }
};
