import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Modal } from '@/shared/ui';

import * as modalModel from '../../model';
import { ModalID } from '../../config/types';

export const ConfirmModal = () => {
  const dispatch = useAppDispatch();

  const {
    visible,
    loading = false,
    data: { title, description, okText, cancelText, danger = false, onConfirm },
  } = useAppSelector((state) => state.modal.Confirm);

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.Confirm));
  }, [dispatch]);

  return (
    <Modal
      // className={cn(styles.container, className)}
      // bodyClassName={styles.modalBody}
      title={title}
      isOpen={visible}
      onApply={onConfirm}
      onClose={handleClose}
      applyButtonText={okText}
      applyButtonProps={{
        danger,
        loading,
      }}
      cancelButtonText={cancelText}
    >
      {description}
    </Modal>
  );
};
