import { useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';
import { useLocation, useNavigate } from 'react-router';

import { useAppDispatch } from '@/shared/hooks';
import api from '@/shared/api/api';
import { PATHS, StreamDataAccumulatorKey } from '@/shared/config';
import { Study } from '@/shared/api/protocol_gen/model/dto_study';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';
import { ToothLandmark } from '@/shared/api/protocol_gen/model/dto_report_landmark';

import { reportsModel } from '@/entities/reports';
import { patientModel } from '@/entities/patient';
import { toothModel } from '@/entities/tooth';
import { studyModel } from '@/entities/study';
import { conditionModel } from '@/entities/condition';
import { assetsModel } from '@/entities/assets';
import { toothLandmarksModel } from '@/entities/toothLandmarks';
import { allowedToothConditionsModel } from '@/entities/allowedToothConditions';
import { logicalConditionModel } from '@/entities/logicalCondition';
import { ToothConditions } from '@/entities/logicalCondition/model/logicalConditionSlice';

type ReportDataStreamDataAccumulators = {
  [StreamDataAccumulatorKey.study]: Study[];
  [StreamDataAccumulatorKey.conditions]: Condition[];
  [StreamDataAccumulatorKey.teeth]: Tooth[];
  [StreamDataAccumulatorKey.assets]: Asset[];
  [StreamDataAccumulatorKey.toothLandmarks]: ToothLandmark[];
  [StreamDataAccumulatorKey.initialROITeethISONumbers]: number[];
  [StreamDataAccumulatorKey.initialROITeethIDs]: string[];
};

const dataAccumulators: ReportDataStreamDataAccumulators = {
  [StreamDataAccumulatorKey.study]: [],
  [StreamDataAccumulatorKey.conditions]: [],
  [StreamDataAccumulatorKey.teeth]: [],
  [StreamDataAccumulatorKey.assets]: [],
  [StreamDataAccumulatorKey.initialROITeethISONumbers]: [],
  [StreamDataAccumulatorKey.initialROITeethIDs]: [],
  [StreamDataAccumulatorKey.toothLandmarks]: [],
};

let conditionsAccumulator: ToothConditions = {};

export const useReportDataStream = (reportID: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const reportDataStream = useRef<Subscription>(undefined);

  const openReportDataStream = () => {
    dispatch(reportsModel.actions.setLoading('pending'));
    dispatch(patientModel.actions.setLoading('pending'));
    dispatch(studyModel.actions.setLoading('pending'));
    dispatch(assetsModel.actions.setLoading('pending'));
    dispatch(toothModel.actions.setLoading('pending'));
    dispatch(logicalConditionModel.actions.setLoading('pending'));

    performance.mark('openReportDataStream');

    reportDataStream.current = api.report
      .ReportDataStream({ ReportID: reportID })
      .subscribe({
        next: (data) => {
          if (data.HistoricalPatient) {
            performance.mark('ReportDataStream:HistoricalPatient');

            const reportDataStreamPatientMeasure = performance.measure(
              'ReportDataStream>>>HistoricalPatient',
              'openReportDataStream',
              'ReportDataStream:HistoricalPatient',
            );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamPatientMeasure);

            dispatch(patientModel.actions.addOne(data.HistoricalPatient));
            dispatch(patientModel.actions.setLoading('succeeded'));
          }

          if (data.UpdatedPatient) {
            dispatch(patientModel.actions.setNewestOne(data.UpdatedPatient));
          }

          if (data.HistoricalStudy) {
            performance.mark('ReportDataStream:HistoricalStudy');

            const reportDataStreamStudyMeasure = performance.measure(
              'ReportDataStream>>>HistoricalStudy',
              'openReportDataStream',
              'ReportDataStream:HistoricalStudy',
            );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamStudyMeasure);

            dataAccumulators[StreamDataAccumulatorKey.study].push(
              data.HistoricalStudy,
            );
          }

          if (data.UpdatedStudy) {
            dispatch(studyModel.actions.setNewestOne(data.UpdatedStudy));
          }

          if (data.EndOfHistoricalStudies) {
            performance.mark('ReportDataStream:EndOfHistoricalStudies');

            const reportDataStreamEndOfHistoricalStudiesMeasure =
              performance.measure(
                'ReportDataStream>>>EndOfHistoricalStudies',
                'openReportDataStream',
                'ReportDataStream:EndOfHistoricalStudies',
              );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamEndOfHistoricalStudiesMeasure);

            dispatch(
              studyModel.actions.setMany(
                dataAccumulators[StreamDataAccumulatorKey.study],
              ),
            );

            dispatch(studyModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.study] = [];
          }

          if (data.HistoricalReport) {
            performance.mark('ReportDataStream:HistoricalReport');

            const reportDataStreamHistoricalReportMeasure = performance.measure(
              'ReportDataStream>>>HistoricalReport',
              'openReportDataStream',
              'ReportDataStream:HistoricalReport',
            );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamHistoricalReportMeasure);

            dispatch(reportsModel.actions.addOne(data.HistoricalReport));
            dispatch(
              reportsModel.actions.setCurrentReport(data.HistoricalReport),
            );
            dispatch(reportsModel.actions.setLoading('succeeded'));
          }

          if (data.UpdatedReport) {
            dispatch(reportsModel.actions.setNewestOne(data.UpdatedReport));
          }

          if (data.HistoricalTooth) {
            if (dataAccumulators[StreamDataAccumulatorKey.teeth].length === 0) {
              performance.mark('ReportDataStream:HistoricalTooth:first');
            }

            dataAccumulators[StreamDataAccumulatorKey.teeth].push(
              data.HistoricalTooth,
            );

            // Collect initial local ROI teeth IDs
            if (data.HistoricalTooth.IsInROI) {
              dataAccumulators[
                StreamDataAccumulatorKey.initialROITeethISONumbers
              ].push(data.HistoricalTooth.Numeration.ISO);

              dataAccumulators[
                StreamDataAccumulatorKey.initialROITeethIDs
              ].push(data.HistoricalTooth.ID);
            }
          }

          if (data.UpdatedTooth) {
            dispatch(toothModel.actions.setNewestOne(data.UpdatedTooth));
          }

          if (data.ToothConditionsHint) {
            dispatch(
              allowedToothConditionsModel.actions.setOne(
                data.ToothConditionsHint,
              ),
            );
          }

          if (data.EndOfHistoricalTeeth) {
            performance.mark('ReportDataStream:EndOfHistoricalTeeth');

            const reportDataStreamEndOfHistoricalTeethMeasure =
              performance.measure(
                'ReportDataStream>>>EndOfHistoricalTeeth',
                'openReportDataStream',
                'ReportDataStream:EndOfHistoricalTeeth',
              );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamEndOfHistoricalTeethMeasure);

            const reportDataStreamHistoricalToothMeasure = performance.measure(
              'ReportDataStream:HistoricalTooth:first>>>EndOfHistoricalTeeth',
              'ReportDataStream:HistoricalTooth:first',
              'ReportDataStream:EndOfHistoricalTeeth',
            );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamHistoricalToothMeasure);

            dispatch(
              toothModel.actions.setMany(
                dataAccumulators[StreamDataAccumulatorKey.teeth],
              ),
            );

            dispatch(
              toothModel.actions.setLocalROITeethISONumbers(
                dataAccumulators[
                  StreamDataAccumulatorKey.initialROITeethISONumbers
                ],
              ),
            );

            dispatch(
              toothModel.actions.setLocalROITeethIDs(
                dataAccumulators[StreamDataAccumulatorKey.initialROITeethIDs],
              ),
            );

            dispatch(toothModel.actions.setLoading('succeeded'));

            dataAccumulators[
              StreamDataAccumulatorKey.initialROITeethISONumbers
            ] = [];
            dataAccumulators[StreamDataAccumulatorKey.teeth] = [];
          }

          if (data.HistoricalCondition) {
            if (
              dataAccumulators[StreamDataAccumulatorKey.conditions].length === 0
            ) {
              performance.mark('ReportDataStream:HistoricalCondition:first');
            }
            dataAccumulators[StreamDataAccumulatorKey.conditions].push(
              data.HistoricalCondition,
            );

            const condition = data.HistoricalCondition;
            const toothID = condition.Tooth?.ToothID;
            const conditionCode = condition.Code;
            const parentID = condition.ParentID;

            const isChildCondition = parentID;

            if (toothID in conditionsAccumulator) {
              if (isChildCondition) {
                if (
                  parentID in conditionsAccumulator[toothID].childConditions
                ) {
                  if (
                    conditionCode in
                    conditionsAccumulator[toothID].childConditions[parentID]
                  ) {
                    conditionsAccumulator[toothID].childConditions[parentID][
                      conditionCode
                    ][condition.ID] = condition;
                  } else {
                    conditionsAccumulator[toothID].childConditions[parentID][
                      conditionCode
                    ] = {
                      [condition.ID]: condition,
                    };
                  }
                } else {
                  conditionsAccumulator[toothID].childConditions[parentID] = {
                    [conditionCode]: {
                      [condition.ID]: condition,
                    },
                  };
                }
              } else if (
                conditionCode in conditionsAccumulator[toothID].conditions
              ) {
                conditionsAccumulator[toothID].conditions[conditionCode][
                  condition.ID
                ] = condition;
              } else {
                conditionsAccumulator[toothID].conditions[conditionCode] = {
                  [condition.ID]: condition,
                };
              }
            } else {
              conditionsAccumulator[toothID] = {
                conditions: {},
                childConditions: {},
              };

              if (isChildCondition) {
                conditionsAccumulator[toothID].childConditions = {
                  [condition.ParentID]: {
                    [conditionCode]: {
                      [condition.ID]: condition,
                    },
                  },
                };
              } else {
                conditionsAccumulator[toothID].conditions = {
                  [conditionCode]: {
                    [condition.ID]: condition,
                  },
                };
              }
            }
          }

          // This could be both added condition or updated condition
          // if (data.UpdatedCondition) {
          //   dispatch(
          //     logicalConditionModel.actions.updateOne(data.UpdatedCondition),
          //   );
          // }

          if (data.HistoricalAsset) {
            if (
              dataAccumulators[StreamDataAccumulatorKey.assets].length === 0
            ) {
              performance.mark('ReportDataStream:HistoricalAsset:first');
            }
            dataAccumulators[StreamDataAccumulatorKey.assets].push(
              data.HistoricalAsset,
            );
          }

          if (data.EndOfHistoricalAssets) {
            performance.mark('ReportDataStream:EndOfHistoricalAssets');

            const reportDataStreamEndOfHistoricalAssetsMeasure =
              performance.measure(
                'ReportDataStream:openReportDataStream>>>EndOfHistoricalAssets',
                'openReportDataStream',
                'ReportDataStream:EndOfHistoricalAssets',
              );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamEndOfHistoricalAssetsMeasure);

            const reportDataStreamHistoricalAssetMeasure = performance.measure(
              'ReportDataStream:HistoricalAsset:first>>>EndOfHistoricalAssets',
              'ReportDataStream:HistoricalAsset:first',
              'ReportDataStream:EndOfHistoricalAssets',
            );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamHistoricalAssetMeasure);

            dispatch(
              assetsModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.assets],
              ),
            );
            dispatch(assetsModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.assets] = [];
          }

          if (data.HistoricalToothLandmark) {
            dataAccumulators[StreamDataAccumulatorKey.toothLandmarks].push(
              data.HistoricalToothLandmark,
            );
          }

          if (data.EndOfHistoricalTeethLandmarks) {
            dispatch(
              toothLandmarksModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.toothLandmarks],
              ),
            );

            dataAccumulators[StreamDataAccumulatorKey.toothLandmarks] = [];
          }

          if (data.EndOfHistoricalConditions) {
            performance.mark('ReportDataStream:EndOfHistoricalConditions');

            const reportDataStreamMeasure = performance.measure(
              'ReportDataStream>>>EndOfHistoricalConditions',
              'openReportDataStream',
              'ReportDataStream:EndOfHistoricalConditions',
            );
            // eslint-disable-next-line no-console
            console.log(reportDataStreamMeasure);

            const reportDataStreamHistoricalConditionMeasure =
              performance.measure(
                'ReportDataStream:HistoricalCondition:first>>>EndOfHistoricalConditions',
                'ReportDataStream:HistoricalCondition:first',
                'ReportDataStream:EndOfHistoricalConditions',
              );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamHistoricalConditionMeasure);

            performance.mark('reportDataStream:conditions:dispatch:start');

            dispatch(
              conditionModel.actions.setMany(
                dataAccumulators[StreamDataAccumulatorKey.conditions],
              ),
            );

            performance.mark('reportDataStream:conditions:dispatch:end');

            const reportDataStreamConditionsDispatchMeasure =
              performance.measure(
                'reportDataStream:conditions:dispatch',
                'reportDataStream:conditions:dispatch:start',
                'reportDataStream:conditions:dispatch:end',
              );
            // eslint-disable-next-line no-console
            console.log(reportDataStreamConditionsDispatchMeasure);

            dispatch(conditionModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.conditions] = [];

            dispatch(
              logicalConditionModel.actions.initialize(conditionsAccumulator),
            );

            dispatch(logicalConditionModel.actions.setLoading('succeeded'));
            conditionsAccumulator = {};
          }
        },
        error: (error) => {
          if (error.type === 'UnauthenticatedError') {
            navigate(PATHS.signIn, { state: { from: location?.pathname } });
          }

          if (error.type === 'NotFoundError') {
            navigate(PATHS.patients);
          }
        },
        complete: () => {
          // Do nothing
        },
      });
  };

  const closeReportDataStream = () => {
    if (reportDataStream.current) {
      reportDataStream.current.unsubscribe();

      dispatch(reportsModel.actions.reset());
      dispatch(toothModel.actions.reset());
      dispatch(conditionModel.actions.reset());
      dispatch(logicalConditionModel.actions.reset());
    }
  };

  useEffect(() => {
    openReportDataStream();

    return () => {
      closeReportDataStream();
    };
  }, []);
};
