import { LogicalCondition } from '@/entities/logicalCondition';

export const getMaskAssetIDSFromConditions = (
  conditions: LogicalCondition[],
): string[] =>
  conditions.reduce((maskAssetsIDs, condition) => {
    const conditionMasksAssetsIDS = condition.Localizations.filter(
      (localization) => localization.MaskAssetID,
    ).map((localization) => localization.MaskAssetID);
    return [...maskAssetsIDs, ...conditionMasksAssetsIDS];
  }, [] as string[]);
