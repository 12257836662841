// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToothImages-module__leftSide--2KmmteH4{display:flex;gap:8px}.ToothImages-module__rightSide--sdSMSrZs{display:flex;gap:8px;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/pages/IOXRayReport/ui/ToothImages/ToothImages.module.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CACA,OAAA,CAGF,yCACE,YAAA,CACA,OAAA,CACA,qBAAA","sourcesContent":[".leftSide {\n  display: flex;\n  gap: 8px;\n}\n\n.rightSide {\n  display: flex;\n  gap: 8px;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftSide": "ToothImages-module__leftSide--2KmmteH4",
	"rightSide": "ToothImages-module__rightSide--sdSMSrZs"
};
export default ___CSS_LOADER_EXPORT___;
