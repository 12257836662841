import { OutputSTLModels } from '@/widgets/Order3DSegmentronModal/config/i18n';

export const OUTPUT_STL_PRESETS: { [key in OutputSTLModels]: number[] } = {
  [OutputSTLModels.AllAnatomiesWithFusedTeethInSeparateFiles]: [
    3, 4, 5, 6, 7, 8, 9, 11, 14, 16,
  ],
  [OutputSTLModels.AllAnatomiesInSeparateFiles]: [
    3, 4, 5, 6, 7, 8, 9, 11, 14, 15,
  ],
  [OutputSTLModels.AllAnatomiesWithFusedTeethInOneFile]: [
    1, 3, 4, 5, 6, 7, 8, 9, 12, 14,
  ],
  [OutputSTLModels.Custom]: [],
};
