import api from '@/shared/api/api';
import {
  AssetServiceClientImpl,
  SetMedicalImageViewOptionsReq,
  SetMedicalImageViewOptionsResp,
  SetTopLayerAnnotationsReq,
  SetTopLayerAnnotationsResp,
  DeleteAssetReq,
  DeleteAssetResp,
} from '@/shared/api/protocol_gen/api/core/svc_asset';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';

type AssetsRequests = keyof AssetServiceClientImpl;

const generateAssetThunk = createThunkGenerator<AssetsRequests>(
  SliceName.assets,
);

export const setTopLayerAnnotations = generateAssetThunk<
  SetTopLayerAnnotationsReq,
  SetTopLayerAnnotationsResp
>('SetTopLayerAnnotations', api.assets.SetTopLayerAnnotations);

export const setMedicalImageViewOptions = generateAssetThunk<
  SetMedicalImageViewOptionsReq,
  SetMedicalImageViewOptionsResp
>('SetMedicalImageViewOptions', api.assets.SetMedicalImageViewOptions);

export const deleteAsset = generateAssetThunk<DeleteAssetReq, DeleteAssetResp>(
  'DeleteAsset',
  api.assets.DeleteAsset,
);
