import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { generatePath } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import { useLocation, useNavigate, useParams } from 'react-router';

import {
  Breadcrumbs,
  Button,
  Layout,
  toastCaller,
  WidgetCard,
} from '@/shared/ui';
import {
  LocationStateType,
  PATHS,
  ToastErrors,
  toastErrors,
} from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { usePatientBreadcrumbs } from '@/entities/patient';
import {
  FMXNavigationMode,
  getReportSignStatus,
  reportsModel,
} from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import { ConfirmModal, ModalID, modalModel } from '@/entities/modal';

import { useReport, useReportDataStream } from '@/features/reportStream';
import {
  groupIOXRayImagesByPartition,
  SubImageInterface,
  useTransformSubImage,
} from '@/features/IOXRayMatrix';

import { Header } from '@/widgets/Header';
import { IOXRayMatrix, sortGroupedSubImages } from '@/widgets/IOXRayMatrix';
import { ToothCard } from '@/widgets/ToothCard';
import { Conclusion } from '@/widgets/Conclusion';
import { AddConditionModal } from '@/widgets/AddConditionModal';
import { TeethNumberingModal } from '@/widgets/TeethNumberingModal';

import { useGetTeethNumberingModalImage } from '../GpReport/hooks/useGetTeethNumberingModalImage';

import { SubImageSwitch } from './ui/SubImageSwitch/SubImageSwitch';
import { IOXRAYNavigation } from './ui/IOXRayNavigation/IOXRayNavigation';
import { ToothImages } from './ui/ToothImages/ToothImages';
import { Toolbar } from './ui/Toolbar/Toolbar';
import { getDisabledSubImagesIDs } from './utils/getDisabledSubImagesIDs';
import styles from './IOXRayReport.module.scss';

type IoxRayReportProps = {
  className?: string;
  testID?: string;
};

export const IOXRayReport: FC<IoxRayReportProps> = (props) => {
  const { className, testID } = props;

  const { patientID, reportID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const rightColumnRef = useRef<HTMLDivElement>();

  const mainViewRef = useRef<HTMLDivElement>();

  const patientBreadcrumbs = usePatientBreadcrumbs(patientID);

  const { ROITeeth, report, isReportSigned, isAllTeethApproved } = useReport(
    reportID,
    patientID,
  );

  // const logicalConditions = useAppSelector(
  //   logicalConditionModel.selectors.selectAll,
  // );
  const activeSubImagesIDs = useAppSelector(
    reportsModel.selectors.selectActiveSubImagesIDs,
  );
  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );
  const showMasks = useAppSelector(reportsModel.selectors.selectShowMasks);
  const activePage = useAppSelector(
    reportsModel.selectors.selectActivePageNumber,
  );

  // const conditionsByROI = useMemo(
  //   () => ROITeeth.map((tooth) => logicalConditions[tooth.ID]),
  //   [ROITeeth, logicalConditions],
  // );

  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  const reportSignStatus = getReportSignStatus(report);

  // const conditionsInterfaces = useMemo(
  //   () => conditionsByROI.flatMap((toothConditions) => useTransformLogicalCondition(toothConditions)),
  //   [conditionsByROI]);
  //
  // const yellowConditionsIDs = getConditionIDsByColor(
  //   'yellow',
  //   conditionsInterfaces,
  // );

  const subImages = useTransformSubImage(reportID);
  const groupedSubImages = groupIOXRayImagesByPartition(subImages);
  const sortedSubImages: SubImageInterface[] =
    sortGroupedSubImages(groupedSubImages);

  const { modalWidth } = useGetTeethNumberingModalImage(report);

  const handleSetActiveSubImagesIDs = useCallback(
    (images: SubImageInterface[]) => {
      dispatch(
        reportsModel.actions.setActiveSubImagesIDs(
          images.map((image) => image.ID),
        ),
      );
    },
    [dispatch],
  );

  const activeSubImages = useMemo(
    () =>
      sortedSubImages.filter((subImage) =>
        activeSubImagesIDs.includes(subImage.ID),
      ),
    [activeSubImagesIDs, sortedSubImages],
  );

  useEffect(() => {
    if (activeSubImages?.length === 0 && subImages?.length > 0) {
      handleSetActiveSubImagesIDs(sortedSubImages);
    }

    const disabledIDs = getDisabledSubImagesIDs(
      subImages,
      ROITeeth.map((tooth) => tooth.Numeration.ISO),
    );

    dispatch(
      reportsModel.actions.setSubImagesIDs(
        sortedSubImages.map((subImage) => subImage.ID),
      ),
    );

    dispatch(reportsModel.actions.setDisabledSubImagesIDs(disabledIDs));
  }, [sortedSubImages.length, activeSubImages?.length, subImages?.length]);

  const handleToothClick = useCallback(
    (ID: string) => {
      const clickedToothIndex = ROITeeth.findIndex((tooth) => tooth.ID === ID);

      dispatch(reportsModel.actions.setActiveToothID(ID));
      dispatch(
        reportsModel.actions.setNavigationMode(FMXNavigationMode.ToothChart),
      );
      dispatch(reportsModel.actions.setActivePage(clickedToothIndex + 1));

      handleSetActiveSubImagesIDs(sortedSubImages);
    },
    [ROITeeth, dispatch, handleSetActiveSubImagesIDs, sortedSubImages],
  );

  const handleScrollToothCardsToTop = () => {
    if (rightColumnRef.current) {
      rightColumnRef.current.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const openConfirmSignReportModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.Confirm,
        data: {
          title: formatMessage({
            id: 'consfirmSignReportModal.approveAll',
            defaultMessage: 'Approve all teeth',
          }),
          description: formatMessage({
            id: 'report.warning.approveAndSign',
            defaultMessage:
              'Are you sure you want to approve all the teeth and include in the report?',
          }),
          okText: formatMessage({
            id: 'report.confirm.approveAndSign',
            defaultMessage: 'Yes, approve all and sign',
          }),
          onConfirm: async () => {
            try {
              // I don't understand why we need collect condition ids for approve all teeth.
              // Need to discuss with backend team.
              const currentReport = await dispatch(
                reportsModel.thunks.approveAllTeethAndSignReport({
                  ReportID: reportID,
                  TeethConditionIDs: [],
                }),
              ).unwrap();
              dispatch(reportsModel.actions.setNewestOne(currentReport.Report));
              dispatch(toothModel.actions.setMany(currentReport.Teeth));
            } catch (error) {
              const errorHeading = formatMessage(
                toastErrors[ToastErrors.errorHeading],
              );
              const parsedMessage = JSON.parse(error?.message);

              toastCaller({
                type: 'error',
                heading: errorHeading,
                message: parsedMessage?.text ?? parsedMessage?.reason,
              });
            } finally {
              dispatch(modalModel.actions.closeModal(ModalID.Confirm));
            }
          },
        },
      }),
    );
  }, [dispatch, formatMessage, reportID]);

  const handleClickAllMatrix = () => {
    dispatch(reportsModel.actions.setActiveToothID(''));
    handleSetActiveSubImagesIDs(sortedSubImages);
    dispatch(reportsModel.actions.setActiveSubImageIndex(0));
    dispatch(reportsModel.actions.setActivePage(0));
    handleScrollToothCardsToTop();
  };

  const handlePrintButton = () => {
    navigate(generatePath(PATHS.previewReport, { patientID, reportID }), {
      state: {
        ...locationState,
        lastPositionReport: window.pageYOffset,
        toothID: undefined,
      },
    });
  };

  const isAllSubImagesShown =
    subImages.length === activeSubImages.length && subImages.length !== 1;

  const handleClickSubImage = (subImage: SubImageInterface) => {
    const clickedSubImageIndex =
      sortedSubImages.findIndex((image) => image.ID === subImage.ID) + 1;

    handleSetActiveSubImagesIDs([subImage]);
    dispatch(reportsModel.actions.setActiveSubImageIndex(clickedSubImageIndex));
    dispatch(reportsModel.actions.setActivePage(clickedSubImageIndex));
    dispatch(reportsModel.actions.setActiveToothID(''));
    handleScrollToothCardsToTop();
  };

  const canClickOnSubImage =
    isAllSubImagesShown &&
    !(navigationMode === FMXNavigationMode.ToothChart && activePage === 0);

  const shouldShowIOXRayMatrix =
    navigationMode === FMXNavigationMode.MatrixView ||
    (navigationMode === FMXNavigationMode.ToothChart && activePage === 0);

  useReportDataStream(reportID);

  return (
    <Layout fullWidth>
      <Layout.Header>
        <Header
          fullWidth
          navigation={
            <Breadcrumbs
              config={[
                patientBreadcrumbs.patients,
                patientBreadcrumbs.patientProfile,
              ]}
            />
          }
        />
      </Layout.Header>

      <Layout.Content>
        <div
          data-testid={testID}
          className={cn(styles.gridContainer, className)}
        >
          <div className={styles.mainView}>
            <div className={styles.IOXRay} ref={mainViewRef}>
              <Toolbar
                className={styles.toolbar}
                controls={['sharpening', 'brightness', 'reset']}
                tooltipPosition="left"
                handleScrollToothCardsToTop={handleScrollToothCardsToTop}
              />

              {shouldShowIOXRayMatrix ? (
                <IOXRayMatrix
                  subImages={activeSubImages}
                  reportID={reportID}
                  showMasks={showMasks}
                  onSubImageClick={canClickOnSubImage && handleClickSubImage}
                  showLandmarks={!isAllSubImagesShown}
                  containerSizes={{
                    width: mainViewRef.current?.clientWidth,
                    height: mainViewRef.current?.clientHeight,
                  }}
                />
              ) : (
                <ToothImages
                  reportID={reportID}
                  wrapperSize={{
                    width: mainViewRef?.current.offsetWidth,
                    height: mainViewRef?.current.offsetHeight,
                  }}
                  biteWingSubImages={[
                    ...groupedSubImages.MiddleLeft,
                    ...groupedSubImages.MiddleMiddle,
                    ...groupedSubImages.MiddleRight,
                  ]}
                />
              )}

              <SubImageSwitch
                handleClickAllMatrix={handleClickAllMatrix}
                handleScrollToothCardsToTop={handleScrollToothCardsToTop}
              />
            </div>
          </div>

          <div className={styles.rightColumn} ref={rightColumnRef}>
            <IOXRAYNavigation
              className={cn(styles.navigation)}
              subImages={subImages}
              reportID={reportID}
              activeSubImages={!isAllSubImagesShown ? activeSubImages : []}
              onSubImageClick={handleClickSubImage}
              onToothClick={handleToothClick}
            />

            {ROITeeth.filter((roiTooth) =>
              activeSubImages.some((subImage) =>
                subImage.teethISONumbers.includes(roiTooth.Numeration.ISO),
              ),
            ).map((tooth) => (
              <ToothCard
                key={tooth.ID}
                tooth={tooth}
                patientID={patientID}
                reportID={reportID}
                showSlices={false}
              />
            ))}

            {/* <ReferralRecommendations reportID={reportID} /> */}

            <WidgetCard>
              <Conclusion
                conclusion={report?.LegacyConclusion ?? ''}
                reportID={reportID}
              />
            </WidgetCard>

            <div className={styles.rightColumnFooter}>
              <Button
                variant={isReportSigned ? 'primary' : 'tertiary'}
                onClick={handlePrintButton}
                icon={isReportSigned ? 'check' : undefined}
              >
                {isReportSigned ? (
                  <FormattedMessage
                    id="report.printReport"
                    defaultMessage="Print report"
                  />
                ) : (
                  <FormattedMessage
                    id="report.printReportWithoutSignature"
                    defaultMessage="Print report without signature"
                  />
                )}
              </Button>

              {!isReportSigned && (
                <Button
                  icon="pen"
                  disabled={
                    reportSignStatus === 'withoutSign' ||
                    !report?.YourPermissions?.CanChangeToothApproved
                  }
                  onClick={openConfirmSignReportModal}
                >
                  {isAllTeethApproved ? (
                    <FormattedMessage id="report.sign" defaultMessage="Sign" />
                  ) : (
                    <FormattedMessage
                      id="report.approveAndSign"
                      defaultMessage="Approve all and sign"
                    />
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Layout.Content>

      <ConfirmModal />

      <AddConditionModal />

      <TeethNumberingModal
        title={formatMessage({
          id: 'report.EditTeethNumbers',
          defaultMessage: 'Edit teeth numbers',
        })}
        patientID={patientID}
        reportID={reportID}
        report={report}
        modalWidth={modalWidth}
      />
    </Layout>
  );
};
