import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { UltimateMedicalImageMode } from '@/shared/graphics/RenderComponents/Presets/UltimateMedicalImage';
import { Annotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';
import { AnnotationOrEraser } from '@/shared/graphics/RenderComponents/Presets/DicomImageWithAnnotations';
import { useAppDispatch } from '@/shared/hooks';
import { toastCaller } from '@/shared/ui';
import { MedicalImageViewOptions } from '@/shared/api/protocol_gen/model/dto_common_image_view_options';
import { ToastErrors, toastErrors } from '@/shared/config';
import { ApiError } from '@/shared/api/api';

import { ViewOptionsInterface } from '../config/reports.type';
import * as toothModel from '../../tooth/model';
import {
  assetsModel,
  croppedImageAnnotationConvertor,
  formatAnnotations,
} from '../../assets';
import * as reportsModel from '../model';

// TODO: [4|h] refactor component, rewrite from local state to redux
export const useMedicalImageControls = (
  src: string,
  assetID?: string,
  viewOptions?: ViewOptionsInterface,
  imageAnnotations?: Annotation[],
) => {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const [activeControl, setActiveControl] = useState('');
  const [mode, setMode] = useState<UltimateMedicalImageMode>('view');
  const [annotations, setAnnotations] = useState<Annotation[]>(
    () => imageAnnotations || [],
  );
  const [inverted, setInverted] = useState(() => viewOptions?.invert || false);
  const [sharpness, setSharpness] = useState(() =>
    viewOptions?.sharpness ? viewOptions?.sharpness : 0,
  );
  const [viewAnnotations, setViewAnnotations] = useState(true);
  const [currentAnnotation, setCurrentAnnotation] =
    useState<AnnotationOrEraser | null>(null);
  const [wwwc, setWWWC] = useState<{ ww: number; wc: number }>(
    () => viewOptions?.wwwc || { ww: 0, wc: 0 },
  );
  const [expandedImage, setExpandedImage] = useState('');
  const [splited, setSplited] = useState(false);

  const initialViewOptions = useRef<ViewOptionsInterface>();

  const handleReset = () => {
    setActiveControl('');
    setMode('view');
    setInverted(initialViewOptions?.current?.invert || false);
    setSharpness(initialViewOptions?.current?.sharpness || 0);
    setWWWC({
      ww: initialViewOptions?.current?.wwwc.ww || 0,
      wc: initialViewOptions?.current?.wwwc.wc || 0,
    });
    setAnnotations(imageAnnotations || []);
    setCurrentAnnotation(null);
  };

  const handleSetAnnotations = async (changedAnnotations: Annotation[]) => {
    setAnnotations(changedAnnotations);
    try {
      if (!assetID) {
        throw new Error('AssetID is not defined');
      }
      const updatedAsset = await dispatch(
        assetsModel.thunks.setTopLayerAnnotations({
          AssetID: assetID,
          Annotations: formatAnnotations(changedAnnotations),
        }),
      ).unwrap();

      if (updatedAsset.Asset) {
        dispatch(assetsModel.actions.setNewestOne(updatedAsset.Asset));
      }
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };

  const handleSetCroppedImageAnnotations = async (
    localizationID: string,
    changedAnnotations: Annotation[],
  ) => {
    setAnnotations(changedAnnotations);
    try {
      const croppedAnnotations =
        croppedImageAnnotationConvertor(changedAnnotations);

      const { Tooth } = await dispatch(
        reportsModel.thunks.setToothLocalizationCropTopLayerAnnotations({
          LocalizationID: localizationID,
          Annotations: croppedAnnotations,
        }),
      ).unwrap();

      if (Tooth) {
        dispatch(toothModel.actions.setNewestOne(Tooth));
      }
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };

  const handleSetMedicalImageViewOptions = async (
    viewOption: Partial<MedicalImageViewOptions>,
  ) => {
    try {
      if (!assetID) {
        throw new Error('AssetID is not defined');
      }
      const { Asset } = await dispatch(
        assetsModel.thunks.setMedicalImageViewOptions({
          AssetID: assetID,
          MedicalImageViewOptions: viewOption as MedicalImageViewOptions, // TODO: disappear after migration on connect lib
        }),
      ).unwrap();

      if (Asset) {
        dispatch(assetsModel.actions.setNewestOne(Asset));
      }
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };

  const handleSetCroppedMedicalImageViewOptions = async (
    localizationID: string,
    viewOption: MedicalImageViewOptions,
  ) => {
    try {
      const { Tooth } = await dispatch(
        reportsModel.thunks.setToothLocalizationCropMedicalImageViewOptions({
          LocalizationID: localizationID,
          MedicalImageViewOptions: viewOption,
        }),
      ).unwrap();

      if (Tooth) {
        dispatch(toothModel.actions.setNewestOne(Tooth));
      }
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };

  useEffect(() => {
    if (viewOptions?.wwwc) {
      setWWWC(viewOptions.wwwc);
    }

    if (viewOptions?.sharpness) {
      setSharpness(viewOptions.sharpness);
    }

    if (viewOptions?.invert) {
      setInverted(viewOptions.invert);
    }
  }, [viewOptions?.invert, viewOptions?.sharpness, viewOptions?.wwwc]);

  useEffect(() => {
    if (viewOptions) {
      initialViewOptions.current = viewOptions;
    }
  }, []);

  useEffect(() => {
    switch (activeControl) {
      case 'ruler':
      case 'arrow':
      case 'angle':
      case 'eraser':
        setMode('annotate');
        setCurrentAnnotation(activeControl);
        setViewAnnotations(true);
        break;
      case 'view':
        setMode('view');
        setViewAnnotations(false);
        break;
      case 'brightness':
        setMode('windowing');
        setViewAnnotations(true);
        break;
      case 'sharpness':
        setMode('sharpening');
        setViewAnnotations(true);
        break;
      case 'expand':
        setExpandedImage(src);
        break;
      case 'reset':
        handleReset();
        break;
      default:
        setMode('view');
        setCurrentAnnotation(null);
        setViewAnnotations(true);
    }
  }, [activeControl]);

  return {
    activeControl,
    setActiveControl,
    mode,
    setMode,
    annotations,
    setAnnotations,
    inverted,
    setInverted,
    splited,
    setSplited,
    sharpness,
    setSharpness,
    viewAnnotations,
    setViewAnnotations,
    currentAnnotation,
    setCurrentAnnotation,
    wwwc,
    setWWWC,
    expandedImage,
    setExpandedImage,
    handleSetAnnotations,
    handleSetCroppedImageAnnotations,
    handleSetMedicalImageViewOptions,
    handleSetCroppedMedicalImageViewOptions,
  };
};
