import { FC, useRef, useState } from 'react';

import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { Skeleton } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';

import {
  protocolLocalizationToBBox,
  ViewOptionsInterface,
} from '@/entities/reports';
import { logicalConditionModel } from '@/entities/logicalCondition';

import { IOXRayMatrixDeprecated } from '@/widgets/IOXRayMatrix';
import { PanoImage } from '@/widgets/PanoImage';

import { ProcessingUnitContext } from 'graphics';

import {
  PANO_DEFAULT_HEIGHT,
  PANO_REPORT_TOOLS,
  PANO_TOOLS,
} from '../../config/constants';

import styles from './ReportImage.module.scss';

type ImageProps = {
  viewOptions: ViewOptionsInterface;
  assetID: string;
  panoramaURL: string;
  panoramaSplitURL?: string;
};

type ReportImageProps = {
  report: Report;
  panoImage: ImageProps;
  getPanoImageContext: (context: ProcessingUnitContext) => void;
};

export const ReportImage: FC<ReportImageProps> = (props) => {
  const { report, getPanoImageContext, panoImage } = props;

  const [imageLoaded, setImageLoaded] = useState(false);

  const panoContainer = useRef<HTMLDivElement>(null);

  const { toothID, code, parentID } = useAppSelector(
    logicalConditionModel.selectors.selectHoveredConditionLink,
  );

  const hoveredCondition = useAppSelector(
    logicalConditionModel.selectors.selectCondition({
      toothID,
      code,
      parentID,
    }),
  );

  const bbox = hoveredCondition?.Localizations.map(protocolLocalizationToBBox);

  const onLoaded = () => {
    setImageLoaded(true);
  };

  const imageSkeleton = (
    <Skeleton
      width="100%"
      borderRadius="12px"
      height={`${PANO_DEFAULT_HEIGHT}px`}
    />
  );

  if (report.Type === ReportType.ReportType_IOXRay_GP) {
    return report?.DataIOXRayGP?.Subimages ? (
      <IOXRayMatrixDeprecated
        className={styles.IOXRayMatrix}
        reportID={report.ID}
        handlePanoContext={(context) => getPanoImageContext(context)}
        hoveredConditionBBox={bbox}
        hoveredCondition={hoveredCondition}
      />
    ) : (
      imageSkeleton
    );
  }

  return report.Type === ReportType.ReportType_CBCT_GP ||
    report.Type === ReportType.ReportType_Pano_GP ? (
    <div ref={panoContainer}>
      <PanoImage
        kind={
          report.Type === ReportType.ReportType_CBCT_GP ? 'dicom' : 'raster'
        }
        controls={
          report.Type === ReportType.ReportType_CBCT_GP
            ? PANO_TOOLS
            : PANO_REPORT_TOOLS
        }
        src={panoImage.panoramaURL}
        splitUrl={panoImage.panoramaSplitURL}
        toolbarPosition="inside"
        handlePanoContext={(context) => {
          onLoaded();
          getPanoImageContext(context);
        }}
        assetID={panoImage.assetID}
        viewOptions={panoImage.viewOptions}
        containerWidth={panoContainer?.current?.clientWidth}
        fixedHeight={!imageLoaded && PANO_DEFAULT_HEIGHT}
        localizations={bbox ?? []}
      />
    </div>
  ) : (
    imageSkeleton
  );
};
