import { AssetContentGeneratedCBCTToothPathologySlice_PathologyType } from '@/shared/api/protocol_gen/model/dto_asset';
import { AssetContentGeneratedCBCTEndoReportSection } from '@/shared/api/protocol_gen/model/dto_asset_cbct_endo';
import { CBCTSectionOrientation } from '@/shared/api/protocol_gen/model/dto_asset_common';

import { GroupConfigsType } from './medicalImages.types';

export const TOOTH_PAGE_GROUPS: GroupConfigsType[] = [
  {
    groupName: 'OrientationAxial',
    groupCode: CBCTSectionOrientation.SectionOrientationAxial,
  },
  {
    groupName: 'OrientationVestibuloOral',
    groupCode: CBCTSectionOrientation.SectionOrientationVestibuloOral,
  },
  {
    groupName: 'OrientationMesioDistal',
    groupCode: CBCTSectionOrientation.SectionOrientationMesioDistal,
  },
];

export const TOOTH_PAGE_PATHOLOGY_GROUPS: GroupConfigsType[] = [
  {
    groupName: 'Caries',
    groupCode:
      AssetContentGeneratedCBCTToothPathologySlice_PathologyType.Caries,
  },
  {
    groupName: 'PeriapicalLesion',
    groupCode:
      AssetContentGeneratedCBCTToothPathologySlice_PathologyType.PeriapicalLesion,
  },
];

export const ENDO_REPORT_GROUPS: GroupConfigsType[] = [
  {
    groupName: 'Endo_ReportSection_RootCanalSpace',
    groupCode:
      AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_RootCanalSpace,
  },
  {
    groupName: 'Endo_ReportSection_RootCanalSystem',
    groupCode:
      AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_RootCanalSystem,
  },
  {
    groupName: 'Endo_ReportSection_RootCanalShape',
    groupCode:
      AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_RootCanalShape,
  },
  {
    groupName: 'Endo_ReportSection_Furcation',
    groupCode:
      AssetContentGeneratedCBCTEndoReportSection.Endo_ReportSection_Furcation,
  },
];

export const ENDODONTIC_PATHOLOGY_GROUP: GroupConfigsType = {
  groupName: 'PeriapicalLesion',
  groupCode:
    AssetContentGeneratedCBCTToothPathologySlice_PathologyType.PeriapicalLesion,
};
