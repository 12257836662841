// TODO: Resolve TS errors
// @ts-nocheck
import { useIntl } from 'react-intl';

import { getCombinedConditions } from '../lib';
import {
  ConditionInterface,
  CombinedConditionGroupCode,
  i18n,
} from '../config';

const PURPLE_ATTRS_CODES = ['treatment'];
const WHITE_ATTRS_CODES = ['anatomy'];

export type CombinedConditionGroupColor = 'purple' | 'red' | 'white';

export type CombinedConditionGroupInterface = {
  code: CombinedConditionGroupCode;
  color: CombinedConditionGroupColor;
  text: string;
  conditionItems: ConditionInterface[];
};

const getCombinedConditionGroupColor = (
  code: CombinedConditionGroupCode,
): CombinedConditionGroupColor => {
  if (PURPLE_ATTRS_CODES.includes(code)) {
    return 'purple';
  }

  if (WHITE_ATTRS_CODES.includes(code)) {
    return 'white';
  }

  return 'red';
};

export const useCombinedConditionGroups = (
  conditionItems: ConditionInterface[],
): CombinedConditionGroupInterface[] => {
  const { formatMessage } = useIntl();

  const combinedConditionGroupsObject = getCombinedConditions(conditionItems);

  const combinedConditionGroups = Object.entries(combinedConditionGroupsObject)
    .filter(([, conditionCodes]) => !!conditionCodes.length)
    .map(([combinedConditionGroupCode, conditionCodes]) => ({
      code: combinedConditionGroupCode as CombinedConditionGroupCode,
      color: getCombinedConditionGroupColor(
        combinedConditionGroupCode as CombinedConditionGroupCode,
      ),
      text: formatMessage(
        i18n.combinedConditionCodesGroup[
          combinedConditionGroupCode as CombinedConditionGroupCode
        ],
      ),
      conditionItems: conditionCodes.map((conditionCode) =>
        conditionItems.find((item) => item.code === conditionCode),
      ),
    }));

  return combinedConditionGroups;
};
