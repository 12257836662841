// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProtectedRoutes-module__spinnerWrapper--QIWe5ZyX{display:flex;justify-content:center;align-items:center;height:100vh;width:100vw}.ProtectedRoutes-module__spinner--OZ06z2Uk{color:var(--purpleflat)}", "",{"version":3,"sources":["webpack://./src/app/providers/ProtectedRoutes.module.scss"],"names":[],"mappings":"AAAA,kDACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,YAAA,CACA,WAAA,CAGF,2CACE,uBAAA","sourcesContent":[".spinnerWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  width: 100vw;\n}\n\n.spinner {\n  color: var(--purpleflat);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerWrapper": "ProtectedRoutes-module__spinnerWrapper--QIWe5ZyX",
	"spinner": "ProtectedRoutes-module__spinner--OZ06z2Uk"
};
export default ___CSS_LOADER_EXPORT___;
