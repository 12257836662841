import { defineMessages } from 'react-intl';

export enum SliceName {
  access = 'access',
  allowedToothConditions = 'allowedToothConditions',
  assets = 'assets',
  auth = 'auth',
  billing = 'billing',
  condition = 'condition',
  marketing = 'marketing',
  messenger = 'messenger',
  modal = 'modal',
  notification = 'notification',
  organization = 'organization',
  patient = 'patient',
  reports = 'reports',
  study = 'study',
  storage = 'storage',
  studyCount = 'studyCount',
  theme = 'theme',
  tooth = 'tooth',
  user = 'user',
  newCondition = 'newCondition',
  logicalCondition = 'logicalCondition',
  toothLandmarks = 'toothLandmarks',
}

export enum ToothStatus {
  unhealthy = 'unhealthy',
  lowProbability = 'lowProbability',
  treated = 'treated',
  healthy = 'healthy',
  missing = 'missing',
}

export enum UserBadRequestError {
  INCORRECT_INVITATION_SECRET = 'incorrect_invitation_secret',
  INVITATION_RECIPIENT_ALREADY_HAS_ORGANIZATION_ROLE = 'invitation_recipient_already_has_organization_role',
  INVITATION_SECRET_INPUT_LIMIT_EXCEEDED = 'invitation_secret_input_limit_exceeded',
  MULTIPLE_PANORAMIC_XRAYS_PROVIDED = 'multiple_panoramic_xrays_provided',
  TOO_MANY_FILES_PROVIDED = 'too_many_files_provided',
  RESOURCE_IS_TOO_LARGE = 'resource_is_too_large',
  FORBIDDEN_VOLUMETRIC_DICOM = 'forbidden_volumetric_dicom',
  FORBIDDEN_BIDIMENSIONAL_DICOM = 'forbidden_bidimensional_dicom',
  FILE_IS_INVALID = 'file_is_invalid',
  LOGIN_INVALID_CREDS = 'login_invalid_creds',
  EMAIL_TAKEN = 'email_taken',
  RESET_INVALID_CREDS = 'reset_invalid_creds',
  ALREADY_REGISTERED = 'already_registered',
  CODE_RETRIES_LIMIT = 'code_retries_limit',
  CODE_IS_EXPIRED = 'code_is_expired',
  WRONG_CODE = 'wrong_code',
}

export enum ToothIconPathByToothStatus {
  unhealthy = 'Unhealthy',
  lowProbability = 'LowProbability',
  treated = 'Treated',
  healthy = 'Healthy',
}

export const REFRESH_TOKEN_COOLDOWN_MS = 240000;

export enum PrintType {
  blackAndWhite = 'black-and-white',
  colored = 'colored',
}

export const MOLAR_TOOTH_NUMBER = [
  18, 17, 16, 28, 27, 26, 38, 37, 36, 48, 47, 46,
];

export const DEFAULT_YEAR_OFFSET: number = 10;

export const DEFAULT_MEDICAL_IMAGE_HEIGHT = 140;

export const SUB_IMAGE_CROPPED_CONTEXT_PADDING = 10;

export enum ToastErrors {
  errorHeading = 'errorHeading',
}
export const toastErrors = defineMessages<ToastErrors>({
  errorHeading: {
    id: 'error.heading',
    defaultMessage: 'Error',
  },
});

export const DIAGNOCAT_EMAIL = 'cat@diagnocat.com';

export const UPLOADING_IMAGE_MAX_SIZE = 5242880;
export const UPLOADING_IMAGE_ACCEPTED_FORMATS = ['.png', '.jpg', '.jpeg'];

export const DEFAULT_LOCALE = 'en';
export const DEFAULT_LOCALE_FOR_COUNTRIES = 'en-US';

// Maybe make separate file for animation constants?
export const MODAL_ANIMATION_DURATION = 0.15;

export const WHITE_SPACE_REGEX = /\s/g;

export const FORWARD_SLASH_REGEX = /\//g;

export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[A-Z])[A-Za-z\d\p{P}\p{S}]*$/u;

export const BILLING_ZONE_WITHOUT_STRIPE_REGEXP = /cis|israel/gi;
