// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopUpLimitsModal-module__body--5gXai8mY{padding:24px;display:flex;flex-direction:column;align-items:center;gap:20px}.TopUpLimitsModal-module__priceContainer--3rvttetp{display:flex;flex-direction:column;color:var(--bw);text-align:center}.TopUpLimitsModal-module__button--iB4MXShz{min-width:304px}", "",{"version":3,"sources":["webpack://./src/widgets/CurrentSubscription/ui/TopUpLimitsModal/TopUpLimitsModal.module.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,QAAA,CAGF,mDACE,YAAA,CACA,qBAAA,CACA,eAAA,CACA,iBAAA,CAGF,2CACE,eAAA","sourcesContent":[".body {\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 20px;\n}\n\n.priceContainer {\n  display: flex;\n  flex-direction: column;\n  color: var(--bw);\n  text-align: center;\n}\n\n.button {\n  min-width: 304px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "TopUpLimitsModal-module__body--5gXai8mY",
	"priceContainer": "TopUpLimitsModal-module__priceContainer--3rvttetp",
	"button": "TopUpLimitsModal-module__button--iB4MXShz"
};
export default ___CSS_LOADER_EXPORT___;
