import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { ReportPrintSettings } from '@/shared/api/protocol_gen/model/dto_report';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { toastCaller } from '@/shared/ui';
import { ToastErrors, toastErrors } from '@/shared/config';

import { reportsModel } from '@/entities/reports';

type UsePrintSettings = {
  isLoading: boolean;
  printSettings: ReportPrintSettings;
  updatePrintSettings: (newPrintSettings: ReportPrintSettings) => void;
  showLowProbability: boolean;
};

export const usePrintSettings = (reportID: string): UsePrintSettings => {
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const report = useAppSelector(reportsModel.selectors.selectByID(reportID));

  const printSettings = report?.PrintSettings ?? {} as ReportPrintSettings;

  const [isLoading, setLoading] = useState(false);

  const showLowProbability = report?.Settings?.LowProbabilityMode ?? false;

  const updatePrintSettings = useCallback(
    async (newPrintSettings: Partial<ReportPrintSettings>) => {
      setLoading(true);

      try {
        const { Report } = await dispatch(
          reportsModel.thunks.setReportPrintSettings({
            ReportID: reportID,
            PrintSettings: newPrintSettings as ReportPrintSettings,
          }),
        ).unwrap();

        dispatch(reportsModel.actions.setNewestOne(Report));
      } catch (error) {
        const parsedMessage = JSON.parse(error?.message);
        const errorHeading = formatMessage(
          toastErrors[ToastErrors.errorHeading],
        );

        toastCaller({
          type: 'error',
          heading: errorHeading,
          message: parsedMessage?.text ?? parsedMessage?.reason,
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatch, reportID],
  );

  return {
    isLoading,
    printSettings,
    updatePrintSettings,
    showLowProbability,
  };
};
