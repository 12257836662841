import { FC, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Icon, toastCaller } from '@/shared/ui';
import { ToastErrors, toastErrors } from '@/shared/config';
import { useAppDispatch } from '@/shared/hooks';
import {
  Invitation,
  Invitation_InvitationStatus,
} from '@/shared/api/protocol_gen/model/dto_access';

import { ModalID, modalModel } from '@/entities/modal';

import styles from './SharedDoctorRow.module.scss';

type SharedDoctorRowProps = {
  sharedPatientInvitation: Invitation;
  canCancelSharing: Boolean;
  className?: string;
  testID?: string;
  lessInfo?: boolean;
  openShowAllModal?: () => void;
};

export const SharedDoctorRow: FC<SharedDoctorRowProps> = (props) => {
  const {
    sharedPatientInvitation,
    canCancelSharing,
    className,
    testID,
    lessInfo,
    openShowAllModal,
  } = props;

  const { formatMessage, formatDate } = useIntl();

  const dispatch = useAppDispatch();

  const [copied, setCopied] = useState(false);

  const email = sharedPatientInvitation.Recipient?.Email;

  const accessCode = sharedPatientInvitation.Secret;

  const isInviteAccepted =
    sharedPatientInvitation.Status ===
    Invitation_InvitationStatus.StatusAccepted;

  const openRevokeSharedPatientModal = () => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.RevokeSharedPatientModal,
        data: {
          patientID: sharedPatientInvitation.Target?.Patient?.PatientID ?? '',
          sharedPatientDoctorID: sharedPatientInvitation.Recipient?.UserID,
          invitationID: sharedPatientInvitation.ID,
          isInviteAccepted,
        },
      }),
    );
  };

  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(accessCode);

      setCopied(true);

      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      setTimeout(() => setCopied(false), 2000);

      const parsedMessage = JSON.parse(error?.message);

      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    }
  };

  const statusIconName = isInviteAccepted ? 'check' : 'time';
  const statusIconSize = isInviteAccepted ? 24 : 32;

  return (
    <div
      className={cn(styles.container, className)}
      data-testid={testID}
      onClick={openShowAllModal}
    >
      <div
        className={cn(styles.statusIcon, {
          [styles.isInviteAccepted]: isInviteAccepted,
        })}
      >
        <Icon name={statusIconName} size={statusIconSize} />
      </div>
      <div className={cn(styles.content, { [styles.showLess]: lessInfo })}>
        <p className={cn(styles.email, 'p2')}>{email}</p>

        {!lessInfo && (
          <>
            <p className={cn(styles.sharedDataList, 'p3')}>
              <FormattedMessage
                id="sharedDoctor.sharingDate"
                defaultMessage="Sharing Date:"
              />
              {sharedPatientInvitation.Created?.At ? (
                <span className={styles.sharedInfo}>
                  {sharedPatientInvitation &&
                    formatDate(new Date(sharedPatientInvitation.Created.At), {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    })}
                </span>
              ) : null}
            </p>

            {!isInviteAccepted && (
              <p className={cn(styles.sharedDataList, 'p3')}>
                <FormattedMessage
                  id="sharedDoctor.accessCode"
                  defaultMessage="Access code:"
                />
                <span className={styles.sharedInfo}>{accessCode}</span>

                <Icon
                  name={copied ? 'check' : 'copy'}
                  size={18}
                  onClick={copyToClipBoard}
                  className={cn(!copied && styles.copyIcon)}
                />
              </p>
            )}
          </>
        )}
      </div>
      <div className={styles.controls}>
        {canCancelSharing && (
          <button
            type="button"
            className={styles.button}
            onClick={openRevokeSharedPatientModal}
          >
            <Icon name="delete" size={32} />
          </button>
        )}
      </div>
    </div>
  );
};
