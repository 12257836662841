import React, { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Modal } from '@/shared/ui';
import { Invitation } from '@/shared/api/protocol_gen/model/dto_access';

import { SharedDoctorRow } from '@/features/sharedDoctorRow';

import styles from './ShowAllSharedWithModal.module.scss';

type ShowAllTreatingDoctorsModalProps = {
  className?: string;
  isOpen?: boolean;
  onClose?: () => void;
  invitations: Invitation[];
  canCancelSharing: boolean;
};

export const ShowAllSharedWithModal: FC<ShowAllTreatingDoctorsModalProps> = (
  props,
) => {
  const { className, onClose, isOpen, invitations, canCancelSharing } = props;

  const { formatMessage } = useIntl();

  return (
    <Modal
      className={cn(styles.container, className)}
      bodyClassName={styles.modalBody}
      title={formatMessage({
        id: 'patientInfo.sharedWith',
        defaultMessage: 'Shared with',
      })}
      isOpen={isOpen}
      onClose={onClose}
      hideFooter
    >
      <div className={styles.content}>
        {invitations.map((invitation) => (
          <SharedDoctorRow
            key={invitation.ID}
            canCancelSharing={canCancelSharing}
            sharedPatientInvitation={invitation}
          />
        ))}
      </div>
    </Modal>
  );
};
