// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../shared/assets/images/error_404.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListLoadingFailed-module__container--y-knccky{display:flex;align-items:center;justify-content:center;height:70vh;background-color:var(--bg2);border-radius:16px}.ListLoadingFailed-module__content--GzFUd6xE{display:flex;flex-direction:column;justify-content:center;align-items:center}.ListLoadingFailed-module__logo--j\\+kCs0hp{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;width:130px;height:130px;margin-bottom:24px}.ListLoadingFailed-module__text--DMTNfLid{margin-bottom:24px;color:var(--text5);opacity:.5}", "",{"version":3,"sources":["webpack://./src/pages/Patients/ui/ListLoadingFailed/ListLoadingFailed.module.scss"],"names":[],"mappings":"AAAA,+CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,2BAAA,CACA,kBAAA,CAGF,6CACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAGF,2CACE,kDAAA,CACA,uBAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CAGF,0CACE,kBAAA,CACA,kBAAA,CACA,UAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 70vh;\n  background-color: var(--bg2);\n  border-radius: 16px;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.logo {\n  background: url('@/shared/assets/images/error_404.png');\n  background-size: contain;\n  width: 130px;\n  height: 130px;\n  margin-bottom: 24px;\n}\n\n.text {\n  margin-bottom: 24px;\n  color: var(--text5);\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ListLoadingFailed-module__container--y-knccky",
	"content": "ListLoadingFailed-module__content--GzFUd6xE",
	"logo": "ListLoadingFailed-module__logo--j+kCs0hp",
	"text": "ListLoadingFailed-module__text--DMTNfLid"
};
export default ___CSS_LOADER_EXPORT___;
