import { useMemo } from 'react';
import { uniq } from 'ramda';
import { isEqual } from 'lodash';

import { IOXRaySubimage } from '@/shared/api/protocol_gen/model/dto_report_type_ioxray_gp';
import { useAppSelector } from '@/shared/hooks';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import { reportsModel } from '@/entities/reports';
import { toothModel, ToothWithLocalization } from '@/entities/tooth';

import { doBBoxOverlap } from '../lib/doBBoxOverlap';
import { isSubImageHasToothLocalization } from '../lib/isSubImageHasToothLocalization';

export type SubImageInterface = IOXRaySubimage & {
  teethISONumbers: number[];
  teethLocalizations: ToothWithLocalization[];
};

// TODO: Refactor for IOXRay preview images
// this hook will be renamed or changed when the preview for subImages is ready
export const useTransformSubImageDeprecated = (
  reportID: string,
): SubImageInterface[] => {
  const report = useAppSelector(
    reportsModel.selectors.selectByID(reportID),
    isEqual,
  );

  const teeth: Tooth[] = useAppSelector(
    toothModel.selectors.selectByReportID(reportID),
    isEqual,
  );

  const subImageInterface = useMemo(
    () =>
      report?.DataIOXRayGP?.Subimages?.map((subImage) => {
        const teethLocalizations: ToothWithLocalization[] = [];
        const teethISONumbers: number[] = teeth.reduce((isoNumbers, tooth) => {
          if (isSubImageHasToothLocalization(subImage, tooth)) {
            tooth.Localizations.forEach((localization) => {
              if (
                subImage?.BBox &&
                localization.BBox &&
                tooth.Numeration?.ISO &&
                subImage.TargetAssetID === localization.TargetAssetID
              ) {
                const isBBoxOverlap = doBBoxOverlap(
                  subImage?.BBox,
                  localization.BBox,
                );

                if (isBBoxOverlap) {
                  isoNumbers.push(tooth.Numeration?.ISO);
                  teethLocalizations.push({
                    toothID: tooth.ID,
                    ISONumber: tooth.Numeration.ISO,
                    Localization: localization,
                  });
                }
              }
            });
          }

          return isoNumbers;
        }, [] as number[]);

        return {
          ...subImage,
          teethISONumbers: uniq(teethISONumbers),
          teethLocalizations,
        };
      }),
    [teeth, report?.DataIOXRayGP?.Subimages],
  );

  return subImageInterface || [];
};
