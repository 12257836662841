import { array, object, string } from '@/shared/config/yup';

import { QuestionnaireSteps } from '@/entities/marketing/config/types';

const step1Schema = object({
  CompanyName: string().required(),
});

const step2Schema = object({
  OwnerSpecialization: string().required(),
});

const step3Schema = object({
  CompanyProfile: string().required(),
});

const step4Schema = object({
  CBCTMachineType: array().of(string()).nullable(),
});

const step5Schema = object({
  CBCTImagingSoftware: array().of(string()).nullable(),
});

const step6Schema = object({
  XRayImagingSoftware: array().of(string()).nullable(),
});

const step7Schema = object({
  FocusOfInterest: array().of(string()).min(1).nullable().required(),
});

export const additionalInfoFormSchema = {
  [QuestionnaireSteps.CompanyName]: step1Schema,
  [QuestionnaireSteps.OwnerSpecialization]: step2Schema,
  [QuestionnaireSteps.CompanyProfile]: step3Schema,
  [QuestionnaireSteps.CBCTMachineType]: step4Schema,
  [QuestionnaireSteps.CBCTImagingSoftware]: step5Schema,
  [QuestionnaireSteps.XRayImagingSoftware]: step6Schema,
  [QuestionnaireSteps.FocusOfInterest]: step7Schema,
};
