import { filter, isEmpty, keys } from 'ramda';

import { useAppSelector } from '@/shared/hooks';

import { allowedToothConditionsModel } from '@/entities/allowedToothConditions';

import { ConditionGroups, ConditionInterface } from '../config';

type ConditionItemsByGroupsType = Record<ConditionGroups, ConditionInterface[]>;

export const useConditionGroupsList = (
  conditionItemsByGroups: ConditionItemsByGroupsType,
  toothID: string,
) => {
  // TODO: [2/m] discuss with the backend the possibility of obtaining allowedToothConditions in the tooth or condition entity and remove the allowedToothConditions entity
  const allowedToothConditions = useAppSelector((state) =>
    allowedToothConditionsModel.selectors.selectById(state, toothID),
  );

  const filteredConditionItemsByGroups = Object.entries(
    conditionItemsByGroups,
  ).reduce((result, [group, items]) => {
    const filteredItems = items.filter((item) =>
      allowedToothConditions?.ConditionCodes.includes(item.code),
    );

    if (filteredItems.length > 0) {
      result[group as keyof ConditionItemsByGroupsType] = filteredItems;
    }

    return result;
  }, {} as Partial<ConditionItemsByGroupsType>);

  const conditionGroupsList = keys(filteredConditionItemsByGroups);

  const openConditionGroups = filter((group: ConditionGroups) => {
    const conditionItems = filteredConditionItemsByGroups[group] ?? [];
    return conditionItems.some((item) => item.isChecked);
  })(conditionGroupsList);

  const isOpenGroupsList = !isEmpty(openConditionGroups);

  return { conditionGroupsList, openConditionGroups, isOpenGroupsList };
};
