import { generatePath } from 'react-router';
import { useIntl } from 'react-intl';

import {
  BreadcrumbElementType,
  LocationStateType,
  PATHS,
} from '@/shared/config';
import { useAppSelector, useLocationState } from '@/shared/hooks';

import * as patientModel from '../model';

type PatientBreadcrumbs = {
  patientProfile: BreadcrumbElementType;
  patients: BreadcrumbElementType;
};

export const usePatientBreadcrumbs = (
  patientID: string,
): PatientBreadcrumbs => {
  const { formatMessage } = useIntl();

  const { lastPositionPatientProfile, recentReportIndex, lastOpenTabStudyID } =
    useLocationState();

  const patientFullName = useAppSelector(
    patientModel.selectors.selectPatientFullName(patientID),
  );

  const patientProfilePath = generatePath(PATHS.patientProfile, { patientID });

  const patientProfileState: LocationStateType = {
    // When returning to the PatientProfile, we clear the location state for the report
    lastPositionReport: 0,
    toothID: '',
    lastPositionPatientProfile,
    recentReportIndex,
    lastOpenTabStudyID,
  };

  return {
    patientProfile: {
      label: patientFullName,
      path: patientProfilePath,
      state: patientProfileState,
    },
    patients: {
      label: formatMessage({
        id: 'navigation.patients',
        defaultMessage: 'Patients',
      }),
      path: PATHS.patients,
      // When returning to the PatientList, we clear the entire location state
      state: {
        lastPositionReport: 0,
        toothID: '',
        lastPositionPatientProfile: 0,
        recentReportIndex: 0,
        lastOpenTabStudyID: '',
      },
    },
  };
};
