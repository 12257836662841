import { FC, memo } from 'react';
import cn from 'classnames';

import { MedicalImageInterface } from '@/shared/config';

import { Group, PathologyGroup } from '@/entities/assets';

import { MedicalImageGroupSection } from '../MedicalImageGroupSection/MedicalImageGroupSection';

import styles from './MedicalImagesGroup.module.scss';

type MedicalImagesGroupProps = {
  selectedMedicalImages?: MedicalImageInterface[];
  groups: (Group | PathologyGroup)[];
  onDragMedicalImage?: (medicalImage: MedicalImageInterface) => void;
  toggleSelected?: (medicalImage: MedicalImageInterface) => void;
  className?: string;
};

export const MedicalImagesGroup: FC<MedicalImagesGroupProps> = memo((props) => {
  const {
    className,
    selectedMedicalImages,
    toggleSelected,
    onDragMedicalImage,
    groups,
  } = props;

  return (
    <div className={cn(styles.container, className)}>
      {groups.length &&
        groups.map((group) => (
          <MedicalImageGroupSection
            key={group.type}
            group={group}
            selectedMedicalImages={selectedMedicalImages}
            onDragMedicalImage={onDragMedicalImage}
            toggleSelected={toggleSelected}
          />
        ))}
    </div>
  );
});
