import { SubImageInterface } from '@/features/IOXRayMatrix';

export const getTeethIDsFromSubImages = (subImages: SubImageInterface[]) =>
  subImages.reduce(
    (ids, subImage) => [
      ...ids,
      ...subImage.teethLocalizations.map(
        (toothLocalization) => toothLocalization.toothID,
      ),
    ],
    [] as string[],
  );
