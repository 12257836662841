import { ToolNames } from '@/shared/config';

export const POPUP_DEFAULT_SIZE = 320;

export const MEDICAL_IMAGE_HORIZONTAL_PADDING = 178;
export const MEDICAL_IMAGE_VERTICAL_PADDING = 172;
export const MEDICAL_IMAGE_MAX_SIZE = 800;
export const MEDICAL_IMAGE_CONTAINER_MARGINS = 2 * 64;

export const CONTROLS: ToolNames[] = [
  'ruler',
  'arrow',
  'eraser',
  'brightness',
  'sharpness',
  'angle',
  'reset',
];
