// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InlineFormError-module__container--t\\+e0e7HV{display:flex;justify-content:flex-start;align-items:center;gap:12px;padding:8px 12px;border:1px solid var(--redrose);border-radius:12px;background-color:rgba(var(--redrose-rgb), 5%)}.InlineFormError-module__icon--p4eweVjZ{color:var(--redrose)}", "",{"version":3,"sources":["webpack://./src/shared/ui/InlineFormError/InlineFormError.module.scss"],"names":[],"mappings":"AAAA,8CACE,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,QAAA,CACA,gBAAA,CACA,+BAAA,CACA,kBAAA,CACA,6CAAA,CAGF,wCACE,oBAAA","sourcesContent":[".container {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 12px;\n  padding: 8px 12px;\n  border: 1px solid var(--redrose);\n  border-radius: 12px;\n  background-color: rgba(var(--redrose-rgb), 5%);\n}\n\n.icon {\n  color: var(--redrose);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "InlineFormError-module__container--t+e0e7HV",
	"icon": "InlineFormError-module__icon--p4eweVjZ"
};
export default ___CSS_LOADER_EXPORT___;
