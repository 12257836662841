import { useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';
import { useParams } from 'react-router-dom';

import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { Study } from '@/shared/api/protocol_gen/model/dto_study';
import { StreamDataAccumulatorKey } from '@/shared/config';
import api from '@/shared/api/api';

type ReportDataStreamDataAccumulators = {
  [StreamDataAccumulatorKey.study]: Study[];
  [StreamDataAccumulatorKey.conditions]: Condition[];
  [StreamDataAccumulatorKey.teeth]: Tooth[];
  [StreamDataAccumulatorKey.assets]: Asset[];
};

const dataAccumulators: ReportDataStreamDataAccumulators = {
  [StreamDataAccumulatorKey.study]: [],
  [StreamDataAccumulatorKey.conditions]: [],
  [StreamDataAccumulatorKey.teeth]: [],
  [StreamDataAccumulatorKey.assets]: [],
};

const useReportDataStream = (reportID: string) => {
  const reportDataStream = useRef<Subscription>(undefined);

  const openReportDataStream = () => {
    performance.mark('openReportDataStream');

    reportDataStream.current = api.report
      .ReportDataStream({ ReportID: reportID })
      .subscribe({
        next: (data) => {
          if (data.HistoricalPatient) {
            performance.mark('ReportDataStream:HistoricalPatient');

            const reportDataStreamPatientMeasure = performance.measure(
              'ReportDataStream>>>HistoricalPatient',
              'openReportDataStream',
              'ReportDataStream:HistoricalPatient',
            );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamPatientMeasure);
          }

          if (data.HistoricalStudy) {
            performance.mark('ReportDataStream:HistoricalStudy');

            const reportDataStreamStudyMeasure = performance.measure(
              'ReportDataStream>>>HistoricalStudy',
              'openReportDataStream',
              'ReportDataStream:HistoricalStudy',
            );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamStudyMeasure);
          }

          if (data.EndOfHistoricalStudies) {
            performance.mark('ReportDataStream:EndOfHistoricalStudies');

            const reportDataStreamEndOfHistoricalStudiesMeasure =
              performance.measure(
                'ReportDataStream>>>EndOfHistoricalStudies',
                'openReportDataStream',
                'ReportDataStream:EndOfHistoricalStudies',
              );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamEndOfHistoricalStudiesMeasure);
          }

          if (data.HistoricalReport) {
            performance.mark('ReportDataStream:HistoricalReport');

            const reportDataStreamHistoricalReportMeasure = performance.measure(
              'ReportDataStream>>>HistoricalReport',
              'openReportDataStream',
              'ReportDataStream:HistoricalReport',
            );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamHistoricalReportMeasure);
          }

          if (data.HistoricalTooth) {
            if (dataAccumulators[StreamDataAccumulatorKey.teeth].length === 0) {
              performance.mark('ReportDataStream:HistoricalTooth:first');
            }

            dataAccumulators[StreamDataAccumulatorKey.teeth].push(
              data.HistoricalTooth,
            );
          }

          if (data.EndOfHistoricalTeeth) {
            performance.mark('ReportDataStream:EndOfHistoricalTeeth');

            const reportDataStreamEndOfHistoricalTeethMeasure =
              performance.measure(
                'ReportDataStream>>>EndOfHistoricalTeeth',
                'openReportDataStream',
                'ReportDataStream:EndOfHistoricalTeeth',
              );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamEndOfHistoricalTeethMeasure);

            const reportDataStreamHistoricalToothMeasure = performance.measure(
              'ReportDataStream:HistoricalTooth:first>>>EndOfHistoricalTeeth',
              'ReportDataStream:HistoricalTooth:first',
              'ReportDataStream:EndOfHistoricalTeeth',
            );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamHistoricalToothMeasure);

            dataAccumulators[StreamDataAccumulatorKey.teeth] = [];
          }

          if (data.HistoricalCondition) {
            if (
              dataAccumulators[StreamDataAccumulatorKey.conditions].length === 0
            ) {
              performance.mark('ReportDataStream:HistoricalCondition:first');
            }
            dataAccumulators[StreamDataAccumulatorKey.conditions].push(
              data.HistoricalCondition,
            );
          }

          if (data.HistoricalAsset) {
            if (
              dataAccumulators[StreamDataAccumulatorKey.assets].length === 0
            ) {
              performance.mark('ReportDataStream:HistoricalAsset:first');
            }
            dataAccumulators[StreamDataAccumulatorKey.assets].push(
              data.HistoricalAsset,
            );
          }

          if (data.EndOfHistoricalAssets) {
            performance.mark('ReportDataStream:EndOfHistoricalAssets');

            const reportDataStreamEndOfHistoricalAssetsMeasure =
              performance.measure(
                'ReportDataStream:openReportDataStream>>>EndOfHistoricalAssets',
                'openReportDataStream',
                'ReportDataStream:EndOfHistoricalAssets',
              );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamEndOfHistoricalAssetsMeasure);

            const reportDataStreamHistoricalAssetMeasure = performance.measure(
              'ReportDataStream:HistoricalAsset:first>>>EndOfHistoricalAssets',
              'ReportDataStream:HistoricalAsset:first',
              'ReportDataStream:EndOfHistoricalAssets',
            );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamHistoricalAssetMeasure);

            dataAccumulators[StreamDataAccumulatorKey.assets] = [];
          }

          if (data.EndOfHistoricalConditions) {
            performance.mark('ReportDataStream:EndOfHistoricalConditions');

            const reportDataStreamMeasure = performance.measure(
              'ReportDataStream>>>EndOfHistoricalConditions',
              'openReportDataStream',
              'ReportDataStream:EndOfHistoricalConditions',
            );
            // eslint-disable-next-line no-console
            console.log(reportDataStreamMeasure);

            const reportDataStreamHistoricalConditionMeasure =
              performance.measure(
                'ReportDataStream:HistoricalCondition:first>>>EndOfHistoricalConditions',
                'ReportDataStream:HistoricalCondition:first',
                'ReportDataStream:EndOfHistoricalConditions',
              );

            // eslint-disable-next-line no-console
            console.log(reportDataStreamHistoricalConditionMeasure);

            dataAccumulators[StreamDataAccumulatorKey.conditions] = [];
          }
        },
        error: () => {
          // Do nothing
        },
        complete: () => {
          // Do nothing
        },
      });
  };

  const closeReportDataStream = () => {
    if (reportDataStream.current) {
      reportDataStream.current.unsubscribe();
    }
  };

  useEffect(() => {
    openReportDataStream();

    return () => {
      closeReportDataStream();
    };
  }, []);
};

export const TestReportStreamPerformance = () => {
  const { reportID = '' } = useParams();

  useReportDataStream(reportID);

  return (
    <div>
      <h1>TestReportStreamPerformance</h1>
    </div>
  );
};
