// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Back-module__backButton--YmNXvmIR{display:inline-flex;align-items:center;gap:4px;color:var(--purpletext2);cursor:pointer}.Back-module__backButton--YmNXvmIR:hover{text-decoration:underline}.Back-module__backButtonIcon--RttR\\+Xqd{color:var(--purpledec);rotate:90deg}", "",{"version":3,"sources":["webpack://./src/shared/ui/Back/Back.module.scss"],"names":[],"mappings":"AAAA,mCACE,mBAAA,CACA,kBAAA,CACA,OAAA,CACA,wBAAA,CACA,cAAA,CAEA,yCACE,yBAAA,CAIJ,wCACE,sBAAA,CACA,YAAA","sourcesContent":[".backButton {\n  display: inline-flex;\n  align-items: center;\n  gap: 4px;\n  color: var(--purpletext2);\n  cursor: pointer;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n\n.backButtonIcon {\n  color: var(--purpledec);\n  rotate: 90deg;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": "Back-module__backButton--YmNXvmIR",
	"backButtonIcon": "Back-module__backButtonIcon--RttR+Xqd"
};
export default ___CSS_LOADER_EXPORT___;
