import { FC } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { BreadcrumbElementType } from '@/shared/config';

import { Skeleton } from '../Skeleton/Skeleton';

import styles from './Breadcrumbs.module.scss';

type BreadcrumbsProps = {
  config: BreadcrumbElementType[];
  className?: string;
  testID?: string;
};

export const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const { config, className, testID } = props;

  const hasEmptyLabels = config.some((item) => item.label === '');

  if (hasEmptyLabels) {
    return <Skeleton.Filter filtersQuantity={config.length} />;
  }

  return (
    <nav className={cn(styles.container, className)} data-testid={testID}>
      <ul className={styles.breadcrumbsList}>
        {config.map((breadcrumb) => (
          <li
            className={cn(styles.listItem, className)}
            data-testid={testID}
            key={breadcrumb.path}
          >
            <Link
              to={breadcrumb.path}
              state={breadcrumb.state}
              className={cn(styles.link, 'p1')}
            >
              {breadcrumb.label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
