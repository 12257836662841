import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { Icon } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { FMXNavigationMode, reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';

import styles from './SubImageSwitch.module.scss';

type SubImageSwitchProps = {
  className?: string;
  handleClickAllMatrix: () => void;
  handleScrollToothCardsToTop: () => void;
};

export const SubImageSwitch: FC<SubImageSwitchProps> = (props) => {
  const { className, handleClickAllMatrix, handleScrollToothCardsToTop } =
    props;

  const subImageIDs = useAppSelector(reportsModel.selectors.selectSubImagesIDs);
  const disabledSubImagesIDs = useAppSelector(
    reportsModel.selectors.selectDisabledSubImagesIDs,
  );

  const dispatch = useAppDispatch();

  const ROITeeth: Tooth[] = useAppSelector(toothModel.selectors.selectROITeeth);

  const activePage = useAppSelector(
    reportsModel.selectors.selectActivePageNumber,
  );

  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );

  const shownSubImagesIDs = useAppSelector(
    reportsModel.selectors.selectShownSubImagesIDs,
  );

  const count =
    navigationMode === FMXNavigationMode.MatrixView
      ? subImageIDs.length - disabledSubImagesIDs.length
      : ROITeeth.length;

  const handleChangeActivePage = (page: number) => {
    if (page < 0 || page > count) {
      return;
    }
    if (page === 0) {
      handleClickAllMatrix();
      return;
    }

    if (navigationMode === FMXNavigationMode.MatrixView) {
      dispatch(
        reportsModel.actions.setActiveSubImagesIDs([
          shownSubImagesIDs[page - 1],
        ]),
      );

      handleScrollToothCardsToTop();
    } else {
      dispatch(reportsModel.actions.setActiveToothID(ROITeeth[page - 1].ID));
    }

    dispatch(reportsModel.actions.setActivePage(page));
  };

  const handleKeyboard = (event: KeyboardEventInit) => {
    if (event.code === 'ArrowRight') {
      handleChangeActivePage(activePage + 1);
    } else if (event.code === 'ArrowLeft') {
      handleChangeActivePage(activePage - 1);
    } else if (event.code === 'Escape') {
      handleClickAllMatrix();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboard);
    return () => {
      window.removeEventListener('keydown', handleKeyboard);
    };
  }, [handleKeyboard]);

  return (
    <div className={cn(styles.container, className)}>
      <button
        className={styles.miniMatrix}
        type="button"
        onClick={handleClickAllMatrix}
      >
        <FormattedMessage id="IOXRayReport.ViewAll" defaultMessage="View All" />
      </button>
      <Icon
        className={styles.prevIcon}
        name="arrowDown"
        onClick={() => handleChangeActivePage(activePage - 1)}
        size={24}
      />
      <span className={cn('p2', styles.counter)}>
        {activePage === 0 ? (
          <FormattedMessage id="global.all" defaultMessage="All" />
        ) : (
          activePage
        )}
      </span>
      <span className={cn('p2', styles.greyText)}>
        {' '}
        <FormattedMessage id="global.of" defaultMessage="Of" />{' '}
      </span>
      <span className={cn('p2', styles.greyText)}>{count}</span>
      <Icon
        className={styles.nextIcon}
        name="arrowDown"
        onClick={() => handleChangeActivePage(activePage + 1)}
        size={24}
      />
    </div>
  );
};
