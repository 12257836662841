// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SwitchMaskType-module__container--sbliM1zY{display:flex;gap:4px;align-items:center;padding:4px 0}.SwitchMaskType-module__container--sbliM1zY img{width:32px;height:32px;border-radius:4px;cursor:pointer}.SwitchMaskType-module__container--sbliM1zY img:hover{box-shadow:0 0 0 2px var(--purpleflat)}.SwitchMaskType-module__maskTypesImagesWrapper--mtIR8x1y{display:none;align-items:center;gap:4px}.SwitchMaskType-module__toggled--N-cuGxv4{display:flex}", "",{"version":3,"sources":["webpack://./src/pages/IOXRayReport/ui/SwitchMaksType/SwitchMaskType.module.scss"],"names":[],"mappings":"AAAA,4CACE,YAAA,CACA,OAAA,CACA,kBAAA,CACA,aAAA,CAEA,gDACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,cAAA,CAEA,sDACE,sCAAA,CAKN,yDACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,0CACE,YAAA","sourcesContent":[".container {\n  display: flex;\n  gap: 4px;\n  align-items: center;\n  padding: 4px 0;\n\n  img {\n    width: 32px;\n    height: 32px;\n    border-radius: 4px;\n    cursor: pointer;\n\n    &:hover {\n      box-shadow: 0 0 0 2px var(--purpleflat);\n    }\n  }\n}\n\n.maskTypesImagesWrapper {\n  display: none;\n  align-items: center;\n  gap: 4px;\n}\n\n.toggled {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SwitchMaskType-module__container--sbliM1zY",
	"maskTypesImagesWrapper": "SwitchMaskType-module__maskTypesImagesWrapper--mtIR8x1y",
	"toggled": "SwitchMaskType-module__toggled--N-cuGxv4"
};
export default ___CSS_LOADER_EXPORT___;
