// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UploadImage-module__container--lTyUEpQy{display:flex;flex-direction:column;align-items:flex-start;gap:12px}.UploadImage-module__container--lTyUEpQy>div{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/features/uploadImage/ui/UploadImage/UploadImage.module.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,QAAA,CAEA,6CACE,cAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 12px;\n\n  & > div {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UploadImage-module__container--lTyUEpQy"
};
export default ___CSS_LOADER_EXPORT___;
