import { BBox } from '@/shared/api/protocol_gen/model/dto_common_geometry';
import { SUB_IMAGE_CROPPED_CONTEXT_PADDING } from '@/shared/config';

const defaultBBox: BBox = {
  X: {
    Min: 0,
    Max: 0,
    Size: 0,
  },
  Y: {
    Min: 0,
    Max: 0,
    Size: 0,
  },
  Z: {
    Min: 0,
    Max: 0,
    Size: 0,
  },
};

export const transformBBoxToCrop = (cropBBox: BBox = defaultBBox) => ({
  x: (cropBBox?.X?.Min ?? 0) - SUB_IMAGE_CROPPED_CONTEXT_PADDING,
  y: (cropBBox?.Y?.Min ?? 0) - SUB_IMAGE_CROPPED_CONTEXT_PADDING,
  w: (cropBBox?.X?.Max ?? 0) - (cropBBox?.X?.Min ?? 0) + SUB_IMAGE_CROPPED_CONTEXT_PADDING * 2,
  h: (cropBBox?.Y?.Max ?? 0) - (cropBBox?.Y?.Min ?? 0) + SUB_IMAGE_CROPPED_CONTEXT_PADDING * 2,
});
