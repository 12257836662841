import { ReactNode } from 'react';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { ApiError } from '@/shared/api/api';
import { PATHS, UserBadRequestError } from '@/shared/config';
import { toastCaller } from '@/shared/ui';

export const errorHandler = (
  error: ApiError,
  setError: UseFormSetError<FieldValues>,
) => {
  const { message } = error;
  const parsedMessage = JSON.parse(message);

  const isInvalidCredsError =
    parsedMessage?.code === UserBadRequestError.RESET_INVALID_CREDS;

  const errorHeading = (
    <FormattedMessage id="global.error" defaultMessage="Error" />
  );

  if (isInvalidCredsError) {
    const errorMessage: unknown = (
      <FormattedMessage
        id="resetPassword.errors.invalidCredsError"
        defaultMessage="This email is not registered. You can <a>Sign up</a> instead."
        values={{
          a: (chunks: ReactNode) => (
            <a style={{ color: 'var(--purpleflat)' }} href={PATHS.signUp}>
              {chunks}
            </a>
          ),
        }}
      />
    );

    setError('root', {
      message: errorMessage as string,
    });
  } else {
    toastCaller({
      type: 'error',
      heading: errorHeading,
      message: parsedMessage?.type,
      autoClose: false,
    });
  }
};
