import { FC } from 'react';
import cn from 'classnames';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { generatePath, useNavigate } from 'react-router';
import { ColumnDef } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';
import { sort } from 'ramda';
import { isEmpty } from 'lodash';

import { useAppSelector } from '@/shared/hooks';
import { PATHS, SortByFunctionType } from '@/shared/config';
import { TableWithInfiniteScroll } from '@/shared/ui';
import { Invitation } from '@/shared/api/protocol_gen/model/dto_access';

import { accessModel } from '@/entities/access';

import { RevokeSharedPatientModal } from '@/features/sharePatient';

import { EmptyResult } from '../EmptyResult/EmptyResult';
import { getSortedInvitationsListByStatus } from '../../utils/getSortedInvitationsListByStatus';
import { ListLoadingFailed } from '../ListLoadingFailed/ListLoadingFailed';

import styles from './SharedPatientList.module.scss';

type SharedPatientListProps = {
  sharedPatientColumnsScheme: ColumnDef<Invitation, unknown>[];
  sharedPatientCounter: number;
  reFetchPatients: (id: string) => void;
  sortBy: SortByFunctionType;
  showSharedByColumn?: boolean;
  className?: string;
  testID?: string;
  emptyText?: string;
};

export const SharedPatientList: FC<SharedPatientListProps> = (props) => {
  const {
    sharedPatientColumnsScheme,
    sharedPatientCounter,
    reFetchPatients,
    sortBy,
    showSharedByColumn,
    className,
    testID,
    emptyText,
  } = props;

  const invitationsList = useAppSelector(accessModel.selectors.selectAll);

  const sortedInvitationsList = sort(
    getSortedInvitationsListByStatus,
    invitationsList,
  );

  const accessListLoading = useAppSelector((state) => state.access.loading);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const search = searchParams.get('search');

  const queryClient = new QueryClient();

  const isListLoadingFailed = accessListLoading === 'failed';

  const showSkeleton =
    (accessListLoading === 'pending' || accessListLoading === 'idle') &&
    sortedInvitationsList.length === 0;

  const showNothingFound =
    accessListLoading === 'succeeded' &&
    sortedInvitationsList.length === 0 &&
    Boolean(search);

  const showEmpty =
    accessListLoading === 'succeeded' &&
    sortedInvitationsList.length === 0 &&
    isEmpty(search);

  const navigateToPatientProfile = (patientID: string) => {
    navigate({
      pathname: generatePath(PATHS.patientProfile, { patientID }),
    });
  };

  const navigateToOrganizationInvite = (token: string) => {
    navigate({
      pathname: generatePath(PATHS.organization_invite, { token }),
    });
  };

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      {isListLoadingFailed && <ListLoadingFailed />}

      {/* TODO: [1/l] may need to move the QueryClientProvider higher up in the routing */}
      {!isListLoadingFailed && (
        <QueryClientProvider client={queryClient}>
          <TableWithInfiniteScroll
            showSharedByColumn={showSharedByColumn}
            listData={sortedInvitationsList}
            reFetchList={reFetchPatients}
            itemCounter={sharedPatientCounter}
            clickOnRow={navigateToPatientProfile}
            conditionalAdditionalClickOnRow={navigateToOrganizationInvite}
            columnsScheme={sharedPatientColumnsScheme}
            loadingState="access"
            sortBy={sortBy}
            showSkeleton={showSkeleton}
            showNothingFound={showNothingFound}
            showEmpty={showEmpty}
            emptyComponent={<EmptyResult emptyText={emptyText} />}
            serverSideSorting
          />
        </QueryClientProvider>
      )}

      <RevokeSharedPatientModal />
    </div>
  );
};
