import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStateType, SliceName } from '@/shared/config';
import { Account } from '@/shared/api/protocol_gen/api/billing_new/dto_account_new';
import { Order } from '@/shared/api/protocol_gen/api/billing_new/dto_order_new';
import {
  ProductModel,
  ProductState,
} from '@/shared/api/protocol_gen/api/billing_new/dto_product_new';

import {
  cancelSubscription,
  getAvailableProducts,
} from './billingSlice.thunks';

const initialState = {
  account: {} as Account,
  inventory: [] as ProductState[],
  invoices: [] as Order[],
  availableProducts: [] as ProductModel[],
  subscriptionLifeTime: 1,
  hasOverdueSubscription: false,
  CBCTConsumption: { limit: 0, leftover: 0 },
  isCancelWarningOpen: false,
  isSubscriptionCancellationPending: false,
  orderIDForPayment: '',
  selectedTopUpPackageID: '',
  loading: 'idle' as LoadingStateType,
};

const billingSlice = createSlice({
  name: SliceName.billing,
  initialState,
  reducers: {
    setAccount: (state, { payload }: PayloadAction<Account>) => {
      state.account = payload;
    },
    setInventory: (state, { payload }: PayloadAction<ProductState[]>) => {
      state.inventory = payload;
    },
    setInvoices: (state, action: PayloadAction<Order[]>) => {
      state.invoices = action.payload.reverse();
    },
    setAvailableProducts: (
      state,
      { payload }: PayloadAction<ProductModel[]>,
    ) => {
      state.availableProducts = payload.filter(
        (product) => product?.Subscription && !product.AvailableForAdminsOnly,
      );
    },
    setSubscriptionLifeTime: (state, { payload }: PayloadAction<number>) => {
      state.subscriptionLifeTime = payload;
    },
    updateInventory: (state, { payload }: PayloadAction<ProductState>) => {
      const inventoryForUpdateIndex = state.inventory.findIndex(
        (inventoryItem) => inventoryItem.ID === payload.ID,
      );

      if (inventoryForUpdateIndex < 0) {
        state.inventory = [payload].concat(state.inventory);
      } else {
        state.inventory[inventoryForUpdateIndex] = payload;
      }
    },
    updateOrders: (state, action: PayloadAction<Order>) => {
      const orderForUpdateIndex = state.invoices.findIndex(
        (invoice) => invoice.ID === action.payload.ID,
      );

      if (orderForUpdateIndex < 0) {
        state.invoices = [action.payload].concat(state.invoices);
      } else {
        state.invoices[orderForUpdateIndex] = action.payload;
      }
    },
    setHasOverdueSubscription: (state, action: PayloadAction<boolean>) => {
      state.hasOverdueSubscription = action.payload;
    },
    setIsCancelWarningOpen: (state, action: PayloadAction<boolean>) => {
      state.isCancelWarningOpen = action.payload;
    },
    setIsSubscriptionCancellationPending: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isSubscriptionCancellationPending = action.payload;
    },
    setOrderIDForPayment: (state, { payload }: PayloadAction<string>) => {
      state.orderIDForPayment = payload;
    },
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
    setSelectedTopUpPackageID: (state, { payload }: PayloadAction<string>) => {
      state.selectedTopUpPackageID = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAvailableProducts.fulfilled, (state, { payload }) => {
      if (payload.AvailableProducts?.length > 0) {
        state.availableProducts = payload.AvailableProducts;
        state.selectedTopUpPackageID =
          payload.AvailableProducts.find(
            (product) => product?.Addon && !product.AvailableForAdminsOnly,
          )?.ID ?? '';
      }
    });
    builder.addCase(cancelSubscription.pending, (state) => {
      state.isSubscriptionCancellationPending = true;
    });
    builder.addCase(cancelSubscription.fulfilled, (state) => {
      state.isSubscriptionCancellationPending = false;
      state.isCancelWarningOpen = false;
    });
    builder.addCase(cancelSubscription.rejected, (state) => {
      state.isSubscriptionCancellationPending = false;
    });
  },
});

export const { actions } = billingSlice;

export default billingSlice.reducer;
