import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Control, Controller } from 'react-hook-form';

import { RadioGroup, Switch } from '@/shared/ui';

import { JAW } from '@/entities/tooth';

import { TeethChart } from '@/features/toothChart';

import { useGetOutputFilesForSegmentation } from '../../hooks/useGetOutputFilesForSegmentation';
import { useMakeTeethItems } from '../../hooks/useMakeTeethItems';
import { Order3DSegmentronFormSchemaFormPayload } from '../../config/formSchema';

import styles from './BasicCBCTToSTL.module.scss';

export type BasicCBCTToSTLProps = {
  className?: string;
  cbctImage: string;
  control: Control<Order3DSegmentronFormSchemaFormPayload>;
  onTeethNumbersChange: (isoNumbers: number[]) => void;
};

export const BasicCBCTToSTL: FC<BasicCBCTToSTLProps> = (props) => {
  const { className, cbctImage, control, onTeethNumbersChange } = props;
  const { formatMessage } = useIntl();

  const outputFilesForSegmentation = useGetOutputFilesForSegmentation();

  const { teethItems, changeToothState, changeTeethState, activeTeeth } =
    useMakeTeethItems(onTeethNumbersChange);

  const isQuartile1Active = JAW.quartile1.every((item) =>
    activeTeeth.includes(item),
  );
  const isQuartile2Active = JAW.quartile2.every((item) =>
    activeTeeth.includes(item),
  );
  const isQuartile3Active = JAW.quartile3.every((item) =>
    activeTeeth.includes(item),
  );
  const isQuartile4Active = JAW.quartile4.every((item) =>
    activeTeeth.includes(item),
  );

  const isAllTeethActive =
    isQuartile1Active &&
    isQuartile2Active &&
    isQuartile3Active &&
    isQuartile4Active;

  return (
    <div className={className}>
      <img
        src={cbctImage}
        alt={formatMessage({
          id: 'global.cbct',
          defaultMessage: 'CBCT',
        })}
      />

      <Controller
        control={control}
        name="outputFilesForSegmentation"
        render={({
          field: { ref, value, name, onChange },
          fieldState: { error },
        }) => (
          <RadioGroup
            ref={ref}
            className={styles.radioGroup}
            name={name}
            value={value}
            onChange={onChange}
            error={error?.message}
            items={outputFilesForSegmentation}
          />
        )}
      />

      <div className={styles.chart}>
        <div className={styles.switches}>
          <Switch
            checked={isQuartile1Active}
            onChange={() => changeTeethState(JAW.quartile1, !isQuartile1Active)}
          >
            <FormattedMessage id="global.q1" defaultMessage="Q1" />
          </Switch>
          <Switch
            checked={isQuartile2Active}
            onChange={() => changeTeethState(JAW.quartile2, !isQuartile2Active)}
          >
            <FormattedMessage id="global.q2" defaultMessage="Q2" />
          </Switch>
        </div>

        <TeethChart
          teethItems={teethItems}
          onToothClick={({ ISONumber }) => changeToothState(ISONumber)}
        />

        <div className={styles.switches}>
          <Switch
            checked={isQuartile4Active}
            onChange={() => changeTeethState(JAW.quartile4, !isQuartile4Active)}
          >
            <FormattedMessage id="global.q4" defaultMessage="Q4" />
          </Switch>
          <Switch
            checked={isAllTeethActive}
            onChange={() =>
              changeTeethState(
                [
                  ...JAW.quartile1,
                  ...JAW.quartile2,
                  ...JAW.quartile3,
                  ...JAW.quartile4,
                ],
                !isAllTeethActive,
              )
            }
          >
            <FormattedMessage id="global.AllTeeth" defaultMessage="All teeth" />
          </Switch>
          <Switch
            checked={isQuartile3Active}
            onChange={() => changeTeethState(JAW.quartile3, !isQuartile3Active)}
          >
            <FormattedMessage id="global.q3" defaultMessage="Q3" />
          </Switch>
        </div>
      </div>
    </div>
  );
};
