import { IOXRaySubimage } from '@/shared/api/protocol_gen/model/dto_report_type_ioxray_gp';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

export const isSubImageHasToothLocalization = (
  subImage: IOXRaySubimage,
  tooth: Tooth,
) =>
  tooth.Localizations?.length &&
  tooth.Localizations.some(
    (toothLocalization) =>
      toothLocalization.TargetAssetID === subImage.TargetAssetID,
  );
