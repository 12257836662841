import { defineMessages } from 'react-intl';

import { QuestionnaireSteps } from '@/entities/marketing/config/types';

export const companyProfiles = defineMessages({
  soloPractitioner: {
    id: 'companyProfiles.soloPractitioner',
    defaultMessage: 'Solo practitioner',
  },
  multiSpecialtyPractice: {
    id: 'companyProfiles.multiSpecialtyPractice',
    defaultMessage: 'Multi-Specialty Practice',
  },
  groupPractice: {
    id: 'companyProfiles.groupPractice',
    defaultMessage: 'Group Practice',
  },
  dso: {
    id: 'companyProfiles.dso',
    defaultMessage: 'DSO',
  },
  dentalLab: {
    id: 'companyProfiles.dentalLab',
    defaultMessage: 'Dental Lab',
  },
  diagnosticCenter: {
    id: 'companyProfiles.diagnosticCenter',
    defaultMessage: 'Diagnostic Center',
  },
  academicInstitution: {
    id: 'companyProfiles.academicInstitution',
    defaultMessage: 'Academic Institution',
  },
});

export const machineTypes = defineMessages({
  carestreamCS96003D: {
    id: 'machineTypes.carestreamCS96003D',
    defaultMessage: 'Carestream CS 9600 3D',
  },
  carestreamCS90003D: {
    id: 'machineTypes.carestreamCS90003D',
    defaultMessage: 'Carestream CS 9000 3D',
  },
  carestreamCS8000: {
    id: 'machineTypes.carestreamCS8000',
    defaultMessage: 'Carestream CS 8000',
  },
  carestreamCS8100: {
    id: 'machineTypes.carestreamCS8100',
    defaultMessage: 'Carestream CS 8100',
  },
  carestreamCS81003D: {
    id: 'machineTypes.carestreamCS81003D',
    defaultMessage: 'Carestream CS 8100 3D',
  },
  carestreamCS82003D: {
    id: 'machineTypes.carestreamCS82003D',
    defaultMessage: 'Carestream CS 8200 3D',
  },
  durrVistaPanoS: {
    id: 'machineTypes.dürrVistaPanoS',
    defaultMessage: 'Dürr VistaPano S',
  },
  durrVistaVoxS: {
    id: 'machineTypes.dürrVistaVoxS',
    defaultMessage: 'Dürr VistaVox S',
  },
  gendexGXDP: {
    id: 'machineTypes.gendexGXDP',
    defaultMessage: 'Gendex GXDP',
  },
  morita3DAccuitomo170: {
    id: 'machineTypes.morita3DAccuitomo170',
    defaultMessage: 'Morita 3D Accuitomo 170',
  },
  moritaVeraviewIC5HD: {
    id: 'machineTypes.moritaVeraviewIC5HD',
    defaultMessage: 'Morita Veraview IC5 HD',
  },
  moritaVeraviewX800: {
    id: 'machineTypes.moritaVeraviewX800',
    defaultMessage: 'Morita Veraview X800',
  },
  moritaVeraviewepocs2D: {
    id: 'machineTypes.moritaVeraviewepocs2D',
    defaultMessage: 'Morita Veraviewepocs 2D',
  },
  moritaVeraviewepocs3DR100: {
    id: 'machineTypes.moritaVeraviewepocs3DR100',
    defaultMessage: 'Morita Veraviewepocs 3D R100',
  },
  orangedentalGreenX: {
    id: 'machineTypes.orangedentalGreenX',
    defaultMessage: 'Orangedental Green X',
  },
  orangedentalPaXi: {
    id: 'machineTypes.orangedentalPaX-i',
    defaultMessage: 'Orangedental PaX-i',
  },
  orangedentalPaXi3D: {
    id: 'machineTypes.orangedentalPaX-i3D',
    defaultMessage: 'Orangedental PaX-i3D',
  },
  planmecaProMax2DS2: {
    id: 'machineTypes.planmecaProMax2DS2',
    defaultMessage: 'Planmeca ProMax 2D S2',
  },
  planmecaProMax2DS3: {
    id: 'machineTypes.planmecaProMax2DS3',
    defaultMessage: 'Planmeca ProMax 2D S3',
  },
  planmecaProOne: {
    id: 'machineTypes.planmecaProOne',
    defaultMessage: 'Planmeca ProOne',
  },
  planmecaVisoG5: {
    id: 'machineTypes.planmecaViso®G5',
    defaultMessage: 'Planmeca Viso® G5',
  },
  planmecaVisoG7: {
    id: 'machineTypes.planmecaViso®G7',
    defaultMessage: 'Planmeca Viso® G7',
  },
  preXion3DExplorerPro: {
    id: 'machineTypes.preXion3DExplorerPro',
    defaultMessage: 'PreXion 3D Explorer Pro',
  },
  sironaAxeos: {
    id: 'machineTypes.sironaAxeos',
    defaultMessage: 'Sirona Axeos',
  },
  sironaOrthophosE: {
    id: 'machineTypes.sironaOrthophosE',
    defaultMessage: 'Sirona Orthophos E',
  },
  sironaOrthophosS2D: {
    id: 'machineTypes.sironaOrthophosS2D',
    defaultMessage: 'Sirona Orthophos S 2D',
  },
  sironaOrthophosS3D: {
    id: 'machineTypes.sironaOrthophosS3D',
    defaultMessage: 'Sirona Orthophos S 3D',
  },
  sironaOrthophosSL2D: {
    id: 'machineTypes.sironaOrthophosSL2D',
    defaultMessage: 'Sirona Orthophos SL 2D',
  },
  sironaOrthophosSL3D: {
    id: 'machineTypes.sironaOrthophosSL3D',
    defaultMessage: 'Sirona Orthophos SL 3D',
  },
  sironaOrthophosXG: {
    id: 'machineTypes.sironaOrthophosXG',
    defaultMessage: 'Sirona Orthophos XG',
  },
  instrumentarium: {
    id: 'machineTypes.instrumentarium',
    defaultMessage: 'Instrumentarium',
  },
  sironaGalileos: {
    id: 'machineTypes.sironaGalileos',
    defaultMessage: 'Sirona Galileos',
  },
  newTomGianoHR: {
    id: 'machineTypes.newTomGianoHR',
    defaultMessage: 'New Tom Giano HR',
  },
  newTomGo: {
    id: 'machineTypes.newTomGo',
    defaultMessage: 'New Tom Go',
  },
  newTom4G: {
    id: 'machineTypes.newTom4G',
    defaultMessage: 'New Tom 4G',
  },
  newTom5G: {
    id: 'machineTypes.newTom5G',
    defaultMessage: 'New Tom 5G',
  },
  newTom6G: {
    id: 'machineTypes.newTom6G',
    defaultMessage: 'New Tom 6G',
  },
  newTom7G: {
    id: 'machineTypes.newTom7G',
    defaultMessage: 'New Tom 7G',
  },
  newTomVgi: {
    id: 'machineTypes.newTomVgi',
    defaultMessage: 'New Tom Vgi',
  },
  myrayHyperionX9pro: {
    id: 'machineTypes.myrayHyperionX9pro',
    defaultMessage: 'Myray Hyperion X9 pro',
  },
  myrayHyperionX5pro: {
    id: 'machineTypes.myrayHyperionX5pro',
    defaultMessage: 'Myray Hyperion X5 pro',
  },
  vaTechGreenCT2: {
    id: 'machineTypes.vaTechGreenCT2',
    defaultMessage: 'VaTech Green CT2',
  },
  vaTechGreenX: {
    id: 'machineTypes.vaTechGreenX',
    defaultMessage: 'VaTech Green X',
  },
  vaTechGreenX12: {
    id: 'machineTypes.vaTechGreenX12',
    defaultMessage: 'VaTech Green X12',
  },
  vaTechSmartPlus: {
    id: 'machineTypes.vaTechSmartPlus',
    defaultMessage: 'VaTech Smart Plus',
  },
});

export const software2DTypes = defineMessages({
  adstra: {
    id: 'software2DTypes.adstra',
    defaultMessage: 'Adstra',
  },
  AISActeon: {
    id: 'software2DTypes.AIS(Acteon)',
    defaultMessage: 'AIS (Acteon)',
  },
  byzzOrangeDental: {
    id: 'software2DTypes.byzzOrangeDental',
    defaultMessage: 'Byzz (Orange Dental)',
  },
  cadiSynca: {
    id: 'software2DTypes.cadiSynca',
    defaultMessage: 'Cadi (Synca)',
  },
  carestream7: {
    id: 'software2DTypes.Carestream7',
    defaultMessage: 'Carestream7',
  },
  carestream8: {
    id: 'software2DTypes.Carestream8',
    defaultMessage: 'Carestream8',
  },
  cliniview: {
    id: 'software2DTypes.Cliniview',
    defaultMessage: 'Cliniview',
  },
  curveHero: {
    id: 'software2DTypes.CurveHero',
    defaultMessage: 'Curve Hero',
  },
  dexis9Envista: {
    id: 'software2DTypes.Dexis9Envista',
    defaultMessage: 'Dexis 9 (Envista)',
  },
  dexis10Envista: {
    id: 'software2DTypes.Dexis10Envista',
    defaultMessage: 'Dexis 10 (Envista)',
  },
  DTXStudio: {
    id: 'software2DTypes.DTXStudio',
    defaultMessage: 'DTX Studio',
  },
  eagleSoftPatterson: {
    id: 'software2DTypes.EagleSoftPatterson',
    defaultMessage: 'Eagle Soft (Patterson)',
  },
  examPro: {
    id: 'software2DTypes.examPro',
    defaultMessage: 'ExamPro',
  },
  EZDentiVaTech: {
    id: 'software2DTypes.EZDent-iVaTech',
    defaultMessage: 'EZ Dent-i (VaTech)',
  },
  iDental: {
    id: 'software2DTypes.iDental',
    defaultMessage: 'iDental',
  },
  openDental: {
    id: 'software2DTypes.OpenDental',
    defaultMessage: 'OpenDental',
  },
  onDemand: {
    id: 'software2DTypes.onDemand',
    defaultMessage: 'On Demand',
  },
  romexisPlanmeca: {
    id: 'software2DTypes.RomexisPlanmeca',
    defaultMessage: 'Romexis (Planmeca)',
  },
  sidexisXGSirona: {
    id: 'software2DTypes.SidexisXGSirona',
    defaultMessage: 'Sidexis XG (Sirona)',
  },
  SOTAImaging: {
    id: 'software2DTypes.SOTAImaging',
    defaultMessage: 'SOTA Imaging',
  },
  tracker: {
    id: 'software2DTypes.Tracker',
    defaultMessage: 'Tracker',
  },
  vixWinDexis: {
    id: 'software2DTypes.VixWinDexis',
    defaultMessage: 'VixWin (Dexis)',
  },
  XVWebPlanetDDS: {
    id: 'software2DTypes.XVWebPlanetDDS',
    defaultMessage: 'XVWeb (PlanetDDS)',
  },
});

export const softwareTypes = defineMessages({
  AISActeon: {
    id: 'softwareTypes.AIS(Acteon)',
    defaultMessage: 'AIS (Acteon)',
  },
  carestream7: {
    id: 'softwareTypes.Carestream7',
    defaultMessage: 'Carestream7',
  },
  carestream8: {
    id: 'softwareTypes.Carestream8',
    defaultMessage: 'Carestream8',
  },
  DTXStudioEnvista: {
    id: 'softwareTypes.DTXStudioEnvista',
    defaultMessage: 'DTX Studio (Envista)',
  },
  EZ3DVaTech: {
    id: 'softwareTypes.EZ3DVaTech',
    defaultMessage: 'EZ 3D (VaTech)',
  },
  iCATVision: {
    id: 'softwareTypes.iCATVision',
    defaultMessage: 'i-CAT Vision',
  },
  iDixelMorita: {
    id: 'softwareTypes.i-DixelMorita',
    defaultMessage: 'i-Dixel (Morita)',
  },
  invivoDexis: {
    id: 'softwareTypes.InvivoDexis',
    defaultMessage: 'Invivo (Dexis)',
  },
  prexion: {
    id: 'softwareTypes.Prexion',
    defaultMessage: 'Prexion',
  },
  romexisPlanmeca: {
    id: 'softwareTypes.RomexisPlanmeca',
    defaultMessage: 'Romexis (Planmeca)',
  },
  sidexisXGSirona: {
    id: 'softwareTypes.SidexisXGSirona',
    defaultMessage: 'Sidexis XG (Sirona)',
  },
  sidexis4Sirona: {
    id: 'softwareTypes.Sidexis4Sirona',
    defaultMessage: 'Sidexis 4 (Sirona)',
  },
  TXStudioDexis: {
    id: 'softwareTypes.TXStudioDexis',
    defaultMessage: 'TX Studio (Dexis)',
  },
});

export const interestTypes = defineMessages({
  improveDiagnosticsOf2Dimages: {
    id: 'companyProfiles.ImproveDiagnosticsOf2Dimages',
    defaultMessage: 'Improve diagnostics of 2D images',
  },
  improveDiagnosticsOfCBCTImages: {
    id: 'companyProfiles.ImproveDiagnosticsOfCBCTImages',
    defaultMessage: 'Improve diagnostics of CBCT images',
  },
  segmentationOfCBCTAndIOS: {
    id: 'companyProfiles.SegmentationOfCBCTAndIOS',
    defaultMessage: 'Segmentation of CBCT and IOS',
  },
  superimpositionofIOSSTLAndSegmentedSTLfromCBCT: {
    id: 'companyProfiles.SuperimpositionofIOS(STL)andsegmentedSTLfromCBCT',
    defaultMessage: 'Superimposition of IOS (STL) and segmented STL from CBCT',
  },
  improvePatientsCommunicationAndCaseAcceptance: {
    id: 'companyProfiles.ImprovePatientsCommunicationAndCaseAcceptance',
    defaultMessage: 'Improve patients communication and case acceptance',
  },
  savepractitionerandpatienttime: {
    id: 'companyProfiles.Savepractitionerandpatienttime',
    defaultMessage: 'Save practitioner and patient time',
  },
});

export const stepImagesAltText = defineMessages({
  [QuestionnaireSteps.CompanyName]: {
    id: 'imgAltText.companyName',
    defaultMessage: 'Company name',
  },
  [QuestionnaireSteps.OwnerSpecialization]: {
    id: 'imgAltText.ownerSpecialization',
    defaultMessage: 'Specialization',
  },
  [QuestionnaireSteps.CompanyProfile]: {
    id: 'imgAltText.companyProfile',
    defaultMessage: 'Company profile',
  },
  [QuestionnaireSteps.CBCTMachineType]: {
    id: 'imgAltText.CBCTMachineType',
    defaultMessage: 'CBCT Machine model',
  },
  [QuestionnaireSteps.CBCTImagingSoftware]: {
    id: 'imgAltText.CBCTImagingSoftware',
    defaultMessage: 'CBCT Software',
  },
  [QuestionnaireSteps.XRayImagingSoftware]: {
    id: 'imgAltText.XRayImagingSoftware',
    defaultMessage: '2D Software',
  },
  [QuestionnaireSteps.FocusOfInterest]: {
    id: 'imgAltText.focusOfInterest',
    defaultMessage: 'Focus of interest',
  },
});

export const ownerSpecialityType = defineMessages({
  generalPractitioner: {
    id: 'OwnerSpecialityType.GeneralPractitioner',
    defaultMessage: 'General Practitioner',
  },
  oralSurgeon: {
    id: 'OwnerSpecialityType.OralSurgeon',
    defaultMessage: 'Oral Surgeon',
  },
  endodontist: {
    id: 'OwnerSpecialityType.Endodontist',
    defaultMessage: 'Endodontist',
  },
  orthodontist: {
    id: 'OwnerSpecialityType.Orthodontist',
    defaultMessage: 'Orthodontist',
  },
  periodontist: {
    id: 'OwnerSpecialityType.Periodontist',
    defaultMessage: 'Periodontist',
  },
  prosthodontist: {
    id: 'OwnerSpecialityType.Prosthodontist',
    defaultMessage: 'Prosthodontist',
  },
  pediatricDentist: {
    id: 'OwnerSpecialityType.PediatricDentist',
    defaultMessage: 'Pediatric Dentist',
  },
  dentalTechnician: {
    id: 'OwnerSpecialityType.DentalTechnician',
    defaultMessage: 'Dental Technician',
  },
  radiologist: {
    id: 'OwnerSpecialityType.Radiologist',
    defaultMessage: 'Radiologist',
  },
});
