// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReportSigns-module__container--ZcAfuEfA{margin-top:12px;padding:12px 0;border-top:1px solid var(--grey9);display:flex;flex-direction:column;gap:12px}", "",{"version":3,"sources":["webpack://./src/pages/GpReport/ui/ReportSigns/ReportSigns.module.scss"],"names":[],"mappings":"AAAA,yCACE,eAAA,CACA,cAAA,CACA,iCAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".container {\n  margin-top: 12px;\n  padding: 12px 0;\n  border-top: 1px solid var(--grey9);\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReportSigns-module__container--ZcAfuEfA"
};
export default ___CSS_LOADER_EXPORT___;
