// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sider-module__container--ZgNeBWHe{display:flex;flex-direction:column;flex:.3;margin-right:16px}\n\n[dir=\"rtl\"] .Sider-module__container--ZgNeBWHe{margin-right:0;margin-left:16px}@media screen and (max-width: 1280px){.Sider-module__container--ZgNeBWHe{flex:.25}}", "",{"version":3,"sources":["webpack://./src/shared/ui/Layout/components/Sider/Sider.module.scss","webpack://./src/shared/styles/media.scss"],"names":[],"mappings":"AAEA,mCACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,iBAAA;;AAJF,+CAIE,cAAA,CAAA,gBAAA,CCmBA,sCDvBF,mCAOI,QAAA,CAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.container {\n  display: flex;\n  flex-direction: column;\n  flex: 0.3;\n  margin-right: 16px;\n\n  @include desktop {\n    flex: 0.25;\n  }\n}\n","$mobile: 420px;\n$tablet: 768px;\n$smallDesktop: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1400px;\n\n@mixin mobile {\n  @media screen and (max-width: $mobile) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media screen and (max-width: $tablet) {\n    @content;\n  }\n}\n\n@mixin smallDesktop {\n  @media screen and (max-width: $smallDesktop) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media screen and (max-width: $desktop) {\n    @content;\n  }\n}\n\n@mixin largeDesktop {\n  @media screen and (max-width: $largeDesktop) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Sider-module__container--ZgNeBWHe"
};
export default ___CSS_LOADER_EXPORT___;
