import { useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';
import { useLocation, useNavigate } from 'react-router';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import api, { ApiError } from '@/shared/api/api';
import { AccountInfoStreamDataAccumulatorKeys, PATHS } from '@/shared/config';
import { ProductState } from '@/shared/api/protocol_gen/api/billing_new/dto_product_new';
import { Order } from '@/shared/api/protocol_gen/api/billing_new/dto_order_new';

import { billingModel } from '@/entities/billing';
import { organizationModel } from '@/entities/organization';

type AccountInfoStreamDataAccumulators = {
  [AccountInfoStreamDataAccumulatorKeys.inventory]: ProductState[];
  [AccountInfoStreamDataAccumulatorKeys.orders]: Order[];
};

const dataAccumulators: AccountInfoStreamDataAccumulators = {
  [AccountInfoStreamDataAccumulatorKeys.inventory]: [],
  [AccountInfoStreamDataAccumulatorKeys.orders]: [],
};

export const useAccountInfoStream = () => {
  const organizationID = useAppSelector(
    organizationModel.selectors.selectCurrentOrganizationID,
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const accountInfoStream = useRef<Subscription>(null);

  const openAccountInfoStream = () => {
    dispatch(billingModel.actions.setLoading('pending'));

    accountInfoStream.current = api.billing
      .AccountInfoStream({ OrganizationID: organizationID })
      .subscribe({
        next: (data) => {
          if (data.AccountInfo) {
            dispatch(billingModel.actions.setAccount(data.AccountInfo));
          }

          if (data.HistoricalInventory) {
            dataAccumulators[
              AccountInfoStreamDataAccumulatorKeys.inventory
            ].push(data.HistoricalInventory);
          }

          if (data.HistoricalOrder && !data.HistoricalOrder?.Service) {
            dataAccumulators[AccountInfoStreamDataAccumulatorKeys.orders].push(
              data.HistoricalOrder,
            );
          }

          if (data.EndOfHistoricalInventory) {
            dispatch(
              billingModel.actions.setInventory(
                dataAccumulators[
                  AccountInfoStreamDataAccumulatorKeys.inventory
                ],
              ),
            );
          }

          if (data.EndOfHistoricalOrder) {
            dispatch(
              billingModel.actions.setInvoices(
                dataAccumulators[AccountInfoStreamDataAccumulatorKeys.orders],
              ),
            );
            dispatch(billingModel.actions.setLoading('succeeded'));
          }

          if (data.Inventory) {
            dispatch(billingModel.actions.updateInventory(data.Inventory));
          }

          if (data.Order && !data.Order?.Service) {
            dispatch(billingModel.actions.updateOrders(data.Order));
          }
        },
        error: (error: ApiError) => {
          dispatch(billingModel.actions.setLoading('failed'));

          if (error.type === 'UnauthenticatedError') {
            navigate(PATHS.signIn, { state: { from: location?.pathname } });
          }

          if (error.type === 'NotFoundError') {
            navigate(PATHS.patients);
          }

          // eslint-disable-next-line no-console
          console.error(
            'AccountInfoStream: error: ',
            error.message,
            error.code,
            error.type,
            error,
          );
        },
        complete: () => {
          // Do nothing
        },
      });
  };

  const closeAccountInfoStream = () => {
    if (accountInfoStream.current) {
      accountInfoStream.current.unsubscribe();
    }
  };

  useEffect(() => {
    if (organizationID) {
      openAccountInfoStream();
    }

    return () => {
      closeAccountInfoStream();
    };
  }, [organizationID]);
};
