// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomPasswordError-module__container--oOremeDY{width:100%;display:flex;flex-direction:column;justify-content:center;align-items:flex-start;gap:16px;padding-bottom:8px}.CustomPasswordError-module__rules--ez84d4rv{display:flex;flex-direction:column;justify-content:center;align-items:flex-start;gap:8px;margin:0;color:var(--redrose)}.CustomPasswordError-module__passed--A6-VamdE{color:var(--green)}.CustomPasswordError-module__passed--A6-VamdE span{color:var(--green)}", "",{"version":3,"sources":["webpack://./src/widgets/CustomPasswordError/ui/CustomPasswordError.module.scss"],"names":[],"mappings":"AAAA,iDACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,sBAAA,CACA,QAAA,CACA,kBAAA,CAGF,6CACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,sBAAA,CACA,OAAA,CACA,QAAA,CACA,oBAAA,CAGF,8CACE,kBAAA,CACA,mDACE,kBAAA","sourcesContent":[".container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  gap: 16px;\n  padding-bottom: 8px;\n}\n\n.rules {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  gap: 8px;\n  margin: 0;\n  color: var(--redrose);\n}\n\n.passed {\n  color: var(--green);\n  span {\n    color: var(--green);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CustomPasswordError-module__container--oOremeDY",
	"rules": "CustomPasswordError-module__rules--ez84d4rv",
	"passed": "CustomPasswordError-module__passed--A6-VamdE"
};
export default ___CSS_LOADER_EXPORT___;
