import { FC, ReactNode, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Icon } from '@/shared/ui';

import {
  ConditionButton,
  ConditionInterface,
  ConditionToggle,
  ConditionToggleContainer,
  ConditionChildGroup,
  i18n,
  selectChildConditionInterfacesByGroup,
  ChildConditionInterface,
} from '@/entities/condition';

import { useUpdateConditionDecision } from '../hooks';

import styles from './ConditionControl.module.scss';

type ConditionControlProps = {
  className?: string;
  toothID: string;
  conditionInterface: ConditionInterface | ChildConditionInterface;
  conditionInterfaceGroup: ConditionInterface[];
  popupTriggerTitle?: ReactNode;
  showLocalizationsOnHover?: boolean;
};

export const ConditionControl: FC<ConditionControlProps> = (props) => {
  const {
    className,
    toothID,
    popupTriggerTitle,
    conditionInterface,
    conditionInterfaceGroup,
    showLocalizationsOnHover = true,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();
  const { updateConditionDecision, isLoading } =
    useUpdateConditionDecision(toothID);

  const selectedChildConditions =
    selectChildConditionInterfacesByGroup(conditionInterface.code)(
      conditionInterfaceGroup,
    ) ?? [];

  const withChilds = !!selectedChildConditions.length;

  // Implement remove condition if needed by design
  // const handleRemoveCondition = useCallback(() => {
  //   updateConditionDecision({
  //     conditionCode: conditionInterface.code,
  //     conditionID: conditionInterface.id,
  //     userDecision: Decision.NegativeDecision,
  //   });
  // }, [conditionInterface.code, conditionInterface.id, updateConditionDecision]);

  return (
    <Popover.Root
      open={isOpen}
      onOpenChange={() => setIsOpen((state) => !state)}
    >
      {isOpen && <div className={styles.overlay} />}
      <Popover.Trigger asChild>
        <div className={cn(withChilds && styles.conditionWithChilds)}>
          <ConditionButton
            className={cn(isOpen && styles.highlightTrigger)}
            text={popupTriggerTitle ?? conditionInterface.text}
            conditionInterface={conditionInterface as ConditionInterface}
            showProbabilityText={!popupTriggerTitle}
            showLocalizationsOnHover={showLocalizationsOnHover}
          />

          {withChilds &&
            selectedChildConditions.map((childConditionInterface) => (
              <ConditionButton
                key={childConditionInterface.id}
                className={cn(isOpen && styles.highlightTrigger)}
                text={childConditionInterface.text}
                conditionInterface={childConditionInterface}
                showLocalizationsOnHover={showLocalizationsOnHover}
                isChild
              />
            ))}
        </div>
      </Popover.Trigger>

      <Popover.Portal>
        {/* TODO: Tune max width and collision paddings of popover on mobile */}
        <Popover.Content
          className={cn(styles.container, className)}
          sideOffset={12}
          collisionPadding={24}
        >
          <header className={styles.header}>
            <h3 className="h3">
              {formatMessage(i18n.conditionGroupText[conditionInterface.group])}
            </h3>

            <Popover.Close className={styles.closeIcon} aria-label="Close">
              <Icon name="close" size={40} />
            </Popover.Close>
          </header>

          {conditionInterfaceGroup.map((data) => {
            const showChildConditionInterfaceGroups =
              (data.childConditionInterfaces?.length ?? 0) > 0 &&
              data.isChecked;

            return showChildConditionInterfaceGroups ? (
              <ConditionToggleContainer key={data.code}>
                <ConditionToggle
                  data={data}
                  onChange={updateConditionDecision}
                  disabled={isLoading}
                />

                <ConditionChildGroup
                  data={data.childConditionInterfaces ?? []}
                  onChange={updateConditionDecision}
                  disabled={isLoading}
                />
              </ConditionToggleContainer>
            ) : (
              <ConditionToggle
                key={data.code}
                data={data}
                onChange={updateConditionDecision}
                disabled={isLoading}
              />
            );
          })}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
