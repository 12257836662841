import { FC, memo } from 'react';
import cn from 'classnames';

import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { Localization } from '@/shared/api/protocol_gen/model/dto_report_localization';
import { useAppSelector } from '@/shared/hooks';

import { protocolLocalizationToBBox } from '@/entities/reports';
import { logicalConditionModel } from '@/entities/logicalCondition';

import { CroppedMedicalImage } from '../CroppedMedicalImage/CroppedMedicalImage';
import { makeCroppedMedicalImagesFromLocalizations } from '../../lib/makeCroppedMedicalImagesFromLocalizations';

import styles from './CroppedImagesList.module.scss';

type CroppedImagesListProps = {
  localizations?: Localization[];
  className?: string;
  reportType?: ReportType;
  toothID: string;
};

export const CroppedImagesList: FC<CroppedImagesListProps> = memo(
  ({ localizations, toothID, className }) => {
    const croppedImagesList =
      makeCroppedMedicalImagesFromLocalizations(localizations);

    const { code, parentID } = useAppSelector(
      logicalConditionModel.selectors.selectHoveredConditionLink,
    );

    const hoveredCondition = useAppSelector(
      logicalConditionModel.selectors.selectCondition({
        toothID,
        code,
        parentID,
      }),
    );

    const hoveredConditionBBox = hoveredCondition?.Localizations?.map(
      protocolLocalizationToBBox,
    );

    const hoveredConditionLocalizations =
      hoveredCondition?.Tooth?.ToothID === toothID ? hoveredConditionBBox : [];

    return (
      <div className={cn(styles.container, className)}>
        {croppedImagesList?.map((croppedImage) => (
          <CroppedMedicalImage
            key={croppedImage.id}
            croppedImage={croppedImage}
            localizations={hoveredConditionLocalizations}
          />
        ))}
      </div>
    );
  },
);
