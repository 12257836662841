import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import {
  SliceConfigType,
  SliceStepConfig,
  SliceThicknessConfig,
} from './types';

export const SLICE_CONFIGS: Array<SliceConfigType> = [
  {
    name: SliceThicknessConfig.name,
    values: [
      SliceThicknessConfig.OneVoxel,
      SliceThicknessConfig.FiveTenthsMillimeter,
      SliceThicknessConfig.OneMillimeter,
      SliceThicknessConfig.ThreeMillimeters,
      SliceThicknessConfig.FiveMillimeters,
    ],
  },
  {
    name: SliceStepConfig.name,
    values: [
      SliceStepConfig.OneMillimeter,
      SliceStepConfig.OneAndAHalfMillimeters,
      SliceStepConfig.TwoMillimeters,
      SliceStepConfig.TwoAndAHalfMillimeters,
      SliceStepConfig.ThreeMillimeters,
    ],
  },
];

export const REPORTS_TYPE_FOR_NEW_REPORT = [
  ReportType.ReportType_CBCT_GP,
  ReportType.ReportType_CBCT_Endo,
  ReportType.ReportType_CBCT_Molar,
  ReportType.ReportType_CBCT_OrthoSlides,
  ReportType.ReportType_CBCT_Implant,
];
