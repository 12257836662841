import { MedicalImageInterface } from '@/shared/config';
import { BBox } from '@/shared/api/protocol_gen/model/dto_common_geometry';

import { doBBoxOverlap } from '@/features/IOXRayMatrix';

export const getCroppedImagesIDSWithCondition = (
  croppedImages: MedicalImageInterface[],
  bbox: BBox,
) =>
  croppedImages.reduce((ids, croppedImage) => {
    if (doBBoxOverlap(croppedImage.BBox, bbox)) {
      return [...ids, croppedImage.id];
    }
    return ids;
  }, [] as string[]);
