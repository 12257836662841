import {
  Asset,
  AssetContentGeneratedMask2D,
} from '@/shared/api/protocol_gen/model/dto_asset';
import { MedicalImageInterface } from '@/shared/config';

import { ContextRequest } from 'graphics';

import {
  GroupConfigsType,
  PathologyGroup,
} from '../config/medicalImages.types';

import { getFileSrc } from './getFileSrc';

export const makePathologyGroup = (
  groupConfigs: GroupConfigsType,
  medicalImagesList: Asset[],
): PathologyGroup => {
  const { groupName, groupCode } = groupConfigs;

  const allMasks = medicalImagesList.reduce((masks, mask) => {
    if (mask?.GeneratedReport?.Mask2D) {
      masks.push(mask.GeneratedReport.Mask2D);
    }

    return masks;
  }, [] as AssetContentGeneratedMask2D[]);

  const medicalImages = medicalImagesList.reduce((acc, item) => {
    if (
      item?.GeneratedReport?.CBCTToothPathologySlice?.Pathology === groupCode
    ) {
      const imageMask2D = allMasks.find(
        (mask) => item?.ID && mask.TargetAssetID === item.ID,
      );
      const imageMask: ContextRequest = {
        kind: 'dicom-mask',
        url: getFileSrc(imageMask2D?.Mask?.ID || ''),
      };

      acc.push({
        id: item?.GeneratedReport?.CBCTToothPathologySlice?.Slice?.ID || '',
        src: getFileSrc(
          item?.GeneratedReport?.CBCTToothPathologySlice?.Slice?.ID || '',
        ),
        path: item?.GeneratedReport?.CBCTToothPathologySlice?.Slice?.Path,
        assetID: item.ID,
        mask: imageMask2D ? imageMask : undefined,
        groupName,
      });
    }

    return acc;
  }, [] as MedicalImageInterface[]);

  return {
    type: groupName,
    medicalImages,
  };
};
