// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PreviewScanInfo-module__greyText--16CrIoy6{color:var(--grey9)}.PreviewScanInfo-module__scanElems--Evj2tMZb{display:flex;gap:54px;margin-top:4px}.PreviewScanInfo-module__scanElem--gsvmpVoC{color:var(--grey9)}", "",{"version":3,"sources":["webpack://./src/pages/PreviewReport/ui/PreviewScanInfo/PreviewScanInfo.module.scss"],"names":[],"mappings":"AAAA,4CACE,kBAAA,CAGF,6CACE,YAAA,CACA,QAAA,CACA,cAAA,CAGF,4CACE,kBAAA","sourcesContent":[".greyText {\n  color: var(--grey9);\n}\n\n.scanElems {\n  display: flex;\n  gap: 54px;\n  margin-top: 4px;\n}\n\n.scanElem {\n  color: var(--grey9);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"greyText": "PreviewScanInfo-module__greyText--16CrIoy6",
	"scanElems": "PreviewScanInfo-module__scanElems--Evj2tMZb",
	"scanElem": "PreviewScanInfo-module__scanElem--gsvmpVoC"
};
export default ___CSS_LOADER_EXPORT___;
