import { FC, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { sort } from 'ramda';
import cn from 'classnames';

import { Modal, Input, Tabs, WidgetCard } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import {
  ConditionToggle,
  ConditionToggleList,
  ConditionChildGroup,
  ConditionToggleContainer,
  ConditionInterface,
  filterConditionInterfacesBySearch,
  sortConditionInterfaceByProbability,
  sortConditionInterfaceAlphabetically,
  groupConditionInterfaceByGroup,
  ConditionListByCategory,
  sortChildConditionInterfaceByProbability,
  ChildConditionInterface,
} from '@/entities/condition';
import { modalModel, ModalID } from '@/entities/modal';
import { reportsModel } from '@/entities/reports';
import { allowedToothConditionsModel } from '@/entities/allowedToothConditions';
import { logicalConditionModel } from '@/entities/logicalCondition';
import { useTransformLogicalCondition } from '@/entities/condition/hooks/useTransformLogicalCondition';

import { useUpdateConditionDecision } from '@/features/conditionControl';

import styles from './AddConditionModal.module.scss';

enum TabValue {
  Probability = 'Probability',
  Category = 'Category',
  Alphabetically = 'Alphabetically',
}

const useAddCondition = (
  conditionItems: ConditionInterface[],
  toothID: string,
) => {
  const [search, setSearch] = useState('');

  // TODO: [2/m] discuss with the backend the possibility of obtaining allowedToothConditions in the tooth or condition entity and remove the allowedToothConditions entity
  const allowedToothConditions = useAppSelector((state) =>
    allowedToothConditionsModel.selectors.selectById(state, toothID),
  );

  const filteredConditionItemsByAllowedToothConditions = conditionItems.filter(
    (conditionItem) =>
      allowedToothConditions?.ConditionCodes.includes(conditionItem.code),
  );

  const filteredConditionItems =
    search !== ''
      ? filterConditionInterfacesBySearch(
          search,
          filteredConditionItemsByAllowedToothConditions,
        )
      : filteredConditionItemsByAllowedToothConditions;

  const conditionItemsSortedByAlphabet = sort(
    sortConditionInterfaceAlphabetically,
    filteredConditionItems,
  );

  const conditionItemsSortedByProbability: ConditionInterface[] =
    filteredConditionItems.reduce(
      (acc: ConditionInterface[], condition: ConditionInterface) => {
        const { childConditionInterfaces = [] } = condition;

        const childConditionsSortedByProbability: ChildConditionInterface[] =
          sort(
            sortChildConditionInterfaceByProbability,
            childConditionInterfaces,
          );
        const conditionWithSortedChildConditions: ConditionInterface = {
          ...condition,
          childConditionInterfaces: childConditionsSortedByProbability,
        };

        const conditionsSortedByProbability: ConditionInterface[] = sort(
          sortConditionInterfaceByProbability,
          acc,
        );

        return [
          ...conditionsSortedByProbability,
          conditionWithSortedChildConditions,
        ];
      },
      [],
    );

  const conditionItemsByGroups = groupConditionInterfaceByGroup(
    filteredConditionItems,
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  return {
    search,
    handleSearch,
    conditionItemsSortedByProbability,
    conditionItemsByGroups,
    conditionItemsSortedByAlphabet,
  };
};

type AddConditionCardProps = {
  className?: string;
  tabContentClassName?: string;
  title?: React.ReactNode;
  toothID: string;
  reportID: string;
};

export const AddConditionCard: FC<AddConditionCardProps> = (props) => {
  const { title, className, tabContentClassName, toothID, reportID } = props;

  const { formatMessage } = useIntl();

  const toothConditions = useAppSelector(
    logicalConditionModel.selectors.selectToothConditions(toothID),
  );

  const report = useAppSelector((state) =>
    reportsModel.selectors.selectById(state, reportID),
  );

  const showLowProbability = report?.Settings?.LowProbabilityMode;

  const conditionItems = useTransformLogicalCondition(
    toothConditions,
    true,
    showLowProbability,
  );

  const {
    search,
    handleSearch,
    conditionItemsSortedByProbability,
    conditionItemsByGroups,
    conditionItemsSortedByAlphabet,
  } = useAddCondition(conditionItems, toothID);

  const { updateConditionDecision, isLoading } =
    useUpdateConditionDecision(toothID);

  return (
    <WidgetCard className={cn(styles.card, className)}>
      {title && <h3 className="h3">{title}</h3>}
      <Tabs.Root defaultValue={TabValue.Probability}>
        <Tabs.List size="small">
          <Tabs.Trigger value={TabValue.Probability} size="small">
            <FormattedMessage
              id="addConditionModal.tab.probability"
              defaultMessage="Probability"
            />
          </Tabs.Trigger>

          <Tabs.Trigger value={TabValue.Category} size="small">
            <FormattedMessage
              id="addConditionModal.tab.category"
              defaultMessage="Category"
            />
          </Tabs.Trigger>

          <Tabs.Trigger value={TabValue.Alphabetically} size="small">
            <FormattedMessage
              id="addConditionModal.tab.alphabetically"
              defaultMessage="Alphabetically"
            />
          </Tabs.Trigger>
        </Tabs.List>

        <Input
          className={styles.search}
          type="search"
          name="search-condition"
          inputMode="search"
          icon="search"
          placeholder={formatMessage({
            id: 'addConditionModal.search',
            defaultMessage: 'Search',
          })}
          value={search}
          onChange={handleSearch}
        />

        <Tabs.Content
          value={TabValue.Probability}
          className={cn(styles.tabContent, tabContentClassName)}
        >
          <ConditionToggleList>
            {conditionItemsSortedByProbability.map((data) =>
              data.childConditionInterfaces ? (
                <ConditionToggleContainer key={data.code}>
                  <ConditionToggle
                    data={data}
                    onChange={updateConditionDecision}
                    disabled={isLoading}
                  />

                  <ConditionChildGroup
                    className={styles.childGroup}
                    data={data.childConditionInterfaces}
                    onChange={updateConditionDecision}
                    disabled={isLoading || !data?.isChecked}
                  />
                </ConditionToggleContainer>
              ) : (
                <ConditionToggle
                  key={data.code}
                  data={data}
                  onChange={updateConditionDecision}
                  disabled={isLoading}
                />
              ),
            )}
          </ConditionToggleList>
        </Tabs.Content>

        <Tabs.Content
          value={TabValue.Category}
          className={cn(styles.tabContent, tabContentClassName)}
        >
          <ConditionListByCategory
            conditionItemsByGroups={conditionItemsByGroups}
            updateConditionDecision={updateConditionDecision}
            disabled={isLoading}
            toothID={toothID}
          />
        </Tabs.Content>

        <Tabs.Content
          value={TabValue.Alphabetically}
          className={cn(styles.tabContent, tabContentClassName)}
        >
          <ConditionToggleList>
            {conditionItemsSortedByAlphabet.map((data) =>
              data.childConditionInterfaces ? (
                <ConditionToggleContainer key={data.code}>
                  <ConditionToggle
                    data={data}
                    onChange={updateConditionDecision}
                    disabled={isLoading}
                  />

                  <ConditionChildGroup
                    className={styles.childGroup}
                    data={data.childConditionInterfaces}
                    onChange={updateConditionDecision}
                    disabled={isLoading || !data?.isChecked}
                  />
                </ConditionToggleContainer>
              ) : (
                <ConditionToggle
                  key={data.code}
                  data={data}
                  onChange={updateConditionDecision}
                  disabled={isLoading}
                />
              ),
            )}
          </ConditionToggleList>
        </Tabs.Content>
      </Tabs.Root>
    </WidgetCard>
  );
};

export const AddConditionModal: FC = () => {
  const dispatch = useAppDispatch();

  const {
    visible,
    data: { toothID, reportID },
  } = useAppSelector((state) => state.modal.AddCondition);

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.AddCondition));
  }, [dispatch]);

  return (
    <Modal
      title={
        <FormattedMessage
          id="addConditionModal.title"
          defaultMessage="Add conditions"
        />
      }
      isOpen={visible}
      onClose={handleClose}
      className={styles.modal}
      bodyClassName={styles.modalBody}
      containerClassName={styles.modalContainer}
      hideFooter
      borderless
    >
      <AddConditionCard
        toothID={toothID}
        reportID={reportID}
        className={styles.modalCard}
      />
    </Modal>
  );
};
