export const REPORT_SIGNED_DATE_FORMAT = 'EEE dd, yyyy HH:mm';

const whiteConditionStyles = {
  color: [1, 1, 1],
  style: {
    filter: 'blur(2px)',
  },
  opacity: 0.1,
};

export const presetTypes = [
  {
    red: {
      color: [1, 0.1, 0.3],
      style: {},
      opacity: 0.7,
    },
    yellow: {
      color: [1, 0.7, 0],
      style: {},
      opacity: 0.6,
    },
    purple: {
      color: [0.5, 0.1, 1],
      style: {},
      opacity: 0.7,
    },
    white: whiteConditionStyles,
  },
  {
    red: {
      color: [1, 0.1, 0.3],
      style: {},
      opacity: 0.6,
    },
    yellow: {
      color: [1, 0.7, 0],
      style: {},
      opacity: 0.5,
    },
    purple: {
      color: [1, 1, 1],
      style: {},
      opacity: 0.7,
    },
    white: whiteConditionStyles,
  },
  {
    red: {
      color: [1, 0.1, 0.3],
      style: {},
      opacity: 0.5,
    },
    yellow: {
      color: [1, 0.7, 0],
      style: {},
      opacity: 0.3,
    },
    purple: {
      color: [0.6, 0.6, 0.6],
      style: {},
      opacity: 0.4,
    },
    white: whiteConditionStyles,
  },
  {
    red: {
      color: [1, 0.1, 0.3],
      style: {
        backgroundBlendMode: 'color',
      },
      opacity: 0.7,
    },
    yellow: {
      color: [1, 0.7, 0],
      style: {
        backgroundBlendMode: 'hard-light',
      },
      opacity: 1,
    },
    purple: {
      color: [1, 1, 1],
      style: {},
      opacity: 0.4,
    },
    white: whiteConditionStyles,
  },
];
