import { useIntl } from 'react-intl';
import { capitalize } from 'lodash';

import { BreadcrumbElementType } from '@/shared/config';
import { useAppSelector, useLocationState } from '@/shared/hooks';

import * as reportModel from '../model';
import { i18n } from '../config';

type ReportBreadcrumbs = {
  report: BreadcrumbElementType;
};

export const useReportsBreadcrumbs = ({
  patientID,
  reportID,
}: {
  reportID: string;
  patientID: string;
}): ReportBreadcrumbs => {
  const { formatMessage, formatDate } = useIntl();

  const { toothID, lastPositionReport, locationState } = useLocationState();

  const { reportType, reportCreatedDate, reportPath } = useAppSelector(
    reportModel.selectors.selectReportBreadcrumbData({ reportID, patientID }),
  );

  const reportDate = formatDate(reportCreatedDate ?? new Date(), {
    dateStyle: 'medium',
  });

  const reportLabel = reportType
    ? `${formatMessage(i18n[reportType])} ${capitalize(reportDate)}`
    : formatMessage({ id: 'global.report', defaultMessage: 'Report' });

  return {
    report: {
      label: reportLabel,
      path: reportPath,
      state: { ...locationState, toothID, lastPositionReport },
    },
  };
};
