import { MaskStyleTypes } from '@/entities/reports';

export const MASKS_TYPES = [
  MaskStyleTypes.Default,
  MaskStyleTypes.Style2,
  MaskStyleTypes.Style3,
  MaskStyleTypes.Style4,
  MaskStyleTypes.StyleBW,
];

export const IOX_RAY_IMAGE_PADDING = 100;
