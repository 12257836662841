import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { replace } from 'ramda';

import {
  BASE_URI,
  FORWARD_SLASH_REGEX,
  ToastErrors,
  toastErrors,
  WHITE_SPACE_REGEX,
} from '@/shared/config';
import { ApiError } from '@/shared/api/api';
import { toastCaller } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import { i18n, reportsModel } from '@/entities/reports';
import { patientModel } from '@/entities/patient';

export const useDownloadPDF = (
  patientID: string,
  reportID: string,
): {
  downloadPDF: () => Promise<void>;
  isLoadingPDF: boolean;
} => {
  const { pathname } = useLocation();

  const { formatMessage, formatDate } = useIntl();

  const [isLoadingPDF, setIsLoadingPDF] = useState<boolean>(false);

  const currentPatient = useAppSelector(
    patientModel.selectors.selectPatientByID(patientID),
  );

  const currentReport = useAppSelector(
    reportsModel.selectors.selectByID(reportID),
  );

  const patientFullName = `${currentPatient?.PersonalData?.LastName}_${currentPatient?.PersonalData?.FirstName}`;

  const reportType = replace(
    WHITE_SPACE_REGEX,
    '_',
    formatMessage(i18n[currentReport?.Type ?? ReportType.ReportType_CBCT_GP]),
  );

  const reportCreationDate = replace(
    FORWARD_SLASH_REGEX,
    '',
    formatDate(currentReport?.Created?.At ?? 0, {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    }),
  );

  const fileName = `${patientFullName}_${reportType}_${reportCreationDate}`;

  const downloadPDF = async () => {
    setIsLoadingPDF(true);

    try {
      const encode = encodeURIComponent(BASE_URI + pathname);

      const url = `${BASE_URI}/typewriter?url=${encode}`;

      const { data } = await axios.get(url, {
        withCredentials: true,
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      });

      const blob = new Blob([data], { type: 'application/pdf' });

      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    } catch (error) {
      const parsedMessage = JSON.parse((error as ApiError)?.message);
      const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

      toastCaller({
        type: 'error',
        heading: errorHeading,
        message: parsedMessage?.text ?? parsedMessage?.reason,
      });
    } finally {
      setIsLoadingPDF(false);
    }
  };

  return {
    downloadPDF,
    isLoadingPDF,
  };
};
