// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResetPasswordErrorModal-module__container--E6YzDXfQ{width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;gap:24px}.ResetPasswordErrorModal-module__errorMessages--SIBGBLBO{width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center}.ResetPasswordErrorModal-module__sadCatImage--Ot8GSY-2{width:130px;height:130px}", "",{"version":3,"sources":["webpack://./src/widgets/ResetPasswordErrorModal/ui/ResetPasswordErrorModal.module.scss"],"names":[],"mappings":"AAAA,qDACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,QAAA,CAGF,yDACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAGF,uDACE,WAAA,CACA,YAAA","sourcesContent":[".container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 24px;\n}\n\n.errorMessages {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.sadCatImage {\n  width: 130px;\n  height: 130px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ResetPasswordErrorModal-module__container--E6YzDXfQ",
	"errorMessages": "ResetPasswordErrorModal-module__errorMessages--SIBGBLBO",
	"sadCatImage": "ResetPasswordErrorModal-module__sadCatImage--Ot8GSY-2"
};
export default ___CSS_LOADER_EXPORT___;
