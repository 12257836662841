import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  PATHS,
  ToastErrors,
  toastErrors,
  UserBadRequestError,
} from '@/shared/config';
import { toastCaller } from '@/shared/ui';
import { ApiError } from '@/shared/api/api';

import { confirmEmailErrorMessages } from '../config/i18n';

export const useConfirmEmailError = (): {
  callConfirmEmailError: (error: ApiError) => void;
  inlineError: string;
} => {
  const navigate = useNavigate();

  const { formatMessage } = useIntl();

  const [inlineError, setInlineError] = useState<string>('');

  const callConfirmEmailError = (error: ApiError) => {
    const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);
    const parsedMessage = JSON.parse(error.message);
    const errorCode = parsedMessage.code;

    if (
      errorCode === UserBadRequestError.CODE_RETRIES_LIMIT ||
      errorCode === UserBadRequestError.CODE_IS_EXPIRED
    ) {
      navigate(PATHS.signUp);
    }

    if (errorCode !== UserBadRequestError.WRONG_CODE) {
      toastCaller({
        type: 'error',
        heading: errorHeading,
        message:
          formatMessage(confirmEmailErrorMessages[errorCode]) ??
          parsedMessage?.text ??
          parsedMessage?.reason,
      });
    } else {
      setInlineError(formatMessage(confirmEmailErrorMessages[errorCode]));
    }
  };

  return {
    callConfirmEmailError,
    inlineError,
  };
};
