import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Control, Controller } from 'react-hook-form';

import { Select } from '@/shared/ui';

import { additionalInfoSelectStyles } from '../AdditionalInfoSelect/AdditionalInfoSelect.styles';
import {
  AdditionalInfoMultiValueLabel,
  AdditionalInfoMultiValueRemove,
} from '../AdditionalInfoSelect/AdditionalInfoSelect.components';
import { useGetMachineTypesOptions } from '../../hooks/useGetMachineTypesOptions';
import styles from '../../Steps.module.scss';
import { AdditionalInfoFormPayload } from '../../config/types';

type CBCTMachineTypeStepProps = {
  control: Control<AdditionalInfoFormPayload>;
  className?: string;
  testID?: string;
};

export const CBCTMachineTypeStep: FC<CBCTMachineTypeStepProps> = (props) => {
  const { control, className, testID } = props;

  const machineTypesOptions = useGetMachineTypesOptions();

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <div className={styles.headerOfOptionalSelect}>
        <h2 className={cn(styles.title, 'h2')}>
          <FormattedMessage
            id="additionalInfo.machineModelQuestion"
            defaultMessage="What is the model of your 3D Machine?"
          />
        </h2>

        <p className="p2">
          <FormattedMessage
            id="additionalInfo.dontKnowTheAnswer"
            defaultMessage="If you don't know the answer, click Next."
          />
        </p>
      </div>

      <Controller
        control={control}
        name="CBCTMachineType"
        render={({
          field: { ref, value, name, onBlur, onChange },
          fieldState: { error },
        }) => (
          <Select
            options={machineTypesOptions}
            isMulti
            ref={ref}
            value={value}
            name={name}
            error={error?.message}
            onBlur={onBlur}
            onChange={onChange}
            styles={additionalInfoSelectStyles}
            components={{
              MultiValueLabel: AdditionalInfoMultiValueLabel,
              MultiValueRemove: AdditionalInfoMultiValueRemove,
            }}
          />
        )}
      />
    </div>
  );
};
