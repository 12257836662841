import { useRef, useState } from 'react';
import { Subscription } from 'rxjs';
import { useSearchParams } from 'react-router-dom';

import api from '@/shared/api/api';
import { useAppDispatch } from '@/shared/hooks';
import { Patient } from '@/shared/api/protocol_gen/model/dto_patient';

import { patientModel } from '@/entities/patient';
import { studyCountModel, StudyCount } from '@/entities/studyCount';

import { AllPatientsFilterSettingsType } from '@/pages/Patients/config/types';

export type UsePatientsListStreamArgs = {
  organizationID: string;
};

export const usePatientsListStream = ({
  organizationID,
}: UsePatientsListStreamArgs) => {
  // TODO: Resolve issue with previous searchParams date on reopening stream
  const [searchParams] = useSearchParams();

  const doctorIDs = searchParams.get('doctorIDs') ?? '';
  const doctorFiltersIsEmpty = !searchParams.get('doctorIDs');

  const searchValue = searchParams.get('search') ?? '';
  const doctorFilter = doctorFiltersIsEmpty ? [] : doctorIDs.split(',');

  // use to restore patients state for infinity scroll
  // must be reseted after any filters update
  const [resumeToken, setResumeToken] = useState('');

  const dispatch = useAppDispatch();

  const patientListStream = useRef<Subscription>();

  const openPatientListStream = (
    filterSettings: AllPatientsFilterSettingsType = {
      searchString: searchValue,
      doctorFilters: doctorFilter,
      id: '',
    },
  ) => {
    const { searchString, id, doctorFilters, sortBy } = filterSettings;

    dispatch(patientModel.actions.setLoading('pending'));

    patientListStream.current = api.patient
      .PatientListStream({
        OrganizationID: organizationID,
        ResumeToken: resumeToken,
        SearchString: searchString,
        AllDoctors: !doctorFilters?.length ? {} : undefined,
        OnlyTheseDoctors: doctorFilters?.length
          ? { DoctorIDs: doctorFilters }
          : undefined,
        Sort: sortBy,
        StartFromPatientID: id,
        Limit: 20,
      })
      .subscribe({
        next: ({
          InitialPatientsListItems,
          PatientUpdated,
          ResumeToken,
          TotalOrganizationPatientCount,
          SharedWithMeCount,
          SharedByMeCount,
        }) => {
          if (InitialPatientsListItems?.Patients) {
            const patients = InitialPatientsListItems?.Patients.map(
              ({ Patient: patient }) => patient,
            );

            if (id) {
              dispatch(patientModel.actions.addMany(patients as Patient[]));
            } else {
              dispatch(patientModel.actions.setMany(patients));
            }

            dispatch(patientModel.actions.setLoading('succeeded'));

            const studyCounts = InitialPatientsListItems?.Patients.reduce(
              (patientStudyCounts, { Patient: patient, Counts }) => {
                patientStudyCounts.push({
                  patientID: patient?.ID ?? '',
                  counts: Counts,
                });

                return patientStudyCounts;
              },
              [] as StudyCount[],
            );

            dispatch(studyCountModel.actions.setMany(studyCounts));
          }

          if (PatientUpdated && PatientUpdated.Patient) {
            dispatch(patientModel.actions.setNewestOne(PatientUpdated.Patient));

            const studyCount = {
              patientID: PatientUpdated.Patient?.ID,
              counts: PatientUpdated.Counts,
            };

            dispatch(studyCountModel.actions.setOne(studyCount));
          }

          if (ResumeToken) {
            setResumeToken(ResumeToken);
          }

          if (SharedWithMeCount) {
            dispatch(
              patientModel.actions.setSharedWithMeCount(SharedWithMeCount),
            );
          }

          if (SharedByMeCount) {
            dispatch(patientModel.actions.setSharedByMeCount(SharedByMeCount));
          }

          if (TotalOrganizationPatientCount) {
            dispatch(
              patientModel.actions.setTotalOrganizationPatientCount(
                TotalOrganizationPatientCount,
              ),
            );
          }
        },
        complete: () => {},
        error: () => {
          dispatch(patientModel.actions.setLoading('failed'));
        },
      });
  };

  const closePatientListStream = (shouldClearStore = true) => {
    if (patientListStream.current) {
      patientListStream.current.unsubscribe();

      if (shouldClearStore) {
        dispatch(patientModel.actions.removeAll());
      }
    }
  };

  return { openPatientListStream, closePatientListStream };
};
