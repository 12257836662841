// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubscriptionPlans-module__container--xMpj2MTa{display:flex;align-items:center;flex-direction:column;gap:24px}.SubscriptionPlans-module__cardsContainer--\\+qdxkGpK{display:flex;flex-wrap:wrap;justify-content:center;gap:24px}.SubscriptionPlans-module__tabs--jVoM4gBu{gap:24px !important}.SubscriptionPlans-module__tabs--jVoM4gBu>label{width:440px}.SubscriptionPlans-module__tabs--jVoM4gBu>label>span{width:440px;justify-content:center;border-radius:24px}", "",{"version":3,"sources":["webpack://./src/widgets/SubscriptionPlans/ui/SubscriptionPlans/SubscriptionPlans.module.scss"],"names":[],"mappings":"AAAA,+CACE,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,QAAA,CAGF,qDACE,YAAA,CACA,cAAA,CACA,sBAAA,CACA,QAAA,CAGF,0CACE,mBAAA,CAEA,gDACE,WAAA,CAEA,qDACE,WAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.cardsContainer {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  gap: 24px;\n}\n\n.tabs {\n  gap: 24px !important;\n\n  > label {\n    width: 440px;\n\n    > span {\n      width: 440px;\n      justify-content: center;\n      border-radius: 24px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SubscriptionPlans-module__container--xMpj2MTa",
	"cardsContainer": "SubscriptionPlans-module__cardsContainer--+qdxkGpK",
	"tabs": "SubscriptionPlans-module__tabs--jVoM4gBu"
};
export default ___CSS_LOADER_EXPORT___;
